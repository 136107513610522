import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledVideoContainer } from 'components/NewForms/DynamicForm/DynamicFormStyles';

interface TenantConfigVideoFieldProps {
  videoUrl?: string;
}

const TenantConfigVideoField: React.FC<TenantConfigVideoFieldProps> = ({
  videoUrl,
}) => {
  const { t } = useTranslation();

  if (!videoUrl) return null;

  return (
    <StyledVideoContainer>
      <video width="80%" height="auto" controls>
        <source src={videoUrl} type="video/mp4" />
        <track kind="captions" srcLang="en" label={t('englishCaptions')} />
        {t('your browser does not support the video tag.')}
      </video>
    </StyledVideoContainer>
  );
};

export default TenantConfigVideoField;
