export enum StageName {
  New = 'New',
  CVReview = 'CV Review',
  Interview = 'Interview',
  PracticalTask = 'Practical Task',
  CultureFitMeeting = 'Culture-fit Meeting',
  Offer = 'Offer',
  OfferAccepted = 'Offer Accepted',
  Hired = 'Hired',
  Disqualified = 'Rejected',
}

export interface Stage {
  id: string;
  name: string;
  order?: number;
  createdAt: Date;
  updatedAt: Date;
}

export const openPositionStageList = [
  {
    key: StageName.New,
    label: StageName.New,
  },
  {
    key: StageName.CVReview,
    label: StageName.CVReview,
  },
  {
    key: StageName.Interview,
    label: StageName.Interview,
  },
  {
    key: StageName.PracticalTask,
    label: StageName.PracticalTask,
  },
  {
    key: StageName.CultureFitMeeting,
    label: StageName.CultureFitMeeting,
  },
  {
    key: StageName.Offer,
    label: StageName.Offer,
  },
  {
    key: StageName.OfferAccepted,
    label: StageName.OfferAccepted,
  },
  {
    key: StageName.Hired,
    label: StageName.Hired,
  },
  {
    key: StageName.Disqualified,
    label: StageName.Disqualified,
  },
];

export enum StageColor {
  New = '#344054',
  CVReview = '#344054',
  Interview = '#344054',
  PracticalTask = '#344054',
  CultureFitMeeting = '#344054',
  Offer = '#027A48',
  OfferAccepted = '#027A48',
  Hired = '#027A48',
  Disqualified = '#B42318',
}
export enum StageBg {
  New = '#edeff3',
  CVReview = '#edeff3',
  Interview = '#edeff3',
  PracticalTask = '#edeff3',
  CultureFitMeeting = '#edeff3',
  Offer = '#ECFDF3',
  OfferAccepted = '#ECFDF3',
  Hired = '#ECFDF3',
  Disqualified = '#FEF3F2',
}

export type StageColorKey = keyof typeof StageColor;
export type StageBgKey = keyof typeof StageBg;
