import React from 'react';

export const InterviewsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.19922H1M14 1.19922V5.19922M6 1.19922V5.19922M5.8 21.1992H14.2C15.8802 21.1992 16.7202 21.1992 17.362 20.8722C17.9265 20.5846 18.3854 20.1257 18.673 19.5612C19 18.9195 19 18.0794 19 16.3992V7.99922C19 6.31906 19 5.47898 18.673 4.83725C18.3854 4.27276 17.9265 3.81382 17.362 3.5262C16.7202 3.19922 15.8802 3.19922 14.2 3.19922H5.8C4.11984 3.19922 3.27976 3.19922 2.63803 3.5262C2.07354 3.81382 1.6146 4.27276 1.32698 4.83725C1 5.47898 1 6.31906 1 7.99922V16.3992C1 18.0794 1 18.9195 1.32698 19.5612C1.6146 20.1257 2.07354 20.5846 2.63803 20.8722C3.27976 21.1992 4.11984 21.1992 5.8 21.1992Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
