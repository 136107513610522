import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';

import { useSearchParams } from 'react-router-dom';

import PurchaseRequestForm from 'components/NewForms/PurchaseRequestForm/PurchaseRequestForm';
import { usePurchaseRequestData } from 'ui-v2/hooks/usePurchaseRequestData';
import {
  clearPurchaseRequest,
  fetchPurchaseRequests,
} from 'redux/purchaseRequest/actions';

export default function PurchaseRequestList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = usePurchaseRequestData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPurchaseRequests({ pageOptions: { page: 1, take } }));
    dispatch(clearPurchaseRequest());
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      ...(searchTerm && {
        filter: { filterText: searchTerm, filterFields: [] },
      }),
      pageOptions: { page, take: 10 },
    };
    dispatch(fetchPurchaseRequests(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      ...(searchTerm && {
        filter: { filterText: searchTerm, filterFields: [] },
      }),
      pageOptions: { page: 1, take: 10 },
    };
    dispatch(fetchPurchaseRequests(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-new-purchase-request') === 'true') {
      searchParams.delete('add-new-purchase-request');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-purchase-request')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  //   async function deleteWorkPositionById(id: string | null) {
  //     if (!id) return;
  //     deleteWorkPosition(id)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           toast.success('Work position deleted successfully!');
  //           dispatch(
  //             fetchWorkPositions({
  //               pageOptionsDto: { page: pagination?.page || 1, take: 10 },
  //             })
  //           );
  //         }
  //       })
  //       .catch(toastErrorMessages);
  //   }

  //   async function onDelete(ids: React.Key[]) {
  //     ids?.forEach(async (id) => {
  //       await deleteWorkPositionById(id?.toString());
  //     });
  //   }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Work positions"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          //   onSelectedDelete={onDelete}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <PurchaseRequestForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-purchase-request');
              setSearchParams(searchParams);
              dispatch(clearPurchaseRequest());
            }}
          />
        )}
      </>
    </Content>
  );
}
