import React, { CSSProperties } from 'react';
import { RulesType } from 'types/FormTypes';
import { FormItemStyled, StyledRate } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  defaultValue?: number;
  disabled?: boolean;
  styles?: CSSProperties;
  onChange?: (value: number) => void;
  hint?: React.ReactNode | string;
}
const RateComponent: React.FC<Props> = ({
  placeholder,
  name,
  rules,
  disabled,
  defaultValue,
  styles,
  onChange,
  hint,
}) => (
  <FormItemStyled
    name={name}
    label={placeholder}
    rules={rules}
    {...(hint && { tooltip: hint })}
  >
    <StyledRate
      style={styles}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
    />
  </FormItemStyled>
);

RateComponent.defaultProps = {
  rules: [],
  defaultValue: 2.5,
};

export default RateComponent;
