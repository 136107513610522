import React, { useEffect, useState } from 'react';

import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { useUsersData } from 'ui-v2/hooks/useUsersData';
import { fetchUsers } from 'redux/users/actions';
import useHeader from 'ui-v2/hooks/useHeader';
import { useSearchParams } from 'react-router-dom';
import UserForm from 'components/NewForms/UserForm';
import { deleteUser } from 'api/usersService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';

function Users() {
  const { columns, take } = useHeader();
  const { list, pagination, loading } = useUsersData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, isVisible] = useState(false);
  const { t } = useTranslation();

  const toogleModalVisibility = () => isVisible((visible) => !visible);

  const dispatch = useDispatch();

  async function deleteUserById(id: string | null) {
    if (!id) return;
    deleteUser(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('userDeletedSuccessfully'));
          dispatch(fetchUsers({ page: pagination?.page, take: 10 }));
        }
      })
      .catch(toastErrorMessages);
  }

  useEffect(() => {
    dispatch(fetchUsers({ page: 1, take }));
  }, []);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchUsers(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchUsers(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-new-user') === 'true') {
      searchParams.delete('add-new-user');
      setSearchParams(searchParams);
      toogleModalVisibility();
      console.log('add new user');
    }
    if (searchParams.get('edit-user')) {
      toogleModalVisibility();
    }
    if (searchParams.get('edit-my-profile')) {
      toogleModalVisibility();
    }
  }, [searchParams]);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteUserById(id?.toString());
    });
  }

  return (
    <>
      <Content position="stretch">
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Users"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
        />
      </Content>
      {visible && (
        <UserForm
          open={visible}
          closeModal={() => {
            isVisible(false);
            searchParams.delete('edit-user');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}

export default Users;
