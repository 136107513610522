import { OnboardingCreateType, OnboardingUpdateType } from 'types/Onboarding';
import { OnboardingQueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import agent from './agent';

import { apiURLs } from './constants';

export const getOnboarding = (id: string) =>
  agent.get(`${apiURLs.onBoarding}/${id}`);

export const getAllOnboardings = (queryParams?: OnboardingQueryParamsType) =>
  agent.post(
    `${apiURLs.onBoarding}/get`,
    searchFields(queryParams, apiURLs.onBoarding)
  );

export const getOnboardingById = (onboardingId: string) =>
  agent.get(`${apiURLs.onBoarding}/${onboardingId}`);

export const createOnboarding = (values: OnboardingCreateType) =>
  agent.post(apiURLs.onBoarding, values);

export const updateOnboarding = (id: string, values: OnboardingUpdateType) =>
  agent.patch(`${apiURLs.onBoarding}/${id}`, values);

export const deleteOnboarding = (id: string) =>
  agent.delete(`${apiURLs.onBoarding}/${id}`);
