import { Avatar, Col, List, Popconfirm, Row } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EmployeeEvaluationDataItem } from 'types/Evaluation';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import {
  ColItem,
  EmmployeeEvaluationItemDescription,
  EmployeeEvaluationContainer,
  EmployeeName,
  Evaluation,
  FeedbackText,
} from './EmployeeEvaluationItemStyled';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
const StyledCol = styled(Col)`
  margin-left: 30px;
`;
interface IProps {
  item: EmployeeEvaluationDataItem;
  onDelete: () => void;
  last?: boolean;
  onItemClick: (id: string) => void;
}
export function EmployeeEvaluationItem({
  onDelete,
  last,
  item,
  onItemClick,
}: IProps) {
  const [showMore, setShowMore] = useState(false);
  const toggle = () => setShowMore(!showMore);
  const handleItemClick = () => {
    onItemClick(item.id);
  };
  const handleDeleteButtonClick = (event: any) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };
  const { t } = useTranslation();
  return (
    <EmployeeEvaluationContainer last={last}>
      <ColItem span={24} onClick={handleItemClick}>
        <Evaluation gutter={[0, 4]} isLast>
          <Row gutter={[200, 0]}>
            <Col>
              <FeedbackText>
                {`${convertUTCtoFormatedLocalTime(
                  item?.startDate
                )} - ${convertUTCtoFormatedLocalTime(item?.endDate)}`}
              </FeedbackText>
            </Col>
            <StyledCol>
              <RoleBasedButton btn={BUTTONS.ADD_EVALUATION_DETAILS}>
                <Popconfirm
                  placement="topLeft"
                  title={t('areYouSureYouWanttoDelete')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={(e) => handleDeleteButtonClick(e)}
                >
                  <IconButton onClick={(e) => e.stopPropagation()}>
                    <DeleteIcon />
                  </IconButton>
                </Popconfirm>
              </RoleBasedButton>
            </StyledCol>
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={item?.feedback as any}
            style={{
              width: '100%',
            }}
            renderItem={(el: EmployeeEvaluationDataItem) => (
              <List.Item key={el.id}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        color: '#F56A00',
                        backgroundColor: '#FDE3CF',
                      }}
                    >
                      {`${el?.evaluator?.firstName
                        .substring(0, 1)
                        ?.toUpperCase()}${el?.evaluator?.lastName
                        .substring(0, 1)
                        ?.toUpperCase()}`}
                    </Avatar>
                  }
                  title={
                    <Row>
                      <EmployeeName>
                        {`${el?.evaluator?.firstName}
                  ${el?.evaluator?.lastName}`}
                      </EmployeeName>
                    </Row>
                  }
                  description={
                    <EmmployeeEvaluationItemDescription onClick={toggle}>
                      <Row>
                        <Col>
                          {showMore
                            ? item?.employeeFeedback
                            : `${item?.employeeFeedback.substring(0, 100)}`}
                        </Col>
                        {el.feedback.length > 100 && (
                          <Col
                            style={{
                              color: '#004EEB',
                              cursor: 'pointer',
                            }}
                          >
                            {showMore ? t('showLess') : t('showMore')}
                          </Col>
                        )}
                      </Row>
                    </EmmployeeEvaluationItemDescription>
                  }
                />
              </List.Item>
            )}
          />
        </Evaluation>
      </ColItem>
    </EmployeeEvaluationContainer>
  );
}
