import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

export const NoteItemContainer = styled.div<{ isLast: boolean }>`
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 10px;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;

export const NoteName = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 600;
  margin-left: 20px;
  margin-top: 20px;
`;

export const NoteDate = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 500;
`;

export const NoteText = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 400;
  margin-top: 20px;
`;
export const ColItem = styled(Col)<{ isLast: boolean }>`
  border-bottom: 1px solid #eaecf0;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;
export const Note = styled(Row)<{ isLast: boolean }>`
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    div,
    button {
      background: #fafafa;
    }
  }
`;
