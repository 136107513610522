import { Col, Form, Row } from 'antd';
import {
  createEmployeeNotes,
  updateEmployeeNotes,
} from 'api/employeeNotesService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { EmployeeNotesType } from 'types/EmployeeNotes';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { toastErrorMessages } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  id: string;
}

export default function AddNoteModal({ open, closeModal, id }: IProps) {
  const [form] = Form.useForm();
  const { employee } = useEmployeesData();
  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notes = employee?.employeeNotes;
  const note = useMemo(() => notes?.find((item) => item.id === id), [id]);
  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: 'input',
          defaultValue: note?.name,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('note'),
          type: 'textarea',
          defaultValue: note?.description,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [endDateDisabled, note]
  );

  const onSubmit = () => {
    if (loading || !employee?.id) return;

    setLoading(true);
    const formValues: EmployeeNotesType = form.getFieldsValue(true);

    if (id) {
      updateEmployeeNotes(id, formValues)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(fetchEmployeesDetail(employee?.id));
            toast.success(t('successfullyUpdatedNote'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createEmployeeNotes({
        employeeId: employee?.id,
        description: formValues.description,
        name: formValues.name,
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(fetchEmployeesDetail(employee?.id));
            toast.success(t('successfullyAddedNote'));
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
          closeModal();
        });
    }
  };

  const onFieldsChange = (values: any) => {
    if (values[0]?.name[0] === 'present') {
      setEndDateDisabled((state) => !state);
    }
  };

  return (
    <GenericModal
      title={id ? t('editNote') : t('addNote')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
