import { Badge } from 'antd';
import styled from 'styled-components';

export const StyledTenantApproveBadge = styled(Badge)<{ approved: string }>(
  ({ theme, approved }) => ({
    ...theme.typography.body.medium.xs,
    padding: '0.125rem 0.4rem',
    borderRadius: '8px',
    ...(approved === 'true'
      ? {
          backgroundColor: theme.colors.palette.primary.success['100'],
          color: theme.colors.palette.primary.success['700'],
        }
      : {
          backgroundColor: theme.colors.palette.primary.gray['200'],
          color: theme.colors.palette.primary.gray['700'],
        }),
  })
);
