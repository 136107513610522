import { PlusCircleOutlined } from '@ant-design/icons';
import { DeleteIcon } from 'Icons/DeleteIcon';
import EyeIcon from 'Icons/EyeIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import { Col, Popconfirm, Row, Spin } from 'antd';
import {
  createQuestionnaireAssignment,
  getQuestionnaireAssignment,
  removeQuestionnaireAssignment,
  updateQuestionnaireAssignment,
} from 'api/questionnaireAssignmentService';
import { getQuestionnaireAssignmentEmployees } from 'api/questionnairesService';
import { IconButton } from 'components/Actions/AssetAction';
import { StyledButton } from 'components/NewForms/FormStyled';
import { StyledSelect } from 'components/common/DataGrid/DataGridStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmployeeType } from 'types/Employee';
import CardItem from 'ui-v2/components/Card';
import GenericModal from 'ui-v2/components/GenericModal';
import { ItemLabel } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { toastErrorMessages } from 'utils/utilFunctions';
import ViewFeedBackModal from './ViewFeedbackModal';

const QuestionnaireAssignment: React.FC = () => {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState<EmployeeType[]>([]);
  const [visible, setVisible] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const questionnaireId = searchParams.get('id');
  const [loading, setLoading] = useState(false);
  const [selectedReviewerId, setSelectedReviewerId] = useState<string>();
  const [selectedRevieweesId, setSelectedRevieweesId] = useState<Array<string>>(
    []
  );
  const [selectedFeedbackReviewee, setSelectedFeedbackReviewee] =
    useState<EmployeeType | null>();
  const [selectedFeedbackReviewer, setSelectedFeedbackReviewer] =
    useState<EmployeeType | null>();
  const [editingId, setEditingId] = useState<string | null>(null);

  const showFeedbackModal = (
    reviewee: EmployeeType,
    reviewer: EmployeeType
  ) => {
    setSelectedFeedbackReviewee(reviewee);
    setSelectedFeedbackReviewer(reviewer);
    setIsFeedbackModalVisible(true);
  };

  const [questionnaireAssignments, setQuestionnaireAssignments] =
    useState<any>();

  const fetchQuestionnaireAssignment = useCallback(async () => {
    if (!questionnaireId) return;
    setLoading(true);
    getQuestionnaireAssignment(questionnaireId)
      .then((response) => {
        setQuestionnaireAssignments(response.data);
      })
      .catch(() => {
        toast.error(t('Could not take employee data'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [questionnaireId]);

  const fetchListEmployees = useCallback(async () => {
    if (!questionnaireId || employeeList?.length) return;
    setLoading(true);
    getQuestionnaireAssignmentEmployees(questionnaireId)
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch(() => {
        toast.error(t('Could not take employee data'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [questionnaireId, employeeList]);

  const createQuestionnaireAssignmentFunc = async (
    reviwerId: string,
    reviweesIds: Array<string>
  ) => {
    if (!questionnaireId) {
      return;
    }
    setLoading(true);
    createQuestionnaireAssignment({ reviwerId, reviweesIds, questionnaireId })
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('questionnaireAssignmentCreatedSuccessfully'));
          setQuestionnaireAssignments(res?.data);
          fetchQuestionnaireAssignment();
        }
      })
      .catch(toastErrorMessages)
      .finally(() => setLoading(false));
  };

  const updateQuestionnaireAssignmentFunc = async (
    id: string,
    reviwerId: string,
    reviweesIds: Array<string>
  ) => {
    if (!questionnaireId) {
      return;
    }
    setLoading(true);
    updateQuestionnaireAssignment(id, {
      reviwerId,
      reviweesIds,
      questionnaireId,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('questionnaireAssignmentCreatedSuccessfully'));
          setQuestionnaireAssignments(res?.data);
          fetchQuestionnaireAssignment();
        }
      })
      .catch(toastErrorMessages)
      .finally(() => {
        setLoading(false);
        setEditingId('');
      });
  };

  const deleteQuestionnaireAssignment = async (id: string) => {
    if (!id || !questionnaireId) {
      return;
    }
    setLoading(true);
    removeQuestionnaireAssignment(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('questionnaireAssignmentRemovedSuccessfully'));
          fetchQuestionnaireAssignment();
        }
      })
      .catch(toastErrorMessages)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (questionnaireId) {
      fetchQuestionnaireAssignment();
      fetchListEmployees();
    }
  }, [questionnaireId]);

  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
    setEditingId('');
  }, [visible]);

  const onSelect = (id: any) => {
    setSelectedReviewerId(id as string);
  };

  const onSelectReviewees = (ids: any) => {
    setSelectedRevieweesId(ids);
  };

  const onSubmit = useCallback(async () => {
    if (!selectedReviewerId || selectedRevieweesId.length === 0) {
      toast.error('Reviewer and Reviewees are required.');
      return;
    }

    if (editingId) {
      updateQuestionnaireAssignmentFunc(
        editingId,
        selectedReviewerId,
        selectedRevieweesId
      );
    } else {
      await createQuestionnaireAssignmentFunc(
        selectedReviewerId,
        selectedRevieweesId
      );
    }

    toggleVisibility();
  }, [selectedReviewerId, selectedRevieweesId]);

  const onEdit = async (id: string) => {
    if (!id) {
      return;
    }

    setEditingId(id);
    const editedAssignment = questionnaireAssignments.find(
      (assignment: any) => assignment.id === id
    );

    if (!editedAssignment) {
      return;
    }

    setSelectedReviewerId(editedAssignment.reviewer.id);
    setSelectedRevieweesId(
      editedAssignment.reviewees.map((reviewee: any) => reviewee.id)
    );

    setVisible(true);
  };

  return (
    <>
      <CardItem title={t('questionnaireAssignment')}>
        <Row gutter={[16, 16]}>
          {questionnaireAssignments?.length &&
            questionnaireAssignments?.map((el: any) => (
              <Col
                span={24}
                key={el.id}
                style={{
                  borderBottom: '1px solid #f0f0f0',
                  paddingBottom: '16px',
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <b>{t('Reviewer')}</b>
                    <br />
                    <Link
                      to={`/employees/employee-profile?id=${el.reviewer.id}`}
                    >
                      <ItemLabel>{`${el.reviewer.firstName} ${el.reviewer.lastName}`}</ItemLabel>
                    </Link>
                  </Col>
                  <Col>
                    <IconButton
                      style={{ marginRight: '1rem' }}
                      onClick={() => onEdit(el.id)}
                    >
                      <EdititingIcon />
                    </IconButton>
                    <Popconfirm
                      title={t('areYouSureYouWanttoDelete')}
                      onConfirm={() => deleteQuestionnaireAssignment(el.id)}
                      cancelText={t('cancel')}
                      okText={t('yes')}
                    >
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Popconfirm>
                  </Col>
                </Row>
                <Row style={{ marginTop: '8px' }}>
                  <Col span={24}>
                    <b>{t('reviewees')}</b>
                    {el?.reviewees?.map((reviewee: EmployeeType) => (
                      <div
                        key={reviewee.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginLeft: '16px',
                          marginTop: '4px',
                        }}
                      >
                        <Link
                          to={`/employees/employee-profile?id=${reviewee.id}`}
                        >
                          <ItemLabel>{`${reviewee.firstName} ${reviewee.lastName}`}</ItemLabel>
                        </Link>
                        <IconButton
                          onClick={() =>
                            showFeedbackModal(reviewee, el.reviewer)
                          }
                        >
                          <EyeIcon />
                        </IconButton>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>
            ))}
          <Col
            span={24}
            key="assign-questionnaire"
            style={{ marginTop: '16px' }}
          >
            <StyledButton
              onClick={() => setVisible(true)}
              style={{ width: '100%' }}
            >
              <Row justify="center" align="middle" gutter={[10, 0]}>
                <Col>
                  <ItemLabel>{t('assignQuestionnaire')}</ItemLabel>
                </Col>
                <Col>
                  <PlusCircleOutlined />
                </Col>
              </Row>
            </StyledButton>
          </Col>
        </Row>
      </CardItem>
      {isFeedbackModalVisible && (
        <ViewFeedBackModal
          visible={isFeedbackModalVisible}
          revieweeId={selectedFeedbackReviewee?.id}
          reviewerId={selectedFeedbackReviewer?.id}
          modalTitle={`${t('feedback for')}: ${
            selectedFeedbackReviewee?.firstName
          } ${selectedFeedbackReviewee?.lastName}`}
          onCancel={() => {
            setIsFeedbackModalVisible(false);
            setSelectedFeedbackReviewee(null);
          }}
          questionnaireId={questionnaireId}
        />
      )}

      {visible && (
        <GenericModal
          open={visible}
          closeModal={toggleVisibility}
          title={t('assignQuestionnaire')}
        >
          <Spin spinning={loading}>
            <Row gutter={[0, 30]}>
              <Col span={24}>
                <StyledSelect
                  className="ant-styled-select"
                  placeholder={t('selectReviwer')}
                  onSelect={onSelect}
                  showSearch
                  defaultValue={
                    editingId
                      ? {
                          id: selectedReviewerId,
                          label: `${
                            employeeList?.find(
                              (el) => el.id === selectedReviewerId
                            )?.firstName
                          } ${
                            employeeList?.find(
                              (el) => el.id === selectedReviewerId
                            )?.lastName
                          }`,
                          value: selectedReviewerId,
                        }
                      : undefined
                  }
                  options={employeeList?.map((el) => ({
                    id: el.id,
                    label: `${el.firstName} ${el.lastName}`,
                    value: el.id,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
                <StyledSelect
                  style={{ marginTop: '2rem' }}
                  className="ant-styled-select"
                  placeholder={t('selectReviewees')}
                  mode="multiple"
                  disabled={!selectedReviewerId}
                  onChange={onSelectReviewees}
                  showSearch
                  defaultValue={
                    editingId
                      ? selectedRevieweesId.map((id) => ({
                          id,
                          label: `${
                            employeeList?.find((el) => el.id === id)?.firstName
                          } ${
                            employeeList?.find((el) => el.id === id)?.lastName
                          }`,
                          value: id,
                        }))
                      : undefined
                  }
                  options={employeeList
                    ?.filter((emp) => emp.id !== selectedReviewerId)
                    ?.map((el) => ({
                      id: el.id,
                      label: `${el.firstName} ${el.lastName}`,
                      value: el.id,
                    }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Col span={11} onClick={toggleVisibility}>
                    <StyledButton danger>{t('cancel')}</StyledButton>
                  </Col>
                  <Col span={11}>
                    <StyledButton type="primary" onClick={onSubmit}>
                      {t('submit')}
                    </StyledButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </GenericModal>
      )}
    </>
  );
};

export default QuestionnaireAssignment;
