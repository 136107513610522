import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchProjectOptions } from 'components/NewForms/SelectWithLoad/utils';

import { ProjectQueryParamsType } from 'types/QueryParams';
import { fetchProjects } from 'redux/projects/actions';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function ProjectFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function onFormChange() {
    const projectId = form.getFieldValue('projectId')?.value;

    const filterProjectTable: ProjectQueryParamsType = {
      projectId,
    };

    if (filterProjectTable) {
      if (projectId) dispatch(fetchProjects({ projectId }));
    }
  }

  const EmployeesConfig: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'projectId',
          label: t('projects'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchProjectOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={EmployeesConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchProjects({ page: 1, take: 10 }));
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
