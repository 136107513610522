import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useHeader from 'ui-v2/hooks/useHeader';

import { setTableConfig } from 'redux/tableConfig/actions';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { ColumnsType } from 'antd/es/table';
import { RootState } from 'redux/store';
import SelectComponent from 'components/NewForms/Select';
import { configRecordsSelectOptions } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import * as Styles from './ConfigureComponentStyles';
import { getTblConfig } from './helpers';

export default function ConfigureComponent() {
  const { entity } = useHeader();
  const { config, take }: any = useSelector(
    (state: RootState) => state.tableConfigReducer
  );
  const [checkedList, setCheckedList] = useState<ColumnsType>(
    config[entity] ?? getTblConfig(entity)
  );
  const [currentSelectedKey, setCurrentSelectedKey] = useState<string>();
  const totalOptions: ColumnsType = getTblConfig(entity);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTableConfig({
        entity,
        columns: checkedList,
      })
    );
  }, [checkedList]);

  const onChange = (e: CheckboxChangeEvent) => {
    if (checkedList.map((i: any) => i.key).includes(e.target.name)) {
      const foundKey = Object.keys(checkedList).find(
        (key: any) => checkedList[key].key === e.target.name
      );
      setCurrentSelectedKey(foundKey);
      setCheckedList(checkedList.filter((i: any) => i.key !== e.target.name));
      return;
    }

    const updateCheckedList = [...checkedList];
    const column = totalOptions.find((i: any) => i.key === e.target.name);
    if (column) {
      updateCheckedList.splice(Number(currentSelectedKey), 0, column);
    }

    setCheckedList(updateCheckedList);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked
        ? totalOptions
        : totalOptions.filter((item: any) => item.default)
    );
  };

  const onRecordCleared = () => {
    dispatch(
      setTableConfig({
        entity,
        columns: checkedList,
        take: 10,
      })
    );
  };

  const onRecordSelected = (id: string) => {
    dispatch(
      setTableConfig({
        entity,
        columns: checkedList,
        take: Number(id),
      })
    );
  };

  const { t } = useTranslation();
  return (
    <Styles.Container gutter={[0, 10]}>
      <Styles.CheckBox
        onChange={onCheckAllChange}
        checked={totalOptions.length === checkedList.length}
      >
        {t('selectAll')}
      </Styles.CheckBox>
      <Styles.Divider />
      <Row gutter={[0, 10]}>
        {totalOptions.map((item: any) => {
          if (!item.default) {
            return (
              <Col span={24}>
                <Checkbox
                  name={item.key}
                  onChange={onChange}
                  checked={!!checkedList.find((i: any) => i?.key === item?.key)}
                >
                  <Styles.Label>
                    {item.title === '' ? t('actions') : item.title}
                  </Styles.Label>
                </Checkbox>
              </Col>
            );
          }
          return null;
        })}
      </Row>
      <Styles.Divider />
      <Styles.Container align="middle">
        <Col span={24}>
          <SelectComponent
            placeholder={t('records')}
            name="records"
            showSearch
            onClear={onRecordCleared}
            onSelect={onRecordSelected}
            defaultValue={String(take)}
            selectOptions={configRecordsSelectOptions}
          />
        </Col>
      </Styles.Container>
    </Styles.Container>
  );
}
