import { Reducer } from 'react';
import { Action } from 'redux';

import { OpenPositions, OpenPositionsState } from './types';

const initialState: OpenPositionsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<OpenPositionsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case OpenPositions.FETCH_OPEN_POSITION_START:
      state = { ...state, loading: true };
      break;
    case OpenPositions.FETCH_OPEN_POSITION_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case OpenPositions.FETCH_OPEN_POSITION_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as OpenPositionsReducer };
