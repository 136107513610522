import { Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changeUserPassword } from 'api/hooks/changePasswordService';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { getUserPasswordRules } from 'api/openRoutesService';
import { useTranslation } from 'react-i18next';
import {
  FormItemStyled,
  StyledEyeBUtton,
  StyledInput,
  StyledLabel,
} from '../FormStyled';

interface IProps {
  userId: string | undefined;
}

function ChangeUserPasswordForm({ userId }: IProps) {
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [rules, setRules] = useState<string[]>();
  const [regex, setRegex] = useState();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { t } = useTranslation();

  const getPasswordRules = async () => {
    try {
      const { data } = await getUserPasswordRules();
      setRegex(data.regex);
      setRules(data.rules);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPasswordRules();
  }, []);

  async function onFormFinish(values: any) {
    isLoading(true);

    if (values.newPassword?.length < 6) {
      toast.error(t('passwordMustContainAtLeast6Characters'));
      isLoading(false);
      return;
    }

    try {
      if (userId) {
        await changeUserPassword({ ...values, userId }).then((response) => {
          if (response.status === 201 && response.data) {
            toast.success(t('successfullyChangedPassword!'));
            navigate('/my-profile');
          }
        });
      }
    } catch (error) {
      toast.error(t('errorDuringThisAction'));
      isLoading(false);
    }
  }
  useEffect(() => {
    if (searchParams.get('change-password-save') === 'true') {
      searchParams.delete('change-password-save');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <CardItem title="">
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => onFormFinish(values)}
          validateTrigger="onChange"
        >
          <FormItemStyled
            name="password"
            label={t('currentPassword')}
            rules={[
              {
                required: true,
                message: t('Please enter your password!'),
              },
            ]}
            validateStatus="validating"
          >
            <StyledLabel>
              <StyledInput
                type={showCurrentPassword ? 'text' : 'password'}
                placeholder={t('currentPassword')}
              />
              <StyledEyeBUtton
                onClick={() => setShowCurrentPassword((state) => !state)}
              >
                {showCurrentPassword ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )}
              </StyledEyeBUtton>
            </StyledLabel>
          </FormItemStyled>
          <FormItemStyled
            name="newPassword"
            label={t('newPassword')}
            rules={[
              {
                required: true,
                message: t('Please enter your new password!'),
              },
              {
                validator(_: any, value: any) {
                  if (value && regex && new RegExp(regex).test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(rules?.join('')));
                },
              },
            ]}
            validateStatus="validating"
          >
            <StyledLabel>
              <StyledInput
                type={showNewPassword ? 'text' : 'password'}
                placeholder={t('newPassword')}
              />
              <StyledEyeBUtton
                onClick={() => setShowNewPassword((state) => !state)}
              >
                {showNewPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </StyledEyeBUtton>
            </StyledLabel>
          </FormItemStyled>
          <FormItemStyled
            name="confirmPassword"
            label={t('confirmPassword')}
            rules={[
              {
                required: true,
                message: t('Please confirm your password'),
              },
              {
                validator(_: any, value: any) {
                  if (!value || form.getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('Passwords are not matching'))
                  );
                },
              },
            ]}
            validateStatus="validating"
          >
            <StyledLabel>
              <StyledInput
                type={showConfirmNewPassword ? 'text' : 'password'}
                placeholder={t('newPassword')}
              />
              <StyledEyeBUtton
                onClick={() => setShowConfirmNewPassword((state) => !state)}
              >
                {showConfirmNewPassword ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )}
              </StyledEyeBUtton>
            </StyledLabel>
          </FormItemStyled>
        </Form>
      </Spin>
    </CardItem>
  );
}

export default ChangeUserPasswordForm;
