import { Typography } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import LoginForm from 'components/NewForms/LoginForm';
import { batch, useDispatch, useSelector } from 'react-redux';
import { onLogin, onLogout, onSuperAdminLogin } from 'redux/authUser/actions';
import { AuthUserCredentials, AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import TwoFactorAuthenticationPage from 'ui-v2/components/TwoFA/TwoFactorAuthenticationPage';
import { useLocation, useNavigate } from 'react-router-dom';
import TwoFactorAuthenticationSetupPage from 'ui-v2/components/TwoFA/TwoFactorAuthenticationSetupPage';
import { AUTH_ROLES } from 'types/Auth';
import { IS_LOGGEDIN } from 'utils/constants';
import useTenantStore from 'redux/persisted-state/tenant-store';

export const RegisterLink = styled(Typography.Link)``;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { clearTenant } = useTenantStore();

  useEffect(() => {
    dispatch(onLogout());
    clearTenant();
  }, []);

  const { twoFactorRequired, twoFactorSetupRequired, authUserRole, loading } =
    authUserState;
  const isSuperAdminLoginPage = location.pathname.includes('super');
  const isLoggedIn = localStorage.getItem(IS_LOGGEDIN);

  if (isLoggedIn && !loading) {
    switch (authUserRole) {
      case AUTH_ROLES.AMDIN:
        navigate('/dashboard');
        break;
      case AUTH_ROLES.HR:
        navigate('/dashboard');
        break;
      case AUTH_ROLES.EMPLOYEE:
        navigate('/employee-details');
        break;
      case AUTH_ROLES.SUPER_ADMIN:
        navigate('/tenants');
        break;
      case AUTH_ROLES.GUEST:
        navigate('/tracking');
        break;
      case AUTH_ROLES.GUEST_TRACKING_HOURS:
        navigate('/tracking');
        break;
      case AUTH_ROLES.EDITOR:
        navigate('/tracking');
        break;
      default:
        break;
    }
  }

  const onSubmit = async (credentials: AuthUserCredentials) => {
    const { email, password } = credentials;
    if (!email || !password) return;
    batch(() => {
      if (isSuperAdminLoginPage) {
        dispatch(onSuperAdminLogin(credentials));
      } else {
        dispatch(onLogin(credentials));
      }
    });
  };

  if (twoFactorSetupRequired) {
    return <TwoFactorAuthenticationSetupPage />;
  }

  if (twoFactorRequired) {
    return <TwoFactorAuthenticationPage />;
  }
  return <LoginForm onSubmit={onSubmit} />;
};

export default Login;
