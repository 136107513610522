import React, { useEffect, useState } from 'react';
import { Col, Row, Steps } from 'antd';
import CardItem from 'ui-v2/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { EMPLOYEE_ID } from 'utils/constants';
import { toastErrorMessages } from 'utils/utilFunctions';
import {
  getEmployeeJobContract,
  getEmployeeNDA,
  signEmployeeJobContract,
  signEmployeeNDA,
  updateEmployeeOnboardingStatus,
} from 'api/employeeService';
import { pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';

import { getAuthUser } from 'redux/authUser/actions';
import { ItemLabel } from 'ui-v2/components/Employees/Onboarding/EmployeeOnboardingTaskItem/EmployeeOnboardingTaskItemStyles';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import { OnboardingStepBtn } from '../StyledOnboardingSteps';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

export default function ContractAndConfidenatialityAgreement() {
  const authUserState = useSelector((state: any) => state.authUser);
  const [jobContract, setJobContract] = useState<any>();
  const [employeeNDA, setEmployeeNDA] = useState<any>();
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const employeeId =
    authUserState?.employee?.id ||
    (localStorage.getItem(EMPLOYEE_ID) as string);

  const nextBtnText = 'Finish';

  const onNextBtnClick = async () => {
    await signEmployeeJobContract(employeeId)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Successfully signed the contract');
          setStep((step) => step + 1);
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      });
  };
  const { Step } = Steps;

  const onOnBoardingFinish = async () => {
    await signEmployeeNDA(employeeId)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Successfully signed NDA');
          updateEmployeeOnboardingStatus(employeeId)
            .then((response) => {
              if (response.status === 200) {
                toast.success('Onboarding completed successfully!');
                dispatch(getAuthUser());
                navigate('/employee-details');
              }
            })
            .catch((error) => {
              toastErrorMessages(error);
            });
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      });
  };

  const getJobContract = async (id: string) => {
    try {
      const response = await getEmployeeJobContract(id);
      setJobContract(response?.data?.fileUrl);
    } catch (error) {
      toastErrorMessages(error);
    }
  };
  const getNDA = async (id: string) => {
    try {
      const response = await getEmployeeNDA(id);
      setEmployeeNDA(response?.data?.fileUrl);
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getJobContract(employeeId);
      getNDA(employeeId);
    }
  }, [employeeId]);
  return (
    <CardItem title="">
      <>
        <Steps
          current={step}
          onChange={setStep}
          style={{ marginBottom: '50px' }}
        >
          <Step title="Job Contract" disabled />
          <Step title="Confidenatiality Agreement" disabled />
        </Steps>
        <Row gutter={[0, 32]}>
          {step === 0 && (
            <Col span={24}>
              <Row>
                <PDFViewer file={jobContract} />
              </Row>
            </Col>
          )}
          {step === 1 && (
            <Col span={24}>
              <Row>
                <PDFViewer file={employeeNDA} />
              </Row>
            </Col>
          )}
          <Col offset={21}>
            <OnboardingStepBtn
              type="primary"
              onClick={() => {
                if (step === 0) {
                  onNextBtnClick();
                } else if (step === 1) {
                  onOnBoardingFinish();
                }
              }}
            >
              <Row align="middle" gutter={[10, 0]}>
                <Col>
                  <ItemLabel>
                    {`${step === 0 ? 'Agree' : nextBtnText}`}
                  </ItemLabel>
                </Col>
                {step === 0 || (
                  <Col>
                    <RightOutlined />
                  </Col>
                )}
              </Row>
            </OnboardingStepBtn>
          </Col>
        </Row>
      </>
    </CardItem>
  );
}
