import agent from 'api/agent';
import { PayrollDatesDTO, PayrollReportRequestDTO } from 'types/PayrollReport';
import { apiURLs } from './constants';

export const getPayrollReportSingleEmployee = (
  payload: PayrollReportRequestDTO
) => agent.post(`${apiURLs.payrollReport}/single`, payload);

export const getPayrollReport = (payload: PayrollReportRequestDTO) =>
  agent.post(`${apiURLs.payrollReport}`, payload);

export const getPayrollReportByDate = (
  date: string,
  tenantLocationId: string
) => agent.get(`${apiURLs.payrollReport}/${date}/${tenantLocationId}`);

export const getPayrollDates = (payload?: PayrollDatesDTO) =>
  agent.post(`${apiURLs.payrollReport}/getDates`, payload);

export const deletePayrollReport = (id: string) =>
  agent.delete(`${apiURLs.payrollReport}/${id}`);
