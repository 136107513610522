import { Rate, Typography } from 'antd';
import styled from 'styled-components';

export const ReviewEmployee = styled.div`
  width: 100%;
`;

export const ReviewQuestionsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewTitle = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;

export const ReviewQuestion = styled(Typography)`
  font-size: 14px;
`;

export const StyledRate = styled(Rate)`
  color: #85e13a;
  display: flex;
  font-size: 30px;
  justify-content: start;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;
