/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Col, Row } from 'antd';

import GenericModal from 'ui-v2/components/GenericModal';
import { AssignTaskIcon } from 'Icons/AssignTaskIcon';
import {
  EmployeeTaskModelType,
  EmployeeTaskStatusOptions,
  EmployeeTaskUpdateType,
} from 'types/EmployeeTask';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { Item } from 'ui-v2/components/PersonalInfo';
import DropDown from 'ui-v2/components/DropDown';
import { updateEmployeeTask } from 'api/employeeTaskService';
import { toast } from 'react-toastify';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';

export default function EmployeeOnboardingTaskViewModal({
  task,
  open,
  closeModal,
}: {
  task?: EmployeeTaskModelType;
  open: boolean;
  closeModal: () => void;
}) {
  const [status, setStatus] = useState<string>(task?.status || '');
  const { t } = useTranslation();

  const onUpdateTaskStatus = (status: string) => {
    if (!task?.id || !status) return;
    updateEmployeeTask(task?.id, { status } as EmployeeTaskUpdateType)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('Task status changed successfully'));
        }
      })
      .catch(() => {
        toast.error(t('Failed to update task status'));
      });
  };

  const handleStatusChange = (selectedStatus: string) => {
    setStatus(selectedStatus);
    onUpdateTaskStatus(selectedStatus);
  };

  return (
    <GenericModal
      title={t('viewTaskDetails')}
      open={open}
      closeModal={closeModal}
      icon={<AssignTaskIcon />}
    >
      <>
        <Row gutter={[0, 20]}>
          <CardItem
            title={t('employeeTaskStatus')}
            rightButton={
              <Row justify="end">
                <Col span={24}>
                  <DropDown
                    status={status.toLocaleLowerCase()}
                    items={EmployeeTaskStatusOptions.map((item: any) => ({
                      label: item.label,
                    }))}
                    onChange={(status) => {
                      handleStatusChange(status.label);
                    }}
                  >
                    <>{status}</>
                  </DropDown>
                </Col>
              </Row>
            }
          >
            <Row gutter={[0, 16]}>
              <Styled.RowItem />
              <Styled.ItemValue>{t('onboardingTaskDetails')}</Styled.ItemValue>
              <Styled.RowItem gutter={[0, 10]} last>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('taskType')}
                    value={
                      task?.onBoardingTasks?.boardingTaskType || t('noType')
                    }
                    children={undefined}
                  />
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('category')}
                    value={task?.onBoardingTasks?.category || t('noCategory')}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
            </Row>
          </CardItem>
        </Row>
      </>
    </GenericModal>
  );
}
