import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { Id } from 'types/Id';
import { useNavigate } from 'react-router-dom';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { ArchieveTableIcon } from 'Icons/ArchieveTableIcon';
import styled from 'styled-components';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchEmployees } from 'redux/employees/actions';
import { removeEmployee } from 'api/employeeService';
import { useTranslation } from 'react-i18next';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const EmployeeActions: React.FC<Id> = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pagination } = useEmployeesData();

  const onViewClick = () => {
    navigate(`employee-profile?id=${id}`);
  };

  const onArchieveClick = async () => {
    const response = await removeEmployee(id);

    if (response.status === 200) {
      toast.success(t('archivedEmployee'));
      dispatch(fetchEmployees({ page: pagination?.page || 1, take: 10 }));
      return;
    }

    toast.warning(t('Could not archieve this employee!'));
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <IconButton onClick={onViewClick}>
              <ViewProfileIcon />
            </IconButton>
          </Col>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('Are you sure you want to archive this employee?')}
                onConfirm={onArchieveClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <ArchieveTableIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default EmployeeActions;
