import styled from 'styled-components';
import { Row, Typography } from 'antd';

export const RowItemAsset = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
`;

export const NodataLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;

export const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
