import { Action } from 'redux';
import { Reducer } from 'react';

import { Tenants, TenantsState } from './types';

const initialSingleTenantDetailsState: TenantsState = {
  tenants: {
    data: [],
    meta: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  singleTenanat: {
    data: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  tenantAccounts: {
    data: [],
    meta: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
};

const TenantsReducer: Reducer<TenantsState, Action> = (
  state = initialSingleTenantDetailsState,
  action: any
) => {
  switch (action.type) {
    case Tenants.FETCH_TENANTS_START:
      state = {
        ...state,
        tenants: {
          ...state.tenants,
          loading: true,
          loaded: false,
        },
      };
      break;
    case Tenants.FETCH_TENANTS_SUCCESS:
      state = {
        ...state,
        tenants: {
          ...state.tenants,
          loading: false,
          loaded: true,
          data: action?.payload?.data,
          meta: action?.payload?.meta,
        },
      };
      break;
    case Tenants.FETCH_TENANTS_ERRORED:
      state = {
        ...state,
        tenants: {
          ...state.tenants,
          loading: false,
          loaded: false,
          errored: true,
          error: action?.payload,
        },
      };
      break;

    case Tenants.FETCH_SINGLE_TENANT_START:
      state = {
        ...state,
        singleTenanat: {
          ...state.singleTenanat,
          loading: true,
          loaded: false,
        },
      };
      break;
    case Tenants.FETCH_SINGLE_TENANT_SUCCESS:
      state = {
        ...state,
        singleTenanat: {
          ...state.singleTenanat,
          loading: false,
          loaded: true,
          data: action?.payload,
        },
      };
      break;
    case Tenants.FETCH_SINGLE_TENANT_ERRORED:
      state = {
        ...state,
        singleTenanat: {
          ...state.singleTenanat,
          loading: false,
          loaded: false,
          errored: true,
          error: action?.payload,
        },
      };
      break;

    case Tenants.FETCH_TENANT_ACCOUNTS_START:
      state = {
        ...state,
        tenantAccounts: {
          ...state.tenantAccounts,
          loading: true,
          loaded: false,
        },
      };
      break;
    case Tenants.FETCH_TENANTS_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        tenantAccounts: {
          ...state.tenantAccounts,
          loading: false,
          loaded: true,
          data: action?.payload?.data,
          meta: action?.payload?.meta,
        },
      };
      break;
    case Tenants.FETCH_TENANTS_ACCOUNTS_ERRORED:
      state = {
        ...state,
        tenantAccounts: {
          ...state.tenantAccounts,
          loading: false,
          loaded: false,
          errored: true,
          error: action?.payload,
        },
      };
      break;
    case Tenants.CLEAR_TENANTS:
      state = {
        ...state,
        tenants: initialSingleTenantDetailsState.tenants,
      };
      break;
    case Tenants.CLEAR_SINGLE_TENANT:
      state = {
        ...state,
        singleTenanat: initialSingleTenantDetailsState.singleTenanat,
      };
      break;
    case Tenants.CLEAR_TENANT_ACCOUNTS:
      state = {
        ...state,
        tenantAccounts: initialSingleTenantDetailsState.tenantAccounts,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { TenantsReducer };
