import moment from 'moment';
import React from 'react';
import EmployeeActions from 'components/Actions/EmployeeActions';
import { EmployeeType } from 'types/Employee';
import FirsRow from 'ui-v2/components/Table/FirsRow';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { convertUTCtoLocalTime } from 'utils/utilFunctions';
import i18n from '../i18n';

export const EmployeesColums = [
  {
    title: i18n.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    width: 200,
    sorter: (a: EmployeeType, b: EmployeeType) =>
      a.firstName.localeCompare(b.firstName),

    render: (record: string, row: EmployeeType) => (
      <FirsRow
        main={record}
        seccond={row.onBoardingStatus}
        key={row.id}
        employeeId={row.id}
      />
    ),
    default: true,
    order: 0,
  },
  {
    title: i18n.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    width: 200,
    sorter: (a: EmployeeType, b: EmployeeType) =>
      a.lastName.localeCompare(b.lastName),
    default: true,
    order: 1,
  },
  {
    title: i18n.t('workEmail'),
    dataIndex: 'workEmail',
    key: 'workEmail',
    width: 200,
    sorter: (a: EmployeeType, b: EmployeeType) =>
      a?.workEmail.localeCompare(b?.workEmail),
    ellipsis: true,
    default: true,
    order: 2,
  },
  {
    title: i18n.t('workPosition'),
    dataIndex: 'workPosition',
    key: 'workPosition',
    ellipsis: true,
    render: (_record: string, row: EmployeeType) => (
      <Styled.Text key={`${row.id}`}>{row?.workPosition?.name}</Styled.Text>
    ),
    default: false,
    width: 120,
    order: 3,
  },
  {
    title: i18n.t('ssn'),
    dataIndex: 'ssn',
    key: 'ssn',
    width: 150,
    default: false,
    order: 4,
  },
  {
    title: i18n.t('phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    render: (_record: string, row: EmployeeType) =>
      `${row?.phoneNumberPrefix || ''} ${row?.phoneNumber}`,
    width: 220,
    default: false,
    ellipsis: true,
    order: 5,
  },
  {
    title: i18n.t('age'),
    dataIndex: 'birthDate',
    key: 'age',
    width: 150,
    sorter: (a: EmployeeType, b: EmployeeType) =>
      a.birthDate.localeCompare(b.birthDate),
    render: (record: string) =>
      `${moment().diff(convertUTCtoLocalTime(record), 'years', false)} years`,
    default: false,
    order: 6,
  },
  {
    title: i18n.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (record: string, row: EmployeeType) => (
      <EmployeeActions id={row.id} />
    ),
    default: false,
    order: 7,
    fixed: 'right',
  },
];
