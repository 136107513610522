import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchOnBoardings } from 'redux/onboardings/actions';
import { OnboardingQueryParamsType } from 'types/QueryParams';
import { fetchWorkPositionsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function OnboardingsFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function onFormChange() {
    const workPositions: string[] =
      [form.getFieldValue('workPosition')?.value] ?? [];

    const filterOnboardingsTable: OnboardingQueryParamsType = {
      workPositions,
    };
    dispatch(fetchOnBoardings(filterOnboardingsTable));
  }

  const OnboardingFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'workPosition',
          label: t('workPositions'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchWorkPositionsOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={OnboardingFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchOnBoardings());
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
