import React from 'react';
import {
  EmployeeAssignmnetsType,
  EmployeeFormType,
  EmployeeType,
} from 'types/Employee';
import CreateEmployeeForm from './forms/CreateEmployeeForm';
import EditEmployeeForm from './forms/EditEmployeeForm';

interface IProps {
  type: string;
  employee: EmployeeType | undefined;
  assignments: EmployeeAssignmnetsType | undefined;
}

function GenericFormSwitcher({ type, employee, assignments }: IProps) {
  switch (type) {
    case EmployeeFormType.EDIT:
      return (
        <EditEmployeeForm
          employee={employee}
          workPosition={assignments?.workPosition}
          currencyId={assignments?.salaryEmployee?.id}
        />
      );

    default:
      return <CreateEmployeeForm />;
  }
}

export default GenericFormSwitcher;
