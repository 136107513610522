import { Col, Space } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';
import React, { useState } from 'react';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import {
  deleteCanidateOffer,
  updateCanidateOffer,
} from 'api/openRoutesService';
import { ActionStatus } from '../../OfferAccept';

type IProps = {
  id: string;
  isAccepted: boolean;
  token?: string;
  setLoading: (loading: boolean) => void;
  setActionStatus: (status: ActionStatus) => void;
};

export default function OfferButtons({
  id,
  isAccepted,
  token,
  setLoading,
  setActionStatus,
}: IProps) {
  const [confirmed, isConfirmed] = useState<boolean>(false);

  const toggleConfirm = () => {
    isConfirmed((confirmed) => !confirmed);
    setActionStatus({ isDeclineView: !confirmed });
  };

  async function removeOffer() {
    setLoading(true);
    if (!token) return;
    deleteCanidateOffer(id, token)
      .then((response) => {
        if (response.status === 200) {
          setActionStatus({ declined: true });
        }
      })
      .catch(() => {
        setActionStatus({
          errored: true,
        });
      });
  }

  async function acceptOffer() {
    if (!token) return;
    setLoading(true);
    updateCanidateOffer(id, token, { isAccepted: true })
      .then((response) => {
        if (response.status === 200) {
          setActionStatus({ accepted: true });
        }
      })
      .catch(() => {
        setActionStatus({
          errored: true,
        });
      });
  }

  return (
    <Styled.RowItem justify="end" last>
      <Col>
        {!confirmed && !isAccepted ? (
          <Space>
            <StyledButton danger onClick={toggleConfirm}>
              Decline
            </StyledButton>
            <StyledButton type="primary" onClick={acceptOffer}>
              Accept
            </StyledButton>
          </Space>
        ) : (
          <Styled.RowItem last align="middle">
            <Col span={24}>
              <Space>
                <Styled.ItemLabel italic>
                  {isAccepted
                    ? 'You have already accepted this offer. Do you want to decline it?'
                    : 'Are you sure you want to decline this offer?'}
                </Styled.ItemLabel>
                {!isAccepted && (
                  <StyledButton onClick={toggleConfirm}>No</StyledButton>
                )}
                <StyledButton danger onClick={removeOffer}>
                  Yes
                </StyledButton>
              </Space>
            </Col>
          </Styled.RowItem>
        )}
      </Col>
    </Styled.RowItem>
  );
}
