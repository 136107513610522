import React from 'react';

export const RateLargeIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <path
      d="M27.2827 19.4536C27.5131 18.9866 27.6284 18.7532 27.7848 18.6786C27.9209 18.6137 28.0791 18.6137 28.2152 18.6786C28.3717 18.7532 28.4869 18.9866 28.7174 19.4536L30.9041 23.8835C30.9721 24.0214 31.0061 24.0903 31.0558 24.1438C31.0999 24.1912 31.1527 24.2296 31.2113 24.2569C31.2776 24.2877 31.3536 24.2988 31.5057 24.321L36.397 25.0359C36.9121 25.1112 37.1696 25.1489 37.2888 25.2747C37.3925 25.3841 37.4412 25.5345 37.4215 25.684C37.3988 25.8558 37.2124 26.0374 36.8395 26.4006L33.3014 29.8467C33.1912 29.9541 33.136 30.0078 33.1004 30.0717C33.0689 30.1283 33.0487 30.1905 33.0409 30.2548C33.0321 30.3274 33.0451 30.4032 33.0711 30.555L33.906 35.4224C33.994 35.9357 34.038 36.1924 33.9553 36.3447C33.8833 36.4773 33.7554 36.5702 33.6071 36.5977C33.4366 36.6293 33.2061 36.5081 32.7451 36.2657L28.3724 33.9661C28.2361 33.8944 28.168 33.8586 28.0962 33.8445C28.0327 33.8321 27.9673 33.8321 27.9038 33.8445C27.832 33.8586 27.7639 33.8944 27.6277 33.9661L23.2549 36.2657C22.7939 36.5081 22.5634 36.6293 22.393 36.5977C22.2447 36.5702 22.1167 36.4773 22.0447 36.3447C21.962 36.1924 22.006 35.9357 22.0941 35.4224L22.9289 30.555C22.9549 30.4032 22.9679 30.3274 22.9591 30.2548C22.9513 30.1905 22.9311 30.1283 22.8996 30.0717C22.864 30.0078 22.8089 29.9541 22.6986 29.8467L19.1606 26.4006C18.7877 26.0374 18.6012 25.8558 18.5785 25.684C18.5588 25.5345 18.6076 25.3841 18.7113 25.2747C18.8304 25.1489 19.088 25.1112 19.603 25.0359L24.4943 24.321C24.6464 24.2988 24.7225 24.2877 24.7887 24.2569C24.8474 24.2296 24.9002 24.1912 24.9442 24.1438C24.9939 24.0903 25.0279 24.0214 25.096 23.8835L27.2827 19.4536Z"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#ECFDF3"
      strokeWidth="8"
    />
  </svg>
);
