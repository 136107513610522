import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/store';

import { VendorQuestionnaireState } from 'redux/vendorQuestionnaire/types';

export const useVendorQuestionnaireData = () => {
  const [searchParams] = useSearchParams();
  const vendorQuestionnaireState: VendorQuestionnaireState = useSelector(
    (state: RootState) => state.vendorQuestionnaire
  );

  const vendorQuestionnaireCategoryId = searchParams.get(
    'edit-vendor-questionnaire'
  );

  const selectedQuestionnaireCategory =
    vendorQuestionnaireState?.questionnaireCategory
      ?.selectedQuestionnaireCategory ||
    vendorQuestionnaireState.questionnaireCategory?.list?.data?.find(
      (questionnaire) => questionnaire?.id === vendorQuestionnaireCategoryId
    );

  return {
    ...vendorQuestionnaireState,
    questionnaireCategory: {
      ...vendorQuestionnaireState?.questionnaireCategory,
      selectedQuestionnaireCategory,
    },
  } as VendorQuestionnaireState;
};
