import { AssigmentType } from './Assigment';
import { EmployeeType } from './Employee';
import { QueryParamsDTO } from './QueryParams';

export enum TrackedHourStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum TrackedHourType {
  OVERTIME_HOURS = 'overtime hours',
  REGULAR_HOURS = 'regular hours',
}

export enum TrackedHourJobLocation {
  REMOTE = 'Remote',
  OFFICE = 'Office',
}

export interface TrackedHourDTO {
  id: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  description: string;
  overTimeStart: Date;
  overTimeEnd: Date;
  overtimeHours: number;
  assignment: AssigmentType;
  employee: EmployeeType;
  aprovalStatus: string;
  isRemote: boolean;
  hourType: string;
}

export interface TrackedHourCreateDTO {
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  assignmentId?: string;
  employeeId: string;
  hourType: string;
}

export interface TrackedHourPieChartDTO {
  totalCount: number;
  projectId: string;
  projectName: string;
}

export interface TrackedHoursBarChartDTO {
  sum: number;
  months: number;
  days?: number;
}

export interface TrackedHourSummaryDetailsDTO {
  description?: string;
  duration: number;
}
export interface TrackedHourSummaryDTO {
  projectId: string;
  projectName: string;
  totalCount: number;
  duration: number;
  details?: Array<TrackedHourSummaryDetailsDTO>;
}

export interface TrackedHourToApproveCreateDTO {
  trackingHoursId: string;
  employeeId: string;
  action: TrackedHourStatus;
}
export interface TrackedHoursQueryParamDTO extends QueryParamsDTO {
  searchOptions?: {
    projectIds?: Array<string>;
    employeeIds?: Array<string>;
    startDate?: string;
    endDate?: string;
  };
}

export enum TrackedHoursTabLabel {
  summary = 'Summary',
  detailed = 'Detailed',
  toApprove = 'To Approve',
  timeTracker = 'Time Tracker',
}

export interface TrackedHoursSearchOptionDTO {
  projects?: Array<string>;
  employees?: Array<string>;
  startDate?: string;
  endDate?: string;
}

export interface TrackedHoursFilterDTO extends QueryParamsDTO {
  searchOptions?: TrackedHoursSearchOptionDTO;
}
export interface TrackedHoursSummaryResponse {
  data: Array<TrackedHourSummaryDTO>;
  overallTotal: number;
  sectionTotal: number;
}

export interface TracedHoursExportDTO {
  employeeName: string;
  projectName: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  description: string;
  duration?: number;
  hourType?: string;
}

export interface TrackedHourApproveDTO {
  trackingHoursId: string;
  employeeId: string;
  action: TrackedHourStatus;
}
