import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import CardItem from 'ui-v2/components/Card';
import { StyledUploaDocButton as StyledUploadDocButton } from 'components/NewForms/FormStyled';
import { Item } from 'ui-v2/components/PersonalInfo';
import { RowItem } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import RightSection from 'ui-v2/components/RightSection';
import { UploadButtonIcon } from 'Icons/UploadButtonIcon';
import { DocumentFileItem } from 'ui-v2/components/Documents/FileItem';
import { DocumentsType } from 'types/DocumentFile';
import { deleteEmployeeDocument } from 'api/employeeService';
import { toast } from 'react-toastify';
import { fetchEmployeeDocuments } from 'redux/employees/actions';
import { useDispatch } from 'react-redux';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { useTranslation } from 'react-i18next';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import AddDocumentModal from './AddDocumentModal';

interface IProps {
  employeeId: string;
}

export default function EmployeeUploadDocument({ employeeId }: IProps) {
  const {
    documents: { data, loading },
  } = useEmployeesData();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const onToggleModal = () => setModalVisible((state) => !state);
  const { t } = useTranslation();

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEmployeeDocuments(employeeId));
    }
  }, [employeeId]);

  const onDeleteCallBack = async (id: string) => {
    try {
      await deleteEmployeeDocument(id);
      toast.success(t('successfullyDeletedDocument'));
      dispatch(fetchEmployeeDocuments(employeeId));
    } catch (error) {
      toast.error(t('errorDuringDeletingDocument'));
    }
  };

  if (loading) {
    return null;
  }

  const documents = data as DocumentsType[];
  return (
    <CardItem
      title={t('uploadedDocuments')}
      rightButton={
        documents?.length ? (
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <RightSection
              buttonCallBack={() => onToggleModal()}
              buttonText={t('addDocuments')}
            />
          </RoleBasedButton>
        ) : undefined
      }
    >
      <>
        <RoleBasedButton btn={BUTTONS.UPLOAD_BUTTON}>
          {!documents.length && (
            <RowItem last gutter={[0, 10]}>
              <Col span={4} offset={10}>
                <StyledUploadDocButton
                  onClick={onToggleModal}
                  icon={<UploadButtonIcon />}
                >
                  {t('uploadedDocuments')}
                </StyledUploadDocButton>
              </Col>
            </RowItem>
          )}
        </RoleBasedButton>
        <Row gutter={[0, 16]}>
          {documents.map((d: DocumentsType) => (
            <RowItem last gutter={[0, 10]}>
              <Col span={24}>
                <Item label={d.type} value="">
                  <DocumentFileItem
                    filename={d.name}
                    filecontent={d.url}
                    filesize={d.size}
                    onDelete={() => onDeleteCallBack(d.id)}
                  />
                </Item>
              </Col>
            </RowItem>
          ))}
        </Row>
        {modalVisible && (
          <AddDocumentModal
            open={modalVisible}
            closeModal={() => setModalVisible(false)}
            employeeId={employeeId}
          />
        )}
      </>
    </CardItem>
  );
}
