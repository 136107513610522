import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import {
  getSingleSkill,
  getSkills,
  getSkillsForCategories,
} from 'api/skillService';
import { getAllJobCategoriesWithSubCategories } from 'api/jobCategoryService';
import { Skills } from './types';

export const fetchAllSkills = () => (dispatch: Dispatch) => {
  dispatch({
    type: Skills.FETCH_ALL_SKILLS_START,
  });
  getAllJobCategoriesWithSubCategories()
    .then((res: { data: any }) => {
      dispatch({
        type: Skills.FETCH_ALL_SKILLS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e: any) => {
      dispatch({
        type: Skills.FETCH_ALL_SKILLS_ERRORED,
        payload: e,
      });
    });
};
export const fetchSkills =
  (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Skills.FETCH_SKILLS_START,
    });
    getSkills(queryParams)
      .then((res: { data: any }) => {
        dispatch({
          type: Skills.FETCH_SKILLS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e: any) => {
        dispatch({
          type: Skills.FETCH_SKILLS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSubSkills = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Skills.FETCH_SUB_SKILLS_START,
  });
  getSkillsForCategories(id)
    .then((res: { data: any }) => {
      dispatch({
        type: Skills.FETCH_SUB_SKILLS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e: any) => {
      dispatch({
        type: Skills.FETCH_SUB_SKILLS_ERRORED,
        payload: e,
      });
    });
};

export const fetchSingleSkill = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Skills.FETCH_SKILL_START,
  });
  getSingleSkill(id)
    .then((res) => {
      dispatch({
        type: Skills.FETCH_SKILL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Skills.FETCH_SKILL_ERRORED,
        payload: e,
      });
    });
};

export const clearSkillData = () => (dispatch: Dispatch) => {
  dispatch({
    type: Skills.CLEAR_SKILL_DATA,
  });
};
