import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearWorkPositionState,
  fetchWorkPositions,
} from 'redux/workPositions/actions';
import { useWorkPositionsData } from 'ui-v2/hooks/useWorkPositionsData';

import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import WorkPositionForm from 'components/NewForms/WorkPositionForm';
import { useSearchParams } from 'react-router-dom';
import { deleteWorkPosition } from 'api/workPositionService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function WorkPositionsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useWorkPositionsData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkPositions({ pageOptions: { page: 1, take: 10 } }));
    dispatch(clearWorkPositionState());
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      ...(searchTerm && {
        filterOptions: { filterText: searchTerm, filterFields: [] },
      }),
      pageOptions: { page, take: 10 },
    };
    dispatch(fetchWorkPositions(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      ...(searchTerm && {
        filterOptions: { filterText: searchTerm, filterFields: [] },
      }),
      pageOptionsDto: { page: 1, take: 10 },
    };
    dispatch(fetchWorkPositions(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-new-work-position') === 'true') {
      searchParams.delete('add-new-work-position');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-work-position')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  async function deleteWorkPositionById(id: string | null) {
    if (!id) return;
    deleteWorkPosition(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Work position deleted successfully!');
          dispatch(
            fetchWorkPositions({
              pageOptions: { page: pagination?.page || 1, take: 10 },
            })
          );
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteWorkPositionById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Work positions"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onSelectedDelete={onDelete}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <WorkPositionForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-work-position');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
