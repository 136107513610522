import { deleteCertification } from 'api/certificationService';
import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchCertifications } from 'redux/certifications/actions';
import Content from 'ui-v2/components/Content';
import { useCertificationsData } from 'ui-v2/hooks/useCertificationsData';
import useHeader from 'ui-v2/hooks/useHeader';
import CertificationModalForm from 'components/NewForms/NewCertificationForm';
import { useTranslation } from 'react-i18next';

export default function CertificationsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useCertificationsData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function deleteSelectedCertification(id: string | null) {
    if (!id) return;
    try {
      await deleteCertification(id)
        .then(() => {
          toast.success(t('certificationDeletedSuccessfully'));
          dispatch(fetchCertifications({ page: pagination?.page || 1, take }));
        })
        .catch(() => toast.error('Delete operation failed!'));
    } catch (error) {
      toast.error('Something went wrong!');
    }
  }

  useEffect(() => {
    if (searchParams.get('add-certification') === 'true') {
      searchParams.delete('add-certification');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-certification')) {
      setModalVisible(true);
    }
    if (searchParams.get('delete-certification')) {
      deleteSelectedCertification(searchParams.get('delete-certification'));
      searchParams.delete('delete-certification');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchCertifications(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchCertifications(params));
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteSelectedCertification(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Certifications"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <CertificationModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-certification');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
