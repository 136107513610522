import { CurrencyType } from './Candidates';

export enum TrainingStatus {
  Hour = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum StatusActivity {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface TrainingSts {
  trainingId: string;
  status: StatusActivity;
}
export interface TrainingType {
  id: string;
  name: string;
  type: string;
  valueTime?: number;
  timeType?: TrainingStatus;
  skills: Array<{ value: string }> | string[];
  cost: number;
  status: StatusActivity;
  currecyTraining: CurrencyType;
  certificationName: string;
  certificationCost: number;
  currecyCertification: CurrencyType;
}

export interface TrainingCreateType {
  name: string;
  type: string;
  valueTime?: number;
  timeType?: TrainingStatus;
  skills: Array<{ value: string }> | string[];
  cost: number;
  certificationName: string;
  certificationCost: number;
  status: StatusActivity;
  currecyTraining: CurrencyType;
  currecyCertification: CurrencyType;
}
