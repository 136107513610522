import { Col, Typography } from 'antd';
import { StyledRow } from 'components/NewForms/FormStyled';
import React from 'react';
import { ActionStatus } from '../../OfferAccept';

const { Paragraph, Title, Text } = Typography;

type IProps = {
  candidateName: string;
  openPositionName: string;
  employmentTypeName: string;
  offeredSalary: string;
  offerValidRange: string;
  actionStatus: ActionStatus;
};

export default function OfferInformation({
  candidateName,
  openPositionName,
  employmentTypeName,
  offeredSalary,
  offerValidRange,
  actionStatus,
}: IProps) {
  return (
    <StyledRow justify="center" className="offer-card-row" gutter={[0, 10]}>
      {actionStatus.isDeclineView ? (
        <Col span={24}>
          <Paragraph style={{ textAlign: 'center' }}>
            <Text>
              Thank you for following up and informing us of your decision.
            </Text>
          </Paragraph>
        </Col>
      ) : (
        <Col span={24}>
          <Paragraph>
            <Text>
              We are very excited to meet and get to know you over the past few
              days
            </Text>
            <Text strong>{` ${candidateName}`}</Text>
            <Text> !</Text>
          </Paragraph>
          <Paragraph>
            <Text>
              We have been impressed with your background and would like to
              formally offer the position of
            </Text>
            <Title level={5}>{openPositionName}</Title>
          </Paragraph>
          <Paragraph>
            <Text>This is a </Text>
            <Text strong>{employmentTypeName}</Text>
            <Text> position.</Text>
          </Paragraph>
          <Paragraph>
            <Text>We will be offering you a monthly salary</Text>
            <Text> of </Text>
            <Text strong>{offeredSalary}</Text>
            <Text>along with other benefits.</Text>
          </Paragraph>
          <Paragraph>
            <Text>This offer is available </Text>
            <Text strong>{offerValidRange}</Text>
            <Text> !</Text>
          </Paragraph>
          <Paragraph>
            <Text>Looking forward to having you on our</Text>
            <Text> team !</Text>
          </Paragraph>
        </Col>
      )}
    </StyledRow>
  );
}
