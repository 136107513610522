/* eslint-disable no-empty-pattern */
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { ItemValue } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { IconButton } from 'components/Actions/AssetAction';

export const StyledProjectEmployeesRow = styled(Row)(({ theme }) => ({
  width: '100%',
  svg: {
    fill: theme.colors.palette.primary.gray['700'],
  },
}));

export const StyledProjectEmployeeName: any = styled(ItemValue)(
  ({ theme }) => ({
    cursor: 'pointer',
    color: theme.colors.palette.primary.blue['700'],
  })
);

export const StyledProjectEmployeesCol = styled(Col)<{ isLast?: boolean }>(
  ({ theme, isLast }) => ({
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'start',
    gap: '2rem',
    padding: '1rem',
    ...(isLast
      ? { borderBottom: 'unset' }
      : {
          borderBottom: `1px solid ${theme.colors.palette.primary.gray['200']}`,
          boxShadow: `0 3px 2px -2px ${theme.colors.palette.primary.gray['100']}`,
        }),
  })
);

export const StyledProjectEmployeesDrilldownIcon = styled(DownOutlined)<{
  collapsed?: boolean;
}>(({ collapsed }) => ({
  marginTop: '.2rem',
  ':hover': {
    cursor: 'pointer',
  },
  ...(collapsed && {
    transform: 'rotate(180deg)',
  }),
}));

export const StyledProjectEmployeesDrilldownAssignmentCol = styled(Col)(
  ({ theme }) => ({
    border: `1px solid ${theme.colors.palette.primary.gray['200']}`,
    borderRadius: '4px',
    padding: '.8rem .5rem',
  })
);

export const StyledProjectEmployeesDrilldownIconButton = styled(IconButton)(
  ({ theme }) => ({
    svg: {
      fill: theme.colors.palette.base.white,
    },
  })
);
