import React, { useEffect } from 'react';
import { Col, Row, Space, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import Header from 'components/Login/Header';
import Content from 'ui-v2/components/Content';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { useDispatch } from 'react-redux';
import { fetchTenants } from 'redux/tenants/actions';
import CardItem from 'ui-v2/components/Card';
import { ItemValue } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { TenantDataType } from 'types/Tenant';
import { useTranslation } from 'react-i18next';
import { CompanyLogo, StyledLabel } from '../GuestPagesStyles';
import { AvatarSizes, CompanyGridItemSizes, getLogoSrc } from '../utils';

const GuestComapiesView: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    tenants: { data: tenants, loaded, loading },
  } = useTenantsData();

  useEffect(() => {
    if (!loaded) {
      dispatch(
        fetchTenants({
          queryParams: { pageOptions: { page: 1 } },
        })
      );
    }
  }, [loaded]);

  const onCompanyClick = (tenant: TenantDataType) =>
    navigate(`/jobs/companies/${tenant.id}`);

  return (
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <Header />
      </Col>
      <Col span={24}>
        <Content position="stretch">
          <Row justify="space-between">
            <Col span={6} />
            <Col span={12}>
              <CardItem>
                <Spin spinning={loading}>
                  <Row gutter={[0, 30]}>
                    <Col span={24}>
                      <Row justify="center">
                        <Col>
                          <ItemValue>{t('Select a Company')}</ItemValue>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row wrap gutter={[0, 50]} align="middle">
                        {tenants?.map((tenant) => (
                          <Col
                            xs={CompanyGridItemSizes.xs}
                            md={CompanyGridItemSizes.md}
                            lg={CompanyGridItemSizes.lg}
                          >
                            <Row justify="center" align="middle">
                              <Col onClick={() => onCompanyClick(tenant)}>
                                <Space direction="vertical" align="center">
                                  <CompanyLogo
                                    size={AvatarSizes}
                                    shape="square"
                                    src={getLogoSrc(tenant?.logoContent)}
                                    alt={tenant?.name}
                                  />
                                  <StyledLabel>{tenant?.name}</StyledLabel>
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Spin>
              </CardItem>
            </Col>
            <Col span={6} />
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default GuestComapiesView;
