import React from 'react';
import { Col, Row } from 'antd';
import Header from '../Header';
import { StyledPageContent } from './PageLayoutStyles';

interface NoAuthPageLayoutProps {
  children: React.ReactNode;
}

const NoAuthPageLayout: React.FC<NoAuthPageLayoutProps> = ({ children }) => (
  <StyledPageContent>
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <Header />
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  </StyledPageContent>
);

export default NoAuthPageLayout;
