import React, { useEffect, useState } from 'react';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import { Col, Row, Space } from 'antd';
import PDFViewer from 'ui-v2/routes/ProtectedRoutes/Employee/OnboardingSteps/ContractAndConfidenatialityAgreement/PDFViewer';
import {
  getEmployeeOffBoardingDoc,
  getSignedDocument,
  signEmployeeOffboardingNDA,
  updateTaskStatus,
} from 'api/offboarding';
import { toastErrorMessages } from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { OffboardingTaskStatus } from 'types/Offboarding';
import { useSearchParams } from 'react-router-dom';
import { FileProtectOutlined, FileTextOutlined } from '@ant-design/icons';
import TabWrapper from './TabWrapper';

export default function SignNDA({ onNext }: { onNext: () => void }) {
  const [searchParams] = useSearchParams();
  const { getTabStatus, data } = useOffboardingData();
  const [nda, setNda] = useState<any>();
  const offboardingId = searchParams.get('offboardingId');
  const employeeId = searchParams.get('employeeId');

  const status = getTabStatus(OffboardingTaskType.SIGNATURE);

  async function getNda() {
    if (employeeId) {
      try {
        const response = await getEmployeeOffBoardingDoc(employeeId);
        setNda(response?.data?.fileUrl);
      } catch (error) {
        toastErrorMessages(error);
      }
    }
  }

  async function getSignedDocmunet() {
    if (employeeId) {
      await getSignedDocument(employeeId)
        .then((res) => {
          if (res.status === 200) {
            setNda(res?.data?.fileUrl);
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    }
  }

  useEffect(() => {
    if (status === OffboardingTaskStatus.COMPLETED) {
      getSignedDocmunet();
    } else getNda();
  }, [data]);

  async function updateStatus() {
    if (offboardingId) {
      updateTaskStatus({
        taskId: data?.offBoardingTasks[2]?.id,
        status: OffboardingTaskStatus.COMPLETED,
        offboardingId,
      })
        .then((updateRes) => {
          if (updateRes.status === 200) {
            toast.success('Task completed successfully!');
            onNext();
          }
        })
        .catch((updateError) => {
          toastErrorMessages(updateError);
        });
    }
  }

  const onSubmit = async () => {
    if (status === OffboardingTaskStatus.COMPLETED) {
      onNext();
      return;
    }

    if (employeeId) {
      await signEmployeeOffboardingNDA(employeeId)
        .then((res) => {
          if (res.status === 200) {
            updateStatus();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    }
  };

  return (
    <TabWrapper onNext={onSubmit} type={OffboardingTaskType.SIGNATURE}>
      <>
        {status === OffboardingTaskStatus.COMPLETED && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <FileTextOutlined
                style={{ fontSize: '64px', color: '#faad14' }}
              />
              <h2>Sign NDA Completed</h2>
              <p>
                The signing of the Non-Disclosure Agreement (NDA) has been
                successfully completed. The necessary documents have been
                digitally signed by the employee.
              </p>
            </Space>
          </div>
        )}
        {[
          OffboardingTaskStatus.IN_PROGRESS,
          OffboardingTaskStatus.PENDING,
        ].includes(status) && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <FileProtectOutlined
                style={{ fontSize: '64px', color: '#faad14' }}
              />
              <h2>Sign NDA Pending</h2>
              <p>
                The signing of the Non-Disclosure Agreement (NDA) is pending.
                Please ensure that the necessary documents are digitally signed
                by the employee.
              </p>
            </Space>
          </div>
        )}
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignContent: 'center',
            pointerEvents:
              status === OffboardingTaskStatus.COMPLETED ? 'none' : 'auto',
          }}
        >
          <Col span={15}>
            <Row>
              <PDFViewer file={nda} />
            </Row>
          </Col>
        </div>
      </>
    </TabWrapper>
  );
}
