import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import {
  CreateQuestionnaireFormDto,
  Questionnaires,
  UpdateQuestionnaire,
} from 'types/Questionnaires';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getSingleQuestionnaire = (id: string) =>
  agent.get(`${apiURLs.questionnaires}/${id}`);

export const getQuestionnaires = (payload: QueryParamsDTO) => {
  const p = searchFields(payload, apiURLs.questionnaires);
  return agent.post(`${apiURLs.questionnaires}/get`, p);
};

export const createQuestionnaire = (payload: Questionnaires) =>
  agent.post(apiURLs.questionnaires, payload);

export const removeQuestionnaire = (id: string) =>
  agent.delete(`${apiURLs.questionnaires}/${id}`);

export const updateQuestionnaire = (id: string, payload: UpdateQuestionnaire) =>
  agent.put(`${apiURLs.questionnaires}/${id}`, payload);

export const createQuestionnaireSaveAnswers = (
  payload: CreateQuestionnaireFormDto
) => agent.post(apiURLs.reviewsSaveAnswers, payload);

export const getQuestionnaireAssignmentEmployees = (id: string) =>
  agent.get(`${apiURLs.questionnaires}/getEmployees/${id}`);
