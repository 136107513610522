import { MetaPagination } from 'types/GridTable';
import { OnboardingModelType } from 'types/Onboarding';

export enum OnBoardings {
  FETCH_ON_BOARDINGS_START = 'FETCH_ON_BOARDINGS_START',
  FETCH_ON_BOARDINGS_SUCCESS = 'FETCH_ON_BOARDINGS_SUCCESS',
  FETCH_ON_BOARDINGS_ERRORED = 'FETCH_ON_BOARDINGS_ERRORED',

  FETCH_ON_BOARDINGS_DETAILS_START = 'FETCH_ON_BOARDINGS_DETAILS_START',
  FETCH_ON_BOARDINGS_DETAILS_SUCCESS = 'FETCH_ON_BOARDINGS_DETAILS_SUCCESS',
  FETCH_ON_BOARDINGS_DETAILS_ERRORED = 'FETCH_ON_BOARDINGS_DETAILS_ERRORED',

  CLEAR_SINGLE_ONBOARDING = 'CLEAR_SINGLE_ONBOARDING',
}
export interface SingleOnboardingState {
  data: OnboardingModelType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
export interface OnBoardingsState {
  data: {
    data: Array<OnboardingModelType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
