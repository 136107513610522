import { MetaPagination } from 'types/GridTable';
import { PayrollRule } from 'types/PayrollRule';

export enum PAYROLL_RULE {
  FETCH_PAYROLL_RULE_START = 'FETCH_PAYROLL_RULE_START',
  FETCH_PAYROLL_RULE_SUCCESS = 'FETCH_PAYROLL_RULE_SUCCESS',
  FETCH_PAYROLL_RULE_ERRORED = 'FETCH_PAYROLL_RULE_ERRORED',

  FETCH_SINGLE_PAYROLL_RULE_START = 'FETCH_SINGLE_PAYROLL_RULE_START',
  FETCH_SINGLE_PAYROLL_RULE_SUCCESS = 'FETCH_SINGLE_PAYROLL_RULE_SUCCESS',
  FETCH_SINGLE_PAYROLL_RULE_ERRORED = 'FETCH_SINGLE_PAYROLL_RULE_ERRORED',

  CLEAR_SINGLE_PAYROLL_RULE = 'CLEAR_SINGLE_PAYROLL_RULE',
  CLEAR_ALL_PAYROLL_RULE = 'CLEAR_ALL_PAYROLL_RULE',
}

export interface PayrollRuleState {
  data: {
    data: Array<PayrollRule>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface SinglePayrollRuleState {
  data: PayrollRule | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
