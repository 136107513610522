import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover, Row } from 'antd';
import React from 'react';
import {
  IconButton,
  NodataLabel,
  RowItemAsset,
  TrainingStatusTag,
} from './TrainingItemStyled';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import i18next from '../../../i18n';

interface IProps {
  training: string;
  note: string;
  startDate: string;
  endDate: string;
  trainingStatus: string;
  onView: () => void;
  onDelete: () => void;
}

export function TrainingItem({
  training,
  note,
  startDate,
  endDate,
  trainingStatus,
  onView,
  onDelete,
}: IProps) {
  return (
    <RowItemAsset>
      <Col span={4}>
        <NodataLabel ellipsis style={{ width: '100%' }}>
          <Popover
            content={training}
            placement="topLeft"
            style={{ width: '30%', height: '30%', maxWidth: '200px' }}
          >
            {training}
          </Popover>
        </NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{startDate}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{endDate}</NodataLabel>
      </Col>
      <Col span={4}>
        <TrainingStatusTag status={trainingStatus}>
          <p>{trainingStatus}</p>
        </TrainingStatusTag>
      </Col>
      <Col span={5}>
        <NodataLabel>{note}</NodataLabel>
      </Col>
      <RoleBasedButton btn={BUTTONS.EDIT_TRAINING}>
        <Col span={3}>
          <Row justify="space-around">
            <IconButton onClick={onView}>
              <EditOutlined />
            </IconButton>
            <Popconfirm
              placement="topLeft"
              title={i18next.t('areYouSureYouWanttoDelete')}
              onConfirm={onDelete}
              okText={i18next.t('yes')}
              cancelText={i18next.t('no')}
            >
              <IconButton>
                <DeleteOutlined />
              </IconButton>
            </Popconfirm>
          </Row>
        </Col>
      </RoleBasedButton>
    </RowItemAsset>
  );
}
