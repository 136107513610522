import { Reducer } from 'react';
import { Action } from 'redux';
import {
  QuestionnairesActions,
  QuestionnaireSingleState,
  QuestionnairesState,
} from './types';

const initialState: QuestionnairesState = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const QuestionnairesReducer: Reducer<QuestionnairesState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case QuestionnairesActions.FETCH_QUESTIONNAIRES_START:
      state = { ...state, loading: true };
      break;
    case QuestionnairesActions.FETCH_QUESTIONNAIRES_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case QuestionnairesActions.FETCH_QUESTIONNAIRES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case QuestionnairesActions.CLEAR_ALL_QUESTIONNAIRES:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleEmployeeState: QuestionnaireSingleState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SingleQuestionnaireReducer: Reducer<QuestionnaireSingleState, Action> = (
  state = initialSingleEmployeeState,
  action: any
) => {
  switch (action.type) {
    case QuestionnairesActions.FETCH_SINGLE_QUESTIONNAIRE_START:
      state = { ...state, loading: true };
      break;
    case QuestionnairesActions.FETCH_SINGLE_QUESTIONNAIRE_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case QuestionnairesActions.FETCH_SINGLE_QUESTIONNAIRE_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case QuestionnairesActions.CLEAR_SINGLE_QUESTIONNAIRE:
      state = initialSingleEmployeeState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { QuestionnairesReducer, SingleQuestionnaireReducer };
