import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import { FolderOpenOutlined, MailOutlined } from '@ant-design/icons';

import { DextrioLogo } from 'Icons/DextrioLogo';
import * as Styled from './HeaderStyles';
import i18next from '../../i18n';

interface HeaderProps {
  loadingPage?: 'LOGIN' | 'REGISTER';
}

const itemStyles = {
  color: '#475467',
};

const Header: React.FC<HeaderProps> = () => (
  <Row
    style={{
      borderBottom: '1px solid #D0D5DD',
      background: '#F9F9F9',
    }}
    justify="center"
  >
    <Col>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['mail']}
        style={{
          padding: '20px 0 0 0',
          marginBottom: 16,
          width: '100%',
          border: 'none',
          background: '#F9F9F9',
        }}
      >
        <Link to="/login">
          <Menu.Item key="home">
            <Styled.NavItem style={itemStyles}>
              <DextrioLogo />
            </Styled.NavItem>
          </Menu.Item>
        </Link>
        {/* <Link to="/candidate-registration">
          <Menu.Item key="email" icon={<FormOutlined style={itemStyles} />}>
            <Styled.NavItem style={itemStyles}>
              Register Candidate
            </Styled.NavItem>
          </Menu.Item>
        </Link> */}
        <Link to="/jobs/companies/a502f4d2-22ee-4c72-8f42-7c35fada6440">
          <Menu.Item
            key="email"
            icon={<FolderOpenOutlined style={itemStyles} />}
          >
            <Styled.NavItem style={itemStyles}>
              {i18next.t('openPositions')}
            </Styled.NavItem>
          </Menu.Item>
        </Link>
        <Link to="/register-company">
          <Menu.Item key="email" icon={<MailOutlined style={itemStyles} />}>
            <Styled.NavItem style={itemStyles}>
              {i18next.t('registerCompany')}
            </Styled.NavItem>
          </Menu.Item>
        </Link>

        {/* {loadingPage === 'LOGIN' && (
          <Link to="/register">
            <Menu.Item key="email" icon={<MailOutlined style={itemStyles} />}>
              <Styled.NavItem style={itemStyles}>Register</Styled.NavItem>
            </Menu.Item>
          </Link>
        )} */}
        {/* {loadingPage === 'REGISTER' && (
          <Link to="/login">
            <Menu.Item key="email" icon={<MailOutlined style={itemStyles} />}>
              <Styled.NavItem style={itemStyles}>Login</Styled.NavItem>
            </Menu.Item>
          </Link>
        )} */}
        {/* {isUndefinedNullOrEmpty(loadingPage) && (
          <Link to="/login">
            <Menu.Item key="email" icon={<LockOutlined style={itemStyles} />}>
              <Styled.NavItem style={itemStyles}>Login</Styled.NavItem>
            </Menu.Item>
          </Link>
        )} */}
        {/* <Menu.Item>
      <LanguageSelector />
    </Menu.Item> */}
      </Menu>
    </Col>
  </Row>
);

Header.defaultProps = {};
export default Header;
