import { InterviewType } from 'types/Interview';
import { InterviewTask, Task } from 'types/Task';

export const groupTasks = (interviews: Array<any>) =>
  interviews
    .map((interview: InterviewType) => ({
      ...interview,
      tasks: interview.tasks.map((task: Task) => ({
        ...task,
        interviewId: interview.id,
        interviewDate: interview.date,
        interviewName: interview.interviewType?.name,
      })),
    }))
    .map((interview: InterviewType) => interview.tasks)
    .flat() as Array<InterviewTask>;
