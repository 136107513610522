import { Col, Row } from 'antd';
import styled from 'styled-components';

export const StyledTrackingTabsRow = styled(Row)<{ cols?: number }>(
  ({ theme, cols }) => ({
    borderRadius: '4px',
    border: `1px solid ${theme.colors.palette.primary.gray['300']}`,
    ...(cols && {
      display: 'grid',
      gridTemplateColumns: `repeat(${cols}, 1fr)`,
    }),
  })
);
export const StyledTrackingTabsCol = styled(Col)<{
  bordered?: boolean;
  active?: boolean;
}>(({ bordered, active, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  ...(bordered && {
    borderLeft: `1px solid ${theme.colors.palette.primary.gray['300']}`,
    borderRight: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  }),
  ':hover': {
    backgroundColor: theme.colors.palette.primary.gray['200'],
  },
  ...(active && { backgroundColor: theme.colors.palette.primary.gray['200'] }),
  cursor: 'pointer',
}));

export const StyledTrackingTabsLabel = styled(Col)(({ theme }) => ({
  padding: '0.625rem',
  ...theme.typography.body.semibold.sm,
}));
