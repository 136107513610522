import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'antd';
import { toast } from 'react-toastify';

import CardItem from 'ui-v2/components/Card';
import { deleteOnBoardingTask } from 'api/onBoardingTaskService';
import RightSection from 'ui-v2/components/RightSection';
import { PlusCircleOutlined } from '@ant-design/icons';
import { OnboardingTaskModelType } from 'types/OnboardingTask';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useOnboardingsData } from 'ui-v2/hooks/useOnboardingsData';
import { fetchOnboardingDetail } from 'redux/onboardings/actions';
import { useTranslation } from 'react-i18next';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import OnboardingTaskModal from '../OnboardingTaskModal';
import OnBoardingTaskDetails from '../OnBoardingTaskDetails';

export default function OnboardingTasksList() {
  const [task, setTask] = useState<OnboardingTaskModelType | null>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    onboarding: { data: onboarding, loaded },
  } = useOnboardingsData();

  const fetchOnboarding = useCallback(async () => {
    if (!onboarding?.id) {
      return;
    }
    dispatch(
      fetchOnboardingDetail(
        onboarding?.id ?? (searchParams.get('id') as string)
      )
    );
  }, [loaded]);

  const onToggleModal = () => setModalVisible((state) => !state);

  const onViewCallBack = (index: number) => {
    setTask(onboarding?.onBoardingTasks?.at(index));
    onToggleModal();
  };

  const onButtonClick = () => {
    onToggleModal();
  };

  const onModalClose = () => {
    setModalVisible(false);
    setTask(null);
    fetchOnboarding();
  };

  async function deleteTaskById(id: string) {
    deleteOnBoardingTask(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('Onboarding task deleted successfully'));
          fetchOnboarding();
        }
      })
      .catch((e) => {
        toastErrorMessages(e);
      });
  }

  useEffect(() => {
    if (searchParams.get('edit-onboardingTask')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  return (
    <CardItem
      title={t('tasks')}
      rightButton={
        onboarding?.onBoardingTasks?.length !== 0 ? (
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <RightSection
              buttonCallBack={() => onButtonClick()}
              buttonText={t('addOnboardingTask')}
            />
          </RoleBasedButton>
        ) : undefined
      }
    >
      <>
        <Row gutter={[0, 24]}>
          {!onboarding?.onBoardingTasks?.length && (
            <>
              <Styled.RowItem justify="center" last>
                <Col>
                  <Styled.NodataLabel>
                    {t('currentlyThereAreNoOnboardingTasksForThisOnboarding.')}
                  </Styled.NodataLabel>
                </Col>
              </Styled.RowItem>
              <Styled.RowItem last justify="center">
                <Col>
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="large"
                    style={{
                      borderRadius: '8px',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '344054',
                    }}
                    onClick={onButtonClick}
                  >
                    {t('addOnboardingTask')}
                  </Button>
                </Col>
              </Styled.RowItem>
            </>
          )}

          <>
            {onboarding?.onBoardingTasks?.map(
              (item: OnboardingTaskModelType, index: number) => (
                <OnBoardingTaskDetails
                  onBoardingTask={item}
                  onEdit={() => onViewCallBack(index)}
                  onDelete={() => deleteTaskById(item.id)}
                />
              )
            )}
          </>
        </Row>

        {modalVisible && (
          <OnboardingTaskModal
            open={modalVisible}
            closeModal={onModalClose}
            onboardingId={onboarding.id}
            onboardingTask={task || undefined}
          />
        )}
      </>
    </CardItem>
  );
}
