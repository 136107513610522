import { Modal, Typography } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 12px;
    padding-top: 12px;
  }
  & .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-close {
    top: 24px;
    right: 12px;
  }
`;

export const ModalHeaderContainer = styled.div`
  [class*='ant-modal-mask'] {
    background-color: red;
    opacity: 0.7;
  }
`;

export const ModalHeaderLabel = styled(Typography.Text)`
  font-weight: 600;
  font-size: 18px;
  color: #101828;
  display: block;
  margin-top: 20px;
`;
