import React from 'react';
import { EmployeePasswordStatus } from '../constants';
import { EmployeeSetPasswordForm } from '../EmployeeSetPasswordForm';
import EmployeeNotFound from '../NotFoundFeedback';
import SuccessFeedBack from '../SuccessFeedback';

type IProps = {
  status: string | undefined;
  setStatus: (status: string) => void;
};
export default function EmployeePasswordViewSwitcher({
  status,
  setStatus,
}: IProps) {
  switch (status) {
    case EmployeePasswordStatus.SET_PASSWORD_FAIL:
      return <EmployeeNotFound />;
    case EmployeePasswordStatus.SET_PASSWORD_SUCCESS:
      return <SuccessFeedBack />;
    default:
      return <EmployeeSetPasswordForm setStatus={setStatus} />;
  }
}
