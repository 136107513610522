import { Reducer } from 'react';
import { Action } from 'redux';

import {
  EvaluationPeriods,
  EvaluationPeriodsState,
  EvaluationPeriodState,
} from './types';

const initialState: EvaluationPeriodsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<EvaluationPeriodsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case EvaluationPeriods.FETCH_EVALUATION_PERIODS_START:
      state = { ...state, loading: true };
      break;
    case EvaluationPeriods.FETCH_EVALUATION_PERIODS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case EvaluationPeriods.FETCH_EVALUATION_PERIODS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialStateEvaluationPeriod: EvaluationPeriodState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const singleEvaluationPeriod: Reducer<EvaluationPeriodState, Action> = (
  state = initialStateEvaluationPeriod,
  action: any
) => {
  switch (action.type) {
    case EvaluationPeriods.FETCH_EVALUATION_PERIOD_START:
      state = { ...state, loading: true };
      break;
    case EvaluationPeriods.FETCH_EVALUATION_PERIOD_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case EvaluationPeriods.FETCH_EVALUATION_PERIOD_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case EvaluationPeriods.CLEAR_EVALUATION_PERIOD:
      state = initialStateEvaluationPeriod;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as EvaluationPeriodsReducer, singleEvaluationPeriod };
