import { EmployeesSkim } from '../../types/Employee';

export enum EmployeeSkim {
  FETCH_EMPLOYEES_SKIM_START = 'FETCH_EMPLOYEES_SKIM_START',
  FETCH_EMPLOYEES_SKIM_SUCCESS = 'FETCH_EMPLOYEES_SKIM_SUCCESS',
  FETCH_EMPLOYEES_SKIM_ERRORED = 'FETCH_EMPLOYEES_SKIM_ERRORED',
}
export interface EmployeesSkimState {
  data: Array<EmployeesSkim>;
}
