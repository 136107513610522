import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PublicSuperAdminsState } from 'redux/publicSuperAdmins/types';
import { PublicSuperAdminType } from 'types/PublicSuperAdmin';
import { AuthUserState } from 'redux/authUser/types';
import { TENANT_ID } from 'utils/constants';

export function usePublicSuperAdminData() {
  const publicSuperAdminsState: PublicSuperAdminsState = useSelector(
    (state: RootState) => state.publicSuperAdmins
  );
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const superAdminId = new URLSearchParams(window.location.search).get(
    'edit-public-super-admin'
  );

  const publicSuperAdmin: PublicSuperAdminType | undefined =
    publicSuperAdminsState?.data?.find((user) => user?.id === superAdminId);

  const isSuperAdmin = !localStorage.getItem(TENANT_ID);

  const me: PublicSuperAdminType | undefined = isSuperAdmin
    ? (authUser as unknown as PublicSuperAdminType)
    : undefined;

  return {
    ...publicSuperAdminsState,
    publicSuperAdmin,
    me,
    isSuperAdmin,
  };
}
