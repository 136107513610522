import React from 'react';
import { RulesType } from 'types/FormTypes';
import { FormItemStyled, StyledCheckbox } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  checked?: boolean;
  onChecked?: any;
  hint?: React.ReactNode | string;
}
const CheckboxComponent: React.FC<Props> = ({
  placeholder,
  checked,
  name,
  rules,
  onChecked,
  hint,
}) => (
  <FormItemStyled name={name} rules={rules} {...(hint && { tooltip: hint })}>
    <StyledCheckbox
      defaultChecked={checked}
      onChange={(e) =>
        onChecked && onChecked({ key: name, checked: e.target.checked })
      }
    >
      {placeholder}
    </StyledCheckbox>
  </FormItemStyled>
);

CheckboxComponent.defaultProps = {
  rules: [],
  checked: false,
};

export default CheckboxComponent;
