import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { VendorType } from 'types/Vendor';

export function useVendorsData() {
  const [searchParams] = useSearchParams();
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.vendors
  );
  const id = searchParams.get('edit-vendor') || searchParams.get('id');
  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    vendor: data?.data?.find((item: VendorType) => item.id === id),
    loading,
    loaded,
    errored,
    error,
  };
}
