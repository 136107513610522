import React from 'react';

export const UserDisqualifyIcon = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7503 12.3333L17.917 16.5M17.917 12.3333L13.7503 16.5M10.0003 11.9167H6.25033C5.08736 11.9167 4.50587 11.9167 4.03271 12.0602C2.96737 12.3834 2.13369 13.217 1.81053 14.2824C1.66699 14.7555 1.66699 15.337 1.66699 16.5M12.0837 5.25C12.0837 7.32107 10.4047 9 8.33366 9C6.26259 9 4.58366 7.32107 4.58366 5.25C4.58366 3.17893 6.26259 1.5 8.33366 1.5C10.4047 1.5 12.0837 3.17893 12.0837 5.25Z"
      stroke="#B42318"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
