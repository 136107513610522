import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton, Typography, Divider, Space, Empty } from 'antd';
import { useParams } from 'react-router-dom';

import Content from 'ui-v2/components/Content';
import { getOpenPositionsByTenantId } from 'api/openRoutesService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { TenantDataType } from 'types/Tenant';
import CardItem from 'ui-v2/components/Card';
import { OpenPositionLogo } from 'pages/GuestPages/GuestPagesStyles';
import { AvatarSizes, getLogoSrc } from 'pages/GuestPages/utils';
import { OpenPositionType } from 'interfaces/OpenPosition';
import NoAuthPageLayout from 'ui-v2/components/NoAuth/NoAuthPageLayout';
import { useTranslation } from 'react-i18next';
import GuestOpenPositionDetails from '../GuestOpenPositionDetails';
import GuestOpenPositionItem from './GuestOpenPositionItem';

interface GuestOpenPositionsListProps {
  hardcodedTenantId?: string;
}

const GuestOpenPositionsList: React.FC<GuestOpenPositionsListProps> = ({
  hardcodedTenantId,
}) => {
  const { tenantId } = useParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [detailsView, setDetailsView] = useState<boolean>(false);
  const [data, setData] = useState<{
    tenant: TenantDataType;
    openPositions: Array<OpenPositionType>;
  }>();
  const { t } = useTranslation();

  const fetchOpenPositionByTenantId = (tenantId: string) => {
    isLoading(true);
    getOpenPositionsByTenantId(tenantId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setData(res.data);
        }
      })
      .catch((error) => toastErrorMessages(error))
      .finally(() => isLoading(false));
  };

  useEffect(() => {
    console.log({ tenantId });
    if (tenantId) {
      fetchOpenPositionByTenantId(tenantId);
    }
  }, [tenantId]);

  useEffect(() => {
    if (hardcodedTenantId) {
      fetchOpenPositionByTenantId(hardcodedTenantId);
    }
  }, [hardcodedTenantId]);

  const onToogleView = () => setDetailsView((detailsView) => !detailsView);

  return (
    <NoAuthPageLayout>
      <Content position="stretch">
        {data?.tenant && detailsView ? (
          <GuestOpenPositionDetails
            tenant={data?.tenant}
            onToogleView={onToogleView}
          />
        ) : (
          <Row>
            <Col span={6} />
            <Col span={12}>
              <CardItem>
                <Row justify="center">
                  <Col>
                    <Space direction="vertical" align="center" size="large">
                      <OpenPositionLogo
                        size={AvatarSizes}
                        shape="square"
                        src={getLogoSrc(data?.tenant?.logoContent)}
                      />
                      {loading ? (
                        <Skeleton.Input active size="large" />
                      ) : (
                        <Typography.Title level={4}>
                          {data?.tenant?.name}
                        </Typography.Title>
                      )}
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Skeleton loading={loading}>
                        <Col xs={24} sm={24} md={24}>
                          <Typography.Title level={4}>
                            {`${t('about')} ${data?.tenant?.name}`}
                          </Typography.Title>
                          <Typography.Text>
                            {data?.tenant?.description}
                          </Typography.Text>
                        </Col>
                      </Skeleton>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 24]}>
                      <Divider />
                      <Col span={24}>
                        <Typography.Title level={5}>
                          {t('CURRENT OPENINGS')}
                        </Typography.Title>
                      </Col>
                      <Col>
                        <Row gutter={[16, 16]}>
                          {data?.openPositions?.length ? (
                            data.openPositions.map((item: OpenPositionType) => (
                              <GuestOpenPositionItem
                                key={item?.id}
                                openPosition={item}
                                tenant={data?.tenant}
                                onToogleView={onToogleView}
                              />
                            ))
                          ) : (
                            <Col span={24}>
                              <Empty
                                description={t(
                                  'Currently there are no job openings!'
                                )}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardItem>
            </Col>
            <Col span={6} />
          </Row>
        )}
      </Content>
    </NoAuthPageLayout>
  );
};

export default GuestOpenPositionsList;
