import styled from 'styled-components';
import { Tag, Typography } from 'antd';

export const Text = styled(Typography.Text)`
  font-weight: 600;
  font-size: 14px;
  color: #101828;
`;

export const Description = styled(Typography.Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
`;
export const TextStyle = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  font-style: italic;
`;
export const Status = styled(Tag)<{
  status: string;
}>`
  border-radius: 8px;
  ${({ status }) =>
    status === 'APPROVED' &&
    'background-color: #f6ffed; color: #52c41a; border-color: #b7eb8f'};
  ${({ status }) =>
    status === 'PENDING' &&
    'background-color: #e6f7ff;; color: #1890ff;  border-color: #91d5ff;'};
  ${({ status }) =>
    status === 'REJECTED' &&
    'background-color: #fff2f0; color: #ff4d4f; border-color: #ffccc7'};
`;
