import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'antd/lib/typography';
import Divider from 'antd/lib/divider';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Spin from 'antd/lib/spin';

import { DashboardStatsType } from 'types/DashboardStats';

import { getAllDashboardStats } from 'api/dashboardStats';

import { DashboardOpenPositionsIcon } from 'Icons/DashboardOpenPositionsIcon';
import { DashboardEmployeesIcon } from 'Icons/DashboardEmployeesIcon';
import { DashboardCandidateIcon } from 'Icons/DashboardCandidateIcon';
import { DashboardProjectsIcon } from 'Icons/DashboardProjectsIcon';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';

const { Text } = Typography;

const Stats = () => {
  const [dashboardStats, setDashboardStats] = useState<DashboardStatsType>();
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const { t } = useTranslation();

  async function fetchDashboardStats() {
    setLoadingStats(true);

    try {
      const { data } = await getAllDashboardStats();

      setDashboardStats(data);
    } catch (error) {
      // console.log(error);
    }

    setLoadingStats(false);
  }

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <Row gutter={[24, 24]}>
      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardEmployeesIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.employees ?? 0}
                  </Text>
                  <Row>{t('employees')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>

            <Row justify="end">
              <Link to="/employees">{t('viewEmployees')}</Link>
            </Row>
          </>
        </CardItem>
      </Col>
      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardOpenPositionsIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.openPosition ?? 0}
                  </Text>
                  <Row>{t('openPositions')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>

            <Row justify="end">
              <Link to="/open-positions">{t('viewopenPositions')}</Link>
            </Row>
          </>
        </CardItem>
      </Col>
      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardProjectsIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.projects ?? 0}
                  </Text>
                  <Row>{t('projects')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>

            <Row justify="end">
              <Link to="/projects">{t('viewProjects')}</Link>
            </Row>
          </>
        </CardItem>
      </Col>

      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardCandidateIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.candidates}
                  </Text>
                  <Row>{t('newCandidates')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>

            <Row justify="end">
              <Link to="/candidates">{t('viewCandidates')}</Link>
            </Row>
          </>
        </CardItem>
      </Col>
    </Row>
  );
};

export default Stats;
