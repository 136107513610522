import { RolesType } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { HEADER_BUTTON_TYPE } from './config';

export default function filterByRole(roles: RolesType[], allActions: any) {
  const role = roles[0];
  if (!role) {
    return allActions;
  }

  if (role.name === AUTH_ROLES.GUEST) {
    return {
      ...allActions,
      candidates: allActions.candidates.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_CANDIDATE) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      profile: allActions.profile.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.EDIT_CANDIDATE) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      interviews: allActions.interviews.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.SCHEDULE_NEW_INTERVIEW) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'open-positions': allActions['open-positions'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_OPEN_POSITION) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      employees: allActions.employees.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_EMPLOYEE) {
          return {
            ...item,
            disabled: true,
          };
        }

        return item;
      }),
      'employee-profile': allActions['employee-profile']?.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.EDIT_EMPLOYEE) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      evaluations: allActions.evaluations.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      certifications: allActions.certifications.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_CERTIFICATION) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'certification-details': allActions['certification-details'].map(
        (item: any) => {
          if (item.label === HEADER_BUTTON_TYPE.EDIT_CERTIFICATION) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        }
      ),
      users: allActions.users.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_USER_LIST) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      trainings: allActions.trainings.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_TRAINING) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'training-details': allActions['training-details'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.EDIT_TRAINING) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      departments: allActions.departments.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_DEPARTMENT) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'department-details': allActions['department-details'].map(
        (item: any) => {
          if (item.label === HEADER_BUTTON_TYPE.EDIT_DEPARTMENT) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        }
      ),
      projects: allActions.projects.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_PROJECT) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'project-details': allActions['project-details'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.EDIT_PROJECT) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      questionnaires: allActions.questionnaires.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_QUESTIONNAIRE) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'questionnaire-details': allActions['questionnaire-details'].map(
        (item: any) => {
          if (item.label === HEADER_BUTTON_TYPE.EDIT_QUESTIONNAIRE) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        }
      ),
      ideabox: allActions.ideabox.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_IDEABOX) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'ideabox-details': allActions['ideabox-details'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.EDIT_IDEABOX) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      onboardings: allActions.onboardings.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_ONBOARDING) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'onboarding-details': allActions['onboarding-details'].map(
        (item: any) => {
          if (item.label === HEADER_BUTTON_TYPE.EDIT_ONBOARDING) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        }
      ),
      'work-positions': allActions['work-positions'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_WORK_POSITION) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'purchase-requests': allActions['purchase-requests'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_PURCHASE_REQUEST) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'work-position-details': allActions['work-position-details'].map(
        (item: any) => {
          if (item.label === HEADER_BUTTON_TYPE.EDIT_WORK_POSITION) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        }
      ),
      assets: allActions.assets?.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_ASSET) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      'asset-details': allActions['asset-details'].map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.EDIT_ASSET) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
      tracking: allActions.tracking.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_WORK_LOG_FOR_ALL) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
    };
  }

  if (role.name === AUTH_ROLES.GUEST_TRACKING_HOURS) {
    return {
      ...allActions,
      candidates: allActions.candidates.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_NEW_CANDIDATE) {
          return {
            ...item,
            disabled: true,
          };
        }

        return item;
      }),
      tracking: allActions.tracking.map((item: any) => {
        if (item.label === HEADER_BUTTON_TYPE.ADD_WORK_LOG_FOR_ALL) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      }),
    };
  }
  return allActions;
}
