import { List, Typography } from 'antd';
import styled from 'styled-components';

export const DayLabel = styled(Typography.Text)`
  font-weight: 600;
`;

export const TimeLabel = styled(Typography.Text)`
  font-weight: 600;
  color: #101828;
  margin-left: -15px;
`;

export const Record = styled(Typography.Text)`
  font-weight: 300;
  color: #475467;
`;

export const CustomList = styled(List)`
  overflow: auto;
  height: 200px;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

export const Duration = styled(Typography.Text)`
  font-weight: 600;
  color: #475467;
`;

export const TimePickerRange = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
`;

export const TimePickerLabel = styled(Typography.Text)`
  font-weight: 400;
  font-size: 13px;
`;
