import { MetaPagination } from 'types/GridTable';
import { VendorType } from 'types/Vendor';

export enum Vendors {
  FETCH_VENDORS_START = 'FETCH_VENDORS_START',
  FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS',
  FETCH_VENDORS_ERRORED = 'FETCH_VENDORS_ERRORED',

  FETCH_VENDOR_START = 'FETCH_VENDOR_START',
  FETCH_VENDOR_SUCCESS = 'FETCH_VENDOR_SUCCESS',
  FETCH_VENDOR_ERRORED = 'FETCH_VENDOR_ERRORED',
  CLEAR_SINGLE_VENDOR = 'CLEAR_SINGLE_VENDOR',
}

export interface VendorsState {
  data: {
    data: Array<VendorType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
