import { FallBackImage } from 'ui-v2/components/Tenant/utils';

export const CompanyGridItemSizes = {
  xs: { span: 24 },
  md: { span: 12 },
  lg: { span: 8 },
};

export const AvatarSizes = {
  xs: 24,
  sm: 32,
  md: 100,
  lg: 100,
  xl: 100,
  xxl: 200,
};

export const getLogoSrc = (content: string | undefined) => {
  if (!content || content === 'test') return FallBackImage;
  return content;
};
