import { MetaPagination } from 'types/GridTable';
import { WorkPositiontModelType } from 'types/WorkPosition';

export enum WorkPositions {
  FETCH_WORK_POSITIONS_START = 'FETCH_WORK_POSITIONS_START',
  FETCH_WORK_POSITIONS_SUCCESS = 'FETCH_WORK_POSITIONS_SUCCESS',
  FETCH_WORK_POSITIONS_ERRORED = 'FETCH_WORK_POSITIONS_ERRORED',

  FETCH_WORK_POSITION_START = 'FETCH_WORK_POSITION_START',
  FETCH_WORK_POSITION_SUCCESS = 'FETCH_WORK_POSITION_SUCCESS',
  FETCH_WORK_POSITION_ERRORED = 'FETCH_POSITION_ERRORED',

  CLEAR_WORK_POSITION_STATE = 'CLEAR_WORK_POSITION_STATE',
}

export interface WorkPositionsState {
  data: {
    data: Array<WorkPositiontModelType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
export interface WorkPositionState {
  data: WorkPositiontModelType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
