import React, { useState } from 'react';
import { Select, Row, Col } from 'antd';
import moment from 'moment';
import { generateYearOptions } from '../TimeOffCalendar/utils';

interface CustomCalendarHeaderProps {
  value: any;
  onSelectMonth: (month: number) => void;
  onSelectYear: (year: number) => void;
}
const CustomCalendarHeader: React.FC<CustomCalendarHeaderProps> = ({
  value,
  onSelectMonth,
  onSelectYear,
}) => {
  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number>(
    value.month()
  );

  return (
    <div style={{ padding: 10, margin: 5 }}>
      <Row justify="space-between">
        <Col>
          <div>
            <Select
              onChange={(selectedMonth) => {
                setSelectedMonthIndex(selectedMonth);
                onSelectMonth(selectedMonth);
              }}
              value={selectedMonthIndex}
            >
              {moment.months().map((monthName, i) => (
                <Select.Option value={i}>{monthName}</Select.Option>
              ))}
            </Select>

            <Select
              onChange={(selectedYear) => {
                onSelectYear(selectedYear);
              }}
              value={value.year()}
            >
              {generateYearOptions().map((yearOption) => (
                <Select.Option key={yearOption} value={yearOption}>
                  {yearOption}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomCalendarHeader;
