/* eslint-disable no-empty-pattern */
import styled from 'styled-components';
import { StyledSidebarMenuText } from '../SidebarMenu/StyledSidebarMenu';

export const StyledSidebarFooterContent = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '1.5rem',
}));

export const StyledSidebarFooterExit = styled.div(({}) => ({
  cursor: 'pointer',
}));

export const StyledSidebarFooterUserContent = styled.div.attrs(
  (props: { collapsed?: boolean }) => props
)(({ theme, collapsed }) => ({
  borderTop: `1px solid ${theme.colors.palette.primary.gray['600']}`,
  paddingTop: '1rem',
  display: 'flex',
  ...(collapsed
    ? {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
      }
    : {
        justifyContent: 'space-between',
      }),
}));

export const StyledSidebarFooterUserCredentialsContent = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '60%',
}));

export const StyledSidebarFooterUserCredentialsText = styled(
  StyledSidebarMenuText
).attrs((props: { bold?: boolean }) => props)(({ theme, bold }) => ({
  width: '8.438rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...theme.typography.body.regular.sm,
  ...(bold && { ...theme.typography.body.semibold.sm }),
}));

export const StyledSidebarFooterUserInfo = styled.div(({}) => ({
  marginTop: '.5rem',
  marginLeft: '.5rem',
  display: 'inline-flex',
  width: '100%',
  gap: '0.75rem',
}));
