import {
  OnboardingTaskCreateType,
  OnboardingTaskUpdateType,
} from 'types/OnboardingTask';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import agent from './agent';
import { apiURLs } from './constants';

export const getAllOnBoardingTasks = (queryParams?: QueryParamsType) =>
  agent.post(
    `${apiURLs.onboardingTasks}/get`,
    searchFields(queryParams, apiURLs.onboardingTasks)
  );

export const getOnboardingTasksById = (onBoardingTaskId: string) =>
  agent.get(`${apiURLs.onboardingTasks}/${onBoardingTaskId}/all`);

export const createOnBoardingTask = (values: OnboardingTaskCreateType) =>
  agent.post(apiURLs.onboardingTasks, values);

export const deleteOnBoardingTask = (onBoardingTaskId: string) =>
  agent.delete(`${apiURLs.onboardingTasks}/${onBoardingTaskId}`);

export const updateOnBoardingTask = (
  onBoardingTaskId: string,
  values: OnboardingTaskUpdateType
) => agent.patch(`${apiURLs.onboardingTasks}/${onBoardingTaskId}`, values);
