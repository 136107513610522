import { type UploadProps } from 'antd';
import { CloudUploadIcon } from 'Icons/CloudUploadIcon';
import React, { useCallback, useMemo, useState } from 'react';
import { RulesType } from 'types/FormTypes';
import i18next from 'i18next';
import { checkImageParameters } from 'utils/utilFunctions';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { FormItemStyled, UploadStyled } from '../FormStyled';

interface Props {
  name: string;
  label: string;
  uploadProps?: UploadProps;
  rules?: RulesType[];
  hint?: React.ReactNode | string;
}

function acceptedUpload(uploadProps: UploadProps | undefined): string {
  let acceptProps: string[] = [];
  if (uploadProps) {
    acceptProps = uploadProps.accept?.split(',') || [''];
    const formatedAcceptProps: string[] = [];
    acceptProps.forEach((i) => {
      if (i.startsWith('.')) {
        formatedAcceptProps.push(i.split('.')[1]);
      } else if (!i.includes('base64')) {
        if (i.split('/')[1]) {
          formatedAcceptProps.push(i.split('/')[1]);
        }
      }
    });
    return `${i18next.t('clickToUpload')} ${formatedAcceptProps
      .toString()
      .replace(',', ' , ')} `;
  }
  return i18next.t('clickToUpload');
}

const UploadComponent: React.FC<Props> = ({
  name,
  label,
  uploadProps,
  rules,
  hint,
}) => {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  useMemo(() => {
    if (uploadProps?.defaultFileList?.length) {
      setFileList(uploadProps?.defaultFileList);
    }
  }, [uploadProps?.defaultFileList]);

  const handleChange = useCallback(
    async (info: UploadChangeParam) => {
      let files = info.fileList;

      files = await checkImageParameters(files);

      setFileList(files.filter(Boolean));
    },
    [fileList]
  );

  return (
    <FormItemStyled
      name={name}
      label={label}
      rules={rules}
      // @ts-expect-error Temporary
      initialValue={uploadProps?.initialValue}
      {...(hint && { tooltip: hint })}
    >
      <UploadStyled
        {...uploadProps}
        fileList={fileList}
        onChange={handleChange}
        maxCount={1} // Limit to one file
      >
        <div className="upload__container">
          <p className="ant-upload-drag-icon">
            <CloudUploadIcon />
          </p>
          <p>
            <span className="link">{acceptedUpload(uploadProps)}</span>
            {i18next.t('or drag and drop. Max file size: 2MB')}
            <br />
            {uploadProps?.action}
          </p>
        </div>
      </UploadStyled>
    </FormItemStyled>
  );
};

UploadComponent.defaultProps = {
  uploadProps: {},
  rules: [],
};

export default UploadComponent;
