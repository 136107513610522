import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchSingleQuestionnaire } from 'redux/questionnaires/actions';
import Content from 'ui-v2/components/Content';
import QuestionnairesModalForm from 'components/NewForms/QuestionnairesForm';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';
import QuestionnaireInformation from 'ui-v2/components/Review/QuestionnaireInformation';
import QuestionnaireAssignment from './QuestionnaireAssignment';

export default function QuestionnaireDetails() {
  const { singleQuestionnaire, singleQuestionnaireLoaded } =
    useQuestionnairesData();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('edit-questionnaire-details')) {
      setModalVisible(true);
      searchParams.delete('edit-questionnaire-details');
      setSearchParams(searchParams);
    }
    if (searchParams.get('id') && !singleQuestionnaireLoaded) {
      dispatch(fetchSingleQuestionnaire(searchParams.get('id') as string));
    }
  }, [searchParams, singleQuestionnaireLoaded]);

  if (!singleQuestionnaire?.id) {
    return null;
  }

  return (
    <>
      <Content position="grid">
        <>
          <QuestionnaireInformation />
          <QuestionnaireAssignment />
          <></>
        </>
      </Content>
      {modalVisible && (
        <QuestionnairesModalForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-questionnaire');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
