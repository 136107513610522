import { getAllOpenPositionsforAdmin } from 'api/openPositions';
import { Dispatch } from 'redux';
import { OpenPositionQueryParamsType } from 'types/QueryParams';
import { OpenPositions } from './types';

export const fetchOpenPositions =
  (queryParamas?: OpenPositionQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: OpenPositions.FETCH_OPEN_POSITION_START,
    });
    getAllOpenPositionsforAdmin(queryParamas)
      .then((res) => {
        dispatch({
          type: OpenPositions.FETCH_OPEN_POSITION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: OpenPositions.FETCH_OPEN_POSITION_ERRORED,
          payload: e,
        });
      });
  };
