import { deleteIpWhitelisting } from 'api/ipAccess';
import IpWhitelistingModalForm from 'components/NewForms/IpWhitelistingModalForm';
import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchIpWhitelisting } from 'redux/ipAccess/actions';

import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { useIpWhitelistingData } from 'ui-v2/hooks/useIpWhitelistingData';

export default function IpAccessList() {
  const { columns } = useHeader();
  const { list, loading } = useIpWhitelistingData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setSearchTerm] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  function deleteIpWhitelistingById(id: string | null) {
    if (!id) return;

    deleteIpWhitelisting(id)
      .then(() => {
        toast.success('Ip deleted successfully!');
        dispatch(fetchIpWhitelisting({ page: 1, take: 10 }));
      })
      .catch(() => {
        toast.error('Ip failed to delete!');
      });
  }

  useEffect(() => {
    if (searchParams.get('add-ip-whitelisting') === 'true') {
      searchParams.delete('add-ip-whitelisting');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('delete-ip-whitelisting')) {
      deleteIpWhitelistingById(searchParams.get('delete-ip-whitelisting'));
      searchParams.delete('delete-ip-whitelisting');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(fetchIpWhitelisting({ page: 1, take: 10 }));
  }, []);

  // const onPageChange = (page: number, take: number) => {
  //   const params = {
  //     page,
  //     take,
  //     ...(searchTerm && { name: searchTerm }),
  //   };
  //   dispatch(fetchIpWhitelisting(params));
  // };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchIpWhitelisting(params));
    setSearchTerm(searchTerm);
  };

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Ip Whitelisting"
          rowSelectionType="checkbox"
          // totalItems={pagination?.itemCount}
          // currentPage={pagination?.page}
          // totalPages={pagination?.pageCount}
          // onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />

        {modalVisible && (
          <IpWhitelistingModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
