import { Col, Image, Row } from 'antd';
import React from 'react';
import { FallBackImage } from 'ui-v2/components/Tenant/utils';
import { useTranslation } from 'react-i18next';
import {
  StyledTenantOnboardingContent,
  StyledTenantOnboardingTitle,
} from './StyledTenantOnboardingSteps';
import {
  TenantOnboardinStepsLayoutProps,
  TenantOnboardinStepsLayout,
} from './TenantOnboardingSteps';

const TenantOnboardingWelcome: React.FC<TenantOnboardinStepsLayoutProps> = ({
  tenant,
  ...props
}) => {
  const welcomeTitle =
    tenant?.firstName && tenant?.lastName
      ? `Welcome ${tenant?.firstName} ${tenant?.lastName}`
      : `Welcome ${tenant?.name || ''}`;
  const { t } = useTranslation();

  return (
    <TenantOnboardinStepsLayout tenant={tenant} {...props}>
      <Row justify="center" gutter={[0, 40]}>
        <Col span={24} />
        <Col>
          <Image
            preview={false}
            src={tenant?.logoContent || FallBackImage}
            width={80}
            height={80}
          />
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col>
              <StyledTenantOnboardingTitle>
                {welcomeTitle}
              </StyledTenantOnboardingTitle>
            </Col>
          </Row>
        </Col>
        <Col span={18}>
          <StyledTenantOnboardingContent>
            {t('welcomeMessage')}
          </StyledTenantOnboardingContent>
        </Col>
        <Col span={18}>
          <StyledTenantOnboardingContent>
            {t('message')}
          </StyledTenantOnboardingContent>
        </Col>
        <Col span={18}>
          <StyledTenantOnboardingContent>
            {t('embarkJourney')}
          </StyledTenantOnboardingContent>
        </Col>
        <Col span={24} />
      </Row>
    </TenantOnboardinStepsLayout>
  );
};

export default TenantOnboardingWelcome;
