import { EmployeesSkim } from 'types/Employee';
import { AssetType } from 'types/Asset';
import moment from 'moment';
import { Tag } from 'antd';
import React from 'react';
import i18next from '../i18n';
import { getRandomColor } from './trackingHoursConfigs/utils';

export const AssetLogsColumns = [
  {
    title: i18next.t('actionType'),
    dataIndex: 'actionType',
    key: 'actionType',
    width: 70,
    default: true,
    render: (record: string) => (
      <Tag
        color={getRandomColor()}
        style={{ borderRadius: '5px', marginBottom: '4px' }}
      >
        {record}
      </Tag>
    ),
    order: 0,
  },
  {
    title: i18next.t('timestamp'),
    dataIndex: 'timestamp',
    key: 'timestamp',
    ellipsis: true,
    width: 130,
    render: (record: Date) => `${moment(record).format('DD-MM-YYYY HH:mm:ss')}`,
    default: true,
    order: 1,
  },
  {
    title: i18next.t('previousValue'),
    dataIndex: 'previousValue',
    key: 'previousValue',
    width: 100,
    render: (record: string) => (record ? `${record}` : '-'),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('newValue'),
    dataIndex: 'newValue',
    key: 'newValue',
    render: (record: string) => (record ? `${record}` : '-'),
    width: 100,
    default: false,
    order: 2,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    width: 130,
    default: false,
    order: 3,
  },
  {
    title: i18next.t('name'),
    dataIndex: 'asset',
    key: 'asset',
    width: 50,
    default: false,
    order: 4,
    render: (record: AssetType) => (record ? `${record?.name}` : '-'),
  },
  {
    title: i18next.t('employee'),
    dataIndex: 'employee',
    key: 'employee',
    width: 70,
    default: false,
    order: 7,
    // eslint-disable-next-line no-confusing-arrow
    render: (record: EmployeesSkim) =>
      record ? `${record?.firstName} ${record?.lastName}` : '-',
  },
];
