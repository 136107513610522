import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  getExportTblConfig,
  prepareExportData,
} from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/exportUtils';
import { ExportOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { StyledButton } from 'components/NewForms/FormStyled';
import { CandidateType } from 'types/Candidates';
import { EmployeeType } from 'types/Employee';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import {
  getResumeFiles,
  handleResumeResponse,
  isExportPathEntity,
} from './utils';

interface ExportDataGridProps {
  entity: string;
  data: any;
}

export const TableBtn = styled(StyledButton)`
  height: 35px;
  svg {
    margin-right: 0;
  }
`;

export default function ExportDataGrid({ entity, data }: ExportDataGridProps) {
  const [mergedResumeFileData, setMergedResumeFileData] = useState<[]>([]);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (mergedResumeFileData.length) {
      document.getElementById('download')?.click();
      setMergedResumeFileData([]);
    }
  }, [mergedResumeFileData]);

  const onExport = async () => {
    setLoadingExport(true);

    const ids: string[] = data.map(
      (item: CandidateType | EmployeeType) => item.id
    );

    const { data: resumes } = await getResumeFiles(entity, ids);
    if (!resumes) {
      toastErrorMessages(t('Could not export table!'));
      return;
    }
    if (resumes) {
      setMergedResumeFileData(handleResumeResponse(data, entity, resumes));
    }

    setLoadingExport(false);
  };

  if (isExportPathEntity(entity)) {
    return (
      <>
        <TableBtn
          icon={<ExportOutlined />}
          onClick={onExport}
          loading={loadingExport}
        />
        <CSVLink
          filename={`${entity}-data`}
          headers={getExportTblConfig(entity) as any}
          data={mergedResumeFileData}
          onClick={() => console.log({ mergedResumeFileData })}
          id="download"
          separator=","
        />
      </>
    );
  }

  return (
    <CSVLink
      filename={`${entity}-data`}
      headers={getExportTblConfig(entity) as any}
      data={prepareExportData(data, entity)}
      id="download"
      separator=","
    >
      <TableBtn icon={<ExportOutlined />} />
    </CSVLink>
  );
}
