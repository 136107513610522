import React from 'react';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

// import Content from 'ui-v2/components/Content';
import BubbleChart from './BubbleChart';
import PieChart from './PieChart';
import PieChartAsset from './PieChartAsset';
import LineChart from './LineChart';
import BarChart from './BarChart';
import Stats from './Stats';

const DashboardPage = () => (
  // <Content position="stretch">
  <Row gutter={[0, 25]}>
    <Col span={24}>
      <Stats />
    </Col>
    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Row gutter={[24, 24]} style={{ width: '100%' }}>
          <Col xxl={16} md={16}>
            <BubbleChart />
          </Col>
          <Col xxl={8} md={8} style={{ paddingRight: '0px' }}>
            <Row
              justify="center"
              style={{ width: '100%', height: '100%' }}
              id="valedin"
            >
              <Col xxl={24} md={24}>
                <PieChart />
              </Col>
              <Col xxl={24} md={24}>
                <PieChartAsset />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ width: '100%' }}>
          <Col md={24} xxl={12}>
            <BarChart />
          </Col>
          <Col md={24} xxl={12}>
            <LineChart />
          </Col>
        </Row>
      </Row>
    </Col>
  </Row>
  // </Content>
);

export default DashboardPage;
