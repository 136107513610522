import { CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Button, message, Form } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { getTenantApiKey } from 'api/tenantService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { TenantKey } from 'types/Tenant';
import { useSearchParams } from 'react-router-dom';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { SettingTab } from 'types/settings';
import { useTranslation } from 'react-i18next';
import { StyledIcon, StyledText, StyledWarning } from './ApiKeysFormStyles';
import GenericForm from '../Form/GenericForm';

const ApiKeysForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const {
    singleTenanat: { data: tenant },
  } = useTenantsData();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  useEffect(() => {
    if (searchParams.get('settings-save') === SettingTab.APIKEYS) {
      searchParams.delete('settings-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams]);

  const { optionsType: roles } = useGetSelectOptions({
    type: 'roles',
    transform: (item) => ({
      id: item.name,
      value: item.name,
      label: item.name,
    }),
  });

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();
      const payload: TenantKey = {
        ...formValues,
        tenantId: tenant?.id || (searchParams.get('approve-tenant') as string),
      };
      const res = await getTenantApiKey(payload);
      if (res.status === 201) {
        const generatedApiKey = res.data;
        setApiKey(generatedApiKey);
        setModalVisible(true);
      }
    } catch (error) {
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    const input = document.getElementById('apiKeyInput') as HTMLInputElement;
    if (input) {
      input.select();
      document.execCommand('copy');
      message.success(t('API key copied to clipboard'));
    }
  };

  const TenantKeyFormConfigurations: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'roles',
          label: t('role'),
          type: InputTypes.SELECT,
          selectOptions:
            roles
              ?.filter((i) => i?.label !== 'super_admin')
              .map((i) => ({
                ...i,
                label:
                  `${i.label}`.split('_').join(' ').charAt(0).toUpperCase() +
                  `${i.label}`.slice(1),
              })) || [],
          rules: [
            {
              required: true,
              message: t('Role is required'),
            },
          ],
        },
      ],
    ],
    [roles]
  );

  return (
    <>
      <GenericForm
        formConfiguration={TenantKeyFormConfigurations}
        onFinish={onFinish}
        form={form}
        loading={loading}
      />
      <GenericModal
        title={t('Success')}
        open={modalVisible}
        closeModal={handleCloseModal}
        maskClosable={false}
      >
        <StyledText>
          {t('youHaveSuccessfullyAddedANewPersonalAccessToken.copyTheToken!')}
        </StyledText>
        <Input
          style={{ width: '60%' }}
          id="apiKeyInput"
          value={apiKey}
          addonAfter={
            <Button
              onClick={copyToClipboard}
              type="link"
              icon={<CopyOutlined />}
            />
          }
        />
        <StyledWarning>
          <StyledIcon>
            <ExclamationCircleOutlined />
          </StyledIcon>
          <span>
            {t(
              'Warning! Make sure you copy the above token now! We do not store it and you will not be able to see it later.'
            )}
          </span>
        </StyledWarning>
      </GenericModal>
    </>
  );
};

export default ApiKeysForm;
