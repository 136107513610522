import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import { IconButton } from './BankItemStyled';

const BankItemAction: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const onEditClick = () => {
    searchParams.append('edit-bankItem', id);
    setSearchParams(searchParams);
  };

  const deleteBankItemById = () => {
    searchParams.append('delete-bankItem', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={10}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Popconfirm
              placement="topLeft"
              title={t('areYouSureYouWanttoDelete')}
              onConfirm={deleteBankItemById}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Popconfirm>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default BankItemAction;
