import agent from 'api/agent';
import { AssignmentCreate, AssignmentUpdate } from 'types/Assigment';
import { apiURLs } from './constants';

export const getAssignments = () => agent.get(apiURLs.assignment);

export const createAssignment = (values: AssignmentCreate) =>
  agent.post(apiURLs.assignment, values);

export const updateAssignment = (id: string, values: AssignmentUpdate) =>
  agent.put(`${apiURLs.assignment}/${id}`, values);

export const getAssignmentById = (id: string) =>
  agent.get(`${apiURLs.assignment}/${id}`);

export const getAssigmentsByEmployeeId = (employeeId: string) =>
  agent.get(`${apiURLs.project}/${employeeId}/assignedProjects`);

export const getAssignmentsByEmployeeId = (employeeId: string) =>
  agent.get(`${apiURLs.assignment}/employee/${employeeId}`);

export const deleteAssignment = (id: string) =>
  agent.delete(`${apiURLs.assignment}/${id}`);
