import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { InvoicingState } from 'redux/invoicing/types';
import { RootState } from 'redux/store';
import { InvoicingType } from 'types/Invoicing';

export function useInvoicingData() {
  const { data, loading, loaded, errored, error, invoicing }: InvoicingState =
    useSelector((state: RootState) => state.invoicing);
  const [searchParams] = useSearchParams();

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    invoice:
      (invoicing as InvoicingType) ||
      data?.data?.find(
        (item: InvoicingType) => item.id === searchParams.get('id')
      ),
    loading,
    loaded,
    error,
    errored,
    invoicing,
  };
}
