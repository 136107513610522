import { useForm } from 'antd/lib/form/Form';
import { approveTenantById } from 'api/tenantService';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchTenants } from 'redux/tenants/actions';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  TenantApproveInputType,
  TenantDataType,
  TenantDbType,
} from 'types/Tenant';
import { toastErrorMessages } from 'utils/utilFunctions';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form';
import TestDbConnection from './TestDbConnection';
import { StyledButton } from '../FormStyled';

interface TenantApproveProps {
  onClose: () => void;
  onSuccess: () => void;
  tenant: TenantDataType;
}

const TenantApproveModalForm: React.FC<TenantApproveProps> = ({
  onClose,
  onSuccess,
  tenant,
}) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const [isDefaultConfig, setIsDefaultConfig] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);
  const [connectionValid, isConnectionValid] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  function approveTenant(values: TenantDbType) {
    const payload: TenantApproveInputType = {
      tenantId: tenant?.id || (searchParams.get('approve-tenant') as string),
    };

    if (!values.isDefault) {
      payload.tenantDB = {
        ...values,
        database: tenant?.id || (searchParams.get('approve-tenant') as string),
      };
      delete payload.tenantDB?.confirmPassword;
      delete payload.tenantDB?.isDefault;
    }

    setApproving(true);
    approveTenantById(payload)
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            `${t('Company approved!')} ${t('An email will be sent to')} ${
              tenant?.email
            } ${t('with login details')}.`
          );
          dispatch(fetchTenants({ queryParams: { pageOptions: { page: 1 } } }));
          onSuccess();
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => setApproving(false));
  }

  const onChecked = (checked: boolean) => {
    setIsDefaultConfig(checked);
  };

  const ApproveTenantFormConfigurations: FormConfigurationType[][] =
    useMemo(() => {
      const FormConfig: FormConfigurationType[][] = [
        [
          {
            col: 13,
            offset: 11,
            name: 'isDefault',
            label: t('Keep default configurations:'),
            type: InputTypes.SWITCH,
            direction: 'vertical',
            onChecked,
          },
        ],
      ];
      if (isDefaultConfig) {
        return FormConfig;
      }
      return [
        ...FormConfig,
        [
          {
            col: 11,
            offset: 0,
            name: 'host',
            label: t('host'),
            type: InputTypes.INPUT,
            inputProps: {
              type: 'text',
            },
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          {
            col: 11,
            offset: 2,
            name: 'port',
            label: t('port'),
            type: InputTypes.INPUT,
            inputProps: {
              type: 'number',
              rows: 1,
            },
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'username',
            label: t('username'),
            type: InputTypes.INPUT,
            inputProps: {
              type: 'text',
            },
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'password',
            label: t('password'),
            type: InputTypes.INPUT,
            inputProps: {
              type: 'password',
            },
            rules: [
              {
                required: true,
                message: t('Please enter your password!'),
                validator(_: any, value: any): Promise<any> {
                  if (!isDefaultConfig && !value) {
                    return Promise.reject(
                      new Error(t('Password is required!'))
                    );
                  }
                  return Promise.resolve();
                },
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'confirmPassword',
            label: t('confirmPassword'),
            type: InputTypes.INPUT,
            inputProps: {
              type: 'password',
            },
            rules: [
              {
                required: true,
                message: t('Passwords do not match!'),
                validator(_: any, value: string | undefined): Promise<any> {
                  if (value && form.getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('Passwords do not match!'))
                  );
                },
              },
            ],
          },
        ],
      ];
    }, [isDefaultConfig]);
  return (
    <GenericForm
      formConfiguration={ApproveTenantFormConfigurations}
      onFinish={approveTenant}
      form={form}
    >
      <Row justify="start" gutter={[0, 30]}>
        {!isDefaultConfig && (
          <Col>
            <TestDbConnection
              form={form}
              tenantId={tenant?.id}
              result={isConnectionValid}
            />
          </Col>
        )}
        <Col span={24}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={onClose} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                type="primary"
                htmlType="submit"
                loading={approving}
                disabled={!connectionValid && !isDefaultConfig}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </GenericForm>
  );
};

export default TenantApproveModalForm;
