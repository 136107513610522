import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Col, TimePicker } from 'antd';

import * as Styled from './CellRenderStyles';
import { getRequestStatusIcon, mergeDateHour } from '../utils';

interface IProps {
  cellDate: Moment;
  selected?: boolean;
  disabled?: boolean;
  onTimes?: (start: Moment, end: Moment) => void;
  description?: string;
  status?: string;
  holidays: Moment[];
  holidayName: string;
}

export default function CellRender({
  cellDate,
  selected,
  disabled,
  onTimes,
  description,
  status,
  holidays,
  holidayName,
}: IProps) {
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [times, setTimes] = useState([]);

  useEffect(() => {
    if (times.length === 2) {
      if (onTimes) {
        onTimes(times[0], times[1]);
      }
    }
  }, [times]);

  const onSetTime = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowTimePicker(true);
  };

  const onChangeTime = (dates: any) => {
    const startTime = mergeDateHour(cellDate, dates[0]);
    const endTime = mergeDateHour(cellDate, dates[1]);

    setTimes([startTime, endTime] as any);

    if (dates.length === 2) {
      setShowTimePicker(false);
    }
  };

  const onStopPropagation = (e: any) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
  };
  const isHolidayDate = holidays.some((holiday) =>
    holiday.isSame(cellDate, 'day')
  );

  if (isHolidayDate) {
    return (
      <Styled.Center>
        <Styled.HolidayCell>{holidayName}</Styled.HolidayCell>
      </Styled.Center>
    );
  }

  if (times.length === 2) {
    return (
      <Styled.Center>
        <Styled.RowItem>
          <Col span={24}>
            <Styled.TimeLabel>
              {moment(times[0]).format('HH:mm')}
            </Styled.TimeLabel>
          </Col>
          <Col span={24}>
            <Styled.TimeLabel>to</Styled.TimeLabel>
          </Col>
          <Col span={24}>
            <Styled.TimeLabel>
              {moment(times[1]).format('HH:mm')}
            </Styled.TimeLabel>
          </Col>
        </Styled.RowItem>
      </Styled.Center>
    );
  }

  if (showTimePicker) {
    return (
      <Styled.Center onClick={onStopPropagation} key={cellDate.toString()}>
        <TimePicker.RangePicker
          format="HH:mm"
          showNow
          onChange={onChangeTime}
        />
      </Styled.Center>
    );
  }

  if (disabled) {
    const Icon: any = status && getRequestStatusIcon(status);
    return (
      <Styled.Disabled status={status} key={cellDate.toString()}>
        <Icon />
        <Styled.DisabledFooter>
          <Styled.RequestedLabel>{description}</Styled.RequestedLabel>
        </Styled.DisabledFooter>
      </Styled.Disabled>
    );
  }

  if (selected) {
    return (
      <Styled.Center onClick={onSetTime} key={cellDate.toString()}>
        <Styled.FullDayLabel>Full day</Styled.FullDayLabel>
      </Styled.Center>
    );
  }

  return null;
}
