import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';

import { TrainingQueryParamsType } from 'types/QueryParams';
import { fetchTrainings } from 'redux/trainings/actions';
import { StatusActivity } from 'types/Training';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function TrainingsFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();

  async function onFormChange() {
    const employee = form.getFieldValue('employee')?.key;
    const status: string = form.getFieldValue('status');

    const filterEmployeeTable: TrainingQueryParamsType = {
      employee,
      status,
    };

    if (filterEmployeeTable) {
      if (employee) dispatch(fetchTrainings({ employee }));
      if (status) dispatch(fetchTrainings({ status }));
    }
  }

  const EmployeesConfig: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'employee',
          label: t('employee'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: getEmployeeSkimSelectWithLoadOptions,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'status',
          label: 'Status',
          type: 'select',
          selectOptions: Object.values(StatusActivity).map((item: string) => ({
            id: item,
            value: item,
            label: item,
          })),
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={EmployeesConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchTrainings({ page: 1, take: 10 }));
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
