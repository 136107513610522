import { Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CardItem from 'ui-v2/components/Card';
import { useSelector } from 'react-redux';
import { getQuestionnaireAssignmentByReviwerId } from 'api/questionnaireAssignmentService';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { EmployeeType } from 'types/Employee';
import { QuestionnaireFeedbackItem } from 'ui-v2/components/QuestionnaireFeedbackItem';
import { QuestionnaireAssignmentType } from 'types/QuestionnaireAssignment';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import EmployeeReview from 'components/Reviews/EmployeeReviewModal';
import * as Styled from './QuestionnaireFeedBackStyles';

export default function QuestionnaireFeedback() {
  const { employeeId } = useEmployeesData();

  const [modalVisible, setModalVisible] = useState(false);
  const [questionnaireDetails, setQuestionnaireDetails] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [revieweeId, setRevieweeId] = useState(['']);
  const [searchParams, setSearchParams] = useSearchParams();

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const fetchQuestionnaireAssignmentByReviewerId = async (id: string) => {
    setLoading(true);
    try {
      setLoading(true);
      const response = await getQuestionnaireAssignmentByReviwerId(id);
      if (response.status === 200 && response.data) {
        setQuestionnaireDetails(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t('Something went wrong'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchQuestionnaireAssignmentByReviewerId(employeeId);
  }, [employeeId]);

  useEffect(() => {
    if (searchParams.get('revieweeId')) {
      const revieweeIdFromParam = searchParams.get('revieweeId') as string;
      setRevieweeId([revieweeIdFromParam]);
      searchParams.delete('revieweeId');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
  }, [searchParams]);

  return (
    <>
      {loading ? (
        <Skeleton loading={loading} paragraph={{ rows: 2 }} active />
      ) : (
        <CardItem title={t('questionnaireAssignment')}>
          <>
            <Row gutter={[0, 24]}>
              {!questionnaireDetails?.length && (
                <>
                  <Styled.RowItem justify="center" last>
                    <Col>
                      <Styled.NodataLabel>
                        {authUserRole === 'employee'
                          ? 'thereAreNoQuestionnaireAssignmentsRelatedToYou'
                          : t(
                              'thereAreNoQuestionnaireAssignmentsRelatedToThisEmployee'
                            )}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                </>
              )}

              {!!questionnaireDetails?.length && (
                <>
                  <Styled.RowItem>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('firstName')}</Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('lastName')}</Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel>
                        {t('assignmentDate')}
                      </Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel style={{ paddingLeft: 20 }}>
                        {t('action')}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                  {!loading &&
                    questionnaireDetails?.map(
                      (questionnaireAssignment: QuestionnaireAssignmentType) =>
                        questionnaireAssignment?.reviewees?.map(
                          (reviewee: EmployeeType) => (
                            <QuestionnaireFeedbackItem
                              key={reviewee?.id}
                              firstName={reviewee?.firstName}
                              lastName={reviewee?.lastName}
                              createdAt={convertUTCtoFormatedLocalTime(
                                questionnaireAssignment?.createdAt
                              )}
                              id={reviewee?.id}
                            />
                          )
                        )
                    )}
                </>
              )}
            </Row>
            {modalVisible && (
              <EmployeeReview
                open={modalVisible}
                employeeIds={revieweeId}
                closeModal={() => setModalVisible(false)}
              />
            )}
          </>
        </CardItem>
      )}
    </>
  );
}
