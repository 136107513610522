import { MetaPagination } from 'types/GridTable';
import {
  TrackedHourDTO,
  TrackedHourPieChartDTO,
  TrackedHoursBarChartDTO,
  TrackedHoursSummaryResponse,
} from 'types/tracking';

export enum Trackings {
  FETCH_TRACKED_HOURS_DETAILS_START = 'FETCH_TRACKED_HOURS_DETAILS_START',
  FETCH_TRACKED_HOURS_DETAILS_SUCCESS = 'FETCH_TRACKED_HOURS_DETAILS_SUCCESS',
  FETCH_TRACKED_HOURS_DETAILS_ERRORED = 'FETCH_TRACKED_HOURS_DETAILS_ERRORED',

  FETCH_TRACKED_HOURS_TO_APPROVE_START = 'FETCH_TRACKED_HOURS_TO_APPROVE_START',
  FETCH_TRACKED_HOURS_TO_APPROVE_SUCCESS = 'FETCH_TRACKED_HOURS_TO_APPROVE_SUCCESS',
  FETCH_TRACKED_HOURS_TO_APPROVE_ERRORED = 'FETCH_TRACKED_HOURS_TO_APPROVE_ERRORED',

  FETCH_TRACKED_HOURS_SUMMARY_START = 'FETCH_TRACKED_HOURS_SUMMARY_START',
  FETCH_TRACKED_HOURS_SUMMARY_SUCCESS = 'FETCH_TRACKED_HOURS_SUMMARY_SUCCESS',
  FETCH_TRACKED_HOURS_SUMMARY_ERRORED = 'FETCH_TRACKED_HOURS_SUMMARY_ERRORED',

  FETCH_TRACKED_HOURS_PIE_CHART_START = 'FETCH_TRACKED_HOURS_PIE_CHART_START',
  FETCH_TRACKED_HOURS_PIE_CHART_SUCCESS = 'FETCH_TRACKED_HOURS_PIE_CHART_SUCCESS',
  FETCH_TRACKED_HOURS_PIE_CHART_ERRORED = 'FETCH_TRACKED_HOURS_PIE_CHART_ERRORED',

  FETCH_TRACKED_HOURS_BAR_CHART_START = 'FETCH_TRACKED_HOURS_BAR_CHART_START',
  FETCH_TRACKED_HOURS_BAR_CHART_SUCCESS = 'FETCH_TRACKED_HOURS_BAR_CHART_SUCCESS',
  FETCH_TRACKED_HOURS_BAR_CHART_ERRORED = 'FETCH_TRACKED_HOURS_BAR_CHART_ERRORED',

  FETCH_SINGLE_TRACKED_HOUR_START = 'FETCH_SINGLE_TRACKED_HOUR_START',
  FETCH_SINGLE_TRACKED_HOUR_SUCCESS = 'FETCH_SINGLE_TRACKED_HOUR_SUCCESS',
  FETCH_SINGLE_TRACKED_HOUR_ERRORED = 'FETCH_SINGLE_TRACKED_HOUR_ERRORED',
}

export interface TrackingsState {
  trackedHoursDetails: {
    data?: Array<TrackedHourDTO>;
    meta?: MetaPagination;
    overallTotal?: number;
    sectionTotal?: number;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  singleTrackedHour: {
    data?: TrackedHourDTO;
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  trackedHoursToApprove: {
    data?: Array<TrackedHourDTO>;
    overallTotal?: number;
    sectionTotal?: number;
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  trackedHoursSummary: {
    data?: TrackedHoursSummaryResponse;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  trackedHoursPieChart: {
    data?: Array<TrackedHourPieChartDTO>;
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  trackedHoursBarChart: {
    data?: TrackedHoursBarChartDTO[];
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
}
