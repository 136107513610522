import React from 'react';
import { Col, Row, Skeleton, Space } from 'antd';

import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { EmployeeWelcomeScreen, MissionData } from 'types/Employee';

interface IProps {
  data: EmployeeWelcomeScreen | undefined;
  arrayMissionData: MissionData[];
}

export default function ExpectationsAndResponsibilities({
  data,
  arrayMissionData,
}: IProps) {
  if (typeof data === 'undefined') {
    return (
      <CardItem>
        <Skeleton title loading active paragraph={{ rows: 10, width: 0 }} />
      </CardItem>
    );
  }

  return (
    <CardItem title="Understand your expectations and responsibilities">
      <Row gutter={[0, 20]}>
        <Col>
          <Styled.RowItem justify="start" gutter={[10, 0]} last>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Row gutter={[0, 10]} align="middle">
                  <Col>
                    <Space align="center">
                      <Styled.ItemLabel>
                        The last step of the onboarding process is understanding
                        your expectations and responsibilities. These are the
                        key elements that define your role and your success.
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[0, 10]} align="middle">
                  {arrayMissionData?.map((item: MissionData) => {
                    if (
                      item.defaultValue === 'Responsibilities' ||
                      item.defaultValue === 'Expectations'
                    ) {
                      return (
                        <>
                          <Col span={24}>
                            <Styled.ItemValue>
                              {item.defaultValue}
                            </Styled.ItemValue>
                          </Col>
                          <Col span={24}>
                            <Space align="center">
                              <Styled.ItemLabel>
                                {item.description}
                              </Styled.ItemLabel>
                            </Space>
                          </Col>
                        </>
                      );
                    }
                    return null;
                  })}
                </Row>
                <Row>
                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        You can find more details about your expectations and
                        responsibilities on your dashboard. Please review them
                        carefully and ask any questions you may have.
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Styled.RowItem>
        </Col>
      </Row>
    </CardItem>
  );
}
