// hrDutiesActionTypes.js
export const CHECK_HR_DUTIES_COMPLETION_REQUEST =
  'CHECK_HR_DUTIES_COMPLETION_REQUEST';
export const CHECK_HR_DUTIES_COMPLETION_SUCCESS =
  'CHECK_HR_DUTIES_COMPLETION_SUCCESS';
export const CHECK_HR_DUTIES_COMPLETION_SUCCESS_WORK_POSITION =
  'CHECK_HR_DUTIES_COMPLETION_SUCCESS_POSITION';
export const CHECK_HR_DUTIES_COMPLETION_SUCCESS_DEPARTMENT =
  'CHECK_HR_DUTIES_COMPLETION_SUCCESS_POSITION_DEPARTMENT';
export const CHECK_HR_DUTIES_COMPLETION_FAILURE =
  'CHECK_HR_DUTIES_COMPLETION_FAILURE';
export const SET_HR_DUTIES_COMPLETION_STATUS =
  'SET_HR_DUTIES_COMPLETION_STATUS';
export const CLOSE_BANNER = 'CLOSE_BANNER';

export interface HrDutiesState {
  data: {
    department: boolean;
    workPosition: boolean;
  };
  loaded: boolean;
  loading: boolean;
  error: any;
}
