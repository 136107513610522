import React from 'react';
import { Tabs, TabPaneProps } from 'antd';

const { TabPane } = Tabs;

interface BaseTabProps extends TabPaneProps {
  key: string;
  tab: string;
}

const BaseTab: React.FC<BaseTabProps> = (props) => (
  <TabPane {...props}>{props.children}</TabPane>
);

export default BaseTab;
