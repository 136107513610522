import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { Line } from 'react-chartjs-2';
import Spin from 'antd/lib/spin';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Select from 'components/NewForms/Select';
import CardItem from 'ui-v2/components/Card';
import {
  getAllJobCategoriesWithSubCategories,
  getSkillsMatrixChartData,
} from 'api/jobCategoryService';
import { SkillSubCategory } from 'types/Skills';
import { OptionType } from 'types/OptionTypes';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LineChart = () => {
  const [loading, setLoading] = useState(true);
  const [skillsList, setSkillsList] = useState<Array<OptionType>>([]);
  const [currentSkill, setCurrentSkill] = useState<string>();
  const [chartData, setChartData] = useState<any>();
  const { t } = useTranslation();

  const fetchSkills = useCallback(async () => {
    getAllJobCategoriesWithSubCategories().then((response) => {
      setSkillsList(
        response.data
          .map((skillCategory: any) => skillCategory.subCategories)
          .flat()
          .map((item: SkillSubCategory) => ({
            id: item.id,
            label: item.subCategoryName,
            value: item.id,
          }))
      );
    });
  }, []);

  const fetchChartData = async (skillId: string) => {
    setLoading(true);
    getSkillsMatrixChartData(skillId)
      .then((response) => {
        setChartData({
          labels,
          datasets: [
            {
              label: t('employeeswiththatSkill'),
              data: labels.map(
                (_: string, index: number) =>
                  response.data.employees.find(
                    (item: any) => Number(item.month) === index + 1
                  )?.employee_number ?? 0
              ),
              borderColor: '#E31B54',
              backgroundColor: '#E31B54',
            },
            {
              label: t('candidateswiththatSkill'),
              data: labels.map(
                (_: string, index: number) =>
                  response.data.candidates.find(
                    (item: any) => Number(item.month) === index + 1
                  )?.candidate_number ?? 0
              ),
              backgroundColor: '#FDB022',
              borderColor: '#FDB022',
            },
            {
              label: t('projectswiththatSkill'),
              data: labels.map(
                (_: string, index: number) =>
                  response.data.projects.find(
                    (item: any) => Number(item.month) === index + 1
                  )?.projects ?? 0
              ),
              backgroundColor: '#09925C',
              borderColor: '#09925C',
            },
            {
              label: t('openpositionswiththatSkill'),
              data: labels.map(
                (_: string, index: number) =>
                  response.data.openPositions.find(
                    (item: any) => Number(item.month) === index + 1
                  )?.open_positions ?? 0
              ),
              backgroundColor: '#60a5fa',
              borderColor: 'blue',
            },
          ],
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (currentSkill) {
      fetchChartData(currentSkill);
    }
  }, [currentSkill]);

  useEffect(() => {
    if (skillsList[0]) {
      setCurrentSkill(skillsList[0].id);
    }
  }, [skillsList]);

  const noData = chartData?.datasets.every(
    (dataset: any) => dataset.data.length === 0
  );

  return (
    <CardItem
      title={
        <Row justify="space-between" align="top">
          <Col>{t('skills')}</Col>
          {currentSkill && (
            <Col span={24}>
              <Row justify="center">
                <Col span={8}>
                  <Select
                    placeholder={t('selectSkill')}
                    name="skill"
                    selectOptions={skillsList}
                    hidePlaceholder
                    onSelect={setCurrentSkill}
                    showSearch
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      }
      minHeight={450}
    >
      <>
        <Spin spinning={loading}>
          {!loading && noData && (
            <Row
              justify="center"
              align="middle"
              style={{
                minHeight: 350,
              }}
            >
              <Col>
                <Typography.Text
                  style={{
                    display: 'block',
                    textAlign: 'center',
                  }}
                >
                  {t('noDataYet.')}
                </Typography.Text>
                <Typography.Link
                  style={{
                    display: 'block',
                    textAlign: 'center',
                  }}
                  href="candidates/add-candidate"
                >
                  {t('Add some records first!')}
                </Typography.Link>
              </Col>
            </Row>
          )}

          {chartData && !noData && (
            <Line
              redraw
              data={chartData}
              options={{
                scales: {
                  yAxes: {
                    grid: {
                      color: '#FFF',
                    },
                  },
                },
              }}
            />
          )}
        </Spin>
      </>
    </CardItem>
  );
};

export default LineChart;
