import React from 'react';

export const ScheduleIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 8.33366H1.5M16.5 10.417V7.33366C16.5 5.93353 16.5 5.23346 16.2275 4.69868C15.9878 4.22828 15.6054 3.84583 15.135 3.60614C14.6002 3.33366 13.9001 3.33366 12.5 3.33366H5.5C4.09987 3.33366 3.3998 3.33366 2.86502 3.60614C2.39462 3.84583 2.01217 4.22828 1.77248 4.69868C1.5 5.23346 1.5 5.93353 1.5 7.33366V14.3337C1.5 15.7338 1.5 16.4339 1.77248 16.9686C2.01217 17.439 2.39462 17.8215 2.86502 18.0612C3.3998 18.3337 4.09987 18.3337 5.5 18.3337H9M12.3333 1.66699V5.00033M5.66667 1.66699V5.00033M11.0833 15.8337L12.75 17.5003L16.5 13.7503"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
