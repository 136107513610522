import { Badge, Row, Typography } from 'antd';
import styled from 'styled-components';

export const BadgeStyle = styled(Badge)`
  border: '1px solid #d9d9d9';
`;

export const RowItemEmployee = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
  margin-bottom: 10px;
`;

export const EmployeeWorkPosition = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

export const EmployeeName = styled(Typography)`
  font-weight: 400;
  text-transform: capitalize;
`;
