/* eslint-disable @typescript-eslint/no-namespace */
import React from 'react';
import { Col, Popover, Row, Space } from 'antd';
import {
  PopoverOverlayInnerStyles,
  PopoverOverlayStyles,
} from 'ui-v2/components/TableFilterPopover/TableFilterPopoverStyles';
import moment from 'moment';
import { convertUTCtoLocalTime } from 'utils/utilFunctions';
import { TableRowCellDataType } from 'types/GridTable';
import { getTenantAvatarStyle } from 'ui-v2/routes/superAdmin/TenantAccountsList/utils';
import {
  StyledTrackingHourTypeTag,
  StyledTrackingPoint,
  StyledTrackingTag,
  StyledTrackingText,
} from 'ui-v2/routes/tracking/StyledTracking';
import { formatTotalMinutes } from 'ui-v2/routes/tracking/utils';
import {
  TrackedHourDTO,
  TrackedHourStatus,
  TrackedHourSummaryDTO,
  TrackedHourType,
} from 'types/tracking';
import { CheckIcon } from 'Icons/CheckIcon';
import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { DEFAULT_USER_DATE_FORMAT, USER_DATE_FORMAT } from 'utils/constants';
import i18next from '../../i18n';

export const getRandomColor = (name?: string) =>
  getTenantAvatarStyle({
    initials: name ?? '',
    saturation: 80,
    lightness: 40,
  }).backgroundColor;

export namespace TrackingHoursDetailedTableUtils {
  export interface TrackingHoursColumnsType extends TableRowCellDataType {
    key?: keyof TrackedHourDTO | keyof TrackedHourSummaryDTO;
    dataIndex?: keyof TrackedHourDTO | keyof TrackedHourSummaryDTO;
    sorter?: (a: TrackedHourDTO, b: TrackedHourDTO) => any;
    render?: (_: string, value: TrackedHourDTO) => any;
  }
  export const SortTimeEntryData = (a: TrackedHourDTO, b: TrackedHourDTO) =>
    a?.assignment?.project?.name.localeCompare(b?.assignment?.project?.name);

  export const SortEmployeeData = (a: TrackedHourDTO, b: TrackedHourDTO) =>
    a?.employee?.firstName.localeCompare(b?.employee?.lastName);

  export function TimeEntryCellData(name: string, item: TrackedHourDTO) {
    const onProjectClick = () => {
      if (!item?.assignment?.project?.id) {
        return;
      }
      window.open(
        `/projects/project-details?id=${item?.assignment?.project?.id}`
      );
    };
    return (
      <Row align="middle" gutter={[10, 0]}>
        <Col>
          <Popover
            overlayStyle={PopoverOverlayStyles}
            overlayInnerStyle={PopoverOverlayInnerStyles}
            content={item?.description ?? ''}
          >
            <StyledTrackingTag light>
              {item?.description ?? ''}
            </StyledTrackingTag>
          </Popover>
        </Col>
        <Col>
          <Row align="middle" gutter={[10, 0]}>
            <Col>
              <StyledTrackingPoint
                color={
                  getTenantAvatarStyle({
                    initials:
                      item?.assignment?.project?.name ||
                      'Standart working hours',
                  }).backgroundColor
                }
              />
            </Col>
            <Col>
              <StyledTrackingText
                onClick={onProjectClick}
                cursorPointer
                color={
                  getTenantAvatarStyle({
                    initials:
                      item?.assignment?.project?.name ||
                      'Standart working hours',
                  }).backgroundColor
                }
              >
                {item?.assignment?.project?.name || 'Standart working hours'}
              </StyledTrackingText>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  export function EmployeeCellData(name: string, item: any) {
    return (
      <Row gutter={[0, 5]}>
        <Col span={24}>
          <StyledTrackingText bold>
            {`${item?.employee?.firstName ?? ''} ${item?.employee?.lastName}`}
          </StyledTrackingText>
        </Col>
        <Col span={24}>
          <StyledTrackingText light>
            {`${
              item?.assignment?.rolesAssignment?.name
                ? item?.assignment?.rolesAssignment?.name
                : i18next.t('noProjectRoleAssigned')
            }`}
          </StyledTrackingText>
        </Col>
      </Row>
    );
  }

  export function DateCellData(_: string, item: TrackedHourDTO) {
    const userDateFormat =
      localStorage.getItem(USER_DATE_FORMAT) || DEFAULT_USER_DATE_FORMAT;
    const overTime = item?.hourType === 'overtime hours';
    const formatedStartTime = moment(
      convertUTCtoLocalTime(item?.startTime)
    ).format('HH:mm');
    const formatedEndTime = moment(convertUTCtoLocalTime(item?.endTime)).format(
      'HH:mm'
    );
    const formatedDate = moment(
      convertUTCtoLocalTime(overTime ? item?.startTime : item?.date)
    ).format(userDateFormat);
    const isPlusOneDay =
      moment(item?.endTime).get('date') > moment(item?.startTime).get('date');

    return (
      <Row gutter={[0, 10]}>
        <Col>
          <Row justify="space-around" align="middle" gutter={[10, 0]}>
            <Col>
              <StyledTrackingText>{formatedStartTime}</StyledTrackingText>
            </Col>
            <Col>
              <Space>
                <StyledTrackingText>{formatedEndTime}</StyledTrackingText>
                {isPlusOneDay && (
                  <StyledTrackingText>
                    <sup>+1</sup>
                  </StyledTrackingText>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <StyledTrackingText light>{formatedDate}</StyledTrackingText>
        </Col>
      </Row>
    );
  }

  export function DurationCellData(name: string, item: TrackedHourDTO) {
    const duration = Math.round(
      moment
        .duration(moment(item.endTime).diff(moment(item.startTime)))
        .asMinutes()
    );
    return (
      <StyledTrackingTag bold>{formatTotalMinutes(duration)}</StyledTrackingTag>
    );
  }

  export function HourTypeCellData(name: string, item: TrackedHourDTO) {
    const Content = ({
      hourStatusContent,
      hourTypeContent,
    }: {
      hourTypeContent: React.ReactNode;
      hourStatusContent: React.ReactNode;
    }) => (
      <Row gutter={[10, 10]}>
        <Col>{hourTypeContent}</Col>
        <Col>{hourStatusContent}</Col>
      </Row>
    );

    if (!item.hourType || !item.aprovalStatus) {
      return null;
    }

    if (item?.hourType === TrackedHourType.OVERTIME_HOURS) {
      if (item?.aprovalStatus === TrackedHourStatus.APPROVED) {
        return (
          <Content
            hourTypeContent={
              <StyledTrackingHourTypeTag capitalize hourType={item.hourType}>
                {item.hourType}
              </StyledTrackingHourTypeTag>
            }
            hourStatusContent={
              <StyledTrackingTag variant="success">
                <Space>
                  <CheckIcon />
                  {item.aprovalStatus}
                </Space>
              </StyledTrackingTag>
            }
          />
        );
      }
      if (item?.aprovalStatus === TrackedHourStatus.REJECTED) {
        return (
          <Content
            hourTypeContent={
              <StyledTrackingHourTypeTag capitalize hourType={item.hourType}>
                {item.hourType}
              </StyledTrackingHourTypeTag>
            }
            hourStatusContent={
              <StyledTrackingTag variant="danger">
                <Space>
                  <CloseOutlined />
                  {item.aprovalStatus}
                </Space>
              </StyledTrackingTag>
            }
          />
        );
      }
      if (item?.aprovalStatus === TrackedHourStatus.PENDING) {
        return (
          <Content
            hourTypeContent={
              <StyledTrackingHourTypeTag capitalize hourType={item.hourType}>
                {item.hourType}
              </StyledTrackingHourTypeTag>
            }
            hourStatusContent={
              <StyledTrackingTag variant="info">
                <Space>
                  <ClockCircleOutlined />
                  {item.aprovalStatus}
                </Space>
              </StyledTrackingTag>
            }
          />
        );
      }
    }

    return (
      <Content
        hourTypeContent={
          <StyledTrackingHourTypeTag
            hourType={item.hourType as TrackedHourType}
            capitalize
          >
            {item?.hourType}
          </StyledTrackingHourTypeTag>
        }
        hourStatusContent={null}
      />
    );
  }
}
