import { Reducer } from 'react';
import { Action } from 'redux';
import { PurchaseOrderState, PurchaseOrders } from './types';

const initialState: PurchaseOrderState = {
  data: {
    data: [],
    meta: null,
  },
  purchaseOrderDetail: null,
  loaded: false,
  loading: false,
  errored: false,
  error: null,
};

const reducer: Reducer<PurchaseOrderState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case PurchaseOrders.FETCH_PURCHASE_ORDERS_START:
      state = { ...state, loading: true };

      break;

    case PurchaseOrders.FETCH_PURCHASE_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case PurchaseOrders.FETCH_PURCHASE_ORDERS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;

    case PurchaseOrders.FETCH_PURCHASE_ORDER_START:
      state = { ...state, loading: true };
      break;
    case PurchaseOrders.FETCH_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        purchaseOrderDetail: action.payload,
      };
      break;
    case PurchaseOrders.FETCH_PURCHASE_ORDER_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;

    case PurchaseOrders.CLEAR_PURCHASE_ORDER_STATE:
      state = { ...state, purchaseOrderDetail: null };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as PurchaseOrderReducer };
