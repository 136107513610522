import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { DepartmentModelType } from 'types/Department';
import DepartmentsActions from 'components/Actions/DepartmentsActions';
import i18next from '../i18n';

export const DepartmentsColumns = [
  {
    title: i18next.t('department'),
    dataIndex: 'department_name',
    key: 'department_name',
    ellipsis: true,
    sorter: (a: DepartmentModelType, b: DepartmentModelType) =>
      a.department_name.localeCompare(b.department_name),
    render: (_: string, item: DepartmentModelType) => (
      <Styled.TextBold>{item?.department_name}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('employeesNumber'),
    dataIndex: 'employeeCount',
    key: 'employeeCount',
    render: (_: string, item: DepartmentModelType) => (
      <Styled.TextBold>
        {`${
          item?.employeeCount > 0 ? item?.employeeCount : 'No employees yet'
        }`}
      </Styled.TextBold>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: DepartmentModelType) => (
      <DepartmentsActions id={row.department_id} />
    ),
    default: false,
    order: 2,
  },
];
