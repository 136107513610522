import { isNil } from 'lodash';
import moment from 'moment';

import { DEFAULT_USER_DATE_FORMAT } from 'utils/constants';
import { checkEquality, convertDateToUTC } from 'utils/utilFunctions';

type Entity =
  | 'project'
  | 'department'
  | 'evaluation'
  | 'training'
  | 'trackingHours'
  | 'users'
  | 'workPosition'
  | 'payrollRule'
  | 'interview'
  | 'candidate'
  | 'openPosition'
  | 'purchaseRequest';

export function isFormEdited({
  formValues,
  valuesToCheck,
  entity,
}: {
  formValues: any;
  valuesToCheck: any;
  entity: Entity;
}): boolean {
  switch (entity) {
    case 'department':
      return checkEquality(
        {
          name: !isNil(formValues?.name) && formValues?.name,
        },
        {
          name: !isNil(valuesToCheck?.name) && valuesToCheck?.name,
        }
      );
    case 'project':
      return checkEquality(
        {
          ...formValues,
          startDate:
            !isNil(formValues?.startDate) &&
            moment(formValues.startDate).format(DEFAULT_USER_DATE_FORMAT),
          endDate:
            !isNil(formValues?.endDate) &&
            moment(formValues.endDate).format(DEFAULT_USER_DATE_FORMAT),
        },
        {
          description: valuesToCheck?.description,
          name: valuesToCheck?.name,
          noEndPeriod: valuesToCheck?.noEndPeriod,
          startDate:
            !isNil(valuesToCheck?.startDate) &&
            moment(valuesToCheck?.startDate).format(DEFAULT_USER_DATE_FORMAT),
          endDate:
            !isNil(valuesToCheck?.endDate) &&
            moment(valuesToCheck?.endDate).format(DEFAULT_USER_DATE_FORMAT),
        }
      );
    case 'evaluation':
      return checkEquality(
        {
          ...formValues,
          startDate: moment(formValues?.startDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
          endDate: moment(formValues?.endDate).format(DEFAULT_USER_DATE_FORMAT),
        },
        {
          employeeFeedback: valuesToCheck?.employeeFeedback,
          employeeId: valuesToCheck?.employee?.id,
          startDate: moment(valuesToCheck?.startDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
          endDate: moment(valuesToCheck?.endDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
          isActive: valuesToCheck?.isActive,
        }
      );
    case 'training':
      return checkEquality(formValues, {
        id: valuesToCheck.id,
        name: valuesToCheck?.name,
        timeType: valuesToCheck?.timeType,
        skills: valuesToCheck?.skills,
        currecyCertification: valuesToCheck?.currecyCertification?.id,
        certificationCost: valuesToCheck?.certificationCost,
        valueTime: valuesToCheck?.valueTime,
        cost: valuesToCheck?.cost,
        status: valuesToCheck?.status,
        type: valuesToCheck?.type,
        currecyTraining: valuesToCheck?.currecyTraining?.id,
        certificationName: valuesToCheck?.certificationName,
      });
    case 'trackingHours':
      return checkEquality(
        { ...formValues, date: moment(formValues.date).format('YYYY-MM-DD') },
        {
          hourType: valuesToCheck?.hourType,
          isRemote: valuesToCheck?.isRemote,
          date: valuesToCheck?.date?.toString(),
          startTime: convertDateToUTC(valuesToCheck?.startTime),
          endTime: convertDateToUTC(valuesToCheck?.endTime),
          description: valuesToCheck?.description,
          ...(valuesToCheck?.assignment?.id && {
            assignmentId: valuesToCheck?.assignment?.id,
          }),
          ...(valuesToCheck?.employee?.id && {
            employeeId: valuesToCheck?.employee?.id,
          }),
        }
      );
    case 'users':
      return checkEquality(
        {
          ...formValues,
          birthDate: moment(formValues.birthDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
        },
        {
          firstName: valuesToCheck?.firstName,
          lastName: valuesToCheck?.lastName,
          birthDate: moment(valuesToCheck?.birthDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
          phoneNumber: valuesToCheck?.phoneNumber,
          isActive: valuesToCheck?.isActive,
        }
      );
    case 'workPosition':
      return checkEquality(formValues, {
        name: valuesToCheck?.name,
        requirements: valuesToCheck?.requirements,
        isHeadDepartment: valuesToCheck?.isHeadDepartment,
        departmentId: valuesToCheck?.department?.id,
      });
    case 'purchaseRequest':
      return checkEquality(formValues, {
        date: moment(formValues.date).format(DEFAULT_USER_DATE_FORMAT),
        time: valuesToCheck?.time,
        estimatedCost: valuesToCheck?.estimatedCost,
        requestingPerson: valuesToCheck?.requestingPerson,
        currencyId: valuesToCheck?.currency?.id,
      });
    case 'payrollRule':
      return checkEquality(formValues, {
        humanID: valuesToCheck?.humanID,
        name: valuesToCheck?.name,
        description: valuesToCheck?.description,
        type: valuesToCheck?.type,
        currencyId: valuesToCheck?.currency?.id,
        parameters: {
          from: valuesToCheck?.parameters?.from,
          to: valuesToCheck?.parameters?.to,
          value: valuesToCheck?.parameters?.value,
          valueType: valuesToCheck?.parameters?.valueType,
        },
      });
    case 'interview':
      delete formValues?.partecipants;
      return checkEquality(formValues, {
        candidateId: valuesToCheck?.candidateOpenPositions?.candidate?.id,
        candidateOpenPositionId: valuesToCheck?.candidateOpenPositions?.id,
        comments: valuesToCheck?.comments,
        date: convertDateToUTC(valuesToCheck?.date),
        interviewTypeId: valuesToCheck?.interviewType?.id,
      });
    case 'candidate':
      return checkEquality(
        {
          ...formValues,
          birthDate: moment(formValues?.birthDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
        },
        {
          birthDate: moment(valuesToCheck?.birthDate).format(
            DEFAULT_USER_DATE_FORMAT
          ),
          candidateOpenPositions: valuesToCheck?.candidateOpenPositions?.map(
            (el: any) => ({
              openPositionId: el?.openPositions?.id,
              stageId: el?.stages?.id,
            })
          ),
          certifications: valuesToCheck?.certifications?.map(
            (el: any) => el?.id
          ),
          currencyId: valuesToCheck?.currency?.id,
          currentJobTitle: valuesToCheck?.currentJobTitle,
          email: valuesToCheck?.email,
          expectedSalary: valuesToCheck?.expectedSalary,
          firstName: valuesToCheck?.firstName,
          lastName: valuesToCheck?.lastName,
          gender: valuesToCheck?.gender,
          notes: valuesToCheck?.notes,
          phoneNumber: valuesToCheck?.phoneNumber,
          phoneNumberPrefix: valuesToCheck?.phoneNumberPrefix,
          referalType: valuesToCheck?.referalType,
          skillSubCategories: valuesToCheck?.skillSubCategoriesCandidates?.map(
            (el: any) => ({
              score: el?.score,
              skillSubCategoryId: el?.skillsSubCategory?.id,
              yearsOfExperience: el?.yearsOfExperience,
            })
          ),
        }
      );
    default:
      return false;
  }
}
