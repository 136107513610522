/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Result, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const REDIRECT_TIMEOUT = 3;
export function PasswordValidationSuccess() {
  const [countDown, setCountDown] = useState(REDIRECT_TIMEOUT);
  const navigate = useNavigate();

  useEffect(() => {
    const decrementCountdown = () => setCountDown((countDown) => countDown - 1);
    const interval = setInterval(() => {
      decrementCountdown();
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      navigate('/login');
    }, REDIRECT_TIMEOUT * 1000);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Result title={t('Password was set successfully!')} status="success" />
      <Row justify="center" align="middle">
        <Col>
          {`${t('Redirecting to Login in')} ${
            countDown > 0 ? `${countDown}` : ''
          }`}
        </Col>
      </Row>
    </>
  );
}
