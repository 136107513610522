import { Avatar, Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { EmployeeType } from 'types/Employee';
import {
  EmployeeName,
  NodataLabel,
  RowItemEmployee,
} from './TrainingItemStyled';

interface IProps {
  employee: EmployeeType;
}

export function EmployeeTrainingItem({ employee }: IProps) {
  const capitalName = `${employee?.firstName
    .substring(0, 1)
    ?.toUpperCase()}${employee?.lastName.substring(0, 1)?.toUpperCase()}`;

  return (
    <>
      <Link
        to={`/employees/employee-profile?id=${employee.id}`}
        style={{ width: '100%' }}
      >
        <RowItemEmployee>
          <Col span={3}>
            <Avatar
              style={{
                color: '#f56a00',
                backgroundColor: '#fde3cf',
              }}
            >
              {capitalName}
            </Avatar>
          </Col>
          <Col span={10}>
            <EmployeeName>{`${employee?.firstName} ${employee?.lastName}`}</EmployeeName>
          </Col>
          <Col span={10}>
            <NodataLabel ellipsis>{employee?.workEmail}</NodataLabel>
          </Col>
        </RowItemEmployee>
      </Link>
    </>
  );
}
