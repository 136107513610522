import { requestTimeOff } from 'api/timeOffService';
import { fetchEmployeeOptions } from 'components/NewForms/SelectWithLoad/utils';
import moment from 'moment';
import { EmployeeType } from 'types/Employee';
import { InputTypes } from 'types/FormTypes';
import {
  IApprover,
  ITimeOffRequestDTO,
  ReasonType,
  TimeOffRequest,
  TimeOffStatus,
} from 'types/TimeOff';
import i18next from '../../../../../i18n';

export function submitRequestTimeOff(
  formValues: any,
  timeOff: any,
  employeeId: string
) {
  return requestTimeOff({
    dates: timeOff,
    reason: formValues.reason,
    approvers: formValues.approvers?.map((item: any) => item.value),
    substitutes: formValues.substitutes?.map(
      (item: EmployeeType) => item.value
    ),
    description: formValues?.description,
    employeeId,
  });
}

export const RequestTimeOffFormConfiguration = [
  [
    {
      col: 24,
      offset: 0,
      name: 'reason',
      label: i18next.t('timeoffType'),
      type: InputTypes.SELECT,
      selectOptions: Object.values(ReasonType).map((item: string) => ({
        id: item,
        value: item,
        label: i18next.t(item),
      })),
      rules: [
        {
          required: true,
          message: i18next.t('selectTimeoffCategory'),
        },
      ],
    },
  ],
  [
    {
      col: 24,
      offset: 0,
      name: 'approvers',
      label: i18next.t('approvedBy'),
      type: InputTypes.SELECT_LOAD,
      fetchOptions: fetchEmployeeOptions,
      mode: 'multiple',
      rules: [
        {
          required: true,
          message: i18next.t('selectEmployeeToApprove'),
        },
      ],
    },
  ],
  [
    {
      col: 24,
      offset: 0,
      name: 'description',
      label: i18next.t('description'),
      type: InputTypes.TEXTAREA,
    },
  ],
];

export function getRequestStatus(approvers: any) {
  if (!approvers.length) return TimeOffStatus.PENDING;

  const flags = approvers.map((i: any) => i.action);

  if (flags.includes(TimeOffStatus.PENDING)) return TimeOffStatus.PENDING;
  if (flags.includes(TimeOffStatus.REJECTED)) return TimeOffStatus.REJECTED;

  return TimeOffStatus.APPROVED;
}

export function getTimeOffStatusColor(status: TimeOffStatus, hex?: boolean) {
  switch (status) {
    case TimeOffStatus.PENDING:
      return hex ? '#1890FF' : 'processing';
    case TimeOffStatus.APPROVED:
      return hex ? '#52C41A' : 'success';
    case TimeOffStatus.REJECTED:
      return hex ? '#FF4D4F' : 'error';
    default:
      return hex ? '#1890FF' : 'processing';
  }
}

export function getTotalTimeOffTimeHours(request: TimeOffRequest) {
  const dates = JSON.parse(request?.dates);

  if (dates.length === 1) {
    const startDate = moment(dates[0].timeOffStart);
    const endDate = moment(dates[0].timeOffEnd);

    const totalDuration = moment.duration(endDate.diff(startDate));
    const totalHours = Math.floor(totalDuration.asHours());
    const totalMinutes = totalDuration.minutes();

    return `${totalHours}:${totalMinutes.toString().padStart(2, '0')}`;
  }

  if (dates.length > 1) {
    const startDate = moment(dates[0].date);
    const endDate = moment(dates[dates.length - 1].date);

    const totalDuration = moment.duration(endDate.diff(startDate));
    const totalHours = Math.floor(totalDuration.asHours());
    const totalMinutes = totalDuration.minutes();

    return `${totalHours}:${totalMinutes.toString().padStart(2, '0')}`;
  }

  return '00:00';
}

export function mapTimeOffRequests(data: any) {
  if (!data) return [];
  return data?.map((item: any) => {
    const status = getRequestStatus(item.approver);
    return {
      ...item,
      status,
      statusColor: getTimeOffStatusColor(status),
      approvers: item.approver.map((approver: IApprover) => ({
        ...approver,
        color: getTimeOffStatusColor(approver.action as TimeOffStatus, true),
      })),
    };
  });
}

/**
 *
 * Map approver employees to respective TimeOff record
 * Add status, statusColor in the same iteration
 *
 * @param data ITimeOffRequestDTO
 * @returns TimeOffRequest[]
 */
export function mapTimeOffToApprove(
  data: ITimeOffRequestDTO
): TimeOffRequest[] {
  const uniqueTimeOffIds: string[] = Array.from(
    new Set(data.timeOffs?.map((item: any) => item.timeOffId))
  );

  const timeOffRequests: any[] = [];

  uniqueTimeOffIds.forEach((timeOffId) => {
    const timeOff = data.timeOffs.find((i: any) => i.timeOffId === timeOffId);
    const requestingEmployeee = data.employees.find(
      (item: EmployeeType) => item.id === timeOff?.employeeId
    );

    const approvers: any[] = data.timeOffs
      .filter((item: any) => item.timeOffId === timeOffId)
      .map((item: any) => ({
        ...item,
        employee: {
          id: item.approveremployeeid,
          firstName: item.approvername,
          lastName: item.approverlastname,
        },
      }));

    const preferedSubstitute: any[] = data.timeOffs
      .filter((item: any) => item.timeOffId === timeOffId)
      .map((item: any) => ({
        id: item?.substituteid,
        firstName: item?.substitutefirstname,
        lastName: item?.substitutelastname,
        email: item?.substituteemail,
      }));

    const status = getRequestStatus(approvers);

    timeOffRequests.push({
      id: timeOffId,
      approvers,
      status,
      dates: timeOff?.dates,
      reason: timeOff?.reason,
      employee: requestingEmployeee,
      statusColor: getTimeOffStatusColor(status),
      substitutes: data?.substitutes,
      preferedSubstitute,
    });
  });

  return timeOffRequests;
}

export function sortByStatus(timeOffRequests: TimeOffRequest[]) {
  const result: TimeOffRequest[] = [];

  Object.keys(TimeOffStatus).forEach((key: any) => {
    result.push(
      ...timeOffRequests.filter(
        (t) => t.status === TimeOffStatus[key as TimeOffStatus]
      )
    );
  });

  return result;
}

export function extractDisabledDates(timeOff: TimeOffRequest[]) {
  return timeOff
    .map((t) => JSON.parse(t.dates))
    .flat()
    .map((i) => i.date);
}
