import { Dispatch } from 'redux';
import {
  getTenants,
  getTenantDetails,
  getTenantUsers,
} from 'api/tenantService';
import {
  TenantsAccountsQueryParamsType,
  TenantsQueryParamsType,
} from 'types/QueryParams';
import { Tenants } from './types';

export const fetchTenants =
  ({ queryParams }: { queryParams?: TenantsQueryParamsType }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: Tenants.FETCH_TENANTS_START,
    });
    getTenants(queryParams)
      .then((res) => {
        dispatch({
          type: Tenants.FETCH_TENANTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Tenants.FETCH_TENANTS_ERRORED,
          payload: e,
        });
      });
  };

export const clearTenants = () => (dispatch: Dispatch) => {
  dispatch({
    type: Tenants.CLEAR_TENANTS,
  });
};

export const clearSingleTenant = () => (dispatch: Dispatch) => {
  dispatch({
    type: Tenants.CLEAR_SINGLE_TENANT,
  });
};

export const clearTenantAccounts = () => (dispatch: Dispatch) => {
  dispatch({
    type: Tenants.CLEAR_TENANT_ACCOUNTS,
  });
};

export const fetchTenantAccounts =
  (tenantId: string, queryParams?: TenantsAccountsQueryParamsType) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: Tenants.FETCH_TENANT_ACCOUNTS_START,
    });
    getTenantUsers(tenantId, queryParams)
      .then((res) => {
        dispatch({
          type: Tenants.FETCH_TENANTS_ACCOUNTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Tenants.FETCH_TENANTS_ACCOUNTS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleTenant = (tenantId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Tenants.FETCH_SINGLE_TENANT_START,
  });
  getTenantDetails(tenantId)
    .then((res) => {
      dispatch({
        type: Tenants.FETCH_SINGLE_TENANT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Tenants.FETCH_SINGLE_TENANT_ERRORED,
        payload: e,
      });
    });
};
