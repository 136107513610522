import { PlusIcon } from 'Icons/PlusIcon';
import { StyledButton } from 'components/NewForms/FormStyled';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AddVendrorQuestionnaireQuestionButtonProps {
  onClick: () => void;
}

const AddVendrorQuestionnaireQuestionButton: React.FC<
  AddVendrorQuestionnaireQuestionButtonProps
> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledButton type="primary" icon={<PlusIcon />} onClick={onClick}>
      {t('addVendorQuestionnaireQuestion')}
    </StyledButton>
  );
};

export default AddVendrorQuestionnaireQuestionButton;
