import { AssetType } from 'types/Asset';
import { MetaPagination } from 'types/GridTable';

export enum Assets {
  FETCH_ASSETS_START = 'FETCH_ASSETS_START',
  FETCH_ASSETS_SUCCESS = 'FETCH_ASSETS_SUCCESS',
  FETCH_ASSETS_ERRORED = 'FETCH_ASSETS_ERRORED',

  FETCH_ASSET_START = 'FETCH_ASSET_START',
  FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS',
  FETCH_ASSET_ERRORED = 'FETCH_ASSET_ERRORED',

  CLEAR_SINGLE_ASSET = 'CLEAR_SINGLE_ASSET',
}

export interface AssetsState {
  data: {
    data: Array<AssetType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface AssetState {
  data: AssetType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
