import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { Col, Row } from 'antd';

import CardItem from 'ui-v2/components/Card';
import GenericForm from 'components/NewForms/Form';
import { PasswordRulesType } from 'types/PasswordRules';
import { UserSetPassword } from 'types/User';
import { InputTypes } from 'types/FormTypes';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { toastErrorMessages } from 'utils/utilFunctions';
import { TENANT_ID } from 'utils/constants';
import {
  getUserPasswordRules,
  setUserPassword,
  verifyUserToken,
} from 'api/openRoutesService';
import { useTranslation } from 'react-i18next';
import { EmployeePasswordStatus } from '../constants';

type IProps = {
  setStatus: (status: string) => void;
};

export function EmployeeSetPasswordForm({ setStatus }: IProps) {
  const [form] = useForm();

  const { token } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [passwordRules, setPasswordRoles] = useState<PasswordRulesType>();
  const { t } = useTranslation();

  const verifyToken = (token: string) => {
    setLoading(true);
    verifyUserToken({ code: token })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          localStorage.setItem(TENANT_ID, response.data);
        }
      })
      .catch(() => {
        setStatus(EmployeePasswordStatus.SET_PASSWORD_FAIL);
      })
      .finally(() => setLoading(false));
  };

  const onFinish = (values: UserSetPassword) => {
    setLoading(true);
    if (!token) {
      setStatus(EmployeePasswordStatus.SET_PASSWORD_FAIL);
      return;
    }
    const payload: UserSetPassword = {
      ...values,
      code: token,
    };

    setUserPassword(payload)
      .then((response) => {
        if (response.status === 201) {
          setStatus(EmployeePasswordStatus.SET_PASSWORD_SUCCESS);
        }
      })
      .catch(() => {
        setStatus(EmployeePasswordStatus.SET_PASSWORD_FAIL);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTenantPasswordRules = () => {
    setLoading(true);
    getUserPasswordRules()
      .then((res) => {
        setPasswordRoles(res.data);
      })
      .catch((error) => toastErrorMessages(error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
      fetchTenantPasswordRules();
    }
  }, [token]);

  const EmployeeSetPasswordFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'password',
          label: t('password'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'password',
          },
          rules: [
            {
              required: true,
              message: t('Please enter your password!'),
            },
            {
              validator(_: any, value: any): Promise<any> {
                const isValid = new RegExp(passwordRules?.regex ?? '').test(
                  value
                );
                if (!isValid) {
                  setIsValidPassword(false);
                  form.resetFields(['confirmPassword']);
                  return Promise.reject(
                    new Error(passwordRules?.rules.join(''))
                  );
                }
                setIsValidPassword(true);
                return Promise.resolve();
              },
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'confirmPassword',
          label: t('confirmPassword'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'password',
          },
          disabled: !isValidPassword,
          rules: [
            {
              required: true,
              message: t('Please confirm your password'),
            },
            {
              validator(_: any, value: string): Promise<any> {
                if (!value || form.getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('Passwords do not match!')));
              },
            },
          ],
        },
      ],
    ],
    [isValidPassword, passwordRules]
  );

  return (
    <CardItem title={t('Set Password')}>
      <GenericForm
        form={form}
        loading={loading}
        onFinish={onFinish}
        formConfiguration={EmployeeSetPasswordFormConfiguration}
      >
        <FormItemStyled className="set-password-form">
          <Row>
            <Col span={11}>
              <StyledButton htmlType="reset" type="primary" ghost>
                {t('Resend Email')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('save')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </CardItem>
  );
}
