import { Reducer } from 'react';
import { Action } from 'redux';

import { WorkPositionState, WorkPositions, WorkPositionsState } from './types';

const initialWorkPositionState: WorkPositionState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};
const WorkPositionReducer: Reducer<WorkPositionState, Action> = (
  state = initialWorkPositionState,
  action: any
) => {
  switch (action.type) {
    case WorkPositions.FETCH_WORK_POSITION_START:
      state = { ...state, loading: true };
      break;
    case WorkPositions.FETCH_WORK_POSITION_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case WorkPositions.FETCH_WORK_POSITION_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case WorkPositions.CLEAR_WORK_POSITION_STATE:
      state = initialWorkPositionState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialWorkPositionsState: WorkPositionsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const WorkPositionsReducer: Reducer<WorkPositionsState, Action> = (
  state = initialWorkPositionsState,
  action: any
) => {
  switch (action.type) {
    case WorkPositions.FETCH_WORK_POSITIONS_START:
      state = { ...state, loading: true };
      break;
    case WorkPositions.FETCH_WORK_POSITIONS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case WorkPositions.FETCH_WORK_POSITIONS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { WorkPositionReducer, WorkPositionsReducer };
