import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import PersonalInfo from 'ui-v2/components/PersonalInfo';
import Task from 'ui-v2/components/Task';
import Content from 'ui-v2/components/Content';
import WorkExperience from 'ui-v2/components/WorkExperience';
import Scoreboard from 'ui-v2/components/Scoreboard';
import CandidateDocuments from 'ui-v2/components/CandidateDocuments';
import SkillList from 'ui-v2/components/Skills';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import InterviewList from './InterviewList';
import CandidateNote from './CandidateNote';
import OpenPositionsTabs from './OpenPositionsTabs/OpenPositionsTabs';
import CandidateOfferList from './CandidateOffer/CandidateOfferList/CandidateOfferList';

export default function CandidateProfile() {
  const { candidate } = useCandidateData();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('schedule') === 'true') {
      searchParams.delete('schedule');
      setSearchParams(searchParams);
    }
    if (searchParams.get('offer') === 'true') {
      searchParams.delete('offer');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const isCandidateHired = !!candidate?.employeeId;
  return (
    <Content position="stretch">
      <>
        <OpenPositionsTabs />
        <Content position="grid">
          <>
            <>
              <PersonalInfo isDisabled={isCandidateHired} />
              <WorkExperience isDisabled={isCandidateHired} />
              <SkillList
                isEmployeePortal={false}
                isDisabled={isCandidateHired}
              />
            </>
            <>
              <InterviewList isDisabled={isCandidateHired} />
              <Task isDisabled={isCandidateHired} />
              <Scoreboard isDisabled={isCandidateHired} />
              <CandidateOfferList isDisabled={isCandidateHired} />
              <CandidateDocuments isDisabled={isCandidateHired} />
              <CandidateNote isDisabled={isCandidateHired} />
            </>
          </>
        </Content>
      </>
    </Content>
  );
}
