import { Col, Row } from 'antd';
import React from 'react';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import * as StyledCard from 'ui-v2/components/Card/CardStyles';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';

type IProps = {
  children?: JSX.Element;
};

export default function OfferCard({ children }: IProps) {
  return (
    <StyledCard.Container direction="vertical" size="middle">
      <Styled.RowItem last>
        <Col span={24}>
          <Styled.RowItem justify="start" align="middle">
            <Col span={24}>
              <WorkExperienceIcon />
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <Styled.ItemValue>Join Us</Styled.ItemValue>
                </Col>
              </Row>
            </Col>
          </Styled.RowItem>
        </Col>
      </Styled.RowItem>

      <Styled.RowItem last>
        <Col span={24}>{children}</Col>
      </Styled.RowItem>
    </StyledCard.Container>
  );
}
