import { getEmployeeReviewQuestions } from 'api/employeeService';
import { Dispatch } from 'redux';
import { EmployeesReviews } from './types';

export const fetchEmployeesReviewQuestions =
  (employeeIds: string[]) => (dispatch: Dispatch) => {
    dispatch({
      type: EmployeesReviews.FETCH_REVIEWS_QUESTIONS_START,
    });

    getEmployeeReviewQuestions(employeeIds)
      .then((res) => {
        dispatch({
          type: EmployeesReviews.FETCH_REVIEWS_QUESTIONS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: EmployeesReviews.FETCH_REVIEWS_QUESTIONS_ERRORED,
          payload: e,
        });
      });
  };
