import React from 'react';

import Space from 'antd/lib/space';
import { DownOutlined } from '@ant-design/icons';
import i18next from '../../../i18n';

import { DropDownStyle, MenuItemStyle, MenuStyle } from './DropdownStyle';

type Props = {
  items: {
    label: string;
    icon?: JSX.Element;
    status?: string;
    id?: string;
    disabled?: boolean;
  }[];
  children?: JSX.Element;
  onChange?: (payload: {
    label: string;
    icon?: JSX.Element;
    id?: string;
    status?: string;
  }) => void;
  status?: string;
};

const DropDown = ({ status, items, children, onChange }: Props) => (
  <DropDownStyle
    overlay={
      <MenuStyle>
        {items.map((item) => (
          <MenuItemStyle
            onClick={() => {
              if (!onChange) return;
              onChange(item);
            }}
            status={item.status}
            disabled={item.disabled}
          >
            {item.icon}
            {item.label}
          </MenuItemStyle>
        ))}
      </MenuStyle>
    }
    trigger={['click']}
    status={status}
  >
    {children ? (
      <Space>
        {children}
        <DownOutlined />
      </Space>
    ) : (
      <Space>
        {i18next.t('select')}
        <DownOutlined />
      </Space>
    )}
  </DropDownStyle>
);

export default DropDown;
