import React, { useState } from 'react';
import { FormInstance } from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { TenantDbType } from 'types/Tenant';
import { testTenantDbConnection } from 'api/tenantService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { StyledButtonLink } from 'pages/Employee/EmployeeDetails/EmployeeDetailsStyledComponents';
import Popconfirm from 'antd/es/popconfirm';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  StyledCircleOutlinedIcon,
  StyledInfoCircleOutlined,
} from './TenantDbConnectionStyles';
import { getPopConfirmMsg } from './util';

type TProp = {
  form: FormInstance;
  tenantId?: string;
  result: (validity: boolean) => void;
};

const TestDbConnection: React.FC<TProp> = ({ form, result, tenantId }) => {
  const [loading, isLoading] = useState<boolean>(false);
  const [connectionValid, isConnectionValid] = useState<boolean>(true);
  const [visible, isVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const toogleModal = () => isVisible((visible) => !visible);

  const testConnection = async () => {
    if (!tenantId) {
      return;
    }
    const validatedFields = await form.validateFields();

    const dbConfing = validatedFields as TenantDbType;
    delete dbConfing.confirmPassword;
    delete dbConfing.isDefault;

    isLoading(true);
    const payload: TenantDbType = {
      ...dbConfing,
      database: tenantId || (searchParams.get('approve-tenant') as string),
    };

    testTenantDbConnection(payload)
      .then((res) => {
        if (res.status === 201) {
          isConnectionValid(res.data);
          result(res.data);
          toogleModal();
        }
      })
      .catch((error) => toastErrorMessages(error))
      .finally(() => isLoading(false));
  };

  const linkBtnIcon = connectionValid ? (
    <InfoCircleOutlined />
  ) : (
    <PlusCircleOutlined rotate={45} />
  );
  const popConfirmMessage = getPopConfirmMsg(connectionValid, form);

  const popConfirmIcon = connectionValid ? (
    <StyledInfoCircleOutlined />
  ) : (
    <StyledCircleOutlinedIcon rotate={45} />
  );

  return (
    <Popconfirm
      title={popConfirmMessage}
      icon={popConfirmIcon}
      visible={visible}
      okText={connectionValid ? 'Ok' : t('Abort')}
      onConfirm={toogleModal}
      showCancel={false}
    >
      <StyledButtonLink
        type="link"
        ghost
        danger={!connectionValid}
        loading={loading}
        size="large"
        icon={linkBtnIcon}
        onClick={testConnection}
      >
        {t('testConnection')}
      </StyledButtonLink>
    </Popconfirm>
  );
};

export default TestDbConnection;
