import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton } from 'antd';
import {
  deleteEmployeeTraining,
  getAllEmployeeTrainings,
} from 'api/employeeService';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmployeeTrainingType } from 'types/Employee';
import CardItem from 'ui-v2/components/Card';
import RightSection from 'ui-v2/components/RightSection';
import { TrainingItem } from 'ui-v2/components/Trainings';
import { formatDate } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import AddTrainingModal from './AddTrainingModal';

interface IProps {
  employeeId: string;
  visiblity: boolean;
}

export default function EmployeeTraining({ employeeId, visiblity }: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, isLoading] = useState<boolean>(false);

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const [selectedTraining, setselectedTraining] =
    useState<EmployeeTrainingType>();
  const [employeeTrainings, setEmployeeTrainings] = useState<[]>([]);
  const { t } = useTranslation();

  const onToggleModal = () => setModalVisible((state) => !state);

  const fetchEmployeeTrainings = () => {
    isLoading(true);
    getAllEmployeeTrainings(employeeId)
      .then((response) => {
        if (response?.data?.data) {
          setEmployeeTrainings(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        isLoading(false);
      });
  };
  useEffect(() => {
    fetchEmployeeTrainings();
  }, [employeeId]);

  const onViewCallBack = (index: number) => {
    setselectedTraining(employeeTrainings[index]);
    onToggleModal();
  };

  const onButtonClick = () => {
    setselectedTraining(undefined);
    onToggleModal();
  };
  const onDelete = (id: string) => {
    isLoading(true);
    deleteEmployeeTraining(id)
      .then(() => {
        fetchEmployeeTrainings();
        toast.success(t('trainingDeletedSuccessfully'));
        getAllEmployeeTrainings(employeeId);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Skeleton loading={loading} paragraph={{ rows: 1 }} active />
      ) : (
        <CardItem
          title={t('employeeTrainings')}
          rightButton={
            employeeTrainings?.length ? (
              <RoleBasedButton btn={BUTTONS.ADD_TRAINING}>
                <RightSection
                  buttonCallBack={() => onButtonClick()}
                  buttonText={t('addTraining')}
                />
              </RoleBasedButton>
            ) : undefined
          }
        >
          <>
            <Row gutter={[0, 24]}>
              {!employeeTrainings?.length && (
                <>
                  <Styled.RowItem justify="center" last>
                    <Col>
                      <Styled.NodataLabel>
                        {authUserRole === 'employee'
                          ? 'There are no training related to you'
                          : t('There are no training related to this employee')}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                  {visiblity && (
                    <Styled.RowItem last justify="center">
                      <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                        <Col>
                          <Button
                            icon={<PlusCircleOutlined />}
                            size="large"
                            style={{
                              borderRadius: '8px',
                              fontWeight: '600',
                              fontSize: '16px',
                              color: '344054',
                            }}
                            onClick={onButtonClick}
                          >
                            {t('assignTraining')}
                          </Button>
                        </Col>
                      </RoleBasedButton>
                    </Styled.RowItem>
                  )}
                </>
              )}

              {visiblity && employeeTrainings?.length === 0 && (
                <Styled.RowItem justify="center" last>
                  <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                    <Col>
                      <Styled.Link>
                        {t('You can add a training from this link')}
                        <Link to="/trainings?add-training=true">
                          {t('Add a new Training')}
                        </Link>
                      </Styled.Link>
                    </Col>
                  </RoleBasedButton>
                </Styled.RowItem>
              )}

              {employeeTrainings?.length > 0 && (
                <>
                  <Styled.RowItem last style={{ paddingLeft: 10 }}>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('training')}</Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('startDate')}</Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('endDate')}</Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('status')}</Styled.NodataLabel>
                    </Col>
                    <Col span={4}>
                      <Styled.NodataLabel>{t('note')}</Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>

                  {employeeTrainings?.map(
                    (a: EmployeeTrainingType, index: number) => (
                      <TrainingItem
                        key={a.id}
                        note={a.notes}
                        trainingStatus={
                          a.trainingStatus.charAt(0).toUpperCase() +
                          a.trainingStatus.slice(1)
                        }
                        training={a.training.name}
                        startDate={formatDate(a.startDate)}
                        endDate={formatDate(a.endDate)}
                        onDelete={() => onDelete(a.id)}
                        onView={() => onViewCallBack(index)}
                      />
                    )
                  )}
                </>
              )}
            </Row>
            {modalVisible && (
              <AddTrainingModal
                open={modalVisible}
                closeModal={() => setModalVisible(false)}
                selectedTraining={selectedTraining}
                employeeId={employeeId}
                setEmployeeTrainings={setEmployeeTrainings}
              />
            )}
          </>
        </CardItem>
      )}
    </>
  );
}
