import { DeleteOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { StyledButton, StyledIconButton } from 'components/NewForms/FormStyled';
import React, { useState } from 'react';
import { TimeOffRecord, TimeOffRequest } from 'types/TimeOff';
import CardItem from 'ui-v2/components/Card';
import TimeOffCalendar from 'ui-v2/components/TimeOffCalendar';
import { useTranslation } from 'react-i18next';
import RequestTimeoffModal from '../RequestTimeoffModal';

export default function TimeOffCalendarCard({
  fetch,
  timeOffList,
}: {
  fetch: any;
  timeOffList: Array<TimeOffRequest>;
}) {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState<Array<TimeOffRecord>>([]);

  const onChange = (data: any) => {
    setData(data);
  };

  const onRequest = () => {
    setModal(true);
  };

  const onClear = () => {
    setData([]);
  };

  const closeModal = () => {
    setModal(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <CardItem
        title={<Typography.Text>{t('selecttimeOff')}</Typography.Text>}
        wrapContentHeight
        rightButton={
          <Row gutter={[10, 0]} justify="end">
            <Col span={7}>
              <StyledIconButton
                type="link"
                shape="circle"
                icon={<DeleteOutlined />}
                disabled={!data[0]}
                onClick={onClear}
                id="timeoff-clear-btn"
              />
            </Col>
            <Col span={16}>
              <StyledButton
                type="primary"
                onClick={onRequest}
                disabled={!data[0]}
              >
                {t('Request')}
              </StyledButton>
            </Col>
          </Row>
        }
      >
        <TimeOffCalendar
          timeOff={data}
          onChange={onChange}
          timeOffList={timeOffList}
        />
      </CardItem>
      {modal && (
        <RequestTimeoffModal
          open={modal}
          closeModal={closeModal}
          timeOff={data}
          fetch={fetch}
          onClear={onClear}
        />
      )}
    </>
  );
}
