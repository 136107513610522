import { Col, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createOnboarding, updateOnboarding } from 'api/onBoardingService';
import { OnboardingCreateType } from 'types/Onboarding';
import GenericModal from 'ui-v2/components/GenericModal';
import { useOnboardingsData } from 'ui-v2/hooks/useOnboardingsData';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';
import { fetchDepartmentsOptions } from '../SelectWithLoad/utils';

type TProps = {
  open: boolean;
  closeModal: () => void;
};
export default function OnboardingModalForm({ open, closeModal }: TProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    onboarding: { data },
  } = useOnboardingsData();
  const [loading, isLoading] = useState<boolean>(false);
  const { optionsType: workPosition } = useGetSelectOptions({
    type: 'work-position',
    transform: (item) => ({
      id: item.id,
      value: item.name,
      label: item.name,
    }),
  });

  const OnboardingFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: InputTypes.TEXTAREA,
          defaultValue: data?.name ?? '',
          rules: [
            {
              required: true,
              message: t('Name is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          label: t('department'),
          name: 'departmentId',
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchDepartmentsOptions,
          defaultValue: data?.department?.name,
          rules: [
            {
              required: true,
              message: t('Department is required!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          label: t('workPosition'),
          name: 'workPositionId',
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: workPosition,
          defaultValue: data?.workPosition?.name,
          rules: [
            {
              required: true,
              message: t('Work Positions is required!'),
            },
          ],
        },
      ],
    ],
    [data, workPosition]
  );

  async function onFormFinish(payload: OnboardingCreateType) {
    isLoading(true);
    payload = {
      ...payload,
      departmentId: form.getFieldValue('departmentId').value,
      workPositionId: form.getFieldValue('workPositionId'),
    };
    if (data?.id) {
      updateOnboarding(data.id, payload)
        .then((response) => {
          toast.success(t('Onboarding updated successfully!'));
          closeModal();
          navigate(`/onboardings/onboarding-details?id=${response.data.id}`);
        })
        .catch(() => {
          toast.error(t('Onboarding failed to update!'));
        })
        .finally(() => {
          isLoading(false);
        });

      return;
    }
    createOnboarding(payload)
      .then((response) => {
        toast.success(t('Onboarding created successfully!'));
        closeModal();
        navigate(`/onboardings/onboarding-details?id=${response.data.id}`);
      })
      .catch(() => {
        toast.error(t('Onboarding failed to create!'));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  return (
    <GenericModal
      title={`${data?.id ? t('editOnboarding') : t('addOnboarding')}`}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={OnboardingFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <Row gutter={[0, 30]} justify="end">
          <Col span={24} />
          <Col span={24}>
            <FormItemStyled>
              <Row>
                <Col span={11}>
                  <StyledButton
                    onClick={() => {
                      closeModal();
                    }}
                    htmlType="reset"
                    danger
                  >
                    {t('cancel')}
                  </StyledButton>
                </Col>
                <Col span={11} offset={2}>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t('submit')}
                  </StyledButton>
                </Col>
              </Row>
            </FormItemStyled>
          </Col>
        </Row>
      </GenericForm>
    </GenericModal>
  );
}
