import EmployeeForm from 'components/NewForms/EmployeeForm';
import React from 'react';
import Content from 'ui-v2/components/Content';

export default function AddEmployee() {
  return (
    <Content position="center">
      <EmployeeForm />
    </Content>
  );
}
