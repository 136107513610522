import { Col, Empty, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { VendorQuestionnaire } from 'types/VendorQuestionnaire';
import CardItem from 'ui-v2/components/Card';
import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import { StyledVendorQuestionnaireScoreCol } from './StyledVendorQuestionnaireQuestion';

interface SelectedVendorQuestionnaireQuestionInfoProps {
  questionnaireQuestion?: VendorQuestionnaire;
}

const SelectedVendorQuestionnaireQuestionInfo: React.FC<
  SelectedVendorQuestionnaireQuestionInfoProps
> = ({ questionnaireQuestion }) => {
  const { t } = useTranslation();
  const {
    questionnaireCategory: { selectedQuestionnaireCategory },
  } = useVendorQuestionnaireData();

  const question =
    questionnaireQuestion ||
    selectedQuestionnaireCategory?.vendorQuestionnaire?.[0];

  const mapScoreToAnswer = (score: number) => {
    switch (score) {
      case 1:
        return 'Yes';
      case 2:
        return 'Yes';
      case 0:
        return 'No';
      default:
        return 'N/A';
    }
  };
  return (
    <CardItem title={t('vendorScores')}>
      <>
        {question?.vendorQuestionnaireScore?.length ? (
          question?.vendorQuestionnaireScore?.map((score) => (
            <Row>
              <StyledVendorQuestionnaireScoreCol span={24}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Link
                      to={`/vendors/vendor-details?id=${score?.vendor?.id}`}
                    >
                      <div>{score?.vendor?.companyName}</div>
                    </Link>
                  </Col>
                  <Col>{mapScoreToAnswer(score?.score)}</Col>
                </Row>
              </StyledVendorQuestionnaireScoreCol>
            </Row>
          ))
        ) : (
          <Empty description={t('noVendorQuestionnaireQuestionScores')} />
        )}
      </>
    </CardItem>
  );
};

export default SelectedVendorQuestionnaireQuestionInfo;
