import React from 'react';

interface PDFViewerProps {
  file: string;
}

const PDFViewer = ({ file }: PDFViewerProps) => {
  const iframeStyle = {
    width: '100%',
    height: '100vh',
  };
  return <iframe title="PDF" src={file} style={iframeStyle} />;
};
export default PDFViewer;
