export enum DATAGRID_ACTION_TYPES {
  CHANGE_CELL_SIZE = 'CHANGE_CELL_SIZE',
  REFRESH_DATA = 'REFRESH_DATA',
}
export type CellSize = 'small' | 'middle' | 'large' | undefined;

export enum DATAGRID_TYPES {
  CANDIDATES_DATAGRID = 'CANDIDATES_DATAGRID',
  INTERVIEWS_DATAGRID = 'INTERVIEWS_DATAGRID',
  OPEN_POSITIONS_DATAGRID = 'OPEN_POSITIONS_DATAGRID',
  WORK_POSITIONS_DATAGRID = 'WORK_POSITIONS_DATAGRID',
  EMPLOYEES_DATAGRID = 'EMPLOYEES_DATAGRID',
  EVALUATION_PERIODS_DATAGRID = 'EVALUATION_PERIODS_DATAGRID',
  CERTIFICATIONS_DATAGRID = 'CERTIFICATIONS_PERIODS_DATAGRID',
  EMPLOYEE_EMERGENCY_CONTACT_DATAGRID = 'EMPLOYEE_EMERGENCY_CONTACT_DATAGRID',
  EMPLOYEENOTES_DATAGRID = 'EMPLOYEENOTES_DATAGRID',
  EMPLOYEE_ASSET_DATAGRID = 'EMPLOYEE_ASSET_DATAGRID',
  DEPARTMENTS_DATAGRID = 'DEPARTMENTS_DATAGRID',
  TRAININGS_DATAGRID = 'TRAININGS_DATAGRID',
  ASSETS_DATAGRID = 'ASSETS_DATAGRID',
}

export type DataGridToBeRefreshed = DATAGRID_TYPES | undefined;
