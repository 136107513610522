import { Col, Row, Skeleton, Space } from 'antd';
import React from 'react';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { EmployeeWelcomeScreen, MissionData } from 'types/Employee';
import { useTranslation } from 'react-i18next';
import { TenantConfigDescriptionDto } from 'types/TenantConfig';

interface IProps {
  data: EmployeeWelcomeScreen | undefined;
  arrayMissionData: MissionData[];
}
export default function OnboardingMission({ data, arrayMissionData }: IProps) {
  const { t } = useTranslation();
  if (typeof data === 'undefined') {
    return (
      <CardItem>
        <Skeleton title loading active paragraph={{ rows: 10, width: 0 }} />
      </CardItem>
    );
  }
  const missionVissionValue = arrayMissionData?.at(0) as
    | TenantConfigDescriptionDto
    | undefined;

  return (
    <CardItem title={t('Mission, Vision and Values')}>
      <Row gutter={[0, 20]}>
        {missionVissionValue && Object.values(missionVissionValue)?.length && (
          <>
            <Col span={24}>
              <Styled.RowItem justify="start" gutter={[0, 10]}>
                <Col span={24}>
                  <Row gutter={[0, 10]} align="middle">
                    <Col span={24}>
                      <Col>
                        <Styled.ItemValue>{t('mission')}</Styled.ItemValue>
                      </Col>
                      <Space align="center">
                        <Styled.ItemLabel>
                          {missionVissionValue?.mission}
                        </Styled.ItemLabel>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Styled.RowItem>
            </Col>
            <Col span={24}>
              <Styled.RowItem justify="start" gutter={[0, 10]}>
                <Col span={24}>
                  <Row gutter={[0, 10]} align="middle">
                    <Col span={24}>
                      <Col>
                        <Styled.ItemValue>{t('vision')}</Styled.ItemValue>
                      </Col>
                      <Space align="center">
                        <Styled.ItemLabel>
                          {missionVissionValue?.vision}
                        </Styled.ItemLabel>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Styled.RowItem>
            </Col>
            <Col span={24}>
              <Styled.RowItem justify="start" gutter={[0, 10]}>
                <Col span={24}>
                  <Row gutter={[0, 10]} align="middle">
                    <Col span={24}>
                      <Col>
                        <Styled.ItemValue>{t('values')}</Styled.ItemValue>
                      </Col>
                      <Space align="center">
                        <Styled.ItemLabel>
                          {missionVissionValue?.values}
                        </Styled.ItemLabel>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Styled.RowItem>
            </Col>
          </>
        )}

        {data?.videoUrl && (
          <Col span={24}>
            <Styled.RowItem gutter={[10, 0]} last>
              <Col span={10}>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Styled.ItemValue>A short video</Styled.ItemValue>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[0, 10]} align="middle">
                      <Col span={24}>
                        <Space align="center">
                          <Styled.ItemLabel>
                            We want you to share our passion and live our
                            values. To help you do that, we have created a short
                            video that explains what they mean and how they
                            apply to your work.
                          </Styled.ItemLabel>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={[0, 40]}>
                  <Col>
                    <Row gutter={[0, 10]}>
                      <Col span={24} offset={10}>
                        <iframe
                          title="Onboarding Mission"
                          src={data?.videoUrl}
                          width="300px"
                          height="300px"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Styled.RowItem>
          </Col>
        )}
      </Row>
    </CardItem>
  );
}
