/* eslint-disable react/no-children-prop */

import React from 'react';
import { Col, Popover, Row, Typography } from 'antd';
import { Item } from 'ui-v2/components/PersonalInfo';
import { ItemValue } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { TenantDataType } from 'types/Tenant';
import i18next from '../../../../../i18n';

interface TenantInfoProps {
  tenant: TenantDataType;
}

const TenantInfo: React.FC<TenantInfoProps> = ({ tenant }) => (
  <Row gutter={[0, 30]}>
    <Col span={24}>
      <Row justify="center" gutter={[0, 20]}>
        <Col span={11}>
          <Item
            label={i18next.t('name')}
            value={tenant?.name ?? ''}
            children={undefined}
          />
        </Col>
        <Col span={11} offset={1}>
          <Item
            label={i18next.t('email')}
            children={
              <ItemValue ellipsis>
                <Popover content={tenant?.email ?? ''} placement="topLeft">
                  {tenant?.email ?? ''}
                </Popover>
              </ItemValue>
            }
          />
        </Col>
        <Col span={11}>
          <Item
            label="Website"
            children={
              <Typography.Link
                ellipsis
                href={tenant?.website ?? '#'}
                target="_blank"
              >
                {tenant?.website}
              </Typography.Link>
            }
          />
        </Col>
        <Col span={11} offset={1}>
          <Item
            label={i18next.t('sector')}
            value={tenant?.sector ?? ''}
            children={undefined}
          />
        </Col>

        <Col span={11}>
          <Item
            label={i18next.t('country')}
            value={tenant?.country ?? ''}
            children={undefined}
          />
        </Col>
        <Col span={11} offset={1}>
          <Item
            label={i18next.t('city')}
            value={tenant?.city ?? ''}
            children={undefined}
          />
        </Col>
        <Col span={11}>
          <Item
            label={i18next.t('phoneNumber')}
            value={tenant?.phoneNumber ?? ''}
            children={undefined}
          />
        </Col>
        <Col span={11} offset={1}>
          <Item
            label={i18next.t('address')}
            value={tenant?.address ?? ''}
            children={undefined}
          />
        </Col>
        <Col span={24} offset={1}>
          <Item
            label={i18next.t('description')}
            children={
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: 'show more',
                }}
              >
                {tenant?.description ?? ''}
              </Typography.Paragraph>
            }
          />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default TenantInfo;
