import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  PointElement,
  Filler,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import CardItem from 'ui-v2/components/Card';
import { Col, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAssetStatsData } from 'ui-v2/hooks/useAssetStatsData';

ChartJS.register(
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const PieChartAsset = () => {
  const { data, loading } = useAssetStatsData();

  const { t } = useTranslation();

  return (
    <CardItem title={t('assetsbyStatus')} minHeight={375}>
      <Spin spinning={loading}>
        {!loading && data.labels?.length === 0 ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 350,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {`${t('noAssetYet.')}`}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="assets/add-asset"
              >
                {`${t('addSomeAssetsFirst!')}`}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <Pie data={data} />
        )}
      </Spin>
    </CardItem>
  );
};

export default PieChartAsset;
