import { Col } from 'antd';
import React from 'react';
import { NodataLabel, RowItemAll } from '../RenumarationStyles';

export function RenumarationEmploymentStatusItem() {
  return (
    <RowItemAll>
      <Col span={8}>
        <NodataLabel>06/22/2023</NodataLabel>
      </Col>
      <Col span={8}>
        <NodataLabel>Full - Time</NodataLabel>
      </Col>
      <Col span={8}>
        <NodataLabel>Perfect</NodataLabel>
      </Col>
    </RowItemAll>
  );
}

export function RenumarationCompensationItem() {
  return (
    <RowItemAll>
      <Col span={4}>
        <NodataLabel>06/22/2023</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>$90,000.00</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>Every other week</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>Salary</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>Yes</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>No reason</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>perfect</NodataLabel>
      </Col>
    </RowItemAll>
  );
}
