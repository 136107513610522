import { Checkbox } from 'antd';
import styled from 'styled-components';

export const StyledCheckboxGroup = styled(Checkbox)(({ theme }) => ({
  textTransform: 'capitalize',
  ':hover': {
    borderRadius: '4px',
  },
  '.ant-checkbox-inner': {
    borderRadius: '4px',
  },
  '.ant-checkbox-checked': {
    borderRadius: 'inherit',
    ':after': {
      borderRadius: 'inherit',
    },
  },
  color: theme.colors.palette.primary.gray['600'],
  ...{ ...theme.typography.body.regular.sm },
}));
