import { Reducer } from 'react';
import { Action } from 'redux';
import { Invoicing, InvoicingState } from './types';

const initialState: InvoicingState = {
  data: {
    data: [],
    meta: null,
  },
  invoicing: null,
  loaded: false,
  loading: false,
  errored: false,
  error: null,
};

const reducer: Reducer<InvoicingState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Invoicing.FETCH_INVOICES_START:
      state = { ...state, loading: true };

      break;

    case Invoicing.FETCH_INVOICES_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case Invoicing.FETCH_INVOICES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;

    case Invoicing.FETCH_INVOICE_START:
      state = { ...state, loading: true };
      break;
    case Invoicing.FETCH_INVOICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        invoicing: action.payload,
      };
      break;
    case Invoicing.FETCH_INVOICE_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;

    case Invoicing.CLEAR_INVOICE_STATE:
      state = { ...state, invoicing: null };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as InvoicingReducer };
