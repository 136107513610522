/* eslint-disable indent */
import { Col, Form, Row } from 'antd';
import { addEvaluation, updateEvaluation } from 'api/evaluationService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import GenericModal from 'ui-v2/components/GenericModal';
import { getLoggedInUserId, getUserDateFormat } from 'utils/utilFunctions';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeId: string;
  id: string;
}

export default function AddEmployeeEvaluationModal({
  open,
  closeModal,
  employeeId,
  id,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const authUserState = useSelector((state: any) => state.authUser);
  const dispatch = useDispatch();
  const { employeeDetail } = useEmployeesData();
  const evaluations = employeeDetail?.evaluations;
  const { t } = useTranslation();
  const evaluation = useMemo(
    () => evaluations?.find((item: { id: string }) => item.id === id),
    [id]
  );
  const userDateFormat = getUserDateFormat();

  const AddEmployeeEvaluationFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'isActive',
          label: t('isActive'),
          defaultValue: evaluation?.isActive,
          type: 'switch',
        },
      ],
      [
        {
          col: 10,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          dataIndex: 'startDate',
          type: 'datepicker',
          dateProps: {
            format: userDateFormat,
          },
          defaultValue: moment(evaluation?.startDate),
          rules: [
            {
              required: true,
              message: t('Start Date is required'),
            },
          ],
        },
        {
          col: 10,
          offset: 4,
          name: 'endDate',
          label: t('endDate'),
          dataIndex: 'endDate',
          type: 'datepicker',
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
          defaultValue: moment(evaluation?.endDate),
          rules: [
            {
              required: true,
              message: t('End Date is required'),
            },
          ],
        },
      ],

      [
        {
          col: 24,
          offset: 0,
          name: 'employeeFeedback',
          label: t('leaveFeedback'),
          dataIndex: 'feedback',
          type: 'textarea',
          defaultValue: evaluation?.employeeFeedback,
          rules: [
            {
              required: true,
              message: t('Feedback is required'),
            },
          ],
        },
      ],
    ],
    [evaluation]
  );

  const onSubmit = async () => {
    setLoading(true);

    const formValues: any = form.getFieldsValue(true);
    const values: any = {
      ...formValues,
      isActive: formValues.isActive ?? false,
      employeeId,
    };
    if (id) {
      await updateEvaluation(id, values)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(fetchEmployeesDetail(employeeId));
            toast.success(t('successfullyUpdatedEvaluation'));
            closeModal();
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('somethingWentWrong'));
        });
    } else {
      await addEvaluation({
        isActive: formValues.isActive ?? false,
        employeeId,
        employeeFeedback: formValues.employeeFeedback,
        evaluations: formValues.employeeFeedback
          ? [
              {
                userId: getLoggedInUserId(authUserState) as string,
                feedback: formValues?.employeeFeedback,
              },
            ]
          : undefined,
        startDate: formValues?.startDate,
        endDate: formValues?.endDate,
      })
        .then(() => {
          setLoading(false);
          dispatch(fetchEmployeesDetail(employeeId));
          toast.success(t('Successfully added employee evaluation!'));
          closeModal();
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('somethingWentWrong'));
        });
    }
  };

  return (
    <GenericModal
      title={id ? t('editEvaluationDetails') : t('addevaluationDetails')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddEmployeeEvaluationFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
