import React from 'react';

export const ScheduleInterviewModalIcon = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <path
      d="M37.5 27.5V24.8C37.5 23.1198 37.5 22.2798 37.173 21.638C36.8854 21.0735 36.4265 20.6146 35.862 20.327C35.2202 20 34.3802 20 32.7 20H24.3C22.6198 20 21.7798 20 21.138 20.327C20.5735 20.6146 20.1146 21.0735 19.827 21.638C19.5 22.2798 19.5 23.1198 19.5 24.8V33.2C19.5 34.8802 19.5 35.7202 19.827 36.362C20.1146 36.9265 20.5735 37.3854 21.138 37.673C21.7798 38 22.6198 38 24.3 38H29M37.5 26H19.5M32.5 18V22M24.5 18V22M34.5 37V31M31.5 34H37.5"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4.5"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#ECFDF3"
      strokeWidth="8"
    />
  </svg>
);
