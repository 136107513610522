/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Typography, Image } from 'antd';
import styled from 'styled-components';
import { StyledAvatar } from 'ui-v2/components/TimeOffCalendarAdmin/CellRender/CellRenderStyles';

export const LogoPlaceholder = styled.div<{
  collapsed: boolean;
}>`
  width: ${({ collapsed }) => (collapsed ? 30 : 40)}px;
  height: ${({ collapsed }) => (collapsed ? 30 : 40)}px;
`;

export const RoundedImage = styled(Image)<{
  collapsed: boolean;
  visible: boolean;
}>`
  border-radius: 100%;
  width: ${({ collapsed }) => (collapsed ? 30 : 40)}px;
  height: ${({ collapsed }) => (collapsed ? 30 : 40)}px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const SidebarContentItemStyle = styled(Row)`
  width: 100%;
`;

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SidebarFooterStyle = styled.div`
  margin-bottom: 32px;
`;

export const SidebarDividerWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
`;

export const SidebarDivider = styled.div`
  width: 90%;
  border: 1px solid #475467;
  margin-bottom: 24px;
`;

export const StyledUserAvatar = styled(StyledAvatar)`
  cursor: pointer;
`;

export const StyledSidebarUserInfoRow = styled(Row)`
  cursor: pointer;
`;

export const MenuContentStyle = styled.div`
  justify-content: space-between;
`;

export const SidebarHeaderContent = styled.div<{
  width?: string;
}>`
  width: ${({ width }) => width || '80%'};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  left: 0px;
`;

export const TenantNameStyle = styled(Typography)`
  color: white;
  font-size: 20px;
  font-weight: 600;
`;

export const SidebarWraper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '20em'};
  /* overflow: visible; */
  z-index: 1;
  position: fixed;
  /* overflow-y: scroll;
  overflow-x: visible; */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none !important;
    margin-right: 50px;
  }
`;

export const ButtonCloseSidebarOption = styled.button<{
  hrDuties: boolean;
  isBannerClosed: boolean;
}>`
  ${({ isBannerClosed, hrDuties }) =>
    isBannerClosed === false && hrDuties
      ? 'top: 82px'
      : isBannerClosed === true || !hrDuties
      ? 'top: 40px'
      : ''};
  position: absolute;
  right: 0;
  transform: translateX(calc(100%));
  z-index: 2;
  background-color: #101828;
  color: white;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border: none;
  width: 11px;
  height: 27px;
  cursor: pointer;
`;

export const RightOutlinedStyles = styled(RightOutlined)`
  font-size: 11px;
  margin-left: -4px;
`;

export const LeftOutlinedStyles = styled(LeftOutlined)`
  font-size: 11px;
  margin-left: -4px;
`;

export const SidebarStyle = styled.div<{
  width?: string;
}>`
  /* position: relative;
  overflow: visible; */
  height: 100%;
  width: ${({ width }) => width || '20em'};
  position: fixed;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #101828 !important;
  [class*='ant-menu'] {
    color: #f9fafb;
    stroke: #d0d5dd;
    font-size: 1em;
    font-weight: 600;
    line-height: 20px;
    gap: 14px;
    padding: 0 13px;
    border-radius: 6px;
    :first-child {
      margin-bottom: 38px;
      gap: 15px;
    }
    :nth-child(3) {
      gap: 15px;
    }
    :nth-child(4) {
      margin-bottom: 38px;
      gap: 18px;
    }
    :nth-child(6) {
      gap: 15px;
    }
    :nth-child(7) {
      gap: 15px;
    }
    :nth-child(8) {
      margin-bottom: 38px;
    }
    :nth-child(12) {
      gap: 15px;
    }
    :nth-child(13) {
      margin-top: 38px;
      gap: 18px;
    }
    :nth-child(14) {
      gap: 14px;
    }
  }
  [class*='ant-menu.ant-menu-dark'] {
    background-color: #101828 !important;
  }
  [class*='ant-menu-item'] {
    padding-left: 14px !important;
    :hover {
      background-color: #344054;
      [class*='ant-menu-title-content'] {
        color: white !important;
      }
    }
    :hover > svg {
      stroke: white;
    }
  }
  [class*='ant-menu-title-content'] {
    padding-left: 0px;
  }
  [class*='ant-menu-item-selected'] {
    background-color: #344054 !important;
  }
  @media only screen and (max-width: 1193px) {
    [class*='ant-menu'] {
      color: #f9fafb;
      stroke: #d0d5dd;
      margin-right: 0.6rem;
      /* [class*='ant-menu-title-content'] {
        display: none;
      } */
    }
    [class*='ant-btn-link'] {
      margin-top: 5px;
    }
  }
`;

export const StyledDiv = styled.div`
  @media only screen and (max-width: 1193px) {
    margin-bottom: 10px !important;
  }
`;

export const SidebarUserName = styled(Typography)<{
  display?: string;
}>`
  width: 100%;
  color: #f9fafb;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: ${({ display }) => display || ''};
`;

export const ToolTipTextStyle = styled(Typography)`
  width: 100%;
  color: #101828;
  :hover {
    text-decoration: underline;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SidebarUserEmail = styled(Typography)<{
  display?: string;
}>`
  width: 100%;
  color: #f9fafb;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: ${({ display }) => display || ''};
`;
