import { Reducer } from 'react';
import { Action } from 'redux';

import { Notifications, NotificationsState } from './types';

const initialState: NotificationsState = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<NotificationsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Notifications.FETCH_NOTIFICATIONS_START:
      state = { ...state, loading: true };
      break;
    case Notifications.FETCH_NOTIFICATIONS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Notifications.FETCH_NOTIFICATIONS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Notifications.PUSH_NOTIFICATION_START:
      state = {
        ...state,
        loading: true,
        data: [action.payload, ...state.data],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as NotificationsReducer };
