import React from 'react';
import { Col, Popconfirm, Row } from 'antd';

import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';

const OnBoardingActions: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onViewClick = () => {
    navigate(`onboarding-details?id=${id}`);
  };

  const onEditClick = () => {
    searchParams.append('edit-onboarding', id);
    setSearchParams(searchParams);
  };

  function onDelete() {
    searchParams.append('delete-onboarding', id);
    setSearchParams(searchParams);
  }

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <StyledIcon onClick={onViewClick}>
              <ViewProfileIcon />
            </StyledIcon>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <StyledIcon onClick={onEditClick}>
                <EdititingIcon />
              </StyledIcon>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={onDelete}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default OnBoardingActions;
