import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import { InputTypes } from 'types/FormTypes';
import { useSearchParams } from 'react-router-dom';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { PurchaseOrderType } from 'types/PurchaseOrder';
import useHeader from 'ui-v2/hooks/useHeader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { convertUTCtoLocalTime, toastErrorMessages } from 'utils/utilFunctions';
import { fetchInvoices, fetchSingleInvoice } from 'redux/invoicing/action';
import { useInvoicingData } from 'ui-v2/hooks/useInvoicingData';
import { InvoicingType } from 'types/Invoicing';
import { createInvoice, editInvoice } from 'api/invoicing';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedInvoice: InvoicingType | null;
}

function InvoicingModalForm({ open, closeModal, selectedInvoice }: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    list: invoices,
    pagination,
    loading: dataLoading,
  } = useInvoicingData();
  const { optionsType: vendorsOptions, loading: vendorsLoading } =
    useGetSelectOptions({
      type: 'vendor',
      transform: (item) => ({
        id: item.id,
        value: item.id,
        label: item.companyName,
      }),
    });
  const { optionsType: purchaseOrdersOptions, loading: purchaseOrdersLoading } =
    useGetSelectOptions({
      type: 'purchase-order',
      transform: (item) => ({
        id: item.id,
        value: item.id,
        label: item.purchaseOrderNumber,
      }),
    });

  const onFormFinish = async () => {
    const formValues = form.getFieldsValue(true);

    const valuesToSend: InvoicingType = {
      invoiceNumber: formValues.invoiceNumber,
      invoiceDate: formValues.invoiceDate,
      dueDate: formValues.dueDate,
      paymentTerms: formValues.paymentTerms,
      purchaseOrders: formValues.purchaseOrders,
      vendorId: formValues.vendor,
    };

    setLoading(true);

    if (selectedInvoice?.id) {
      editInvoice(valuesToSend, selectedInvoice?.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyUpdatedInvoice'));
            dispatch(fetchSingleInvoice(selectedInvoice?.id ?? ''));
            const currentPage = pagination?.page ?? 1;
            dispatch(
              fetchInvoices({ pageOptions: { page: currentPage, take } })
            );
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createInvoice(valuesToSend)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyCreatedInvoice'));
            const currentPage = pagination?.page ?? 1;
            dispatch(
              fetchInvoices({ pageOptions: { page: currentPage, take } })
            );
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (searchParams.get('edit-invoice')) {
      searchParams.delete('edit-invoice');
      setSearchParams(searchParams);
    }
    if (searchParams.get('add-invoice') === 'true') {
      searchParams.delete('add-invoice');
      setSearchParams(searchParams);
      form.submit();
    }
    if (searchParams.get('edit-invoice') === 'true') {
      searchParams.delete('edit-invoice');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams, form, setSearchParams, invoices]);

  const PurchaseOrderFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'invoiceDate',
          label: t('date'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(
            selectedInvoice?.invoiceDate || ''
          ),
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'dueDate',
          label: t('dueDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(selectedInvoice?.dueDate || ''),
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'invoiceNumber',
          label: t('invoiceNumber'),
          type: InputTypes.INPUT,
          defaultValue: selectedInvoice?.invoiceNumber || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'paymentTerms',
          label: t('paymentTerms'),
          type: InputTypes.INPUT,
          defaultValue: selectedInvoice?.paymentTerms || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'vendor',
          label: t('vendor'),
          type: InputTypes.SELECT,
          defaultValue: selectedInvoice?.vendor?.id,
          selectOptions: vendorsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          showSearch: true,
          loading: vendorsLoading,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'purchaseOrders',
          label: t('purchaseOrders'),
          type: InputTypes.SELECT,
          defaultValue:
            selectedInvoice?.purchaseOrders?.map(
              (purchaseOrder: PurchaseOrderType) => purchaseOrder?.id
            ) || '',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          selectOptions: purchaseOrdersOptions,
          showSearch: true,
          isMultiSelect: true,
          loading: purchaseOrdersLoading,
        },
      ],
    ],
    [
      vendorsOptions,
      vendorsLoading,
      purchaseOrdersLoading,
      purchaseOrdersOptions,
      selectedInvoice,
    ]
  );

  return (
    <GenericModal
      title={selectedInvoice?.id ? t('editInvoice') : t('addInvoice')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={PurchaseOrderFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={
          dataLoading || loading || vendorsLoading || purchaseOrdersLoading
        }
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default InvoicingModalForm;
