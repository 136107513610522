import { Col, Row } from 'antd';
import React from 'react';
import Content from 'ui-v2/components/Content';
import RenumarationEmploymentStatus from './RenumarationEmploymentStatus';
import Compensation from './Compensation';

export default function Renumaration() {
  return (
    <Row gutter={[0, 30]}>
      <Row>
        <Content position="stretch">
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <RenumarationEmploymentStatus />
            </Col>
            <Col span={24}>
              <Compensation />
            </Col>
          </Row>
        </Content>
      </Row>
    </Row>
  );
}
