import React from 'react';
import CertificationActions from 'components/Actions/CertificationAction';
import { CertificationType, SingleCertificationDTO } from 'types/Certification';
import { Typography } from 'antd';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import i18next from '../i18n';

export const CertificationColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    default: true,
    order: 0,
  },
  {
    title: i18next.t('issuedBy'),
    dataIndex: 'companyName',
    key: 'companyName',
    default: true,
    order: 1,
  },
  {
    title: i18next.t('validation'),
    dataIndex: 'validation',
    key: 'validation',
    render: (record: string) => (
      <Typography.Text>
        {`Expires in ${convertUTCtoFormatedLocalTime(record)}`}
      </Typography.Text>
    ),
    default: false,
    order: 2,
  },
  {
    title: i18next.t('costs'),
    dataIndex: 'cost',
    key: 'cost',
    render: (_: string, item: SingleCertificationDTO) => (
      <>
        {item?.currency?.symbol === 'ALL'
          ? `${item?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
              item?.currency?.symbol
            }`
          : `${item?.cost} ${item?.currency?.symbol}`}
      </>
    ),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('trainingDuration'),
    dataIndex: 'trainingDuration',
    key: 'trainingDuration',
    default: false,
    order: 4,
  },
  {
    title: i18next.t('skills'),
    dataIndex: 'certificationSkills',
    key: 'certificationSkills',
    render: (record: string, row: SingleCertificationDTO) => (
      <>{row?.certificationSkills?.length || 0}</>
    ),
    default: false,
    order: 5,
  },
  {
    title: i18next.t('action'),
    key: 'action',
    width: 150,
    render: (record: string, row: CertificationType) => (
      <CertificationActions id={row.id} />
    ),
    default: false,
    order: 6,
    fixed: 'right',
  },
];
