import React, { useEffect, useState } from 'react';
import { Calendar, Row } from 'antd';
import moment, { Moment } from 'moment';
import DateHolidays from 'date-holidays';
import { TimeOffRequest } from 'types/TimeOff';
import { useTranslation } from 'react-i18next';
import CellRender from './CellRender';
import { findRequestsAtDate } from '../TimeOffCalendar/utils';
import CustomCalendarHeader from './CustomCalendarHeader';
import { StyledCalendarHeader } from './CellRender/CellRenderStyles';

export default function TimeOffCalendarAdmin({
  requests,
  setRequests,
}: {
  requests: Array<TimeOffRequest>;
  setRequests: (requests: TimeOffRequest[]) => void;
}) {
  const [holidays, setHolidays] = useState<Moment[]>([]);
  const [holidayNames, setHolidayNames] = useState<Record<string, string>>({});
  const [selectedMonth, setSelectedMonth] = useState<number>(moment().month());
  const [selectedYear, setSelectedYear] = useState<number>(moment().year());
  const { t } = useTranslation();

  function changeLanguage() {
    const weekDays = [
      t('Su'),
      t('Mo'),
      t('Tu'),
      t('We'),
      t('Th'),
      t('Fr'),
      t('Sa'),
    ];

    const table = document.querySelectorAll<HTMLElement>(
      '#timeoff-admin-calendar .ant-picker-content > thead > tr > th'
    );
    weekDays.forEach((item, index) => {
      table[index].innerText = item;
    });
  }
  useEffect(() => {
    const calendar = document.getElementById('timeoff-admin-calendar');
    if (calendar) {
      changeLanguage();
    }
  }, []);

  useEffect(() => {
    // Fetch holidays for the desired country
    const fetchHolidays = async () => {
      try {
        const country = 'AL'; // Replace with the desired country code
        const year = new Date().getFullYear();

        const hd = new DateHolidays(country);
        // Create a "date-holidays" instance
        hd.setLanguages('al'); // Set the desired language

        const holidays = hd.getHolidays(year); // Get the holidays for the specified year

        // Convert holiday dates to Moment objects
        const holidayDates = holidays.map((holiday) => moment(holiday.date));
        setHolidays(holidayDates);

        // Create a map of holiday names for quick lookup
        const holidayNamesMap: Record<string, string> = {};
        holidays.forEach((holiday) => {
          holidayNamesMap[moment(holiday.date).format('YYYY-MM-DD')] =
            holiday.name;
        });
        setHolidayNames(holidayNamesMap);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };

    fetchHolidays();
  }, []);

  const onSelectDate = (requests: Array<TimeOffRequest>) => {
    setRequests(requests);
  };
  const dateCellRender = (cellDate: Moment) => {
    const isHoliday = holidays.some((holiday) =>
      holiday.isSame(cellDate, 'day')
    );
    const holidayName = holidayNames[moment(cellDate).format('YYYY-MM-DD')]; // Access the holiday name using moment(cellDate).format('YYYY-MM-DD')

    return (
      <CellRender
        cellDate={cellDate}
        requests={findRequestsAtDate(cellDate, requests)}
        onSelect={onSelectDate}
        isHoliday={isHoliday}
        holidayName={holidayName}
      />
    );
  };

  return (
    <Row id="timeoff-admin-calendar">
      <Calendar
        dateCellRender={dateCellRender}
        value={moment().year(selectedYear).month(selectedMonth)}
        headerRender={({ value }) => (
          <StyledCalendarHeader>
            <CustomCalendarHeader
              value={value}
              onSelectMonth={setSelectedMonth}
              onSelectYear={setSelectedYear}
            />
          </StyledCalendarHeader>
        )}
      />
    </Row>
  );
}
