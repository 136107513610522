import React from 'react';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { OffboardingTaskStatus } from 'types/Offboarding';
import { Space } from 'antd';
import { CheckCircleOutlined, UserDeleteOutlined } from '@ant-design/icons';
import TabWrapper from './TabWrapper';

export default function Revocation({ onNext }: { onNext: () => void }) {
  const { getTabStatus } = useOffboardingData();

  const status = getTabStatus(OffboardingTaskType.REVOCATION);

  return (
    <TabWrapper onNext={onNext} type={OffboardingTaskType.REVOCATION}>
      <div>
        {[
          OffboardingTaskStatus.PENDING,
          OffboardingTaskStatus.IN_PROGRESS,
        ].includes(status) && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <UserDeleteOutlined
                style={{ fontSize: '64px', color: '#f5222d' }}
              />
              <h2>Revocation of Access</h2>
              <p>
                When an employee leaves the company, it&apos;s crucial to revoke
                their access to company resources to ensure security and
                confidentiality. This includes revoking access to company email,
                internal systems, and any other sensitive information.
              </p>
            </Space>
          </div>
        )}

        {status === OffboardingTaskStatus.COMPLETED && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <CheckCircleOutlined
                style={{ fontSize: '64px', color: '#52c41a' }}
              />
              <h2>Revocation Successful</h2>
              <p>
                The revocation of access has been successfully completed. The
                employee no longer has access to company email, internal
                systems, and any other sensitive information. Thank you for
                ensuring the security and confidentiality of our resources.
              </p>
            </Space>
          </div>
        )}
      </div>
    </TabWrapper>
  );
}
