import { Dispatch } from 'redux';
import { InvoicingQueryParamsType } from 'types/QueryParams';
import { getInvoices, getInvoice } from 'api/invoicing';
import { Invoicing } from './types';

export const fetchInvoices =
  (queryParams?: InvoicingQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Invoicing.FETCH_INVOICES_START,
    });
    getInvoices(queryParams)
      .then((res) => {
        dispatch({
          type: Invoicing.FETCH_INVOICES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Invoicing.FETCH_INVOICES_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleInvoice = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Invoicing.FETCH_INVOICE_START,
  });
  getInvoice(id)
    .then((res) => {
      dispatch({
        type: Invoicing.FETCH_INVOICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Invoicing.FETCH_INVOICE_ERRORED,
        payload: e,
      });
    });
};

export const clearInvoicingState = () => (dispatch: Dispatch) => {
  dispatch({
    type: Invoicing.CLEAR_INVOICE_STATE,
  });
};
