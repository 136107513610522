export enum CandidateStatus {
  NEW = 'new',
  VERIFY = 'verify',
  HIRED = 'hired',
  REJECTED = 'rejected',
}

export const candidateStatusList = [
  {
    key: '1',
    label: CandidateStatus.NEW,
  },
  {
    key: '2',
    label: CandidateStatus.VERIFY,
  },
  {
    key: '3',
    label: CandidateStatus.HIRED,
  },
  {
    key: '4',
    label: CandidateStatus.REJECTED,
  },
];

export enum CandidateStatusColor {
  NEW = '#344054',
  VERIFY = '#027A48',
  HIRED = '#027A48',
  REJECTED = '#B42318',
}
export enum CandidateStatusBg {
  NEW = '#edeff3',
  VERIFY = '#ECFDF3',
  HIRED = '#ECFDF3',
  REJECTED = '#FEF3F2',
}

export type CandidateStatusColorKey = keyof typeof CandidateStatusColor;
export type CandidateStatusBgKey = keyof typeof CandidateStatusBg;
