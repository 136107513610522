import styled from 'styled-components';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Button, Col, Row } from 'antd';

export const StyledTrackingSummaryBarChart = styled(Bar)(() => ({
  margin: '0 .1rem',
  minHeight: '25rem',
  padding: '1rem',
}));

export const StyledTrackingSummaryContent = styled.div(() => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'space-between',
}));

export const StyledTrackingSummaryListContent = styled(Row)(({ theme }) => ({
  width: '100%',
  borderRight: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledTrackingSummaryListDetailContent = styled(Row)((): any => ({
  padding: '1rem 0',
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledTrackingSummaryListDetailItemContent = styled(Row)<{
  isLast?: boolean;
}>(({ theme, isLast }) => ({
  border: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  padding: '1rem',
  backgroundColor: theme.colors.palette.primary.gray['50'],
  borderBottom: isLast
    ? `1px solid ${theme.colors.palette.primary.gray['300']}`
    : 'unset',
}));

export const StyledTrackingSummaryListContentItem = styled(Col)<{
  isLast?: boolean;
  isDetailsOpen?: boolean;
}>(({ theme, isLast, isDetailsOpen }) => ({
  width: '100%',
  borderBottom: isLast
    ? 'none'
    : `1px solid ${theme.colors.palette.primary.gray['300']}`,
  padding: '1rem',
  ...(isDetailsOpen && { paddingBottom: 'unset' }),
}));

export const StyledTrackingSummaryListContentItemButton = styled(Button)(
  ({ theme }) => ({
    ...theme.typography.body.medium.sm,
    color: theme.colors.palette.primary.gray['500'],
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.colors.palette.primary.blue['50'],
    width: 'fit-content',
    padding: '.6rem',
    alignItems: 'center',
  })
);

export const StyledTrackingPieChart = styled(Doughnut)(() => ({
  border: 'unset',
  padding: '2rem',
  maxWidth: '25rem',
  maxHeight: '25rem',
}));
