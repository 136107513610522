import { Reducer } from 'react';
import { Action } from 'redux';
import {
  CellSize,
  DATAGRID_ACTION_TYPES,
  DataGridToBeRefreshed,
} from './types';

export interface DataGridState {
  size: CellSize;
  dataGridToRefresh: DataGridToBeRefreshed;
}

const initialState: DataGridState = {
  size: undefined,
  dataGridToRefresh: undefined,
};

const reducer: Reducer<DataGridState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case DATAGRID_ACTION_TYPES.CHANGE_CELL_SIZE:
      state = { ...state, size: action.size };
      break;
    case DATAGRID_ACTION_TYPES.REFRESH_DATA:
      state = { ...state, dataGridToRefresh: action.dataGridToRefresh };
      break;
    default:
      break;
  }
  return state;
};

export { reducer as DataGridReducer };
