/* eslint-disable max-len */
import { Col, Row, Form, RadioChangeEvent, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEmployeesReviewQuestions } from 'redux/employeeReviews/actions';
import { useEmployeeReviewData } from 'ui-v2/hooks/useEmployeeReviewData';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import TextArea from 'antd/lib/input/TextArea';

import { CreateQuestionnaireFormDto } from 'types/Questionnaires';
import { useCreateReview } from 'ui-v2/hooks/useCreateReviewQuestions';
import GenericForm from 'components/NewForms/Form';
import { StyledButton } from 'components/NewForms/FormStyled';
import {
  Buttons,
  ReviewEmployee,
  ReviewQuestion,
  ReviewQuestionsList,
  ReviewTitle,
} from './ReviewStyles';

interface EmployeeReviewQuestionsProps {
  closeModal: () => void;
  employeeIds: Array<string>;
}
const EmployeeReviewQuestions = ({
  closeModal,
  employeeIds,
}: EmployeeReviewQuestionsProps) => {
  const [value, setValue] = useState(1);
  const { list: employeesToReview } = useEmployeeReviewData();

  const [currentEmployee, setCurrentEmployee] = useState<any>();
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number>(0);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleCreateReview } = useCreateReview();

  useEffect(() => {
    dispatch(fetchEmployeesReviewQuestions(employeeIds));
  }, []);

  useEffect(() => {
    setCurrentEmployee(employeesToReview[0]);
  }, [employeesToReview]);

  useEffect(() => {
    setCurrentEmployee(employeesToReview[currentEmployeeIndex]);
  }, [currentEmployeeIndex]);

  const prevEmployee = () => {
    if (currentEmployeeIndex - 1 >= 0) {
      setCurrentEmployeeIndex(currentEmployeeIndex - 1);
    }
  };

  const nextEmployee = () => {
    if (currentEmployeeIndex + 1 < employeeIds.length) {
      setCurrentEmployeeIndex(currentEmployeeIndex + 1);
    } else {
      closeModal();
      toast.success(t('completedReview'));
    }
  };

  const onNext = async (formValues: CreateQuestionnaireFormDto) => {
    await handleCreateReview(formValues, currentEmployee, form, nextEmployee);
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <GenericForm form={form} onFinish={onNext} formConfiguration={[]}>
      <Row>
        {currentEmployee && (
          <>
            <ReviewEmployee>
              <ReviewTitle>
                {`${t('reviewFor')} ${currentEmployee.firstName} ${
                  currentEmployee.lastName
                }`}
              </ReviewTitle>
              {currentEmployee?.workPosition?.department?.questionnaire
                ?.questionnaireQuestion && (
                <ReviewQuestionsList>
                  {currentEmployee?.workPosition?.department?.questionnaire?.questionnaireQuestion?.map(
                    (question: any, index: number) => (
                      <Col>
                        <Form.Item
                          name={['alternatives', index, 'question']}
                          hidden
                          initialValue={question.id}
                        />
                        <Form.Item
                          name={['alternatives', index, 'alternative']}
                          label={
                            <ReviewQuestion>{question.content}</ReviewQuestion>
                          }
                          rules={[
                            {
                              required: true,
                              message: t('provideRating'),
                            },
                          ]}
                        >
                          <Radio.Group onChange={onChange} value={value}>
                            <Space direction="vertical">
                              {question?.questionnaireAlternative?.map(
                                (item: any) => (
                                  <Radio value={item.id}>{item.content}</Radio>
                                )
                              )}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    )
                  )}

                  <Form.Item name="content">
                    <TextArea
                      rows={4}
                      placeholder="Leave a feedback"
                      maxLength={6}
                    />
                  </Form.Item>
                </ReviewQuestionsList>
              )}
            </ReviewEmployee>
            <Buttons>
              {currentEmployeeIndex !== 0 && (
                <StyledButton onClick={prevEmployee} id="prev" type="default">
                  {t('previous')}
                </StyledButton>
              )}
              {currentEmployeeIndex === employeeIds.length - 1 ? (
                <StyledButton id="finish" htmlType="submit" type="primary">
                  {t('finish')}
                </StyledButton>
              ) : (
                <StyledButton htmlType="submit" id="next" type="dashed">
                  {t('nextEmployee')}
                </StyledButton>
              )}
            </Buttons>
          </>
        )}
      </Row>
    </GenericForm>
  );
};

export default EmployeeReviewQuestions;
