import { getEmployeeAssetsByEmployeeId } from 'api/employeeAssetsService';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function useEmployeeAssetsData({
  employeeId,
}: {
  employeeId: string;
}) {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEmployeeAssets = async (employeeId: string) => {
    setLoading(true);
    try {
      const response = await getEmployeeAssetsByEmployeeId(employeeId);
      setAssets(response?.data);
      setLoading(false);
    } catch {
      toast.error('Failed to get employee assets!');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      fetchEmployeeAssets(employeeId);
    }
  }, [employeeId]);

  return {
    assets,
    loading,
    fetchEmployeeAssets,
  };
}
