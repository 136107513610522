import React from 'react';

export const ViewProfileIcon = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.93008 16.199C4.43702 15.0047 5.62061 14.167 6.99984 14.167H11.9998C13.3791 14.167 14.5627 15.0047 15.0696 16.199M12.8332 7.91699C12.8332 9.75794 11.3408 11.2503 9.49984 11.2503C7.65889 11.2503 6.1665 9.75794 6.1665 7.91699C6.1665 6.07604 7.65889 4.58366 9.49984 4.58366C11.3408 4.58366 12.8332 6.07604 12.8332 7.91699ZM17.8332 10.0003C17.8332 14.6027 14.1022 18.3337 9.49984 18.3337C4.89746 18.3337 1.1665 14.6027 1.1665 10.0003C1.1665 5.39795 4.89746 1.66699 9.49984 1.66699C14.1022 1.66699 17.8332 5.39795 17.8332 10.0003Z"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
