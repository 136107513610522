import React from 'react';

export const TrainingsIcon = () => (
  <svg
    width="20"
    height="16.82"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 7.99958V14.0107C4 14.3697 4 14.5492 4.05465 14.7076C4.10299 14.8477 4.18187 14.9754 4.28558 15.0813C4.40287 15.201 4.5634 15.2813 4.88446 15.4418L10.2845 18.1418C10.5468 18.273 10.678 18.3386 10.8156 18.3644C10.9375 18.3873 11.0625 18.3873 11.1844 18.3644C11.322 18.3386 11.4532 18.273 11.7155 18.1418L17.1155 15.4418C17.4366 15.2813 17.5971 15.201 17.7144 15.0813C17.8181 14.9754 17.897 14.8477 17.9453 14.7076C18 14.5492 18 14.3697 18 14.0107V7.99958M1 6.49958L10.6422 1.67846C10.7734 1.61287 10.839 1.58008 10.9078 1.56717C10.9687 1.55574 11.0313 1.55574 11.0922 1.56717C11.161 1.58008 11.2266 1.61287 11.3578 1.67846L21 6.49958L11.3578 11.3207C11.2266 11.3863 11.161 11.4191 11.0922 11.432C11.0313 11.4434 10.9687 11.4434 10.9078 11.432C10.839 11.4191 10.7734 11.3863 10.6422 11.3207L1 6.49958Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
