export const mapper = (entity: string) => {
  switch (entity) {
    case 'workPosition':
      return 'work-positions?add-new-work-position=true';
    case 'department':
      return 'departments?add-new-department=true';
    default:
      return entity;
  }
};
