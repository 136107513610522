import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Id } from 'types/Id';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { useTranslation } from 'react-i18next';

const AssetCategoryAction: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const onEdit = async () => {
    searchParams.append('edit-asset-category', id);
    setSearchParams(searchParams);
  };

  const onViewCategory = () => {
    navigate(`details?id=${id}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editAssetCategory')}>
                <StyledIcon onClick={onEdit}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('details')}>
                <StyledIcon onClick={onViewCategory}>
                  <ViewProfileIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default AssetCategoryAction;
