import { Col, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  StatusActivity,
  TrainingCreateType,
  TrainingStatus,
  TrainingType,
} from 'types/Training';
import { createTraining, updateTraining } from 'api/trainingService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import GenericModal from 'ui-v2/components/GenericModal';
import { useDispatch } from 'react-redux';
import useHeader from 'ui-v2/hooks/useHeader';
import { fetchTraining, fetchTrainings } from 'redux/trainings/actions';
import { AddTrainingIcon } from 'Icons/AddTrainingIcon';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'types/OptionTypes';
import moment from 'moment';
import GenericForm from '../Form';
import { fetchTrainingSkillsOptions } from '../SelectWithLoad/utils';
import { isFormEdited } from '../Form/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedTraining?: TrainingType;
  modalLoading?: boolean;
}
export default function NewTrainingForm({
  open,
  closeModal,
  selectedTraining,
  modalLoading = false,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { take } = useHeader();
  const { t } = useTranslation();

  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  async function onSubmit() {
    const formValues: TrainingCreateType = form.getFieldsValue(true);

    const valuesToSend: TrainingCreateType = {
      name: formValues.name,
      timeType: formValues.timeType,
      skills: formValues.skills.map((el: any) => el.label) as string[],
      currecyCertification: formValues.currecyCertification,
      certificationCost: Number(formValues.certificationCost),
      valueTime: Number(formValues.valueTime),
      cost: Number(formValues.cost),
      status: formValues.status,
      type: formValues.type,
      currecyTraining: formValues.currecyTraining,
      certificationName: formValues.certificationName,
    };

    isLoading(true);

    if (selectedTraining?.id) {
      const valuesToSendUpdate: TrainingType = {
        ...valuesToSend,
        id: selectedTraining.id,
      };

      if (
        isFormEdited({
          formValues: valuesToSendUpdate,
          valuesToCheck: selectedTraining,
          entity: 'training',
        })
      ) {
        isLoading(false);
        closeModal();
        return;
      }

      try {
        const res = await updateTraining(
          selectedTraining.id,
          valuesToSendUpdate
        );
        if (res.status === 200) {
          closeModal();
          toast.success(t('Training was updated successfully!'));
          isLoading(false);
          if (selectedTraining.id) {
            dispatch(fetchTraining(selectedTraining.id));
          }
          dispatch(fetchTrainings({ page: 1, take }));
        }
      } catch (err: { response: { data: { error: string } } } | any) {
        if (err?.response?.data?.error) {
          const parsedError = JSON.parse(err.response.data.error);
          toast.error(
            `${t(parsedError.error)}: ${moment(parsedError.maxDate).format(
              'DD/MM/YYYY - HH:mm'
            )}`
          );
        } else {
          toast.error(t('Error during updating'));
        }
        isLoading(false);
      }
    } else {
      try {
        const res = await createTraining(valuesToSend);
        if (res.status === 200) {
          closeModal();
          toast.success(t('Training created successfully'));
          isLoading(false);
          dispatch(fetchTrainings({ page: 1, take }));
        }
      } catch (err) {
        toast.error('Training was not created!');
        isLoading(false);
      }
    }
  }

  const TrainingFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: 'input',
          defaultValue: selectedTraining?.name,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'valueTime',
          label: t('duration'),
          type: 'selectPrefix',
          defaultValue: selectedTraining?.valueTime,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'timeType',
            selectOptions: Object.values(TrainingStatus).map(
              (item: string) => ({
                id: item,
                value: item,
                label: item.charAt(0).toUpperCase() + item.slice(1),
              })
            ),
            defaultValue:
              selectedTraining?.timeType || Object.values(TrainingStatus)[0],
            placeholder: 'Select',
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        },
        {
          col: 11,
          offset: 2,
          name: 'status',
          label: t('trainingStatus'),
          type: 'select',
          selectOptions: Object.values(StatusActivity).map((item: string) => ({
            id: item,
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1),
          })),
          defaultValue: selectedTraining?.status,
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'type',
          label: t('type'),
          type: 'input',
          defaultValue: selectedTraining?.type,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'cost',
          label: t('trainingCost'),
          type: 'selectPrefix',
          defaultValue: selectedTraining && selectedTraining?.cost,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currecyTraining',
            selectOptions: currencyOptions,
            defaultValue:
              selectedTraining?.currecyTraining?.id || currencyOptions[1]?.id,
            placeholder: '',
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          loading: currencyLoading,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'skills',
          label: t('trainingSkills'),
          type: InputTypes.SELECT_LOAD,
          mode: 'multiple',
          fetchOptions: fetchTrainingSkillsOptions,
          defaultValue: selectedTraining?.skills?.map(
            (skill: string | { value: string }) =>
              ({
                id: skill,
                value: skill,
                label: skill,
              } as OptionType)
          ),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'certificationName',
          label: t('certificationName'),
          type: 'input',
          defaultValue: selectedTraining?.certificationName,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'certificationCost',
          label: t('certificationCost'),
          type: 'selectPrefix',
          defaultValue: selectedTraining?.certificationCost,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currecyCertification',
            selectOptions: currencyOptions,
            defaultValue:
              selectedTraining?.currecyCertification?.id ||
              currencyOptions[1]?.id,
            placeholder: '',
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          loading: currencyLoading,
        },
      ],
    ],
    [selectedTraining, currencyOptions]
  );

  return (
    <GenericModal
      title={`${selectedTraining ? t('Edit training') : t('addTraining')}`}
      open={open}
      closeModal={closeModal}
      icon={<AddTrainingIcon />}
    >
      <GenericForm
        formConfiguration={TrainingFormConfiguration}
        form={form}
        onFinish={onSubmit}
        loading={loading || modalLoading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                type="primary"
                htmlType="submit"
                loading={loading || modalLoading}
                onClick={onSubmit}
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
