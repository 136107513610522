import { Dispatch } from 'redux';
import { getAllEmployeesSkim } from '../../api/employeeService';
import { EmployeeSkim } from './types';

export const fetchEmployeesSkim = () => (dispatch: Dispatch) => {
  dispatch({
    type: EmployeeSkim.FETCH_EMPLOYEES_SKIM_START,
  });

  getAllEmployeesSkim()
    .then((res) => {
      dispatch({
        type: EmployeeSkim.FETCH_EMPLOYEES_SKIM_SUCCESS,
        data: res.data,
      });
    })
    .catch((e) => {
      dispatch({ type: EmployeeSkim.FETCH_EMPLOYEES_SKIM_ERRORED, payload: e });
    });
};
