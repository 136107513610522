import React from 'react';

export const ArrowDownIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00016 1.33301V10.6663M6.00016 10.6663L10.6668 5.99967M6.00016 10.6663L1.3335 5.99967"
      stroke="#475467"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
