import { EvaluationPeriodsType } from 'types/EvaluationPeriods';
import { MetaPagination } from 'types/GridTable';

export enum EvaluationPeriods {
  FETCH_EVALUATION_PERIOD_START = 'FETCH_EVALUATION_PERIOD_START',
  FETCH_EVALUATION_PERIOD_SUCCESS = 'FETCH_EVALUATION_PERIOD_SUCCESS',
  FETCH_EVALUATION_PERIOD_ERRORED = 'FETCH_EVALUATION_PERIOD_ERRORED',

  FETCH_EVALUATION_PERIODS_START = 'FETCH_EVALUATION_PERIODS_START',
  FETCH_EVALUATION_PERIODS_SUCCESS = 'FETCH_EVALUATION_PERIODS_SUCCESS',
  FETCH_EVALUATION_PERIODS_ERRORED = 'FETCH_EVALUATION_PERIODS_ERRORED',

  CLEAR_EVALUATION_PERIOD = 'CLEAR_EVALUATION_PERIOD',
}

export interface EvaluationPeriodsState {
  data: {
    data: Array<EvaluationPeriodsType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface EvaluationPeriodState {
  data: EvaluationPeriodsType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
