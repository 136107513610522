import { takeLatest, put, call } from 'redux-saga/effects';

import {
  login,
  getAuthUser,
  logOutAndRedirect,
  authenticateTenantUser,
  clearLocalStorage,
  getPublicSuperAdmin,
} from 'api/authService';
import {
  ACCESS_TOKEN,
  IS_LOGGEDIN,
  EMPLOYEE_ID,
  USER,
  USER_ROLE,
  TENANT_ID,
  USER_LANGUAGE,
  DEFAULT_LANGUAGE,
} from 'utils/constants';
import {
  AuthActionPayload,
  AuthActionTypes,
  AuthUserState,
  SuperAdminLoginPaylod,
  TwoFactorAuthenticationPayload,
} from 'redux/authUser/types';

import * as actions from 'redux/authUser/actions';
import { isEmployee, toastErrorMessages } from 'utils/utilFunctions';
import { Action } from 'redux';
import { AUTH_ROLES } from 'types/Auth';
import {
  authenticatePublicSuperAdmin,
  publicSuperAdminLogin,
} from 'api/publicSuperAdminServise';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* onSuperAdminLogin(payload: AuthActionPayload) {
  const { userCredentials } = payload;
  yield put(actions.updateAuthState({ loading: true }));
  try {
    const response: ResponseGenerator = yield call(
      publicSuperAdminLogin,
      userCredentials
    );
    if (response) {
      const data: SuperAdminLoginPaylod = response?.data;
      const {
        superAdmin: { isTwoFAComplete },
        token,
      } = data;
      localStorage.setItem(ACCESS_TOKEN, token);

      if (!isTwoFAComplete) {
        yield put(
          actions.updateAuthState({
            twoFactorSetupRequired: true,
          })
        );
        return;
      }
      yield put(actions.updateAuthState({ twoFactorRequired: true }));
    }
  } catch (error: any) {
    yield put(actions.updateAuthState({ error: error?.response?.data?.error }));
    toastErrorMessages(error);
  } finally {
    yield put(actions.updateAuthState({ loading: false }));
  }
}

function* onLogin(payload: AuthActionPayload) {
  yield put(actions.updateAuthState({ loading: true }));
  const { userCredentials } = payload;
  try {
    const response: ResponseGenerator = yield call(login, userCredentials);
    if (response) {
      const {
        data: {
          user,
          user: { twoFactorEnabled, isTwoFAComplete, lang },
          token,
          tenantId,
        },
      } = response;
      localStorage.setItem(ACCESS_TOKEN, token);
      localStorage.setItem(TENANT_ID, tenantId);
      localStorage.setItem(USER_LANGUAGE, lang || DEFAULT_LANGUAGE);

      if (twoFactorEnabled && !isTwoFAComplete) {
        yield put(actions.updateAuthState({ twoFactorSetupRequired: true }));
        return;
      }

      if (twoFactorEnabled && isTwoFAComplete) {
        yield put(
          actions.updateAuthState({
            twoFactorRequired: true,
          })
        );
        return;
      }

      if (isEmployee({ authUser: user } as AuthUserState)) {
        if (!user.employee?.id) {
          yield put(actions.updateAuthState({ error: user }));
          localStorage.removeItem(USER);
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(IS_LOGGEDIN);
          localStorage.removeItem(TENANT_ID);
          localStorage.removeItem(EMPLOYEE_ID);
          localStorage.removeItem(USER_ROLE);
          return;
        }
      }
      yield put(
        actions.updateAuthState({
          authUser: user,
          authUserRole: user?.roles?.at(0)?.name,
          accountBlocked: false,
          authUserPermissions: user?.roles?.at(0)?.permissions,
        })
      );
      localStorage.setItem(IS_LOGGEDIN, 'true');
      localStorage.setItem(TENANT_ID, tenantId);
      localStorage.setItem(USER_ROLE, user?.roles[0]?.name);
      localStorage.setItem(EMPLOYEE_ID, user.employee.id);
    }
  } catch (error: any) {
    if (error?.response?.data?.error === 'Your account has been blocked!') {
      yield put(
        actions.updateAuthState({
          accountBlocked: true,
          error: error?.response?.data?.error,
        })
      );
      return;
    }
    yield put(actions.updateAuthState({ error: error?.response?.data?.error }));
    toastErrorMessages(error);
  } finally {
    yield put(actions.updateAuthState({ loading: false }));
  }
}

function* onGetUser() {
  const isSuperAdmin = !localStorage.getItem(TENANT_ID);
  try {
    let response: ResponseGenerator;
    if (isSuperAdmin) {
      response = yield call(getPublicSuperAdmin);
      const { data: userData } = response;
      yield put(
        actions.updateAuthState({
          authUser: userData,
          authUserRole: AUTH_ROLES.SUPER_ADMIN,
        })
      );
    } else {
      response = yield call(getAuthUser);
      const { data: userData } = response;
      yield put(
        actions.updateAuthState({
          authUser: userData,
          authUserRole: userData?.roles?.at(0)?.name,
          authUserPermissions: userData?.roles?.at(0)?.permissions,
        })
      );
    }
  } catch (error) {
    toastErrorMessages(error);
    yield call(logOutAndRedirect);
  }
}

function* onTwoFactorAuthentication(
  payload: Action & TwoFactorAuthenticationPayload
) {
  const { authentionCode, isSuperAdminLogin } = payload;
  yield put(actions.updateAuthState({ loading: true }));
  try {
    let response: ResponseGenerator;

    if (isSuperAdminLogin) {
      response = yield call(authenticatePublicSuperAdmin, authentionCode);
    } else {
      response = yield call(authenticateTenantUser, authentionCode);
    }
    if (response) {
      const {
        data: { token, user },
      } = response;

      localStorage.setItem(IS_LOGGEDIN, 'true');
      if (isSuperAdminLogin) {
        localStorage.setItem(ACCESS_TOKEN, response?.data?.access_token);
      } else {
        localStorage.setItem(ACCESS_TOKEN, token);
      }

      if (isSuperAdminLogin) {
        yield put(
          actions.updateAuthState({
            authUserRole: AUTH_ROLES.SUPER_ADMIN,
          })
        );
      } else {
        yield put(
          actions.updateAuthState({
            authUser: user,
            authUserRole: user?.roles?.at(0)?.name,
            authUserPermissions: user?.roles?.at(0)?.permissions,
          })
        );
        localStorage.setItem(USER_ROLE, user?.roles[0]?.name);
        localStorage.setItem(EMPLOYEE_ID, user.employee.id);
      }
    }
  } catch (error: any) {
    yield put(actions.updateAuthState({ error: error?.response?.data?.error }));
    toastErrorMessages(error);
  } finally {
    yield put(actions.updateAuthState({ loading: false }));
  }
}

function* onLogout() {
  yield call(clearLocalStorage);
}

export default function* AuthSaga() {
  yield takeLatest(AuthActionTypes.ON_LOGIN, onLogin);
  yield takeLatest(AuthActionTypes.GET_USER, onGetUser);
  yield takeLatest(AuthActionTypes.ON_LOGOUT, onLogout);
  yield takeLatest(AuthActionTypes.ON_SUPER_ADMIN_LOGIN, onSuperAdminLogin);
  yield takeLatest(
    AuthActionTypes.ON_TWO_FACTOR_AUTHENTICATION,
    onTwoFactorAuthentication
  );
}
