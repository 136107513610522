/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import Content from 'ui-v2/components/Content';
import CardItem from 'ui-v2/components/Card';
import { Avatar, Col } from 'antd';
import { Item } from 'ui-v2/components/PersonalInfo';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { FallBackImage } from 'ui-v2/components/Tenant/utils';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SuperAdminEditModal from './SuperAdminEditModal/SuperAdminEditModal';
import SuperAdminChangePasswordModal from './SuperAdminChangePasswordModal';

const SuperAdminProfile: React.FC = () => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [changePasswodModalVisible, setChangePasswodModalVisible] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const toogleChangePasswodModalVisibility = useCallback(() => {
    setChangePasswodModalVisible(!changePasswodModalVisible);
  }, [changePasswodModalVisible]);
  const toogleEditModalVisibility = useCallback(() => {
    setEditModalVisible(!editModalVisible);
  }, [editModalVisible]);

  useEffect(() => {
    if (searchParams.get('edit-super-admin-profile')) {
      toogleEditModalVisibility();
      searchParams.delete('edit-super-admin-profile');
      setSearchParams(searchParams);
    }
    if (searchParams.get('change-super-admin-password')) {
      toogleChangePasswodModalVisibility();
      searchParams.delete('change-super-admin-password');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <Content position="center">
      <>
        <CardItem title={t('personalInfo')}>
          <Styled.RowItem gutter={[0, 10]} last>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Avatar
                size={64}
                src={authUser?.avatar ?? FallBackImage}
                style={{ background: '#0074c1' }}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('email')}
                value={authUser?.email}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('firstName')}
                value={authUser?.firstName}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('lastName')}
                value={authUser?.lastName}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('phoneNumber')}
                value={authUser?.phoneNumber}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('birthdate')}
                value={
                  authUser?.birthday
                    ? new Date(authUser.birthday).toLocaleDateString()
                    : 'N/A'
                }
                children={undefined}
              />
            </Col>
          </Styled.RowItem>
        </CardItem>
        {editModalVisible && (
          <SuperAdminEditModal onClose={toogleEditModalVisibility} />
        )}
        {changePasswodModalVisible && (
          <SuperAdminChangePasswordModal
            onClose={toogleChangePasswodModalVisibility}
          />
        )}
      </>
    </Content>
  );
};

export default SuperAdminProfile;
