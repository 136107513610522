import { EmployeeType } from './Employee';
import { OnboardingModelType } from './Onboarding';

export enum OnboardingTaskStatus {
  NEW = 'new',
  IN_PROGRESS = 'in progress',
  DONE = 'done',
}

export const OnboardingTaskStatusOptions = [
  {
    id: OnboardingTaskStatus.NEW,
    value: OnboardingTaskStatus.NEW,
    label:
      OnboardingTaskStatus.NEW.charAt(0).toUpperCase() +
      OnboardingTaskStatus.NEW.slice(1),
    key: '0',
  },
  {
    id: OnboardingTaskStatus.IN_PROGRESS,
    value: OnboardingTaskStatus.IN_PROGRESS,
    label:
      OnboardingTaskStatus.IN_PROGRESS.charAt(0).toUpperCase() +
      OnboardingTaskStatus.IN_PROGRESS.slice(1),
    key: '1',
  },
  {
    id: OnboardingTaskStatus.DONE,
    value: OnboardingTaskStatus.DONE,
    label:
      OnboardingTaskStatus.DONE.charAt(0).toUpperCase() +
      OnboardingTaskStatus.DONE.slice(1),
    key: '2',
  },
];

export interface OnboardingTaskModelType {
  id: string;
  boardingTaskType: string;
  category: string;
  startDate: Date;
  endDate: Date;
  order?: number;
  status: string;
  onBoarding: OnboardingModelType;
  duration: Date;
  mentor: EmployeeType;
}

export interface OnboardingTaskCreateType {
  id: string;
  boardingTaskType: string;
  status: OnboardingTaskStatus;
  category: string;
  duration?: string;
  onBoardingId: string;
  mentor: EmployeeType;
}

export interface OnboardingTaskUpdateType {
  boardingTaskType?: string;
  category?: string;
  startDate?: Date;
  endDate?: Date;
  order?: number;
  onBoardingId?: string;
  mentor: EmployeeType;
}
