import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const RowItem = styled(Row)`
  width: 100%;
  padding-top: 10px;
`;

export const Label = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;

export const RateLabel = styled(Typography.Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`;
