import React from 'react';

export const NotificationsModalIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1E0FF" />

    <path
      d="M 25.293 38.2 C 25.998 38.822 26.924 39.2 27.938 39.2 C 28.953 39.2 29.879 38.822 30.584 38.2 M 33.938 25.2 C 33.938 23.609 33.306 22.083 32.181 20.957 C 31.056 19.832 29.53 19.2 27.938 19.2 C 26.347 19.2 24.821 19.832 23.696 20.957 C 22.571 22.083 21.938 23.609 21.938 25.2 C 21.938 28.29 21.159 30.406 20.288 31.805 C 19.554 32.986 19.186 33.576 19.2 33.741 C 19.215 33.923 19.253 33.993 19.4 34.102 C 19.533 34.2 20.131 34.2 21.327 34.2 L 34.55 34.2 C 35.746 34.2 36.344 34.2 36.477 34.102 C 36.624 33.993 36.662 33.923 36.677 33.741 C 36.691 33.576 36.323 32.986 35.589 31.805 C 34.718 30.406 33.938 28.29 33.938 25.2 Z"
      stroke="#155EEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#EFF4FF"
      strokeWidth="8"
    />
  </svg>
);
