import React, { useCallback, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import { FormConfigurationType } from 'types/FormTypes';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import { VendorQuestionnaireApiClient } from 'api/vendorQuestionnaireService';
import { VendorQuestionnaireAsyncAction } from 'redux/vendorQuestionnaire/actions';
import { toastErrorMessages } from 'utils/utilFunctions';
import { getVendorQuestionnaireQuestionFormConfig } from './utils';
import GenericForm from '../Form/GenericForm';
import { StyledButton } from '../FormStyled';

interface VendorQuestionnaireQuestionFormProps {
  open: boolean;
  closeModal: () => void;
  id?: string;
}

const VendorQuestionnaireQuestionForm: React.FC<
  VendorQuestionnaireQuestionFormProps
> = ({ open, closeModal, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const {
    questionnaireCategory: { selectedQuestionnaireCategory },
  } = useVendorQuestionnaireData();
  const [loading, setLoading] = useState<boolean>(false);

  const onFormFinish = useCallback(async () => {
    if (!selectedQuestionnaireCategory?.id) {
      return;
    }
    setLoading(true);

    if (id) {
      const payload: VendorQuestionnaireApiClient.QuestionnaireUpdateQuestionRequest =
        {
          id,
          payload: {
            question: form.getFieldValue('question'),
            priority: form.getFieldValue('priority'),
            vendorQuestionnaireCategoryId: selectedQuestionnaireCategory?.id,
          },
        };
      VendorQuestionnaireApiClient.updateQuestionnaire(payload)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('vendorQuestionnaireQuestionUpdatedSuccessfully'));
            dispatch(
              VendorQuestionnaireAsyncAction.fetchCategorie({
                id: selectedQuestionnaireCategory?.id,
              })
            );
          }
        })
        .catch((e) => toastErrorMessages(e));
    } else {
      const payload: VendorQuestionnaireApiClient.QuestionnaireCreateQuestionRequest =
        {
          payload: {
            question: form.getFieldValue('question'),
            priority: form.getFieldValue('priority'),
            vendorQuestionnaireCategoryId: selectedQuestionnaireCategory?.id,
          },
        };
      VendorQuestionnaireApiClient.createQuestionnaire(payload)
        .then((res) => {
          if (res.status === 201) {
            toast.success(t('vendorQuestionnaireQuestionAddedSuccessfully'));
            dispatch(
              VendorQuestionnaireAsyncAction.fetchCategorie({
                id: selectedQuestionnaireCategory?.id,
              })
            );
          }
        })
        .catch((e) => toastErrorMessages(e));
    }
    setLoading(false);
    closeModal();
  }, [selectedQuestionnaireCategory, form, id]);

  const VendorQuestionnaireQuestionFormConfiguration: FormConfigurationType[][] =
    useMemo(() => {
      const questionnaire =
        selectedQuestionnaireCategory?.vendorQuestionnaire?.find(
          (question) => question.id === id
        ) || null;

      return [
        ...getVendorQuestionnaireQuestionFormConfig({
          questionnaire,
        }),
      ];
    }, [selectedQuestionnaireCategory]);

  const title = id
    ? t('editVendorQuestionnaireQuestion')
    : t('addVendorQuestionnaireQuestion');

  return (
    <GenericModal title={title} open={open} closeModal={closeModal}>
      <GenericForm
        formConfiguration={VendorQuestionnaireQuestionFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={loading}
      >
        <Row gutter={[0, 0]}>
          <Col span={24} />
          <Col span={24}>
            <Row gutter={[0, 30]}>
              <Col span={24}>
                <Row>
                  <Col span={11}>
                    <StyledButton onClick={closeModal} htmlType="reset" danger>
                      {t('cancel')}
                    </StyledButton>
                  </Col>
                  <Col span={11} offset={2}>
                    <StyledButton
                      onClick={() => form.submit()}
                      type="primary"
                      htmlType="submit"
                    >
                      {t('submit')}
                    </StyledButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </GenericForm>
    </GenericModal>
  );
};

export default VendorQuestionnaireQuestionForm;
