import React, { useCallback, useEffect, useState } from 'react';
import { Form, Spin } from 'antd';
import { Image as ImageType } from 'types/Image';
import { useSearchParams } from 'react-router-dom';

import {
  TenantConfigDescriptionDto,
  TenantConfigUpdateDTO,
} from 'types/TenantConfig';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import { SettingTab } from 'types/settings';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import TenantConfigFormContentLayout from './components/TenantConfigFormContentLayout';

import { getTenantConfigDto } from './utils';
import TenantMissionConfig from './components/TenantMissionConfig';
import TenantGeneralConfig from './components/TenantGeneralConfig';

export interface TenantOnboardingConfigProps {
  validating?: boolean;
  formValues?: (values: any) => void;
  toggleValidation?: () => void;
  hideConfigureBtn?: boolean;
}

interface TenantConfigFormContentProps {
  loading: boolean;
  onSubmit: (values: any) => void;
  tenantOnboardingConfigProps?: TenantOnboardingConfigProps;
}

const TenantConfigFormContent: React.FC<TenantConfigFormContentProps> = ({
  loading,
  onSubmit,
  tenantOnboardingConfigProps,
}) => {
  const [generalConfigForm] = Form.useForm();
  const [companyDescriptionPanelForm] = Form.useForm();
  const [companyLocationPanelForm] = Form.useForm();

  const [searchParams, setSearchParams] = useSearchParams();
  const [fileToUpload, setFileToUpload] = useState<ImageType>();

  const { tenantPersisted: tenant } = useTenantsData();

  const { data: tenantConfig, loading: tenantConfLoading } =
    useTenantConfigData();

  useEffect(() => {
    if (searchParams.get('settings-save') === SettingTab.CONFIGURATIONS) {
      searchParams.delete('settings-save');
      setSearchParams(searchParams);
      companyDescriptionPanelForm.submit();
      companyLocationPanelForm.submit();
      generalConfigForm.submit();
    }
  }, [searchParams]);

  useEffect(() => {
    if (tenantOnboardingConfigProps?.validating) {
      companyDescriptionPanelForm.submit();
      generalConfigForm.submit();
      tenantOnboardingConfigProps?.toggleValidation?.();
    }
  }, [tenantOnboardingConfigProps?.validating]);

  const onFinish = useCallback(
    (formValues: any) => {
      const companyDescriptionPanelFormErrors =
        companyDescriptionPanelForm.getFieldsError();

      const hasErrors = companyDescriptionPanelFormErrors.some(
        (fieldError) => !!fieldError.errors.length
      );

      if (!tenantConfig || hasErrors) {
        return;
      }
      let valuesToSubmit: TenantConfigUpdateDTO = getTenantConfigDto({
        formValues,
        tenantConfig,
        fileToUpload,
      });

      const missionVisionValues: TenantConfigDescriptionDto =
        companyDescriptionPanelForm.getFieldsValue();

      valuesToSubmit = {
        ...valuesToSubmit,
        tenant: tenant?.id as string,
        ...(Object.values(missionVisionValues)?.length && {
          missionVisionValues: JSON.stringify([missionVisionValues]),
        }),
      };

      onSubmit(valuesToSubmit);
    },
    [tenant, tenantConfig, companyDescriptionPanelForm, fileToUpload]
  );

  return (
    <Spin spinning={loading || tenantConfLoading}>
      <TenantConfigFormContentLayout
        companyDescriptionPanelContent={
          <TenantMissionConfig formInstance={companyDescriptionPanelForm} />
        }
      >
        <TenantGeneralConfig
          generalConfigForm={generalConfigForm}
          companyDescriptionPanelForm={companyDescriptionPanelForm}
          onFinish={onFinish}
          setFileToUpload={setFileToUpload}
        />
      </TenantConfigFormContentLayout>
    </Spin>
  );
};

export default TenantConfigFormContent;
