import agent from 'api/agent';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { PurchaseOrderType } from 'types/PurchaseOrder';
import { apiURLs } from './constants';

export const getPurchaseOrders = async (queryParams?: QueryParamsType) =>
  agent.post(
    `${apiURLs.purchaseOrder}/get`,
    searchFields(queryParams, apiURLs.purchaseOrder)
  );

export const createPurchaseOrder = (payload: PurchaseOrderType) =>
  agent.post(`${apiURLs.purchaseOrder}`, payload);

export const editPurchaseOrder = (
  payload: PurchaseOrderType,
  purchaseOrderId: string
) => agent.patch(`${apiURLs.purchaseOrder}/${purchaseOrderId}`, payload);

export const getPurchaseOrder = (id: string) =>
  agent.get(`${apiURLs.purchaseOrder}/${id}`);

export const deletePurchaseOrder = (id: string) =>
  agent.delete(`${apiURLs.purchaseOrder}/${id}`);
