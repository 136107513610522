import agent from './agent';
import { apiURLs } from './constants';

export const getCompany = (id: string) =>
  agent.get(`${apiURLs.companies}/${id}`);

export const getCompanyLogoContent = (token: string) =>
  agent.get(`${apiURLs.companies}/logo-content`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
