import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Content from 'ui-v2/components/Content';
import { EmployeeWelcomeScreen } from 'types/Employee';
import { getEmployeeWelcomeScreen } from 'api/employeeService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { EMPLOYEE_ID } from 'utils/constants';
import { ItemLabel } from 'ui-v2/components/Employees/Onboarding/EmployeeOnboardingTaskItem/EmployeeOnboardingTaskItemStyles';

import {
  LogoPlaceholder,
  RoundedImage,
  TenantNameStyle,
} from 'layouts/LayoutStyles/SideBarStyles';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import {
  FlexRow,
  OnboardingHeaderWrapper,
  OnboardingStepBtn,
} from './StyledOnboardingSteps';
import { OnboardingStep } from './utils';
import WelcomeScreen from './WelcomeScreen';
import BankDetails from './BankDetails';
import ExpectationsAndResponsibilities from './ExpectationsAndResponsibilities';
import OnboardingMission from './OnboardingMission';
import ContractAndConfidenatialityAgreement from './ContractAndConfidenatialityAgreement';

const { Step } = Steps;

export default function OnboardingSteps() {
  const authUserState = useSelector((state: any) => state.authUser);
  const { tenantPersisted: tenant } = useTenantsData();
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onboardingStepsData, setOnboardingStepsData] =
    useState<EmployeeWelcomeScreen>();

  const employeeId =
    authUserState?.employee?.id ||
    (localStorage.getItem(EMPLOYEE_ID) as string);

  const getWelcomScreenData = async (id: string) => {
    try {
      const response = await getEmployeeWelcomeScreen(id);
      if (response.status === 200 && response.data) {
        setOnboardingStepsData(response.data);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };
  useEffect(() => {
    getWelcomScreenData(employeeId);
  }, []);

  const onPreviousBtnClick = () => setStep((step) => step - 1);

  const onNextBtnClick = () => {
    setStep((step) => step + 1);
  };

  useEffect(() => {
    if (searchParams.get('bank-detail-completed') === 'true') {
      onNextBtnClick();
      searchParams.delete('bank-detail-completed');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  let nextBtnText = 'Start Onboarding';
  nextBtnText = step === 0 ? 'Start Onboarding' : 'Next';
  nextBtnText = step === 1 ? 'Complete' : nextBtnText;

  return (
    <Row gutter={[0, 50]}>
      <OnboardingHeaderWrapper span={24}>
        <Row align="middle" gutter={[8, 0]}>
          <Col>
            <LogoPlaceholder collapsed={false}>
              <RoundedImage
                src={tenant?.logoContent}
                collapsed={false}
                alt={tenant?.name}
                visible={!!tenant?.logoContent}
              />
            </LogoPlaceholder>
          </Col>
          <Col>
            {tenant?.name && (
              <TenantNameStyle>{`${tenant?.name ?? ''}`}</TenantNameStyle>
            )}
          </Col>
        </Row>
      </OnboardingHeaderWrapper>
      <Col span={24}>
        <Content position="center">
          <Row gutter={[0, 40]} style={{ padding: '0 5rem' }}>
            <Col span={24}>
              <Steps current={step} onChange={setStep}>
                <Step title={OnboardingStep.WHEN_AND_WHERE} disabled />
                <Step title={OnboardingStep.EMPLOYEE_INFO} disabled />
                <Step title={OnboardingStep.GET_INTRODUCED} disabled />
                <Step title={OnboardingStep.TASK_COMPLETED} disabled />
                <Step title={OnboardingStep.DOCUMENTS} disabled />
              </Steps>
            </Col>
            <Col span={24}>
              {step === 0 && <WelcomeScreen data={onboardingStepsData} />}
              {step === 1 && <BankDetails employeeId={employeeId} />}
              {step === 2 && (
                <OnboardingMission
                  data={onboardingStepsData}
                  arrayMissionData={
                    onboardingStepsData &&
                    (onboardingStepsData.missionVision
                      ? JSON.parse(onboardingStepsData.missionVision)
                      : [])
                  }
                />
              )}
              {step === 3 && (
                <ExpectationsAndResponsibilities
                  data={onboardingStepsData}
                  arrayMissionData={
                    onboardingStepsData &&
                    (onboardingStepsData.missionVision
                      ? JSON.parse(onboardingStepsData.missionVision)
                      : [])
                  }
                />
              )}
              {step === 4 && <ContractAndConfidenatialityAgreement />}
            </Col>
            {step !== 4 && (
              <Col span={24}>
                <Row justify="end" align="middle" gutter={[10, 0]}>
                  {step > 0 && (
                    <Col sm={8} md={10} lg={6} xl={4}>
                      <OnboardingStepBtn
                        disabled={step === 0}
                        onClick={onPreviousBtnClick}
                      >
                        <FlexRow align="middle">
                          <Col span={10}>
                            <LeftOutlined />
                            <ItemLabel style={{ marginLeft: 8 }}>
                              Previous
                            </ItemLabel>
                          </Col>
                        </FlexRow>
                      </OnboardingStepBtn>
                    </Col>
                  )}
                  {step === 1 && (
                    <Col sm={8} md={8} lg={6} xl={4}>
                      <OnboardingStepBtn onClick={onNextBtnClick}>
                        <FlexRow align="middle">
                          <Col span={10}>
                            <ItemLabel style={{ marginRight: 8 }}>
                              Skip
                            </ItemLabel>
                            <RightOutlined />
                          </Col>
                        </FlexRow>
                      </OnboardingStepBtn>
                    </Col>
                  )}
                  <Col sm={10} md={10} lg={10} xl={step === 0 ? 8 : 4}>
                    <OnboardingStepBtn
                      type="primary"
                      onClick={() => {
                        if (step === 1) {
                          searchParams.append('bank-details-save', 'true');
                          setSearchParams(searchParams);
                        } else {
                          onNextBtnClick();
                        }
                      }}
                    >
                      <Row align="middle" gutter={[10, 0]}>
                        <Col>
                          <ItemLabel>
                            {`${step === 4 ? 'Finish' : nextBtnText}`}
                          </ItemLabel>
                        </Col>
                        {step === 1 ||
                          step === 2 ||
                          (step === 3 && (
                            <Col>
                              <RightOutlined />
                            </Col>
                          ))}
                      </Row>
                    </OnboardingStepBtn>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Content>
      </Col>
    </Row>
  );
}
