import { Reducer } from 'react';
import { Action } from 'redux';

import { DepartmentState, Departments, DepartmentsState } from './types';

const initialState: DepartmentsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<DepartmentsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Departments.FETCH_DEPARTMENTS_START:
      state = { ...state, loading: true };
      break;
    case Departments.FETCH_DEPARTMENTS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Departments.FETCH_DEPARTMENTS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialStateSingle: DepartmentState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const singleDepartment: Reducer<DepartmentState, Action> = (
  state = initialStateSingle,
  action: any
) => {
  switch (action.type) {
    case Departments.FETCH_DEPARTMENT_START:
      state = { ...state, loading: true };
      break;
    case Departments.FETCH_DEPARTMENT_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Departments.FETCH_DEPARTMENT_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Departments.CLEAR_DEPARTMENT:
      state = initialStateSingle;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as DepartmentsReducer, singleDepartment };
