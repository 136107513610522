import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';
import { RcFile } from 'antd/lib/upload';
import React, { useMemo, useEffect, useState } from 'react';

import { Image } from 'types/Image';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  convertBase64,
  convertDateToUTC,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { CreateTenantAccountType } from 'types/Tenant';
import { getAllTenantRoles, registerTenantAccount } from 'api/tenantService';
import { prefixSelector } from 'utils/constants';
import { OptionType } from 'types/OptionTypes';
import { RolesType } from 'redux/authUser/types';
import { useDispatch } from 'react-redux';
import { clearTenants, fetchTenantAccounts } from 'redux/tenants/actions';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';
import { StyledButton } from '../FormStyled';

interface TenantAccountRegistrationFormProps {
  onSubmitted: () => void;
}

const TenantAccountRegistrationForm: React.FC<
  TenantAccountRegistrationFormProps
> = ({ onSubmitted }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, isLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<Image>();
  const [tenantRoleOptions, setTenantRoleOptions] =
    useState<Array<OptionType>>();
  const tenantId = new URLSearchParams(window.location.search).get('tenantId');
  const { t } = useTranslation();

  const fetchTenantRoles = (tenantId: string) => {
    isLoading(true);
    getAllTenantRoles(tenantId)
      .then((res) => {
        if (res.status === 200 && res?.data?.length) {
          const roles = res.data.filter(
            (el: RolesType) => el.name !== 'super_admin'
          ) as Array<RolesType>;
          setTenantRoleOptions(
            roles.map((role: RolesType) => ({
              id: role.name,
              value: role.name,
              label: role.name.replaceAll('_', ' '),
            }))
          );
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => isLoading(false));
  };

  useEffect(() => {
    if (tenantId) {
      fetchTenantRoles(tenantId);
    }
  }, [tenantId]);

  function onFormFinish(values: CreateTenantAccountType) {
    isLoading(true);
    if (!tenantId) {
      return;
    }

    const payload: CreateTenantAccountType = {
      ...values,
      tenantId,
      avatar: fileToUpload?.content ?? '',
      birthDate: convertDateToUTC(values.birthDate),
    };

    delete payload.tenantId;

    registerTenantAccount(tenantId, payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('Account registered successfully!'));
          dispatch(fetchTenantAccounts(tenantId));
          onSubmitted();
          dispatch(clearTenants());
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64, type: file.type });
    return false;
  }

  const AccountConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 7,
          offset: 0,
          name: 'firstName',
          label: t('firstName'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('First name is required!'),
            },
          ],
        },
        {
          col: 7,
          offset: 2,
          name: 'lastName',
          label: t('lastName'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('Last name is required!'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'birthDate',
          label: t('birthdate'),
          type: InputTypes.DATEPICKER,
          birthday: true,
          rules: [
            {
              required: true,
              message: t('Birthdate is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'roles',
          label: t('role'),
          type: InputTypes.SELECT,
          styles: { textTransform: 'capitalize' },
          selectOptions: tenantRoleOptions,
          disabled: !tenantRoleOptions?.length,
          isMultiSelect: true,
          rules: [
            {
              required: true,
              message: t('Role is required'),
            },
          ],
        },
      ],

      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          rules: [
            {
              required: true,
              message: t('requiredEmail'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          rules: [
            {
              required: true,
              message: t('Phone number is required!'),
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'avatar',
          label: t('avatar'),
          type: 'upload',
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (fileToUpload: RcFile) => uploadAction(fileToUpload),
            maxCount: 1,
            listType: 'picture',
          },
          rules: [
            {
              required: true,
              message: t('Avatar is required!'),
            },
          ],
        },
      ],
    ],
    [tenantRoleOptions]
  );

  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <GenericForm
          formConfiguration={AccountConfiguration}
          onFinish={onFormFinish}
          form={form}
          loading={loading}
        />
      </Col>
      <Col span={24}>
        <Row justify="space-between">
          <Col span={11}>
            <StyledButton type="ghost" onClick={onSubmitted}>
              {t('cancel')}
            </StyledButton>
          </Col>
          <Col span={11}>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              {t('submit')}
            </StyledButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TenantAccountRegistrationForm;
