import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Popconfirm, Row, Tooltip } from 'antd';

import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import * as Styled from 'components/common/DataGrid/DataGridStyles';
import { VendorQuestionPriority } from 'types/VendorQuestionnaire';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { EdititingIcon } from 'Icons/EditIcon';
import { StyledIcon } from 'components/NewForms/ApiKeysForm/ApiKeysFormStyles';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { IconButton } from 'components/Actions/AssetAction';
import {
  StyledVendorQuestionnaireQuestionsTablePriorityTag,
  StyledVendorQuestionnaireQuestionsTableText,
} from './StyledVendorQuestionnaireQuestion';

interface VendorQuestionnaireQuestionProps {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
}

const VendorQuestionnaireQuestionTable: React.FC<
  VendorQuestionnaireQuestionProps
> = ({ onEdit, onDelete, onSelect }) => {
  const { t } = useTranslation();

  const {
    questionnaireCategory: { selectedQuestionnaireCategory, loading },
  } = useVendorQuestionnaireData();
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(
    selectedQuestionnaireCategory?.vendorQuestionnaire?.[0]?.id
  );

  const onSelectHandler = (record: any) => {
    if (!record?.id) return;
    setSelectedRowId(record?.id);
    onSelect(record?.id);
  };

  const selectedRowKeys = selectedRowId ? [selectedRowId] : [];
  const defaultSelectedRowKeys = selectedQuestionnaireCategory
    ?.vendorQuestionnaire?.[0]?.id
    ? [selectedQuestionnaireCategory?.vendorQuestionnaire?.[0]?.id]
    : [];

  return (
    <Styled.DataGrid
      rowSelection={{
        type: 'radio',
        selectedRowKeys,
        defaultSelectedRowKeys,
        onSelect: onSelectHandler,
      }}
      loading={loading}
      dataSource={selectedQuestionnaireCategory?.vendorQuestionnaire}
      pagination={false}
      rowKey={(record: any) => record.id}
      columns={[
        {
          key: 'question',
          title: '',
          dataIndex: 'question',
          width: '70%',
          render: (question: string) => (
            <StyledVendorQuestionnaireQuestionsTableText>
              {question}
            </StyledVendorQuestionnaireQuestionsTableText>
          ),
        },
        {
          key: 'priority',
          title: t('priority'),
          dataIndex: 'priority',
          width: '15%',
          render: (priority: VendorQuestionPriority) => (
            <StyledVendorQuestionnaireQuestionsTablePriorityTag
              priority={priority}
            >
              {t(priority)}
            </StyledVendorQuestionnaireQuestionsTablePriorityTag>
          ),
        },
        {
          key: 'id',
          title: t('actions'),
          dataIndex: 'id',
          width: '10%',
          render: (id: string) => (
            <Row justify="center" gutter={[10, 0]}>
              <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
                <Col>
                  <Tooltip title={t('editVendorQuestionnaireQuestion')}>
                    <IconButton onClick={() => onEdit(id)}>
                      <StyledIcon>
                        <EdititingIcon />
                      </StyledIcon>
                    </IconButton>
                  </Tooltip>
                </Col>
              </RoleBasedButton>
              <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
                <Col>
                  <Popconfirm
                    placement="topLeft"
                    title={t(
                      'areYouSureYouWantToArchiveThisVendorQuestionnaireQuestion'
                    )}
                    onConfirm={() => onDelete(id)}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <IconButton>
                      <StyledIcon>
                        <DeleteIcon />
                      </StyledIcon>
                    </IconButton>
                  </Popconfirm>
                </Col>
              </RoleBasedButton>
            </Row>
          ),
        },
      ]}
    />
  );
};

export default VendorQuestionnaireQuestionTable;
