/* eslint-disable indent */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton } from 'antd';
import React, { useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/WorkExperience';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { EmployeeWorkExperienceType } from 'types/EmployeeWorkExperience';
import { useTranslation } from 'react-i18next';
import AddWorkExperienceModal from './AddWorkExperienceModal';

import * as Styled from '../../WorkExperience/WorkExperienceStyles';

interface IProps {
  workExperience: EmployeeWorkExperienceType[];
  loading: boolean;
}
export default function EmployeeWorkExperience({
  workExperience,
  loading,
}: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [workExperienceSelected, setWorkExperienceSelected] =
    useState<EmployeeWorkExperienceType>();

  const onToggleModal = () => setModalVisible((state) => !state);

  const { t } = useTranslation();

  return (
    <CardItem title={t('workExperience')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        {workExperience?.length > 0 ? (
          <Row gutter={[0, 16]}>
            {workExperience?.map((w: EmployeeWorkExperienceType, index) => (
              <Styled.RowItem
                key={w.id}
                last={index === workExperience.length - 1}
              >
                <Col span={24}>
                  <Item
                    label={w.workTitle}
                    company={w.companyName}
                    value={`${convertUTCtoFormatedLocalTime(w.startDate)} - ${
                      w.endDate
                        ? convertUTCtoFormatedLocalTime(w.endDate)
                        : 'Present'
                    }`}
                    setWorkExperience={() => {
                      setWorkExperienceSelected(w);
                      setModalVisible(true);
                    }}
                  />
                </Col>
              </Styled.RowItem>
            ))}
            <Styled.RowItem last justify="center">
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col>
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="large"
                    style={{
                      borderRadius: '8px',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '344054',
                    }}
                    onClick={onToggleModal}
                  >
                    {t('addworkExperience')}
                  </Button>
                </Col>
              </RoleBasedButton>
            </Styled.RowItem>
          </Row>
        ) : (
          <Row gutter={[0, 24]}>
            <Styled.RowItem justify="center" last>
              <Col>
                <Styled.NodataLabel>
                  {t('thisemployeehasnoworkexperienceYet')}
                </Styled.NodataLabel>
              </Col>
            </Styled.RowItem>
            <Styled.RowItem last justify="center">
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col>
                  <Row justify="center">
                    <Button
                      icon={
                        <Styled.PlusIcon>
                          <PlusCircleOutlined />
                        </Styled.PlusIcon>
                      }
                      size="large"
                      style={{
                        borderRadius: '8px',
                        width: '100%',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '344054',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={onToggleModal}
                    >
                      {t('addworkExperience')}
                    </Button>
                  </Row>
                </Col>
              </RoleBasedButton>
            </Styled.RowItem>
          </Row>
        )}
        {modalVisible && (
          <AddWorkExperienceModal
            open={modalVisible}
            workExperienceSelected={workExperienceSelected}
            closeModal={() => {
              setModalVisible(false);
              setWorkExperienceSelected(undefined);
            }}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
