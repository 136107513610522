import { getDepartmentSelectOptions } from 'api/departmentService';
import { getProjectSelectOptions } from 'api/projectService';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';

export async function fetchProjectsList(
  name: string
): Promise<SelectLoadPayload> {
  return getProjectSelectOptions({
    filterOptions: { filterText: name, filterFields: ['name'] },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchDepartmentsList(
  name: string
): Promise<SelectLoadPayload> {
  return getDepartmentSelectOptions({
    filterOptions: { filterText: name, filterFields: ['name'] },
    pageOptions: { page: 1 },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}
