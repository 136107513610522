import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchDepartments } from 'redux/departments/actions';
import Content from 'ui-v2/components/Content';
import { useDepartmentData } from 'ui-v2/hooks/useDepartmentData';
import useHeader from 'ui-v2/hooks/useHeader';
import DepartmentForm from 'components/NewForms/DepartmentForm/DepartmentForm';
import { deleteDepartment } from 'api/departmentService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function DepartmentsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useDepartmentData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const toogleModalVisibility = () =>
    setModalVisible((modalVisible) => !modalVisible);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchDepartments(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchDepartments(params));
    setSearchTerm(searchTerm);
  };
  useEffect(() => {
    if (searchParams.get('add-new-department') === 'true') {
      searchParams.delete('add-new-department');
      setSearchParams(searchParams);
      toogleModalVisibility();
    }
    if (searchParams.get('edit-department')) {
      toogleModalVisibility();
    }
  }, [searchParams]);

  async function deleteDepartmentById(id: string | null) {
    if (!id) return;
    deleteDepartment(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Department deleted successfully!');
          dispatch(fetchDepartments({ page: pagination?.page, take: 10 }));
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteDepartmentById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Departments"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <DepartmentForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-department');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
