import { Col, Form, Result, Row, Skeleton } from 'antd';
import React, { useMemo, useEffect, useState } from 'react';

import { InputTypes } from 'types/FormTypes';
import CardItem from 'ui-v2/components/Card';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RcFile } from 'antd/lib/upload/interface';
import {
  convertBase64,
  isDuplicateEmailError,
  isOfUrlValid,
  toastErrorMessages,
  validateFileSize,
} from 'utils/utilFunctions';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import GenericForm from 'components/NewForms/Form';
import Content from 'ui-v2/components/Content';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { OptionType } from 'types/OptionTypes';
import SkillsMatrix, {
  appendSkills,
} from 'components/NewForms/DynamicForm/SkillsMatrix';
import {
  CurrencyType,
  Gender,
  OpenCandidateRegistration,
} from 'types/Candidates';
import {
  candidateRegistration,
  getCertificationsByTenantId,
  getCurrenciesByTenantId,
  getOpenPositionById,
  getSkillsByTenantId,
} from 'api/openRoutesService';
import { OpenPositionType } from 'interfaces/OpenPosition';
import { prefixSelector } from 'utils/constants';
import { CertificationType } from 'types/Certification';
import NoAuthPageLayout from 'ui-v2/components/NoAuth/NoAuthPageLayout';
import { useTranslation } from 'react-i18next';

const Preview = () => <FileOutlinedIcon />;

function CandidateForm() {
  const [form] = Form.useForm();
  const { tenantId } = useParams();
  const [searchParams] = useSearchParams();
  const [defaultSkills, setDefaultSkills] = useState([]);
  const [loading, isLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
  }>({});
  const { t } = useTranslation();

  const [openPosition, setPositionOption] = useState<OpenPositionType>();
  const [currencyOptions, setCurrencyOptions] = useState<Array<OptionType>>([]);
  const [certificationOptions, setCertificationOptionsOptions] = useState<
    Array<OptionType>
  >([]);
  const [success, setSuccess] = useState(false);

  const fetchOpenPositon = async (openPositionId: string, tenantId: string) => {
    isLoading(true);
    getOpenPositionById({ openPositionId, tenantId })
      .then((response) => {
        if (response.status === 201 && response?.data) {
          setPositionOption(response.data);
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const fetchCurrencies = async (tenantId: string) => {
    isLoading(true);

    getCurrenciesByTenantId(tenantId)
      .then((res) => {
        if (res.status === 201 && res?.data) {
          setCurrencyOptions(
            res.data.map((currency: CurrencyType) => ({
              id: currency.id,
              label: currency.name,
              value: currency.symbol,
            }))
          );
        }
      })
      .catch((error) => toastErrorMessages(error))
      .finally(() => isLoading(false));
  };

  const fetchCertifications = async (tenantId: string) => {
    isLoading(true);
    getCertificationsByTenantId(tenantId)
      .then((res) => {
        if (res.status === 201 && res?.data) {
          setCertificationOptionsOptions(
            res.data.map((currency: CertificationType) => ({
              id: currency.id,
              label: currency.name,
              value: currency.id,
            }))
          );
        }
      })
      .catch((error) => toastErrorMessages(error))
      .finally(() => isLoading(false));
  };

  useEffect(() => {
    const openPositionId: string | null = searchParams.get('openPositionId');
    if (tenantId && openPositionId) {
      fetchOpenPositon(openPositionId, tenantId);
      fetchCurrencies(tenantId);
      fetchCertifications(tenantId);
    }
  }, [searchParams, tenantId]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (success) {
      timeout = setTimeout(() => {
        window.location.href = 'https://www.atrax.al';
      }, 5 * 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [success]);

  async function onFormFinish(values: any) {
    if (loading) return;
    if (values.expectedSalary < 0) {
      toast.warning(t('pleaseEnterAValidSalary!'));
      isLoading(false);
      return;
    }
    const valuesToSend: OpenCandidateRegistration = {
      ...appendSkills(values, [], [], []),
      gender: values.gender.charAt(0),
      tenantId,
      referalType: form.getFieldValue('referalType')?.toLowerCase() ?? 'other',
      candidateOpenPositionId: openPosition?.id,
      resume: {
        name: fileToUpload.name,
        content: fileToUpload.content?.split(',').pop(),
      },
    };

    if (
      !valuesToSend.linkedIn ||
      String(valuesToSend.linkedIn).replace(/\s/g, '').length === 0
    ) {
      delete valuesToSend.linkedIn;
    } else if (!isOfUrlValid(valuesToSend.linkedIn)) {
      toast.warning(t('LinkedIn URL is not valid'));
      return;
    }

    isLoading(true);

    try {
      await candidateRegistration(valuesToSend)
        .then((response) => {
          if (response.data?.id) {
            setSuccess(true);
            isLoading(false);
            toast.success(t('Registered successfully!'));
          }
        })
        .catch((error) => {
          if (isDuplicateEmailError(error)) {
            toast.warning(t('This e-mail is already in use'));
            return;
          }
          toast.error(t('somethingWentWrong'));
        });
      isLoading(false);
    } catch (error) {
      toast.error(t('errorDuringThisAction'));
      isLoading(false);
    }
  }

  async function uploadAction(file: RcFile) {
    if (!validateFileSize(file)) return false;
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64 });
    return false;
  }

  const CandidateFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'firstName',
          label: t('firstName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'lastName',
          label: t('lastName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'birthDate',
          label: t('birthday'),
          type: 'datepicker',
          birthday: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'gender',
          label: t('gender'),
          type: 'select',
          selectOptions: Object.values(Gender).map((item: Gender) => ({
            id: item,
            value: item,
            label: item,
          })),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: t('emailAddress'),
          type: 'input',
          inputProps: {
            type: 'email',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: 'selectPrefix',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'referalType',
          label: t('source'),
          type: InputTypes.SELECT_PREFIX_REFERAL,
          defaultValue: null,
          disableEmployeeReferal: true,
        },
        {
          col: 11,
          offset: 2,
          name: 'linkedIn',
          label: 'LinkedIn',
          type: 'input',
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'currentJobTitle',
          label: t('currentjobTitle'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'expectedSalary',
          label: t('expectedSalary'),
          type: 'selectPrefix',

          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions,
            placeholder: '',
            defaultValue: currencyOptions[1]?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'certifications',
          label: t('certifications'),
          type: 'select',
          selectOptions: certificationOptions,
          isMultiSelect: true,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'resume',
          type: 'upload',
          label: t('uploadCV'),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          uploadProps: {
            accept: ".pdf,'data:application/pdf;base64,'",
            beforeUpload: (file: RcFile) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            iconRender: Preview,
          },
        },
      ],
    ],
    [currencyOptions, certificationOptions]
  );

  const onEditedSkillChange = (index: number, field: any) => {
    let skill: any = defaultSkills && defaultSkills[index];

    if (typeof field === 'string') {
      skill = { ...skill, yearsOfExperience: field };
    } else {
      skill = { ...skill, skillSubCategoryId: field?.id };
    }

    const currentDefaultSkills: any = defaultSkills;
    currentDefaultSkills[index] = skill;

    setDefaultSkills([] as any);
    setDefaultSkills(currentDefaultSkills);
  };

  return (
    <NoAuthPageLayout>
      <Content position="stretch" bg="#F9F9F9">
        <Row justify="center">
          <Col xxl={12} xl={12} md={18} sm={20}>
            {success ? (
              <Result
                status="success"
                title={t('registrationSuccessful!')}
                subTitle={t('You will be contacted by the company soon!')}
                style={{
                  minHeight: '100vh',
                }}
              />
            ) : (
              <CardItem
                title={
                  openPosition?.name && (
                    <Skeleton
                      title={false}
                      active
                      paragraph={{ rows: 1 }}
                      loading={loading}
                    >
                      {openPosition.name}
                    </Skeleton>
                  )
                }
              >
                <GenericForm
                  formConfiguration={CandidateFormConfiguration}
                  onFinish={onFormFinish}
                  form={form}
                  loading={loading}
                >
                  <SkillsMatrix
                    fetchList={() => getSkillsByTenantId(tenantId as string)}
                    defaultSkills={defaultSkills as any}
                    onEditedSkillChange={onEditedSkillChange}
                  />
                  <FormItemStyled style={{ marginTop: 30 }}>
                    <Row>
                      <Col span={24}>
                        <StyledButton type="primary" htmlType="submit">
                          {t('submit')}
                        </StyledButton>
                      </Col>
                    </Row>
                  </FormItemStyled>
                </GenericForm>
              </CardItem>
            )}
          </Col>
        </Row>
      </Content>
    </NoAuthPageLayout>
  );
}

export default CandidateForm;
