import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useSearchParams } from 'react-router-dom';
import { createVendorCategory, editVendorCategory } from 'api/vendorCategory';
import { toast } from 'react-toastify';

import { toastErrorMessages } from 'utils/utilFunctions';
import { VendorCategory, VendorCategoryType } from 'types/VendorCategory';
import { useVendorCategoriesData } from 'ui-v2/hooks/useVendorCategoriesData';
import { useDispatch } from 'react-redux';
import {
  fetchSingleVendorCategory,
  fetchVendorCategories,
} from 'redux/vendorCategories/actions';
import useHeader from 'ui-v2/hooks/useHeader';
import { FormItemStyled, StyledButton } from '../FormStyled';
import GenericForm from '../Form/GenericForm';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedVendorCategory?: VendorCategoryType | null;
}

function VendorCategoryForm({
  open,
  closeModal,
  selectedVendorCategory,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const { take } = useHeader();
  const { list: vendorCategories, pagination } = useVendorCategoriesData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  async function onFormFinish() {
    const formValues = form.getFieldsValue(true);
    const valuesToSend: VendorCategory = {
      categoryName: formValues.categoryName,
      description: formValues.description,
    };

    isLoading(true);

    if (selectedVendorCategory?.id) {
      editVendorCategory(valuesToSend, selectedVendorCategory?.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyUpdatedVendorCategory'));
            dispatch(fetchSingleVendorCategory(selectedVendorCategory?.id));
            dispatch(fetchVendorCategories({ page: 1, take: 10 }));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          isLoading(false);
        });
    } else {
      createVendorCategory(valuesToSend)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyCreatedVendorCategory'));
            dispatch(
              fetchVendorCategories({ page: pagination?.page ?? 1, take })
            );
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          isLoading(false);
        });
    }
  }

  useEffect(() => {
    if (searchParams.get('edit-vendor-category')) {
      searchParams.delete('edit-vendor-category');
      setSearchParams(searchParams);
    }

    if (searchParams.get('add-vendor-category') === 'true') {
      searchParams.delete('add-vendor-category');
      setSearchParams(searchParams);
      form.submit();
    }

    if (searchParams.get('edit-vendor-category-save') === 'true') {
      searchParams.delete('edit-vendor-category-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams, form, setSearchParams, vendorCategories]);

  const VendorCategoryFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'categoryName',
          label: t('categoryName'),
          type: InputTypes.INPUT,
          defaultValue: selectedVendorCategory?.categoryName || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: InputTypes.TEXTAREA,
          defaultValue: selectedVendorCategory?.description || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
    ],
    [selectedVendorCategory]
  );

  return (
    <GenericModal
      title={
        selectedVendorCategory?.id
          ? t('editVendorCategory')
          : t('addVendorCategory')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={VendorCategoryFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default VendorCategoryForm;
