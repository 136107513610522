import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { updateInterview } from 'api/interviewService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import RateComponent from 'components/NewForms/RateComponent';
import { ScheduleInterviewModalIcon } from 'Icons/ScheduleInterviewModalIcon';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCandidateOpenPositionInterviews } from 'redux/candidates/actions';
import { InterviewsState } from 'redux/candidates/types';
import { RootState } from 'redux/store';
import { FormConfigurationType } from 'types/FormTypes';
import { InterviewType, InterviewTypeDTO } from 'types/Interview';
import { OptionType } from 'types/OptionTypes';

import GenericModal from 'ui-v2/components/GenericModal';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { useTranslation } from 'react-i18next';
import {
  convertUTCtoLocalTime,
  getUserDateFormat,
  isInterviewDatePassed,
} from 'utils/utilFunctions';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import {
  fetchInterviewTypeOrder,
  transformInterviewTypeOrderToOptionType,
} from '../utilts';

interface IProps {
  id: string;
  open: boolean;
  closeModal: (rankNumber?: number) => void;
}

export default function EditInterviewModal({ id, open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();

  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const { candidate, selectedCandidateOpenPosition } = useCandidateData();
  const {
    data: { interviews },
  } = useSelector((state: RootState) => state.interviews as InterviewsState);
  const [score, setScore] = useState(0);
  const interview: InterviewTypeDTO | undefined = interviews.find(
    (item) => item.id === id
  );
  const [interviewOrderList, setInterviewOrderList] = useState<
    Array<OptionType>
  >([]);
  const dispatch = useDispatch();

  async function getInterviewOrder(candidateOpenPositionId: string) {
    const data: Array<InterviewType> = await fetchInterviewTypeOrder(
      candidateOpenPositionId
    );
    setInterviewOrderList(transformInterviewTypeOrderToOptionType(data));
  }

  useEffect(() => {
    if (selectedCandidateOpenPosition?.data?.id) {
      getInterviewOrder(selectedCandidateOpenPosition?.data?.id);
    }
  }, [selectedCandidateOpenPosition]);

  const EditInterviewFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'date',
          label: t('dateAndTime'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(interview?.date),
          dateProps: {
            format: `${userDateFormat}  ->  HH:mm`,
            showTime: true,
          },
          rules: [
            {
              required: true,
              message: t('pickATime'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'partecipants',
          label: t('employee'),
          type: 'select-load',
          mode: 'multiple',
          defaultValue: interview?.partecipants.map((i) => ({
            id: i.id,
            value: i.id,
            label: `${i.firstName} ${i.lastName}`,
          })),
          fetchOptions: getEmployeeSkimSelectWithLoadOptions,
          rules: [
            {
              required: true,
              message: t('chooseEmployee'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'interviewType',
          label: t('interviewType'),
          type: 'select',
          defaultValue: {
            id: interview?.interviewType?.id,
            value: interview?.interviewType?.id,
            label: interview?.interviewType?.name,
          },
          selectOptions: interviewOrderList,
          rules: [
            {
              required: true,
              message: t('chooseInterviewType'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'comment',
          label: t('comment'),
          type: 'textarea',
          defaultValue: interview?.comments,
        },
      ],
    ],
    [interview, interviewOrderList]
  );

  const onSubmit = async () => {
    if (loading) return;

    const formValues = form.getFieldsValue(true);
    const values: any = {
      comment: formValues?.comment,
      date: formValues?.date,
      candidateOpenPositionId: formValues?.candidateOpenPositionId,
      score,
      interviewTypeId:
        formValues?.interviewType?.value || formValues?.interviewType,
      partecipants: formValues?.partecipants.map((i: OptionType) => i.value),
    };
    setLoading(true);

    await updateInterview(id, values)
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data) {
          closeModal();
          toast.success(`${t('updatedInterview')}!`);
          if (candidate?.id) {
            dispatch(
              fetchCandidateOpenPositionInterviews(
                selectedCandidateOpenPosition?.data?.id as string
              )
            );
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.warning(`${t('failedtoUpdateInterview')}!`);
      });
  };

  const modalTitle = selectedCandidateOpenPosition?.data?.openPositions?.name
    ? `${t('for')}  ${selectedCandidateOpenPosition?.data?.openPositions?.name}`
    : '';

  return (
    <GenericModal
      title={`${t('editAnInterview')} ${modalTitle}`}
      open={open}
      closeModal={() => closeModal()}
      icon={<ScheduleInterviewModalIcon />}
      maskClosable={false}
    >
      <GenericForm
        formConfiguration={EditInterviewFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Col span={12}>
            <RateComponent
              placeholder={
                isInterviewDatePassed(interview?.date || '')
                  ? t('availableAfterDatePassed')
                  : t('Rate')
              }
              name="score"
              onChange={setScore}
              defaultValue={interview?.score}
              disabled={isInterviewDatePassed(interview?.date || '')}
            />
          </Col>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => closeModal()}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
