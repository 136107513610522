import {
  EmployeeTaskCreateType,
  EmployeeTaskUpdateType,
} from 'types/EmployeeTask';
import agent from './agent';
import { apiURLs } from './constants';

export const createEmployeeTask = (values: EmployeeTaskCreateType) =>
  agent.post(`${apiURLs.employeeTasks}`, values);

export const getAllEmployeeTasks = () => agent.get(apiURLs.employeeTasks);

export const getEmployeeTasks = (employeeId: string) =>
  agent.get(`${apiURLs.employeeTasks}/employee/${employeeId}`);

export const updateEmployeeTask = (
  taskId: string,
  values: EmployeeTaskUpdateType
) => agent.put(`${apiURLs.employeeTasks}/${taskId}`, values);

export const deleteEmployeeTask = (taskId: string) =>
  agent.delete(`${apiURLs.employeeTasks}/${taskId}`);
