import { Form, Col, Typography, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { FormConfigurationType } from 'types/FormTypes';
import { toast } from 'react-toastify';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ForgotPassword } from 'redux/users/types';
import { useTranslation } from 'react-i18next';
import LoginFormLayout from '../LoginForm/LoginFormLayout';
import { forgotPassword } from '../../../api/authService';

const TextWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 25px;
`;

function ForgotPasswordForm() {
  const [form] = Form.useForm();
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const { loading } = authUserState;
  const handleForgotPassword = async (values: ForgotPassword) => {
    try {
      const response = await forgotPassword(values);
      if (response.status === 200) {
        toast.success(
          t('Email Sent. Please check your email for further instructions.')
        );
      } else {
        toast.error(t('Something went wrong. Please try again later.'));
      }
    } catch (error) {
      toast.error(t('There was an error processing your request.'));
    }
  };

  const ForgotPasswordFormConfiguration: FormConfigurationType[][] = [
    [
      {
        col: 24,
        offset: 0,
        name: 'email',
        label: t('email'),
        type: 'input',
        rules: [
          {
            required: true,
            message: t('requiredEmail'),
          },
        ],
      },
    ],
  ];

  return (
    <LoginFormLayout>
      <Typography.Text>
        <TextWrapper>{t('forgotPsswordMessage')}</TextWrapper>
      </Typography.Text>
      <GenericForm
        formConfiguration={ForgotPasswordFormConfiguration}
        form={form}
        loading={loading}
        onFinish={handleForgotPassword}
      >
        <FormItemStyled>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormItemStyled>
                <Link to="/login">Back to login</Link>
              </FormItemStyled>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <StyledButton type="primary" htmlType="submit">
                {t('continue')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </LoginFormLayout>
  );
}

export default ForgotPasswordForm;
