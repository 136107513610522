/* eslint-disable react/no-children-prop */
import { FolderOpenOutlined } from '@ant-design/icons';
import { Col, List, Row } from 'antd';
import { getDepartmentWorkPositions } from 'api/departmentService';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { WorkPositiontModelType } from 'types/WorkPosition';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';

interface IProps {
  departmentId: string | null;
  onRowClick: (item: WorkPositiontModelType) => void;
}

export default function DepartmentWorkPositions({
  departmentId,
  onRowClick,
}: IProps) {
  const [departmentWorkPositions, setDepartmentWorkPositions] = useState<
    WorkPositiontModelType[]
  >([]);
  const { t } = useTranslation();

  async function fetchDepartmentWorkPositions(departmentId: string | null) {
    if (!departmentId) return;
    getDepartmentWorkPositions(departmentId)
      .then((response) => {
        setDepartmentWorkPositions(response.data);
      })
      .catch(() => {
        toast.error(
          t('Something went wrong! Cant get department work positions')
        );
      })
      .finally(() => {
        console.log();
      });
  }

  useEffect(() => {
    if (departmentId) {
      fetchDepartmentWorkPositions(departmentId);
    }
  }, [departmentId]);

  const onItemClick = (employeeList: WorkPositiontModelType) => {
    onRowClick(employeeList);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <CardItem title={t('workPositionsOfTheDepartment')}>
        <List
          itemLayout="horizontal"
          style={{ width: '100%' }}
          dataSource={departmentWorkPositions}
          renderItem={(workPosition: WorkPositiontModelType) => (
            <Styled.Item onClick={() => onItemClick(workPosition)}>
              <List.Item.Meta
                avatar={<FolderOpenOutlined style={{ fontSize: '20px' }} />}
                description={
                  <Row justify="end">
                    <Col span={11}>
                      <Row justify="start">
                        <Styled.ItemLabel ellipsis>
                          {workPosition?.name || ''}
                        </Styled.ItemLabel>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Styled.ItemLabel ellipsis>
                        {workPosition?.requirements || ''}
                      </Styled.ItemLabel>
                    </Col>
                  </Row>
                }
              />
            </Styled.Item>
          )}
        />
      </CardItem>
    </>
  );
}
