import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import TenantConfigForm from 'components/NewForms/TenantConfigForm';
import {
  TenantOnboardinStepsLayout,
  TenantOnboardinStepsLayoutProps,
} from './TenantOnboardingSteps';

interface TenantOnboardingConfigurationProps
  extends TenantOnboardinStepsLayoutProps {
  onSubmit: (values: any) => void;
}

const TenantOnboardingConfiguration: React.FC<
  TenantOnboardingConfigurationProps
> = ({ onSubmit, ...otherProps }) => {
  const [validating, setValidating] = useState(false);
  const [submited, setSubmited] = useState(false);
  const formValues = (values: any) => {
    onSubmit(values);
    otherProps.onNextStep();
  };

  const toggleValidation = useCallback(() => {
    setValidating(!validating);
  }, [validating]);

  const toggleSubmit = useCallback(() => {
    setSubmited(!submited);
  }, [submited]);

  useEffect(() => {
    if (submited) {
      otherProps?.onNextStep();
    }
  }, [submited]);

  const onNextStep = useCallback(() => {
    if (!validating) {
      setValidating(true);
    }
  }, [validating]);

  return (
    <TenantOnboardinStepsLayout {...otherProps} onNextStep={onNextStep}>
      <Row justify="center" gutter={[0, 40]}>
        <Col span={24} />
        <Col span={18}>
          <TenantConfigForm
            tenantOnboardingConfigProps={{
              hideConfigureBtn: true,
              validating,
              toggleValidation,
              formValues,
            }}
            toggleSubmit={toggleSubmit}
          />
        </Col>
      </Row>
    </TenantOnboardinStepsLayout>
  );
};

export default TenantOnboardingConfiguration;
