import { Col, Row } from 'antd';
import React, { useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RightSection from 'ui-v2/components/RightSection';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import * as Styled from '../RenumarationStyles';
import { RenumarationCompensationItem } from '../RenumartionItem';
import EmploymentStatusModal from './EmploymentStatusModal.tsx/EmploymentSatusModal';

export default function RenumarationEmploymentSatus() {
  const [modalVisible, setModalVisible] = useState(false);
  const onToggleModal = () => setModalVisible((state) => !state);
  const { t } = useTranslation();

  return (
    <CardItem
      title={t('employmentStatus')}
      rightButton={
        <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
          <RightSection
            buttonCallBack={onToggleModal}
            buttonText={t('updateemploymentStatus')}
          />
        </RoleBasedButton>
      }
    >
      <>
        <Row gutter={[0, 24]}>
          <Styled.RowItem last style={{ paddingLeft: 10 }}>
            <Col span={8}>
              <Styled.NodataLabel>{t('effectiveDate')}</Styled.NodataLabel>
            </Col>
            <Col span={8}>
              <Styled.NodataLabel>{t('employmentStatus')}</Styled.NodataLabel>
            </Col>
            <Col span={8}>
              <Styled.NodataLabel>{t('comment')}</Styled.NodataLabel>
            </Col>
          </Styled.RowItem>
          <RenumarationCompensationItem />
        </Row>
        {modalVisible && (
          <EmploymentStatusModal
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
            }}
          />
        )}
      </>
    </CardItem>
  );
}
