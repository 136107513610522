/* eslint-disable no-empty-pattern */
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

export const StyledSiderbarMenu = styled(Row)(({}) => ({
  paddingTop: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledSidebarMenuItem = styled(Col)<{
  collapsed?: boolean;
  active?: boolean;
}>(({ theme, collapsed, active }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.75rem',
  cursor: 'pointer',
  ...(active && {
    backgroundColor: theme.colors.palette.primary.gray['700'],
  }),
  ':hover': {
    backgroundColor: theme.colors.palette.primary.gray['500'],
  },
  padding: collapsed ? '.8rem 0 .5rem 0' : '.75rem .5rem',
  ...(collapsed && {
    justifyContent: 'center',
  }),
  borderRadius: '.5rem',
  svg: {
    width: '1.2rem !important',
    height: '1.2rem !important',
  },
  width: '100%',
}));

export const StyledSidebarMenuText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body.semibold.sm,
  color: theme.colors.palette.primary.gray['100'],
  fontSize: '0.875rem',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const StyledSiderbarMenuGroupItem = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const StyledSidebarSubMenuItem = styled(Col).attrs(
  (props: { collapsed?: boolean; $active?: boolean }) => props
)(({ theme, collapsed, $active }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.75rem',
  cursor: 'pointer',
  ...($active && {
    backgroundColor: theme.colors.palette.primary.gray['700'],
  }),
  ':hover': {
    backgroundColor: theme.colors.palette.primary.gray['500'],
  },
  width: '100%',
  padding: collapsed ? '.8rem 0 .5rem 0' : '.75rem .5rem',
  margin: '0 .5rem',
  ...(collapsed && {
    justifyContent: 'center',
  }),
  borderRadius: '.5rem',
  svg: {
    width: '1rem !important',
    height: '1rem !important',
  },
}));

export const StyledSidebarSubMenu = styled(Col).attrs(
  (props: { collapsed?: boolean }) => props
)(({ collapsed }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.75rem',
  cursor: 'pointer',
  width: '100%',
  padding: collapsed ? '.8rem 0 .5rem 0' : '.75rem .5rem',
  ...(collapsed && {
    justifyContent: 'center',
  }),
  borderRadius: '.5rem',
  svg: {
    width: '.8rem !important',
    height: '.8rem !important',
  },
}));
