import React from 'react';
import { Col, Row } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';

import { IconButton } from 'components/Actions/AssetAction';
import { StyledHeaderText } from './StyledHeaderRender';

interface HeaderRenderProps {
  headerConfig: {
    value: moment.Moment;
    type: CalendarMode;
    onChange: (date: moment.Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
  };
  setDate: (date: moment.Moment) => void;
}

const HeaderRender: React.FC<HeaderRenderProps> = ({
  headerConfig,
  setDate,
}) => {
  const currentMonth = moment(headerConfig.value).startOf('month');
  const nextMonth = moment(headerConfig.value).add(1, 'month').startOf('month');
  const year = moment(headerConfig.value).format('YYYY');

  return (
    <Row justify="space-between">
      <Col>
        <Row align="middle" justify="center" gutter={[10, 0]}>
          <Col>
            <IconButton
              onClick={() =>
                setDate(currentMonth.subtract(1, 'month').startOf('month'))
              }
            >
              <LeftOutlined />
            </IconButton>
          </Col>
          <Col>
            <StyledHeaderText>{currentMonth.format('MMM')}</StyledHeaderText>
          </Col>
        </Row>
      </Col>
      <Col>
        <StyledHeaderText bold>{year}</StyledHeaderText>
      </Col>
      <Col>
        <Row align="middle" justify="center" gutter={[10, 0]}>
          <Col>
            <StyledHeaderText>{nextMonth.format('MMM')}</StyledHeaderText>
          </Col>
          <Col>
            <IconButton onClick={() => setDate(nextMonth)}>
              <RightOutlined />
            </IconButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderRender;
