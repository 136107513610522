import styled from 'styled-components';
import { Row } from 'antd';

export const Container = styled(Row)<{
  bg?: string;
}>`
  margin-bottom: 50px;
  ${({ bg }) => bg && `background-color: ${bg}`};
  padding: 1px;
`;
