import styled from 'styled-components';
import useHeader from 'ui-v2/hooks/useHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';
import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { EdititingIcon } from 'Icons/EditIcon';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { toast } from 'react-toastify';
import { fetchVendors } from 'redux/vendor/action';
import { deleteVendor } from 'api/vendorService';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { Id } from 'types/Id';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const VendorActions: React.FC<Id> = ({ id }) => {
  const { take } = useHeader();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pagination } = useQuestionnairesData();

  const onViewClick = () => {
    navigate(`vendor-details?id=${id}`);
  };

  const onEditClick = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'edit-vendor',
        value: id,
      })
    );
  };

  const onDeleteClick = async () => {
    try {
      const response = await deleteVendor(id);

      if (response.status === 200) {
        toast.success(t('deletedVendors'));
        dispatch(fetchVendors({ page: pagination?.page || 1, take }));
        return;
      }

      toast.warning(t('couldNotArchieveThisVendor'));
    } catch (error) {
      console.error('An error occurred while deleting the vendor:', error);
      toast.error(t('somethingWentWrong'));
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <IconButton onClick={onViewClick}>
              <ViewProfileIcon />
            </IconButton>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWantToArchiveThisVendor')}
                onConfirm={onDeleteClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default VendorActions;
