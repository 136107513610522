import { CertificationType, SingleCertificationDTO } from 'types/Certification';
import { MetaPagination } from 'types/GridTable';

export enum Certifications {
  FETCH_CERTIFICATIONS_START = 'FETCH_CERTIFICATIONS_START',
  FETCH_CERTIFICATIONS_SUCCESS = 'FETCH_CERTIFICATIONS_SUCCESS',
  FETCH_CERTIFICATIONS_ERRORED = 'FETCH_CERTIFICATIONS_ERRORED',

  FETCH_SINGLE_CERTIFICATION_START = 'FETCH_SINGLE_CERTIFICATION_START',
  FETCH_SINGLE_CERTIFICATION_SUCCESS = 'FETCH_SINGLE_CERTIFICATION_SUCCESS',
  FETCH_SINGLE_CERTIFICATION_ERRORED = 'FETCH_SINGLE_CERTIFICATION_ERRORED',

  CLEAR_SINGLE_CERTIFICATION = 'CLEAR_SINGLE_CERTIFICATION',
}

export interface CertificationsState {
  data: {
    data: Array<CertificationType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface SingleCertificationState {
  data: SingleCertificationDTO | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
