import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useMemo, useState } from 'react';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export default function CompensationModal({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const CompensationModalFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'effectiveDate',
          label: t('effectiveDate'),
          type: InputTypes.DATEPICKER,
          rules: [
            {
              required: true,
              message: 'This field is required!',
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'payRate',
          label: t('payRate'),
          type: 'selectPrefix',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions,
            placeholder: '',
            defaultValue: currencyOptions[1]?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        },
        {
          col: 11,
          offset: 2,
          name: 'payPer',
          label: t('payPer'),
          type: 'select',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'paySchedule',
          label: t('paySchedule'),
          type: 'select',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'payType',
          label: t('payType'),
          type: 'select',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'overtimeStatus',
          label: t('overtimeStatus'),
          type: 'select',
        },
        {
          col: 11,
          offset: 2,
          name: 'changeReason',
          label: t('change Reason'),
          type: 'select',
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'comment',
          label: t('comment'),
          type: InputTypes.TEXTAREA,
        },
      ],
    ],
    []
  );

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
  };

  return (
    <GenericModal
      title={t('updateCompensation')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={CompensationModalFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
