import React from 'react';

export const CandidatesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00002 21.6172C3.6026 21.7998 4.41649 21.7998 5.8 21.7998H16.2C17.5835 21.7998 18.3974 21.7998 19 21.6172M3.00002 21.6172C2.87082 21.5781 2.75133 21.5306 2.63803 21.4728C2.07354 21.1852 1.6146 20.7263 1.32698 20.1618C1 19.52 1 18.68 1 16.9998V6.5998C1 4.91965 1 4.07957 1.32698 3.43783C1.6146 2.87335 2.07354 2.41441 2.63803 2.12679C3.27976 1.7998 4.11984 1.7998 5.8 1.7998H16.2C17.8802 1.7998 18.7202 1.7998 19.362 2.12679C19.9265 2.41441 20.3854 2.87335 20.673 3.43783C21 4.07957 21 4.91965 21 6.5998V16.9998C21 18.68 21 19.52 20.673 20.1618C20.3854 20.7263 19.9265 21.1852 19.362 21.4728C19.2487 21.5306 19.1292 21.5781 19 21.6172M3.00002 21.6172C3.00035 20.8079 3.00521 20.3797 3.07686 20.0194C3.39249 18.4327 4.63288 17.1923 6.21964 16.8767C6.60603 16.7998 7.07069 16.7998 8 16.7998H14C14.9293 16.7998 15.394 16.7998 15.7804 16.8767C17.3671 17.1923 18.6075 18.4327 18.9231 20.0194C18.9948 20.3797 18.9996 20.8079 19 21.6172M15 9.2998C15 11.5089 13.2091 13.2998 11 13.2998C8.79086 13.2998 7 11.5089 7 9.2998C7 7.09067 8.79086 5.2998 11 5.2998C13.2091 5.2998 15 7.09067 15 9.2998Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
