import React, { useEffect } from 'react';
import useTenantStore from 'redux/persisted-state/tenant-store';
import { ConfigProvider } from 'antd';
import {
  DEFAULT_USER_DATE_FORMAT,
  USER_DATE_FORMAT,
  USER_LANGUAGE,
} from 'utils/constants';
import moment from 'moment';
import { Country } from 'country-state-city';
import { useTranslation } from 'react-i18next';
import localesConfig from 'configs/locales.config';

export const appMoment = moment;

const AppConfig: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { tenant } = useTenantStore();
  const userDateFormat =
    localStorage.getItem(USER_DATE_FORMAT) || DEFAULT_USER_DATE_FORMAT;

  const userLanguage = localStorage.getItem(USER_LANGUAGE);

  const isoCode =
    userLanguage ??
    Country.getAllCountries()
      .find((c) => c?.name === tenant?.country)
      ?.isoCode?.toLowerCase();

  const lang =
    localesConfig.find((el) => el.isoCode === isoCode)?.value || 'en-gb';

  useEffect(() => {
    i18n.changeLanguage(isoCode);
  }, [isoCode]);

  appMoment.locale(lang);

  return (
    <ConfigProvider
      locale={{
        locale: appMoment.locale(),
        DatePicker: {
          lang: {
            locale: appMoment.locale(),
            monthBeforeYear: true,
            monthFormat: 'MMM',
            yearFormat: 'YYYY',
            today: t('today'),
            now: t('now'),
            clear: t('clear'),
            ok: t('ok'),
            dateFormat: userDateFormat,
            backToToday: t('backToToday'),
          } as any,
        } as any,
      }}
    >
      <>{children}</>
    </ConfigProvider>
  );
};

export default AppConfig;
