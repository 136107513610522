import { useSelector } from 'react-redux';

export function useAssetCategoriesData() {
  const { data, subCategories, loading, loaded, errored, error } = useSelector(
    (state: any) => state.assetCategories
  );

  return {
    list: data?.data ?? [],
    subCategories,
    pagination: data?.meta,
    loading,
    loaded,
    error,
    errored,
  };
}
