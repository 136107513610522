import { Dispatch } from 'redux';
import { getKnowledgeForEmployee } from 'api/knowledgeService';
import { KnowledgeAction } from './types';

export const fetchKnowledge = (employeeId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: KnowledgeAction.FETCH_KNOWLEDGE_START,
  });
  getKnowledgeForEmployee(employeeId)
    .then((res) => {
      dispatch({
        type: KnowledgeAction.FETCH_KNOWLEDGE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: KnowledgeAction.FETCH_KNOWLEDGE_ERRORED,
        payload: e,
      });
    });
};

export const clearFetchKnowledge = () => (dispatch: Dispatch) => {
  dispatch({
    type: KnowledgeAction.CLEAR_KNOWLEDGE,
  });
};
