import { Request } from 'types/Request';

export enum Requests {
  FETCH_REQUESTS_START = 'FETCH_REQUESTS_START',
  FETCH_REQUESTS_SUCCESS = 'FETCH_REQUESTS_SUCCESS',
  FETCH_REQUESTS_ERRORED = 'FETCH_REQUESTS_ERRORED',
}

export interface RequestsState {
  data: Request[];
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
