import { getAllUsersList, getSingleUser } from 'api/usersService';
import { Dispatch } from 'redux';
import { UsersQueryParamsType } from 'types/QueryParams';
import { Users } from './types';

export const fetchUsers =
  (queryParams?: UsersQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Users.FETCH_USERS_START,
    });
    getAllUsersList(queryParams)
      .then((res) => {
        dispatch({
          type: Users.FETCH_USERS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Users.FETCH_USERS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleUser = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Users.FETCH_USER_START,
  });
  getSingleUser(id)
    .then((res) => {
      dispatch({
        type: Users.FETCH_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Users.FETCH_USER_ERRORED,
        payload: e,
      });
    });
};

export const clearUserData = () => (dispatch: Dispatch) => {
  dispatch({
    type: Users.CLEAR_USER_DATA,
  });
};
