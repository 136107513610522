import { Col, Row, Skeleton } from 'antd';
import CardItem from 'ui-v2/components/Card';
import React, { useState } from 'react';
import { EmployeeAssignmnetsType } from 'types/Employee';
import { formatDate, isEmployee } from 'utils/utilFunctions';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import ProjectModalForm from 'components/NewForms/ProjectForm';
import ProjectInformationItem from './ProjectInformationItem';
import AssignProjectModal from './AssignProjectModal';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import { LinkButtonStyled, StyledButton } from './ProjectInformationStyled';

interface IProps {
  assignments: EmployeeAssignmnetsType;
  visibility: boolean;
  loading: boolean;
}

export default function EmployeeProjectInformation({
  assignments,
  visibility,
  loading,
}: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const authUserState = useSelector((state: any) => state.authUser);
  const isEmployeeUser = isEmployee(authUserState);
  const { t } = useTranslation();

  const onToggleModal = () => setModalVisible((modalVisible) => !modalVisible);
  const onToggleProjectModal = () =>
    setProjectModalVisible((projectModalVisible) => !projectModalVisible);

  if (!assignments) return null;

  const { projects } = assignments;
  const employeeAssignments = assignments.assignments;

  return (
    <CardItem title={t('projectsInformation')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Row gutter={[0, 24]}>
          {employeeAssignments &&
            employeeAssignments.map((item, index: number) => (
              <ProjectInformationItem
                projectId={item.project?.id}
                project={item.project?.name}
                hireDate={formatDate(item.project?.startDate)}
                description={item.project?.description}
                isLast={employeeAssignments.length - 1 === index}
              />
            ))}
        </Row>
        <Row gutter={[0, 24]}>
          {!employeeAssignments.length && (
            <Styled.RowItem justify="center" last>
              <Col>
                <Styled.NodataLabel>
                  {t('therearenoprojectsassignedtothisemployeeYet')}
                </Styled.NodataLabel>
              </Col>
            </Styled.RowItem>
          )}
          {visibility && !isEmployeeUser && !employeeAssignments.length && (
            <Styled.RowItem justify="center" align="middle" last>
              <Col>
                <Styled.Link>
                  {t('youCanAddaProjectFromThisLink')}
                  <LinkButtonStyled type="link" onClick={onToggleProjectModal}>
                    {t('addanewProject')}
                  </LinkButtonStyled>
                </Styled.Link>
              </Col>
            </Styled.RowItem>
          )}
          {!isEmployeeUser && (
            <Styled.RowItem last justify="center">
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col>
                  <StyledButton
                    icon={
                      <Styled.PlusIcon>
                        <PlusCircleOutlined />
                      </Styled.PlusIcon>
                    }
                    size="large"
                    onClick={onToggleModal}
                  >
                    {t('assignProject')}
                  </StyledButton>
                </Col>
              </RoleBasedButton>
            </Styled.RowItem>
          )}
        </Row>
        {modalVisible && !isEmployeeUser && (
          <AssignProjectModal
            currentProjects={projects}
            open={modalVisible}
            closeModal={() => setModalVisible(false)}
          />
        )}
        {projectModalVisible && (
          <ProjectModalForm
            open={projectModalVisible}
            closeModal={() => setProjectModalVisible(false)}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
