import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
} from 'utils/utilFunctions';
import { ProjectDTOType } from 'types/Project';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { createNewProject, updateProject } from 'api/projectService';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';

import GenericModal from 'ui-v2/components/GenericModal';
import { useDispatch } from 'react-redux';
import { clearSingleProject } from 'redux/projects/actions';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';

import { FormItemStyled, StyledButton } from '../FormStyled';
import { isFormEdited } from '../Form/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function ProjectModalForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, isLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    project: { data },
  } = useProjectsData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDateFormat = getUserDateFormat();

  useEffect(() => {
    if (searchParams.get('add-save-project') === 'true') {
      searchParams.delete('add-save-project');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-save-project') === 'true') {
      searchParams.delete('edit-save-project');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  function onFormFinish(values: ProjectDTOType) {
    let noEndPeriod = false;
    let valuesToSend: any = {
      ...values,
      startDate: convertDateToUTC(values.startDate),
      noEndPeriod,
      employees: [],
    };
    if (!values.endDate) {
      noEndPeriod = true;
      delete values.endDate;
    } else {
      valuesToSend = {
        ...valuesToSend,
        endDate: convertDateToUTC(values.endDate),
      };
    }

    isLoading(true);

    if (data?.id) {
      delete valuesToSend?.employees;

      if (
        isFormEdited({
          formValues: valuesToSend,
          valuesToCheck: data,
          entity: 'project',
        })
      ) {
        closeModal();
        isLoading(false);
        return;
      }
      updateProject(valuesToSend, data?.id)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            toast.success(t('Project updated successfully!'));
            navigate(`/projects/project-details?id=${data?.id}`);
          }
        })
        .catch(() => {
          toast.error(t('Project failed to update!'));
        })
        .finally(() => {
          dispatch(clearSingleProject());
          isLoading(false);
        });
      return;
    }

    createNewProject(valuesToSend)
      .then((response) => {
        if (response.status === 200 && response.data) {
          closeModal();
          toast.success(t('Project created successfully'));
          navigate(`/projects/project-details?id=${response.data?.id}`);
        }
      })
      .catch(() => {
        toast.error(t('Project creation failed!'));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const ProjectFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('projectName'),
          type: InputTypes.INPUT,
          defaultValue: data?.name,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(data?.startDate),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('endDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(data?.endDate),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: InputTypes.TEXTAREA,
          defaultValue: data?.description,
          textAreaProps: { rows: 10 },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [data]
  );

  return (
    <GenericModal
      title={data?.id ? t('Edit Project') : t('Add Project')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={ProjectFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default ProjectModalForm;
