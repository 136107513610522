import { Tabs } from 'antd';
import styled from 'styled-components';

export const TabsStyled = styled(Tabs)`
  font-weight: 600;
  font-size: 1.125rem !important;
  line-height: 24px;
  color: #344054 !important;
  background-color: unset;
  & :before {
    border-bottom: unset !important;
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    background: #eaecf0 !important;
    border-radius: 6px !important;
    color: #1d2939 !important;
  }
  & .ant-tabs-tab {
    background-color: transparent !important;
  }
  & .ant-tabs-ink-bar {
    all: unset;
  }
  & :hover {
    color: #1d2939 !important;
  }
`;
