import React, { useEffect, useState } from 'react';
import { Avatar, Col, Row } from 'antd';
import { ViewFeedbackIcon } from 'Icons/ViewFeedbackIcon';
import GenericModal from 'ui-v2/components/GenericModal';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { convertUTCtoLocalTime, toastErrorMessages } from 'utils/utilFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import {
  TrackedHourApproveDTO,
  TrackedHourDTO,
  TrackedHourStatus,
} from 'types/tracking';
import { approveTrackingHourOverTime } from 'api/trackedHoursService';
import * as Styled from './OvertimeApprovalModalStyles';

export default function OvertimeApprovalModal({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const approverEmployeeId = authUserState?.authUser?.employee?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    trackedHoursToApprove: { data },
    fetch,
  } = useTrackedHoursData();
  const [trackingHourToApprove, setTrackingHourToApprove] =
    useState<TrackedHourDTO>();

  useEffect(() => {
    if (searchParams.get('view-trackingHour')) {
      const id = searchParams.get('view-trackingHour') as string;
      setTrackingHourToApprove(data?.find((el) => el?.id === id));
      searchParams.delete('view-trackingHour');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const updateOverTimeVote = async (vote: TrackedHourStatus) => {
    if (loading && !approverEmployeeId) {
      return;
    }
    setLoading(true);

    const payload: TrackedHourApproveDTO = {
      employeeId: approverEmployeeId as string,
      action: vote,
      trackingHoursId: trackingHourToApprove?.id as string,
    };

    try {
      await approveTrackingHourOverTime(payload);
      toast.success(
        `Tracking hour has been ${
          vote === 'APPROVED' ? 'succesfully approved' : 'rejected'
        } `
      );
      fetch({});
      closeModal();
      setLoading(false);
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const onCancelRequest = () => {
    updateOverTimeVote(TrackedHourStatus.REJECTED);
  };

  const onApproveRequest = () => {
    updateOverTimeVote(TrackedHourStatus.APPROVED);
  };

  return (
    <GenericModal
      title="View OverTime Tracking Hour"
      open={open}
      closeModal={closeModal}
      icon={<ViewFeedbackIcon />}
    >
      <>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row gutter={[0, 4]} align="middle">
              <Col span={4}>
                <Avatar size={50}>
                  {trackingHourToApprove?.employee?.firstName.trim().charAt(0)}
                  {trackingHourToApprove?.employee?.lastName.trim().charAt(0)}
                </Avatar>
              </Col>
              <Col span={8}>
                <Row gutter={[10, 0]}>
                  <Col>
                    <Styled.Text>
                      {trackingHourToApprove?.employee?.firstName ?? ''}
                    </Styled.Text>
                  </Col>
                  <Col>
                    <Styled.Text>
                      {trackingHourToApprove?.employee?.lastName ?? ''}
                    </Styled.Text>
                  </Col>
                </Row>
              </Col>
              <Col span={4} offset={8}>
                <Styled.Status
                  status={
                    trackingHourToApprove?.aprovalStatus as TrackedHourStatus
                  }
                >
                  {trackingHourToApprove?.aprovalStatus}
                </Styled.Status>
              </Col>
              <Col span={24} offset={4}>
                <Col span={20} style={{ marginBottom: '8px' }}>
                  {'Time: '}
                  <Styled.Text>
                    {moment(
                      convertUTCtoLocalTime(trackingHourToApprove?.startTime)
                    ).format('HH:mm')}
                    -
                    {moment(
                      convertUTCtoLocalTime(trackingHourToApprove?.endTime)
                    ).format('HH:mm')}
                  </Styled.Text>
                </Col>
              </Col>
              <Col span={24} offset={4}>
                <Col span={20} style={{ marginBottom: '8px' }}>
                  {'Date: '}
                  <Styled.Text>
                    {moment(
                      convertUTCtoLocalTime(trackingHourToApprove?.date)
                    ).format('DD/MM/YYYY')}
                  </Styled.Text>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        {trackingHourToApprove?.aprovalStatus === 'PENDING' && (
          <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
            <Row>
              <Col span={11}>
                <StyledButton
                  onClick={() => {
                    onCancelRequest();
                    closeModal();
                  }}
                  htmlType="reset"
                  danger
                >
                  Reject
                </StyledButton>
              </Col>
              <Col span={11} offset={2}>
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={onApproveRequest}
                >
                  Approve
                </StyledButton>
              </Col>
            </Row>
          </FormItemStyled>
        )}
      </>
    </GenericModal>
  );
}
