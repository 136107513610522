import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export default function EmploymentStatusModal({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const EmploymentStatusModalFormConfiguration: FormConfigurationType[][] =
    useMemo(
      () => [
        [
          {
            col: 24,
            offset: 0,
            name: 'effectiveDate',
            label: t('effectiveDate'),
            type: InputTypes.DATEPICKER,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'employmentStatus',
            label: t('employmentStatus'),
            type: 'select',
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'comment',
            label: t('comment'),
            type: InputTypes.TEXTAREA,
          },
        ],
      ],
      []
    );

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
  };

  return (
    <GenericModal
      title={t('updateemploymentStatus')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={EmploymentStatusModalFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
