import {
  EmergencyContactType,
  EmergencyContacUpdatetDto,
} from 'types/EmergencyContact';
import agent from 'api/agent';
import { apiURLs } from './constants';

export const createEmergencyContact = (values: EmergencyContactType) =>
  agent.post(`${apiURLs.emergencyContact}`, values);

export const getAllEmergencyContacts = () =>
  agent.get(apiURLs.emergencyContact);

export const getEmergencyContactById = (id: string) =>
  agent.get(`${apiURLs.emergencyContact}/${id}`);

export const updateEmergencyContact = (
  id: string,
  values: EmergencyContacUpdatetDto
) => agent.patch(`${apiURLs.emergencyContact}/${id}`, values);

export const deleteEmergencyContact = (id: string) =>
  agent.delete(`${apiURLs.emergencyContact}/${id}`);
