import React, { ReactNode } from 'react';

import { CloseModalIcon } from 'Icons/CloseModalIcon';
import * as Styled from './GenericModalStyles';

export type ModalProps = {
  title: string;
  children: ReactNode;
  open: boolean;
  closeModal: () => void;
  icon?: ReactNode;
  maskClosable?: boolean;
};

export default function GenericModal({
  title,
  children,
  open,
  closeModal,
  icon,
  maskClosable,
}: ModalProps) {
  return (
    <Styled.CustomModal
      title={
        <Styled.ModalHeaderContainer>
          {icon}
          <Styled.ModalHeaderLabel>{title}</Styled.ModalHeaderLabel>
        </Styled.ModalHeaderContainer>
      }
      visible={open}
      onCancel={closeModal}
      footer={null}
      closeIcon={<CloseModalIcon />}
      maskClosable={maskClosable}
    >
      {children}
    </Styled.CustomModal>
  );
}

GenericModal.defaultProps = {
  icon: null,
};
