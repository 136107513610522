import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Id } from 'types/Id';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { ArchieveTableIcon } from 'Icons/ArchieveTableIcon';
import { useTranslation } from 'react-i18next';
import { fetchQuestionnaires } from 'redux/questionnaires/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useHeader from 'ui-v2/hooks/useHeader';
import { removeQuestionnaire } from 'api/questionnairesService';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const QuestionnaireActions: React.FC<Id> = ({ id }) => {
  const { take } = useHeader();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pagination } = useQuestionnairesData();

  const onViewClick = () => {
    navigate(`questionnaire-details?id=${id}`);
  };

  const onEditClick = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'edit-questionnaire',
        value: id,
      })
    );
  };

  const onArchieveClick = async () => {
    const response = await removeQuestionnaire(id);

    if (response.status === 200) {
      toast.success(t('archivedQuestionnaire'));
      dispatch(
        fetchQuestionnaires({
          pageOptions: { page: pagination?.page || 1, take },
        })
      );
      return;
    }

    toast.warning(t('couldNotArchieveThisQuestionnaire'));
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <IconButton onClick={onViewClick}>
              <ViewProfileIcon />
            </IconButton>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWantToArchiveThisQuestionnaire')}
                onConfirm={onArchieveClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <ArchieveTableIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default QuestionnaireActions;
