import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const FullWidthRow = styled(Row)`
  width: 100%;
`;

export const ProjectName = styled(Typography.Text)`
  display: block;
`;

export const SubstituteName = styled(Typography.Text)`
  display: flex;
  flex-direction: column;
`;
