import React from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import EmployeeReviewQuestions from './EmployeeReviewQuestions';
import i18next from '../../i18n';

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeIds: Array<string>;
}
const EmployeeReviewModal = ({ open, closeModal, employeeIds }: IProps) => (
  <>
    <GenericModal
      title={i18next.t('leaveReview')}
      open={open}
      closeModal={closeModal}
      maskClosable={false}
    >
      <EmployeeReviewQuestions
        closeModal={closeModal}
        employeeIds={employeeIds}
      />
    </GenericModal>
  </>
);

export default EmployeeReviewModal;
