import React from 'react';
import { Row, Col, Empty, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  StyledTrackingCard,
  StyledTrackingCardContent,
  StyledTrackingCardNoData,
  StyledTrackingCardPanel,
  StyledTrackingCardSkeleton,
} from './StyledTrackingCard';
import { formatTotalMinutes } from '../utils';
import { StyledTrackingText } from '../StyledTracking';
import TrackingExport from './TrackingExport';
import i18next from '../../../../i18n';

interface TrackingCardProps {
  children: React.ReactNode;
  loading?: boolean;
  isEmpty?: boolean;
  overallTitle?: string;
  perPageTitle?: string;
  overallTotal?: number;
  pageTotal?: number;
  canAddHour?: boolean;
  onAddHourClick?: () => void;
}

const TrackingCard: React.FC<TrackingCardProps> = ({
  children,
  loading,
  isEmpty = true,
  overallTitle,
  perPageTitle,
  overallTotal,
  pageTotal,
  canAddHour,
  onAddHourClick,
}) => (
  <StyledTrackingCard>
    <StyledTrackingCardPanel span={24}>
      <Row gutter={[100, 0]}>
        <Col>
          <Row gutter={[10, 0]} align="middle">
            {overallTitle && (
              <Col>
                <StyledTrackingText light>
                  {`${i18next.t('overallApprovedHours')}:`}
                </StyledTrackingText>
              </Col>
            )}
            {(overallTotal || overallTotal === 0) && (
              <Col>
                <StyledTrackingText bold>
                  {formatTotalMinutes(overallTotal)}
                </StyledTrackingText>
              </Col>
            )}
          </Row>
        </Col>
        <Row gutter={[10, 0]} align="middle">
          {perPageTitle && (
            <Col>
              <StyledTrackingText light>{perPageTitle}</StyledTrackingText>
            </Col>
          )}
          {(pageTotal || pageTotal === 0) && (
            <Col>
              <StyledTrackingText bold>
                {formatTotalMinutes(pageTotal)}
              </StyledTrackingText>
            </Col>
          )}
        </Row>
        <Col />
      </Row>
      <Row align="middle" gutter={[10, 0]}>
        <Col>
          <TrackingExport />
        </Col>
        {canAddHour && (
          <Col>
            <StyledTrackingText
              cursorPointer
              borderLeft
              onClick={onAddHourClick}
            >
              <Space align="center">
                <PlusOutlined />
                {i18next.t('logHour')}
              </Space>
            </StyledTrackingText>
          </Col>
        )}
      </Row>
    </StyledTrackingCardPanel>
    {loading && (
      <Col span={24}>
        <StyledTrackingCardSkeleton shape="square" active />
      </Col>
    )}
    {!loading && isEmpty && (
      <Col span={24}>
        <StyledTrackingCardNoData>
          <Empty
            description={
              <StyledTrackingText light>
                {`${i18next.t(
                  'thereAreNoHoursTrackedFromtheSelectedInterval'
                )}!`}
              </StyledTrackingText>
            }
          />
        </StyledTrackingCardNoData>
      </Col>
    )}
    {!loading && !isEmpty && (
      <StyledTrackingCardContent>{children}</StyledTrackingCardContent>
    )}
  </StyledTrackingCard>
);

export default TrackingCard;
