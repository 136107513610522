import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import CardItem from 'ui-v2/components/Card';
import { PALETE } from 'components/styledComponents/colors';
import { getBarChartStats } from 'api/dashboardStats';
import { Col, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const BarChart = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any>({
    labels,
    datasets: [
      {
        label: t('candidates'),
        data: [],
        backgroundColor: PALETE['blue-400'],
      },
      {
        label: t('employees'),
        data: [],
        backgroundColor: PALETE['rose-400'],
      },
    ],
  });

  const fetchData = async () => {
    setLoading(true);
    getBarChartStats()
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.candidates &&
          response.data.employee
        ) {
          const candidates = response.data.candidates.map((item: any) => ({
            ...item,
            month: moment(`${item.month}`)
              .subtract(1, 'month')
              .startOf('month')
              .format('MMMM'),
          }));
          const employees = response.data.employee.map((item: any) => ({
            ...item,
            month: moment(`${item.month}`)
              .subtract(1, 'month')
              .startOf('month')
              .format('MMMM'),
          }));

          setChartData({
            labels,
            datasets: [
              {
                label: t('candidates'),
                data: labels.map(
                  (month: string) =>
                    candidates.find((item: any) => item.month === month)
                      ?.candidatesnumber
                ),
                backgroundColor: PALETE['blue-400'],
              },
              {
                label: t('employees'),
                data: labels.map(
                  (month: string) =>
                    employees.find((item: any) => item.month === month)
                      ?.employeenumber
                ),
                backgroundColor: PALETE['rose-400'],
              },
            ],
          });

          setData(
            response.data.candidates.length || response.data.employee.length
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        console.log('error');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CardItem title={t('employees/candidatesperMonth')} minHeight={460}>
      <Spin spinning={loading}>
        {!loading && !data ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 400,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {t('noDataYet.')}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="candidates/add-candidate"
              >
                {t('addSomeCandidates&EmployeesFirst!')}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <Bar
            data={chartData}
            options={{
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {},
              },
            }}
          />
        )}
      </Spin>
    </CardItem>
  );
};

export default BarChart;
