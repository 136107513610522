import React from 'react';
import { Col } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { IconButton } from 'components/Actions/AssetAction';
import {
  StyledEditableFormPanelTitleContainer,
  StyledEditableFormPanelTitleRow,
} from './StyledEditableFormPanel';

export interface EditableFormPanelCollapseProps {
  onClick: () => void;
  collapsed: boolean;
  title?: string;
}

const EditableFormPanelCollapse: React.FC<EditableFormPanelCollapseProps> = ({
  title,
  collapsed,
  onClick,
}) => (
  <StyledEditableFormPanelTitleContainer onClick={onClick} span={24}>
    <StyledEditableFormPanelTitleRow
      align="middle"
      gutter={[10, 0]}
      showUnderline={collapsed}
    >
      <Col>
        <IconButton>{collapsed ? <UpOutlined /> : <DownOutlined />}</IconButton>
      </Col>
      <Col>{title}</Col>
    </StyledEditableFormPanelTitleRow>
  </StyledEditableFormPanelTitleContainer>
);

export default EditableFormPanelCollapse;
