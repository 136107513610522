import { Col } from 'antd';
import React from 'react';
import { CurrencyType } from 'types/Candidates';
import { NodataLabel, RowItemAsset } from './BankItemStyled';
import BankItemAction from './BankItemAction';

interface IProps {
  id: string;
  bankName: string;
  iban: string;
  swift: string;
  bankAddress: string;
  currency: CurrencyType;
}

export function BankItem({
  id,
  bankName,
  iban,
  swift,
  currency,
  bankAddress,
}: IProps) {
  return (
    <RowItemAsset>
      <Col span={4}>
        <NodataLabel>{bankName}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{iban}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{swift}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{currency?.name}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{bankAddress}</NodataLabel>
      </Col>
      <Col span={4}>
        <BankItemAction id={id} />
      </Col>
    </RowItemAsset>
  );
}
