import React from 'react';

export const DashboardIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 13.5996V15.5996M10 9.59961V15.5996M14 5.59961V15.5996M5.8 19.5996H14.2C15.8802 19.5996 16.7202 19.5996 17.362 19.2726C17.9265 18.985 18.3854 18.5261 18.673 17.9616C19 17.3198 19 16.4798 19 14.7996V6.39961C19 4.71945 19 3.87937 18.673 3.23764C18.3854 2.67315 17.9265 2.21421 17.362 1.92659C16.7202 1.59961 15.8802 1.59961 14.2 1.59961H5.8C4.11984 1.59961 3.27976 1.59961 2.63803 1.92659C2.07354 2.21421 1.6146 2.67315 1.32698 3.23764C1 3.87937 1 4.71945 1 6.39961V14.7996C1 16.4798 1 17.3198 1.32698 17.9616C1.6146 18.5261 2.07354 18.985 2.63803 19.2726C3.27976 19.5996 4.11984 19.5996 5.8 19.5996Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
