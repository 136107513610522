import { getPurchaseOrder } from 'api/purchaseOrder';
import PurchaseOrderModalForm from 'components/NewForms/PurchaseOrderForm';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PurchaseOrderType } from 'types/PurchaseOrder';
import Content from 'ui-v2/components/Content';

export default function EditPurchaseOrder() {
  const [purchaseOrderData, setPurchaseOrderData] =
    useState<PurchaseOrderType | null>(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const getPurchaseOrderData = async (id: string) => {
    if (id) {
      getPurchaseOrder(id)
        .then((response) => {
          const { data } = response;
          setPurchaseOrderData(data);
        })
        .catch(() => {
          toast.error(t('errorFetchingOpenPosition'));
        });
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseOrderData(id);
    }
  }, [id]);

  return (
    <Content position="center">
      <PurchaseOrderModalForm selectedPurchaseOrder={purchaseOrderData} />
    </Content>
  );
}
