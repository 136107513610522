import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import { CreateIdeabox } from 'types/Ideabox';
import { apiURLs } from './constants';

export const getSingleIdeabox = (id: string) =>
  agent.get(`${apiURLs.ideabox}/${id}`);

export const getIdeabox = (payload: QueryParamsDTO) =>
  agent.post(`${apiURLs.ideabox}/get`, payload);

export const createIdeabox = (payload: CreateIdeabox) =>
  agent.post(apiURLs.ideabox, payload);

export const removeIdeabox = (id: string) =>
  agent.delete(`${apiURLs.ideabox}/${id}`);

export const updateIdeabox = (id: string, payload: CreateIdeabox) =>
  agent.patch(`${apiURLs.ideabox}/${id}`, payload);
