import React from 'react';
import { AntDesignOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Content from 'ui-v2/components/Content';
import { ChangePassword } from '../../ChangePassword/ChangePassword';

export default function EmployeeProfileSetting() {
  return (
    <Content position="stretch">
      <Row>
        <Col>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<AntDesignOutlined />}
          />
          <ChangePassword
            closeModal={() => {
              console.log();
            }}
          />
        </Col>
      </Row>
    </Content>
  );
}
