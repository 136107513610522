import { MetaPagination } from 'types/GridTable';
import { TrainingType } from 'types/Training';

export enum Trainings {
  FETCH_TRAININGS_START = 'FETCH_TRAININGS_START',
  FETCH_TRAININGS_SUCCESS = 'FETCH_TRAININGS_SUCCESS',
  FETCH_TRAININGS_ERRORED = 'FETCH_TRAININGS_ERRORED',

  FETCH_TRAINING_START = 'FETCH_TRAINING_START',
  FETCH_TRAINING_SUCCESS = 'FETCH_TRAINING_SUCCESS',
  FETCH_TRAINING_ERRORED = 'FETCH_TRAINING_ERRORED',
  CLEAR_SINGLE_TRAINING = 'CLEAR_SINGLE_TRAINING',
}

export interface TrainingsState {
  data: {
    data: Array<TrainingType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
