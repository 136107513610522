import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Id } from 'types/Id';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';

import { DeleteIcon } from 'Icons/DeleteIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import Popconfirm from 'antd/es/popconfirm';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { deleteSkill } from 'api/skillService';
import { fetchSkills } from 'redux/skills/actions';
import { useTranslation } from 'react-i18next';
import { SkillsState } from 'redux/skills/types';
import { RootState } from 'redux/store';
import { PlusCircleIconProp } from 'Icons/PlusIconProps';

const SkillActions: React.FC<Id> = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const skillSelector: SkillsState = useSelector(
    (state: RootState) => state?.skills
  );

  const { meta: pagination } = skillSelector.skillCategories;
  const [searchParams, setSearchParams] = useSearchParams();
  const onEdit = async () => {
    searchParams.append('edit-skill', id);
    setSearchParams(searchParams);
  };
  const { t } = useTranslation();

  const onDeleteClick = async () => {
    const response = await deleteSkill(id);

    if (response.status === 200) {
      toast.success(t('deletedSkill'));
      dispatch(
        fetchSkills({ pageOptions: { page: pagination?.page, take: 10 } })
      );
      return;
    }

    toast.warning(t('couldNotDeleteSkill!'));
  };
  const onViewCategory = () => {
    navigate(`category-details?id=${id}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editSkill')}>
                <StyledIcon onClick={onEdit}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('createSkill')}>
                <StyledIcon onClick={onViewCategory}>
                  <PlusCircleIconProp color="#475467" />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={onDeleteClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default SkillActions;
