import React, { useCallback, useMemo, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CaretDownOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

import {
  SelectLoadPayload,
  ValueType,
} from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import GenericForm from 'components/NewForms/Form';
import { CheckBox } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/ConfigureComponentStyles';
import { useTranslation } from 'react-i18next';
import {
  TrackingFilterPanelLabel,
  TrackingFilterPanelPopoverInnerOverlayStyle,
} from '../utils';
import {
  StyledTrackingFilterPanelBadge,
  StyledTrackingFilterPanelCol,
  StyledTrackingFilterPanelLabel,
} from '../StyledTrackingFilterPanel';

interface TrackingFilterSelectItemProps {
  fieldName: 'projectIds' | 'employeeIds';
  noDataMsg: string;
  urlIds: string[];
  label: TrackingFilterPanelLabel;
  fetchOptions: any;
  borderRight?: boolean;
}

const TrackingFilterSelectItem: React.FC<TrackingFilterSelectItemProps> = ({
  fieldName,
  noDataMsg,
  label,
  urlIds,
  fetchOptions,
  borderRight,
}) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectValues, setSelectValues] = useState<OptionType[]>([]);
  const { setUrlState } = useTrackedHoursData();
  const { t } = useTranslation();
  const onLoaded = (values: ValueType[]) => {
    const options: OptionType[] =
      values?.map((el: ValueType) => ({
        id: el?.value as string,
        label: el?.label as string,
        value: el?.value as string,
      })) || [];
    setSelectValues([...selectValues, ...options]);
  };

  const onFetchOptions = useCallback(
    async (filterText: string, page: number): Promise<SelectLoadPayload> => {
      setLoading(true);
      const response = await fetchOptions(filterText, page);
      onLoaded(response.data);
      setLoading(false);
      return response;
    },
    [urlIds]
  );

  const onSelect = useCallback(
    (option: any) => {
      if (!option?.value) {
        return;
      }
      if (urlIds.includes(option.value as string)) {
        const newIds = urlIds.filter((id) => id !== (option.value as string));
        setUrlState({
          ...(fieldName === 'projectIds' && { projectIds: newIds }),
          ...(fieldName === 'employeeIds' && { employeeIds: newIds }),
        });
      } else {
        setUrlState({
          ...(fieldName === 'projectIds' && {
            projectIds: [...urlIds, option.value],
          }),
          ...(fieldName === 'employeeIds' && {
            employeeIds: [...urlIds, option.value],
          }),
        });
      }
    },
    [urlIds, fieldName]
  );

  const FormConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: `${fieldName}`,
          label: `${t('Find')} ${
            fieldName === 'employeeIds' ? t('employees') : t('projects')
          }...`,

          type: InputTypes.SELECT_LOAD,
          mode: 'multiple',
          defaultValue: urlIds?.length ? urlIds : [],
          hideLabel: true,
          onSelect,
          fetchOptions: onFetchOptions,
        },
      ],
    ],
    [fieldName, urlIds]
  );

  const onChange = useCallback(
    (e: CheckboxChangeEvent, clickedItem: OptionType) => {
      if (e.target.checked) {
        setUrlState({
          ...(fieldName === 'projectIds' && {
            projectIds: [...urlIds, clickedItem.id],
          }),
          ...(fieldName === 'employeeIds' && {
            employeeIds: [...urlIds, clickedItem.id],
          }),
        });
      } else {
        setUrlState({
          ...(fieldName === 'projectIds' && {
            projectIds: urlIds.filter((id) => id !== clickedItem.id),
          }),
          ...(fieldName === 'employeeIds' && {
            employeeIds: urlIds.filter((id) => id !== clickedItem.id),
          }),
        });
      }
    },
    [urlIds, form, fieldName]
  );

  const onSelectAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const options = selectValues?.map((el: ValueType) => el.value as string);
      setUrlState({
        ...(fieldName === 'employeeIds' && {
          employeeIds: [...(options || [])],
        }),
        ...(fieldName === 'projectIds' && {
          projectIds: [...(options || [])],
        }),
      });
    } else {
      setUrlState({
        ...(fieldName === 'employeeIds' && { employeeIds: [] }),
        ...(fieldName === 'projectIds' && { projectIds: [] }),
      });
    }
  };

  const tooglePopoverVisibility = useCallback(
    (open: boolean) => {
      if (!open) {
        const ids = form.getFieldsValue(true)[fieldName];
        if (ids?.length) {
          if (typeof ids.at(0) === 'object') {
            setUrlState({
              ...(fieldName === 'employeeIds' && {
                employeeIds: ids?.map((item: ValueType) => item?.value),
              }),
              ...(fieldName === 'projectIds' && {
                projectIds: ids?.map((item: ValueType) => item?.value),
              }),
            });
          } else {
            setUrlState({
              ...(fieldName === 'employeeIds' && { employeeIds: ids }),
              ...(fieldName === 'projectIds' && { projectIds: ids }),
            });
          }
        } else {
          setUrlState({
            ...(fieldName === 'employeeIds' && { employeeIds: [] }),
            ...(fieldName === 'projectIds' && { projectIds: [] }),
          });
        }
      }
      setVisible(!visible);
    },
    [visible, form, fieldName]
  );

  return (
    <Popover
      overlayInnerStyle={TrackingFilterPanelPopoverInnerOverlayStyle}
      trigger="click"
      placement="bottom"
      popupVisible={visible}
      onVisibleChange={tooglePopoverVisibility}
      content={
        <GenericForm
          form={form}
          loading={loading}
          formConfiguration={FormConfig}
        >
          <Row gutter={[0, 10]}>
            <Col>
              <CheckBox
                checked={urlIds.length === selectValues?.length}
                onChange={onSelectAllChange}
              >
                {t('selectAll')}
              </CheckBox>
            </Col>
            {form.getFieldValue?.length ? (
              selectValues?.map((item) => (
                <Col span={24} key={item?.id}>
                  <CheckBox
                    checked={urlIds.includes(item.id)}
                    onChange={(e) => onChange(e, item)}
                  >
                    {item?.label}
                  </CheckBox>
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Row justify="center">
                  <Col>{noDataMsg}</Col>
                </Row>
              </Col>
            )}
          </Row>
        </GenericForm>
      }
    >
      <StyledTrackingFilterPanelCol borderRight={borderRight}>
        <StyledTrackingFilterPanelBadge count={urlIds.length}>
          <StyledTrackingFilterPanelLabel>
            <Row align="middle" gutter={[5, 0]}>
              <Col>{label}</Col>
              <Col>
                <CaretDownOutlined />
              </Col>
            </Row>
          </StyledTrackingFilterPanelLabel>
        </StyledTrackingFilterPanelBadge>
      </StyledTrackingFilterPanelCol>
    </Popover>
  );
};

export default TrackingFilterSelectItem;
