import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';

import { CertificationsQueryParamsType } from 'types/QueryParams';
import { fetchCertifications } from 'redux/certifications/actions';
import { getAllJobCategoriesWithSubCategories } from 'api/jobCategoryService';
import { getAllSubSkills } from 'components/NewForms/DynamicForm/SkillsMatrix';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';
import i18next from '../../../../i18n';

export default function CertificationsFilter() {
  const [allSubSkills, setAllSubSkills] = useState<any>([]);

  const [form] = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllJobCategoriesWithSubCategories()
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setAllSubSkills(getAllSubSkills(response.data) as any);
        }
      })
      .catch(() => {
        console.log('error');
      });
  }, []);

  async function onFormChange() {
    const certificationSkillsValue: string =
      form.getFieldValue('certificationSkills') ?? '';

    const certificationSkills = allSubSkills.find(
      (skill: any) => skill.id === certificationSkillsValue
    ).subCategoryName;

    const filterCertificateTable: CertificationsQueryParamsType = {
      certificationSkills,
    };

    if (filterCertificateTable) {
      if (certificationSkills) {
        dispatch(fetchCertifications({ certificationSkills }));
      }
    }
  }

  const CertificateFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'certificationSkills',
          label: i18next.t('skills'),
          type: InputTypes.SELECT,
          selectOptions: allSubSkills.map((item: any) => ({
            ...item,
            label: item.subCategoryName,
            value: item.subCategoryName,
          })),
        },
      ],
    ],
    [allSubSkills]
  );
  return (
    <>
      <GenericForm
        formConfiguration={CertificateFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchCertifications({ page: 1, take: 10 }));
            }}
          >
            {i18next.t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
