import React, { useCallback, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import { FormConfigurationType } from 'types/FormTypes';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import { VendorQuestionnaireAsyncAction } from 'redux/vendorQuestionnaire/actions';
import { VendorQuestionnaireApiClient } from 'api/vendorQuestionnaireService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { getVendorQuestionnaireCategoryFormConfig } from './utils';
import GenericForm from '../Form/GenericForm';
import { StyledButton } from '../FormStyled';

interface VendorQuestionnaireFormProps {
  open: boolean;
  closeModal: () => void;
  id?: string;
}

const VendorQuestionnaireForm: React.FC<VendorQuestionnaireFormProps> = ({
  open,
  closeModal,
  id,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const {
    questionnaireCategory: { selectedQuestionnaireCategory },
  } = useVendorQuestionnaireData();
  const [loading, setLoading] = useState<boolean>(false);

  const onFormFinish = useCallback(async () => {
    setLoading(true);
    if (selectedQuestionnaireCategory?.id) {
      const payload: VendorQuestionnaireApiClient.CategoryUpdateRequest = {
        id: selectedQuestionnaireCategory?.id,
        payload: form.getFieldsValue(),
      };
      VendorQuestionnaireApiClient.updateCategorie(payload)
        .then((res) => {
          if (res.status === 200) {
            if (id) {
              dispatch(
                VendorQuestionnaireAsyncAction.fetchCategorie({
                  id,
                })
              );
            } else {
              dispatch(VendorQuestionnaireAsyncAction.fetchCategories());
            }
            toast.success(t('vendorQuestionnaireCategoryUpdatedSuccessfully'));
            closeModal();
          }
        })
        .catch((e) => toastErrorMessages(e))
        .finally(() => setLoading(false));
    } else {
      const payload: VendorQuestionnaireApiClient.CategoryCreateRequest = {
        payload: form.getFieldsValue(),
      };
      VendorQuestionnaireApiClient.createCategorie(payload)
        .then((res) => {
          if (res.status === 201) {
            dispatch(VendorQuestionnaireAsyncAction.fetchCategories());
            toast.success(t('vendorQuestionnaireCategoryAddedSuccessfully'));
            closeModal();
          }
        })
        .catch((e) => toastErrorMessages(e))
        .finally(() => setLoading(false));
      closeModal();
    }
  }, [selectedQuestionnaireCategory, form, id]);

  const VendorCategoryFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      ...getVendorQuestionnaireCategoryFormConfig({
        selectedQuestionnaireCategory,
      }),
    ],
    [selectedQuestionnaireCategory]
  );

  return (
    <GenericModal
      title={
        selectedQuestionnaireCategory?.id
          ? t('editVendorQuestionnaire')
          : t('addVendorQuestionnaire')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={VendorCategoryFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={loading}
      >
        <Row gutter={[0, 0]}>
          <Col span={24} />
          <Col span={24}>
            <Row gutter={[0, 30]}>
              <Col span={24}>
                <Row>
                  <Col span={11}>
                    <StyledButton onClick={closeModal} htmlType="reset" danger>
                      {t('cancel')}
                    </StyledButton>
                  </Col>
                  <Col span={11} offset={2}>
                    <StyledButton
                      onClick={() => form.submit()}
                      type="primary"
                      htmlType="submit"
                    >
                      {t('submit')}
                    </StyledButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </GenericForm>
    </GenericModal>
  );
};

export default VendorQuestionnaireForm;
