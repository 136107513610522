import { Col } from 'antd';
import React from 'react';
import { NodataLabel, RowItemAsset } from './QuestionniareFeedbackItemStyled';
import BankItemAction from './QuestionnaireFeedbackItemAction';

interface IProps {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
}

export function QuestionnaireFeedbackItem({
  id,
  firstName,
  lastName,
  createdAt,
}: IProps) {
  return (
    <RowItemAsset>
      <Col span={4}>
        <NodataLabel>{firstName}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{lastName}</NodataLabel>
      </Col>
      <Col span={4}>
        <NodataLabel>{createdAt}</NodataLabel>
      </Col>
      <Col span={4}>
        <BankItemAction id={id} />
      </Col>
    </RowItemAsset>
  );
}
