import React from 'react';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import * as Styled from 'ui-v2/components/DataGridComponents';
import QuestionnaireActions from 'components/Actions/QuestionnaireActions';
import { QuestionnairesType } from 'types/Questionnaires';
import i18next from '../i18n';

export const QuestionnairesColumns = [
  {
    title: i18next.t('duration'),
    dataIndex: 'startDate',
    key: 'startDate',
    render: (_: string, item: QuestionnairesType) => (
      <Styled.Text>
        {`${convertUTCtoFormatedLocalTime(
          item?.startDate
        )} - ${convertUTCtoFormatedLocalTime(item?.endDate)}
        `}
      </Styled.Text>
    ),
    default: false,
  },
  {
    title: i18next.t('department'),
    dataIndex: 'department',
    key: 'department',
    render: (_: string, item: QuestionnairesType) => (
      <>{`${item?.department?.map((dep) => dep?.name)}`}</>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('avgEmployeesScore'),
    dataIndex: 'avgScore',
    key: 'avgScore',
    default: true,
    order: 2,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (_: string, row: QuestionnairesType) => (
      <QuestionnaireActions id={row?.id} />
    ),
    default: false,
    order: 3,
    fixed: 'right',
  },
];
