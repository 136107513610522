import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const NoteItem = styled(Row)`
  width: 100%;
  padding-top: 0px;
`;
export const IconSpace = styled.span`
  margin-left: -10px;
`;
export const Item = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;
export const NoteName = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 400;
`;
export const Label = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
  margin-top: -15px;
`;
