import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { useVendorsData } from 'ui-v2/hooks/useVendorsData';
import { VendorType } from 'types/Vendor';
import { createVendor, updateVendor } from 'api/vendorService';
import { ScheduleInterviewModalIcon } from 'Icons/ScheduleInterviewModalIcon';
import { fetchVendors } from 'redux/vendor/action';
import { InputTypes } from 'types/FormTypes';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import useHeader from 'ui-v2/hooks/useHeader';
import {
  phoneNumberValidator,
  prefixSelector,
} from '../EmployeeForm/components/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedVendor?: any;
}
export default function AddVendorForm({
  open,
  closeModal,
  selectedVendor,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { vendor } = useVendorsData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { take } = useHeader();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { optionsType: vendorCategory } = useGetSelectOptions({
    type: 'vendor-category',
    transform: (item) => ({
      id: item.id,
      value: item.categoryName,
      label: item.categoryName,
    }),
  });

  function onFormFinish(values: VendorType) {
    const valuesToSend: VendorType = {
      ...values,
      companyName: values.companyName,
      vendorCategories: values.vendorCategories,
    };
    setLoading(true);

    if (vendor?.id) {
      updateVendor(vendor?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('vendorUpdatedSuccessfully'));
            dispatch(fetchVendors({ page: currentPage, take }));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createVendor(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('vendorCreatedSuccessfully'));
            dispatch(fetchVendors({ page: currentPage, take }));
            closeModal();
          }
        })
        .catch(() => {
          toast.error(t('failedToCreateVendor'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  useEffect(
    () => () => {
      closeModal();
    },
    [searchParams]
  );

  useEffect(() => {
    if (searchParams.get('add-vendor') === 'true') {
      searchParams.delete('add-vendor');
      setSearchParams(searchParams);
      form.submit();
    }

    if (searchParams.get('edit-vendor') === 'true') {
      searchParams.delete('edit-vendor');
      setSearchParams(searchParams);
      form.submit();
    }

    setCurrentPage(
      searchParams.get('page')
        ? parseInt(searchParams.get('page') as string, 10)
        : 1
    );
  }, [searchParams, form, setSearchParams, vendor]);
  const VendorFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'taxId',
          label: t('taxId'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.taxId || selectedVendor?.taxId,
          required: true,
          rules: [
            {
              required: true,
              message: t('taxIdIsRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'companyName',
          label: t('companyName'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.companyName,
          rules: [
            {
              required: true,
              message: t('companyNameIsRequired!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'country',
          label: t('country'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.country,
          rules: [
            {
              required: true,
              message: t('countryIsRequired!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'city',
          label: t('city'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.city,
          rules: [
            {
              required: true,
              message: t('cityIsRequired!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'street',
          label: t('street'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.street,
          rules: [
            {
              required: true,
              message: t('streetIsRequired!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'zipCode',
          label: t('zipCode'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.zipCode,
          rules: [
            {
              required: true,
              message: t('zipCodeIsRequired!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'state',
          label: t('state'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.state,
          rules: [
            {
              required: true,
              message: t('stateIsRequired!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'contactPerson',
          label: t('contactPerson'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: vendor?.contactPerson,
          rules: [
            {
              required: true,
              message: t('contactPersonIsRequired!'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'contactPersonPrefix',
            selectOptions: prefixSelector,
            defaultValue: vendor?.contactPersonPrefix || prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: InputTypes.INPUT,
          defaultValue: vendor?.email,
          rules: [
            {
              required: true,
              message: t('requiredEmail'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: vendor?.phoneNumber,
          rules: [
            {
              required: true,
              message: t('Phone number is required!'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: vendor?.phoneNumberPrefix || prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'vendorCategories',
          label: t('vendorCategory'),
          type: InputTypes.SELECT,
          defaultValue:
            vendor?.vendorCategories?.map((item: VendorType) => item.id) || [],
          isMultiSelect: true,
          selectOptions: vendorCategory,
          showSearch: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [vendor, vendorCategory, selectedVendor]
  );

  return (
    <GenericModal
      title={vendor?.id ? t('editVendor') : t('addVendor')}
      open={open}
      closeModal={closeModal}
      icon={<ScheduleInterviewModalIcon />}
    >
      <GenericForm
        formConfiguration={VendorFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
