import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  clearInterviews,
  clearScheduleInterview,
  clearSingleCandidate,
} from 'redux/candidates/actions';
import { RootState } from 'redux/store';
import { clearUserData } from 'redux/users/actions';
import routesConfig, { ConfigItem } from 'ui-v2/components/MainLayout/config';
import { getTblConfig } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/helpers';

export default function useHeader() {
  const { pathname } = useLocation();
  const [ID, setID] = useState();
  const subRoutes = pathname?.split('/').filter((i) => i !== '');
  const entity = subRoutes?.at(0) || '';
  const currentRoute = subRoutes.reverse()[0];
  const routeConfig = routesConfig[entity as ConfigItem];
  const { config, take }: any = useSelector(
    (state: RootState) => state.tableConfigReducer
  );

  const visibleHeaderRoutes = routeConfig?.visibleHeaderRoutes || [];
  const hideDetailsRoutes = routeConfig?.hideDetailsRoutes || [];
  const dispatch = useDispatch();

  const hideDetails = hideDetailsRoutes.find((item) => item === currentRoute);

  const visibleHeader = visibleHeaderRoutes.find(
    (item) => item === currentRoute
  );

  useEffect(() => {
    const ID: any = new URLSearchParams(window.location?.search).get('id');
    setID(ID);

    if (currentRoute === 'candidates') {
      dispatch(clearSingleCandidate());
      dispatch(clearScheduleInterview());
      dispatch(clearInterviews());
    }

    if (currentRoute === 'users') {
      dispatch(clearUserData());
    }
  }, [currentRoute]);

  useEffect(() => {
    const title = (entity.charAt(0).toUpperCase() + entity.slice(1)).replaceAll(
      '-',
      ' '
    );
    document.title = `Dextrio - ${title}`;
  }, [currentRoute, entity]);

  return {
    entity,
    currentRoute,
    visibleHeader,
    hideDetails,
    ID,
    columns: config[entity] ?? getTblConfig(entity),
    take,
  };
}
