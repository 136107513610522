import React from 'react';

import TrackingHoursActions from 'components/Actions/TrackingHoursActions';
import { TrackingHoursDetailedTableUtils } from './utils';
import i18next from '../../i18n';

type ColumnType = TrackingHoursDetailedTableUtils.TrackingHoursColumnsType;

export const TrackingHoursColumns: ColumnType[] = [
  {
    title: i18next.t('descriptionProject'),
    dataIndex: 'assignment',
    key: 'assignment',
    sorter: TrackingHoursDetailedTableUtils.SortTimeEntryData,
    render: TrackingHoursDetailedTableUtils.TimeEntryCellData,
    ellipsis: true,
    default: true,
    width: 300,
  },
  {
    title: i18next.t('employee'),
    dataIndex: 'employee',
    key: 'employee',
    sorter: TrackingHoursDetailedTableUtils.SortEmployeeData,
    render: TrackingHoursDetailedTableUtils.EmployeeCellData,
    default: true,
  },
  {
    title: i18next.t('date'),
    dataIndex: 'date',
    key: 'date',
    render: TrackingHoursDetailedTableUtils.DateCellData,
    default: true,
  },
  {
    title: i18next.t('duration'),
    dataIndex: 'duration',
    key: 'duration',
    render: TrackingHoursDetailedTableUtils.DurationCellData,
    default: true,
  },
  {
    title: i18next.t('type'),
    dataIndex: 'hourType',
    key: 'hourType',
    render: TrackingHoursDetailedTableUtils.HourTypeCellData,
    default: true,
  },
  {
    width: 120,
    render: (_, row) => <TrackingHoursActions id={row?.id} />,
    default: false,
  },
];
