import React, { useState } from 'react';
import { Col, Row, Skeleton, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { EmergencyContactDto } from 'types/EmergencyContact';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { deleteEmergencyContact } from 'api/employeeEmergencyContactsService';
import { useTranslation } from 'react-i18next';
import CardItem from '../Card';
import * as Styled from '../Task/TaskStyles';
import AddEmergencyContactModal from './AddEmergencyContact';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import { EmergencyContactItem } from './EmergencyContactItem';

interface IProps {
  emergencyContact: EmergencyContactDto[];
  employeeId: string;
  loading: boolean;
}

export default function EmergencyContact({
  emergencyContact,
  employeeId,
  loading,
}: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [editContactId, setEditContactId] = useState('');
  const onToggleModal = () => setModalVisible((state) => !state);
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const ondeleteEmergencyContact = async (emergencyContactId: string) => {
    try {
      await deleteEmergencyContact(emergencyContactId);
      toast.success(t('emergencyContactDeleted'));
      dispatch(fetchEmployeesDetail(employeeId));
      setEditContactId('');
    } catch (error) {
      toast.error(t('errorDeletingEmergencyContact'));
    }
  };

  const onEditClose = () => {
    setEditContactId('');
  };

  return (
    <CardItem title={t('emergencyContact')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Row gutter={[10, 24]} justify="center">
          {emergencyContact.map((item: EmergencyContactDto, index: number) => (
            <EmergencyContactItem
              key={item.id}
              item={item}
              onDelete={() => ondeleteEmergencyContact(item.id)}
              isLast={index === emergencyContact.length - 1}
              onItemClick={setEditContactId}
            />
          ))}
          {!emergencyContact.length && (
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col>
                <Styled.Label>
                  {authUserRole === AUTH_ROLES.EMPLOYEE ? (
                    <Styled.Label>
                      {t('youDontHaveAnyEmergencyContact')}
                    </Styled.Label>
                  ) : (
                    <Styled.Label>
                      {t('addacontacttothisEmployee')}
                    </Styled.Label>
                  )}
                </Styled.Label>
              </Col>
            </RoleBasedButton>
          )}
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <Col span={24}>
              <Row justify="center">
                <Button
                  icon={
                    <Styled.AddSpaceIcon>
                      <PlusCircleOutlined />
                    </Styled.AddSpaceIcon>
                  }
                  size="large"
                  style={{
                    borderRadius: '8px',
                    width: '100%',
                    fontWeight: '600',
                    fontSize: '16px',
                    color: '344054',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={onToggleModal}
                >
                  {t('addcontact')}
                </Button>
              </Row>
            </Col>
          </RoleBasedButton>
        </Row>
        {modalVisible && (
          <AddEmergencyContactModal
            open={modalVisible}
            closeModal={() => setModalVisible(false)}
            employeeId={employeeId}
            id=""
          />
        )}
        {editContactId && (
          <AddEmergencyContactModal
            id={editContactId}
            open
            closeModal={onEditClose}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
