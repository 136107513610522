import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNotifications,
  pushNotification,
} from 'redux/notifications/actions';
import { notificationListener } from 'api/notificationService';
import { destructNotification } from 'ui-v2/components/Notifications/NotificationsModal/utils';
import { useCallback } from 'react';
import { IS_LOGGEDIN } from 'utils/constants';

export function useNotificationsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.notifications
  );
  const dispatch = useDispatch();

  const isUserLogIn = localStorage.getItem(IS_LOGGEDIN);

  const addNotificationListener = useCallback(async () => {
    if (!isUserLogIn) {
      return;
    }

    notificationListener()
      .then(async (response) => {
        if (response.body) {
          const reader = response.body.getReader();
          const readChunk = () => {
            reader
              .read()
              .then(({ value, done }) => {
                if (done) {
                  return;
                }
                destructNotification(value).then((notification) => {
                  if (notification) {
                    dispatch(pushNotification(notification));
                  }
                });

                readChunk();
              })
              .catch((error) => {
                console.error(error);
              });
          };
          readChunk();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isUserLogIn]);

  const initNotifications = () => {
    dispatch(fetchNotifications());
    addNotificationListener();
  };

  return {
    data,
    loading,
    loaded,
    errored,
    error,
    initNotifications,
  };
}
