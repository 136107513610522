import React from 'react';

export const CertificationsIcon = () => (
  <svg
    width="20"
    height="20.19"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 19.7998H3C1.89543 19.7998 1 18.9044 1 17.7998V3.7998C1 2.69524 1.89543 1.7998 3 1.7998H17C18.1046 1.7998 19 2.69524 19 3.7998V17.7998C19 18.9044 18.1046 19.7998 17 19.7998H15.5M10 18.7998C11.6569 18.7998 13 17.4567 13 15.7998C13 14.143 11.6569 12.7998 10 12.7998C8.34315 12.7998 7 14.143 7 15.7998C7 17.4567 8.34315 18.7998 10 18.7998ZM10 18.7998L10.0214 18.7996L6.82867 21.9924L4.00024 19.1639L7.01965 16.1445M10 18.7998L13.1928 21.9924L16.0212 19.1639L13.0018 16.1445M7 5.7998H13M5 9.2998H15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
