import { MetaPagination } from 'types/GridTable';

export enum PurchaseRequest {
  FETCH_PURCHASE_REQUESTS_START = 'FETCH_PURCHASE_REQUESTS_START',
  FETCH_PURCHASE_REQUESTS_SUCCESS = 'FETCH_PURCHASE_REQUESS_SUCCESS',
  FETCH_PURCHASE_REQUESTS_ERRORED = 'FETCH_PURCHASE_REQUESTS_ERRORED',

  FETCH_PURCHASE_REQUEST_START = 'FETCH_PURCHASE_REQUEST_START',
  FETCH_PURCHASE_REQUEST_SUCCESS = 'FETCH_PURCHASE_REQUEST_SUCCESS',
  FETCH_PURCHASE_REQUEST_ERRORED = 'FETCH_PURCHASE_REQUEST_ERRORED',

  CLEAR_PURCHASE_REQUESTS_STATE = 'CLEAR_PURCHASE_REQUESTS_STATE',
  CLEAR_WPURCHASE_REQUEST_STATE = 'CLEAR_PURCHASE_REQUEST_STATE',
}

export interface PurchaseRequestsState {
  data: {
    data: Array<any>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
export interface PurchaseRequestState {
  data: any;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
