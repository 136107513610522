import { AssetType } from './Asset';
import { AssigmentType } from './Assigment';
import { CurrencyType } from './Candidates';
import { EmergencyContactType } from './EmergencyContact';
import { EmployeeEmploymentTypeModel } from './EmployeeEmploymentType';
import { EmployeeNotesType } from './EmployeeNotes';
import { EmployeeTaskModelType } from './EmployeeTask';
import { EmploymentType } from './EmploymentType';
import { EvaluationItem } from './Evaluation';
import { IResume } from './IResume';
import { ProjectType } from './Project';
import { SkillSubCategoryInputType } from './Skills';
import { TeanantLocation } from './TenantLocation';
import { TrainingType } from './Training';
import {
  WorkPositionListModelType,
  WorkPositiontModelType,
} from './WorkPosition';

export enum EmployeeTrainingStatus {
  STARTED = 'started',
  ATTENDING = 'attending',
  COMPLETED = 'completed',
}
export interface EmployeeSalaryType {
  id: string;
  name: string;
  symbol: string;
}

export enum PayType {
  MONTHLY = 'Monthly',
  COMMISION = 'Commision',
  COMMISION_BASE = 'Commision + Base',
  HOURLY = 'Hourly',
}

export interface IUpdateEmployeeSkillsDTO {
  employeeId: string;
  skillSubCategories: SkillSubCategoryInputType[];
}

export interface EmployeeTrainingType {
  id: string;
  training: TrainingType;
  notes: string;
  startDate: Date;
  endDate: Date;
  trainingStatus: EmployeeTrainingStatus;
  firstName?: string;
  lastName?: string;
  workEmail?: string;
}
export interface IFileDocuments {
  fileName: string;
  content: string;
}

export interface IEmployeeDocumentDTO {
  name: IFileDocuments;
  type: string;
  description: string;
  employeeId: string;
  companyId: string;
}

export interface EmployeeProjectsType {
  id: string;
  name: string;
  description: string;
  documents: string;
  startDate: Date;
  endDate: Date;
  noEndPeriod: boolean;
}

export interface SkillsSubCategory {
  id: string;
  subCategoryName: string;
}

export interface SkillSubCategoriesEmployees {
  yearsOfExperience: string;
  skillsSubCategory: SkillsSubCategory;
}

export interface EmployeeType {
  candidateId: string;
  value: EmployeeType;
  id: string;
  ssn: string;
  martialStatus: string;
  firstName: string;
  lastName: string;
  salaryNeto: number;
  email: string;
  maxWorkHoursPerMonth: number;
  workEmail: string;
  birthDate: string;
  hireDate?: Date;
  phoneNumber: string;
  phoneNumberPrefix: string;
  resume: string;
  evaluations: EvaluationItem[];
  tenantLocation?: TeanantLocation;
  onBoardingStartDate: Date;
  onBoardingEndDate: Date;
  onBoardingStatus: string;
  salary: number;
  salaryEmployee: EmployeeSalaryType;
  currency: CurrencyType;
  workPosition: WorkPositiontModelType;
  experience: any[];
  employeeAssets: AssetType[];
  trainings: EmployeeTrainingType;
  emergencyContact: EmergencyContactType[];
  skillSubCategoriesEmployees?: any[];
  employeeTraining: EmployeeTrainingType;
  employeeTask: EmployeeTaskModelType[];
  linkedIn?: string;
  gender?: string;
  isFirstLogin?: boolean;
  hourlyRate?: number;
  payType?: PayType;
  employeeEmploymentType?: EmployeeEmploymentTypeModel[];
  projects: ProjectType[];
  employeeIdentifier?: string;
  employeeNotes?: EmployeeNotesType[];
}

export interface EmployeesSkim {
  id: string;
  firstName: string;
  lastName: string;
}

export interface EmployeeUpdateDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  ssn?: string;
  phoneNumber?: string;
  birthDate?: Date;
  resume?: IResume;
  workPositionId?: string;
  hireDate?: Date;
  workEmail?: string;
  onBoardingStartDate?: Date;
  onBoardingEndDate?: Date;
  onBoardingStatus?: string;
  gender?: string;
  linkedIn?: string;
  employmentTypeId?: string;
}

export interface EmployeeWelcomeScreen {
  firstName: string;
  lastName: string;
  hireDate: Date | string;
  location: string;
  city: string;
  expectations: string;
  responsibilities: string;
  videoUrl: string;
  missionVision: string;
  projects: ProjectType[];
}

export interface MissionData {
  key: number;
  defaultValue: string;
  description: string;
}
export interface EmployeeCretateDto {
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  phoneNumber: string;
  employeeIdentifier: string;
  birthDate: Date;
  certifications?: string[];
  newCertificatios?: string[];
  resume: IResume;
  hireDate: Date;
  workEmail: string;
  onBoardingStartDate: Date;
  onBoardingEndDate: Date;
  onBoardingStatus: string;
  workPositionId: string;
  companyId: string;
  gender?: string;
  linkedIn?: string;
}

export interface EmployeeInput extends EmployeeCretateDto {
  employmentTypeId: string;
  startDate: Date;
  endDate: Date;
}

export interface EmployeeUpdateForm extends EmployeeCretateDto {
  onBoardingDateRange?: [];
}

export interface EmployeeInputType {
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  phoneNumber: string;
  birthDate: Date;
  certifications?: string[];
  newCertificatios?: string[];
  resume: IResume;
  hireDate: Date;
  workEmail: string;
  EmergencyContact: string;
  onBoardingStartDate: Date;
  onBoardingEndDate: Date;
  onBoardingStatus: string;
  workPositionId?: string;
  gender?: string;
}

export interface EmployeeAssetsDTO {
  employeeId: string;
  assetId: string;
  startDate: Date;
  endDate: Date;
  notes: string;
}

export interface RemoveEmployeeAsset {
  assetId: string;
  employeeId: string;
}

export interface EmployeeTrainingsDTO {
  employeeId: string;
  trainingId: string;
  startDate: Date;
  endDate: Date;
  notes: string;
  trainingStatus: EmployeeTrainingStatus;
}

export interface EmployeeTrainings {
  employeeId: string;
  trainingId: string;
  startDate: Date;
  endDate: Date;
  notes: string;
}

export interface EmployeeAssignmnetsType {
  id: string;
  ssn: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthDate: string;
  resume: string;
  linkedIn: string;
  hireDate: Date;
  workEmail: string;
  onBoardingStartDate: Date;
  onBoardingEndDate: Date;
  onBoardingStatus: Date;
  projects: ProjectType[];
  assignments: AssigmentType[];
  candidateId: string;
  workPosition: WorkPositionListModelType;
  salaryEmployee: EmployeeSalaryType;
  gender: string;
}

export enum EmployeeFormType {
  'FROM_SCRATCH' = 'FROM_SCRATCH',
  'SELECT_LOAD' = 'SELECT_LOAD',
  'EDIT' = 'EDIT',
}

export interface ChangePassword {
  id: string;
  password: string;
}

export interface EmployeeTypeCreate {
  birthDate: string;
  candidateOpenPositions: Array<{ id: string }>;
  certificationsCandidates: Array<any>;
  currency: string;
  currentJobTitle: string;
  email: string;
  employed: boolean;
  martialStatus: string;
  employmentTypeId: string;
  endDate: string;
  expectedSalary: number;
  experience: Array<string>;
  payType: string;
  firstName: string;
  gender: string;
  hireDate: string;
  lastName: string;
  linkedIn?: string;
  currencyId?: any;
  notes: string;
  onBoardingEndDate: string;
  onBoardingStartDate: string;
  onBoardingStatus: string;
  phoneNumber: string;
  phoneNumberPrefix?: string;
  referalType: string;
  referedBy: null;
  resume?: IResume;
  salary: number;
  sendingAccountDetails: string;
  skillSubCategoriesCandidates: Array<{ id: string }>;
  ssn: string;
  startDate: string;
  workEmail: string;
  workPositionId: string;
  candidate?: any;
  switched?: any;
}

export interface EmployeeResumesDTO {
  employeeIds: string[];
}

export interface EmployeeResumeType {
  name: string;
  data: {
    content: string;
    name: string;
  };
}

export interface EmployeeOnBoardingMission {
  missionVision: string;
  videoUrl: string;
}

export enum MartialStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
  WIDOWED = 'Widowed',
  DIVORCED = 'Divorced',
  SEPARATED = 'Separated',
  REGISTRED_PARTNERSHIP = 'Registered Partnership',
}

export interface EmployeesBulkUploadDetails {
  workPositions: WorkPositionListModelType[];
  currencies: CurrencyType[];
  employmentTypes: EmploymentType[];
}
