import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

export const EmployeeEvaluationContainer = styled.div<{
  last?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  border-bottom: ${(props) => (props.last ? '' : '1px solid #eaecf0')};
`;

export const EmployeeEvaluationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EmmployeeEvaluationItemDescription = styled(Typography.Text)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
  margin-bottom: 0.625rem;

  font-weight: 400;
`;

export const FeedbackText = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 400 !important;
`;
export const EmployeeName = styled(Typography)`
  font-weight: 600;
  text-transform: capitalize;
`;
export const ColItem = styled(Col)<{ isLast?: boolean }>`
  border-bottom: 1px solid #eaecf0;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;
export const Evaluation = styled(Row)<{ isLast: boolean }>`
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    button {
      background: #fafafa;
    }
  }
`;
