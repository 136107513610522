import agent from 'api/agent';
import {
  ForgotPassword,
  ResendVerificationEmail,
  SetPassword,
} from 'redux/users/types';
import {
  ACCESS_TOKEN,
  EMPLOYEE_ID,
  IS_LOGGEDIN,
  TENANT_ID,
  USER,
  USER_ROLE,
} from 'utils/constants';
import { AuthUserCredentials } from 'redux/authUser/types';
import { apiURLs } from './constants';

export const register = async (values: any) => agent.post('register', values);

export const authenticateTenantUser = async (
  twoFactorAuthenticationCode: string
) =>
  agent.post(`${apiURLs.tenantedTwoFactorAuthentication}/authenticate`, {
    twoFactorAuthenticationCode,
  });

export const login = async (values: AuthUserCredentials) =>
  agent.post(apiURLs.authLogin, values);

export const getTenantUserQRCode = async () =>
  agent.get(`${apiURLs.tenantedTwoFactorAuthentication}/generate`);

export const getAuthUser = async () => agent.get(apiURLs.authMe);

export const getPublicSuperAdmin = async () =>
  agent.post(`${apiURLs.publicSuperAdmin}/me`);

export const getResendLinkToSetPassword = async () => agent.get(apiURLs.users);

export const getResendLinkToSetPasswordWithEmail = async (
  values: ResendVerificationEmail
) => agent.post(`${apiURLs.users}/resend-verification-email`, values);

export const setPassword = async (values: SetPassword) =>
  agent.post(`${apiURLs.users}/set-password`, values);

export const forgotPassword = async (values: ForgotPassword) =>
  agent.post('/open-routes/forgot-password', values);

export const setPasswordBoolean = async (userId: string) =>
  agent.patch(`${apiURLs.users}/setPasswordBoolean/${userId}`);

export const clearLocalStorage = async () => {
  localStorage.removeItem(USER);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(IS_LOGGEDIN);
  localStorage.removeItem(TENANT_ID);
  localStorage.removeItem(EMPLOYEE_ID);
  localStorage.removeItem(USER_ROLE);
};

export const logOutAndRedirect = async (toSuperAdminLogin?: boolean) => {
  await clearLocalStorage();
  window.location.replace(toSuperAdminLogin ? '/login/super-admin' : '/login');
};
