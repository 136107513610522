import { Dispatch } from 'redux';
import {
  getQuestionnaires,
  getSingleQuestionnaire,
} from 'api/questionnairesService';
import { QueryParamsDTO } from 'types/QueryParams';
import { QuestionnairesActions } from './types';

export const fetchQuestionnaires =
  (queryParams: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: QuestionnairesActions.FETCH_QUESTIONNAIRES_START,
    });
    getQuestionnaires(queryParams)
      .then((res) => {
        dispatch({
          type: QuestionnairesActions.FETCH_QUESTIONNAIRES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: QuestionnairesActions.FETCH_QUESTIONNAIRES_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleQuestionnaire =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: QuestionnairesActions.FETCH_SINGLE_QUESTIONNAIRE_START,
    });
    getSingleQuestionnaire(id)
      .then((res) => {
        dispatch({
          type: QuestionnairesActions.FETCH_SINGLE_QUESTIONNAIRE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: QuestionnairesActions.FETCH_SINGLE_QUESTIONNAIRE_ERRORED,
          payload: e,
        });
      });
  };

export const clearFetchQuestionnaires = () => (dispatch: Dispatch) => {
  dispatch({
    type: QuestionnairesActions.CLEAR_ALL_QUESTIONNAIRES,
  });
};

export const clearFetchSingleReivew = () => (dispatch: Dispatch) => {
  dispatch({
    type: QuestionnairesActions.CLEAR_SINGLE_QUESTIONNAIRE,
  });
};
