import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { EnvironmentOutlined, FieldTimeOutlined } from '@ant-design/icons';

import { OpenPositionType } from 'interfaces/OpenPosition';
import { TenantDataType } from 'types/Tenant';
import { useSearchParams } from 'react-router-dom';
import { OpenPositionName } from 'pages/GuestPages/GuestPagesStyles';
import * as Styled from '../../OpenPositionCardStyle';

const { Text } = Typography;
interface CompanyProps {
  openPosition: OpenPositionType | undefined;
  tenant: TenantDataType | undefined;
  onToogleView: () => void;
}

const GuestOpenPositionItem = ({
  openPosition,
  tenant,
  onToogleView,
}: CompanyProps) => {
  if (!openPosition || !tenant) return null;

  const { id } = openPosition;
  const [searchParams, setSearchParams] = useSearchParams();

  const onClick = () => {
    searchParams.set('openPositionId', id);
    setSearchParams(searchParams);
    onToogleView();
  };

  return (
    <Col sm={24} md={12} lg={12} xl={12} xxl={12} id={id}>
      <Styled.Container onClick={onClick}>
        <Row>
          <Col span={24}>
            <OpenPositionName>{openPosition?.name}</OpenPositionName>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 0]}>
              <Col>
                <Space>
                  <EnvironmentOutlined />
                  <Text>{tenant?.city}</Text>
                </Space>
              </Col>
              <Col>
                <Space>
                  <FieldTimeOutlined />
                  <Text>{tenant?.address}</Text>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Styled.Container>
    </Col>
  );
};
export default GuestOpenPositionItem;
