import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  clearSingleCertification,
  fetchCertifications,
  fetchSingleCertification,
} from 'redux/certifications/actions';
import { CertificationType } from 'types/Certification';
import useHeader from './useHeader';

export function useCertificationsData() {
  const { take } = useHeader();
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.certifications
  );

  const singleCertification = useSelector(
    (state: any) => state.singleCertification
  );

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { entity } = useHeader();
  const dispatch = useDispatch();

  if (entity === 'certifications' && !loaded && !loading) {
    dispatch(fetchCertifications({ page: 1, take }));
  }

  const certificationId = searchParams.get('id');

  if (
    entity === 'certifications' &&
    certificationId &&
    !singleCertification.loaded &&
    !singleCertification.loading
  ) {
    dispatch(fetchSingleCertification(certificationId));
  }

  useEffect(() => {
    if (
      pathname === '/certifications' ||
      (certificationId && certificationId !== singleCertification?.data?.id)
    ) {
      dispatch(clearSingleCertification());
    }
  }, [pathname]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    certification:
      singleCertification.data ||
      data?.data?.find(
        (item: CertificationType) =>
          item?.id === searchParams.get('edit-certification')
      ),
    loading,
    loaded,
    errored,
    error,
  };
}
