import React from 'react';
import Content from 'ui-v2/components/Content';

export default function ArchivedCandidates() {
  return (
    <Content position="stretch">
      <p>Archived</p>
    </Content>
  );
}
