import { Typography } from 'antd';
import styled from 'styled-components';

export const OfferLabel = styled(Typography.Text)<{
  isValid: boolean;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #101828;
  ${({ isValid }) => !isValid && 'color: #B42318;opacity: 0.7;'};
`;
