import type { ColumnsType } from 'antd/es/table';

export enum TableConfig {
  SET_TABLE_CONFIG = 'SET_TABLE_CONFIG',
  CLEAR_TABLE_CONFIG = 'CLEAR_TABLE_CONFIG',
}

export interface TableConfigState {
  config: {
    [x: string]: Array<ColumnsType>;
  };
  take: number;
}
