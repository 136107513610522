import React from 'react';
import { Moment } from 'moment';
import { InputType, InputTypes, PrefixType, RulesType } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { Typography, type SwitchProps, type UploadProps } from 'antd';
import { CSSProperties } from 'styled-components';
import DatepickerComponent from '../Datepicker/Datepicker';
import InputComponent from '../Input/Input';
import PrefixSelectComponent from '../PrefixSelect';
import SelectComponent from '../Select';
import SwitchComponent from '../Switch/Switch';
import UploadComponent from '../Upload';
import DateRange from '../DateRange';
import ButtonComponent from '../Button';
import TextAreaComponent from '../TextArea';
import CheckboxComponent from '../Checkbox';
import RateComponent from '../RateComponent';
import TimePickerComponent from '../TimePicker/TimePicker';
import SelectLoad from '../SelectWithLoad/SelectWithLoad';
import PrefixReferal from '../PrefixReferal/PrefixReferal';
import CheckboxGroup from '../CheckboxGroup';
// import PasswordInputComponent from '../PasswordInput';

interface Props {
  inputType: InputType;
  placeholder: string;
  checkboxOptions?: Array<OptionType>;
  checkedOptions?: Array<OptionType>;
  selectOptions?: OptionType[];
  prefix?: PrefixType;
  name: string;
  defaultValue?: string;
  onClick?: any;
  buttonType?: string;
  rules?: RulesType[];
  isMultiSelect?: boolean;
  disabled?: boolean;
  birthday?: boolean;
  uploadProps?: UploadProps;
  inputProps?: {
    type: 'number' | 'email' | 'text' | 'password' | 'decimal';
  };
  switchProps?: SwitchProps;
  textAreaProps?: {
    rows?: number;
  };
  dateProps?: {
    format: any;
    showTime: boolean;
    disabledDate?: (current: Moment, fieldName?: string) => boolean;
  };
  timeProps?: {
    disabledHours?: () => number[];
    disabledMinutes?: () => number[];
  };
  validateStatus?: 'error' | 'validating';
  label: string;
  help?: string;
  showSearch?: boolean;
  fetchOptions?: any;
  checked?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  disableEmployeeReferal?: boolean;
  styles?: CSSProperties;
  onSelect?: (id: string) => void;
  onDeselect?: (id: string) => void;
  onSelectChange?: (values: any) => void;
  onChange?: (date: Moment | null, dateString: string) => void;
  onChecked?: (checked: boolean) => void;
  direction?: 'horizontal' | 'vertical';
  defaultChecked?: boolean | undefined;
  hideLabel?: boolean | undefined;
  hint?: React.ReactNode | string;
  value: string;
  loading?: boolean;
}

const InputSwitcher: React.FC<Props> = ({
  inputType,
  placeholder,
  checkboxOptions,
  checkedOptions,
  selectOptions,
  prefix,
  label,
  name,
  defaultValue,
  onClick,
  buttonType,
  rules,
  isMultiSelect,
  disabled,
  uploadProps,
  inputProps,
  dateProps,
  birthday,
  textAreaProps,
  validateStatus,
  help,
  showSearch,
  fetchOptions,
  checked,
  mode,
  disableEmployeeReferal,
  timeProps,
  styles,
  onSelect,
  onDeselect,
  onSelectChange,
  onChange,
  onChecked,
  direction,
  defaultChecked,
  hideLabel,
  switchProps,
  value,
  hint,
  loading,
}) => {
  switch (inputType) {
    case InputTypes.INPUT:
      return (
        <InputComponent
          name={name}
          key="input"
          placeholder={placeholder}
          rules={rules}
          inputProps={inputProps}
          validateStatus={validateStatus}
          disabled={disabled}
          help={help}
          hideLabel={hideLabel}
          hint={hint}
        />
      );
    case InputTypes.SELECT:
      return (
        <SelectComponent
          name={name}
          key="select"
          selectOptions={selectOptions}
          placeholder={placeholder}
          rules={rules}
          mode={(isMultiSelect && 'multiple') || undefined}
          disabled={disabled}
          showSearch={showSearch}
          styles={styles}
          onSelect={onSelect}
          onDeselect={onDeselect}
          onSelectChange={onSelectChange}
          hidePlaceholder={hideLabel}
          value={value}
          hint={hint}
        />
      );
    case InputTypes.SELECT_LOAD:
      return (
        <SelectLoad
          name={name}
          mode={mode}
          rules={rules}
          placeholder={placeholder}
          fetchOptions={fetchOptions}
          disabled={disabled}
          onSelect={onSelect}
          hidePlaceholder={hideLabel}
          hint={hint}
        />
      );
    case InputTypes.SELECT_PREFIX_REFERAL:
      return (
        <PrefixReferal
          name={name}
          key="selectPrefixReferal"
          defaultValue={defaultValue}
          placeholder={placeholder}
          disableEmployeeReferal={disableEmployeeReferal}
          hint={hint}
        />
      );
    case InputTypes.SELECTPREFIX:
      return (
        <PrefixSelectComponent
          name={name}
          key="selectPrefix"
          placeholder={placeholder}
          prefix={prefix}
          rules={rules}
          inputProps={inputProps}
          hint={hint}
          loading={loading}
        />
      );
    case InputTypes.TIMEPICKER:
      return (
        <TimePickerComponent
          name={name}
          key="input"
          placeholder={placeholder}
          rules={rules}
          disabled={disabled}
          timeProps={timeProps}
          hint={hint}
        />
      );
    case InputTypes.DATEPICKER:
      return (
        <DatepickerComponent
          name={name}
          key="input"
          placeholder={placeholder}
          rules={rules}
          birthday={birthday}
          disabled={disabled}
          dateProps={dateProps}
          onChange={onChange}
          hint={hint}
        />
      );
    case InputTypes.SWITCH:
      return (
        <SwitchComponent
          name={name}
          placeholder={placeholder}
          defaultChecked={defaultChecked}
          rules={rules}
          disabled={disabled}
          onChecked={onChecked}
          direction={direction}
          switchProps={switchProps}
          hint={hint}
        />
      );
    case 'date-range':
      return <DateRange name={name} label={placeholder} rules={rules} />;
    case InputTypes.UPLOAD:
      return (
        <UploadComponent
          name={name}
          label={placeholder}
          uploadProps={uploadProps}
          rules={rules}
          hint={hint}
        />
      );
    case InputTypes.BUTTON:
      return (
        <ButtonComponent
          name={name}
          label={placeholder}
          onClick={onClick}
          buttonType={buttonType}
        />
      );
    case InputTypes.TEXTAREA:
      return (
        <TextAreaComponent
          name={name}
          key="textarea"
          placeholder={placeholder}
          inputProps={textAreaProps}
          rules={rules}
          hideLabel={hideLabel}
          hint={hint}
        />
      );
    case InputTypes.LABEL:
      return <Typography.Text>{label}</Typography.Text>;
    case InputTypes.CHECKBOX_GROUP:
      return (
        <CheckboxGroup
          label={label}
          name={name}
          mode={mode}
          onSelect={onSelect}
          checkboxOptions={checkboxOptions}
          checkedOptions={checkedOptions}
          hint={hint}
        />
      );
    case InputTypes.CHECKBOX:
      return (
        <CheckboxComponent
          checked={checked}
          placeholder={placeholder}
          name={name}
          onChecked={onChecked}
          hint={hint}
        />
      );
    case InputTypes.RATE:
      return (
        <RateComponent
          name={name}
          key="rate"
          styles={styles}
          placeholder={placeholder}
          rules={rules}
          defaultValue={Number(defaultValue)}
          disabled={disabled}
          hint={hint}
        />
      );
    default:
      return (
        <InputComponent
          name={name}
          key="input"
          placeholder={placeholder}
          inputProps={inputProps}
          disabled={disabled}
          hint={hint}
        />
      );
  }
};

InputSwitcher.defaultProps = {
  selectOptions: [],
  prefix: {
    placeholder: '',
    selectOptions: [],
    name: '',
  },
  defaultValue: '',
  onClick: undefined,
  buttonType: 'default',
  rules: [],
  isMultiSelect: false,
  disabled: false,
  uploadProps: {},
  inputProps: { type: 'text' },
  textAreaProps: {
    rows: 4,
  },
  showSearch: false,
  checked: false,
};

export default InputSwitcher;
