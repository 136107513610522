import { Reducer } from 'react';
import { Action } from 'redux';
import {
  PAYROLL_RULE,
  PayrollRuleState,
  SinglePayrollRuleState,
} from './types';

const initialState: PayrollRuleState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const PayrollRuleReducer: Reducer<PayrollRuleState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case PAYROLL_RULE.FETCH_PAYROLL_RULE_START:
      state = { ...state, loading: true };
      break;
    case PAYROLL_RULE.FETCH_PAYROLL_RULE_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case PAYROLL_RULE.FETCH_PAYROLL_RULE_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case PAYROLL_RULE.CLEAR_ALL_PAYROLL_RULE:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSinglePayrollRuleState: SinglePayrollRuleState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SinglePayrollRuleReducer: Reducer<SinglePayrollRuleState, Action> = (
  state = initialSinglePayrollRuleState,
  action: any
) => {
  switch (action.type) {
    case PAYROLL_RULE.FETCH_SINGLE_PAYROLL_RULE_START:
      state = { ...state, loading: true };
      break;
    case PAYROLL_RULE.FETCH_SINGLE_PAYROLL_RULE_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case PAYROLL_RULE.FETCH_SINGLE_PAYROLL_RULE_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case PAYROLL_RULE.CLEAR_SINGLE_PAYROLL_RULE:
      state = initialSinglePayrollRuleState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { PayrollRuleReducer, SinglePayrollRuleReducer };
