import { Row, Tag, Typography } from 'antd';
import styled from 'styled-components';

export const Item = styled(Row)<{
  last?: boolean;
}>`
  width: 100%;
  margin-bottom: 10px;
  ${({ last }) => !last && 'border-bottom: 1px solid #f0f0f0;'};
  padding-bottom: 10px;
`;

export const Status = styled(Tag)`
  border-radius: 8px;
`;

export const Description = styled(Typography.Text)``;

export const Reason = styled(Typography.Text)`
  font-weight: 600;
  color: #475467;
  margin-bottom: 16px;
`;

export const Record = styled(Typography.Text)`
  font-weight: 300;
  color: #475467;
`;

export const Duration = styled(Typography.Text)`
  font-weight: 600;
  color: #475467;
`;
