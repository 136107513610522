import moment from 'moment';
import { isNil } from 'lodash';
import { AnimationEvent, ChartData, ChartOptions } from 'chart.js';

import { PALETE } from 'components/styledComponents/colors';
import { TrackedHoursBarChartDTO } from 'types/tracking';
import { formatTotalMinutes } from 'ui-v2/routes/tracking/utils';

export const getFormatedBarchartResponse = (
  responseData: TrackedHoursBarChartDTO[]
): ChartData<'bar'> => {
  const isYear = !!responseData?.find((el) => isNil(el?.days));
  const sortedResponse = responseData.sort((a, b) => a.months - b.months);
  if (isYear) {
    return {
      labels: sortedResponse.map((item) =>
        moment().month(item.months).format('MMMM')
      ),
      datasets: [
        {
          data: sortedResponse.map((item) => item.sum || 0),
          backgroundColor: PALETE['blue-400'],
        },
      ],
    };
  }
  return {
    labels: sortedResponse.map((item) =>
      moment()
        .dayOfYear(item.days || 0)
        .month(item.months)
        .format('ddd, MMM DD')
    ),
    datasets: [
      {
        data: sortedResponse.map((item) => item.sum || 0),
        backgroundColor: PALETE['blue-400'],
      },
    ],
  };
};

export const barChartOptions: ChartOptions<'bar'> = {
  animation: {
    onComplete: (event: AnimationEvent) => {
      event.chart.ctx.textAlign = 'center';
      event.chart.ctx.font = '0.8rem sans-serif';
      event?.chart?.data?.datasets?.forEach((item, i) => {
        const meta = event.chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const totalMinutes: number = item.data[index] as number;
          const label = formatTotalMinutes(totalMinutes);
          event.chart.ctx.fillText(
            label !== '00:00' ? label : '',
            bar.x,
            bar.y - 5
          );
        });
      });
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(context: any) {
          const label = context?.at(0)?.label;
          const totalMinutes = context?.at(0)?.raw as number;
          if (!label || !totalMinutes) {
            return '';
          }
          return `${label}   ${formatTotalMinutes(totalMinutes)}`;
        },
        label() {
          return '';
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
        offset: true,
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 11,
        stepSize: 0.2,
      },
    },
    y: {
      ticks: {
        stepSize: 2.8,
        padding: 10,
        callback(value) {
          const totalMinutes = Math.floor(parseInt(value.toString(), 10));
          const hours = Math.floor(totalMinutes / 60);
          return `${hours}h`;
        },
      },
    },
  },
};

export const doughnutOptions: ChartOptions<'doughnut'> = {
  responsive: true,
  animation: {
    onComplete: (event: AnimationEvent) => {
      event.chart.ctx.textAlign = 'center';
      event.chart.ctx.font = '2rem sans-serif';
      const x = event.chart.getDatasetMeta(0).data?.at(0)?.x ?? 0;
      const y = event.chart.getDatasetMeta(0).data?.at(0)?.y ?? 0;
      const data = event.chart.data.datasets.map((el) => el.data);
      const totalMinutes: number = data
        ?.at(0)
        ?.reduce((acc: number, el: any) => acc + (el as number), 0) as number;

      let label = '';
      if (totalMinutes) {
        label = formatTotalMinutes(totalMinutes);
      }
      event.chart.ctx.fillText(`${label}`, x, y + 5);
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(context: any) {
          const label = context?.at(0)?.label;
          const totalMinutes = context?.at(0)?.raw as number;
          if (!label || !totalMinutes) {
            return '';
          }
          return `${label}\n${formatTotalMinutes(totalMinutes)}`;
        },
        label() {
          return '';
        },
      },
    },
  },
};
