import moment, { Moment } from 'moment';
import React from 'react';

import { RulesType } from 'types/FormTypes';

import {
  disableInvalidBirthDates,
  getUserDateFormat,
} from 'utils/utilFunctions';
import { FormItemStyled, StyledDatepicker } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  birthday?: boolean;
  disabled?: boolean;
  dateProps?: {
    format: any;
    showTime: boolean;
    disabledDate?: (current: Moment) => boolean;
  };
  onChange?: (date: Moment | null, dateString: string) => void;
  hint?: React.ReactNode | string;
}

const DatepickerComponent: React.FC<Props> = ({
  placeholder,
  name,
  rules,
  birthday,
  disabled,
  dateProps,
  onChange,
  hint,
}) => {
  const userDateFormat = getUserDateFormat();

  return (
    <FormItemStyled
      name={name}
      label={placeholder}
      rules={rules}
      {...(hint && { tooltip: hint })}
    >
      {birthday ? (
        <StyledDatepicker
          disabledDate={disableInvalidBirthDates}
          format={userDateFormat}
          placeholder={placeholder}
          defaultPickerValue={moment().subtract(16, 'years')}
        />
      ) : (
        <StyledDatepicker
          format={dateProps?.format ?? userDateFormat}
          placeholder={placeholder}
          disabled={disabled}
          showTime={dateProps?.showTime}
          disabledDate={dateProps?.disabledDate}
          onChange={onChange}
        />
      )}
    </FormItemStyled>
  );
};
DatepickerComponent.defaultProps = {
  rules: [],
  disabled: false,
  birthday: false,
};
export default DatepickerComponent;
