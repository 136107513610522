import styled from 'styled-components';
import { Typography } from 'antd';

export const TableContainer = styled.div``;

export const TableRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
`;

export const TableRecordRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
`;

export const TableHeader = styled(Typography)<{
  width: number;
}>`
  font-weight: bold;
  width: 25%;
  width: ${({ width }) => width}%;
  text-align: left;
`;

export const TableRecordText = styled(Typography)`
  margin: 0;
`;

export const GenerateReportDate = styled(Typography)`
  font-weight: bold;
`;
