import { CandidatesColums } from 'table-configuration/CandidatesConfig';
import { CertificationColumns } from 'table-configuration/CertificationsConfig';
import { DepartmentsColumns } from 'table-configuration/DepartmentsConfig';
import { EmployeesColums } from 'table-configuration/EmployeesConfig';
import { EvaluationPeriodsConfig } from 'table-configuration/EvaluationPeriodsConfig';
import { InterviewsColumns } from 'table-configuration/InterviewsConfig';
import { OnboardingColumns } from 'table-configuration/OnboardingConfig';
import { OpenPositionsColumns } from 'table-configuration/OpenPositionsConfig';
import { ProjectsColumns } from 'table-configuration/ProjectsConfig';
import { QuestionnairesColumns } from 'table-configuration/QuestionnairesConfig';
import { TenantColumns } from 'table-configuration/tenantConfigs/TenantConfig';
import { TrainingsColumns } from 'table-configuration/TrainingsConfig';
import { WorkPositionsColumns } from 'table-configuration/WorkPositionsConfig';
import { AssetColumns as AssetsColumns } from 'table-configuration/AssetConfig';
import { AssetLogsColumns } from 'table-configuration/AssetLogsConfig';
import { CandidateOpenPositionObj, CandidateType } from 'types/Candidates';
import { DepartmentModelType } from 'types/Department';
import { TrainingType } from 'types/Training';
import { EmployeeType } from 'types/Employee';
import moment from 'moment';
import { convertDateToUTC, convertUTCtoLocalTime } from 'utils/utilFunctions';
import { UsersColumns } from 'table-configuration/UsersTableConfig';
import { EvaluationItem } from 'types/Evaluation';
import { TenantAccountColumns } from 'table-configuration/TenantAccountConfig';
import { PayrollRuleColumns } from 'table-configuration/PayrollRuleConfig';
import { PayrollRule, PayrollRuleType } from 'types/PayrollRule';
import { BenefitsColums } from 'table-configuration/BenefitsConfig';
import { BenefitPlanInterface, BenefitTemplate } from 'types/Benefit';
import { QuestionnairesType } from 'types/Questionnaires';
import { WorkPositionListModelType } from 'types/WorkPosition';
import { SkillsColumns } from 'table-configuration/SkillConfig';
import { VendorsColumns } from 'table-configuration/VendorsConfig';
import { IdeaboxColumns } from 'table-configuration/IdeaboxConfig';
import { Ideabox } from 'types/Ideabox';
import i18next from '../../../../i18n';

export const prepareExportData = (data: any, entity: string) => {
  switch (entity) {
    case 'candidates':
      return data.map((item: CandidateType) => ({
        ...item,
        expectedSalary: `${item.expectedSalary} ${item.currency?.symbol}`,
        openPosition: item.candidateOpenPositions
          .map((i: CandidateOpenPositionObj) => i?.openPositions?.name)
          .join(', '),
      }));
    case 'benefits':
      return data.map((item: BenefitTemplate) => ({
        ...item,
        plan: item.plans
          .map((i: BenefitPlanInterface) => i?.plan_name)
          .join(', '),
      }));
    case 'certifications':
      return data.map((item: any) => ({
        ...item,
        cost: `${item.cost} ${item.currency?.symbol}`,
        certificationSkills: item.certificationSkills
          .map((i: any) => i?.subCategoryName)
          .join(', '),
      }));
    case 'questionnaires':
      return data.map((item: QuestionnairesType) => ({
        ...item,
        department: item?.department.map((i: any) => i?.name).join(', '),
      }));
    case 'ideabox':
      return data.map((item: Ideabox) => ({
        ...item,
        department: item?.tags
          .map((i: EmployeeType) => `${i?.firstName} ${i?.lastName}`)
          .join(', '),
      }));
    case 'work-positions':
      return data.map((item: WorkPositionListModelType) => ({
        ...item,
        isHeadDepartment: item.isHeadDepartment
          ? i18next.t('yes')
          : i18next.t('no'),
        department: item?.department?.name,
      }));
    case 'employees':
      return data.map((item: EmployeeType) => ({
        ...item,
        age: `${moment().diff(
          convertUTCtoLocalTime(item.birthDate),
          'years',
          false
        )} years`,
        workPosition: item?.workPosition?.name,
      }));
    case 'departments':
      return data?.map((item: DepartmentModelType) => ({
        ...item,
      }));
    case 'evaluations':
      return data?.map((item: EvaluationItem) => ({
        ...item,
        name: 'redi',
      }));
    case 'trainings':
      return data.map((item: TrainingType) => ({
        ...item,
        skills: item.skills.join(', '),
        cost: `${item.cost} ${item.currecyTraining?.symbol}`,
        duration: `${item?.valueTime} ${item?.timeType}`,
      }));
    case 'users':
      return data.map((item: any) => ({
        ...item,
        roles: item.roles[0]?.name,
      }));
    case 'tenant-accounts':
      return data.map((item: any) => ({
        ...item,
        firstName: `${item.firstName} ${item.lastName}, ${moment().diff(
          convertUTCtoLocalTime(item.birthDate),
          'years',
          false
        )}`,
      }));
    case 'assets':
      return data.map((item: any) => ({
        ...item,
        status: item?.status?.toUpperCase(),
        purchaseDate: convertDateToUTC(item?.timestamp),
      }));
    case 'asset-logs':
      return data.map((item: any) => ({
        ...item,
        actionType: item?.actionType?.toUpperCase(),
        employee: `${
          item?.employee
            ? `${item?.employee?.firstName} ${item?.employee?.lastName}`
            : '-'
        }`,
        asset: item?.asset?.name || '-',
        timestamp: convertDateToUTC(item?.timestamp),
      }));
    case 'payroll-report':
      return data;
    case 'payroll-rules':
      return data.map((item: PayrollRule | any) => {
        if (item?.type === PayrollRuleType.FIXED) {
          return {
            ...item,
            parameters: `${item?.parameters?.value}${item?.currency?.symbol}`,
          };
        }
        if (item?.type === PayrollRuleType.PERCENTAGE) {
          return {
            ...item,
            parameters: `${item?.parameters?.value}%`,
          };
        }
        if (item?.type === PayrollRuleType.INTERVAL) {
          return {
            ...item,
            parameters: item?.parameters
              ?.map(
                (el: any) =>
                  `${el?.from} - ${el?.to} | Value: ${el?.value}${item?.currency?.symbol}`
              )
              .join(', '),
          };
        }
        return item;
      });

    default:
      return data;
  }
};

export const getExportTblConfig = (entity: string) => {
  let config: any[] = [];

  switch (entity) {
    case 'candidates':
      config = CandidatesColums.map((col) => {
        if (col.key === 'currentJobTitle') {
          return {
            label: col.title,
            key: 'currentJobTitle',
          };
        }
        if (col.key === 'status') {
          return {
            label: col.title,
            key: 'status',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'employees':
      config = EmployeesColums.map((col) => {
        if (col.key === 'firstName') {
          return {
            label: col.title,
            key: 'firstName',
          };
        }
        if (col.key === 'lastName') {
          return {
            label: col.title,
            key: 'lastName',
          };
        }
        if (col.key === 'email') {
          return {
            label: col.title,
            key: 'email',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'interviews':
      config = InterviewsColumns.map((col) => {
        if (col.key === 'candidateOpenPositions') {
          return {
            label: col.title,
            key: 'candidateOpenPositions.candidate.firstName',
          };
        }
        if (col.key === 'interviewType') {
          return {
            label: col.title,
            key: 'interviewType.name',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'open-positions':
      config = OpenPositionsColumns.map((col) => {
        if (col.key === 'workPositions') {
          return {
            label: col.title,
            key: 'workPosition.name',
          };
        }
        if (col.key === 'employmentType') {
          return {
            label: col.title,
            key: 'employeeType.name',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'certifications':
      config = CertificationColumns.map((col) => {
        if (col.key === 'companyName') {
          return {
            label: col.title,
            key: 'companyName',
          };
        }
        if (col.key === 'validation') {
          return {
            label: col.title,
            key: 'validation',
          };
        }
        if (col.key === 'cost') {
          return {
            label: col.title,
            key: 'cost',
          };
        }
        if (col.key === 'trainingDuration') {
          return {
            label: col.title,
            key: 'trainingDuration',
          };
        }
        if (col.key === 'certificationSkills') {
          return {
            label: col.title,
            key: 'certificationSkills',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'trainings':
      config = TrainingsColumns.map((col) => {
        if (col.key === 'name') {
          return {
            label: col.title,
            key: 'name',
          };
        }
        if (col.key === 'type') {
          return {
            label: col.title,
            key: 'type',
          };
        }
        if (col.key === 'duration') {
          return {
            label: col.title,
            key: 'duration',
          };
        }
        if (col.key === 'skills') {
          return {
            label: col.title,
            key: 'skills',
          };
        }
        if (col.key === 'cost') {
          return {
            label: col.title,
            key: 'cost',
          };
        }
        if (col.key === 'status') {
          return {
            label: col.title,
            key: 'status',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'departments':
      config = DepartmentsColumns.map((col) => {
        if (col.key === 'workPosition') {
          return {
            label: col.title,
            key: 'workPosition',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'projects':
      config = ProjectsColumns.map((col) => {
        if (col.key === 'description') {
          return {
            label: col.title,
            key: 'description',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'questionnaires':
      config = QuestionnairesColumns.map((col) => {
        if (col.key === 'description') {
          return {
            label: col.title,
            key: 'description',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'ideabox':
      config = IdeaboxColumns.map((col) => ({
        label: col.title,
        key: col.key,
      }));
      break;
    case 'work-positions':
      config = WorkPositionsColumns.map((col) => {
        if (col.key === 'isHeadDepartment') {
          return {
            label: col.title,
            key: 'isHeadDepartment',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'vendors':
      config = VendorsColumns.map((col) => ({
        label: col.title,
        key: col.key,
      }));
      break;
    case 'assets':
      config = AssetsColumns.map((col) => {
        if (col.key === 'description') {
          return {
            label: col.title,
            key: 'description',
          };
        }
        if (col.key === 'serialNumber') {
          return {
            label: col.title,
            key: 'serialNumber',
          };
        }
        if (col.key === 'purchasePrice') {
          return {
            label: col.title,
            key: 'purchasePrice',
          };
        }
        if (col.key === 'condition') {
          return {
            label: col.title,
            key: 'condition',
          };
        }
        if (col.key === 'type') {
          return {
            label: col.title,
            key: 'type',
          };
        }
        if (col.key === 'employeeAssets') {
          return {
            label: col.title,
            key: 'employeeAssets',
          };
        }
        if (col.key === 'status') {
          return {
            label: col.title,
            key: 'status',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'asset-logs':
      config = AssetLogsColumns?.map((col) => {
        if (col.key === 'actionType') {
          return {
            label: col.title,
            key: 'actionType',
          };
        }
        if (col.key === 'timestamp') {
          return {
            label: col.title,
            key: 'timestamp',
          };
        }
        if (col.key === 'previousValue') {
          return {
            label: col.title,
            key: 'previousValue',
          };
        }
        if (col.key === 'newValue') {
          return {
            label: col.title,
            key: 'newValue',
          };
        }
        if (col.key === 'description') {
          return {
            label: col.title,
            key: 'description',
          };
        }
        if (col.key === 'asset') {
          return {
            label: col.title,
            key: 'asset',
          };
        }
        if (col.key === 'employee') {
          return {
            label: col.title,
            key: 'employee',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'tenants':
      config = TenantColumns.map((col) => {
        if (col.key === 'name') {
          return {
            label: col.title,
            key: 'name',
          };
        }
        if (col.key === 'email') {
          return {
            label: col.title,
            key: 'email',
          };
        }
        if (col.key === 'phoneNumber') {
          return {
            label: col.title,
            key: 'phoneNumber',
          };
        }
        if (col.key === 'country') {
          return {
            label: col.title,
            key: 'country',
          };
        }
        if (col.key === 'city') {
          return {
            label: col.title,
            key: 'city',
          };
        }
        if (col.key === 'address') {
          return {
            label: col.title,
            key: 'address',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'onboardings':
      config = OnboardingColumns.map((col) => {
        if (col.key === 'department') {
          return {
            label: col.title,
            key: 'department.name',
          };
        }
        if (col.key === 'workPosition') {
          return {
            label: col.title,
            key: 'workPosition.name',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'companies':
      config = TenantColumns;
      break;
    case 'tenant-accounts':
      config = TenantAccountColumns.filter(
        (item: any) => item !== 'avatar'
      ).map((col: any) => {
        if (col.key === 'firstName') {
          return {
            label: col.title,
            key: 'firstName',
          };
        }
        if (col.key === 'email') {
          return {
            label: col.title,
            key: 'email',
          };
        }
        if (col.key === 'isActive') {
          return {
            label: col.title,
            key: 'isActive',
          };
        }
        if (col.key === 'phoneNumber') {
          return {
            label: col.title,
            key: 'phoneNumber',
          };
        }
        if (col.key === 'avatar') {
          const record = { ...col };
          delete record.avatar;
          return record;
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'evaluations':
      config = EvaluationPeriodsConfig.map((col) => {
        if (col.key === 'employee') {
          return {
            label: col.title,
            key: 'employee.firstName',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'users':
      config = UsersColumns.map((col) => ({
        label: col.title,
        key: col.key,
      }));
      break;
    case 'skills':
      config = SkillsColumns.map((col) => ({
        label: col.title,
        key: col.key,
      }));
      break;
    case 'payroll-report':
      config = [
        {
          label: 'Date',
          key: 'unique_date',
        },
        {
          label: 'Count',
          key: 'count',
        },
      ];
      break;
    case 'benefits':
      config = BenefitsColums.map((col) => {
        if (col.key === 'title') {
          return {
            label: col.title,
            key: 'title',
          };
        }
        if (col.key === 'specifications') {
          return {
            label: col.title,
            key: 'specifications',
          };
        }
        if (col.key === 'plan') {
          return {
            label: col.title,
            key: 'plan',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    case 'payroll-rules':
      config = PayrollRuleColumns.map((col) => {
        if (col.key === 'name') {
          return {
            label: col.title,
            key: 'humanID',
          };
        }
        if (col.key === 'description') {
          return {
            label: col.title,
            key: 'description',
          };
        }
        if (col.key === 'description') {
          return {
            label: col.title,
            key: 'description',
          };
        }
        if (col.key === 'type') {
          return {
            label: col.title,
            key: 'type',
          };
        }
        if (col.key === 'params') {
          return {
            label: col.title,
            key: 'parameters',
          };
        }
        return {
          label: col.title,
          key: col.key,
        };
      });
      break;
    default:
      return config;
  }

  return config.filter((col) => col.key !== 'action');
};
