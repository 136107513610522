import { EmployeeType } from './Employee';
import { OnboardingTaskModelType } from './OnboardingTask';

export interface EmployeeTaskModelType {
  id: string;
  name: string;
  assignedDate: Date;
  dueDate: Date;
  status: string;
  type: string;
  onBoardingTasks: OnboardingTaskModelType;
  mentor: EmployeeType;
}

export enum EmployeeTaskStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  FINISHED = 'Finished',
}
export enum EmployeeTrainingStatus {
  STARTED = 'Started',
  ATTENDING = 'Attending',
  COMPLETED = 'Completed',
}

export enum EmployeeTaskType {
  ON_BOARDING = 'On Boarding',
  CURRENTLY_WORKING = 'Currently Working',
}

export const EmployeeTaskStatusOptions = [
  {
    id: EmployeeTaskStatus.CREATED,
    value: EmployeeTaskStatus.CREATED,
    label: EmployeeTaskStatus.CREATED,
    key: '0',
  },
  {
    id: EmployeeTaskStatus.IN_PROGRESS,
    value: EmployeeTaskStatus.IN_PROGRESS,
    label: EmployeeTaskStatus.IN_PROGRESS,
    key: '1',
  },
  {
    id: EmployeeTaskStatus.FINISHED,
    value: EmployeeTaskStatus.FINISHED,
    label: EmployeeTaskStatus.FINISHED,
    key: '2',
  },
];

export const EmployeeTaskTypeOptions = [
  {
    id: EmployeeTaskType.CURRENTLY_WORKING,
    value: EmployeeTaskType.CURRENTLY_WORKING,
    label: EmployeeTaskType.CURRENTLY_WORKING,
    key: '0',
  },
  {
    id: EmployeeTaskType.ON_BOARDING,
    value: EmployeeTaskType.ON_BOARDING,
    label: EmployeeTaskType.ON_BOARDING,
    key: '1',
  },
];

export interface EmployeeTaskCreateType {
  name: string;
  assignedDate: Date;
  dueDate: Date;
  status: EmployeeTaskStatus;
  type: EmployeeTaskType;
  employeeId: string;
  onBoardingTaskId?: string;
  mentor: EmployeeType;
}
export interface EmployeeTaskUpdateType {
  name?: string;
  assignedDate: Date;
  dueDate: Date;
  status: EmployeeTaskStatus;
  type: EmployeeTaskType;
  employeeId?: string;
  onBoardingTaskId: string;
}
