/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';

import useHeader from 'ui-v2/hooks/useHeader';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { useWorkPositionsData } from 'ui-v2/hooks/useWorkPositionsData';
import { useAssetsData } from 'ui-v2/hooks/useAssetsData';
import { updateCandidate } from 'api/candidateService';
import { toast } from 'react-toastify';
import { candidateStatusList } from 'types/CandidateStatus';
import { UpdateCandidatesType } from 'types/Candidates';
import { UserAuthDataType } from 'redux/authUser/types';
import { useDispatch, useSelector } from 'react-redux';
import { useCertificationsData } from 'ui-v2/hooks/useCertificationsData';
import { useTrainingsData } from 'ui-v2/hooks/useTrainingsData';
import { useDepartmentData } from 'ui-v2/hooks/useDepartmentData';
import { useSkillsData } from 'ui-v2/hooks/useSkillsData';
import { useSearchParams } from 'react-router-dom';
import { fetchSubSkills } from 'redux/skills/actions';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { fetchSingleTenant } from 'redux/tenants/actions';
import { useTranslation } from 'react-i18next';
import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import { useCandidateData } from '../../hooks/useCandidateData';
import HeaderActions from '../HeaderActions';
import * as Styled from './HeaderStyles';
import {
  getPageTitle,
  candidateRoutesWithStatusBadge,
  routesWithSkeleton,
  formatDescriptionLabel,
  getCandidateStatusIcon,
} from './helpers';
import DropDown from '../DropDown';

export default function Header() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { authUser: data } = useSelector(
    (state: { authUser: UserAuthDataType }) => state.authUser
  );

  const { t } = useTranslation();
  const { currentRoute, hideDetails } = useHeader();
  const { candidate, loading } = useCandidateData();
  const {
    workPosition: { data: singleWorkPosition, loading: isWorkPositionLoading },
  } = useWorkPositionsData();
  const { singleAsset, loading: isAssetLoading } = useAssetsData();
  const {
    project: { data: singleProject, loading: isProjectLoading },
  } = useProjectsData();
  const { skillCategories } = useSkillsData();
  const { training, loading: isTrainingLoading } = useTrainingsData();
  const { loading: isDepartmentLoading, department } = useDepartmentData();
  const { certification, loading: isCertificationLoading } =
    useCertificationsData();
  const { loading: isEmployeeLoading, employeeAssignments } =
    useEmployeesData();
  const { employee, employeeBasicProfile } = useEmployeesData();
  const {
    questionnaireCategory: {
      selectedQuestionnaireCategory: vendorQuestionnaireCategory,
      loading: isVendorQuestionnaireCategoryLoading,
    },
  } = useVendorQuestionnaireData();

  const {
    tenants: { loaded: tenantsLoaded },
    singleTenanat: { loading: tenantLoading },
  } = useTenantsData();

  const tenantId = new URLSearchParams(window.location.search).get('tenantId');

  useEffect(() => {
    if (!tenantsLoaded && tenantId) {
      dispatch(fetchSingleTenant(tenantId));
    }
  }, [tenantId, tenantsLoaded]);

  const [candidateStatus, setCandidateStatus] = useState<string>(
    candidate?.status || ''
  );

  useEffect(() => {
    const { pathname } = window.location;
    const searchParamsId = searchParams.get('id');
    const isSkillsCategoryDetailsRoute = pathname.includes(
      '/skills/category-details'
    );

    if (isSkillsCategoryDetailsRoute && searchParamsId) {
      dispatch(fetchSubSkills(searchParamsId));
    }
  }, [searchParams]);

  useEffect(() => {
    if (candidate?.status) {
      setCandidateStatus(candidate?.status);
    }
  }, [candidate]);

  const onUpdateCandidateStatus = (status: string) => {
    if (!candidate?.id || !status) return;

    updateCandidate(candidate?.id, { status } as UpdateCandidatesType)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('Candidate status changed successfully!'));
        }
      })
      .catch(() => {
        toast.error(t('error'));
      });
  };
  if (
    routesWithSkeleton.includes(currentRoute) &&
    (loading ||
      isEmployeeLoading ||
      isProjectLoading ||
      isWorkPositionLoading ||
      isAssetLoading ||
      singleAsset.loading ||
      isTrainingLoading ||
      isCertificationLoading ||
      isDepartmentLoading ||
      tenantLoading ||
      isVendorQuestionnaireCategoryLoading)
  ) {
    return (
      <Styled.Container>
        <Row>
          <Col span={16}>
            <Skeleton
              active
              title={{
                width: 350,
              }}
              paragraph={{ rows: 1, width: 200 }}
            />
          </Col>
        </Row>
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Row>
        <Col span={10}>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="start" gutter={24}>
                <Col xs={{ order: 1 }} md={{ order: 2 }} lg={{ order: 1 }}>
                  <Styled.Title>
                    {getPageTitle({
                      path: currentRoute,
                      candidate,
                      employee: employeeBasicProfile || employee,
                      project: singleProject,
                      workPosition: singleWorkPosition,
                      asset: singleAsset?.data,
                      user: data,
                      training,
                      certification,
                      department,
                      vendorQuestionnaireCategory,
                      categoryName: skillCategories?.categoryName,
                    })}
                  </Styled.Title>
                </Col>
                <Col
                  xs={{ order: 3, span: 24 }}
                  md={{ order: 3, span: 24 }}
                  lg={{ order: 2, span: 7 }}
                >
                  {candidateRoutesWithStatusBadge.includes(currentRoute) &&
                    candidate?.status && (
                      <DropDown
                        status={candidateStatus.toLocaleLowerCase()}
                        items={candidateStatusList.map((item) => ({
                          label: item.label,
                          icon: getCandidateStatusIcon(item.label),
                          status: item.label.toLocaleLowerCase(),
                          disabled: !!candidate?.employeeId,
                        }))}
                        onChange={(status) => {
                          setCandidateStatus(status.label);
                          onUpdateCandidateStatus(status.label);
                        }}
                      >
                        <>
                          {getCandidateStatusIcon(
                            candidateStatus.toLocaleLowerCase()
                          )}
                          {t(candidateStatus)}
                        </>
                      </DropDown>
                    )}
                </Col>
                <Col
                  xs={{ order: 2 }}
                  md={{ order: 2 }}
                  lg={{ order: 2 }}
                  xl={{ order: 2, span: 24 }}
                >
                  <Styled.Role hidden={!!hideDetails}>
                    {formatDescriptionLabel(
                      currentRoute,
                      candidate,
                      employeeAssignments
                    )}
                  </Styled.Role>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row justify="end">
            <HeaderActions />
          </Row>
        </Col>
      </Row>
    </Styled.Container>
  );
}
