import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import GenericModal from 'ui-v2/components/GenericModal';
import { AssetsIcon } from 'Icons/AssetsIcon';
import { toast } from 'react-toastify';
import {
  convertDateToUTC,
  getLoggedInUserId,
  getUserDateFormat,
} from 'utils/utilFunctions';
import { AddEvaluationPayload } from 'types/Evaluation';
import { addEvaluation, updateEvaluation } from 'api/evaluationService';
import moment from 'moment';
import { InputTypes } from 'types/FormTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useEvaluationPeriodsData } from 'ui-v2/hooks/useEvaluationPeriodsData';
import { fetchEvaluationPeriods } from 'redux/evaluationPeriods/actions';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { FormItemStyled, StyledButton } from '../FormStyled';
import GenericForm from '../Form';
import { isFormEdited } from '../Form/utils';

type TProps = {
  open: boolean;
  closeModal: () => void;
};

export default function EvaluationModalForm({ open, closeModal }: TProps) {
  const { evaluation } = useEvaluationPeriodsData();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const authUserState = useSelector((state: any) => state.authUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const AddEmployeeEvaluationFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'employeeId',
          label: t('employee'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: getEmployeeSkimSelectWithLoadOptions,
          defaultValue: evaluation?.employee
            ? `${evaluation.employee.firstName} ${evaluation.employee.lastName}`
            : null,
        },
      ],
      [
        {
          col: 10,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          dataIndex: 'startDate',
          type: 'datepicker',
          dateProps: {
            format: userDateFormat,
          },
          defaultValue: evaluation?.startDate && moment(evaluation?.startDate),
          rules: [
            {
              required: true,
              message: t('Start Date is required'),
            },
          ],
        },
        {
          col: 10,
          offset: 4,
          name: 'endDate',
          label: t('endDate'),
          dataIndex: 'endDate',
          type: 'datepicker',
          defaultValue: evaluation?.endDate && moment(evaluation?.endDate),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
          rules: [
            {
              required: true,
              message: t('End Date is required'),
            },
          ],
        },
      ],

      [
        {
          col: 24,
          offset: 0,
          name: 'employeeFeedback',
          label: t('leaveFeedback'),
          dataIndex: 'feedback',
          defaultValue: evaluation?.employeeFeedback,
          type: 'textarea',
          rules: [
            {
              required: true,
              message: t('Feedback is required'),
            },
          ],
        },
      ],
    ],
    [evaluation]
  );
  const onSubmit = async () => {
    setLoading(true);

    const formValuesToAdd: AddEvaluationPayload = form.getFieldsValue(true);

    const formValuesToEdit: any = form.getFieldsValue(true);

    const payloadToEdit = {
      ...formValuesToEdit,
      startDate: convertDateToUTC(formValuesToEdit?.startDate),
      endDate: convertDateToUTC(formValuesToEdit?.endDate),
      isActive: true,
      employeeId: evaluation?.employee?.id,
    };

    if (evaluation?.id) {
      if (
        isFormEdited({
          formValues: payloadToEdit,
          valuesToCheck: evaluation,
          entity: 'evaluation',
        })
      ) {
        closeModal();
        setLoading(false);
        return;
      }
      await updateEvaluation(evaluation?.id, payloadToEdit)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(
              fetchEvaluationPeriods({ pageOptionsDto: { page: 1, take: 10 } })
            );
            toast.success(t('Successfully updated evaluation'));
            closeModal();
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('somethingWentWrong'));
        });
    } else {
      await addEvaluation({
        isActive: formValuesToAdd.isActive ?? false,
        employeeId: form.getFieldValue('employeeId').value,
        employeeFeedback: formValuesToAdd.employeeFeedback,
        evaluations: formValuesToAdd.employeeFeedback
          ? [
              {
                userId: getLoggedInUserId(authUserState) as string,
                feedback: formValuesToAdd?.employeeFeedback,
              },
            ]
          : undefined,
        startDate: formValuesToAdd?.startDate,
        endDate: formValuesToAdd?.endDate,
      })
        .then(() => {
          setLoading(false);
          dispatch(
            fetchEvaluationPeriods({ pageOptionsDto: { page: 1, take: 10 } })
          );
          toast.success(t('Successfully added employee evaluation!'));
          closeModal();
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('somethingWentWrong'));
        });
    }
  };

  return (
    <GenericModal
      title={evaluation?.id ? t('editEvaluation') : t('Add evaluation')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddEmployeeEvaluationFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
