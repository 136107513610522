import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Select } from 'antd';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { StyledCollapse } from 'components/NewForms/DynamicForm/DynamicFormStyles';
import { FormItemStyled } from 'components/NewForms/FormStyled';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPlanInterface, BenefitTemplate } from 'types/Benefit';

type IProps = {
  form: any;
  benefitPlans: BenefitPlanInterface[];
  setBenefitPlans: (benefits: BenefitPlanInterface[]) => void;
  selectedBenefitTemplate: BenefitTemplate | undefined;
  selectedBenefitTemplatePlan: any;
};

export default function BenefitPlan({
  form,
  benefitPlans,
  setBenefitPlans,
  selectedBenefitTemplate,
  selectedBenefitTemplatePlan,
}: IProps) {
  const { Option } = Select;

  const onAddBenefitPlan = () => {
    const newBenefitPlanKey = benefitPlans.length;
    const newBenefitPlan: any = {
      key: newBenefitPlanKey,
      plan_name: null,
      plan_value: null,
      currency: null,
    };

    setBenefitPlans([...benefitPlans, newBenefitPlan]);
  };

  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  const onRemovePanel = (benefitKey: number) => {
    const removedBenefitPlan = benefitPlans.filter(
      (benefit: any) => benefit.key !== benefitKey
    );
    setBenefitPlans(removedBenefitPlan);
  };

  const onInputChanged = (
    currentBenefit: BenefitPlanInterface,
    value: string,
    field: string
  ): BenefitPlanInterface[] => {
    const updatedBenefitPlans = benefitPlans.map((b: any) => {
      if (b.key === currentBenefit.key) {
        return {
          ...b,
          [field]: value,
        };
      }
      return b;
    });
    setBenefitPlans(updatedBenefitPlans);
    return updatedBenefitPlans;
  };

  const onCurrencyChanged = (currentBenefit: any, value: any) => {
    const updatedBenefitPlans = benefitPlans.map((b) => {
      if (b.key === currentBenefit.key) {
        return {
          ...b,
          currency: value,
        };
      }
      return b;
    });
    setBenefitPlans(updatedBenefitPlans);
    return updatedBenefitPlans;
  };

  useEffect(() => {
    if (selectedBenefitTemplate) {
      const selectedPlansWithKey = selectedBenefitTemplate?.plans.map(
        (plan, index) => ({
          ...plan,
          key: index,
        })
      );

      const sortedPlans = selectedPlansWithKey?.sort(
        (a, b) => Number(a.plan_value) - Number(b.plan_value)
      );
      setBenefitPlans(sortedPlans ?? []);
    }
  }, [selectedBenefitTemplate]);

  const { t } = useTranslation();

  return (
    <div>
      <Form form={form}>
        <FormItemStyled style={{ marginTop: 30 }}>
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <StyledCollapse style={{ marginTop: '-30px' }}>
                {benefitPlans.map((benefit, index) => (
                  <Collapse.Panel
                    header={`${t('plan')} ${index + 1}`}
                    key={benefit.key}
                    extra={
                      <MinusCircleOutlined
                        onClick={() => onRemovePanel(benefit.key)}
                      />
                    }
                  >
                    <Row>
                      <Col span={11}>
                        <Form.Item
                          label={t('plansName')}
                          name={`name${benefit.key}`}
                          initialValue={benefit.plan_name}
                          rules={[
                            {
                              required: true,
                              message: t('pleaseEnterPlansName'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t('addPlansName')}
                            onChange={(e) =>
                              onInputChanged(
                                benefit,
                                e.target.value,
                                'plan_name'
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11} offset={2}>
                        <Form.Item
                          label={t('value')}
                          name={`value${benefit.key}`}
                          initialValue={benefit.plan_value}
                          rules={[
                            {
                              required: true,
                              message: t('Please enter value'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t('addValue')}
                            type="number"
                            onChange={(e) =>
                              onInputChanged(
                                benefit,
                                e.target.value,
                                'plan_value'
                              )
                            }
                            addonAfter={
                              <Select
                                defaultValue={
                                  selectedBenefitTemplatePlan
                                    ? selectedBenefitTemplatePlan?.plans[index]
                                        ?.currency?.name
                                    : currencyOptions[0]?.label
                                }
                                loading={currencyLoading}
                                style={{ width: 80 }}
                                onChange={(value) =>
                                  onCurrencyChanged(benefit, value)
                                }
                              >
                                {currencyOptions.map((currency) => (
                                  <Option key={currency.id} value={currency.id}>
                                    {currency.label}
                                  </Option>
                                ))}
                              </Select>
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                ))}
              </StyledCollapse>
            </Col>
            <Col offset={18}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => onAddBenefitPlan()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('addPlan')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </FormItemStyled>
      </Form>
    </div>
  );
}
