import { Collapse } from 'antd';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)`
  background-color: white;
  border: none;
  [class*='ant-collapse'],
  [class*='ant-collapse-content'],
  [class*='ant-collapse-content-box'] {
    border-radius: 8px !important;
  }
  [class*='ant-collapse-content'],
  [class*='ant-collapse-content-box'] {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  [class*='ant-collapse-item'] {
    margin-bottom: 10px;
  }
  [class*='anticon anticon-plus-square'] {
    margin: 4px 5px 0 0;
  }
  [class*='ant-collapse-item'] {
    border-bottom: 0px !important;
  }
`;
export const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-content {
    border-top: 0px;
  }
  .kabrxI {
    border: 0px !important;
  }
`;
export const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  video {
    width: 100%;
    height: 15rem;
    margin-bottom: 10px;
  }
`;
