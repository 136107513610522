import React, { useCallback, useEffect, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { CheckBox } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/ConfigureComponentStyles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useTranslation } from 'react-i18next';
import {
  TrackingFilterPanelLabel,
  TrackingFilterPanelPopoverInnerOverlayStyle,
} from '../utils';
import {
  StyledTrackingFilterPanelCol,
  StyledTrackingFilterPanelLabel,
} from '../StyledTrackingFilterPanel';

interface FilterTogglePanelItemProps {
  borderRight?: boolean;
  onFilterVisibilityChange?: (newLabels: TrackingFilterPanelLabel[]) => void;
  labels?: TrackingFilterPanelLabel[];
}

const FilterTogglePanelItem: React.FC<FilterTogglePanelItemProps> = ({
  borderRight,
  onFilterVisibilityChange,
  labels,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [hiddenLabels, setHiddenLabels] = useState<TrackingFilterPanelLabel[]>(
    []
  );
  useEffect(() => {
    onFilterVisibilityChange?.(hiddenLabels);
  }, [hiddenLabels]);

  const onChange = (
    e: CheckboxChangeEvent,
    clickedLabel: TrackingFilterPanelLabel
  ) => {
    if (!e.target.checked) {
      setHiddenLabels([...hiddenLabels, clickedLabel]);
    } else {
      setHiddenLabels(hiddenLabels.filter((el) => el !== clickedLabel));
    }
  };
  const tooglePopoverVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onVisibleChange = () => {
    tooglePopoverVisibility();
    onFilterVisibilityChange?.(hiddenLabels);
  };
  return (
    <Popover
      overlayInnerStyle={TrackingFilterPanelPopoverInnerOverlayStyle}
      trigger="click"
      placement="bottom"
      popupVisible={visible}
      onVisibleChange={onVisibleChange}
      content={
        <Row gutter={[0, 10]}>
          {labels?.map((label) => (
            <Col span={24}>
              <CheckBox
                defaultChecked={!hiddenLabels.find((el) => el === label)}
                onChange={(e) => onChange(e, label)}
              >
                {t(label)}
              </CheckBox>
            </Col>
          ))}
        </Row>
      }
    >
      <StyledTrackingFilterPanelCol borderRight={borderRight}>
        <StyledTrackingFilterPanelLabel>
          <Row align="middle" gutter={[5, 0]}>
            <Col>{t(TrackingFilterPanelLabel.FILTERS)}</Col>
            <Col>
              <CaretDownOutlined />
            </Col>
          </Row>
        </StyledTrackingFilterPanelLabel>
      </StyledTrackingFilterPanelCol>
    </Popover>
  );
};

export default FilterTogglePanelItem;
