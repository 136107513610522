import React from 'react';
import { Badge, Col, Popconfirm, Popover, Row } from 'antd';
import { CloseIcon } from 'Icons/CloseIcon';
import { ResponseSkillCategoryType } from 'types/Skills';
import SkillDetails from '../SkillDetails';
import i18next from '../../../../../i18n';
import * as Styled from '../../SkillsStyles';

type IProps = {
  skill: ResponseSkillCategoryType;
  onDelete: (skill: ResponseSkillCategoryType) => void;
};

const SkillTag: React.FC<IProps> = ({ skill, onDelete }) => (
  <Popover
    placement="top"
    content={<SkillDetails skill={skill} />}
    trigger="hover"
    overlayClassName="popover-card-container"
    overlayInnerStyle={Styled.SillPopoverInnerOverlayStyles}
  >
    <Badge
      count={Number(skill.yearsOfExperience)}
      style={Styled.SkillBadgeStyles}
    >
      <Styled.Tag>
        <Row justify="center" align="middle" className="skill-tag">
          <Col>
            <Styled.TagLabel>
              {skill.skillsSubCategory?.subCategoryName}
            </Styled.TagLabel>
          </Col>
          <Popconfirm
            placement="bottomLeft"
            title={i18next.t('Are you sure you want to delete this skill')}
            onConfirm={() => onDelete(skill)}
            okText={i18next.t('yes')}
            cancelText={i18next.t('no')}
          >
            <Col>
              <CloseIcon />
            </Col>
          </Popconfirm>
        </Row>
      </Styled.Tag>
    </Badge>
  </Popover>
);

export default SkillTag;
