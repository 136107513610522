import { TrainingCreateType, TrainingSts, TrainingType } from 'types/Training';
import agent from 'api/agent';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllTrainings = (queryParams: QueryParamsType) =>
  agent.post(
    `${apiURLs.training}/get`,
    searchFields(queryParams, apiURLs.training)
  );

export const getTraining = (id?: string) =>
  agent.get(`${apiURLs.training}/${id}`);

export const searchTrainings = (searchText: string | null) =>
  agent.get(`${apiURLs.training}?name=${searchText}&limit=${20}`);

export const createTraining = (trainingData: TrainingCreateType) =>
  agent.post(apiURLs.training, trainingData);

export const updateTraining = (id: string, trainingData: TrainingType) =>
  agent.patch(`${apiURLs.training}/${id}`, trainingData);

export const employeesAttendingTraining = (trainingId: string) =>
  agent.get(`${apiURLs.employeesAttendingTraining}/${trainingId}`);

export const trainingStatus = (payload: TrainingSts) =>
  agent.patch(`${apiURLs.trainingStatus}`, payload);

export const deleteTraining = (id: string) =>
  agent.delete(`${apiURLs.training}/${id}`);
