import { removeEvaluation } from 'api/evaluationService';
import EvaluationModalForm from 'components/NewForms/EvaluationModalForm';
import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchEvaluationPeriods } from 'redux/evaluationPeriods/actions';
import Content from 'ui-v2/components/Content';
import { useEvaluationPeriodsData } from 'ui-v2/hooks/useEvaluationPeriodsData';
import useHeader from 'ui-v2/hooks/useHeader';
import ViewEvaluationFeedbackModal from './ViewEvaluationFeedbackModal';

export default function EvaluationPeriodsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useEvaluationPeriodsData();

  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [viewModalVisible, setViewModalVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>();

  const dispatch = useDispatch();

  async function deleteEvaluationById(id: string | null) {
    if (!id) return;
    try {
      await removeEvaluation(id);
      toast.success('Evaluation deleted successfully!');
      let currentPage = pagination?.page;
      if (list.length === 1) {
        currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      }
      dispatch(
        fetchEvaluationPeriods({
          pageOptionsDto: { page: currentPage, take },
        })
      );
    } catch (error) {
      toast.error('Evaluation failed to delete!');
    }
  }

  useEffect(() => {
    if (!loading) {
      dispatch(
        fetchEvaluationPeriods({ pageOptionsDto: { page: 1, take: 10 } })
      );
    }
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      pageOptionsDto: { page, take: 10 },
      ...(searchTerm && {
        filter: { filterText: searchTerm },
      }),
    };
    dispatch(fetchEvaluationPeriods(params));
  };

  const onSearch = (searchTermValue: string) => {
    const params = {
      ...(searchTermValue && {
        filter: { filterText: searchTermValue, filterFields: [] },
      }),
      pageOptionsDto: { page: 1, take: 10 },
    };
    dispatch(fetchEvaluationPeriods(params));
    setSearchTerm(searchTermValue);
  };

  useEffect(() => {
    if (searchParams.get('add-evaluation') === 'true') {
      searchParams.delete('add-evaluation');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('view-evaluation') !== null) {
      setViewModalVisible(true);
    }
    if (searchParams.get('edit-evaluation')) {
      setModalVisible(true);
    }
    if (searchParams.get('delete-evaluation')) {
      deleteEvaluationById(searchParams.get('delete-evaluation'));
      searchParams.delete('delete-evaluation');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteEvaluationById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Evaluation Periods"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <EvaluationModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-evaluation');
              setSearchParams(searchParams);
            }}
          />
        )}
        {viewModalVisible && (
          <ViewEvaluationFeedbackModal
            open={viewModalVisible}
            closeModal={() => {
              setViewModalVisible(false);
              searchParams.delete('view-evaluation');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
