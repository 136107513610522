import { useEffect, useState } from 'react';
import { UpdateQuestionnaireQuestionDTO } from 'types/Questionnaires';

export function CanAddQuestion(
  questions: Array<UpdateQuestionnaireQuestionDTO>,
  threshold = 5
) {
  const [canAddQuestion, setCanAddQuestion] = useState(false);

  useEffect(() => {
    const nonDeletedQuestions = questions.filter((q) => !q.deleted);
    const canAdd = nonDeletedQuestions.length < threshold;
    setCanAddQuestion(canAdd);
  }, [questions, threshold]);

  return canAddQuestion;
}
