import agent from 'api/agent';
import {
  CandidateOfferCreate,
  CandidateOfferUpdate,
} from 'types/CandidateOffer';
import { apiURLs } from './constants';

export const getCandidateOffer = (offerId: string) =>
  agent.get(`${apiURLs.candidateOffers}/${offerId}`);

export const getCandidateOpenPositionOffer = (openPositionId: string) =>
  agent.get(`${apiURLs.candidateOpenPositions}/offer/${openPositionId}`);

export const updateCandiadteOffer = (
  offerId: string,
  values: CandidateOfferUpdate
) => agent.patch(`${apiURLs.candidateOffers}/${offerId}`, values);

export const createCandidateOffer = (values: CandidateOfferCreate) =>
  agent.post(apiURLs.candidateOffers, values);

export const deleteCandidateOffer = (id: string) =>
  agent.delete(`${apiURLs.candidateOffers}/${id}`);
