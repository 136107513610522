import React from 'react';
import { StyledUploadButton } from 'components/NewForms/FormStyled';
import { PlusCircleOutlined } from '@ant-design/icons';
import { RowStyled } from './RightSecctionStyled';

interface IProps {
  buttonText: string;
  buttonCallBack: () => void;
}

export default function RightSection({ buttonText, buttonCallBack }: IProps) {
  return (
    <RowStyled>
      <StyledUploadButton
        type="link"
        icon={<PlusCircleOutlined />}
        onClick={buttonCallBack}
      >
        {buttonText}
      </StyledUploadButton>
    </RowStyled>
  );
}
