import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { useSearchParams } from 'react-router-dom';
import {
  EmployeeAssignmnetsType,
  EmployeeFormType,
  EmployeeType,
} from 'types/Employee';
import GenericFormSwitcher from './components/GenericFormSwitcher';

interface IProps {
  employee?: EmployeeType | undefined;
  assignments?: EmployeeAssignmnetsType | undefined;
}
function EmployeeForm({ employee, assignments }: IProps) {
  const [formType, setFormType] = useState<string>(
    EmployeeFormType.SELECT_LOAD
  );
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('id')) {
      setFormType(EmployeeFormType.EDIT);
    }
  }, [searchParams]);
  return (
    <CardItem title="">
      <GenericFormSwitcher
        type={formType}
        employee={employee}
        assignments={assignments}
      />
    </CardItem>
  );
}

EmployeeForm.defaultProps = {
  employee: {},
  assignments: {},
};

export default EmployeeForm;
