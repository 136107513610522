import { useDispatch, useSelector } from 'react-redux';
import { fetchPayrollDates } from 'redux/payrollReport/actions';

export default function usePayrollDatesData() {
  const dispatch = useDispatch();
  const payrollDates = useSelector((state: any) => state.payrollDates);

  function generateReportDates() {
    dispatch(fetchPayrollDates());
  }

  return {
    data: payrollDates?.data || [],
    loading: payrollDates?.loading,
    loaded: payrollDates?.loaded,
    error: payrollDates?.error,
    generateReportDates,
  };
}
