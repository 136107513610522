import { UpdateQuestionnaireQuestionDTO } from 'types/Questionnaires';

const canDeleteQuestion = (
  questions: Array<UpdateQuestionnaireQuestionDTO>
): boolean => {
  const filteredQuestions = questions.filter((q) => !q.deleted);
  return filteredQuestions.length > 3;
};

export default canDeleteQuestion;
