import React from 'react';
import { FormInstance } from 'antd';

import TenantConfigMissionVisionPanel from './TenantConfigMissionVisionPanel';

interface TenantMissionConfigProps {
  formInstance: FormInstance;
}

const TenantMissionConfig: React.FC<TenantMissionConfigProps> = ({
  formInstance,
}) => <TenantConfigMissionVisionPanel formInstance={formInstance} />;

export default TenantMissionConfig;
