import { Reducer } from 'react';
import { TenantConfig, TenantConfigState } from './types';

const initialTenantConfigState: TenantConfigState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

export type TenantConfigReducedAction = {
  type: TenantConfig;
  payload: Partial<TenantConfigState>;
};

const TenantConfigReducer: Reducer<
  TenantConfigState,
  TenantConfigReducedAction
> = (state = initialTenantConfigState, action: TenantConfigReducedAction) => {
  switch (action.type) {
    case TenantConfig.FETCH_TENANT_CONFIG_START:
      state = { ...state, ...action.payload };
      break;
    case TenantConfig.FETCH_TENANT_CONFIG_SUCCESS:
      state = { ...state, ...action.payload };
      break;
    case TenantConfig.FETCH_TENANT_CONFIG_ERRORED:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case TenantConfig.CLEAR_TENANT_CONFIG:
      state = initialTenantConfigState;
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export { TenantConfigReducer };
