/* eslint react/prop-types: 0 */

import React, { useEffect, useMemo, useState } from 'react';

import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Image } from 'types/Image';

import { InputTypes } from 'types/FormTypes';
import { prefixSelector } from 'utils/constants';
import GenericForm from 'components/NewForms/Form';
import Card from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import {
  convertBase64,
  convertDateToUTC,
  toastErrorMessages,
  getValidFileContent,
} from 'utils/utilFunctions';
import { CreateUserDto } from 'redux/users/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addNewUser } from 'api/userService';
import { toast } from 'react-toastify';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';

function AddUserForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, isLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState<string>();
  const [fileToUpload, setFileToUpload] = useState<Image>();
  const { t } = useTranslation();

  const { optionsType: roles } = useGetSelectOptions({
    type: 'roles',
    transform: (item) => ({
      id: item.name,
      value: item.name,
      label: item.name,
    }),
  });

  const { optionsType: project } = useGetSelectOptions({
    type: 'project',
    transform: (item) => ({
      id: item.id,
      value: item.value,
      label: item.name,
    }),
  });

  const onFieldsChange = () => {
    setSelectedRole(form.getFieldValue('roles'));
  };

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFileToUpload({ name: file.name, content: validBase64, type: file.type });
    return false;
  }

  function onFormFinish(formValues: CreateUserDto) {
    isLoading(true);
    const payload: CreateUserDto = {
      ...formValues,
      avatar: {
        ...fileToUpload,
        content: fileToUpload?.content,
      } as Image,
      roles: [`${formValues.roles}`],
      birthDate: convertDateToUTC(formValues.birthDate),
    };

    addNewUser(payload)
      .then((res) => {
        if (res.status === 200 && res.data?.id) {
          isLoading(false);
          toast.success(t('User is added successfully!'));
          navigate('/users');
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  useEffect(() => {
    if (searchParams.get('add-save-user') === 'true') {
      searchParams.delete('add-save-user');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams]);

  const UserConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'firstName',
          label: t('firstName'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('First name is required!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'lastName',
          label: t('lastName'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('Last name is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 2,
          name: 'birthDate',
          label: t('birthdate'),
          type: InputTypes.DATEPICKER,
          birthday: true,
          rules: [
            {
              required: true,
              message: t('thisFieldisRequired!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('requiredEmail'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          rules: [
            {
              required: true,
              message: t('Phone number is required!'),
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'roles',
          label: t('role'),
          type: InputTypes.SELECT,
          styles: { textTransform: 'capitalize' },
          selectOptions:
            roles
              ?.filter((i) => i?.label !== 'super_admin')
              .map((i) => ({
                ...i,
                label: `${i.label}`.split('_').join(' '),
              })) || [],
          rules: [
            {
              required: true,
              message: t('Role is required'),
            },
          ],
        },
      ],
      selectedRole === AUTH_ROLES.GUEST_TRACKING_HOURS
        ? [
            {
              col: 24,
              offset: 0,
              name: 'projects',
              label: t('projects'),
              type: InputTypes.SELECT,
              styles: { textTransform: 'capitalize' },
              selectOptions: project,
              isMultiSelect: true,
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
          ]
        : [],
      [
        {
          col: 24,
          offset: 0,
          name: 'avatar',
          label: t('avatar'),
          type: 'upload',
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (fileToUpload: RcFile) => uploadAction(fileToUpload),
            maxCount: 1,
            listType: 'picture',
          },
        },
      ],
    ],
    [roles, project, selectedRole]
  );

  return (
    <Content position="center">
      <Card title={t('Add User')}>
        <GenericForm
          formConfiguration={UserConfiguration}
          onFinish={onFormFinish}
          onFieldsChange={onFieldsChange}
          form={form}
          loading={loading}
        />
      </Card>
    </Content>
  );
}

export default AddUserForm;
