/* eslint-disable indent */
import { Dispatch } from 'redux';
import { QueryParamsType } from 'types/QueryParams';
import { getAllIpWhiteListing } from 'api/ipAccess';
import { IpAccess } from './types';

export const fetchIpWhitelisting =
  (queryParams: QueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: IpAccess.FETCH_IP_ACCESS_START,
    });
    getAllIpWhiteListing(queryParams)
      .then((res) => {
        dispatch({
          type: IpAccess.FETCH_IP_ACCESS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: IpAccess.FETCH_IP_ACCESS_ERRORED,
          payload: e,
        });
      });
  };
