import moment from 'moment';

import { OpenPositionType } from 'interfaces/OpenPosition';
import { CandidateStatus } from './CandidateStatus';
import { CertificationType, SingleCertificationDTO } from './Certification';
import { InterviewType } from './Interview';
import { IResume } from './IResume';
import { Stage } from './Stages';

export interface CurrencyType {
  id: string;
  symbol: string;
  name: string;
  label?: string;
}

export enum ReferalType {
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  EMPLOYEE = 'employee',
  OTHER = 'other',
}

interface CertificationsCandidates {
  id: string;
  certification: CertificationType;
}

export interface YearOfExperience {
  yearsOfExperience: string | undefined;
}

export interface JobSubCategoriesCandidates {
  id: string;
  jobSubCategory: {
    id: string;
    subCategoryName: string;
    jobCategory: {
      categoryName: string;
      id: string;
    };
  };
}
export interface ICandidateOpenPositionDTO {
  candidateOpenPosId: string;
  stageId?: string;
  score?: number;
}
export interface CandidateOpenPositionObj {
  id: string;
  stages: Stage;
  openPositions?: OpenPositionType;
  score?: number;
  certifications?: SingleCertificationDTO;
}
export interface CandidatesType {
  id: string;
  email: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  currentJobTitle: string;
  expectedSalary: number;
  candidateOpenPositions: CandidateOpenPositionObj[];
  resume: string;
  referalType?: ReferalType;
  skills: string[];
  interviews: InterviewType[];
  currency: CurrencyType;
  stage: Stage;
  status: CandidateStatus;
  // score: number | string | undefined;
  jobSubCategoriesCandidates: [YearOfExperience];
  selectedCurrency: string;
  gender?: string;
}

export type WorkExperience = {
  companyId: string;
  id: string;
  workTitle: string;
  companyName: string;
  startDate: string;
  endDate: string | undefined | Date;
  ongoing: boolean;
};

export interface UpdateCandidatesType {
  stageName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  currentJobTitle?: string;
  source?: string;
  employeeReferral?: string;
  phoneNumber?: string;
  expectedSalary?: number;
  birthDate?: string;
  openPositions?: Array<string>;
  phoneNumberPrefix?: string;
  selectedCurrency?: string;
  currencyId?: string;
  workExperience?: WorkExperience[];
  skillSubCategories?: any;
  score?: number;
  status?: CandidateStatus;
  gender?: string;
  notes?: string;
}
export interface CandidateResumeType {
  name: string;
  data: {
    content: string;
    name: string;
  };
}

export interface JobSubCategories {
  technology: string;
  skillSubCategoryId: string;
}

export type SelectedSkills = {
  categoryName: string;
  skill: {
    id: string;
    yearsOfExperience: number;
  }[];
};

export interface CandidateFormType {
  email: string;
  firstName: string;
  lastName: string;
  currencyId: string;
  birthDate: string | Date;
  phoneNumber: string | number;
  employeeReferral: string | undefined;
  currentJobTitle: string;
  expectedSalary: string | number;
  openPositions: string[];
  certifications: string[];
  phoneNumberPrefix: string;
  backend: JobSubCategories[];
  'dev op': JobSubCategories[];
  frontend: JobSubCategories[];
  resume: IResume;
  gender?: string;
}

export type SelectedSkillsGroup = {
  [key: string]: {
    id: string;
    yearsOfExperience: number;
  }[];
};

export interface CandidateType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  currency: CurrencyType;
  birthDate: string | Date;
  phoneNumber: string | number;
  referalType?: ReferalType;
  referedBy?: string;
  currentJobTitle: string;
  expectedSalary: string | number;
  candidateOpenPositions: CandidateOpenPositionObj[];
  certifications: string[];
  phoneNumberPrefix: string;
  resume: string | IResume;
  jobSubCategories: JobSubCategories[];
  certificationsCandidates: CertificationsCandidates[];
  jobSubCategoriesCandidates: JobSubCategoriesCandidates[];
  selectedTypeGroup: SelectedSkillsGroup;
  experience: WorkExperience[];
  status?: string;
  interviews?: InterviewType[];
  employeeId?: string;
  skillSubCategoriesCandidates: any[];
  notes?: string;
  stage?: Stage;
  linkedIn?: string;
  score?: number;
  gender: string;
}

export interface CandidateCreateDTO extends CandidateType {
  openPositions: string[];
}

export interface OpenCandidateRegistration extends CandidateCreateDTO {
  tenantId: string;
  candidateOpenPositionId: string;
}

export enum Gender {
  F = 'Female',
  M = 'Male',
  O = 'Other',
}

export type GenderKey = keyof typeof Gender;
export interface HireCandidateInputType extends CandidateType {
  ssn: string;
  workEmail: string;
  workPositionId: string;
  onBoardingStatus: string;
  hireDate: moment.Moment | string;
  onBoardingEndDate?: moment.Moment | string;
  onBoardingStartDate: moment.Moment | string;
  salary: number;
}

export interface CandidateResumesDTO {
  candidateIds: string[];
}
