import { Row } from 'antd';
import styled from 'styled-components';

export const StyledText = styled('div')`
   marginTop: '30px', 
   marginBottom: '20px'
`;

export const RowItemLocation = styled(Row)`
  width: 100%;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

export const HeaderLocation = styled(Row)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -22px;
`;
