import { PlusCircleOutlined } from '@ant-design/icons';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { Col, Popconfirm, Row, Spin } from 'antd';
import {
  createDepartmentHeadEmployee,
  deleteDepartmentHeadEmployee,
  getDepartmentEmployees,
  getDepartmentHeadEmployee,
} from 'api/departmentService';
import { IconButton } from 'components/Actions/AssetAction';
import { StyledButton } from 'components/NewForms/FormStyled';
import { StyledSelect } from 'components/common/DataGrid/DataGridStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmployeeType } from 'types/Employee';
import CardItem from 'ui-v2/components/Card';
import GenericModal from 'ui-v2/components/GenericModal';
import {
  ItemLabel,
  ItemValue,
} from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { toastErrorMessages } from 'utils/utilFunctions';

const DepartmentHead: React.FC = () => {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState<EmployeeType[]>([]);
  const [visible, setVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const departmentId = searchParams.get('id');
  const [loading, setLoading] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();
  const [departmentHeadEmployee, setDepartmentHeadEmployee] =
    useState<EmployeeType>();

  const fetchDepartmentHeadEmployee = useCallback(async () => {
    if (!departmentId) return;
    setLoading(true);
    getDepartmentHeadEmployee(departmentId)
      .then((response) => {
        setDepartmentHeadEmployee(response.data);
      })
      .catch(() => {
        toast.error(t('cantGetHeadDepartmentEmployee'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [departmentId]);

  const fetchDepartmentEmployees = useCallback(async () => {
    if (!departmentId || employeeList?.length) return;
    setLoading(true);
    getDepartmentEmployees(departmentId)
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch(() => {
        toast.error(t('cantGetHeadDepartmentEmployee'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [departmentId, employeeList]);

  const createDepartmentHead = async (employeeId: string) => {
    if (!departmentId) {
      return;
    }
    setLoading(true);
    createDepartmentHeadEmployee({ employeeId, departmentId })
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('headDepartmentEmployeeCreatedSuccessfully'));
          setDepartmentHeadEmployee(res.data);
          fetchDepartmentHeadEmployee();
        }
      })
      .catch(toastErrorMessages)
      .finally(() => setLoading(false));
  };

  const deleteDepartmentHead = async () => {
    if (!departmentId) {
      return;
    }
    setLoading(true);
    deleteDepartmentHeadEmployee(departmentId)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('headDepartmentEmployeeDeletedSuccessfully'));
          fetchDepartmentHeadEmployee();
        }
      })
      .catch(toastErrorMessages)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (departmentId) {
      fetchDepartmentHeadEmployee();
    }
  }, [departmentId]);

  useEffect(() => {
    if (visible && departmentId) {
      fetchDepartmentEmployees();
    }
  }, [visible, departmentId]);

  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onSelect = (id: any) => {
    setSelectedEmployeeId(id as string);
  };

  const onSubmit = useCallback(async () => {
    if (!selectedEmployeeId) {
      return;
    }
    await createDepartmentHead(selectedEmployeeId);
    toggleVisibility();
  }, [selectedEmployeeId]);

  return (
    <>
      <CardItem title={t('departmentHead')}>
        <Row>
          {departmentHeadEmployee?.id ? (
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Link
                    to={`/employees/employee-profile?id=${departmentHeadEmployee.id}`}
                  >
                    <ItemValue>{`${departmentHeadEmployee?.firstName} ${departmentHeadEmployee?.lastName}`}</ItemValue>
                  </Link>
                </Col>
                <Col>
                  <Popconfirm
                    title={t('headDepartmentEmployeeDeletePopconfirmMessage')}
                    onConfirm={deleteDepartmentHead}
                    cancelText={t('cancel')}
                    okText={t('yes')}
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col span={24}>
              <StyledButton onClick={toggleVisibility}>
                <Row justify="center" align="middle" gutter={[10, 0]}>
                  <Col>
                    <ItemLabel>{t('addHeadofDepartment')}</ItemLabel>
                  </Col>
                  <Col>
                    <PlusCircleOutlined />
                  </Col>
                </Row>
              </StyledButton>
            </Col>
          )}
        </Row>
      </CardItem>
      {visible && (
        <GenericModal
          open={visible}
          closeModal={toggleVisibility}
          title={t('headDepartmentEmployeeSelectPlaceholderMessage')}
        >
          <Spin spinning={loading}>
            <Row gutter={[0, 30]}>
              <Col span={24}>
                <StyledSelect
                  className="ant-styled-select"
                  placeholder={t(
                    'headDepartmentEmployeeSelectPlaceholderMessage'
                  )}
                  onSelect={onSelect}
                  options={employeeList?.map((el) => ({
                    id: el.id,
                    label: `${el.firstName} ${el.lastName}`,
                    value: el.id,
                  }))}
                />
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  <Col span={11} onClick={toggleVisibility}>
                    <StyledButton danger>{t('cancel')}</StyledButton>
                  </Col>
                  <Col span={11}>
                    <StyledButton type="primary" onClick={onSubmit}>
                      {t('submit')}
                    </StyledButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </GenericModal>
      )}
    </>
  );
};

export default DepartmentHead;
