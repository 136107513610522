import {
  PublicSuperAdminCreateDto,
  PublicSuperAdminUpdateDto,
} from 'types/PublicSuperAdmin';
import { AuthUserCredentials } from 'redux/authUser/types';
import { QueryParamsDTO } from 'types/QueryParams';
import agent from './agent';
import { apiURLs } from './constants';

export const getAllPublicSuperAdmins = async (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.publicSuperAdmin}/get`, queryParams);

export const updatePublicSuperAdmin = async (
  id: string,
  payload: PublicSuperAdminUpdateDto
) => agent.patch(`${apiURLs.publicSuperAdmin}/${id}`, payload);

export const createPublicSuperAdmin = async (
  payload: PublicSuperAdminCreateDto
) => agent.post(apiURLs.publicSuperAdmin, payload);

export const deletePublicSuperAdminUser = async (id: string) =>
  agent.delete(`${apiURLs.publicSuperAdmin}/${id}`);

export const getPublicSuperAdminQRCode = async () =>
  agent.get(`${apiURLs.twoFactorAuthentication}/generate`);

export const authenticatePublicSuperAdmin = async (
  twoFactorAuthenticationCode: string
) =>
  agent.post(`${apiURLs.twoFactorAuthentication}/authenticate`, {
    twoFactorAuthenticationCode,
  });

export const publicSuperAdminLogin = async (values: AuthUserCredentials) =>
  agent.post(apiURLs.authSuperAdminLogin, values);

export const getLoggedInSuperAdmin = () =>
  agent.post(`${apiURLs.publicSuperAdmin}/me`);

export const superAdminPasswordRules = () =>
  agent.post(`${apiURLs.publicSuperAdmin}/password-rules`);
