import React, { useState } from 'react';
import { Button, Col, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { EmployeeNotesType } from 'types/EmployeeNotes';
import { deleteEmployeeNote } from 'api/employeeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { useTranslation } from 'react-i18next';
import CardItem from '../Card';
import * as Styled from '../Task/TaskStyles';
import { NoteItem } from './NoteItem';
import AddNoteModal from './AddNoteModal';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';

interface IProps {
  notes: EmployeeNotesType[];
  employeeId: string;
  loading: boolean;
}
export default function NotesComponent({ notes, employeeId, loading }: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [editNoteId, setEditNoteId] = useState('');
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const onToggleModal = () => setModalVisible((state) => !state);

  const deleteNote = async (noteId: string) => {
    await deleteEmployeeNote(noteId)
      .then(() => {
        toast.success(t('noteDeleted'));
        dispatch(fetchEmployeesDetail(employeeId));
        setEditNoteId('');
      })
      .catch(() => {
        toast.error(t('errorDeletingthisNote'));
      });
  };
  const onEditClose = () => {
    setEditNoteId('');
  };
  const handleNoteItemClick = (id: string) => {
    setEditNoteId(id);
  };

  return (
    <CardItem title={t('notes')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Styled.RowItem gutter={[0, 24]} justify="center">
          {notes.map((n: EmployeeNotesType, index: number) => (
            <Col span={24}>
              <NoteItem
                item={n}
                onItemClick={handleNoteItemClick}
                {...n}
                onDelete={() => deleteNote(n.id || '')}
                isLast={index === notes.length - 1}
              />
            </Col>
          ))}
          {!notes.length && (
            <Col>
              <Styled.Label>
                {authUserRole === AUTH_ROLES.EMPLOYEE ? (
                  <Styled.Label>{t('youDontHaveAnyNotes')}</Styled.Label>
                ) : (
                  <Styled.Label>{t('assignANoteToThisEmployee')}</Styled.Label>
                )}
              </Styled.Label>
            </Col>
          )}
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <Col span={24}>
              <Button
                icon={
                  <Styled.AddSpaceIcon>
                    <PlusCircleOutlined />
                  </Styled.AddSpaceIcon>
                }
                size="large"
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  fontWeight: '600',
                  fontSize: '16px',
                  color: '344054',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={onToggleModal}
              >
                {t('addNote')}
              </Button>
            </Col>
          </RoleBasedButton>
        </Styled.RowItem>
        {modalVisible && (
          <AddNoteModal
            open={modalVisible}
            closeModal={() => setModalVisible(false)}
            id=""
          />
        )}
        {editNoteId && (
          <AddNoteModal id={editNoteId} open closeModal={onEditClose} />
        )}
      </Skeleton>
    </CardItem>
  );
}
