import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from 'components/NewForms/FormStyled';
import { ScheduleIcon } from 'Icons/ScheduleIcon';
import {
  clearScheduleInterview,
  fetchCandidateOpenPositionInterviews,
  scheduleNewInterview,
} from 'redux/candidates/actions';
import { RootState } from 'redux/store';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { InterviewType } from 'types/Interview';
import {
  convertDateToUTC,
  convertUTCtoFormatedLocalTime,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import { Spin } from 'antd';
import CustomCalendar from './CustomCalendar.tsx';
import { HEADER_BUTTON_TYPE } from '../HeaderActions/config';
import * as Styled from './CalendarPopoverStyles';

interface IProps {
  offboarding?: boolean;
  onInterviewScheduled?: () => void;
}

export default function CalendarPopover({
  offboarding = false,
  onInterviewScheduled,
}: IProps) {
  const [width, setWidth] = useState('450');
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const { candidate, selectedCandidateOpenPosition } = useCandidateData();
  const { data: offboardingData } = useOffboardingData();
  const interviews: InterviewType[] = candidate?.interviews || [];
  const [loading, setLoading] = useState(false);
  const { data, loaded } = useSelector(
    (state: RootState) => state.scheduleInterview
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const employeeId = searchParams.get('employeeId');

  const buttonLabel = interviews?.length
    ? `${t('interviewIn')} ${convertUTCtoFormatedLocalTime(interviews[0].date)}`
    : t(HEADER_BUTTON_TYPE.SCHEDULE_INTERVIEW);

  useEffect(() => {
    if (!visible) setWidth('450');
  }, [visible]);

  useEffect(() => {
    if (loaded && data) {
      dispatch(clearScheduleInterview());
      if (selectedCandidateOpenPosition?.data?.id) {
        dispatch(
          fetchCandidateOpenPositionInterviews(
            selectedCandidateOpenPosition.data.id
          )
        );
      }
    }
  }, [loaded, data]);

  const onSchedule = async ({
    date,
    employee,
    interviewType,
  }: {
    date: Moment;
    employee: string[];
    interviewType: string;
  }) => {
    setVisible(false);
    setLoading(true);

    try {
      await dispatch(
        scheduleNewInterview({
          date: convertDateToUTC(date),
          comments: 'string',
          candidateOpenPositionId:
            selectedCandidateOpenPosition?.data?.id || '',
          offboardingId:
            !selectedCandidateOpenPosition?.data?.id &&
            (offboardingData?.id || ''),
          interviewTypeId: interviewType,
          partecipants:
            offboardingData?.id && employeeId
              ? [employeeId, ...employee]
              : employee,
        })
      );

      if (onInterviewScheduled) {
        onInterviewScheduled();
      }
    } catch (error) {
      console.error('Error scheduling interview:', error);
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  };

  const tooglePopoverVisibility = () => setVisible((visible) => !visible);

  return (
    <Spin spinning={loading} tip="Scheduling interview...">
      <Styled.CustomPopover
        overlayStyle={{
          width: `${width}px`,
        }}
        overlayInnerStyle={{
          height: '520px',
        }}
        visible={visible}
        onVisibleChange={tooglePopoverVisibility}
        content={
          visible && (
            <CustomCalendar
              offboarding={offboarding}
              onTimeVisible={() => setWidth('650')}
              onCancel={tooglePopoverVisibility}
              onSchedule={onSchedule}
            />
          )
        }
        trigger="click"
      >
        <StyledButton
          id="schedule-interview-popover"
          onClick={tooglePopoverVisibility}
          icon={<ScheduleIcon />}
          disabled={!!candidate?.employeeId}
        >
          {buttonLabel}
        </StyledButton>
      </Styled.CustomPopover>
    </Spin>
  );
}
