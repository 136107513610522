import React from 'react';
import { StyledButton } from 'components/NewForms/FormStyled';
import { FilterByIcon } from 'Icons/FilterByIcon';
import { Popover } from 'antd';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTranslation } from 'react-i18next';
import FilterComponent from './FilterComponent';
import {
  PopoverOverlayInnerStyles,
  PopoverOverlayStyles,
} from './TableFilterPopoverStyles';
import { routesWithFilters } from '../TableConfigurePopover/ConfigreComponent/helpers';

export default function FilterPopover() {
  const { entity } = useHeader();
  const { t } = useTranslation();

  if (!routesWithFilters.includes(entity)) {
    return (
      <StyledButton id="filter-popover" icon={<FilterByIcon />}>
        {t('filterBy')}
      </StyledButton>
    );
  }

  return (
    <Popover
      overlayClassName="popover-content"
      overlayStyle={PopoverOverlayStyles}
      overlayInnerStyle={PopoverOverlayInnerStyles}
      content={<FilterComponent />}
      trigger="click"
    >
      <StyledButton id="filter-popover" icon={<FilterByIcon />}>
        {t('filterBy')}
      </StyledButton>
    </Popover>
  );
}
