/* eslint-disable */
import { getInterviewTasks } from 'api/taskService';
import { Dispatch } from 'redux';
import { TasksAction } from './types';

interface ActionParams {
  interviewId: string;
}

export const fetchTasks =
  ({ interviewId }: ActionParams) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: TasksAction.FETCH_TASKS_START,
    });
    getInterviewTasks(interviewId)
      .then((res) => {
        dispatch({
          type: TasksAction.FETCH_TASKS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: TasksAction.FETCH_TASKS_ERRORED,
          payload: e,
        });
      });
  };

export const clearFetchTasks = () => (dispatch: Dispatch) => {
  dispatch({
    type: TasksAction.CLEAR_TASKS,
  }); 
};
