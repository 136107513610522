/* eslint-disable @typescript-eslint/no-unused-vars */
import { AUTH_ROLES } from 'types/Auth';
import { logOutAndRedirect } from 'api/authService';
import { Action } from 'redux';
import {
  AuthActionPayload,
  AuthActionTypes,
  AuthUserCredentials,
  AuthUserState,
  TwoFactorAuthenticationPayload,
} from './types';
import { AuthReducedAction } from './reducer';

export const onSuperAdminLogin = (userCredentials: AuthUserCredentials) => ({
  type: AuthActionTypes.ON_SUPER_ADMIN_LOGIN,
  userCredentials,
});

export const onLogin = (
  userCredentials: AuthUserCredentials
): AuthActionPayload => ({
  type: AuthActionTypes.ON_LOGIN,
  userCredentials,
});

export const onLogout = () => ({
  type: AuthActionTypes.ON_LOGOUT,
});

export const getAuthUser = () => ({
  type: AuthActionTypes.GET_USER,
});

export const actionFailed = (error: any) => ({
  type: AuthActionTypes.ACTION_FAILED,
  error,
});

export const actionCompleted = (data: any) => ({
  type: AuthActionTypes.ACTION_COMPLETED,
  data,
});

export const updateAuthState = (changes: AuthUserState): AuthReducedAction => ({
  type: AuthActionTypes.UPDATE_AUTH_STATE,
  ...changes,
});

export const actionTwoFactorQRCodeRequired = (
  twoFactorQRCodeRequired: boolean
) => ({
  type: AuthActionTypes.ACTION_TWO_FACTOR_QR_CODE_REQUIRED,
  twoFactorQRCodeRequired,
});

export const actionAccountBlocked = (isAccountBlocked: boolean) => ({
  type: AuthActionTypes.ACTION_ACCOUNT_BLOCKED,
  isAccountBlocked,
});

export const actionLoggedInUserRole = (role: AUTH_ROLES) => ({
  type: AuthActionTypes.ACTION_LOGGED_IN_USER_ROLE,
  role,
});

export const firstLoginHandler = (data: boolean) => ({
  type: AuthActionTypes.FIRST_LOGIN,
  data,
});

export const onTwoFactorAuthentication = (
  data: TwoFactorAuthenticationPayload
): Action & TwoFactorAuthenticationPayload => ({
  type: AuthActionTypes.ON_TWO_FACTOR_AUTHENTICATION,
  authentionCode: data?.authentionCode,
  isSuperAdminLogin: data?.isSuperAdminLogin,
});

export const clearAuthState = (toSuperAdminLogin?: boolean) => {
  logOutAndRedirect(toSuperAdminLogin);
  return {
    type: AuthActionTypes.CLEAR_AUTH_STATE,
  };
};
