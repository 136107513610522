import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchTenantAccounts } from 'redux/tenants/actions';
import { TenantAccountType } from 'types/Tenant';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import useHeader from 'ui-v2/hooks/useHeader';
import { TenantsAccountsQueryParamsType } from 'types/QueryParams';
import TenantAccountDetailModal from './TenantAccountDetailModal';
import TenantAccountSettingsModal from './TenantAccountSettingsModal';
import RegisterTenantAccount from './RegisterTenantAccount';

const TenantAccountsList: React.FC = () => {
  const dispatch = useDispatch();
  const { columns, take } = useHeader();
  const [tenantAccount, setTenantAccount] = useState<TenantAccountType>();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [registerAccountModalVisible, setRegisterAccountModalVisible] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    tenantAccounts: { data: accounts, meta: pagination, loading, loaded },
  } = useTenantsData();
  const tenantId = new URLSearchParams(window.location.search).get('tenantId');
  const [queryParams, setQueryParams] =
    useState<TenantsAccountsQueryParamsType>({
      pageOptions: { take },
    });

  const toogleDetailModalVisibility = useCallback(() => {
    setDetailModalVisible(!detailModalVisible);
  }, [detailModalVisible]);

  const toogleSettingModalVisibility = useCallback(() => {
    setSettingModalVisible(!settingModalVisible);
  }, [settingModalVisible]);

  const toogleRegisterAccountModalVisibility = useCallback(() => {
    setRegisterAccountModalVisible(!registerAccountModalVisible);
  }, [registerAccountModalVisible]);

  const onSearch = (text: string) => {
    setQueryParams({
      filterOptions: { filterText: text },
    });
  };

  const onPageChange = (page: number) => {
    setQueryParams({
      pageOptions: { take, page },
    });
  };
  useEffect(() => {
    if (tenantId) {
      dispatch(
        fetchTenantAccounts(tenantId, {
          ...queryParams,
          pageOptions: { ...queryParams.pageOptions, take },
        })
      );
    }
  }, [tenantId, queryParams, take]);

  useEffect(() => {
    if (loaded && searchParams.get('viewtenantaccountid')) {
      setTenantAccount(
        accounts?.find(
          (account) =>
            account?.id === (searchParams.get('viewtenantaccountid') as string)
        )
      );
      searchParams.delete('viewtenantaccountid');
      setSearchParams(searchParams);
      toogleDetailModalVisibility();
    }
    if (loaded && searchParams.get('tenantaccountsettings')) {
      setTenantAccount(
        accounts?.find(
          (account) =>
            account?.id ===
            (searchParams.get('tenantaccountsettings') as string)
        )
      );
      searchParams.delete('tenantaccountsettings');
      setSearchParams(searchParams);
      toogleSettingModalVisibility();
    }
    if (loaded && searchParams.get('register-account')) {
      searchParams.delete('register-account');
      setSearchParams(searchParams);
      toogleRegisterAccountModalVisibility();
    }
  }, [loaded, searchParams]);

  return (
    <>
      <DataGrid
        columns={columns}
        data={accounts}
        loading={loading}
        paginationName="tenant-accounts"
        showSearch
        totalItems={pagination?.itemCount}
        currentPage={pagination?.page}
        totalPages={pagination?.pageCount}
        onPageChange={onPageChange}
        onSearch={onSearch}
      />
      {detailModalVisible && (
        <TenantAccountDetailModal
          tenantAccount={tenantAccount}
          onClose={toogleDetailModalVisibility}
        />
      )}
      {settingModalVisible && (
        <TenantAccountSettingsModal
          tenantAccount={tenantAccount}
          onClose={toogleSettingModalVisibility}
        />
      )}
      {registerAccountModalVisible && (
        <RegisterTenantAccount onClose={toogleRegisterAccountModalVisibility} />
      )}
    </>
  );
};
export default TenantAccountsList;
