import React from 'react';

export const DashboardProjectsIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#F2F4F7" />
    <path
      d="M23 28.0001L18 30.5001L27.6422 35.3212C27.7734 35.3868 27.839 35.4196 27.9078 35.4325C27.9687 35.4439 28.0313 35.4439 28.0922 35.4325C28.161 35.4196 28.2266 35.3868 28.3578 35.3212L38 30.5001L33 28.0001M18 25.5001L27.6422 20.679C27.7734 20.6134 27.839 20.5806 27.9078 20.5677C27.9687 20.5562 28.0313 20.5562 28.0922 20.5677C28.161 20.5806 28.2266 20.6134 28.3578 20.679L38 25.5001L28.3578 30.3212C28.2266 30.3868 28.161 30.4196 28.0922 30.4325C28.0313 30.4439 27.9687 30.4439 27.9078 30.4325C27.839 30.4196 27.7734 30.3868 27.6422 30.3212L18 25.5001Z"
      stroke="#475467"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#F9FAFB"
      strokeWidth="8"
    />
  </svg>
);
