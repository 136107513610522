import React from 'react';

export const AddOnboardingIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1E0FF" />
    <path
      d="M32 34L35 37M35 37L38 34M35 37V31M31.5 19.2908C32.9659 19.8841 34 21.3213 34 23C34 24.6787 32.9659 26.1159 31.5 26.7092M28 31H24C22.1362 31 21.2044 31 20.4693 31.3045C19.4892 31.7105 18.7105 32.4892 18.3045 33.4693C18 34.2044 18 35.1362 18 37M29.5 23C29.5 25.2091 27.7091 27 25.5 27C23.2909 27 21.5 25.2091 21.5 23C21.5 20.7909 23.2909 19 25.5 19C27.7091 19 29.5 20.7909 29.5 23Z"
      stroke="#155EEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#EFF4FF"
      strokeWidth="8"
    />
  </svg>
);
