import React from 'react';
import { Alert, Col, Row, Typography } from 'antd';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { Title } from 'ui-v2/components/Card/CardStyles';
import CardItem from 'ui-v2/components/Card';
import NoAuthPageLayout from 'ui-v2/components/NoAuth/NoAuthPageLayout';
import { useLocation } from 'react-router-dom';
import i18next from '../../../i18n';

function AccountBlockedMsg({ error }: { error?: string }) {
  return (
    <Alert
      type="error"
      message={`${
        error ?? i18next.t('Your account has been blocked!')
      } ${i18next.t('Please contact your administrator.')}`}
      showIcon
    />
  );
}

interface LoginFormLayoutProps {
  children: React.ReactNode;
}

const LoginFormLayout: React.FC<LoginFormLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { accountBlocked, error } = useSelector(
    (state: RootState) => state.authUser
  );
  const isSuperAdminLoginPage = location.pathname.includes('super');
  const isForgotPasswordPage = location.pathname.includes('forgot');
  let title = '';

  if (isSuperAdminLoginPage) {
    title = i18next.t('superAdmin');
  } else if (accountBlocked) {
    title = i18next.t('accountBlocked');
  } else if (isForgotPasswordPage) {
    title = i18next.t('forgotPassword');
  } else {
    title = i18next.t('login');
  }

  return (
    <NoAuthPageLayout>
      <Row justify="center" gutter={[0, 20]}>
        <Col xs={20} md={12} lg={10}>
          <CardItem>
            <Row justify="center" gutter={[0, 20]}>
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    <Title>{title}</Title>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={18} lg={15}>
                {accountBlocked ? (
                  <AccountBlockedMsg error={error} />
                ) : (
                  children
                )}
              </Col>
              {accountBlocked && (
                <Col span={24}>
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <Row justify="center">
                        <Col>
                          <Typography.Link href="login">
                            {i18next.t('backToLogin')}
                          </Typography.Link>
                        </Col>
                      </Row>
                    </Col>
                    <Col />
                  </Row>
                </Col>
              )}
            </Row>
          </CardItem>
        </Col>
      </Row>
    </NoAuthPageLayout>
  );
};

export default LoginFormLayout;
