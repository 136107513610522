import { Button, Row, Space } from 'antd';
import styled from 'styled-components';

export const CompanyDetailsStyled = styled(Row)`
  [class*='ant-skeleton-avatar'],
  [class*='ant-avatar-circle'] {
    width: 100px;
    height: 100px;
  }

  [class*='ant-skeleton-input'] {
    border-radius: 5px;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 200px;
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  background-color: #155eef;
`;

export const SpaceStyled = styled(Space)`
  [class*='ant-space-item'] {
    width: auto;
    margin-top: 50px;
  }
`;
