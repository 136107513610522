import React, { useEffect, useState } from 'react';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { Col, Row } from 'antd';
import { CandidateOpenPositionObj } from 'types/Candidates';
import { useDispatch } from 'react-redux';
import { setSelectedOpenPosition } from 'redux/candidates/actions';
import BaseTabs from 'ui-v2/components/tabs/BaseTabs';
import BaseTab from 'ui-v2/components/tabs/BaseTab';
import { useSearchParams } from 'react-router-dom';

export default function OpenPositionsTabs() {
  const { candidate } = useCandidateData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<string>(
    searchParams?.get('openPositionId') ?? ''
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidate?.id && candidate?.candidateOpenPositions.length) {
      const selectedOpenPos: CandidateOpenPositionObj | undefined =
        candidate?.candidateOpenPositions?.find((c: any) => c.id === tab);

      dispatch(
        setSelectedOpenPosition(
          selectedOpenPos ?? candidate?.candidateOpenPositions[0]
        )
      );
      // dispatch(
      //   fetchCandidateOpenPositionInterviews(
      //     candidate?.candidateOpenPositions[0]?.id
      //   )
      // );
      // dispatch(fetchCandidateOffer(candidate?.candidateOpenPositions[0]?.id));
    }
  }, [candidate?.id]);

  useEffect(() => {
    searchParams.set('openPositionId', tab);
    setSearchParams(searchParams);
  }, [tab]);

  const onChange = (value: string) => {
    const selectedOpenPos: CandidateOpenPositionObj | undefined =
      candidate?.candidateOpenPositions?.find((c: any) => c.id === value);
    if (selectedOpenPos) {
      setTab(value);
      dispatch(setSelectedOpenPosition(selectedOpenPos));
    }
  };

  const showTabs = (candidate?.candidateOpenPositions?.length ?? 0) > 0;
  if (!showTabs) return null;

  return (
    <Row justify="space-between">
      <Col>
        <BaseTabs onChange={onChange} defaultActiveKey={tab}>
          {showTabs &&
            candidate?.candidateOpenPositions?.map(
              (item: CandidateOpenPositionObj) => (
                <BaseTab tab={item.openPositions?.name ?? ''} key={item.id} />
              )
            )}
        </BaseTabs>
      </Col>
    </Row>
  );
}
