import { Reducer } from 'react';
import { Action } from 'redux';

import { Trainings, TrainingsState } from './types';

const initialState: TrainingsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<TrainingsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Trainings.FETCH_TRAININGS_START:
      state = { ...state, loading: true };
      break;
    case Trainings.FETCH_TRAININGS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Trainings.FETCH_TRAININGS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const singleTraniningReducer: Reducer<TrainingsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Trainings.FETCH_TRAINING_START:
      state = { ...state, loading: true };
      break;
    case Trainings.FETCH_TRAINING_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Trainings.FETCH_TRAINING_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Trainings.CLEAR_SINGLE_TRAINING:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as TrainingsReducer };
export { singleTraniningReducer as singleReducer };
