import React from 'react';

export const UserHireIcon = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3337 15.4997V14.4997C13.3337 13.0995 13.3337 12.3995 13.0612 11.8647C12.8215 11.3943 12.439 11.0118 11.9686 10.7722C11.4339 10.4997 10.7338 10.4997 9.33366 10.4997H5.66699C4.26686 10.4997 3.5668 10.4997 3.03202 10.7722C2.56161 11.0118 2.17916 11.3943 1.93948 11.8647C1.66699 12.3995 1.66699 13.0995 1.66699 14.4997V15.4997M13.3337 2.99967L15.0003 4.66634L18.3337 1.33301M10.417 4.24967C10.417 5.86051 9.11116 7.16634 7.50033 7.16634C5.8895 7.16634 4.58366 5.86051 4.58366 4.24967C4.58366 2.63884 5.8895 1.33301 7.50033 1.33301C9.11116 1.33301 10.417 2.63884 10.417 4.24967Z"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
