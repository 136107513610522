import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/store';

import { SingleProjectState } from 'redux/projects/types';
import { ProjectType } from 'types/Project';

export function useProjectsData() {
  const {
    data,
    employeeAssignment,
    employeeProject,
    loading,
    loaded,
    errored,
    error,
  } = useSelector((state: any) => state.projects);
  const singleProject: SingleProjectState = useSelector(
    (state: RootState) => state.singleProject
  );

  const [searchParams] = useSearchParams();

  const projectId =
    singleProject?.data?.id ?? (searchParams.get('edit-project') as string);
  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
    employeeProject,
    employeeAssignment,
    project: {
      projectId,
      data:
        singleProject?.data ??
        data?.data?.find((project: ProjectType) => project.id === projectId),
      loading: singleProject.loading,
      loaded: singleProject.loaded,
      errored: singleProject.errored,
      error: singleProject.error,
    },
  };
}
