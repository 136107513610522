import React, { useEffect, useState } from 'react';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import { OffboardingTaskStatus } from 'types/Offboarding';
import { Space } from 'antd';
import { CheckCircleOutlined, HourglassOutlined } from '@ant-design/icons';
import { getPayrollReportSingleEmployee } from 'api/payrollReportService';
import { convertDateToUTC } from 'utils/utilFunctions';
import { GenerateReportDate } from 'pages/PayrollReport/ExpandedTable/ExpandedTableStyles';
import Content from 'ui-v2/components/Content';
import moment from 'moment';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { PayrollReportColumns } from 'table-configuration/PayrollReportConfig';
import { PayrollReport } from 'types/PayrollReport';
import { useSearchParams } from 'react-router-dom';
import TabWrapper from './TabWrapper';
import ExpandedRow from '../../../../pages/PayrollReport/ExpandedTable/index';

export default function Balance({ onNext }: { onNext: () => void }) {
  const { getTabStatus } = useOffboardingData();
  const [searchParams] = useSearchParams();
  const [balance, setBalance] = useState<Array<any>>();
  const status = getTabStatus(OffboardingTaskType.BALANCE);
  const today = new Date();
  const employeeId = searchParams.get('employeeId') as string;
  const locationId = searchParams.get('locationId') as string;

  const getPayrollReport = async () => {
    const response = await getPayrollReportSingleEmployee({
      date: convertDateToUTC(today),
      tenantLocationId: locationId,
      nationalHolidays: [],
      employeeId,
    });
    setBalance([response?.data]);
  };

  useEffect(() => {
    getPayrollReport();
  }, []);

  return (
    <TabWrapper onNext={onNext} type={OffboardingTaskType.BALANCE}>
      <>
        {' '}
        {status === OffboardingTaskStatus.COMPLETED && (
          <div
            style={{
              maxWidth: '700px',
              margin: '50px auto',
              textAlign: 'center',
              pointerEvents:
                status === OffboardingTaskStatus.COMPLETED ? 'none' : 'auto',
            }}
          >
            <Space direction="vertical" size="large">
              <CheckCircleOutlined
                style={{ fontSize: '64px', color: '#52c41a' }}
              />
              <h2>Balance Settlement Completed</h2>
              <p>
                The final payroll for the employee has been successfully
                processed and completed. All due payments and settlements have
                been made.
              </p>
            </Space>
            <>
              <GenerateReportDate>
                Date:
                {moment(today).format('DD/MM/YYYY')}
              </GenerateReportDate>
              <DataGrid
                loading={false}
                columns={PayrollReportColumns}
                data={balance}
                totalPages={balance?.length}
                paginationName="payrollReport"
                rowKey={(record: PayrollReport) => record?.employee?.id}
                expandedRowRender={{
                  expandedRowRender: (record: PayrollReport) => (
                    <ExpandedRow report={record} />
                  ),
                }}
              />
            </>
          </div>
        )}
        {[
          OffboardingTaskStatus.PENDING,
          OffboardingTaskStatus.IN_PROGRESS,
        ].includes(status) && (
          <Content position="stretch">
            <>
              <div
                style={{
                  maxWidth: '600px',
                  margin: '50px auto',
                  textAlign: 'center',
                }}
              >
                <Space direction="vertical" size="large">
                  <HourglassOutlined
                    style={{ fontSize: '64px', color: '#faad14' }}
                  />
                  <h2>Balance Settlement Pending</h2>
                  <p>
                    The final payroll for the employee is pending. Please ensure
                    that all due payments and settlements are processed and
                    completed.
                  </p>
                </Space>
              </div>
              <>
                <GenerateReportDate>
                  Date:
                  {moment(today).format('DD/MM/YYYY')}
                </GenerateReportDate>
                <DataGrid
                  loading={false}
                  columns={PayrollReportColumns}
                  data={balance}
                  totalPages={balance?.length}
                  paginationName="payrollReport"
                  rowKey={(record: PayrollReport) => record?.employee?.id}
                  expandedRowRender={{
                    expandedRowRender: (record: PayrollReport) => (
                      <ExpandedRow report={record} />
                    ),
                  }}
                />
              </>
            </>
          </Content>
        )}
      </>
    </TabWrapper>
  );
}
