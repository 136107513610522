import { Reducer } from 'react';
import { Action } from 'redux';
import { EmployeeSkim, EmployeesSkimState } from './types';

const initialState: EmployeesSkimState = {
  data: [],
};

export interface EmployeeSkimReducedAction extends EmployeesSkimState, Action {}
const reducer: Reducer<EmployeesSkimState, EmployeeSkimReducedAction> = (
  state = initialState,
  action: EmployeeSkimReducedAction
) => {
  switch (action.type) {
    case EmployeeSkim.FETCH_EMPLOYEES_SKIM_START:
      state = { ...state };

      break;

    case EmployeeSkim.FETCH_EMPLOYEES_SKIM_SUCCESS:
      state = {
        ...state,
        data: action.data,
      };
      break;
    case EmployeeSkim.FETCH_EMPLOYEES_SKIM_ERRORED:
      state = {
        ...state,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as EmployeesSkimReducer };
