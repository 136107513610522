/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Result, Row, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { CompanyCreateDTO } from 'types/Company';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/lib/upload';
import {
  convertBase64,
  getValidFileContent,
  toastErrorMessages,
} from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form';
import CardItem from 'ui-v2/components/Card';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { getResendLinkToSetPasswordWithEmail } from 'api/authService';
import { companySectorSelector, prefixSelector } from 'utils/constants';
import ReCaptcha from 'ui-v2/components/ReCaptcha';
import { registerCompany } from 'api/openRoutesService';
import { t } from 'i18next';
import { getCountries } from 'api/countriesService';
import { City, ICity } from 'country-state-city';
import NoAuthPageLayout from '../NoAuthPageLayout';

const RESEND_CODE_TIMEOUT = 60;

interface CountryType {
  name: {
    common: string;
  };
  cca2: string;
  flags: {
    svg: string;
  };
}
export default function RegisterCompany() {
  const [form] = useForm();
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
    type?: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countDown, setCountDown] = useState(RESEND_CODE_TIMEOUT);
  const [formValues, setFormValues] = useState<CompanyCreateDTO>();
  const [validateStatus, setValidateStatus] = useState<any>();
  const [valdidateStatusCompany, setValidateStatusCompany] = useState<any>();
  const [countries, setCountries] = useState<Array<CountryType>>([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [cities, setCities] = useState<ICity[]>([]);

  const resend = async () => {
    if (!formValues?.email) return;

    await getResendLinkToSetPasswordWithEmail({
      email: formValues?.email,
    }).catch((error) => {
      toastErrorMessages(error);
      setResendDisabled(true);
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (registered) {
      timer = setTimeout(() => {
        setResendDisabled(false);
      }, RESEND_CODE_TIMEOUT * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [registered]);

  const fetchCountries = async () => {
    getCountries()
      .then(({ data }) => {
        const sortedData = data.sort(
          (a: { name: { common: string } }, b: { name: { common: string } }) =>
            a.name.common.localeCompare(b.name.common)
        );

        setCountries(sortedData);
      })
      .catch((error) => {
        toastErrorMessages(error);
      });
  };

  const fetchCities = (countryName: string) => {
    const country = countries.find((c) => c.name.common === countryName);
    if (country) {
      const cities = City.getCitiesOfCountry(country.cca2);
      if (cities) {
        setCities(cities);
      }
    }
  };

  const getCountryCode = (country: any) => {
    const suffix =
      country?.idd?.suffixes && country?.idd?.suffixes[0]
        ? country?.idd?.suffixes[0]
        : '';
    return [country?.idd?.root, suffix].join('');
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountries();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchCities(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    form.setFieldsValue({
      phoneNumberPrefix: getCountryCode(countries[0]),
    });
  }, [countries]);

  const onCountrySelect = () => {
    setSelectedCountry(form.getFieldValue('country'));

    const country = countries.find(
      (country) => country?.name?.common === form.getFieldValue('country')
    );

    if (country) {
      form.setFieldsValue({ phoneNumberPrefix: getCountryCode(country) });
    }
  };

  const onFinish = (values: CompanyCreateDTO) => {
    if (!submitted) {
      setSubmitted(true);
      return;
    }
    setFormValues(values);
    setValidateStatus('');
    setValidateStatusCompany('');
    setLoading(true);
    setSubmitted(false);

    const companyValues = {
      ...values,
      logoName: fileToUpload?.name ?? '',
      logoContent: fileToUpload?.content ?? '',
      phoneNumber: values.phoneNumber.toString(),
      recaptchaValue: values.recaptchaValue,
    };
    setLoading(false);

    delete companyValues.upload;

    registerCompany(companyValues)
      .then((response) => {
        setLoading(false);
        form.resetFields();
        setRegistered(true);

        const decrementCountdown = () =>
          setCountDown((countDown) => countDown - 1);
        const interval = setInterval(() => {
          decrementCountdown();
        }, 1000);
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFileToUpload({ name: file.name, content: validBase64, type: file.type });
    return false;
  }
  const CompanyFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 6,
          offset: 0,
          name: 'name',
          label: t('companyName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'firstName',
          label: t('firstName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('firstName'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'lastName',
          label: t('lastName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('lastName'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: t('companyEmail'),
          type: 'input',
          valdidateStatus: valdidateStatusCompany,
          help: valdidateStatusCompany && t('companyEmailExists'),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'address',
          label: t('address'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 6,
          offset: 0,
          name: 'sector',
          label: t('industry'),
          type: 'input',
          selectOptions: companySectorSelector,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'website',
          label: t('companyWebsite'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'country',
          label: t('country'),
          type: InputTypes.SELECT,
          showSearch: true,
          onSelect: onCountrySelect,
          onSelectChange: fetchCities,
          selectOptions: countries?.map((country: CountryType) => ({
            id: country?.name?.common,
            label: (
              <div>
                <img
                  src={country.flags.svg}
                  alt={country.cca2}
                  style={{ width: 20, marginRight: 10 }}
                />
                {country.name.common}
              </div>
            ),
            value: country?.name?.common,
          })),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 6,
          offset: 0,
          name: 'city',
          label: t('city'),
          showSearch: true,
          type: InputTypes.SELECT,
          selectOptions: cities.map((city: ICity) => ({
            id: `${city.name}, ${city.stateCode}`,
            label: `${city.name}, ${city.stateCode}`,
            value: `${city.name}, ${city.stateCode}`,
          })),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: 'selectPrefix',
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: countries?.map((country: any) => {
              const countryCode = getCountryCode(country);

              return {
                id: countryCode,
                value: countryCode,
                label: countryCode,
              };
            }),
            placeholder: '',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'zipCode',
          label: t('zipCode'),
          type: 'input',
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('companyDescription'),
          type: InputTypes.TEXTAREA,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'upload',
          label: t('uploadCompanyLogo'),
          type: 'upload',
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (file: any) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
          },
        },
      ],
      [
        {
          col: 0,
          offset: 0,
          name: 'recaptchaValue',
          label: '',
          type: 'input',
        },
      ],
    ],
    [validateStatus, valdidateStatusCompany, countries, selectedCountry, cities]
  );

  const onResendCode = async () => {
    if (formValues) resend();
    setCountDown(RESEND_CODE_TIMEOUT);

    const decrementCountdown = () => setCountDown((countDown) => countDown - 1);
    const interval = setInterval(() => {
      decrementCountdown();
    }, 1000);

    setResendDisabled(true);
  };

  const onRecaptchaVerify = (token: string) => {
    if (token) {
      form.setFieldsValue({
        recaptchaValue: token,
      });
      form.submit();
    }
  };

  return (
    <NoAuthPageLayout>
      <ReCaptcha verify={submitted} onVerify={onRecaptchaVerify}>
        <Row justify="center">
          <Col xxl={12} xl={12} md={18} sm={20}>
            {!registered ? (
              <CardItem title={t('registerCompany')}>
                <>
                  <GenericForm
                    formConfiguration={CompanyFormConfiguration}
                    onFinish={onFinish}
                    form={form}
                    loading={loading}
                  >
                    <FormItemStyled style={{ marginTop: 30 }}>
                      <Row>
                        <Col span={11}>
                          <Row justify="center">
                            <Col span={8} offset={8}>
                              <Typography.Link href="login">
                                {t('login')}
                              </Typography.Link>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={11} offset={2}>
                          <StyledButton type="primary" htmlType="submit">
                            {t('save')}
                          </StyledButton>
                        </Col>
                      </Row>
                    </FormItemStyled>
                  </GenericForm>
                </>
              </CardItem>
            ) : (
              <div style={{ minHeight: '100vh' }}>
                <Result
                  status="success"
                  title={t('successfullyRegisteredCompany!')}
                  subTitle={t('pendingApproval')}
                />
                <Row justify="center">
                  <Col span={8}>
                    <StyledButton
                      type="primary"
                      onClick={onResendCode}
                      disabled={resendDisabled}
                    >
                      {`${t('resendVerificationEmail')} ${
                        countDown > 0 ? `(${countDown})` : ''
                      }`}
                    </StyledButton>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </ReCaptcha>
    </NoAuthPageLayout>
  );
}
