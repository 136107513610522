import styled from 'styled-components';
import { Alert, Typography } from 'antd';

export const Banner = styled(Alert)`
  width: 100%;
  height: 45px;
  margin-bottom: 5px;
  margin-top: -25px;
`;
export const BannerLink = styled(Typography.Text)`
  text-decoration: underline;
  font-weight: 900;
`;
