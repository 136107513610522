import { Reducer } from 'react';
import { Action } from 'redux';
import { IdeaboxActions, IdeaboxSingleState, IdeaboxState } from './types';

const initialState: IdeaboxState = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const IdeaboxReducer: Reducer<IdeaboxState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case IdeaboxActions.FETCH_IDEABOX_START:
      state = { ...state, loading: true };
      break;
    case IdeaboxActions.FETCH_IDEABOX_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case IdeaboxActions.FETCH_IDEABOX_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case IdeaboxActions.CLEAR_ALL_IDEABOX:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleEmployeeState: IdeaboxSingleState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SingleIdeaboxReducer: Reducer<IdeaboxSingleState, Action> = (
  state = initialSingleEmployeeState,
  action: any
) => {
  switch (action.type) {
    case IdeaboxActions.FETCH_SINGLE_IDEABOX_START:
      state = { ...state, loading: true };
      break;
    case IdeaboxActions.FETCH_SINGLE_IDEABOX_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case IdeaboxActions.FETCH_SINGLE_IDEABOX_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case IdeaboxActions.CLEAR_SINGLE_IDEABOX:
      state = initialSingleEmployeeState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { IdeaboxReducer, SingleIdeaboxReducer };
