import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getTraining } from 'api/trainingService';
import { useEffect, useState } from 'react';
import useHeader from './useHeader';

export function useTrainingsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.trainings
  );

  const { data: training, loading: trainingLoading } = useSelector(
    (state: any) => state.singleReducer
  );
  const [, setTraining] = useState<any>();
  const [searchParams] = useSearchParams();
  const { entity } = useHeader();

  const trainingId = searchParams.get('id');

  async function fetchTraining(trainingId: string | null) {
    if (!trainingId) return;
    getTraining(trainingId)
      .then((response) => {
        setTraining(response.data);
      })
      .catch(() => {
        toast.error("Something went wrong! Can't get training!");
      });
  }

  useEffect(() => {
    if (trainingId && entity === 'trainings') {
      fetchTraining(trainingId);
    }
  }, [trainingId, entity]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    training,
    trainingId,
    trainingLoading,
    loading,
    loaded,
    errored,
    error,
    fetch,
  };
}
