import { useDispatch } from 'react-redux';
import { createQuestionnaire } from 'redux/questionnaireQuestion/actions';
import { CreateQuestionnaireFormDto } from 'types/Questionnaires';

export const useCreateReview = () => {
  const dispatch = useDispatch();

  const handleCreateReview = async (
    formValues: CreateQuestionnaireFormDto,
    currentEmployee: { id: string },
    form: any,
    nextEmployee: () => void
  ) => {
    await dispatch(createQuestionnaire(formValues, currentEmployee));
    form.resetFields();
    nextEmployee();
  };

  return { handleCreateReview };
};
