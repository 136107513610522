import { Tabs } from 'antd';
import styled from 'styled-components';

export const TabsStyled = styled(Tabs)<{ compact?: boolean }>(
  ({ theme, compact }) => ({
    '.ant-tabs-nav': {
      '::before': {
        display: 'none',
      },
      ...{ ...(compact && { width: 'fit-content' }) },
      '.ant-tabs-nav-wrap': {
        borderRadius: compact ? '0.5rem' : '0.375rem',
        ...{
          ...(compact && {
            border: `1px solid ${theme.colors.palette.primary.gray[300]}`,
            borderRadius: '0.5rem',
          }),
        },
        '*': {
          margin: 'unset',
          padding: 'unset',
          '.ant-tabs-ink-bar': {
            display: 'none',
          },
          ...theme.typography.body.semibold.md,
          ...{ ...(!compact && { borderRadius: '0.375rem' }) },
          '.ant-tabs-tab-btn': {
            color: theme.colors.palette.primary.gray[800],
            backgroundColor: theme.colors.palette.secondary.grayNeutral[50],
            padding: '0.5rem 0.75rem 0.5rem 0.75rem',
          },
        },
        '.ant-tabs-nav-list': {
          gap: compact ? 'unset' : '0.25rem',
        },
        '.ant-tabs-tab-active': {
          backgroundColor: theme.colors.palette.primary.gray[200],
          '.ant-tabs-tab-btn': {
            color: theme.colors.palette.primary.gray[700],
            backgroundColor: theme.colors.palette.primary.gray[200],
            ...{ ...(compact && { borderRadius: 'unset' }) },
          },
        },
      },
    },
  })
);
