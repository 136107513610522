import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { VendorQuestionnaireAsyncAction } from 'redux/vendorQuestionnaire/actions';
import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import VendorQuestionnaireDetailsContent from './VendorQuestionnaireDetailsContent';

const VendorQuestionnaireDetails: React.FC = () => {
  const dispatch = useDispatch();
  const {
    questionnaireCategory: { loaded, loading },
  } = useVendorQuestionnaireData();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(VendorQuestionnaireAsyncAction.clearCategoriesState());
    return () => {
      dispatch(VendorQuestionnaireAsyncAction.clearSingleCategorieState());
    };
  }, []);

  useEffect(() => {
    if (!loading && !loaded && searchParams.get('id')) {
      dispatch(
        VendorQuestionnaireAsyncAction.fetchCategorie({
          id: searchParams.get('id') as string,
        })
      );
    }
  }, [searchParams, loaded, loading]);

  return <VendorQuestionnaireDetailsContent />;
};

export default VendorQuestionnaireDetails;
