export enum OffboardingTaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface ICreateOffBoardingDTO {
  employeeId: string;
  successorEmployeeId?: string;
  workPositionId: string;
  reviewerId?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface IUpdateOffboardingTaskStatusDTO {
  offboardingId: string;
  taskId: string;
  status: OffboardingTaskStatus;
}

export interface OffboardingTask {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  status: string;
  deletedAt: string | null;
}

export interface Offboarding {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  startDate: string;
  endDate: string;
  successorId: string | null;
  exitInterviewId: string;
  deletedAt: string | null;
  offBoardingTasks: OffboardingTask[];
}

export interface SuccessorDto {
  employeeId: string;
}

export interface UpdateTaskStatusDto {
  offboardingId: string;
  taskId: string;
  status: OffboardingTaskStatus;
}
export enum OffboardingStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETED = 'COMPLETED',
}
