import { MetaPagination } from 'types/GridTable';
import { CreateIpAccessDTO } from 'types/IpAccess';

export enum IpAccess {
  FETCH_IP_ACCESS_START = 'FETCH_IP_ACCESS_START',
  FETCH_IP_ACCESS_SUCCESS = 'FETCH_IP_ACCESS_SUCCESS',
  FETCH_IP_ACCESS_ERRORED = 'FETCH_IP_ACCESS_ERRORED',
}

export interface IpAccessState {
  data: Array<CreateIpAccessDTO>;
  meta: Array<MetaPagination>;

  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
