import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'App';
import store from 'redux/store';

import 'index.css';
import 'antd/dist/antd.min.css';
import { ThemeProvider } from 'styled-components';
import { appTheme } from 'appTheme';
import AppConfig from 'AppConfig';

const app = (
  <Provider store={store}>
    <AppConfig>
      <ThemeProvider theme={appTheme}>
        <App />
      </ThemeProvider>
    </AppConfig>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
