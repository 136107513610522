import React, { useCallback, useEffect, useState } from 'react';

import { EmployeeType } from 'types/Employee';
import { Col, Empty, Row } from 'antd';
import { getAssignmentsByEmployeeId } from 'api/assignmentService';
import { Assignment } from 'types/Assignment';
import { toastErrorMessages } from 'utils/utilFunctions';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import ProjectEmployeeInfo from './ProjectEmployeeInfo';
import ProjectEmployeeDrilldown from './ProjectEmployeeDrilldown';
import {
  StyledProjectEmployeesCol,
  StyledProjectEmployeesDrilldownIcon,
} from './StyledProjectEmployees';

interface ProjectEmployeeProps {
  isLast?: boolean;
  employee: EmployeeType;
}

const ProjectEmployee: React.FC<ProjectEmployeeProps> = ({
  isLast,
  employee,
}) => {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const [collapsed, setCollapsed] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [assignments, setAssignments] = useState<Assignment[]>([]);

  const fetch = useCallback(() => {
    if (fetched) {
      return;
    }
    getAssignmentsByEmployeeId(employee.id)
      .then((res) => {
        if (res.status === 200) {
          setAssignments(res.data);
        }
      })
      .catch(toastErrorMessages)
      .finally(() => {
        setFetched(true);
      });
  }, [employee.id, fetched]);

  useEffect(() => {
    if (collapsed) {
      fetch();
    }
  }, [collapsed]);

  const toogleDrilldownCollapse = useCallback(() => {
    setCollapsed((collapsed) => !collapsed);
  }, [collapsed]);

  return (
    <StyledProjectEmployeesCol span={24} isLast={isLast}>
      {authUserRole === AUTH_ROLES.EMPLOYEE ? (
        ''
      ) : (
        <StyledProjectEmployeesDrilldownIcon
          collapsed={collapsed}
          onClick={toogleDrilldownCollapse}
        />
      )}
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <ProjectEmployeeInfo employee={employee} />
        </Col>

        {collapsed && assignments?.length ? (
          <Col span={24}>
            <ProjectEmployeeDrilldown assignments={assignments} />
          </Col>
        ) : null}

        {collapsed && fetched && !assignments?.length && (
          <Col span={24}>
            <Empty description="There are no assignments yet" />
          </Col>
        )}
      </Row>
    </StyledProjectEmployeesCol>
  );
};

export default ProjectEmployee;
