import { Row, Col } from 'antd';
import React from 'react';

import Content from 'ui-v2/components/Content';
import * as Styled from './FilesStyles';

export default function Files() {
  return (
    <Content position="stretch">
      <Row
        justify="center"
        style={{
          padding: 24,
        }}
      >
        <Col>
          <Styled.WorkInProgressLabel>
            Work in progress...
          </Styled.WorkInProgressLabel>
        </Col>
      </Row>
    </Content>
  );
}
