import { Button, Col, Row, Skeleton } from 'antd';
import { EdititingIcon } from 'Icons/EditIcon';
import { RateLargeIcon } from 'Icons/RateLargeIcon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidate } from 'redux/candidates/actions';
import { InterviewsState } from 'redux/candidates/types';
import { RootState } from 'redux/store';
import { CandidateOpenPositionObj } from 'types/Candidates';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { useTranslation } from 'react-i18next';

import CardItem from '../Card';
import {
  ButtonStyle,
  Link as StyledLink,
} from '../WorkExperience/WorkExperienceStyles';
import CandidateScore from './CandidateScore';
import * as Styled from './ScoreboardStyles';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';

interface ScoreboardProps {
  isDisabled: boolean;
}

export default function Scoreboard({ isDisabled }: ScoreboardProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    candidate,
    candidateLoaded: loaded,
    loading,
    selectedCandidateOpenPosition,
  } = useCandidateData();
  const [scoreValue, setScoreValue] = useState<number | undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: interviews } = useSelector(
    (state: RootState) => state.interviews as InterviewsState
  );

  useEffect(() => {
    const openPos = candidate?.candidateOpenPositions?.find(
      (item: CandidateOpenPositionObj) =>
        item.id === selectedCandidateOpenPosition?.data?.id
    );
    setScoreValue(openPos?.score);
  }, [candidate, selectedCandidateOpenPosition?.data?.id]);

  const onAddInterview = () => {
    document.getElementById('schedule-interview-popover')?.click();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!candidate) return null;

  return (
    <CardItem
      title={t('scoreboard')}
      disabled={isDisabled}
      minHeight={70}
      rightButton={
        loaded && scoreValue && !isEditing ? (
          <Row justify="end">
            <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
              <Col>
                <ButtonStyle
                  icon={<EdititingIcon />}
                  size="large"
                  onClick={() => setIsEditing(true)}
                >
                  {t('edit')}
                </ButtonStyle>
              </Col>
            </RoleBasedButton>
          </Row>
        ) : undefined
      }
    >
      <>
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={{ rows: 6, width: '100%' }}
        >
          {scoreValue && !isEditing && (
            <Row gutter={[16, 0]} align="middle">
              <Col>
                <RateLargeIcon />
              </Col>
              <Col>
                <Styled.RateLabel>{scoreValue || 0}</Styled.RateLabel>
              </Col>
            </Row>
          )}

          {(isEditing || !scoreValue) && (
            <Styled.RowItem gutter={[0, 5]} justify="center">
              <Col span={20}>
                <CandidateScore
                  id={selectedCandidateOpenPosition?.data?.id}
                  score={scoreValue || 0}
                  disabled={interviews?.interviews?.length === 0}
                  onScored={() => {
                    setIsEditing(false);
                    dispatch(fetchCandidate(candidate?.id));
                  }}
                />
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Styled.Label>{t('ratethisCandidate')}</Styled.Label>
                </Row>
              </Col>
              {interviews.interviews.length === 0 && (
                <Col>
                  <StyledLink>
                    {t('youNeedTo')}
                    <Button
                      style={{ padding: '5px', fontStyle: 'italic' }}
                      type="link"
                      onClick={onAddInterview}
                    >
                      {t('scheduleAnInterviewFirst.')}
                    </Button>
                  </StyledLink>
                </Col>
              )}
            </Styled.RowItem>
          )}
        </Skeleton>
      </>
    </CardItem>
  );
}
