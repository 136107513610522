import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PurchaseOrderState } from 'redux/purchaseOrders/types';
import { RootState } from 'redux/store';
import { PurchaseOrderType } from 'types/PurchaseOrder';

export function usePurchaseOrdersData() {
  const {
    data,
    loading,
    loaded,
    errored,
    error,
    purchaseOrderDetail,
  }: PurchaseOrderState = useSelector(
    (state: RootState) => state.purchaseOrders
  );
  const [searchParams] = useSearchParams();

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    purchaseOrder:
      (purchaseOrderDetail as PurchaseOrderType) ||
      data?.data?.find(
        (item: PurchaseOrderType) => item.id === searchParams.get('id')
      ),
    loading,
    loaded,
    error,
    errored,
    purchaseOrderDetail,
  };
}
