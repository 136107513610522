import { Col, Form, Row } from 'antd';
import { createDepartmentsApi, updateDepartment } from 'api/departmentService';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  clearSingleDepartment,
  fetchDepartments,
  fetchSingleDepartment,
} from 'redux/departments/actions';
import { DepartmentDTO } from 'types/Department';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useDepartmentData } from 'ui-v2/hooks/useDepartmentData';
import { checkHrDutiesCompletion } from 'redux/hrDuties/actions';
import { isAdmin, isHR } from 'utils/utilFunctions';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import { isFormEdited } from '../Form/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function DepartmentForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { department } = useDepartmentData();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authUser);
  const { t } = useTranslation();

  function onFormFinish(values: DepartmentDTO) {
    const valuesToSend: DepartmentDTO = {
      ...values,
      name: values.name,
    };
    setLoading(true);

    if (department?.department_id || department?.id) {
      if (
        isFormEdited({
          formValues: valuesToSend,
          valuesToCheck: department,
          entity: 'department',
        })
      ) {
        closeModal();
        setLoading(false);
        return;
      }
      updateDepartment(
        department?.department_id || department?.id,
        valuesToSend
      )
        .then((res) => {
          toast.success(t('Department updated successfully!'));
          dispatch(
            fetchSingleDepartment(department?.department_id || department?.id)
          );
          dispatch(fetchDepartments({ page: 1 }));
          if (res.data?.id) {
            closeModal();
            navigate(`/departments/department-details?id=${res.data?.id}`);
          } else {
            navigate('/departments');
          }
        })
        .catch(() => {
          toast.error(t('Failed to update department!'));
        })
        .finally(() => {
          dispatch(clearSingleDepartment());
          setLoading(false);
        });

      return;
    }

    createDepartmentsApi(valuesToSend)
      .then((res) => {
        toast.success(t('Department created successfully!'));
        dispatch(fetchDepartments({ page: 1 }));
        if (res.data?.id) {
          closeModal();
          navigate(`/departments/department-details?id=${res.data?.id}`);
          if (isAdmin(auth) || isHR(auth)) {
            dispatch(checkHrDutiesCompletion());
          }
        } else {
          navigate('/departments');
        }
      })
      .catch(() => {
        toast.error(t('Failed to create department!'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (searchParams.get('add-save-department') === 'true') {
      searchParams.delete('add-save-department');
      form.submit();
      setSearchParams(searchParams);
    }
    if (searchParams.get('edit-department-save') === 'true') {
      searchParams.delete('edit-department-save');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const DepartmentFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('departmentName'),
          type: InputTypes.INPUT,
          defaultValue: department?.department_name || department?.name,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [department]
  );

  return (
    <GenericModal
      title={
        department?.department_id || department?.id
          ? t('editDepartment')
          : t('addDepartment')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={DepartmentFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default DepartmentForm;
