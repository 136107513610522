/* eslint-disable no-use-before-define */
import { VendorType } from './Vendor';

export enum VendorQuestionnaireAnswer {
  YES = 'yes', // scored as 1 when question priority is standard and 2 when question priority is critical
  NO = 'no', // scored as 0
  NOT_APPLICABLE = 'not_applicable', // scored as -1
}

export enum VendorQuestionPriority {
  STANDARD = 'standard',
  CRITICAL = 'critical',
}

export type VendorQuestionnaireCreateDto = {
  question: string;
  priority: VendorQuestionPriority;
  vendorQuestionnaireCategoryId: string;
};

export type VendorQuestionnaireUpdateDto = {
  question?: string;
  priority?: VendorQuestionPriority;
  vendorQuestionnaireCategoryId?: string;
};

export interface VendorQuestionnaire {
  id: string;
  question: string;
  priority: VendorQuestionPriority;
  vendorQuestionnaireCategory: VendorQuestionnaireCategory;
  vendorQuestionnaireScore: VendorQuestionnaireScore[];
}

export interface VendorQuestionnaireScore {
  score: number;
  answer: VendorQuestionnaireAnswer;
  vendor: VendorType;
  vendorQuestionnaire: VendorQuestionnaire;
}

export interface VendorQuestionnaireCategory {
  id: string;
  name: string;
  vendorQuestionnaireCount?: number;
  vendorQuestionnaire?: VendorQuestionnaire[];
  createdAt: Date;
}

export interface VendorQuestionnaireCategoryCreateDto {
  name?: string;
}

export interface VendorQuestionnaireCategoryUpdateDto {
  name?: string;
}
