import {
  EmployeeNotesType,
  EmployeeNotesUpdateType,
} from 'types/EmployeeNotes';
import agent from 'api/agent';
import { apiURLs } from './constants';

export const getAllEmployeeNotes = () => agent.get(apiURLs.employeeNote);

export const createEmployeeNotes = (data: EmployeeNotesType) =>
  agent.post(apiURLs.employeeNote, data);
export const getEmployeeNotes = (id: string) =>
  agent.get(`${apiURLs.employeeNote}/${id}`);
export const getOneEmployeeNotes = async (id: string) =>
  agent.get(`${apiURLs.employeeNote}/${id}`);

export const updateEmployeeNotes = async (
  id: string,
  values: EmployeeNotesUpdateType
) => agent.put(`${apiURLs.employeeNote}/${id}`, values);
