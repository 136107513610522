import agent from './agent';
import { apiURLs } from './constants';

export const getAllDashboardStats = () =>
  agent.get(`${apiURLs.dashboard}/stats`);

export const showDashboardPieStats = () =>
  agent.get(`${apiURLs.dashboard}/stats/pie`);

export const getAllDashboardBubbleStats = () =>
  agent.get(`${apiURLs.dashboard}/bubbleChart`);

export const getBarChartStats = () =>
  agent.get(`${apiURLs.dashboard}/stats/bar`);

export type DashboardAssetCountDTO = {
  purchaseDate?: string;
  location?: string;
  price?: number;
};

export const showDashboardAssetPieStats = (payload: DashboardAssetCountDTO) =>
  agent.post(`${apiURLs.asset}/getAssetsCountByStatus`, payload);
