import agent from 'api/agent';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllEmployeeEmploymentType = () =>
  agent.get(apiURLs.employmentType);

export const getAllEmploymentTypes = (queryParams?: QueryParamsType) =>
  agent.post(
    `${apiURLs.employmentType}/get`,
    searchFields(queryParams, apiURLs.employmentType)
  );

export const getEmploymentTypeById = (id: string) =>
  agent.get(`${apiURLs.employmentType}/${id}`);
