export enum TrackingFilterPanelLabel {
  FILTERS = 'FILTERS',
  TEAM = 'Team',
  PROJECT = 'Project',
  DESCRIPTION = 'Description',
}
export const TrackingFilterPanelPopoverInnerOverlayStyle = {
  width: '400px',
  borderRadius: '8px',
  padding: '0px',
  boxShadow:
    '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  border: '1px solid #EAECF0',
};
