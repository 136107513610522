import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useHeader from 'ui-v2/hooks/useHeader';
import Content from 'ui-v2/components/Content';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { VendorQuestionnaireAsyncAction } from 'redux/vendorQuestionnaire/actions';
import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import VendorQuestionnaireForm from 'components/NewForms/VendorQuestionnaireForm/VendorQuestionnaireForm';
import { VendorQuestionnaireApiClient } from 'api/vendorQuestionnaireService';
import { toastErrorMessages } from 'utils/utilFunctions';

const VendorQuestionnaireList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { take, columns } = useHeader();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const {
    questionnaireCategory: {
      list: { data, meta },
      loading,
    },
  } = useVendorQuestionnaireData();

  useEffect(() => {
    dispatch(VendorQuestionnaireAsyncAction.clearCategoriesState());
  }, []);

  const toogleModalVisibility = useCallback(() => {
    setModalVisible(!modalVisible);
    if (modalVisible) {
      searchParams.delete('edit-vendor-questionnaire');
      setSearchParams(searchParams);
    }
  }, [modalVisible, searchParams]);

  const onDelete = useCallback(
    async (id: string) => {
      VendorQuestionnaireApiClient.deleteCategorie({ id })
        .then((res) => {
          if (res.status) {
            dispatch(
              VendorQuestionnaireAsyncAction.fetchCategories({
                pageOptions: { take, page: data?.length > 1 ? currentPage : 1 },
              })
            );
            toast.success(t('vendorQuestionnaireCategoryDeletedSuccessfully'));
          }
        })
        .catch((e) => toastErrorMessages(e));
    },
    [currentPage, take, data]
  );

  useEffect(() => {
    if (searchParams.get('add-vendor-questionnaire') === 'true') {
      searchParams.delete('add-vendor-questionnaire');
      setSearchParams(searchParams);
      toogleModalVisibility();
    }
    if (searchParams.get('edit-vendor-questionnaire')) {
      toogleModalVisibility();
    }
    if (searchParams.get('delete-vendor-questionnaire')) {
      onDelete(searchParams.get('delete-vendor-questionnaire') as string);
      searchParams.delete('delete-vendor-questionnaire');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      VendorQuestionnaireAsyncAction.fetchCategories({ pageOptions: { take } })
    );
  }, [take]);

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(
        VendorQuestionnaireAsyncAction.fetchCategories({
          pageOptions: { take, page },
        })
      );
    },
    [take]
  );

  const onSearch = useCallback(
    (searchTerm: string) => {
      dispatch(
        VendorQuestionnaireAsyncAction.fetchCategories({
          pageOptions: { take },
          filterOptions: {
            filterFields: ['name'],
            filterText: searchTerm,
          },
        })
      );
    },
    [take]
  );

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={data}
          totalItems={meta?.itemCount}
          currentPage={meta?.page}
          totalPages={meta?.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
          paginationName="VendorCategories"
        />
        {modalVisible && (
          <VendorQuestionnaireForm
            open={modalVisible}
            closeModal={toogleModalVisibility}
          />
        )}
      </>
    </Content>
  );
};

export default VendorQuestionnaireList;
