import React from 'react';

export const TimeEntryIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 7V4.2C15.5 3.0799 15.5 2.51984 15.282 2.09202C15.0903 1.71569 14.7843 1.40973 14.408 1.21799C13.9802 1 13.4201 1 12.3 1H4.7C3.5799 1 3.01984 1 2.59202 1.21799C2.21569 1.40973 1.90973 1.71569 1.71799 2.09202C1.5 2.51984 1.5 3.0799 1.5 4.2V11.8C1.5 12.9201 1.5 13.4802 1.71799 13.908C1.90973 14.2843 2.21569 14.5903 2.59202 14.782C3.01984 15 3.5799 15 4.7 15H7.5M11.5 14L13.5 16L18 11.5M10.7 21H18.3C19.4201 21 19.9802 21 20.408 20.782C20.7843 20.5903 21.0903 20.2843 21.282 19.908C21.5 19.4802 21.5 18.9201 21.5 17.8V10.2C21.5 9.07989 21.5 8.51984 21.282 8.09202C21.0903 7.71569 20.7843 7.40973 20.408 7.21799C19.9802 7 19.4201 7 18.3 7H10.7C9.57989 7 9.01984 7 8.59202 7.21799C8.21569 7.40973 7.90973 7.71569 7.71799 8.09202C7.5 8.51984 7.5 9.07989 7.5 10.2V17.8C7.5 18.9201 7.5 19.4802 7.71799 19.908C7.90973 20.2843 8.21569 20.5903 8.59202 20.782C9.01984 21 9.57989 21 10.7 21Z"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
