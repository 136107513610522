import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import AssetCategoryAction from 'components/Actions/AssetCategoryAction';

export const AssetCategoriesColumns = [
  {
    title: i18next.t('categoryName'),
    dataIndex: 'categoryName',
    key: 'categoryName',
    ellipsis: true,
    sorter: (a: any, b: any) => a.categoryName.localeCompare(b.categoryName),
    render: (_: string, item: any) => (
      <Styled.TextBold>{item?.categoryName}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => <AssetCategoryAction id={row.id} />,
    default: false,
    order: 2,
  },
];
