import { useEffect, useMemo, useState } from 'react';
import { showDashboardAssetPieStats } from 'api/dashboardStats';
import { DashboardPieStatsAssetType } from 'types/DashboardStats';
import { ChartData } from 'chart.js';
import { t } from 'i18next';
import { FAVORITE_PALETTE, PALETE } from 'components/styledComponents/colors';

export function useAssetStatsData() {
  const [dashboardPieStatsAsset, setDashboardPieStatsAsset] =
    useState<DashboardPieStatsAssetType[]>();
  const [loading, setLoading] = useState(true);

  async function fetchDashboardPieStatsAsset() {
    try {
      const { data } = await showDashboardAssetPieStats({});
      setDashboardPieStatsAsset(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard pie stats:', error);
    }
  }

  useEffect(() => {
    fetchDashboardPieStatsAsset();
  }, []);

  const data: ChartData<'pie', number[], string> = useMemo(
    () => ({
      labels: dashboardPieStatsAsset
        ? dashboardPieStatsAsset.map((item: any) => item.asset_status)
        : [],
      datasets: [
        {
          label: t('openPositions'),
          data: dashboardPieStatsAsset
            ? dashboardPieStatsAsset.map((item: any) => item.asset_count)
            : [],
          backgroundColor: dashboardPieStatsAsset
            ? dashboardPieStatsAsset.map(
                (_, index) =>
                  FAVORITE_PALETTE[index] ??
                  Object.values(PALETE)[
                    Math.floor(Math.random() * Object.values(PALETE).length)
                  ]
              )
            : [],
          borderWidth: 1,
        },
      ],
    }),
    [dashboardPieStatsAsset]
  );

  return { dashboardPieStatsAsset, loading, data };
}
