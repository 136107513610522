import React from 'react';
import { RulesType } from 'types/FormTypes';
import { FormItemStyled, StyledTextArea } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  hideLabel?: boolean;
  defaultValue?: string;
  inputProps?: {
    rows?: number;
  };
  hint?: React.ReactNode | string;
}
const TextAreaComponent: React.FC<Props> = ({
  placeholder,
  name,
  rules,
  hideLabel,
  defaultValue,
  inputProps,
  hint,
}) => (
  <FormItemStyled
    name={name}
    label={!hideLabel && placeholder}
    rules={rules}
    {...(hint && { tooltip: hint })}
  >
    <StyledTextArea
      {...inputProps}
      defaultValue={defaultValue && defaultValue}
      placeholder={placeholder}
    />
  </FormItemStyled>
);

TextAreaComponent.defaultProps = {
  rules: [],
  hideLabel: false,
  inputProps: {
    rows: 4,
  },
};

export default TextAreaComponent;
