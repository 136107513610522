import React, { useState } from 'react';
import Container from './SetEmployeePasswordContainer';
import EmployeePasswordViewSwitcher from './EmployeePasswordViewSwitcher';

export default function SetEmployeePassword() {
  const [status, setStatus] = useState<string>();

  return (
    <Container>
      <EmployeePasswordViewSwitcher status={status} setStatus={setStatus} />
    </Container>
  );
}
