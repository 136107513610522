import { CreateQuestionnaireFormDto } from 'types/Questionnaires';

export enum Questionnaire {
  CREATE_QUESTIONNAIRE_QUESTION_START = 'CREATE_QUESTIONNAIRE_QUESTION_START',
  CREATE_QUESTIONNAIRE_QUESTION_SUCCESS = 'CREATE_QUESTIONNAIRE_QUESTION_SUCCESS',
  CREATE_QUESTIONNAIRE_QUESTION_ERRORED = 'CREATE_QUESTIONNAIRE_QUESTION_ERRORED',
}

export interface ReviewQuestionState {
  data: CreateQuestionnaireFormDto | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
