import { Reducer } from 'react';
import { Action } from 'redux';

import {
  PurchaseRequest,
  PurchaseRequestState,
  PurchaseRequestsState,
} from './types';

const initialPurchaseState: PurchaseRequestState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};
const PurchaseRequestReducer: Reducer<PurchaseRequestState, Action> = (
  state = initialPurchaseState,
  action: any
) => {
  switch (action.type) {
    case PurchaseRequest.FETCH_PURCHASE_REQUEST_START:
      state = { ...state, loading: true };
      break;
    case PurchaseRequest.FETCH_PURCHASE_REQUEST_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case PurchaseRequest.FETCH_PURCHASE_REQUEST_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case PurchaseRequest.CLEAR_WPURCHASE_REQUEST_STATE:
      state = initialPurchaseState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialPurchaseRequestState: PurchaseRequestsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const PurchaseRequestsReducer: Reducer<PurchaseRequestsState, Action> = (
  state = initialPurchaseRequestState,
  action: any
) => {
  switch (action.type) {
    case PurchaseRequest.FETCH_PURCHASE_REQUESTS_START:
      state = { ...state, loading: true };
      break;
    case PurchaseRequest.FETCH_PURCHASE_REQUESTS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case PurchaseRequest.FETCH_PURCHASE_REQUESTS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case PurchaseRequest.CLEAR_PURCHASE_REQUESTS_STATE:
      state = initialPurchaseRequestState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { PurchaseRequestsReducer, PurchaseRequestReducer };
