import { Employees } from 'redux/employees/types';

export enum EmployeesReviews {
  FETCH_REVIEWS_QUESTIONS_START = 'FETCH_REVIEWS_QUESTIONS_START',
  FETCH_REVIEWS_QUESTIONS_SUCCESS = 'FETCH_REVIEWS_QUESTIONS_SUCCESS',
  FETCH_REVIEWS_QUESTIONS_ERRORED = 'FETCH_REVIEWS_QUESTIONS_ERRORED',
}

export interface EmployeesReviewsState {
  data: Array<Employees>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
