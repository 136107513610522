import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { SettingTab } from 'types/settings';
import TenantSettingsPageTabs from './components/TenantSettingsPageTabs';
import Content from '../Content';

const TenantSettingsPageContent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab =
    (searchParams.get('tab') as SettingTab) || SettingTab.CONFIGURATIONS;

  const onTabChange = (tab?: SettingTab) => {
    if (!tab) return;
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  return (
    <Content position="stretch">
      <TenantSettingsPageTabs activeTab={activeTab} onTabChange={onTabChange} />
    </Content>
  );
};

export default TenantSettingsPageContent;
