import { Col, Divider, Image, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { SubTitle, Title } from 'ui-v2/components/Card/CardStyles';
import NoAuthPageLayout from 'ui-v2/components/NoAuth/NoAuthPageLayout';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useDispatch } from 'react-redux';
import { onTwoFactorAuthentication } from 'redux/authUser/actions';
import { useLocation } from 'react-router-dom';
import { getPublicSuperAdminQRCode } from 'api/publicSuperAdminServise';
import { getTenantUserQRCode } from 'api/authService';
import AuthenticatorPinInput from '../AuthenticatorPinInput';

const TwoFactorAuthenticationSetupPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [qrCode, setQrCode] = useState<string>();
  const [loading, setLoading] = useState(false);
  const isSuperAdminLogin = location.pathname.includes('super');

  const getQrCode = async () => {
    setLoading(true);
    if (isSuperAdminLogin) {
      getPublicSuperAdminQRCode()
        .then((res) => {
          if (res.status === 200) {
            setQrCode(res.data);
          }
        })
        .catch(toastErrorMessages)
        .finally(() => setLoading(false));
    } else {
      getTenantUserQRCode()
        .then((res) => {
          if (res.status === 200) {
            setQrCode(res.data);
          }
        })
        .catch(toastErrorMessages)
        .finally(() => setLoading(false));
    }
  };
  useEffect(() => {
    getQrCode();
  }, []);

  const onComplete = (authentionCode: string) => {
    dispatch(onTwoFactorAuthentication({ authentionCode, isSuperAdminLogin }));
  };

  return (
    <NoAuthPageLayout>
      <Spin spinning={loading}>
        <Row justify="center" gutter={[0, 0]}>
          <Col xs={20} md={12} lg={10}>
            <CardItem>
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <Title>Two-Factor Authentication (2FA)</Title>
                    </Col>
                    <Col span={24}>
                      <Divider style={{ margin: 'unset' }} />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 0]}>
                    <Col span={24}>
                      <Typography.Title
                        level={5}
                        style={{
                          color: '#0050b3',
                        }}
                      >
                        Configure Gooogle Authenticator
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[0, 5]}>
                        <Col span={24}>
                          <SubTitle>
                            {`1. Install Authenticator 
                            App (IOS - Android)`}
                          </SubTitle>
                        </Col>
                        <Col span={24}>
                          <SubTitle>
                            {`2. In the authenticator app 
                            select "+" icon`}
                          </SubTitle>
                        </Col>
                        <Col span={24}>
                          <SubTitle>
                            {`3. Select "Scan a barcode (or QR code)" and use the
                            phone's camera to scan this barcode`}
                          </SubTitle>
                        </Col>
                        <Col span={24}>
                          <SubTitle>
                            4. Make sure to sync clock with google in the
                            authenticator app
                          </SubTitle>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Typography.Title
                        level={5}
                        style={{
                          color: '#0050b3',
                        }}
                      >
                        Scan QR Code
                      </Typography.Title>
                    </Col>
                    <Col>
                      <Image src={qrCode} preview={false} />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Typography.Title
                        level={5}
                        style={{
                          color: '#0050b3',
                        }}
                      >
                        Verify Code
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24}>
                          <Col span={24}>
                            <SubTitle>
                              Please enter the authentication code
                            </SubTitle>
                          </Col>
                        </Col>
                        <Col span={24}>
                          <AuthenticatorPinInput onComplete={onComplete} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <Row justify="center">
                        <Col>
                          <Typography.Link href="login">
                            Back to login
                          </Typography.Link>
                        </Col>
                      </Row>
                    </Col>
                    <Col />
                  </Row>
                </Col>
              </Row>
            </CardItem>
          </Col>
        </Row>
      </Spin>
    </NoAuthPageLayout>
  );
};

export default TwoFactorAuthenticationSetupPage;
