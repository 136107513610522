/* eslint-disable react/no-children-prop */
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useVendorsData } from 'ui-v2/hooks/useVendorsData';
import { VendorType } from 'types/Vendor';
import { getVendorById } from 'api/vendorService';
import AddVendorForm from 'components/NewForms/VendorModalForm';
import { fetchVendor } from 'redux/vendor/action';

export default function VendorDetails() {
  const { vendor } = useVendorsData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [vendorSelected, setVendorSelected] = useState<VendorType>();

  useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(fetchVendor(searchParams.get('id') as string));
    }
  }, [searchParams, vendor]);

  const fetchVendorById = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await getVendorById(id);
      if (response.status === 200) {
        setVendorSelected(response as any);
      }
    } catch (error) {
      toast.error('Vendor failed to fetch by Id!');
    }
  };

  useEffect(() => {
    if (searchParams.get('edit-vendor') !== null) {
      fetchVendorById(searchParams.get('id') as string);
      setModalVisible(true);
      searchParams.delete('edit-vendor');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <Content position="grid">
        <>
          <CardItem title={t('vendorInformation')}>
            <Row gutter={[0, 16]}>
              <Styled.RowItem gutter={[0, 10]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('taxId')}
                    value={vendor?.taxId}
                    children={undefined}
                  />
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('companyName')}
                    value={vendor?.companyName}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
              <Styled.RowItem gutter={[0, 10]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('country')}
                    value={vendor?.country}
                    children={undefined}
                  />
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('city')}
                    value={vendor?.city}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
              <Styled.RowItem gutter={[0, 10]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('state')}
                    value={vendor?.state}
                    children={undefined}
                  />
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('street')}
                    value={vendor?.street}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
              <Styled.RowItem gutter={[0, 10]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('zipCode')}
                    value={vendor?.zipCode}
                    children={undefined}
                  />
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('phoneNumber')}
                    value={vendor?.phoneNumber}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
              <Styled.RowItem gutter={[0, 10]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('contactPerson')}
                    value={vendor?.contactPerson}
                    children={undefined}
                  />
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('email')}
                    value={vendor?.email}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
              <Styled.RowItem last gutter={[0, 10]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('vendorCategories')}
                    value={vendor?.vendorCategories
                      .map(
                        (item: { categoryName: string }) => item?.categoryName
                      )
                      .join(', ')}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
            </Row>
          </CardItem>
          <></>
        </>
      </Content>
      {modalVisible && (
        <AddVendorForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-vendor');
            setSearchParams(searchParams);
          }}
          selectedVendor={vendorSelected}
        />
      )}
    </>
  );
}
