import { AssignTaskIcon } from 'Icons/AssignTaskIcon';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import { toastErrorMessages } from 'utils/utilFunctions';
import { Col, Row } from 'antd';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { AUTH_ROLES } from 'types/Auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPrjoectEmployeeAssignments,
  fetchPrjoectEmployeeAssignments,
  fetchProjectsOfEmployee,
} from 'redux/projects/actions';
import { toast } from 'react-toastify';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { useTranslation } from 'react-i18next';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import {
  TrackedHourDTO,
  TrackedHourJobLocation,
  TrackedHourType,
} from 'types/tracking';
import { OptionType } from 'types/OptionTypes';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import {
  createTrackingHour,
  updateTrackingHours,
} from 'api/trackedHoursService';
import { FormItemStyled, StyledButton } from '../FormStyled';
import GenericForm from '../Form';
import { getThFormConfigurations, getThPayload } from './utils';
import { ValueType } from '../SelectWithLoad/SelectWithLoad';
import { isFormEdited } from '../Form/utils';

interface TrackinHourModalFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function TrackinHourModalForm({
  open,
  onClose,
  onSuccess,
}: TrackinHourModalFormProps) {
  const dispatch = useDispatch();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const { authUserRole, authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const [form] = useForm();
  const [hourType, setHourType] = useState<TrackedHourType>(
    TrackedHourType.REGULAR_HOURS
  );
  const [isRemote, setIsRemote] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: tenantConfig } = useTenantConfigData();
  const {
    trackedHoursDetails: { data: list },
  } = useTrackedHoursData();
  const {
    employeeAssignment: { data: assignments },
    employeeProject,
  } = useProjectsData();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [trackedHour, setTrackedHour] = useState<TrackedHourDTO>();
  const [defaultProjectOption, setDefaultProjectOption] =
    useState<OptionType>();
  const [defaultEmployeeOption, setDefaultEmployeeOption] =
    useState<OptionType>();

  useEffect(() => {
    if (authUserRole === AUTH_ROLES.EMPLOYEE) {
      dispatch(fetchProjectsOfEmployee());
    }
  }, [authUserRole]);

  const onEditHour = (id: string | null) => {
    if (!id) {
      onClose();
      return;
    }
    const foundTrackedHour = list?.find((item) => item?.id === id);

    if (foundTrackedHour?.employee?.id) {
      if (!authUser?.employee?.id) {
        dispatch(
          fetchPrjoectEmployeeAssignments(foundTrackedHour.employee?.id)
        );
      }
      setTrackedHour(foundTrackedHour);
      if (foundTrackedHour?.assignment?.project?.id) {
        setDefaultProjectOption({
          id: foundTrackedHour?.assignment?.id,
          label: `${foundTrackedHour?.assignment?.project?.name}`,
          value: foundTrackedHour?.assignment?.id,
        });
        dispatch(
          fetchPrjoectEmployeeAssignments(
            foundTrackedHour?.employee?.id as string
          )
        );
      }
      setDefaultEmployeeOption({
        id: foundTrackedHour?.employee?.id,
        label: `${foundTrackedHour?.employee?.firstName} ${foundTrackedHour?.employee?.lastName}`,
        value: foundTrackedHour?.employee?.id,
      });
      setIsRemote(!!foundTrackedHour?.isRemote);
      searchParams.delete('edit-hour');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (searchParams.get('edit-hour')) {
      onEditHour(searchParams.get('edit-hour') as string);
    }
  }, [searchParams]);

  const onEmployeeSelect = useCallback(
    (selectedItem: ValueType) => {
      form.resetFields(['projectId']);
      setDefaultProjectOption(undefined);
      setDefaultEmployeeOption({
        id: selectedItem?.value as string,
        label: selectedItem?.label as string,
        value: selectedItem.value as string,
      });
      dispatch(fetchPrjoectEmployeeAssignments(selectedItem?.value as string));
    },
    [form]
  );

  const onProjectSelect = useCallback((selectedItem?: string) => {
    if (selectedItem) {
      form.resetFields(['projectId']);
      setDefaultProjectOption({
        id: selectedItem,
        label: selectedItem,
        value: selectedItem,
      });
    } else {
      setDefaultProjectOption(undefined);
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (
      !authUserRole ||
      authUserRole === AUTH_ROLES.GUEST_TRACKING_HOURS ||
      submitted
    ) {
      return;
    }
    setSubmitted(true);
    const payload = {
      ...getThPayload(
        form.getFieldsValue(true),
        defaultEmployeeOption?.id || authUser?.employee.id,
        !!defaultProjectOption?.id
      ),
      isRemote,
      hourType: trackedHour?.id
        ? (trackedHour?.hourType as TrackedHourType)
        : hourType,
    };
    if (!trackedHour) {
      createTrackingHour(payload)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Time entry has been created!'));
            onSuccess();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
          setSubmitted(false);
        })
        .finally(() => {
          setLoading(false);
          setDefaultEmployeeOption(undefined);
        });
    } else {
      if (
        isFormEdited({
          formValues: payload,
          valuesToCheck: trackedHour,
          entity: 'trackingHours',
        })
      ) {
        onClose();
        setLoading(false);
        setDefaultEmployeeOption(undefined);
        return;
      }
      updateTrackingHours(trackedHour.id, payload)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Time entry has been updated!'));
            onSuccess();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
          setSubmitted(false);
        })
        .finally(() => {
          setLoading(false);
          setDefaultEmployeeOption(undefined);
        });
    }
  }, [
    submitted,
    hourType,
    isRemote,
    trackedHour,
    defaultEmployeeOption,
    defaultProjectOption,
  ]);

  const onModalClose = () => {
    dispatch(clearPrjoectEmployeeAssignments());
    setDefaultEmployeeOption(undefined);
    searchParams.delete('edit-hour');
    setSearchParams(searchParams);
    onClose();
  };

  const onHourTypeSelect = useCallback(
    (hourType: string) => {
      setHourType(hourType as TrackedHourType);
    },
    [hourType]
  );

  const onJobLocationSelect = useCallback(
    (jobLocation: string) => {
      setIsRemote(jobLocation === TrackedHourJobLocation.REMOTE);
    },
    [isRemote]
  );

  const TrackinHourFormConfiguration: FormConfigurationType[][] = useMemo(
    () =>
      getThFormConfigurations({
        form,
        onEmployeeSelect,
        trackedHour,
        tenantConfig,
        loggedInUserRole: authUserRole as AUTH_ROLES | undefined,
        assignments,
        employeeProject: employeeProject?.data,
        onHourTypeSelect,
        onJobLocationSelect,
        defaultEmployeeOption,
        defaultProjectOption,
        authUser,
        onProjectSelect,
        getEmployeeSkimSelectWithLoadOptions,
      }),
    [
      form,
      trackedHour,
      authUser,
      tenantConfig,
      authUserRole,
      assignments,
      defaultEmployeeOption,
      defaultProjectOption,
      employeeProject?.data,
    ]
  );

  return (
    <GenericModal
      title={`${trackedHour ? t('edit') : t('registerhour')} ${t('loghour')}`}
      open={open}
      closeModal={onModalClose}
      icon={<AssignTaskIcon />}
    >
      <GenericForm
        formConfiguration={TrackinHourFormConfiguration}
        form={form}
        onFinish={onSubmit}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={onModalClose} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
