import agent from 'api/agent';
import { CandidateDocumentCreate } from 'types/CandidateDocument';
import { apiURLs } from './constants';

export const createCandidateDocument = (values: CandidateDocumentCreate) =>
  agent.post(apiURLs.candidateDocuments, values);

export const getAllCandidateDocuments = (candidateId: string) =>
  agent.get(`${apiURLs.candidateDocuments}/candidate/${candidateId}`);

export const deleteCandidateDocument = (documentId: string) =>
  agent.delete(`${apiURLs.candidateDocuments}/${documentId}`);
