import React from 'react';

import { ColumnsType } from 'antd/lib/table';
import { EmployeeEvaluationDataItem } from 'types/Evaluation';
import EvaluationActions from 'components/Actions/EvaluationActions';
import EmptyCell from 'components/common/EmptyCell';

import { convertUTCtoFormatedLocalTimeMonthWithLetters } from 'utils/utilFunctions';
import { Col, Row } from 'antd';
import i18next from '../i18n';

export const EvaluationPeriodsConfig: ColumnsType<EmployeeEvaluationDataItem> =
  [
    {
      title: i18next.t('name'),
      dataIndex: 'employee',
      key: 'employee',
      render: (_, item: EmployeeEvaluationDataItem) => (
        <>
          <Row>
            <Col>
              {`${item?.employee?.firstName}
            ${item?.employee?.lastName}`}
            </Col>
          </Row>
          <Row>
            <Col style={{ fontStyle: 'italic', fontSize: '12px' }}>
              {` ${i18next.t('createdAt')}:
            ${convertUTCtoFormatedLocalTimeMonthWithLetters(item?.createdAt)}`}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: i18next.t('evaluationPeriod'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, item) =>
        `${convertUTCtoFormatedLocalTimeMonthWithLetters(
          item.startDate
        )} - ${convertUTCtoFormatedLocalTimeMonthWithLetters(item?.endDate)}`,
    },
    {
      title: i18next.t('feedback'),
      dataIndex: 'employeeFeedback',
      key: 'employeeFeedback',
      ellipsis: true,
      render: (_, item) => item?.employeeFeedback ?? <EmptyCell />,
    },
    {
      title: i18next.t('action'),
      key: 'action',
      width: 180,
      render: (_: string, row: EmployeeEvaluationDataItem) => (
        <EvaluationActions id={row.id} />
      ),
    },
  ];
