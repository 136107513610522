import { Form } from 'antd';
import React, { useMemo, useEffect, useState } from 'react';

import { getAllJobCategoriesWithSubCategories } from 'api/jobCategoryService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import CardItem from 'ui-v2/components/Card';
import { CandidateType } from 'types/Candidates';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { registerCandidate, updateCandidate } from 'api/candidateService';
import { toast } from 'react-toastify';
import { RcFile } from 'antd/lib/upload/interface';
import {
  convertBase64,
  convertDateToUTC,
  isDuplicateEmailError,
  isOfUrlValid,
} from 'utils/utilFunctions';
import useHeader from 'ui-v2/hooks/useHeader';
import { useDispatch } from 'react-redux';
import { fetchCandidate } from 'redux/candidates/actions';
import { OptionType } from 'types/OptionTypes';
import { FormConfigurationType } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';
import SkillsMatrix, { appendSkills } from '../DynamicForm/SkillsMatrix';
import { getCandidateFormConfigurations } from './utils';
import { ValueType } from '../SelectWithLoad/SelectWithLoad';
import { isFormEdited } from '../Form/utils';

interface IProps {
  candidate?: CandidateType;
}

function CandidateForm({ candidate }: IProps) {
  const [form] = Form.useForm();
  const { ID } = useHeader();
  const [defaultSkills, setDefaultSkills] = useState([]);
  const [deletedSkills, setDeletedSkills] = useState([]);
  const [loading, isLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
  }>({});
  const { t } = useTranslation();

  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });
  const { optionsType: stages } = useGetSelectOptions({
    type: 'stages',
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidate?.skillSubCategoriesCandidates) {
      setDefaultSkills(
        candidate?.skillSubCategoriesCandidates.map((item, index) => ({
          skillSubCategoryId: item.skillsSubCategory.id,
          yearsOfExperience: item.yearsOfExperience,
          score: item.score,
          index,
        })) as any
      );
    }
  }, [candidate?.skillSubCategoriesCandidates]);

  function onFormFinish(values: any) {
    if (values.expectedSalary < 0) {
      toast.warning(t('pleaseEnterAValidSalary!'));
      isLoading(false);
      return;
    }
    const candidateOpenPositions: Array<{
      openPositionId: string;
      stageId: string;
    }> =
      values?.candidateOpenPositions?.map((option: ValueType) => ({
        openPositionId: option.value,
        stageId: stages
          .filter(
            (item: OptionType) => `${item.label}`.toLocaleLowerCase() === 'new'
          )
          ?.at(0)?.id,
      })) ?? [];

    const valuesToSend: any = {
      ...appendSkills(
        values,
        candidate?.skillSubCategoriesCandidates,
        defaultSkills,
        deletedSkills
      ),
      candidateOpenPositions,
      certifications: values.certifications.map((item: any) => item.key),
      birthDate: convertDateToUTC(values.birthDate),
      gender: values.gender.charAt(0),
      resume: {
        name: fileToUpload.name,
        content: fileToUpload.content?.split(',').pop(),
      },
      expectedSalary: Number(values.expectedSalary),
    };
    if (
      !valuesToSend.linkedIn ||
      String(valuesToSend.linkedIn).replace(/\s/g, '').length === 0
    ) {
      delete valuesToSend.linkedIn;
    } else if (!isOfUrlValid(valuesToSend.linkedIn)) {
      toast.warning(t('LinkedIn URL is not valid!'));
      return;
    }

    if (!valuesToSend.referalType) {
      valuesToSend.referalType = 'other';
    } else if (valuesToSend.referalType === 'EMPLOYEE') {
      valuesToSend.referalType = values.referalType.toLowerCase();
      valuesToSend.referedBy = values?.employeeId?.value;
      delete valuesToSend.employeeId;
    } else {
      valuesToSend.referalType = values.referalType?.toLowerCase();
    }
    if (!fileToUpload.content) {
      delete valuesToSend.resume;
    }

    isLoading(true);

    try {
      if (candidate?.id) {
        if (
          isFormEdited({
            formValues: valuesToSend,
            valuesToCheck: candidate,
            entity: 'candidate',
          })
        ) {
          navigate(`/candidates/profile?id=${ID}`);
          isLoading(false);
          return;
        }

        updateCandidate(candidate?.id, valuesToSend)
          .then((response) => {
            if (response.status === 200 && response.data) {
              if (candidate?.id) {
                dispatch(fetchCandidate(candidate.id));
              }
              toast.success(t('Successfully updated'));
              navigate(`/candidates/profile?id=${ID}`);
            }
          })
          .catch((error) => {
            if (isDuplicateEmailError(error)) {
              toast.warning(t('This e-mail is already in use'));
              return;
            }
            toast.error(
              error?.response?.data?.error || t('somethingWentWrong')
            );
          });
      } else {
        registerCandidate(valuesToSend)
          .then((response) => {
            if (response.status === 200 && response.data?.id) {
              if (candidate?.id) {
                dispatch(fetchCandidate(candidate.id));
              }
              toast.success(t('Successfully created candidate!'));
              navigate(`/candidates/profile?id=${response.data?.id}`);
              isLoading(false);
            }
          })
          .catch((error) => {
            if (isDuplicateEmailError(error)) {
              toast.warning(t('This e-mail is already in use'));
              return;
            }
            toast.error(
              error?.response?.data?.error ||
                error?.message ||
                t('somethingWentWrong')
            );
          });
      }
      isLoading(false);
    } catch (error) {
      toast.error(t('errorDuringThisAction'));
      isLoading(false);
    }
  }

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64 });
    return false;
  }

  const CandidateFormConfiguration: FormConfigurationType[][] = useMemo(
    () =>
      getCandidateFormConfigurations({
        candidate,
        currencyOptions,
        uploadAction,
        currencyLoading,
      }),
    [currencyOptions, candidate]
  );

  useEffect(() => {
    if (searchParams.get('add-save') === 'true') {
      searchParams.delete('add-save');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-save-user') === 'true') {
      searchParams.delete('edit-save-user');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onEditedSkillChange = (
    index: number,
    field: any,
    deletedSkill?: any
  ) => {
    if (deletedSkill) {
      setDefaultSkills(
        defaultSkills.filter(
          (skill: any) =>
            skill.skillSubCategoryId !== deletedSkill.skillSubCategoryId
        )
      );
      setDeletedSkills([...deletedSkills, deletedSkill] as any);
      return;
    }
    let skill: any = defaultSkills && defaultSkills[index];
    if (typeof field === 'number') {
      skill = { ...skill, score: field };
    } else if (typeof field === 'string') {
      skill = { ...skill, yearsOfExperience: field };
    } else {
      skill = { ...skill, skillSubCategoryId: field?.id };
    }
    const currentDefaultSkills: any = defaultSkills;
    currentDefaultSkills[index] = skill;
    setDefaultSkills([] as any);
    setDefaultSkills(currentDefaultSkills);
  };

  return (
    <CardItem title="">
      <GenericForm
        formConfiguration={CandidateFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <SkillsMatrix
          fetchList={getAllJobCategoriesWithSubCategories}
          defaultSkills={defaultSkills as any}
          onEditedSkillChange={onEditedSkillChange}
        />
      </GenericForm>
    </CardItem>
  );
}

CandidateForm.defaultProps = {
  candidate: {},
};

export default CandidateForm;
