import { Dispatch } from 'redux';
import {
  TenantConfigApiClient,
  getTenantConfigById,
} from 'api/tenantConfigService';

import { TenantConfig } from './types';
import { TenantConfigReducedAction } from './reducer';

export namespace TenantConfigAsyncActions {
  export const fetchCurrentTenantConfigurations =
    () => (dispatch: Dispatch<TenantConfigReducedAction>) => {
      dispatch({
        type: TenantConfig.FETCH_TENANT_CONFIG_START,
        payload: {
          loading: true,
          loaded: false,
        },
      });
      TenantConfigApiClient.getCurrentTenantConfig()
        .then((res) => {
          dispatch({
            type: TenantConfig.FETCH_TENANT_CONFIG_SUCCESS,
            payload: {
              loading: false,
              loaded: true,
              data: res.data,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: TenantConfig.FETCH_TENANT_CONFIG_ERRORED,
            payload: {
              loading: false,
              loaded: false,
              errored: true,
              error: e,
            },
          });
        });
    };
  export const fetchTenantConfigigurations =
    (payload: TenantConfigApiClient.TenantConfigRequest) =>
    (dispatch: Dispatch<TenantConfigReducedAction>) => {
      dispatch({
        type: TenantConfig.FETCH_TENANT_CONFIG_START,
        payload: {
          loading: true,
          loaded: false,
        },
      });
      TenantConfigApiClient.getTenantConfig(payload)
        .then((res) => {
          dispatch({
            type: TenantConfig.FETCH_TENANT_CONFIG_SUCCESS,
            payload: {
              loading: false,
              loaded: true,
              data: res.data,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: TenantConfig.FETCH_TENANT_CONFIG_ERRORED,
            payload: {
              loading: false,
              loaded: false,
              errored: true,
              error: e,
            },
          });
        });
    };
}

export const fetchTenantConfigById =
  (tenantConfigId: string) => (dispatch: Dispatch) => {
    dispatch({
      type: TenantConfig.FETCH_TENANT_CONFIG_START,
    });
    getTenantConfigById(tenantConfigId)
      .then((res) => {
        dispatch({
          type: TenantConfig.FETCH_TENANT_CONFIG_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: TenantConfig.FETCH_TENANT_CONFIG_ERRORED,
          payload: e,
        });
      });
  };

export const clearTenantConfig = () => (dispatch: Dispatch) => {
  dispatch({
    type: TenantConfig.CLEAR_TENANT_CONFIG,
  });
};
