import { Col, Row, Spin } from 'antd';
import React from 'react';
import CardItem from 'ui-v2/components/Card';
import { SubTitle, Title } from 'ui-v2/components/Card/CardStyles';
import NoAuthPageLayout from 'ui-v2/components/NoAuth/NoAuthPageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { onTwoFactorAuthentication } from 'redux/authUser/actions';
import { useLocation } from 'react-router-dom';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import AuthenticatorPinInput from '../AuthenticatorPinInput';

const TwoFactorAuthenticationPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const isSuperAdminLogin = location.pathname.includes('super');

  const onComplete = (authentionCode: string) => {
    dispatch(onTwoFactorAuthentication({ authentionCode, isSuperAdminLogin }));
  };

  return (
    <NoAuthPageLayout>
      <Spin spinning={loading}>
        <Row justify="center" gutter={[0, 20]}>
          <Col xs={20} md={12} lg={10}>
            <CardItem>
              <Row justify="center" gutter={[0, 40]}>
                <Col span={24}>
                  <Row
                    justify="center"
                    style={{
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                    gutter={[0, 10]}
                  >
                    <Col>
                      <Title>{t('twoFactorAuthentication')}</Title>
                    </Col>
                    <Col>
                      <SubTitle>{t('getYourVerificationCode')}</SubTitle>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="center">
                    <Col>
                      <AuthenticatorPinInput onComplete={onComplete} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardItem>
          </Col>
        </Row>
      </Spin>
    </NoAuthPageLayout>
  );
};

export default TwoFactorAuthenticationPage;
