/* eslint-disable indent */
import { getAllOnboardings, getOnboarding } from 'api/onBoardingService';
import { Dispatch } from 'redux';
import { QueryParamsType } from 'types/QueryParams';
import { OnBoardings } from './types';

export const fetchOnBoardings =
  (queryParams?: QueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: OnBoardings.FETCH_ON_BOARDINGS_START,
    });
    getAllOnboardings(queryParams)
      .then((res) => {
        dispatch({
          type: OnBoardings.FETCH_ON_BOARDINGS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: OnBoardings.FETCH_ON_BOARDINGS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchOnboardingDetail = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: OnBoardings.FETCH_ON_BOARDINGS_DETAILS_START,
  });

  getOnboarding(id)
    .then((res) => {
      dispatch({
        type: OnBoardings.FETCH_ON_BOARDINGS_DETAILS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: OnBoardings.FETCH_ON_BOARDINGS_DETAILS_ERRORED,
        payload: e,
      });
    });
};

export const clearOnboardingState = () => (dispatch: Dispatch) => {
  dispatch({
    type: OnBoardings.CLEAR_SINGLE_ONBOARDING,
  });
};
