import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { PayrollRulesQueryParamsType } from 'types/QueryParams';
import { useTranslation } from 'react-i18next';
import { fetchPayrollRules } from 'redux/payrollRules/actions';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import { fetchTenantLocationsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function PayrollRulesFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: tenantConfig } = useTenantConfigData();

  async function onFormChange() {
    const tenantLocationId = form.getFieldValue('tenantLocationId')?.value;

    const filterPayrollRulesTable: PayrollRulesQueryParamsType = {
      tenantLocationId,
    };

    dispatch(fetchPayrollRules(filterPayrollRulesTable));
  }

  const PayrollRuleFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'tenantLocationId',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [tenantConfig]
  );

  return (
    <>
      <GenericForm
        formConfiguration={PayrollRuleFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchPayrollRules({ page: 1, take: 10 }));
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
