import { Dispatch } from 'redux';
import { getVendor, getVendors } from 'api/vendorService';
import { QueryParamsType } from 'types/QueryParams';
import { Vendors } from './types';

export const fetchVendors =
  (queryParams: QueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Vendors.FETCH_VENDORS_START,
    });
    getVendors(queryParams)
      .then((res) => {
        dispatch({
          type: Vendors.FETCH_VENDORS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Vendors.FETCH_VENDORS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchVendor = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Vendors.FETCH_VENDORS_START,
  });
  getVendor(id)
    .then((res) => {
      dispatch({
        type: Vendors.FETCH_VENDOR_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Vendors.FETCH_VENDOR_ERRORED,
        payload: e,
      });
    });
};
