import { Dispatch } from 'redux';

import { TrackedHoursQueryParamDTO } from 'types/tracking';
import {
  getTrackedHoursBarChart,
  getTrackedHoursDetails,
  getTrackedHoursPieCHart,
  getTrackedHoursSummary,
  getTrackedHoursToApprove,
} from 'api/trackedHoursService';
import { Trackings } from './types';

export const fetchTrackedHoursToApprove =
  (queryParams?: TrackedHoursQueryParamDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Trackings.FETCH_TRACKED_HOURS_TO_APPROVE_START,
    });
    getTrackedHoursToApprove(queryParams)
      .then((res) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_TO_APPROVE_SUCCESS,
          trackedHoursToApprove: {
            data: res?.data?.data,
            meta: res?.data?.meta,
            overallTotal: res?.data?.overallTotal,
            sectionTotal: res?.data?.sectionTotal,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_TO_APPROVE_ERRORED,
          trackedHoursToApprove: {
            error,
          },
        });
      });
  };

export const fetchTrackedHoursSummary =
  (queryParams?: TrackedHoursQueryParamDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Trackings.FETCH_TRACKED_HOURS_SUMMARY_START,
    });
    getTrackedHoursSummary(queryParams)
      .then((res) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_SUMMARY_SUCCESS,
          trackedHoursSummary: {
            data: res?.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_SUMMARY_ERRORED,
          trackedHoursSummary: {
            error,
          },
        });
      });
  };

export const fetchTrackedHourDetails =
  (queryParams?: TrackedHoursQueryParamDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Trackings.FETCH_TRACKED_HOURS_DETAILS_START,
    });
    getTrackedHoursDetails(queryParams)
      .then((res) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_DETAILS_SUCCESS,
          trackedHoursDetails: {
            data: res?.data?.data,
            meta: res?.data?.meta,
            overallTotal: res?.data?.overallTotal,
            sectionTotal: res?.data?.sectionTotal,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_DETAILS_ERRORED,
          trackedHoursDetails: {
            error,
          },
        });
      });
  };

export const fetchTrackedHoursPieChart =
  (filters: TrackedHoursQueryParamDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Trackings.FETCH_TRACKED_HOURS_PIE_CHART_START,
    });
    getTrackedHoursPieCHart(filters)
      .then((res) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_PIE_CHART_SUCCESS,
          trackedHoursPieChart: {
            data: res?.data,
            meta: res?.data?.meta,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_PIE_CHART_ERRORED,
          trackedHoursPieChart: {
            error,
          },
        });
      });
  };

export const fetchTrackedHoursBarChart =
  (filters: TrackedHoursQueryParamDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Trackings.FETCH_TRACKED_HOURS_BAR_CHART_START,
    });
    getTrackedHoursBarChart(filters)
      .then((res) => {
        dispatch({
          type: Trackings.FETCH_TRACKED_HOURS_BAR_CHART_SUCCESS,
          trackedHoursBarChart: {
            data: res.data,
            meta: res?.data?.meta,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Trackings.FETCH_SINGLE_TRACKED_HOUR_ERRORED,
          trackedHoursBarChart: {
            error,
          },
        });
      });
  };
