/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Result, Row } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';

export default function EmployeeNotFound() {
  const { t } = useTranslation();
  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <Result
          status="404"
          title={t('somethingWentWrong')}
          subTitle={t('Please click the button below to resend the email.')}
          children={
            <Row justify="center" align="middle">
              <Col>
                <StyledButton type="primary" size="middle">
                  {t('Resend Email')}
                </StyledButton>
              </Col>
            </Row>
          }
        />
      </Col>
    </Row>
  );
}
