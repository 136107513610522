import React, { ChangeEvent, useRef, useState } from 'react';
import Papa from 'papaparse';
import {
  bulkUploadEmployees,
  getEmployeesBulkUploadDetails,
} from 'api/employeeService';
import { bulkUploadCandidates } from 'api/candidateService';
import { EmployeeCretateDto, EmployeesBulkUploadDetails } from 'types/Employee';
import { ImportOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getValidFileContent, toastErrorMessages } from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchEmployees } from 'redux/employees/actions';
import { fetchCandidates } from 'redux/candidates/actions';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { getBase64 } from 'Helpers';
import { RcFile } from 'antd/lib/upload';
import { buldUploadAssets } from 'api/assetService';
import { fetchAssets } from 'redux/assets/actions';

const StyledBtnContainer = styled.div`
  background: #ffffff;
  height: 35px;
  width: 35px;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items; center;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; // Ensure cursor remains pointer when hovering over hidden input
  }
 
  svg {
    transform: rotate(90deg);
    font-size: 16px;
    margin-top: 10px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.5;
    color: #004eeb !important;
    span {
      color: #004eeb !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;

function prettifyEmployees(data: any) {
  return data.map((item: EmployeeCretateDto) => ({
    ...item,
    resume: {
      name: '',
      content: '',
    },
  }));
}

interface BulkUploadProps {
  entity: string;
}

function BulkUpload({ entity }: BulkUploadProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const prepareFile = async (file: any) => {
    const dataToUpload = prettifyEmployees(file);
    const bulkDetails: EmployeesBulkUploadDetails =
      await getEmployeesBulkUploadDetails({})
        .then((res) => res.data)
        .catch((e) => Promise.reject(e));

    const completeRecords: any = [];

    if (
      bulkDetails.workPositions.length < 1 ||
      bulkDetails.currencies.length < 1 ||
      bulkDetails.employmentTypes.length < 1
    ) {
      return completeRecords;
    }

    dataToUpload.forEach((row: { [key: string]: string }) => {
      const currentWorkPosition = bulkDetails.workPositions.find(
        (item) => item.name === row.workPositionId
      );
      const currentCurrency = bulkDetails.currencies.find(
        (item) => item.name === row.currencyId
      );
      const currentEmploymentType = bulkDetails.employmentTypes.find(
        (item) => item.name === row.employmentTypeId
      );

      if (currentWorkPosition && currentCurrency && currentEmploymentType) {
        row.workPositionId = currentWorkPosition.id;
        row.currencyId = currentCurrency.id;
        row.employmentTypeId = currentEmploymentType.id;
        completeRecords.push(row);
      }
    });
    return completeRecords;
  };

  const submitData = async (entity: string, data: string) => {
    setLoading(true);
    if (entity === 'candidates') {
      await bulkUploadCandidates({ data })
        .then((res: any) => {
          if (res.status === 200) {
            dispatch(fetchCandidates());

            toast.success(t('Successfully uploaded candidates data'));
          }
        })
        .catch((e) => {
          toastErrorMessages(e);
        })
        .finally(() => setLoading(false));
    }

    if (entity === 'employees') {
      await prepareFile(data)
        .then(async (completeRecords) => {
          await bulkUploadEmployees({ data: completeRecords })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(fetchEmployees());
                toast.success(t('Successfully uploaded employees data'));
              }
            })
            .catch((e) => {
              toastErrorMessages(e);
            })
            .finally(() => setLoading(false));
        })
        .catch((e) => {
          toastErrorMessages(e);
        })
        .finally(() => setLoading(false));
    }
  };

  const openFileChooser = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : undefined;

    if (file) {
      if (entity === 'assets') {
        const fileBuffer = await getBase64(file as RcFile);
        const validBase64 = getValidFileContent(fileBuffer);
        await buldUploadAssets({ file: validBase64 })
          .then((res: any) => {
            if (res?.data?.length) {
              res?.data?.forEach((message: string) => {
                toast.warning(message);
              });
            } else if (res?.status === 200) {
              toast.success(t('successfullyUploadedAssetsData'));
            }
            dispatch(fetchAssets({ page: 1, take: 10 }));
          })
          .catch((e) => {
            toastErrorMessages(e);
          })
          .finally(() => setLoading(false));
      } else {
        Papa.parse(file, {
          complete: (result) => {
            submitData(entity, result.data as any);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      }
    }
  };

  if (entity === 'employees' || entity === 'assets') {
    return (
      <Spin spinning={loading}>
        <StyledBtnContainer>
          <input
            ref={fileInputRef}
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            placeholder={
              entity === 'assets' ? t('importAssets') : t('importEmployees')
            }
          />
          <ImportOutlined onClick={openFileChooser} />
        </StyledBtnContainer>
      </Spin>
    );
  }

  return null;
}

export default BulkUpload;
