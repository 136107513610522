import { Breadcrumb, Typography } from 'antd';
import styled from 'styled-components';

export const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const StyledBreadcrumbText = styled(Typography.Text)<{
  active: string;
}>`
  font-weight: ${({ active }) => (active === 'true' ? 400 : 300)};
  cursor: pointer;
`;

export const StyledBreadcrumbBack = styled(Typography.Text)`
  font-weight: 400;
  cursor: pointer;
  color: #155eef;
`;
