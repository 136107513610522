/* eslint-disable @typescript-eslint/no-unused-vars */
import { TeanantLocation } from 'types/TenantLocation';
import agent from './agent';

import { apiURLs } from './constants';

export const getAllTenantLocations = () =>
  agent.get(`${apiURLs.tenantLocation}`);

export const getSingleTenantLocation = (id: string) =>
  agent.get(`${apiURLs.tenantLocation}/${id}`);

export const createTenantLocation = (payload: TeanantLocation) =>
  agent.post(`${apiURLs.tenantLocation}/addLocation`, payload);

export const editTenantLocation = (id: string, payload: TeanantLocation) =>
  agent.patch(`${apiURLs.tenantLocation}/editLocation/${id}`, payload);

export const deleteTenantLocation = (id: string) =>
  agent.delete(`${apiURLs.tenantLocation}/${id}`);
