import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SkillSubCategoriesEmployees } from 'types/Employee';
import CardItem from 'ui-v2/components/Card';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { RowItem } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { NodataLabel } from 'ui-v2/components/WorkExperience/WorkExperienceStyles';
import { Button, Col, Row, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { OptionType } from 'types/OptionTypes';
import AddSkill from 'ui-v2/components/Skills/AddSkillModal/AddSkillsModal';
import { updateEmployeeSkills } from 'api/employeeService';
import {
  ResponseSkillCategoryType,
  SkillSubCategoryInputType,
  SkillSubCategoryType,
} from 'types/Skills';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useSkillsData } from 'ui-v2/hooks/useSkillsData';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface IProps {
  employeeSkills: ResponseSkillCategoryType[];
  employeeId: string;
  loading: boolean;
}
export default function RadarSkills({
  employeeSkills,
  employeeId,
  loading,
}: IProps) {
  const dispatch = useDispatch();
  const {
    skills: { data: skillsData },
  } = useSkillsData();

  const [modalVisible, setModalVisible] = useState(false);
  const [skillsList, setSkillList] = useState<Array<SkillSubCategoryType>>([]);
  const { t } = useTranslation();

  const onToggleModal = useCallback(
    () => setModalVisible((state) => !state),
    [modalVisible]
  );

  const onAddSkill = async (addedSkill: SkillSubCategoryInputType) => {
    if (loading || !employeeId) return;
    const skillSubCategories: Array<SkillSubCategoryInputType> = [
      ...employeeSkills.map((el) => ({
        skillSubCategoryId: el.skillsSubCategory.id,
        yearsOfExperience: Number(el.yearsOfExperience),
        score: el.score,
      })),
      {
        ...addedSkill,
        yearsOfExperience: Number(addedSkill?.yearsOfExperience),
        skillSubCategoryId: addedSkill?.skillSubCategoryId,
      },
    ];
    updateEmployeeSkills({
      employeeId,
      skillSubCategories,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('successfullyAddedSkill!'));
          dispatch(fetchEmployeesDetail(employeeId));
        }
      })
      .catch(toastErrorMessages)
      .finally(() => {
        onToggleModal();
      });
  };

  const skillOptions: OptionType[] = skillsList
    ?.map((skill) => ({
      id: skill?.id,
      label: skill?.subCategoryName,
      value: skill?.id,
    }))
    .filter(
      (el) =>
        !employeeSkills.find(
          (employeeSkill) => employeeSkill.skillsSubCategory?.id === el?.id
        )
    );

  const labels: string[] = employeeSkills.map(
    (skill: SkillSubCategoriesEmployees) =>
      skill.skillsSubCategory.subCategoryName
  );

  const yearsOfExperience: number[] = employeeSkills.map(
    (skill: SkillSubCategoriesEmployees) =>
      skill.yearsOfExperience as unknown as number
  );

  const maxYearOfExperience = useMemo(
    () =>
      Math.max(
        ...employeeSkills.map(
          (e: SkillSubCategoriesEmployees) =>
            e.yearsOfExperience as unknown as number
        )
      ),
    []
  );

  const chartOptions = {
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 12,
          },
        },
        suggestedMin: 0,
        suggestedMax: maxYearOfExperience,
      },
    },
  };

  const chartData = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: t('yearsOfExperience'),
          data: yearsOfExperience,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 2,
        },
      ],
    }),
    [yearsOfExperience, labels]
  );

  const initSkills = () => {
    const allSubCategories = skillsData
      .map((item) => item.subCategories)
      .flat();
    const uniqueSubs: Array<string> = [];
    allSubCategories.forEach((item) => {
      if (!uniqueSubs.includes(item.id)) {
        uniqueSubs.push(item.id);
      }
    });
    setSkillList(
      allSubCategories?.filter((item) => uniqueSubs.includes(item.id))
    );
  };

  useEffect(() => {
    if (skillsData?.length) {
      initSkills();
    }
  }, [skillsData?.length]);

  return (
    <CardItem title={t('skills')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Row gutter={[0, 24]}>
          {!employeeSkills.length ||
            (employeeSkills.length > 2 && (
              <Radar data={chartData} options={chartOptions} />
            ))}
          {employeeSkills.length <= 2 && (
            <RowItem justify="center" last>
              <Col>
                <NodataLabel>{t('addmoreskillstobuildGraph')}</NodataLabel>
              </Col>
            </RowItem>
          )}
          {skillOptions.length ? (
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col span={24}>
                <Row justify="center">
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="large"
                    style={{
                      borderRadius: '8px',
                      width: '100%',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '344054',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={onToggleModal}
                  >
                    {t('addaSkill')}
                  </Button>
                </Row>
              </Col>
            </RoleBasedButton>
          ) : null}
        </Row>

        {modalVisible && skillOptions.length ? (
          <AddSkill
            open={modalVisible}
            skillOptions={skillOptions}
            closeModal={onToggleModal}
            onSubmit={onAddSkill}
            loading={loading}
          />
        ) : null}
      </Skeleton>
    </CardItem>
  );
}
