/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { getRandomColor } from 'utils/utilFunctions';
import { Row, Tag } from 'antd';
import { BenefitTemplate } from 'types/Benefit';
import BenefitsActions from 'components/Actions/BenefitsAction';
import i18next from '../i18n';

const planColors: Record<string, string> = {};

export const BenefitsColums = [
  {
    title: i18next.t('title'),
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    render: (_: string, item: BenefitTemplate) => (
      <Styled.TextBold>{item?.title}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('specifications'),
    dataIndex: 'specifications',
    key: 'specifications',
    ellipsis: true,
    render: (_: string, item: any) => (
      <Styled.Text>{item?.specifications}</Styled.Text>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('plan'),
    dataIndex: 'plan',
    key: 'plan',
    render: (_: string, row: any) => (
      <Row justify="start">
        {row?.plans?.map((plan: any) => {
          if (!planColors[plan]) {
            planColors[plan] = getRandomColor();
          }
          return (
            <Tag
              color={planColors[plan]}
              style={{ borderRadius: '5px', marginBottom: '4px' }}
            >
              {plan?.title?.charAt(0).toUpperCase() + plan?.title?.slice(1)}
            </Tag>
          );
        })}
      </Row>
    ),
    default: true,
    order: 2,
  },

  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: BenefitTemplate) => (
      <BenefitsActions id={row.id} />
    ),
    default: false,
    order: 6,
  },
];
