import { Avatar, Typography } from 'antd';
import styled from 'styled-components';

import { ItemLabel } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';

export const OpenPositionName = styled(Typography.Text)`
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: #278fe6;
`;

export const OpenPositionLogo = styled(Avatar)`
  border-radius: 8px;
`;

export const CompanyLogo = styled(OpenPositionLogo)`
  cursor: pointer;
`;

export const StyledLabel = styled(ItemLabel)`
  font-weight: bold;
`;
