import agent from 'api/agent';
import { ACCESS_TOKEN, TENANT_ID } from 'utils/constants';
import { apiURLs } from './constants';

export const notificationListener = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const X_TENANT_ID = localStorage.getItem(TENANT_ID);
  const reqHeaders: { [key: string]: string } = {};
  if (accessToken) {
    reqHeaders.Authorization = `Bearer ${accessToken}`;
  }

  if (X_TENANT_ID) {
    reqHeaders['x-tenant-id'] = `${X_TENANT_ID}`;
  }

  return fetch(
    `${process.env.REACT_APP_BASE_API}${apiURLs.notifications}/${apiURLs.listener}`,
    {
      method: 'get',
      headers: reqHeaders,
    }
  );
};
export const getAllNotifications = () =>
  agent.post(`${apiURLs.notifications}/get`);
export const markAsRead = (notificationId: string) =>
  agent.post(`${apiURLs.notifications}/markAsRead`, {
    notificationIds: [notificationId],
  });
export const deleteNotification = (notificationId: string) =>
  agent.delete(`${apiURLs.notifications}/${notificationId}`);
export const getUserNotificationsCount = () =>
  agent.get(`${apiURLs.notifications}/count`);
export const clearAllNotifications = () =>
  agent.post(`${apiURLs.notifications}/clear-all`);
