/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { toastErrorMessages } from 'utils/utilFunctions';
import { BenefitTemplate } from 'types/Benefit';
import {
  deleteBenefitTemplate,
  getBenefitTemplates,
  getOneBenefitTemplate,
} from 'api/benefitTemplates';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { MetaPagination } from 'types/GridTable';
import { useTranslation } from 'react-i18next';
import BenefitModalForm from '../BenefitModalForm';

export default function BenefitList() {
  const [modalVisible, setModalVisible] = useState(false);
  const [benefitTemplates, setBenefitTemplates] = useState<BenefitTemplate[]>(
    []
  );
  const [benefitPagination, setBenefitPagination] = useState<MetaPagination>();
  const { columns, take } = useHeader();

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [selectedBenefitTemplate, setSelectedBenefitTemplate] =
    useState<BenefitTemplate>();

  const onToggleModal = () => setModalVisible((state) => !state);

  const { t } = useTranslation();

  const getAllBenefitTemplates = async (page: number, take: number) => {
    setLoading(true);
    try {
      const res = await getBenefitTemplates({ page, take });
      setBenefitTemplates(res?.data?.data);
      setBenefitPagination(res?.data?.meta);
      setLoading(false);
    } catch (error) {
      toastErrorMessages(error);
      setLoading(false);
    }
  };

  async function deleteBenefitById(benefitTemplateId: string) {
    if (benefitTemplateId) {
      try {
        const response = await deleteBenefitTemplate(benefitTemplateId);
        if (response?.status === 200) {
          toast.success(t('Successfully deleted benefit template!'));
          const page = response ? benefitPagination?.page : 1;
          const take = 10;
          await getAllBenefitTemplates(page ?? 1, take);
        }
      } catch (error) {
        toastErrorMessages(error);
      }
    }
  }

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteBenefitById(id?.toString());
    });
  }

  const onSearch = async (searchTerm: string) => {
    const params = {
      page: benefitPagination?.page,
      take,
      filterFields: ['title'],
      filterText: searchTerm,
    };

    setSearchTerm(searchTerm);
    const res = await getBenefitTemplates(params);
    if (res?.status === 200) {
      setBenefitTemplates(res?.data?.data);
    } else {
      toast.error(t('errorWhileSearching'));
    }
  };

  const fetchById = async (benefitId: string) => {
    const res = await getOneBenefitTemplate(benefitId);
    if (res?.status === 200) {
      setSelectedBenefitTemplate(res?.data);
    }
  };

  const onPageChange = async (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    const res = await getBenefitTemplates(params);
    setBenefitTemplates(res?.data?.data);
    setBenefitPagination(res?.data?.meta);
  };

  useEffect(() => {
    if (searchParams.get('added-benefit-template')) {
      searchParams.delete('added-benefit-template');
      setSearchParams(searchParams);
      setSelectedBenefitTemplate(undefined);
      setModalVisible(true);
    }
    if (searchParams.get('edited-benefit-template')) {
      setModalVisible(true);
      fetchById(searchParams.get('edited-benefit-template') ?? '');
      setSearchParams(searchParams);
    }
    if (searchParams.get('deleted-benefit-template')) {
      deleteBenefitById(searchParams.get('deleted-benefit-template') ?? '');
      searchParams.delete('deleted-benefit-template');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    getAllBenefitTemplates(1, 10);
  }, []);

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={benefitTemplates}
          paginationName="benefits"
          onPageChange={onPageChange}
          totalItems={benefitPagination?.itemCount}
          currentPage={benefitPagination?.page}
          totalPages={benefitPagination?.pageCount}
          showSearch
          onSelectedDelete={onDelete}
          onSearch={onSearch}
        />
        {modalVisible && (
          <BenefitModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edited-benefit-template');
              setSearchParams(searchParams);
            }}
            selectedBenefitTemplate={selectedBenefitTemplate}
            fetchBenefitTemplates={getAllBenefitTemplates}
            page={1}
            take={10}
          />
        )}
      </>
    </Content>
  );
}
