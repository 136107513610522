import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import SkillActions from 'components/Actions/SkillActions';
import i18next from 'i18next';

export const SkillsColumns = [
  {
    title: i18next.t('categoryName'),
    dataIndex: 'skills_category_categoryName',
    key: 'skills_category_categoryName',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.skills_category_categoryName.localeCompare(
        b.skills_category_categoryName
      ),
    render: (_: string, item: any) => (
      <Styled.TextBold>{item?.skills_category_categoryName}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('numberofSkills'),
    dataIndex: 'skillSubCategoryCount',
    key: 'skillSubCategoryCount',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.skillSubCategoryCount.localeCompare(b.skillSubCategoryCount),
    render: (_: string, item: any) => (
      <Styled.TextBold>{item?.skillSubCategoryCount}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => (
      <SkillActions id={row.skills_category_id} />
    ),
    default: false,
    order: 2,
  },
];
