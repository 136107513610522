import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { City, ICity } from 'country-state-city';
import { InputTypes } from 'types/FormTypes';
import { SettingTab } from 'types/settings';
import { useTranslation } from 'react-i18next';
import { TeanantLocation } from 'types/TenantLocation';
import { toast } from 'react-toastify';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  toastErrorMessages,
} from 'utils/utilFunctions';
import GenericModal from 'ui-v2/components/GenericModal';
import {
  createTenantLocation,
  editTenantLocation,
} from 'api/tenantLocationService';
import moment from 'moment';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { dateFormatOptions } from 'utils/constants';
import countryCodes from 'country-codes-list';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import countriesJson from '../../../countries.json';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedLocation?: TeanantLocation;
  fetchLocations?: any;
  xTenantId: string;
}

interface CountryType {
  name: {
    common: string;
  };
  cca2: string;
}

export default function TenantLocationForm({
  open,
  closeModal,
  selectedLocation,
  fetchLocations,
  xTenantId,
}: IProps) {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [countries] = useState<Array<CountryType>>(
    countriesJson.sort(
      (a: { name: { common: string } }, b: { name: { common: string } }) =>
        a.name.common.localeCompare(b.name.common)
    )
  );
  const [cities, setCities] = useState([]);
  const [code, setCode] = useState<string>();

  const myCountryCodesObject = countryCodes.customList(
    'countryCode' as any,
    '+{countryCallingCode}'
  );
  const { t } = useTranslation();

  function fetchCities(countryName: string) {
    const country = countries.find((c) => c.name.common === countryName);
    if (country) {
      const cities = City.getCitiesOfCountry(country.cca2 as string);
      setCities(cities as []);
      const countryCodeInfo =
        myCountryCodesObject[country.cca2 as keyof typeof myCountryCodesObject];
      if (countryCodeInfo) {
        setCode(countryCodeInfo);
      }
    }
  }

  useEffect(() => {
    if (selectedLocation) {
      form.setFieldsValue({
        country: selectedLocation.country,
        city: selectedLocation.city,
        streetName: selectedLocation.streetName,
        postalCode: selectedLocation.postalCode,
        countryCode: selectedLocation?.countryCode,
        timezone: selectedLocation?.timezone,
        ...(selectedLocation?.isDefaultLocation && {
          isDefaultLocation: true,
        }),
        ...(selectedLocation?.workingHourStart && {
          workingHourStart: convertUTCtoLocalTime(
            selectedLocation?.workingHourStart
          ),
        }),
        ...(selectedLocation?.workingHourEnd && {
          workingHourEnd: convertUTCtoLocalTime(
            selectedLocation?.workingHourEnd
          ),
        }),
        ...(selectedLocation?.currency?.id && {
          currencyId: selectedLocation?.currency?.id,
        }),
        ...(selectedLocation?.dateTimeFormat && {
          dateTimeFormat: selectedLocation?.dateTimeFormat,
        }),
        ...(selectedLocation?.address && {
          address: selectedLocation?.address,
        }),
        ...(selectedLocation?.zipCode && {
          zipCode: selectedLocation?.zipCode,
        }),
      });
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation && selectedLocation.country) {
      fetchCities(selectedLocation.country);
    }
  }, [selectedLocation, countries]);

  useEffect(() => {
    if (searchParams.get('settings-save') === SettingTab.APIKEYS) {
      searchParams.delete('settings-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams]);

  function onFormFinish(values: TeanantLocation) {
    const valuesToSend: TeanantLocation = {
      ...values,
      workingHourStart: convertDateToUTC(values?.workingHourStart),
      workingHourEnd: convertDateToUTC(values?.workingHourEnd),
    };
    setLoading(true);

    if (selectedLocation?.id) {
      editTenantLocation(selectedLocation?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('tenantLocationUpdatedSuccessfully'));
            searchParams.append('edited-location', 'true');
            closeModal();
            fetchLocations(xTenantId);
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createTenantLocation(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('tenantLocationCreatedSuccessfully'));
            searchParams.append('added-location', 'true');
            closeModal();
            fetchLocations(xTenantId);
          }
        })
        .catch(() => {
          toast.error(t('Faild to create Location!'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const TenantLocationFormConfigurations: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'isDefaultLocation',
          label: t('defaultLocation'),
          type: InputTypes.SWITCH,
          switchProps: {
            checkedChildren: t('yes'),
            unCheckedChildren: t('no'),
          },
          defaultChecked: !!selectedLocation?.isDefaultLocation,
          direction: 'vertical',
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'country',
          label: t('country'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: countries?.map((country: any) => ({
            id: country.name.common,
            label: (
              <div>
                <img
                  src={country.flags.svg}
                  alt={country.cca2}
                  style={{ width: 20, marginRight: 10 }}
                />
                {country.name.common}
              </div>
            ),
            value: country.name.common,
          })),
          onSelectChange: fetchCities,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'countryCode',
          label: t('countryCode'),
          type: InputTypes.INPUT,
          disabled: true,
          defaultValue: code,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'currencyId',
          label: t('currency'),
          type: InputTypes.SELECT,
          selectOptions: currencyOptions,
          defaultValue: selectedLocation?.currency?.id,
          showSearch: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'timezone',
          label: t('timezone'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: moment.tz.names().map((name) => ({
            label: name,
            value: name,
            id: name,
          })),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'city',
          label: t('city'),
          showSearch: true,
          type: InputTypes.SELECT,
          selectOptions: cities.map((city: ICity) => ({
            id: `${city.name}, ${city.stateCode}`,
            label: `${city.name}, ${city.stateCode}`,
            value: `${city.name}, ${city.stateCode}`,
          })),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'streetName',
          label: t('streetName'),
          type: InputTypes.INPUT,
          defaultValue: selectedLocation?.streetName,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'postalCode',
          label: t('postalCode'),
          type: InputTypes.INPUT,
          defaultValue: selectedLocation?.postalCode,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'address',
          label: t('address'),
          type: InputTypes.INPUT,
          defaultValue: selectedLocation?.address,
          inputProps: {
            type: 'text',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'workingHourStart',
          label: t('workdaystartTime'),
          type: InputTypes.TIMEPICKER,
          defaultValue: convertUTCtoLocalTime(
            selectedLocation?.workingHourStart
          ),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'workingHourEnd',
          label: t('workdayendTime'),
          type: InputTypes.TIMEPICKER,
          defaultValue: convertUTCtoLocalTime(selectedLocation?.workingHourEnd),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'dateTimeFormat',
          label: t('dateTimeFormat'),
          type: InputTypes.SELECT,
          selectOptions: dateFormatOptions,
          defaultValue: selectedLocation?.dateTimeFormat,
          showSearch: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [countries, cities, selectedLocation, currencyOptions]
  );

  return (
    <GenericModal
      title={selectedLocation?.id ? t('editLocation') : t('addLocation')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={TenantLocationFormConfigurations}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
