import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTenantStore from 'redux/persisted-state/tenant-store';
import { RootState } from 'redux/store';
import { fetchSingleTenant, fetchTenants } from 'redux/tenants/actions';
import { TenantsState } from 'redux/tenants/types';
import {
  TENANT_ID,
  USER_DATE_FORMAT,
  DEFAULT_USER_DATE_FORMAT,
} from 'utils/constants';
import { TenantsQueryParamsType } from 'types/QueryParams';
import { AuthUserState } from 'redux/authUser/types';

export function useTenantsData() {
  const dispatch = useDispatch();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { tenant: tenantPersisted, setTenant } = useTenantStore();
  const tenantsState: TenantsState = useSelector(
    (state: RootState) => state.tenants
  );

  const tenantDetails = tenantsState?.singleTenanat?.data;
  const tenantId = localStorage.getItem(TENANT_ID);
  const userDateFormat = localStorage.getItem(USER_DATE_FORMAT);

  useEffect(() => {
    if (authUser?.id && tenantPersisted?.id && !userDateFormat) {
      localStorage.setItem(USER_DATE_FORMAT, DEFAULT_USER_DATE_FORMAT);
    }
  }, [authUser?.id, tenantPersisted?.id, userDateFormat]);

  const getTenants = (queryParams: TenantsQueryParamsType) => {
    dispatch(
      fetchTenants({
        queryParams,
      })
    );
  };

  const getTenantDetails = useCallback(
    (id: string) => {
      if (
        !tenantsState?.singleTenanat?.loaded &&
        !tenantsState?.singleTenanat?.loading
      ) {
        dispatch(fetchSingleTenant(id));
      }
    },
    [tenantsState?.singleTenanat?.loaded, tenantsState?.singleTenanat?.loading]
  );

  useEffect(() => {
    if (tenantDetails?.id) {
      setTenant(tenantDetails);
    }
  }, [tenantDetails?.id]);

  const fetchTenant = useCallback(() => {
    if (tenantId) {
      getTenantDetails(tenantId);
    }
  }, [tenantId]);

  return {
    ...tenantsState,
    tenantPersisted,
    getTenantDetails,
    getTenants,
    fetchTenant,
  };
}
