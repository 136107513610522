import React from 'react';
import { Col, Row } from 'antd';
import { toast } from 'react-toastify';

import { removeTimeOff } from 'api/timeOffService';
import Content from 'ui-v2/components/Content';
import useTimeOffRequestsData from 'ui-v2/hooks/useTimeOffRequestsData';
import RequestList from './RequestList';
import TimeOffCalendarCard from './TimeOffCalendarCard';

export default function TimeOff() {
  const {
    employeeTimeOffList,
    loading,
    fetchTimeOffEmployee,
    currentPage,
    PAGE_SIZE,
    onPageChange,
    total,
  } = useTimeOffRequestsData();

  const onDeleteRequest = (timeOffId: string) => {
    removeTimeOff(timeOffId)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Timeoff deleted!');
          fetchTimeOffEmployee(currentPage, PAGE_SIZE);
        }
      })
      .catch(() => {
        toast.error('Failed to delete timeOff!');
      });
  };

  return (
    <Content position="stretch">
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <RequestList
            employeeTimeOffList={employeeTimeOffList}
            loading={loading}
            onDeleteRequest={onDeleteRequest}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            onChange={onPageChange}
            total={total}
          />
        </Col>
        <Col>
          <TimeOffCalendarCard
            fetch={fetchTimeOffEmployee}
            timeOffList={employeeTimeOffList}
          />
        </Col>
      </Row>
    </Content>
  );
}
