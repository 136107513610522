import React from 'react';

export const FilesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 2.06934V6.19988C11 6.75993 11 7.03995 11.109 7.25387C11.2049 7.44203 11.3578 7.59501 11.546 7.69088C11.7599 7.79988 12.0399 7.79988 12.6 7.79988H16.7305M11 16.7998H5M13 12.7998H5M17 9.78803V16.9998C17 18.68 17 19.52 16.673 20.1618C16.3854 20.7263 15.9265 21.1852 15.362 21.4728C14.7202 21.7998 13.8802 21.7998 12.2 21.7998H5.8C4.11984 21.7998 3.27976 21.7998 2.63803 21.4728C2.07354 21.1852 1.6146 20.7263 1.32698 20.1618C1 19.52 1 18.68 1 16.9998V6.5998C1 4.91965 1 4.07957 1.32698 3.43783C1.6146 2.87335 2.07354 2.41441 2.63803 2.12679C3.27976 1.7998 4.11984 1.7998 5.8 1.7998H9.01178C9.74555 1.7998 10.1124 1.7998 10.4577 1.88269C10.7638 1.95619 11.0564 2.0774 11.3249 2.24188C11.6276 2.42741 11.887 2.68684 12.4059 3.20569L15.5941 6.39392C16.113 6.91277 16.3724 7.1722 16.5579 7.47495C16.7224 7.74337 16.8436 8.036 16.9171 8.34211C17 8.68737 17 9.05426 17 9.78803Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
