import { ProjectDTOType, ProjectUpdateType } from 'types/Project';
import agent from 'api/agent';
import { ProjectDocumentCreate } from 'types/ProjectDocument';
import { QueryParamsDTO, QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllProjects = (queryParams: QueryParamsType) =>
  agent.post(
    `${apiURLs.project}/get`,
    searchFields(queryParams, apiURLs.project)
  );

export const searchProjects = (searchText: string | null) =>
  agent.get(`${apiURLs.project}?name=${searchText}`);

export const searchAllProjects = (search: string) =>
  agent.get(
    `${apiURLs.project}?&sortBy=createdAt&sortType=DESC&name=${search}`
  );

export const createNewProject = (values: ProjectDTOType) =>
  agent.post(apiURLs.project, values);

export const updateProject = (value: ProjectUpdateType, id: string) =>
  agent.put(`${apiURLs.project}/${id}`, value);

export const getProjectById = (id: string) =>
  agent.get(`${apiURLs.project}/${id}`);

export const createProjectDocument = (values: ProjectDocumentCreate) =>
  agent.post(apiURLs.projectDocuments, values);

export const deleteProjectDocument = (id: string) =>
  agent.delete(`${apiURLs.projectDocuments}/${id}`);

export const deleteProject = (id: string) =>
  agent.delete(`${apiURLs.project}/${id}`);

export const getEmployeeOwnProjects = () =>
  agent.get(`${apiURLs.project}/getEmployeeOwnProjects`);

export const geProjectAssignmentOfEmployee = (employeeId: string) =>
  agent.get(`${apiURLs.project}/${employeeId}/assignedProjects`);

export const getProjectEmployees = (projectId: string) =>
  agent.get(`${apiURLs.projectEmployees}/${projectId}`);

export const getProjectSelectOptions = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.project}/selectOptions`, queryParams);
