import {
  TenantConfigDTO,
  TenantConfigUpdateDTO,
  TenantOnboardingConfigPayload,
} from 'types/TenantConfig';

import { TENANT_ID } from 'utils/constants';
import agent from './agent';
import { apiURLs } from './constants';

export namespace TenantConfigApiClient {
  export interface TenantConfigRequest {
    id: string;
  }

  export interface TenantConfigUpdateRequest {
    payload: TenantConfigUpdateDTO;
  }

  export const getAllTenantConfigs = () =>
    agent.get(`${apiURLs.tenantConfig}/all`);

  export const getTenantConfig = ({ id }: TenantConfigRequest) =>
    agent.get(`${apiURLs.tenantConfig}/${id}`);

  export const getCurrentTenantConfig = () => {
    const id = localStorage.getItem(TENANT_ID);
    return agent.get(`${apiURLs.tenantConfig}/${id}`);
  };

  export const updateTenantConfig = ({ payload }: TenantConfigUpdateRequest) =>
    agent.patch(`${apiURLs.tenantConfig}`, payload);
}

export const getAllTenantConfig = () =>
  agent.get(`${apiURLs.tenantConfig}/all`);

export const getTenantConfigById = (configId: string) =>
  agent.get(`${apiURLs.tenantConfig}/${configId}`);

// create update on same api
export const updateTenantConfig = (payload: TenantConfigDTO) =>
  agent.patch(`${apiURLs.tenantConfig}`, payload);

export const getConfig = () => agent.get(`${apiURLs.tenantConfig}`);

export const completeTenantOnboardingConfig = (
  payload: TenantOnboardingConfigPayload
) => agent.post(`${apiURLs.tenantConfig}/complete-onboarding`, payload);
