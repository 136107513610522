import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchAssetOptions } from 'components/NewForms/SelectWithLoad/utils';
import { AssetQueryParamsType } from 'types/QueryParams';
import { fetchAssets } from 'redux/assets/actions';
import { AssetStatusTag } from 'types/Asset';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';
import i18next from '../../../../i18n';

export default function AssetFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();

  async function onFormChange() {
    const assetId: string = form.getFieldValue('assetId')?.value ?? '';
    const assetStatus: string = form.getFieldValue('status');

    const filterAssetTable: AssetQueryParamsType = {
      assetId,
      assetStatus,
    };
    if (filterAssetTable) {
      if (assetId) dispatch(fetchAssets({ assetId }));
      if (assetStatus) dispatch(fetchAssets({ assetStatus }));
    }
  }

  const AssetFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'assetId',
          label: i18next.t('name'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchAssetOptions,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'status',
          label: i18next.t('status'),
          type: InputTypes.SELECT,
          selectOptions: Object.values(AssetStatusTag).map((item: string) => ({
            id: item,
            value: item,
            label: i18next.t(item),
          })),
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={AssetFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchAssets({ page: 1, take: 10 }));
            }}
          >
            {i18next.t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
