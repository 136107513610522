import React from 'react';

export const DashboardOpenPositionsIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
    <path
      d="M32 23C32 22.07 32 21.605 31.8978 21.2235C31.6204 20.1883 30.8117 19.3796 29.7765 19.1022C29.395 19 28.93 19 28 19C27.07 19 26.605 19 26.2235 19.1022C25.1883 19.3796 24.3796 20.1883 24.1022 21.2235C24 21.605 24 22.07 24 23M21.2 37H34.8C35.9201 37 36.4802 37 36.908 36.782C37.2843 36.5903 37.5903 36.2843 37.782 35.908C38 35.4802 38 34.9201 38 33.8V26.2C38 25.0799 38 24.5198 37.782 24.092C37.5903 23.7157 37.2843 23.4097 36.908 23.218C36.4802 23 35.9201 23 34.8 23H21.2C20.0799 23 19.5198 23 19.092 23.218C18.7157 23.4097 18.4097 23.7157 18.218 24.092C18 24.5198 18 25.0799 18 26.2V33.8C18 34.9201 18 35.4802 18.218 35.908C18.4097 36.2843 18.7157 36.5903 19.092 36.782C19.5198 37 20.0799 37 21.2 37Z"
      stroke="#D92D20"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#FEF3F2"
      strokeWidth="8"
    />
  </svg>
);
