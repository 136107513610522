import agent from 'api/agent';
import { Knowledge } from 'types/Knowledge';
import { apiURLs } from './constants';

export const createKnowledge = async (values: Knowledge) =>
  agent.post(apiURLs.knowledgeTransfer, values);

export const getKnowledgeForEmployee = async (id: string) =>
  agent.get(`${apiURLs.knowledgeTransfer}/employee/${id}`);

export const updateKnowledge = async (values: Knowledge, id: string) =>
  agent.patch(`${apiURLs.knowledgeTransfer}/${id}`, values);
