import { Col, Row, Skeleton } from 'antd';
import styled from 'styled-components';

export const StyledTrackingCard = styled(Row)(({ theme }) => ({
  backgroundColor: theme.colors.palette.base.white,
  border: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  borderRadius: '4px',
}));

export const StyledTrackingCardPanel = styled(Col)(({ theme }) => ({
  backgroundColor: theme.colors.palette.primary.gray['100'],
  padding: '1.5rem 1rem',
  maxHeight: '4rem',
  borderTopLeftRadius: 'inherit',
  borderTopRightRadius: 'inherit',
  borderBottom: 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTrackingCardSkeleton = styled(Skeleton.Avatar)((): any => ({
  width: '100%',
  '.ant-skeleton-avatar': {
    minHeight: '25rem',
    width: '100%',
  },
}));

export const StyledTrackingCardNoData = styled.div(() => ({
  width: '100%',
  minHeight: '25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledTrackingCardContent = styled(Col)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'start',
  '& .ant-table-wrapper': {
    border: 'none',
    boxShadow: 'none',
  },
  '& .ant-table-column-sorters': {
    width: 'fit-content !important',
    margin: 'unset',
  },
}));
