import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  clearEvaluationPeriod,
  fetchEvaluationPeriods,
  fetchSingleEvaluation,
} from 'redux/evaluationPeriods/actions';
import { useEffect } from 'react';
import { EvaluationPeriodsType } from 'types/EvaluationPeriods';
import useHeader from './useHeader';

export function useEvaluationPeriodsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.evaluationPeriods
  );
  const { data: evaluationPeriod } = useSelector(
    (state: any) => state.singleEvaluationPeriod
  );

  const [searchParams] = useSearchParams();
  const { entity } = useHeader();
  const dispatch = useDispatch();
  const evaluationId = searchParams.get('id');

  if (entity === 'evaluations' && !loaded && !loading) {
    dispatch(fetchEvaluationPeriods({ pageOptionsDto: { page: 1, take: 10 } }));
  }

  useEffect(() => {
    if (evaluationId) {
      dispatch(fetchSingleEvaluation(evaluationId));
    }
    return () => {
      dispatch(clearEvaluationPeriod());
    };
  }, [evaluationId]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    evaluation: data?.data?.find(
      (item: EvaluationPeriodsType) =>
        item.id === searchParams.get('edit-evaluation') ||
        item.id === searchParams.get('view-evaluation') ||
        evaluationPeriod
    ),
    loading,
    loaded,
    errored,
    error,
  };
}
