import React, { useCallback, useState } from 'react';
import { Col, Popconfirm, Row } from 'antd';
import { Assignment } from 'types/Assignment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  ItemValue,
  ItemLabel,
  RowItem,
} from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { deleteAssignment } from 'api/assignmentService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useDispatch } from 'react-redux';
import { fetchSingleProject } from 'redux/projects/actions';
import { useSearchParams } from 'react-router-dom';
import {
  StyledProjectEmployeesDrilldownAssignmentCol,
  StyledProjectEmployeesDrilldownIconButton,
  StyledProjectEmployeesRow,
} from './StyledProjectEmployees';
import AssignEmployeeModal from './AssignEmployeeModal';

interface ProjectEmployeeDrilldown {
  assignments: Assignment[];
}

const ProjectEmployeeDrilldown: React.FC<ProjectEmployeeDrilldown> = ({
  assignments,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();

  const toogleModalVisibility = useCallback(() => {
    setSelectedAssignment(undefined);
  }, [selectedAssignment]);

  const onEditClick = useCallback(
    (assignment: Assignment) => {
      setSelectedAssignment(assignment);
    },
    [selectedAssignment]
  );

  const onDeleteClick = useCallback(
    (assignmentId: string) => {
      deleteAssignment(assignmentId)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('assignmentDeletedSuccessfully'));
            if (searchParams.get('id')) {
              dispatch(fetchSingleProject(searchParams.get('id') as string));
            }
          }
        })
        .catch(toastErrorMessages);
    },
    [selectedAssignment, searchParams]
  );

  return (
    <>
      <StyledProjectEmployeesRow gutter={[0, 10]}>
        <Col span={24}>
          <RowItem>
            <ItemLabel>{t('Assignments')}</ItemLabel>
          </RowItem>
        </Col>
        <Col span={24}>
          <StyledProjectEmployeesRow style={{ padding: '0 .5rem' }}>
            <Col span={5}>
              <ItemLabel>{t('startDate')}</ItemLabel>
            </Col>
            <Col span={5}>
              <ItemLabel>{t('endDate')}</ItemLabel>
            </Col>
            <Col span={5}>
              <ItemLabel>{t('role')}</ItemLabel>
            </Col>
            <Col span={5}>
              <ItemLabel>{t('Manager')}</ItemLabel>
            </Col>
          </StyledProjectEmployeesRow>
        </Col>

        <Col span={24}>
          <StyledProjectEmployeesRow gutter={[0, 10]}>
            {assignments?.map((assignment) => (
              <StyledProjectEmployeesDrilldownAssignmentCol
                span={24}
                key={assignment.id}
              >
                <Row>
                  <Col span={5}>
                    <ItemValue ellipsis>
                      {assignment.startDate
                        ? moment(assignment.startDate)
                            .local()
                            .format('DD/MM/YYYY')
                        : '-'}
                    </ItemValue>
                  </Col>
                  <Col span={5}>
                    <ItemValue ellipsis>
                      {assignment?.endDate
                        ? moment(assignment.endDate)
                            .local()
                            .format('DD/MM/YYYY')
                        : '-'}
                    </ItemValue>
                  </Col>
                  <Col span={5}>
                    <ItemValue ellipsis>
                      {assignment.rolesAssignment?.name || '-'}
                    </ItemValue>
                  </Col>
                  <Col span={5}>
                    <ItemValue
                      ellipsis
                      type={assignment.isManager ? 'success' : 'secondary'}
                    >
                      {`${assignment.isManager ? 'Yes' : 'No'}`}
                    </ItemValue>
                  </Col>
                  <Col span={4}>
                    <Row justify="end" gutter={[15, 0]}>
                      <Col>
                        <StyledProjectEmployeesDrilldownIconButton
                          onClick={() => onEditClick(assignment)}
                        >
                          <EdititingIcon />
                        </StyledProjectEmployeesDrilldownIconButton>
                      </Col>
                      <Col>
                        <Popconfirm
                          title={t('doYouWantToProceed')}
                          onConfirm={() => onDeleteClick(assignment.id)}
                        >
                          <StyledProjectEmployeesDrilldownIconButton>
                            <DeleteIcon />
                          </StyledProjectEmployeesDrilldownIconButton>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledProjectEmployeesDrilldownAssignmentCol>
            ))}
          </StyledProjectEmployeesRow>
        </Col>
      </StyledProjectEmployeesRow>
      {selectedAssignment && (
        <AssignEmployeeModal
          open
          assignment={selectedAssignment}
          closeModal={toogleModalVisibility}
        />
      )}
    </>
  );
};

export default ProjectEmployeeDrilldown;
