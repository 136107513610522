/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton, Typography } from 'antd';
import {
  deleteCandidateDocument,
  getAllCandidateDocuments,
} from 'api/candidateDocumentService';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CandidateDocumentType } from 'types/CandidateDocument';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import * as Styled from 'ui-v2/components/WorkExperience/WorkExperienceStyles';
import { useTranslation } from 'react-i18next';
import CardItem from '../Card';
import { DocumentFileItem } from '../Documents/FileItem';
import { RowItem } from '../PersonalInfo/PersonalInfoStyles';
import RightSection from '../RightSection';
import CandidateDocumentModal from './CandidateDocumentModal';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';

interface CandidateDocumentProps {
  isDisabled: boolean;
}

export function CandidateDocuments({ isDisabled }: CandidateDocumentProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
  const { candidate, loading, candidateLoaded, candidateOffer } =
    useCandidateData();
  const onToggleModal = () => setModalVisible((state) => !state);
  const [candidateDocuments, setCandidateDocuments] = useState<
    CandidateDocumentType[]
  >([]);
  const { t } = useTranslation();

  async function fetchDocuments(candidateId: string) {
    setIsLoadingDocuments(true);
    getAllCandidateDocuments(candidateId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data) {
            setCandidateDocuments(response.data.data);
          }
        }
      })
      .catch(() => {
        toast.error(t("Something went wrong! Can't get candidate documents!"));
      })
      .finally(() => {
        setIsLoadingDocuments(false);
      });
  }

  async function onDeleteCallBack(id: string) {
    setIsLoadingDocuments(true);
    deleteCandidateDocument(id)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('Document was deleted successfully!'));
        }
      })
      .catch(() => {
        toast.error(t("Something went wrong, document didn't delete!"));
      })
      .finally(() => fetchDocuments(candidate?.id as string));
    setIsLoadingDocuments(false);
  }

  const onButtonClick = () => {
    onToggleModal();
  };

  useEffect(() => {
    if (candidate?.id && candidateLoaded && candidateOffer?.loaded) {
      fetchDocuments(candidate.id as string);
    }
  }, [candidate?.id, candidateLoaded, candidateOffer?.loaded]);

  const onSuccess = () => fetchDocuments(candidate?.id as string);

  return (
    <CardItem
      title={t('addDocuments')}
      disabled={isDisabled}
      rightButton={
        candidateDocuments?.length !== 0 ? (
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <RightSection
              buttonCallBack={() => onToggleModal()}
              buttonText={t('addDocuments')}
            />
          </RoleBasedButton>
        ) : undefined
      }
    >
      <>
        <Skeleton
          loading={loading || isLoadingDocuments}
          active
          title={false}
          paragraph={{ rows: 6, width: '100%' }}
        >
          <>
            <Row gutter={[0, 24]}>
              {candidateDocuments.length === 0 ? (
                <>
                  <Styled.RowItem justify="center" last>
                    <Col>
                      <Styled.NodataLabel>
                        {t('there are no documents yet')}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem last justify="center">
                    <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                      <Col span={24}>
                        <Button
                          icon={<PlusCircleOutlined />}
                          size="large"
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            fontWeight: '600',
                            fontSize: '16px',
                            color: '344054',
                          }}
                          onClick={() => onButtonClick()}
                        >
                          {t('addDocument')}
                        </Button>
                      </Col>
                    </RoleBasedButton>
                  </Styled.RowItem>
                </>
              ) : (
                <>
                  {candidateDocuments?.map(
                    (document: CandidateDocumentType) => (
                      <RowItem
                        last
                        disabled={false}
                        gutter={[0, 10]}
                        align="middle"
                      >
                        <Col span={24}>
                          <Item label="" value={document?.type}>
                            <Row gutter={[0, 10]}>
                              <Col span={24}>
                                <Typography>{document?.description}</Typography>
                              </Col>
                              <Col span={24}>
                                <DocumentFileItem
                                  filename={document?.name}
                                  filecontent={document?.url}
                                  filesize={document?.size}
                                  onDelete={() => onDeleteCallBack(document.id)}
                                />
                              </Col>
                            </Row>
                          </Item>
                        </Col>
                      </RowItem>
                    )
                  )}
                </>
              )}
            </Row>

            {modalVisible && (
              <CandidateDocumentModal
                open={modalVisible}
                closeModal={() => {
                  setModalVisible(false);
                }}
                candidateId={candidate?.id as string}
                onSuccess={onSuccess}
              />
            )}
          </>
        </Skeleton>
      </>
    </CardItem>
  );
}
