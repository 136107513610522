import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { TenantConfigState } from 'redux/tenantConfig/types';

export function useTenantConfigData() {
  const tenantConfigState = useSelector(
    (state: RootState): TenantConfigState => state.tenantConfig
  );

  return {
    ...tenantConfigState,
  };
}
