import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProps {
  isAllowed: boolean;
  children?: React.ReactElement;
}

export default function ProtectedRoute({ isAllowed, children }: IProps) {
  if (!isAllowed) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
}
