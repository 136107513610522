import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  clearScheduleInterview,
  fetchCandidate,
} from 'redux/candidates/actions';
import { registerEmployee } from 'api/employeeService';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { HireCandidateInputType } from 'types/Candidates';
import { clearFetchTasks } from 'redux/tasks/actions';

import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { convertDateToUTC, getUserDateFormat } from 'utils/utilFunctions';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import { useWorkPositionsSkimData } from 'ui-v2/hooks/useWorkPositionsSkimData';
import GenericForm from '../Form/GenericForm';

export default function HireCandidateForm() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const { candidate, candidateLoaded } = useCandidateData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { filteredWorkPositions } = useWorkPositionsSkimData();

  const onSubmit = (values: HireCandidateInputType) => {
    if (!candidate?.id) return;
    setLoading(true);

    let payload: HireCandidateInputType = {
      ...candidate,
      ...values,
      resume: {
        name: '-',
        content: 'string',
      },

      workPositionId: form.getFieldValue('workPositionId').value,
      onBoardingStartDate: convertDateToUTC(values.onBoardingStartDate),
      hireDate: convertDateToUTC(values.hireDate),
      salary: Number(form.getFieldValue('salary')),
    };
    if (!values?.onBoardingEndDate) {
      delete values?.onBoardingEndDate;
    } else {
      payload = {
        ...payload,
        onBoardingEndDate: convertDateToUTC(values.onBoardingEndDate),
      };
    }

    registerEmployee(payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('Candidate is hired successfully'));
          dispatch(clearScheduleInterview());
          dispatch(clearFetchTasks());
          if (response?.data?.id) {
            navigate(`/employees/employee-profile?id=${response?.data?.id}`);
          }
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || t('somethingWentWrong'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchParams.get('hire-candidate-save') === 'true') {
      searchParams.delete('hire-candidate-save');
      setSearchParams(searchParams);
      form.submit();
    }
    if (searchParams.get('id') && !candidateLoaded) {
      dispatch(fetchCandidate(searchParams.get('id') as string));
    }
  }, [searchParams]);

  const onBoardingStatus = [
    {
      id: 'New',
      value: 'New',
      label: t('new'),
    },
    {
      id: 'inProgress',
      value: 'In Progress',
      label: t('inProgress'),
    },
    {
      id: 'Done',
      value: 'Done',
      label: t('done'),
    },
  ];

  const HireCandidateFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 10,
          offset: 0,
          name: 'workEmail',
          label: t('workEmail'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          rules: [
            {
              required: true,
              message: t('workEmailisRequired!'),
            },
          ],
        },
        {
          col: 10,
          offset: 4,
          name: 'ssn',
          label: t('ssn'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('ssnisRequired!'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'hireDate',
          label: t('hireDate'),
          type: InputTypes.DATEPICKER,
          rules: [
            {
              required: true,
              message: t('Hire Date is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'onBoardingStatus',
          label: t('onboardingProccess'),
          type: InputTypes.SELECT,
          selectOptions: onBoardingStatus,
          rules: [
            {
              required: true,
              message: t('On Boarding Status is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 10,
          offset: 0,
          name: 'onBoardingStartDate',
          label: t('onBoardingStartDate'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
            showTime: false,
          },
          rules: [
            {
              required: true,
              message: t('On Boarding Start Date is required!'),
            },
          ],
        },
        {
          col: 10,
          offset: 4,
          name: 'onBoardingEndDate',
          label: t('onBoardingEndDate'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(
                form.getFieldValue('onBoardingStartDate')
              ),
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'workPositionId',
          label: t('workPositions'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: filteredWorkPositions,
          rules: [
            {
              required: true,
              message: t('Work Positions is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'certifications',
          label: t('certifications'),
          type: InputTypes.INPUT,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'salary',
          label: t('grossSalary'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('On Boarding Status is required!'),
            },
          ],
        },
      ],
    ],
    [candidate, filteredWorkPositions]
  );

  return (
    <CardItem title="">
      <GenericForm
        formConfiguration={HireCandidateFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      />
    </CardItem>
  );
}
