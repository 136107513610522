import React from 'react';

export const DashboardCandidateIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
    <path
      d="M20 37.8174C20.6026 38 21.4165 38 22.8 38H33.2C34.5835 38 35.3974 38 36 37.8174M20 37.8174C19.8708 37.7783 19.7513 37.7308 19.638 37.673C19.0735 37.3854 18.6146 36.9265 18.327 36.362C18 35.7202 18 34.8802 18 33.2V22.8C18 21.1198 18 20.2798 18.327 19.638C18.6146 19.0735 19.0735 18.6146 19.638 18.327C20.2798 18 21.1198 18 22.8 18H33.2C34.8802 18 35.7202 18 36.362 18.327C36.9265 18.6146 37.3854 19.0735 37.673 19.638C38 20.2798 38 21.1198 38 22.8V33.2C38 34.8802 38 35.7202 37.673 36.362C37.3854 36.9265 36.9265 37.3854 36.362 37.673C36.2487 37.7308 36.1292 37.7783 36 37.8174M20 37.8174C20.0004 37.0081 20.0052 36.5799 20.0769 36.2196C20.3925 34.6329 21.6329 33.3925 23.2196 33.0769C23.606 33 24.0707 33 25 33H31C31.9293 33 32.394 33 32.7804 33.0769C34.3671 33.3925 35.6075 34.6329 35.9231 36.2196C35.9948 36.5799 35.9996 37.0081 36 37.8174M32 25.5C32 27.7091 30.2091 29.5 28 29.5C25.7909 29.5 24 27.7091 24 25.5C24 23.2909 25.7909 21.5 28 21.5C30.2091 21.5 32 23.2909 32 25.5Z"
      stroke="#DC6803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#FFFAEB"
      strokeWidth="8"
    />
  </svg>
);
