import { Col, Row } from 'antd';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DEFAULT_USER_DATE_FORMAT } from 'utils/constants';
import {
  ItemLabel,
  ItemValue,
} from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { CheckBox } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/ConfigureComponentStyles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { uniqueId } from 'lodash';
import { PayrollHoliday } from '../PayrollDateModal/PayrollDateModal';

interface HolidaySelectableListProps {
  holidays: PayrollHoliday[];
  selectedHolidays: PayrollHoliday[];
  setSelectedHolidays: (holidays: PayrollHoliday[]) => void;
}

const HolidaySelectableList: React.FC<HolidaySelectableListProps> = ({
  holidays,
  selectedHolidays,
  setSelectedHolidays,
}) => {
  const { t } = useTranslation();

  const onChange = (e: CheckboxChangeEvent, holiday: PayrollHoliday) => {
    if (e.target.checked) {
      setSelectedHolidays([...selectedHolidays, holiday]);
    } else {
      setSelectedHolidays(
        selectedHolidays.filter((el) => el.name !== holiday.name)
      );
    }
  };

  if (!holidays.length) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <ItemValue>{`${t('nationalHolidaysToConsider')}:`}</ItemValue>
      </Col>
      {holidays.map((holiday) => (
        <Col span={24} key={uniqueId(holiday.date.toISOString())}>
          <CheckBox
            checked={selectedHolidays.includes(holiday)}
            onChange={(c) => onChange(c, holiday)}
          >
            <Row gutter={[10, 0]}>
              <Col>
                <ItemLabel>
                  {moment(holiday.date).format(DEFAULT_USER_DATE_FORMAT)}
                </ItemLabel>
              </Col>
              <Col>
                <ItemLabel>-</ItemLabel>
              </Col>
              <Col>
                <ItemLabel>{holiday?.name}</ItemLabel>
              </Col>
            </Row>
          </CheckBox>
        </Col>
      ))}
    </Row>
  );
};

export default HolidaySelectableList;
