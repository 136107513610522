import { Col, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { InputTypes } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeModal: () => void;
  onFinish: (date: Date) => void;
}

function DateModal({ open, closeModal, onFinish }: IProps) {
  const [, setPayrollDate] = useState<Date | null>(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  async function onFormFinish() {
    const formValues = form.getFieldsValue(true);
    onFinish(formValues.date);
    setPayrollDate(null);
  }

  const DateModalConfig: any = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'date',
          label: t('Date'),
          type: InputTypes.DATEPICKER,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    []
  );

  const onCloseModal = () => {
    setPayrollDate(null);
    closeModal();
  };

  return (
    <GenericModal
      title={t('setReportDate')}
      open={open}
      closeModal={onCloseModal}
    >
      <GenericForm
        formConfiguration={DateModalConfig}
        form={form}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={onCloseModal} htmlType="reset" danger>
                {t('Cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default DateModal;
