interface backUrl {
  from: string;
  to: string;
  query: boolean;
}

const backUrls: backUrl[] = [
  {
    from: '/assets/asset-details',
    to: '/assets',
    query: false,
  },
  {
    from: '/employees/edit-employee',
    to: '/employees/employee-profile',
    query: true,
  },
  {
    from: '/employees/employee-profile',
    to: '/employees',
    query: false,
  },
  {
    from: '/payroll-rules/payroll-rule-details',
    to: '/payroll-rules',
    query: false,
  },
  {
    from: '/questionnaires/questionnaire-details',
    to: '/questionnaires',
    query: false,
  },
  {
    from: '/ideabox/ideabox-details',
    to: '/ideabox',
    query: false,
  },
  {
    from: '/training-details',
    to: '/trainings',
    query: false,
  },
  {
    from: '/work-position-details',
    to: '/work-positions',
    query: false,
  },
  {
    from: '/certifications/certification-details',
    to: '/certifications',
    query: false,
  },
  {
    from: '/departments/department-details',
    to: '/departments',
    query: false,
  },
  {
    from: '/projects/project-details',
    to: '/projects',
    query: false,
  },
  {
    from: '/skills/category-details',
    to: '/skills',
    query: false,
  },
  {
    from: '/asset-categories/details',
    to: '/asset-categories',
    query: false,
  },
  {
    from: '/candidates/edit-profile',
    to: '/candidates/profile',
    query: true,
  },
  {
    from: '/candidates/profile',
    to: '/candidates',
    query: false,
  },
  {
    from: '/onboardings/onboarding-details',
    to: '/onboardings',
    query: false,
  },
  {
    from: '/purchase-orders/purchase-order-details',
    to: '/purchase-orders',
    query: false,
  },
  {
    from: '/purchase-orders/edit-purchase-order',
    to: '/purchase-orders/purchase-order-details',
    query: true,
  },
];

export default backUrls;
