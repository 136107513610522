/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Avatar, Col, Popconfirm } from 'antd';
import { GiftOutlined } from '@ant-design/icons';
import { DeleteIcon } from 'Icons/DeleteIcon';
import styled from 'styled-components';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { CurrencyType } from 'types/Candidates';
import { Bonus } from 'types/Bonus';
import { useTranslation } from 'react-i18next';
import {
  BonusDate,
  BonusItemContainer,
  BonusLabel,
  BonusValue,
  Bonuse,
  ColItem,
} from './BonusItemStyled';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
interface IProps extends Bonus {
  label: string;
  date: Date | string;
  value: number;
  isLast: boolean;
  onDelete: () => void;
  currency: CurrencyType;
  onItemClick: (id: string) => void;
  item: any;
}
export function BonusItem({
  label,
  date,
  value,
  isLast,
  onDelete,
  currency,
  item,
  onItemClick,
}: IProps) {
  const handleItemClick = () => {
    onItemClick(item.id);
  };
  const handleDeleteButtonClick = (event: any) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };
  const { t } = useTranslation();
  return (
    <BonusItemContainer isLast={isLast}>
      <ColItem span={24} onClick={handleItemClick} isLast={isLast}>
        <Bonuse gutter={[0, 4]} isLast>
          <FlexRow>
            <Col span={5}>
              <Avatar
                style={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }}
                icon={<GiftOutlined />}
                size={40}
              />
            </Col>
            <Col span={16}>
              <BonusLabel>{label}</BonusLabel>
              <BonusDate>{convertUTCtoFormatedLocalTime(date)}</BonusDate>
              <BonusValue>
                {value} {currency?.name}
              </BonusValue>
            </Col>

            <Col span={3}>
              <RoleBasedButton btn={BUTTONS.DELETE_BONUS}>
                <Popconfirm
                  placement="topLeft"
                  title={t('areYouSureYouWanttoDelete')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={(e) => handleDeleteButtonClick(e)}
                >
                  <IconButton onClick={(e) => e.stopPropagation()}>
                    <DeleteIcon />
                  </IconButton>
                </Popconfirm>
              </RoleBasedButton>
            </Col>
          </FlexRow>
        </Bonuse>
      </ColItem>
    </BonusItemContainer>
  );
}
