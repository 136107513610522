// reducers.js
import { Reducer } from 'react';
import { Action } from 'redux';
import {
  CHECK_HR_DUTIES_COMPLETION_REQUEST,
  CHECK_HR_DUTIES_COMPLETION_SUCCESS,
  CHECK_HR_DUTIES_COMPLETION_FAILURE,
  HrDutiesState,
  CLOSE_BANNER,
} from './types';

const initialState: HrDutiesState = {
  data: {
    department: false,
    workPosition: false,
  },
  loading: false,
  loaded: false,
  error: null,
};

const initialStateBanner = {
  isBannerClosed: false,
};

const hrDutiesReducer: Reducer<any, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case CHECK_HR_DUTIES_COMPLETION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHECK_HR_DUTIES_COMPLETION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: null,
      };
    case CHECK_HR_DUTIES_COMPLETION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const isClosedBannerReducer: Reducer<any, Action> = (
  state = initialStateBanner,
  action: any
) => {
  switch (action.type) {
    case CLOSE_BANNER:
      return {
        ...state,
        isBannerClosed: true,
      };
    default:
      return state;
  }
};

export { hrDutiesReducer, isClosedBannerReducer };
