import { Input, Select, Table } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';
import styled from 'styled-components';

export const DataGrid = styled(Table)`
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: white;

  .ant-table-thead
    > tr
    > th:not(:last-child)
    :not(.ant-table-selection-column)
    :not(.ant-table-row-expand-icon-cell)
    :not([colspan]):before {
    display: none;
  }
  & .ant-table {
    border-radius: 10px;
  }
  & .ant-table-container {
    border-radius: 10px;
  }

  & .ant-table-thead .ant-table-cell {
    background-color: #f9fafb;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #475467;
  }

  [class*='ant-pagination'] {
    & .ant-pagination-item {
      border: none;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      align-content: center;
      & a {
        margin-top: 5px;
      }
    }
    & .ant-pagination-item-active {
      border: none;
      border-radius: 8px;
      background-color: #f9fafb;
      & a {
        color: #475467;
      }
    }
    & .ant-pagination-disabled {
      & .ant-btn {
        border: 1px solid #eaecf0;
        background-color: white;
        color: #d0d5dd;
        svg {
          filter: invert(85%) sepia(9%) saturate(179%) hue-rotate(178deg)
            brightness(103%) contrast(84%);
        }
      }
    }
    & .ant-pagination-prev {
      margin-right: auto;
      margin-left: 24px;

      & .ant-btn-first {
        & svg {
          margin: 0;
          margin-right: 12px;
        }
      }
    }
    & .ant-pagination-next {
      margin-left: auto;
      margin-right: 24px;

      & .ant-btn-last {
        & svg {
          margin: 0;
          order: 2;
          margin-left: 12px;
        }
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  width: 100%;
  height: 35px;

  &[class*='ant-select'] {
    overflow-x: scroll;
    overflow-y: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
  [class*='ant-select-selector'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }

  &[class*='ant-select'] {
    overflow-x: scroll;
    overflow-y: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
  .ant-select-selector {
    border: unset !important;
    border-radius: 8px !important;
    padding: 5px 14px !important;
    height: 44px;
  }

  &[class*='ant-select-focused'] {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
  [class*='ant-select-selection-placeholder'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
`;

export const Search = styled(Input)`
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 35px;
  [class*='ant-input'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
`;

export const TableBtn = styled(StyledButton)`
  height: 35px;
  svg {
    margin-right: 0;
  }
`;

export const StyledIcon = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
