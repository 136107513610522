import { Dispatch } from 'redux';
import { getAllPublicSuperAdmins } from 'api/publicSuperAdminServise';
import { QueryParamsDTO } from 'types/QueryParams';
import { PublicSuperAdmins } from './types';

export const fetchPublicSuperAdmins =
  (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: PublicSuperAdmins.FETCH_SUPER_ADMINS_START,
    });
    getAllPublicSuperAdmins(queryParams)
      .then((response) => {
        dispatch({
          type: PublicSuperAdmins.FETCH_SUPER_ADMINS_SUCCESS,
          ...response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PublicSuperAdmins.FETCH_SUPER_ADMINS_ERRORED,
          ...error,
        });
      });
  };
