import React from 'react';

export const AssetsIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1E0FF" />
    <path
      d="M21.5 36H24M33 25H33.01M24 22H21.2C20.0799 22 19.5198 22 19.092 22.218C18.7157 22.4097 18.4097 22.7157 18.218 23.092C18 23.5198 18 24.0799 18 25.2V28.8C18 29.9201 18 30.4802 18.218 30.908C18.4097 31.2843 18.7157 31.5903 19.092 31.782C19.5198 32 20.0799 32 21.2 32H24M31.2 36H34.8C35.9201 36 36.4802 36 36.908 35.782C37.2843 35.5903 37.5903 35.2843 37.782 34.908C38 34.4802 38 33.9201 38 32.8V23.2C38 22.0799 38 21.5198 37.782 21.092C37.5903 20.7157 37.2843 20.4097 36.908 20.218C36.4802 20 35.9201 20 34.8 20H31.2C30.0799 20 29.5198 20 29.092 20.218C28.7157 20.4097 28.4097 20.7157 28.218 21.092C28 21.5198 28 22.0799 28 23.2V32.8C28 33.9201 28 34.4802 28.218 34.908C28.4097 35.2843 28.7157 35.5903 29.092 35.782C29.5198 36 30.0799 36 31.2 36ZM34 31C34 31.5523 33.5523 32 33 32C32.4477 32 32 31.5523 32 31C32 30.4477 32.4477 30 33 30C33.5523 30 34 30.4477 34 31Z"
      stroke="#155EEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#EFF4FF"
      strokeWidth="8"
    />
  </svg>
);
