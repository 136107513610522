import { CreateUserDto, EditUserDto } from 'redux/users/types';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import agent from './agent';

import { apiURLs } from './constants';

export const getAllUsersList = (queryParams?: QueryParamsType) =>
  agent.post(`${apiURLs.users}/get`, searchFields(queryParams, apiURLs.users));

export const getSingleUser = (id: string) =>
  agent.get(`${apiURLs.users}/${id}`);

export const createUser = (payload: CreateUserDto) =>
  agent.post(`${apiURLs.users}`, payload);

export const editUser = (id: string, payload: EditUserDto) =>
  agent.patch(`${apiURLs.users}/admin/${id}`, payload);

export const deleteUser = (id: string) =>
  agent.delete(`${apiURLs.users}/${id}`);
