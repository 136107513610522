/* eslint-disable react/no-children-prop */
import { Col, Row } from 'antd';
import React from 'react';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useWorkPositionsData } from 'ui-v2/hooks/useWorkPositionsData';

export default function WorkPositionDepartmentInformation() {
  const {
    workPosition: { data },
  } = useWorkPositionsData();

  if (!data?.id) return null;

  return (
    <CardItem title="Department">
      <Row gutter={[0, 16]}>
        <Styled.RowItem gutter={[0, 10]} last>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label=""
              value={data?.department?.name || ''}
              children={undefined}
            />
          </Col>
        </Styled.RowItem>
      </Row>
    </CardItem>
  );
}
