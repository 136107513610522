import { Reducer } from 'react';
import { Action } from 'redux';
import { PublicSuperAdmins, PublicSuperAdminsState } from './types';

const initialPublicSuperAdminsState: PublicSuperAdminsState = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
};

export interface PublicSuperAdminsReducedAction
  extends PublicSuperAdminsState,
    Action {}

const PublicSuperAdminsReducer: Reducer<
  PublicSuperAdminsState,
  PublicSuperAdminsReducedAction
> = (
  state = initialPublicSuperAdminsState,
  action: PublicSuperAdminsReducedAction
) => {
  switch (action.type) {
    case PublicSuperAdmins.FETCH_SUPER_ADMINS_START:
      state = { ...state, loading: true, loaded: false };
      break;
    case PublicSuperAdmins.FETCH_SUPER_ADMINS_SUCCESS:
      state = { ...state, ...action, loading: false, loaded: true };
      break;
    case PublicSuperAdmins.FETCH_SUPER_ADMINS_ERRORED:
      state = {
        ...state,
        ...action,
        loading: false,
        loaded: false,
        errored: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { PublicSuperAdminsReducer };
