import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { usePublicSuperAdminData } from 'ui-v2/hooks/usePublicSuperAdminData';
import { fetchPublicSuperAdmins } from 'redux/publicSuperAdmins/actions';
import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import PublicSuperAdminModalForm from 'components/NewForms/PublicSuperAdminModalForm';
import { deletePublicSuperAdminUser } from 'api/publicSuperAdminServise';
import { toastErrorMessages } from 'utils/utilFunctions';

const SuperAdminsUsersList: React.FC = () => {
  const dispatch = useDispatch();
  const { columns, take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: list, loading } = usePublicSuperAdminData();
  const [deleting, setDeleting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchPublicSuperAdmins({ pageOptions: { take } }));
  }, [take]);

  const onSearch = (text: string) => {
    dispatch(
      fetchPublicSuperAdmins({
        pageOptions: { take },
        filterOptions: { filterText: text },
      })
    );
  };

  const onPageChange = (page: number) => {
    dispatch(
      fetchPublicSuperAdmins({
        pageOptions: { take, page },
      })
    );
  };

  const toogleModalVisibility = useCallback(
    () => setModalVisible((modalVisible) => !modalVisible),
    [modalVisible]
  );

  const deleteSuperAdmin = useCallback(
    async (id: string | null) => {
      if (id) {
        setDeleting(true);
        deletePublicSuperAdminUser(id)
          .then((response) => {
            if (response.status === 200) {
              toast.success('Successfully deleted super admin user!');
              dispatch(fetchPublicSuperAdmins());
            }
          })
          .catch((error) => toastErrorMessages(error))
          .finally(() => setDeleting(false));
        searchParams.delete('delete-public-super-admin');
        setSearchParams(searchParams);
      }
    },
    [searchParams.get('delete-public-super-admin')]
  );

  useEffect(() => {
    if (searchParams.get('edit-public-super-admin')) {
      toogleModalVisibility();
    }
    if (searchParams.get('add-public-super-admin') === 'true') {
      searchParams.delete('add-public-super-admin');
      setSearchParams(searchParams);
      toogleModalVisibility();
    }
    if (searchParams.get('delete-public-super-admin')) {
      deleteSuperAdmin(searchParams.get('delete-public-super-admin'));
    }
  }, [searchParams]);

  const onModalClose = () => {
    searchParams.delete('edit-public-super-admin');
    setSearchParams(searchParams);
    toogleModalVisibility();
  };

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading || deleting}
          columns={columns}
          data={list ?? []}
          showSearch
          onSearch={onSearch}
          onPageChange={onPageChange}
          paginationName="Public Super Admins"
          rowSelectionType="checkbox"
        />
        {modalVisible && (
          <PublicSuperAdminModalForm open={modalVisible} close={onModalClose} />
        )}
      </>
    </Content>
  );
};

export default SuperAdminsUsersList;
