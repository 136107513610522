import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import DataGrid from 'components/common/DataGrid/DataGrid';
import useHeader from 'ui-v2/hooks/useHeader';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import usePayrollDatesData from 'ui-v2/hooks/usePayrollDatesData';
import { convertDateToUTC, toastErrorMessages } from 'utils/utilFunctions';
import {
  deletePayrollReport,
  getPayrollReport,
} from 'api/payrollReportService';
import { useTranslation } from 'react-i18next';
import { fetchPayrollDates } from 'redux/payrollReport/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PayrollGenerateReportModal from './PayrollDateModal/PayrollDateModal';
import { GenerateReportDate } from './ExpandedTable/ExpandedTableStyles';

export default function PayrollGenerateReport() {
  const { columns } = useHeader();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading, generateReportDates } = usePayrollDatesData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useState(false);
  const [payrollDate, setPayrollDate] = useState<Date | null>(new Date());
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    generateReportDates();
  }, []);

  useEffect(() => {
    if (searchParams.get('new-report') === 'true') {
      searchParams.delete('new-report');
      setSearchParams(searchParams);
      setModal(true);
    }
  }, [searchParams]);

  const onFinish = (
    nationalHolidays: Date[],
    date: Date,
    tenantLocationId: string
  ) => {
    setPayrollDate(date);

    getPayrollReport({
      date: convertDateToUTC(date),
      nationalHolidays: nationalHolidays?.map((el) => convertDateToUTC(el)),
      tenantLocationId,
    })
      .then(() => {
        navigate(
          `view-report?date=${moment(date).format(
            'YYYY-MM-DD'
          )}&tenantLocationId=${tenantLocationId}`
        );
      })
      .catch((error) => {
        toastErrorMessages(error.response.data.error);
      });

    setModal(false);
  };

  const onSearch = (search: string) => {
    dispatch(
      fetchPayrollDates({
        count: search,
      })
    );
    setSearchTerm(searchTerm);
  };

  async function deletePayrollReportById(id: string | null) {
    if (!id) return;
    deletePayrollReport(id)
      .then((res: { status: number }) => {
        if (res.status === 200) {
          toast.success(t('deletePayrollReportSuccess'));
          dispatch(fetchPayrollDates());
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    const arrayOfPromises: Array<Promise<unknown>> = [];
    ids?.forEach(async (id) => {
      arrayOfPromises.push(deletePayrollReportById(id?.toString()));
    });
    await Promise.all(arrayOfPromises);
  }

  return (
    <Content position="stretch">
      <>
        <GenerateReportDate>
          {`${t('Date')}:`}
          {moment(payrollDate).format('DD/MM/YYYY')}
        </GenerateReportDate>
        <DataGrid
          loading={loading}
          columns={columns}
          data={data}
          rowSelectionType="checkbox"
          totalPages={data?.length}
          showSearch
          rowKey={(record: { payrollReportId: string }) =>
            record?.payrollReportId
          }
          paginationName="PayrollReport"
          onSearch={onSearch}
          onSelectedDelete={onDelete}
        />
        <PayrollGenerateReportModal
          open={modal}
          closeModal={() => setModal(false)}
          onFinish={onFinish}
        />
      </>
    </Content>
  );
}
