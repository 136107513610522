import React, { useMemo, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import GenericModal from 'ui-v2/components/GenericModal';
import { StyledButton } from 'components/NewForms/FormStyled';
import { toastErrorMessages } from 'utils/utilFunctions';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { useForm } from 'antd/lib/form/Form';
import { usePublicSuperAdminData } from 'ui-v2/hooks/usePublicSuperAdminData';
import {
  createPublicSuperAdmin,
  updatePublicSuperAdmin,
} from 'api/publicSuperAdminServise';
import { fetchPublicSuperAdmins } from 'redux/publicSuperAdmins/actions';
import {
  PublicSuperAdminCreateDto,
  PublicSuperAdminUpdateDto,
} from 'types/PublicSuperAdmin';
import { useTranslation } from 'react-i18next';

type PublicSuperAdminModalFormProps = {
  open: boolean;
  close: () => void;
};

export default function PublicSuperAdminModalForm({
  open,
  close,
}: PublicSuperAdminModalFormProps) {
  const [form] = useForm();
  const { publicSuperAdmin } = usePublicSuperAdminData();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onSubmit = async () => {
    setLoading(true);
    if (publicSuperAdmin) {
      const payload: PublicSuperAdminUpdateDto = {
        email: form.getFieldValue('email') ?? publicSuperAdmin.email,
        isTwoFAComplete:
          form.getFieldValue('isTwoFAComplete') ??
          publicSuperAdmin.isTwoFAComplete,
      };
      updatePublicSuperAdmin(publicSuperAdmin.id, payload)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('Successfully updated super admin user!'));
            dispatch(fetchPublicSuperAdmins());
            close();
          }
        })
        .catch((error) => toastErrorMessages(error))
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    const payload: PublicSuperAdminCreateDto = {
      email: form.getFieldValue('email'),
      password: form.getFieldValue('password'),
    };
    createPublicSuperAdmin(payload)
      .then((response) => {
        if (response.status === 201) {
          toast.success(t('Successfully created a new super admin user!'));
          dispatch(fetchPublicSuperAdmins());
          close();
        }
      })
      .catch((error) => toastErrorMessages(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const PublicSuperAdminFormConfigurations: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          defaultValue: publicSuperAdmin?.email,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      !publicSuperAdmin
        ? [
            {
              col: 24,
              offset: 0,
              name: 'password',
              label: t('password'),
              type: InputTypes.INPUT,
              inputProps: {
                type: 'password',
              },
              rules: [
                {
                  required: true,
                  message: t('Please enter your password!'),
                  validator(_: any, value: any): Promise<any> {
                    if (!value) {
                      return Promise.reject(new Error('Password is required!'));
                    }
                    return Promise.resolve();
                  },
                },
              ],
            },
          ]
        : [],
      !publicSuperAdmin
        ? [
            {
              col: 24,
              offset: 0,
              name: 'confirmPassword',
              label: t('confirmPassword'),
              type: InputTypes.INPUT,
              inputProps: {
                type: 'password',
              },
              rules: [
                {
                  required: true,
                  message: t('Passwords are not matching'),
                  validator(_: any, value: string | undefined): Promise<any> {
                    if (value && form.getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('Passwords are not matching'))
                    );
                  },
                },
              ],
            },
          ]
        : [],
      publicSuperAdmin
        ? [
            {
              col: 24,
              offset: 0,
              name: 'isTwoFAComplete',
              label: t('twoFactorAuthentication'),
              type: InputTypes.SWITCH,
              disabled: !publicSuperAdmin?.isTwoFAComplete,
              defaultChecked: publicSuperAdmin?.isTwoFAComplete ?? false,
            },
          ]
        : [],
    ],
    [publicSuperAdmin]
  );

  return (
    <Spin spinning={loading}>
      <GenericModal
        title={`${publicSuperAdmin ? t('edit') : t('add')} Super Admin`}
        open={open}
        closeModal={close}
      >
        <GenericForm
          formConfiguration={PublicSuperAdminFormConfigurations}
          onFinish={onSubmit}
          form={form}
        >
          <Row justify="start" gutter={[0, 30]}>
            <Col span={24}>
              <Row>
                <Col span={11}>
                  <StyledButton onClick={close} htmlType="reset" danger>
                    {t('cancel')}
                  </StyledButton>
                </Col>
                <Col span={11} offset={2}>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t('submit')}
                  </StyledButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </GenericForm>
      </GenericModal>
    </Spin>
  );
}
