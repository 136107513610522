import styled from 'styled-components';
import { Badge, Col, Row } from 'antd';

export const StyledTrackingFilterPanelRow = styled(Row)(({ theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  padding: '.5rem 0',
  paddingRight: '.5rem',
}));
export const StyledTrackingFilterPanelCol = styled(Col)<{
  borderRight?: boolean;
  active?: boolean;
}>(({ borderRight, theme }) => ({
  ...(borderRight && {
    borderRight: `1px solid ${theme.colors.palette.primary.gray['300']}`,
    borderRightStyle: 'dashed',
  }),
  padding: '0 1rem',
}));

export const StyledTrackingFilterPanelLabel = styled(Col)(({ theme }) => ({
  padding: '.4rem',
  ...theme.typography.body.regular.sm,
  svg: {
    fontSize: '.8rem',
    fill: theme.colors.palette.primary.gray[500],
  },
  cursor: 'pointer',
}));

export const StyledTrackingFilterPanelMainLabel = styled(Col)(({ theme }) => ({
  padding: '.8rem',
  ...theme.typography.body.regular.sm,
  color: theme.colors.palette.primary.gray[500],
  svg: {
    fontSize: '.8rem',
    fill: theme.colors.palette.primary.gray[500],
  },
  cursor: 'pointer',
}));

export const StyledTrackingFilterPanelBadge = styled(Badge)(({ theme }) => ({
  '.ant-badge-count': {
    backgroundColor: theme.colors.palette.primary.blue['500'],
    color: theme.colors.palette.base.white,
  },
}));
