import React from 'react';
import { NotificationsModalIcon } from 'Icons/NotificationsModalIcon';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import NotificationsList from './NotificationsList';

interface IProps {
  open: boolean;
  closeModal: () => void;
  loaded: boolean;
}

export default function NotificationsModal({
  open,
  closeModal,
  loaded,
}: IProps) {
  const { t } = useTranslation();

  return (
    <GenericModal
      title={t('Notifications')}
      open={open}
      closeModal={closeModal}
      icon={<NotificationsModalIcon />}
    >
      <NotificationsList loaded={loaded} closeModal={closeModal} />
    </GenericModal>
  );
}
