import { Reducer } from 'react';
import { Action } from 'redux';
import { KnowledgeAction, KnowledgeState } from './types';

const initialState: KnowledgeState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<KnowledgeState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case KnowledgeAction.FETCH_KNOWLEDGE_START:
      state = { ...state, loading: true };
      break;
    case KnowledgeAction.FETCH_KNOWLEDGE_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case KnowledgeAction.FETCH_KNOWLEDGE_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case KnowledgeAction.CLEAR_KNOWLEDGE:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as KnowledgeReducer };
