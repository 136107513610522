import { Typography } from 'antd';
import styled, { CSSProperties } from 'styled-components';

export const SkillBadgeStyles: CSSProperties = { background: '#528BFF' };
export const SillPopoverInnerOverlayStyles: CSSProperties = {
  borderRadius: '8px',
  width: '80%',
};
export const SkillModalRateStyles: CSSProperties = {
  padding: '0 30px',
  fontSize: '50px',
};
export const SkillDetailsRateStyles: CSSProperties = {
  paddingRight: '20px',
  fontSize: '30px',
};

export const Subtitle = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  display: block;
`;

export const Tag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f4f7;
  border-radius: 16px;
  line-height: 20px;
  padding: 2px 10px;

  &:hover {
    cursor: pointer;
  }

  .skill-tag {
    padding: 5px;
  }
`;

export const TagLabel = styled(Typography.Text)`
  color: #344054;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  text-transform: capitalize;
`;
export const AddSpaceIcon = styled(Typography.Text)`
  margin: 5px;
  padding-top: 5px;
  align-content: center;
`;
export const PlusIcon = styled(Typography.Text)`
  margin: 8px;
`;
