import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { t } from 'i18next';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';
import { isNil } from 'lodash';

import {
  UpdateQuestionnaireAlternativeDTO,
  UpdateQuestionnaireQuestionDTO,
} from 'types/Questionnaires';
import { StyledInput } from '../FormStyled';
import {
  StyledCollapse,
  StyledCollapsePanel,
} from '../DynamicForm/DynamicFormStyles';
import { CanAddQuestion } from './CanAddQuestion';
import {
  CustomMinusCircleOutlined,
  CustomStyledAddButton,
  CustomStyledButton,
  CustomStyledInput,
  CustomStyledRate,
  CustomStyledRemoveButton,
} from './StyledQuestionnairesQuestions';
import canDeleteQuestion from './CanDeleteQuestion';

interface QuestionnaireQuestionsProps {
  setQuestionnaireQuestionsData: any;
}

function QuestionnairesQuestions({
  setQuestionnaireQuestionsData,
}: QuestionnaireQuestionsProps) {
  const { singleQuestionnaire, singleQuestionnaireLoaded } =
    useQuestionnairesData();
  const data = singleQuestionnaire?.questionnaireQuestion;
  const [questions, setQuestions] = useState<
    Array<UpdateQuestionnaireQuestionDTO>
  >([]);

  const canAddQuestion: boolean = CanAddQuestion(questions);

  useEffect(() => {
    if (data && singleQuestionnaireLoaded) {
      setQuestions(data);
    }
  }, [data, singleQuestionnaireLoaded]);

  const filterQuestionsForQuestionnaireData = (
    questions: Array<UpdateQuestionnaireQuestionDTO>
  ) => {
    if (isNil(questions)) return [];

    return questions.filter(
      (question) =>
        (question?.id && question?.deleted) || // Deleting an existing question
        (!question?.id && !question?.deleted) || // Saving a new question
        (question?.id && !question?.deleted) // Modifying an existing question
    );
  };

  useEffect(() => {
    const questionnaireQuestionsData =
      filterQuestionsForQuestionnaireData(questions);
    setQuestionnaireQuestionsData(questionnaireQuestionsData);
  }, [questions, setQuestionnaireQuestionsData]);

  const onQuestionChange = useCallback(
    (index: number, question: string) => {
      setQuestions((prevQuestions) => {
        const updatedQuestion = [...prevQuestions];
        updatedQuestion[index].content = question;
        return updatedQuestion;
      });
    },
    [questions]
  );

  const onAnswerChange = useCallback(
    (questionIndex: number, answerIndex: number, answer: string) => {
      setQuestions((prevQuestions) => {
        const updatedQuestion = [...prevQuestions];
        updatedQuestion[questionIndex].questionnaireAlternative[
          answerIndex
        ].content = answer;
        return updatedQuestion;
      });
    },
    [questions]
  );

  const onStarsChange = useCallback(
    (questionIndex: number, answerIndex: number, rate: number) => {
      setQuestions((prevQuestions) => {
        const updatedQuestion = [...prevQuestions];
        updatedQuestion[questionIndex].questionnaireAlternative[
          answerIndex
        ].score = rate;
        return updatedQuestion;
      });
    },
    [questions]
  );

  const onAddNewAnswer = useCallback(
    (questionIndex: number) => {
      setQuestions((prevQuestions) => {
        const updatedQuestion = [...prevQuestions];
        updatedQuestion[questionIndex].questionnaireAlternative.push({
          content: '',
          score: 1,
        });
        return updatedQuestion;
      });
    },
    [questions]
  );

  const onRemoveAnswer = useCallback(
    (questionIndex: number, answerIndex: number) => {
      setQuestions((prevQuestions) => {
        const updatedQuestion = [...prevQuestions];
        updatedQuestion[questionIndex].questionnaireAlternative[
          answerIndex
        ].deleted = true;
        return updatedQuestion;
      });
    },
    [questions]
  );

  const onAddNewQuestion = useCallback(() => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        content: '',
        questionnaireAlternative: [
          {
            content: '',
            score: 1,
          },
        ],
      },
    ]);
  }, [questions]);

  const onDeleteQuestion = useCallback(
    (index: number) => {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index].deleted = true;
        return updatedQuestions;
      });
    },
    [questions]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    questionIndex: number,
    answerIndex: number
  ) => {
    const { value } = e.target;
    onAnswerChange(questionIndex, answerIndex, value);
  };

  const handleWheel = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    target.blur();
  };

  const handleRemoveAnswer = (questionIndex: number, answerIndex: number) => {
    onRemoveAnswer(questionIndex, answerIndex);
  };

  const handleDeleteQuestion = (index: number) => {
    onDeleteQuestion(index);
  };

  const handleAddNewAnswer = (index: number) => {
    onAddNewAnswer(index);
  };

  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <StyledCollapse>
          {questions?.map(
            (question: any, questionIndex: number) =>
              !question.deleted && (
                <StyledCollapsePanel
                  header={
                    <StyledInput
                      key={`question-${question.id}`}
                      value={question?.content}
                      placeholder={t('title')}
                      onChange={(e) =>
                        onQuestionChange(questionIndex, e.target.value)
                      }
                    />
                  }
                  key={question.id}
                >
                  {question?.questionnaireAlternative
                    ?.filter(
                      (answer: UpdateQuestionnaireAlternativeDTO) =>
                        !answer?.deleted
                    )
                    .map(
                      (
                        answer: UpdateQuestionnaireAlternativeDTO,
                        answerIndex: number
                      ) => (
                        <Row key={answer.id}>
                          <CustomStyledInput
                            placeholder={t('addSomething')}
                            value={answer?.content}
                            onChange={(e) =>
                              handleChange(e, questionIndex, answerIndex)
                            }
                            onWheel={handleWheel}
                          />
                          <CustomStyledRate
                            defaultValue={answer?.score}
                            allowClear={false}
                            onChange={(score) =>
                              onStarsChange(questionIndex, answerIndex, score)
                            }
                          />
                          <CustomMinusCircleOutlined
                            onClick={() =>
                              handleRemoveAnswer(questionIndex, answerIndex)
                            }
                          />
                        </Row>
                      )
                    )}
                  <Row>
                    {canDeleteQuestion(questions) && (
                      <CustomStyledRemoveButton
                        onClick={() => handleDeleteQuestion(questionIndex)}
                      >
                        {t('deleteQuestion')}
                      </CustomStyledRemoveButton>
                    )}

                    <CustomStyledAddButton
                      onClick={() => handleAddNewAnswer(questionIndex)}
                    >
                      {t('addAnswer')}
                    </CustomStyledAddButton>
                  </Row>
                </StyledCollapsePanel>
              )
          )}
        </StyledCollapse>
        <Row justify="end">
          {canAddQuestion && (
            <CustomStyledButton onClick={onAddNewQuestion}>
              {t('addQuestion')}
            </CustomStyledButton>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default QuestionnairesQuestions;
