import { EmployeeAssetDTO, EmployeeAssetUpdateTYpe } from 'types/Asset';
import agent from 'api/agent';
import { apiURLs } from './constants';

export const getAllEmployeeAssets = () => agent.get(apiURLs.employeeAsset);

export const getEmployeeAssetById = (id: string) =>
  agent.get(`${apiURLs.employeeAsset}/${id}`);

export const getEmployeeAssetsByEmployeeId = (id: string) =>
  agent.get(`${apiURLs.employeeAsset}/employee/${id}`);

export const createEmployeeAsset = (employAsset: EmployeeAssetDTO) =>
  agent.post(apiURLs.employeeAsset, employAsset);

export const updateEmployeeAssetById = (
  id: string,
  values: EmployeeAssetUpdateTYpe
) => agent.put(`${apiURLs.employeeAsset}/${id}`, values);
