/* eslint-disable react/no-array-index-key */
import React from 'react';

import { UserDataType } from 'redux/authUser/types';
import { CreateUserDto } from 'redux/users/types';

import UsersActions from 'components/Actions/UsersActions';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { Row, Tooltip } from 'antd';
import i18next from '../i18n';

export const UsersColumns = [
  {
    title: i18next.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a: UserDataType, b: UserDataType) =>
      a.firstName.localeCompare(b.firstName),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a: UserDataType, b: UserDataType) =>
      a.lastName.localeCompare(b.lastName),
    default: true,
    order: 3,
  },
  {
    title: i18next.t('email'),
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    sorter: (a: UserDataType, b: UserDataType) =>
      a.email.localeCompare(b.email),
    default: true,
    render: (email: string) => (
      <Tooltip title={email}>
        <span>{email}</span>
      </Tooltip>
    ),
    order: 4,
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    render: (record: string) => record,
    default: false,
    order: 5,
  },
  {
    title: i18next.t('role'),
    dataIndex: 'roles',
    key: 'roles',
    ellipsis: true,
    render: (_: string, row: UserDataType) => (
      <Row justify="start">
        <Styled.RoleTag
          roles={row?.roles[0]?.name || ''}
          style={{ textTransform: 'capitalize' }}
        >
          {row?.roles[0]?.name.split('_').join(' ')}
        </Styled.RoleTag>
      </Row>
    ),
    default: false,
    order: 7,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (record: string, row: CreateUserDto) => (
      <UsersActions id={row.id} />
    ),
    default: false,
    order: 8,
    fixed: 'right',
  },
];
