import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  clearSinglePayrollRule,
  fetchPayrollRules,
} from 'redux/payrollRules/actions';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { usePayrollRuleData } from 'ui-v2/hooks/usePayrollRule';
import {
  convertUTCtoFormatedLocalTimeMonthWithLetters,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { deletePayrollRule } from 'api/payrollRulesService';
import { useTranslation } from 'react-i18next';
import AddPayrollRuleForm from './PayrollRuleForm';

export default function PayrollRulesList() {
  const { columns, take } = useHeader();
  const { loading, list, pagination } = usePayrollRuleData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchPayrollRules({ page: 1, take }));
  }, [take]);

  useEffect(() => {
    if (searchParams.get('add-payroll-rules') === 'true') {
      dispatch(clearSinglePayrollRule());
      searchParams.delete('add-payroll-rules');
      setSearchParams(searchParams);
      setModalVisible(true);
    }

    if (searchParams.get('edit-rule')) {
      setSearchParams(searchParams);
      setModalVisible(true);
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
    };
    dispatch(fetchPayrollRules(params));
  };

  const normalizeString = (str: string) => str.trim().toLowerCase();

  const setPayrollEndDate = (data: any[]) =>
    data?.map((d, index) => {
      if (
        index < data.length - 1 &&
        normalizeString(d.humanID) === normalizeString(data[index + 1]?.humanID)
      ) {
        return {
          ...d,
          createdAt: convertUTCtoFormatedLocalTimeMonthWithLetters(d.createdAt),
          endDate: convertUTCtoFormatedLocalTimeMonthWithLetters(
            data[index + 1].createdAt
          ),
        };
      }
      return {
        ...d,
        createdAt: convertUTCtoFormatedLocalTimeMonthWithLetters(d.createdAt),
      };
    });

  async function deletePayrollRuleById(id: string | null) {
    if (!id) return;
    deletePayrollRule(id)
      .then((res: { status: number }) => {
        if (res.status === 200) {
          toast.success(t('deletePayrollRuleSuccess'));
          dispatch(fetchPayrollRules({ page: 1, take }));
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    const arrayOfPromises: Array<Promise<unknown>> = [];
    ids?.forEach(async (id) => {
      arrayOfPromises.push(deletePayrollRuleById(id?.toString()));
    });
    await Promise.all(arrayOfPromises);
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={setPayrollEndDate(list)}
          paginationName="Payroll Rules"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          showSearch
          onSelectedDelete={onDelete}
          onPageChange={onPageChange}
        />
        {modalVisible && (
          <AddPayrollRuleForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-rule');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
