import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  clearFetchSingleReivew,
  fetchQuestionnaires,
  fetchSingleQuestionnaire,
} from 'redux/questionnaires/actions';
import { QueryParamsDTO } from 'types/QueryParams';
import moment from 'moment';

export default function useQuestionnairesData() {
  const dispatch = useDispatch();
  const questionnaires = useSelector((state: any) => state.questionnaires);

  function getQuestionnaires(queryParams: QueryParamsDTO) {
    dispatch(fetchQuestionnaires(queryParams));
  }

  const [searchParams] = useSearchParams();
  const {
    data: singleQuestionnaire,
    loading: singleQuestionnaireLoading,
    loaded: singleQuestionnaireLoaded,
    errored: singleQuestionnaireErrored,
    error: singleQuestionnaireError,
  } = useSelector((state: any) => state.singleQuestionnaire);
  const id = searchParams.get('edit-questionnaire') as string;

  const today = moment();
  const lastDay = moment(singleQuestionnaire?.endDate);
  const isPastDeadline = today.isAfter(lastDay);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleQuestionnaire(id));
    }

    return () => {
      dispatch(clearFetchSingleReivew());
    };
  }, [id]);

  return {
    data: questionnaires?.data?.data || [],
    loading: questionnaires?.loading,
    loaded: questionnaires?.loaded,
    error: questionnaires?.error,
    pagination: questionnaires?.data?.meta,
    getQuestionnaires,

    singleQuestionnaire,
    singleQuestionnaireLoading,
    singleQuestionnaireLoaded,
    singleQuestionnaireErrored,
    singleQuestionnaireError,
    isPastDeadline,
  };
}
