import React, { useEffect, useState } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { usePurchaseOrdersData } from 'ui-v2/hooks/usePurchaseOrdersData';
import {
  clearPurchaseOrderState,
  fetchPurchaseOrders,
  fetchSinglePurchaseOrder,
} from 'redux/purchaseOrders/action';
import PurchaseOrderModalForm from 'components/NewForms/PurchaseOrderForm';
import { deletePurchaseOrder } from 'api/purchaseOrder';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';

export default function PurchaseOrdersList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { columns, take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, loading, pagination } = usePurchaseOrdersData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const toogleModal = () => setModalVisible((modalVisible) => !modalVisible);

  const onModalClose = () => {
    toogleModal();
    searchParams.delete('edit-purchase-order');
    setSearchParams(searchParams);
    dispatch(clearPurchaseOrderState());
  };

  async function deletePurchaseOrderById(id: string | null) {
    if (!id) return;
    try {
      await deletePurchaseOrder(id);
      toast.success(t('successfullyDeletedOrder'));
      let currentPage = pagination?.page ?? 1;
      if (list.length === 1) {
        currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      }
      dispatch(fetchPurchaseOrders({ page: currentPage, take }));
    } catch (error) {
      toastErrorMessages(t('failedDeleteOrder'));
    }
  }

  useEffect(() => {
    if (!loading) {
      dispatch(fetchPurchaseOrders({ page: 1, take: 10 }));
    }
  }, [take]);

  useEffect(() => {
    if (searchParams.get('add-purchase-order') === 'true') {
      searchParams.delete('add-purchase-order');
      setSearchParams(searchParams);
      toogleModal();
    }
    if (searchParams.get('edit-purchase-order')) {
      dispatch(
        fetchSinglePurchaseOrder(searchParams.get('edit-purchase-order') ?? '')
      );
      toogleModal();
    }

    if (searchParams.get('delete-purchase-order')) {
      deletePurchaseOrderById(searchParams.get('delete-purchase-order'));
      searchParams.delete('delete-purchase-order');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    dispatch(
      fetchPurchaseOrders({
        page,
        take,
        filterText: searchTerm,
        filterFields: [],
      })
    );
  };

  const onSearch = (searchTerm: string) => {
    dispatch(
      fetchPurchaseOrders({
        page: 1,
        filterText: searchTerm,
        filterFields: [],
      })
    );
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deletePurchaseOrderById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
          paginationName="PurchaseOrders"
          onSelectedDelete={onDelete}
        />
        {modalVisible && (
          <PurchaseOrderModalForm
            open={modalVisible}
            closeModal={onModalClose}
          />
        )}
      </>
    </Content>
  );
}
