/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Content from 'ui-v2/components/Content';
import { useState, useEffect } from 'react';
import { message, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getResendLinkToSetPassword,
  getResendLinkToSetPasswordWithEmail,
  setPassword,
} from 'api/authService';
import { ResendVerificationEmail, SetPassword } from 'redux/users/types';
import { CompanyType } from 'types/Company';
import { getCompanyLogoContent } from 'api/company';
import { ACCESS_TOKEN } from 'utils/constants';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PasswordValidationSuccess } from './SetPasswordViews/PasswordValidationSuccess';
import { PasswordValidationFail } from './SetPasswordViews/PasswordValidationFail';
import { SetPasswordForm } from './SetPasswordViews/SetPasswordForm';
import { ResendEmailSuccess } from './SetPasswordViews/ResendEmailSuccess';
import { SendVaidationEmailForm } from './SetPasswordViews/SendVaidationEmailForm';

const RESEND_EMAIL_TIMEOUT = 60;

const PASSWORD_VALIDATION_FAIL = 'PASSWORD_VALIDATION_FAIL';
const PASSWORD_VALIDATION_SUCCESS = 'PASSWORD_VALIDATION_SUCCESS';
const SET_PASSWORD = 'SET_PASSWORD';
const COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND';
const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';

export default function SetUserPassword() {
  const { token } = useParams();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [companyLogoContetn, setCompanyLogoContent] = useState<CompanyType>();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<string>(SET_PASSWORD);
  const [countDown, setCountDown] = useState(RESEND_EMAIL_TIMEOUT);
  const { t } = useTranslation();

  const timeOutInit = () => {
    setCountDown(RESEND_EMAIL_TIMEOUT);
    const decrementCountdown = () => setCountDown((countDown) => countDown - 1);
    const interval = setInterval(() => {
      decrementCountdown();
    }, 1000);
    setResendDisabled(true);
    const timeout = setTimeout(() => {
      setResendDisabled(false);
      clearInterval(interval);
    }, RESEND_EMAIL_TIMEOUT * 1000);
  };

  const onSendValidationEmailFormFinish = async (
    values: ResendVerificationEmail
  ) => {
    try {
      await getResendLinkToSetPasswordWithEmail(values)
        .then((response) => {
          if (response.status === 200) {
            setStatus((status) => RESEND_EMAIL_SUCCESS);
            localStorage.removeItem(ACCESS_TOKEN);
          }
        })
        .catch((error) => {
          if (error.response.data.includes('User is already activated')) {
            toast.error(t('User is activated'));
            setResendDisabled(true);
          }
        });
    } catch (error) {
      toast.error(t('Something went wrong, please try again'));
    }
    timeOutInit();
  };

  const onSetPasswordFormFinish = async (values: SetPassword) => {
    setLoading(true);
    const { password } = values;
    const { confirmPassword } = values;
    const regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (password.length < 8) {
      message.error('Password should be at least 8 characters long!');
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      message.error('The password confirmation does not match!');
      setLoading(false);
      return;
    }
    if (!regularExpression.test(password)) {
      message.warning(
        'Password should contain atleast one number and one special character!'
      );
      setLoading(false);
      return;
    }
    try {
      const payload: SetPassword = {
        password,
        confirmPassword,
      };
      await setPassword(payload)
        .then((response) => {
          if (response.status === 201) {
            message.success('Password validated successfully!');
            localStorage.removeItem(ACCESS_TOKEN);
            setStatus((status) => PASSWORD_VALIDATION_SUCCESS);
          }
        })
        .catch(() => {
          message.error('Password validation failed!');
          setStatus((status) => PASSWORD_VALIDATION_FAIL);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  };

  async function fetchCompanyLogoContent(token: string) {
    await getCompanyLogoContent(token)
      .then((response) => {
        if (response.status === 200) {
          setCompanyLogoContent(response.data);
        }
      })
      .catch((error) => {
        setStatus((status) => COMPANY_NOT_FOUND);
      })
      .finally(() => setLoading(false));
  }

  async function resendEmail() {
    try {
      await getResendLinkToSetPassword()
        .then((response) => {
          if (response.status === 200) {
            setStatus((status) => RESEND_EMAIL_SUCCESS);
          }
        })
        .catch(() => {
          toast.error(t('Something went wrong, please try again'));
          setResendDisabled(true);
        });
    } catch (error) {
      toast.error(t('Something went wrong, please try again'));
    }
    timeOutInit();
  }

  useEffect(() => {
    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);
      fetchCompanyLogoContent(token);
    } else setStatus((status) => COMPANY_NOT_FOUND);
  }, [token]);

  return (
    <Content position="center">
      <>
        <Space
          direction="vertical"
          size="large"
          style={{ width: '100%', marginTop: '50px' }}
        >
          {
            {
              RESEND_EMAIL_SUCCESS: <ResendEmailSuccess />,
              PASSWORD_VALIDATION_SUCCESS: <PasswordValidationSuccess />,
              PASSWORD_VALIDATION_FAIL: (
                <PasswordValidationFail
                  resendDisabled={resendDisabled}
                  countDown={countDown}
                  resendEmail={resendEmail}
                />
              ),
              COMPANY_NOT_FOUND: (
                <SendVaidationEmailForm
                  resendDisabled={resendDisabled}
                  countDown={countDown}
                  onFinish={onSendValidationEmailFormFinish}
                />
              ),
              SET_PASSWORD: (
                <SetPasswordForm
                  company={companyLogoContetn}
                  onFinish={onSetPasswordFormFinish}
                  loading={loading}
                />
              ),
            }[status]
          }
        </Space>
      </>
    </Content>
  );
}
