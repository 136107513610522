/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Col, Popover, Row, Skeleton, Tooltip } from 'antd';
import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { Gender, GenderKey } from 'types/Candidates';
import { FileCVIcon } from 'Icons/FileCVIcon';
import { ArrowDownIcon } from 'Icons/ArrowDownIcon';
import { convertToKB, documentSizeInKb } from 'utils/utilFunctions';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { IResume } from 'types/IResume';
import { getEmployeeResume } from 'api/employeeService';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';

interface IProps {
  email: string;
  status: string;
  birthDate: string;
  gender: string;
  maritalStatus: string;
  loading: boolean;
  employeeIdentifier: string;
}

export function FileItem({
  filename = 'Resume.pdf',
  filesize,
  filecontent,
  noActions,
  preview,
}: {
  filename?: string;
  filesize: string;
  filecontent: string | undefined;
  noActions?: boolean;
  preview?: boolean;
}) {
  return (
    <Styled.FileItemContainer noBorder={noActions}>
      <Col span={4}>
        <FileCVIcon />
      </Col>
      <Col span={14}>
        <Styled.FileItemName ellipsis={{ tooltip: true }}>
          {filename}
        </Styled.FileItemName>
        <Styled.FileItemSize>{convertToKB(filesize)}</Styled.FileItemSize>
      </Col>
      <Styled.Actions span={6} noActions={!preview && noActions}>
        <Row justify="space-around" align="top">
          <Styled.IconButton
            type="default"
            icon={<ArrowDownIcon />}
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              if (filecontent) {
                window.open(filecontent);
              }
            }}
          />
        </Row>
      </Styled.Actions>
    </Styled.FileItemContainer>
  );
}

export default function EmployeePersonalInfo({
  email,
  status,
  birthDate,
  gender,
  maritalStatus,
  loading,
  employeeIdentifier,
}: IProps) {
  const { employee, loaded } = useEmployeesData();
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  const [resume, setResume] = useState<IResume>();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const fetchResume = (id: string) => {
    getEmployeeResume(id)
      .then((response) => {
        if (response) {
          setResume(response.data);
        }
      })
      .catch(() => {
        console.log("Couldn't get candidate resume!");
      });
  };

  useEffect(() => {
    if (!loaded || !employee?.id || authUserRole === AUTH_ROLES.EMPLOYEE) {
      return;
    }
    if (employee?.id && loaded) {
      fetchResume(employee.id);
    }
  }, [employee, loaded, authUserRole]);

  const { t } = useTranslation();

  const handleCopyCode = () => {
    navigator.clipboard.writeText(employeeIdentifier);
    setIsCodeCopied(true);
  };

  return (
    <CardItem title={t('personalInfo')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Row gutter={[0, 16]}>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={`${t('employee')} #`}
                children={
                  <Styled.ItemValue ellipsis style={{ width: '80%' }}>
                    <Popover content={email} placement="topLeft">
                      {email}
                    </Popover>
                  </Styled.ItemValue>
                }
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item label={t('status')} value={status} children={undefined} />
            </Col>
          </Styled.RowItem>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('birthdate')}
                value={birthDate}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('gender')}
                value={Gender[gender as GenderKey]}
                children={undefined}
              />
            </Col>
          </Styled.RowItem>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('martialStatus')}
                value={maritalStatus}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={
                  <span>
                    {t('employeeCode')}
                    <Tooltip title={isCodeCopied ? t('copied') : t('copyCode')}>
                      <Styled.CopyButton
                        icon={<CopyOutlined />}
                        onClick={handleCopyCode}
                      />
                    </Tooltip>
                  </span>
                }
                value={employeeIdentifier}
              />
            </Col>
          </Styled.RowItem>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('location')}
                value={`${employee?.tenantLocation?.country},${employee?.tenantLocation?.city}`}
                children={undefined}
              />
            </Col>
          </Styled.RowItem>
          {resume?.content ? (
            <Styled.RowItem last>
              <Col
                md={{ span: 24 }}
                xs={{ span: 24 }}
                xl={{ span: 12 }}
                sm={{ span: 24 }}
              >
                <Item label="CV" value="">
                  <FileItem
                    filename={resume?.name as string}
                    filesize={documentSizeInKb()}
                    filecontent={resume?.content as string}
                  />
                </Item>
              </Col>
            </Styled.RowItem>
          ) : (
            ''
          )}
        </Row>
      </Skeleton>
    </CardItem>
  );
}
