import AssetActions from 'components/Actions/AssetAction';
import * as Styled from 'ui-v2/components/DataGridComponents';

import React from 'react';
import { AssetType } from 'types/Asset';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { Space } from 'antd';
import i18next from '../i18n';

export const AssetColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    width: 100,
    default: true,
    order: 0,
    sorter: (a: AssetType, b: AssetType) =>
      a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    width: 100,
    render: (record: string) => <Styled.Text>{record}</Styled.Text>,
    default: true,
    order: 1,
  },
  {
    title: i18next.t('serialNumber'),
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    width: 100,
    default: false,
    order: 2,
  },
  {
    title: i18next.t('purchaseDate'),
    dataIndex: 'purchaseDate',
    key: 'purchaseDate',
    width: 100,
    sorter: (a: AssetType, b: AssetType) =>
      convertUTCtoFormatedLocalTime(a?.purchaseDate).localeCompare(
        convertUTCtoFormatedLocalTime(b?.purchaseDate)
      ),
    render: (record: Date) => (
      <Space>{convertUTCtoFormatedLocalTime(record)}</Space>
    ),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('purchasePrice'),
    dataIndex: 'purchasePrice',
    key: 'purchasePrice',
    width: 100,
    default: false,
    order: 4,
    sorter: (a: AssetType, b: AssetType) =>
      (a?.purchasePrice ?? 0) - (b?.purchasePrice ?? 0),
    render: (record: string, row: any) => (
      <>
        {row.currency?.symbol === 'ALL'
          ? `${row?.purchasePrice
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${row.currency?.symbol}`
          : `${row?.purchasePrice} ${row.currency?.symbol}`}
      </>
    ),
  },
  {
    title: i18next.t('condition'),
    dataIndex: 'condition',
    key: 'condition',
    width: 100,
    default: false,
    order: 5,
  },
  {
    title: i18next.t('type'),
    dataIndex: 'type',
    key: 'type',
    width: 100,
    default: false,
    order: 6,
  },
  {
    title: i18next.t('status'),
    dataIndex: 'status',
    key: 'status',
    width: 100,
    default: false,
    order: 7,
  },
  {
    title: i18next.t('action'),
    key: 'action',
    width: 100,
    margin: '10px',
    render: (_: string, row: AssetType) => <AssetActions id={row.id} />,
    default: false,
    order: 8,
    fixed: 'right',
    // render: (record: any, row: AssetView) => (
    //   <AssetActions id={row.id} employeeName={record?.employeeName} />
    // ),
  },
];
