import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { EmployeeWorkExperienceType } from 'types/EmployeeWorkExperience';
import {
  createEmployeeWorkExperience,
  updateEmployeeWorkExperience,
} from 'api/employeeWorkExperience';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
// import { convertUTCtoLocalTime } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  workExperienceSelected?: EmployeeWorkExperienceType;
}

export default function AddWorkExperienceModal({
  open,
  closeModal,
  workExperienceSelected,
}: IProps) {
  const [form] = Form.useForm();
  const { employee } = useEmployeesData();
  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'workTitle',
          label: t('title'),
          type: 'input',
          defaultValue: workExperienceSelected?.workTitle,
          rules: [
            {
              required: true,
              message: t('experienceTitleisRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'companyName',
          label: t('company'),
          type: 'input',
          defaultValue: workExperienceSelected?.companyName,
          rules: [
            {
              required: true,
              message: t('companyNameisRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: form.getFieldValue('present') ? 'disabled' : t('start'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(
            workExperienceSelected?.startDate
          ),
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('end'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(workExperienceSelected?.endDate),
          disabled: endDateDisabled,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'present',
          label: t('stillWorkingHere'),
          defaultValue: workExperienceSelected?.ongoing,
          type: 'checkbox',
          checked: endDateDisabled,
        },
      ],
    ],
    [endDateDisabled, workExperienceSelected]
  );

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const formValues: EmployeeWorkExperienceType = form.getFieldsValue(true);

    const valuesToSend = {
      ...formValues,
      employeeId: employee?.id,
      startDate:
        formValues?.startDate && convertDateToUTC(formValues?.startDate),
      endDate: formValues?.endDate && convertDateToUTC(formValues?.endDate),
    };

    if (workExperienceSelected?.id) {
      await updateEmployeeWorkExperience(
        valuesToSend,
        workExperienceSelected?.id
      )
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(fetchEmployeesDetail(employee?.id));
            toast.success(t('Succesfully updated work experience'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    } else {
      await createEmployeeWorkExperience(valuesToSend)
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(fetchEmployeesDetail(employee?.id));
            toast.success(t('Succesfully added work experience'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    }
  };

  const onFieldsChange = (values: any) => {
    if (values[0]?.name[0] === 'present') {
      setEndDateDisabled((state) => !state);
    }
  };

  return (
    <GenericModal
      title={
        workExperienceSelected?.id
          ? t('editWorkExperience')
          : t('addworkExperience')
      }
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
