/* eslint-disable no-nested-ternary */
import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useEffect, useMemo, useState } from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import { InputTypes } from 'types/FormTypes';
import {
  assignBenefitTemplateToEmployee,
  getBenefitTemplatePlans,
  getEmployeeAvailableBenefitTemplates,
  upgradeEmployeeBenefitPlan,
} from 'api/benefitTemplates';
import {
  editBenefitSuccessMessage,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BenefitTemplateDTO } from 'types/Benefit';

interface IProps {
  open: boolean;
  closeModal: (refetch?: boolean) => void;
  employeeId: string;
  selectedBenefit: any;
}

export default function BenefitModal({
  open,
  closeModal,
  employeeId,
  selectedBenefit,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [benefitTemplates, setBenefitTemplates] = useState<
    BenefitTemplateDTO[]
  >([]);
  const [plans, setPlans] = useState([]);
  const [templateId, setTemplateId] = useState('');
  const [planId, setPlanId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const authUserState = useSelector((state: any) => state.authUser);
  const { t } = useTranslation();

  const fetchBenefitTemplatesOptions = async () => {
    try {
      const response = await getEmployeeAvailableBenefitTemplates(employeeId);
      if (response.status === 200) {
        setBenefitTemplates(response?.data);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  useEffect(() => {
    fetchBenefitTemplatesOptions();
  }, []);

  useEffect(() => {
    const fetchBenefitTemplatePlans = async (templateId: string) => {
      try {
        const response = await getBenefitTemplatePlans(templateId);
        if (response.status === 200) {
          setPlans(response?.data);
        }
      } catch (error) {
        toastErrorMessages(error);
      }
    };
    if (selectedBenefit?.bt_id || templateId) {
      fetchBenefitTemplatePlans(selectedBenefit?.bt_id || templateId);
    }
  }, [templateId, selectedBenefit]);

  const benefitTemplateOptions = benefitTemplates?.map(
    (benefitTemplate: BenefitTemplateDTO) => ({
      id: benefitTemplate?.id,
      label: benefitTemplate?.type,
      value: benefitTemplate?.id,
    })
  );
  const BenefitConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'specifications',
          label: t('specifications'),
          type: InputTypes.INPUT,
          disabled: !!selectedBenefit?.bt_id,
          defaultValue: selectedBenefit?.bt_specifications,
          rules: [
            {
              required: true,
              message: t('Type is required!'),
            },
          ],
        },
      ],
      !selectedBenefit?.bt_id
        ? [
            {
              col: 24,
              offset: 0,
              name: 'template',
              label: t('templates'),
              type: InputTypes.SELECT,
              disabled: !!selectedBenefit?.bt_id,
              selectOptions: benefitTemplateOptions,
              onSelect: setTemplateId,
              rules: [
                {
                  required: !selectedBenefit?.bt_id,
                  message: t('Type is required!'),
                },
              ],
            },
          ]
        : [],
      [
        {
          col: 24,
          offset: 0,
          name: 'plan',
          label: t('plans'),
          type: InputTypes.SELECT,
          selectOptions: plans.map((i: any) => ({
            id: i.id,
            label: i.plan_name,
            value: i.plan_value,
          })),
          onSelect: setPlanId,
          defaultValue: planId || selectedBenefit?.btp_id,
          rules: [
            {
              required: true,
              message: t('planisRequired'),
            },
          ],
          disabled: !selectedBenefit && !templateId,
        },
      ],
    ],
    [benefitTemplateOptions, templateId, plans, planId, selectedBenefit]
  );
  const onSubmit = async () => {
    if (loading) return;

    setLoading(true);

    const formValues = form.getFieldsValue(true);

    const createPayload = {
      ...formValues,
      employeeId,
    };

    const editPayload = {
      ...formValues,
      fromEmployee: employeeId,
      benefitPackageId: selectedBenefit?.benefits_id,
      planId: formValues.plan,
    };

    try {
      if (selectedBenefit?.benefits_id) {
        const response = await upgradeEmployeeBenefitPlan(editPayload);
        if (response.status === 200) {
          editBenefitSuccessMessage(
            selectedBenefit?.benefits_id,
            authUserState
          );
          searchParams.append('added-benefit', 'true');
          setSearchParams(searchParams);
          setLoading(false);
          closeModal();
        }
      } else {
        const response = await assignBenefitTemplateToEmployee(createPayload);
        if (response.status === 200) {
          toast.success(t('Succesfully assigned benefit template'));
          searchParams.append('added-benefit', 'true');
          setSearchParams(searchParams);
          setLoading(false);
          closeModal();
        }
      }
    } catch (error) {
      toastErrorMessages(error);
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <GenericModal
      title={selectedBenefit ? t('editBenefit') : t('addBenefit')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={BenefitConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={onCloseModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
