import agent from 'api/agent';
import { CreatePayrollRuleDTO } from 'types/PayrollRule';
import { PayrollRulesQueryParamsType } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getPayrollRules = (queryParams: PayrollRulesQueryParamsType) =>
  agent.post(
    `${apiURLs.payrollRules}/get/${queryParams.tenantLocationId ?? ''}`,
    queryParams
  );

export const getSinglePayrollRule = (id: string) =>
  agent.get(`${apiURLs.payrollRules}/${id}`);

export const getPayrollRuleByHumanId = (humandId: string) =>
  agent.get(`${apiURLs.payrollRules}/historical/${humandId}`);

export const createPayrollRule = (payload: CreatePayrollRuleDTO) =>
  agent.post(`${apiURLs.payrollRules}`, payload);

export const updatePayrollRule = (payload: CreatePayrollRuleDTO) =>
  agent.put(`${apiURLs.payrollRules}`, payload);

export const deletePayrollRule = (id: string) =>
  agent.delete(`${apiURLs.payrollRules}/${id}`);
