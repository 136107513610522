import React from 'react';
import { Row } from 'antd';
import { TenantDataType } from 'types/Tenant';
import TenantActions from 'components/Actions/TenantActions';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { getCandidateStatusIcon } from 'ui-v2/components/Header/helpers';
import { TableRowCellDataType } from 'types/GridTable';
import i18next from '../../i18n';

interface TenantColumnType extends TableRowCellDataType {
  key: keyof TenantDataType | 'action';
  dataIndex: keyof TenantDataType | 'action';
  sorter?: (a: TenantDataType, b: TenantDataType) => any;
  render?: (_: string, value: TenantDataType) => any;
}

const NEW_STATUS = i18next.t('new');
const VERIFY_STATUS = i18next.t('verify');
const REJECTED_STATUS = i18next.t('rejected');
const APPROVED_STATUS = i18next.t('approved');
const PENDING_STATUS = i18next.t('pending');

export const TenantColumns: TenantColumnType[] = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a: TenantDataType, b: TenantDataType) =>
      a.name.localeCompare(b.name),
    render: (_: string, item: TenantDataType) => (
      <Styled.TextBold ellipsis={{ tooltip: item?.logoContent }}>
        {item.name}
      </Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('email'),
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    render: (_: string, row: TenantDataType) => (
      <Styled.Text>{row.email}</Styled.Text>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('phone'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    ellipsis: true,
    default: false,
    order: 2,
  },
  {
    title: i18next.t('country'),
    dataIndex: 'country',
    key: 'country',
    ellipsis: true,
    render: (_: string, item: TenantDataType) => (
      <Styled.Text>{item.country}</Styled.Text>
    ),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('city'),
    dataIndex: 'city',
    key: 'city',
    ellipsis: true,
    render: (record: string, item: TenantDataType) => (
      <Styled.Text>{item.city}</Styled.Text>
    ),
    default: false,
    order: 4,
  },
  {
    title: i18next.t('sector'),
    dataIndex: 'sector',
    key: 'sector',
    ellipsis: true,
    render: (_: string, item: TenantDataType) => (
      <Styled.Text>{item.sector}</Styled.Text>
    ),
    default: true,
    order: 5,
  },
  {
    title: i18next.t('status'),
    dataIndex: 'approved',
    key: 'approved',
    ellipsis: true,
    render: (_: string, item: TenantDataType) => {
      let status = NEW_STATUS.toLowerCase();

      if (item?.deletedAt) {
        status = REJECTED_STATUS.toLowerCase();
      } else if (item?.approved) {
        status = VERIFY_STATUS.toLowerCase();
      }

      return (
        <Row justify="start">
          <Styled.StatusTag status={status}>
            <p>{getCandidateStatusIcon(status)}</p>
            {item?.deletedAt && REJECTED_STATUS}
            {!item?.deletedAt && item?.approved && APPROVED_STATUS}
            {!item?.deletedAt && !item?.approved && PENDING_STATUS}
          </Styled.StatusTag>
        </Row>
      );
    },
    default: true,
    order: 6,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    render: (_: string, tenant: TenantDataType) => (
      <TenantActions id={tenant?.id} />
    ),
    width: 80,
    default: false,
    order: 7,
  },
];
