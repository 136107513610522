import React from 'react';

import { RangePickerProps } from 'antd/lib/date-picker';

import { RulesType } from 'types/FormTypes';

import { CalendarIcon } from 'Icons/CalendarIcon';
import { getUserDateFormat } from 'utils/utilFunctions';
import { FormItemStyled, StyledDateRange } from '../FormStyled';

type Props = RangePickerProps & {
  label: string;
  name: string;
  rules?: RulesType[];
};

const DateRange = ({ name, label, rules, ...props }: Props) => {
  const userDateFormat = getUserDateFormat();

  return (
    <FormItemStyled name={name} label={label} rules={rules}>
      <StyledDateRange
        format={userDateFormat}
        suffixIcon={<CalendarIcon />}
        {...props}
      />
    </FormItemStyled>
  );
};

DateRange.defaultProps = {
  rules: undefined,
};

export default DateRange;
