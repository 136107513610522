import { MetaPagination } from 'types/GridTable';
import { PurchaseOrderType } from 'types/PurchaseOrder';

export enum PurchaseOrders {
  FETCH_PURCHASE_ORDERS_START = 'FETCH_PURCHASE_ORDERS_START',
  FETCH_PURCHASE_ORDERS_SUCCESS = 'FETCH_PURCHASE_ORDERS_SUCCESS',
  FETCH_PURCHASE_ORDERS_ERRORED = 'FETCH_PURCHASE_ORDERS_ERRORED',

  FETCH_PURCHASE_ORDER_START = 'FETCH_PURCHASE_ORDER_START',
  FETCH_PURCHASE_ORDER_SUCCESS = 'FETCH_PURCHASE_ORDER_SUCCESS',
  FETCH_PURCHASE_ORDER_ERRORED = 'FETCH_PURCHASE_ORDER_ERRORED',

  CLEAR_PURCHASE_ORDER_STATE = 'CLEAR_PURCHASE_ORDER_STATE',
}

export interface PurchaseOrderState {
  data: {
    data: Array<PurchaseOrderType>;
    meta: MetaPagination | null;
  };
  purchaseOrderDetail: PurchaseOrderType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
