import styled from 'styled-components';
import { EmployeeTaskStatus } from 'types/EmployeeTask';

export const StatusTag = styled.div<{
  status: string;
}>`
  width: fit-content;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ status }) =>
    status === EmployeeTaskStatus.FINISHED && 'background-color: #c9ffe1;'};
  ${({ status }) =>
    status === EmployeeTaskStatus.IN_PROGRESS &&
    'background-color: #00ffff23;'};
  ${({ status }) =>
    status === EmployeeTaskStatus.CREATED && 'background-color: #eaecf0;'};

  p {
    margin-bottom: 0;
    margin-left: 6px;
  }
`;
