import { MetaPagination } from 'types/GridTable';
import { VendorCategoryType } from 'types/VendorCategory';

export enum VendorCategories {
  FETCH_VENDOR_CATEGORIES_START = 'FETCH_VENDOR_CATEGORIES_START',
  FETCH_VENDOR_CATEGORIES_SUCCESS = 'FETCH_VENDOR_CATEGORIES_SUCCESS',
  FETCH_VENDOR_CATEGORIES_ERRORED = 'FETCH_VENDOR_CATEGORIES_ERRORED',

  FETCH_VENDOR_CATEGORY_START = 'FETCH_VENDOR_CATEGORY_START',
  FETCH_VENDOR_CATEGORY_SUCCESS = 'FETCH_VENDOR_CATEGORY_SUCCESS',
  FETCH_VENDOR_CATEGORY_ERRORED = 'FETCH_VENDOR_CATEGORY_ERRORED',

  CLEAR_VENDOR_CATEGORY_STATE = 'CLEAR_VENDOR_CATEGORY_STATE',
}

export interface VendorCategoryState {
  data: {
    data: Array<VendorCategoryType>;
    meta: MetaPagination | null;
  };
  vendorCategoryDetail: VendorCategoryType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
