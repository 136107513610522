import { Col, Form, Row } from 'antd';
import {
  createEmployeeTrainings,
  getAllEmployeeTrainings,
  updateEmployeeTrainings,
} from 'api/employeeService';
import { getTraining } from 'api/trainingService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { fetchTrainingsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { AssetsIcon } from 'Icons/AssetsIcon';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import {
  EmployeeTrainingsDTO,
  EmployeeTrainingStatus,
  EmployeeTrainingType,
} from 'types/Employee';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { convertDateToUTC, convertUTCtoLocalTime } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedTraining?: EmployeeTrainingType;
  employeeId: string;
  setEmployeeTrainings: Dispatch<SetStateAction<[]>>;
}

export default function AddTrainingModal({
  open,
  closeModal,
  selectedTraining,
  employeeId,
  setEmployeeTrainings,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [training, setTraining] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [startDate, setStartDate] = useState<any>();
  const { t } = useTranslation();

  const trainingEndDateOnEdit = `${
    selectedTraining?.training?.valueTime || ''
  } ${selectedTraining?.training?.timeType || ''}`;

  const onSelect = async () => {
    if (selectedTraining) {
      const response = await getTraining(selectedTraining?.training.id);
      setTraining(response.data);
    } else {
      const response = await getTraining(
        form.getFieldValue('trainingId')?.value
      );
      setTraining(response.data);
    }
  };

  const handleStartDateChange = () => [
    setStartDate(form.getFieldValue('startDate')),
  ];
  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'trainingId',
          label: t('trainingsList'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTrainingsOptions,
          defaultValue: selectedTraining?.training.name,
          onSelect,
          rules: [
            {
              required: true,
              message: t('Training is required'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          type: InputTypes.DATEPICKER,
          onChange: handleStartDateChange,
          defaultValue:
            convertUTCtoLocalTime(startDate) ||
            convertUTCtoLocalTime(selectedTraining?.startDate),
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('endDate'),
          type: InputTypes.DATEPICKER,
          defaultValue:
            convertUTCtoLocalTime(endDate) ||
            convertUTCtoLocalTime(selectedTraining?.endDate),
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'trainingStatus',
          label: t('employeeStatus'),
          type: InputTypes.SELECT,
          selectOptions: Object.values(EmployeeTrainingStatus).map(
            (item: string) => ({
              id: item,
              value: item,
              label: item.charAt(0).toUpperCase() + item.slice(1),
            })
          ),
          defaultValue: selectedTraining?.trainingStatus,
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'notes',
          label: t('notes'),
          type: 'input',
          defaultValue: selectedTraining?.notes,
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
    ],
    [startDate]
  );

  const onSubmit = async () => {
    if (loading || !employeeId) return;

    setLoading(true);
    const formValues: EmployeeTrainingsDTO = form.getFieldsValue(true);

    const valueToSend = {
      employeeId,
      trainingId: form.getFieldValue('trainingId')?.value,
      notes: formValues.notes,
      startDate: startDate || new Date(convertDateToUTC(formValues.startDate)),
      endDate: endDate || new Date(convertDateToUTC(formValues.endDate)),
      trainingStatus: formValues.trainingStatus,
    };

    if (selectedTraining?.id) {
      await updateEmployeeTrainings(selectedTraining?.id, valueToSend)
        .then(() => {
          getAllEmployeeTrainings(employeeId).then((response) => {
            if (response?.data?.data) {
              setEmployeeTrainings(response.data.data);
            }
          });
          dispatch(fetchEmployeesDetail(employeeId));
          toast.success(t('successfullyUpdatedTraining'));
          closeModal();
        })
        .catch(() => {
          toast.error(t('failedtoUpdateTraining'));
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    await createEmployeeTrainings(valueToSend)
      .then(() => {
        getAllEmployeeTrainings(employeeId).then((response) => {
          if (response?.data?.data) {
            setEmployeeTrainings(response.data.data);
          }
        });
        dispatch(fetchEmployeesDetail(employeeId));
        toast.success(t('successfullyAssignedTraining'));
        closeModal();
      })
      .catch(() => {
        toast.error(t('failedtoAssignTraining'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFieldsChange = () => {
    if (training?.id) {
      setEndDate(
        form.getFieldValue('endDate') ||
          moment(form.getFieldValue('startDate')).add(
            training?.valueTime,
            training?.timeType
          )
      );
    }

    if (selectedTraining?.id) {
      setEndDate(
        form.getFieldValue('endDate') ||
          moment(startDate).add(
            selectedTraining?.training?.valueTime,
            selectedTraining?.training?.timeType
          )
      );
    }
  };

  return (
    <GenericModal
      title={selectedTraining?.id ? t('Edit training') : t('assignTraining')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <Row justify="end" style={{ fontStyle: 'italic' }}>
        <Col>
          {`${t('trainingDuration')}: ${
            selectedTraining?.id
              ? `${trainingEndDateOnEdit}`
              : `${training?.valueTime || ''} ${training?.timeType || ''}`
          } `}
        </Col>
      </Row>
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
