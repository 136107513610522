import { DepartmentModelType, DepartmentSingleType } from 'types/Department';
import { MetaPagination } from 'types/GridTable';

export enum Departments {
  FETCH_DEPARTMENT_START = 'FETCH_DEPARTMENT_START',
  FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS',
  FETCH_DEPARTMENT_ERRORED = 'FETCH_DEPARTMENT_ERRORED',

  FETCH_DEPARTMENTS_START = 'FETCH_DEPARTMENTS_START',
  FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS',
  FETCH_DEPARTMENTS_ERRORED = 'FETCH_DEPARTMENTS_ERRORED',

  CLEAR_DEPARTMENT = 'CLEAR_DEPARTMENT',
}

export interface DepartmentsState {
  data: {
    data: Array<DepartmentModelType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface DepartmentState {
  data: DepartmentSingleType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
