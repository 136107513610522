import ProjectModalForm from 'components/NewForms/ProjectForm';
import React, { useCallback, useEffect, useState } from 'react';

import { clearSingleProject, fetchProjects } from 'redux/projects/actions';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { deleteProject } from 'api/projectService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function ProjectsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useProjectsData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const dispatch = useDispatch();

  const getProjectList = useCallback(async () => {
    dispatch(fetchProjects({ page: 1, take }));
    dispatch(clearSingleProject());
  }, [take]);

  useEffect(() => {
    getProjectList();
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchProjects(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchProjects(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-project') === 'true') {
      searchParams.delete('add-project');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-project')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  async function deleteProjectById(id: string | null) {
    if (!id) return;
    deleteProject(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Project deleted successfully!');
          dispatch(fetchProjects({ page: pagination?.page || 1, take: 10 }));
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteProjectById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Projects"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onSelectedDelete={onDelete}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <ProjectModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-project');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
