import React, { useEffect, useState } from 'react';

import DataGrid from 'components/common/DataGrid/DataGrid';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { clearSingleEmployee, fetchEmployees } from 'redux/employees/actions';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import useHeader from 'ui-v2/hooks/useHeader';
import { removeEmployee } from 'api/employeeService';
import { toastErrorMessages } from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const EmployeeList = () => {
  const dispatch = useDispatch();
  const { columns, take } = useHeader();
  const { list: employees, loading, pagination } = useEmployeesData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(clearSingleEmployee());
  }, []);

  useEffect(() => {
    dispatch(fetchEmployees({ page: 1, take }));
  }, [take]);

  async function deleteEmployeeById(id: string | null) {
    if (!id) return;
    removeEmployee(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('employeeDeletedSuccessfully'));
          let currentPage = pagination?.page;
          if (employees.length === 1) {
            currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
          }
          dispatch(fetchEmployees({ page: currentPage, take }));
        }
      })
      .catch(toastErrorMessages);
  }

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchEmployees(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchEmployees(params));
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteEmployeeById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <DataGrid
        loading={loading}
        columns={columns}
        data={employees}
        paginationName="employees"
        onPageChange={onPageChange}
        totalItems={pagination.itemCount}
        currentPage={pagination.page}
        totalPages={pagination.pageCount}
        showSearch
        onSelectedDelete={onDelete}
        onSearch={onSearch}
      />
    </Content>
  );
};

export default EmployeeList;
