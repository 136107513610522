import { Spin, Col, Row } from 'antd';
import { getQuestionnaireFeedback } from 'api/questionnaireAssignmentService';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CreateQuestionnaireAlternativeDTO } from 'types/Questionnaires';
import GenericModal from 'ui-v2/components/GenericModal';
import RateComponent from 'components/NewForms/RateComponent';
import { StyledQuestion, StyledAnswer } from './QuestionnaireAssignmentStyles';

interface ReviewerModalProps {
  visible: boolean;
  revieweeId: string | null | undefined;
  reviewerId: string | null | undefined;
  questionnaireId: string | null;
  onCancel: () => void;
  modalTitle: string;
}

const ViewFeedBackModal: React.FC<ReviewerModalProps> = ({
  visible,
  revieweeId,
  reviewerId,
  modalTitle,
  questionnaireId,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchQuestionnaireFeedback = useCallback(async () => {
    if (!questionnaireId || !revieweeId || !reviewerId) return;
    setLoading(true);
    getQuestionnaireFeedback(questionnaireId, revieweeId, reviewerId)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        toast.error(t('cantGetFeedback'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [questionnaireId]);

  useEffect(() => {
    if (questionnaireId && revieweeId) {
      fetchQuestionnaireFeedback();
    }
  }, [questionnaireId, revieweeId]);

  return (
    <GenericModal open={visible} closeModal={onCancel} title={modalTitle}>
      <Spin spinning={loading}>
        <Col>
          {Object.entries(data).length === 0 && !loading ? (
            <Row gutter={10} justify="space-between" align="middle">
              <StyledQuestion>
                {t('questionnaireHasNotYetBeenCompletedByEmployees')}
              </StyledQuestion>
            </Row>
          ) : (
            Object.entries(data).map(([key, value], index) => {
              const typedValue = value as {
                question: { content: string };
                alternatives: Array<CreateQuestionnaireAlternativeDTO>;
              };
              const questionContent = typedValue.question.content;
              const answerContent = typedValue.alternatives.map(
                (alternative: CreateQuestionnaireAlternativeDTO) =>
                  alternative.content
              )[0];
              const scoreValue = typedValue.alternatives.map(
                (alternative: CreateQuestionnaireAlternativeDTO) =>
                  alternative.score
              )[0];

              return (
                <Col span={24} key={key} style={{ marginBottom: 10 }}>
                  <StyledQuestion>
                    {`${index + 1}. ${questionContent}`}
                  </StyledQuestion>
                  <Col>
                    <Row gutter={10} justify="space-between" align="middle">
                      <StyledAnswer>{answerContent}</StyledAnswer>
                      <RateComponent
                        defaultValue={scoreValue}
                        placeholder=""
                        name="Score"
                        disabled
                      />
                    </Row>
                  </Col>
                </Col>
              );
            })
          )}
        </Col>
      </Spin>
    </GenericModal>
  );
};

export default ViewFeedBackModal;
