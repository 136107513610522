import { Reducer } from 'react';
import { Action } from 'redux';
import { AuthActionTypes, AuthUserState } from './types';

const initialState: AuthUserState = {
  authUser: null,
  authUserRole: null,
  authUserPermissions: null,
  accountBlocked: false,
  twoFactorRequired: false,
  twoFactorSetupRequired: false,
  loading: false,
  error: null,
};

export interface AuthReducedAction extends AuthUserState, Action {}

const authReducer: Reducer<AuthUserState, AuthReducedAction> = (
  state = initialState,
  action: AuthReducedAction
) => {
  switch (action.type) {
    case AuthActionTypes.UPDATE_AUTH_STATE:
      state = {
        ...state,
        ...action,
      };
      break;
    case AuthActionTypes.ON_LOGOUT:
      state = initialState;
      break;
    case AuthActionTypes.CLEAR_AUTH_STATE:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { authReducer };
