/* eslint-disable indent */
import { getAllDepartments, getOneDepartment } from 'api/departmentService';
import { Dispatch } from 'redux';
import { DepartmentQueryParamsType } from 'types/QueryParams';
import { Departments } from './types';

export const fetchDepartments =
  (queryParams: DepartmentQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Departments.FETCH_DEPARTMENTS_START,
    });
    getAllDepartments(queryParams)
      .then((res) => {
        dispatch({
          type: Departments.FETCH_DEPARTMENTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Departments.FETCH_DEPARTMENTS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleDepartment = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Departments.FETCH_DEPARTMENT_START,
  });
  getOneDepartment(id)
    .then((res) => {
      dispatch({
        type: Departments.FETCH_DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Departments.FETCH_DEPARTMENT_ERRORED,
        payload: e,
      });
    });
};

export const clearSingleDepartment = () => (dispatch: Dispatch) => {
  dispatch({
    type: Departments.CLEAR_DEPARTMENT,
  });
};
