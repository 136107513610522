import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { RootState } from 'redux/store';
import { CLOSE_BANNER } from 'redux/hrDuties/types';
import { isAdmin, isHR } from 'utils/utilFunctions';
import { checkHrDutiesCompletion } from 'redux/hrDuties/actions';
import { useTranslation } from 'react-i18next';
import { Banner, BannerLink } from './HRDutiesBannerStyles';
import { mapper } from './herlpers';

export default function HrDutiesBanner() {
  const auth = useSelector((state: RootState) => state.authUser);
  const [hrDuties, setHrDuties] = useState<string>('');
  const dispatch = useDispatch();
  const hrDutiesData: any = useSelector((state: RootState) => state.hrDuties);
  const { data, loading } = hrDutiesData;
  const isAdminUser = isAdmin(auth);
  const isHrUser = isHR(auth);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(checkHrDutiesCompletion());
  }, []);

  useEffect(() => {
    if (data) {
      const duty = Object.keys(data)?.find((key) => data[key] === false);
      setHrDuties(duty ?? '');
    }
  }, [data]);

  const isBannerClosed: any = useSelector(
    (state: RootState) => state.isClosedBanner
  );

  const onCloseBanner = () => {
    dispatch({ type: CLOSE_BANNER });
  };

  if (loading || !hrDuties || !isBannerClosed || !(isAdminUser || isHrUser)) {
    return null;
  }

  return (
    <>
      <Banner
        message={`${t('Please add some')} ${hrDuties}`}
        action={
          <BannerLink>
            <Link to={`/${mapper(hrDuties)}`}>
              <Typography.Text>{`${t('Go to')} ${hrDuties}`}</Typography.Text>
            </Link>
          </BannerLink>
        }
        banner
        closable
        onClose={onCloseBanner}
      />
    </>
  );
}
