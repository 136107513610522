import { Col, Row } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';

import styled from 'styled-components';

export const StyledOnboardingContent = styled(Row)`
  & .bg-dark {
    background-color: #101828;
  }

  & .text-white {
    color: white;
  }

  & .bg-avatar {
    background-color: #69b1ff;
  }
`;

export const OnboardingHeaderWrapper = styled(Col)`
  background-color: #101828;
  padding: 1.5rem 2rem;
`;

export const OnboardingStepBtn = styled(StyledButton)`
  [class*='anticon'] {
    margin: unset;
    padding: unset;
    svg {
      margin: unset;
      padding: unset;
    }
  }
  ${(props) => props.disabled && "[class*='ant-typography'] { color: #d9d9d9;}"}
  ${(props) =>
    props.type === 'primary' && "[class*='ant-typography'] { color: white;}"}
`;
export const FlexRow = styled(Row)`
  display: flex !important;
  align-items: center !important;
  gap: 4px;
`;
