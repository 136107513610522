import React from 'react';

export const AssetSidebarIcon = () => (
  <svg
    width="20"
    height="19.88"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 13V4.2C20 3.0799 20 2.51984 19.782 2.09202C19.5903 1.71569 19.2843 1.40973 18.908 1.21799C18.4802 1 17.9201 1 16.8 1H5.2C4.07989 1 3.51984 1 3.09202 1.21799C2.71569 1.40973 2.40973 1.71569 2.21799 2.09202C2 2.51984 2 3.0799 2 4.2V13M3.66667 17H18.3333C18.9533 17 19.2633 17 19.5176 16.9319C20.2078 16.7469 20.7469 16.2078 20.9319 15.5176C21 15.2633 21 14.9533 21 14.3333C21 14.0233 21 13.8683 20.9659 13.7412C20.8735 13.3961 20.6039 13.1265 20.2588 13.0341C20.1317 13 19.9767 13 19.6667 13H2.33333C2.02334 13 1.86835 13 1.74118 13.0341C1.39609 13.1265 1.12654 13.3961 1.03407 13.7412C1 13.8683 1 14.0233 1 14.3333C1 14.9533 1 15.2633 1.06815 15.5176C1.25308 16.2078 1.79218 16.7469 2.48236 16.9319C2.73669 17 3.04669 17 3.66667 17Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
