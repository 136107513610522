import React from 'react';

export const ArchieveTableIcon = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.83317 5.66383C2.6965 5.6603 2.59725 5.65239 2.50802 5.63464C1.84687 5.50313 1.33004 4.9863 1.19853 4.32515C1.1665 4.16415 1.1665 3.97055 1.1665 3.58333C1.1665 3.19612 1.1665 3.00251 1.19853 2.84152C1.33004 2.18037 1.84687 1.66354 2.50802 1.53202C2.66902 1.5 2.86262 1.5 3.24984 1.5H15.7498C16.1371 1.5 16.3307 1.5 16.4917 1.53202C17.1528 1.66354 17.6696 2.18037 17.8011 2.84152C17.8332 3.00251 17.8332 3.19612 17.8332 3.58333C17.8332 3.97055 17.8332 4.16415 17.8011 4.32515C17.6696 4.9863 17.1528 5.50313 16.4917 5.63464C16.4024 5.65239 16.3032 5.6603 16.1665 5.66383M7.83317 9.83333H11.1665M2.83317 5.66667H16.1665V12.5C16.1665 13.9001 16.1665 14.6002 15.894 15.135C15.6543 15.6054 15.2719 15.9878 14.8015 16.2275C14.2667 16.5 13.5666 16.5 12.1665 16.5H6.83317C5.43304 16.5 4.73297 16.5 4.19819 16.2275C3.72779 15.9878 3.34534 15.6054 3.10565 15.135C2.83317 14.6002 2.83317 13.9001 2.83317 12.5V5.66667Z"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
