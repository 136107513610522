import { MetaPagination } from 'types/GridTable';
import { InvoicingType } from 'types/Invoicing';

export enum Invoicing {
  FETCH_INVOICES_START = 'FETCH_INVOICES_START',
  FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_ERRORED = 'FETCH_INVOICES_ERRORED',

  FETCH_INVOICE_START = 'FETCH_INVOICE_START',
  FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS',
  FETCH_INVOICE_ERRORED = 'FETCH_INVOICE_ERRORED',

  CLEAR_INVOICE_STATE = 'CLEAR_INVOICE_STATE',
}

export interface InvoicingState {
  data: {
    data: Array<InvoicingType>;
    meta: MetaPagination | null;
  };
  invoicing: InvoicingType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
