/* eslint-disable */
import { Select } from 'antd';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { RulesType } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { FormItemStyled, SelectStyled, StyledInput } from '../FormStyled';

const { Option } = Select;

export interface Props {
  placeholder: string;
  hidePlaceholder?: boolean;
  selectOptions?: OptionType[];
  name: string;
  rules?: RulesType[];
  disabled?: boolean;
  onSelect?: any;
  onDeselect?: any;
  onSelectChange?: any;
  mode?: 'multiple' | 'tags' | undefined;
  styles?: CSSProperties;
  showSearch?: boolean;
  defaultValue?: string;
  onClear?: any;
  value?: string;
  hint?: React.ReactNode | string;
}

const SelectComponents: React.FC<Props> = ({
  placeholder,
  hidePlaceholder,
  selectOptions,
  name,
  rules,
  disabled,
  onSelect,
  onDeselect,
  onSelectChange,
  onClear,
  styles,
  mode,
  showSearch,
  defaultValue,
  value,
  hint,
}) => {
    return (
    <FormItemStyled
      name={name}
      style={styles}
      label={!hidePlaceholder && placeholder}
      rules={rules}
      {...(hint && { tooltip: hint })}
    >
      {disabled ? (
        <StyledInput
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <SelectStyled
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          onSelect={(id: any) => onSelect(id, name)}
          onDeselect={onDeselect}
          onChange={onSelectChange}
          getPopupContainer={(trigger) => trigger?.parentNode?.parentNode}
          onClear={onClear}
          mode={mode}
          className="ant-styled-select"
          allowClear
          {...(() => {
            if (showSearch) {
              return {
                showSearch: true,
                optionFilterProp: 'children',
                filterOption: (input, option) => {
                  if (typeof option?.children === 'string') {
                    return (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }

                  return (option!.value as unknown as string)
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase());
                },
              };
            }

            return {};
          })()}
        >
          {selectOptions?.map((option, i) => (
            <Option key={option.id} value={option.id} style={styles}>
              {option.label}
            </Option>
          ))}
        </SelectStyled>
      )}
    </FormItemStyled>
  );
};

SelectComponents.defaultProps = {
  selectOptions: [],
  rules: [],
  disabled: false,
  hidePlaceholder: false,
  onSelect: () => null,
  onDeselect: () => null,
  mode: undefined,
};

export default SelectComponents;
