import React from 'react';

export const TimeIcon = ({ size = 'small' }: { size?: string }) => {
  const width = size === 'small' ? '12px' : '34px';
  const box = size === 'small' ? '12' : '22';

  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${box} ${box}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
