import React, { useCallback, useEffect, useState } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import useHeader from 'ui-v2/hooks/useHeader';
import { useSearchParams } from 'react-router-dom';
import { fetchAssetCategories } from 'redux/assetCategories/actions';
import { useAssetCategoriesData } from 'ui-v2/hooks/useAssetCategoriesData';
import AddAssetCategoryForm from '../AssetCategoryForm';

function AssetCategoriesList() {
  const { columns, take } = useHeader();
  const {
    list: assetCategories,
    pagination,
    loading,
  } = useAssetCategoriesData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const toogleModalVisibility = useCallback(
    () => setModalVisible(!modalVisible),
    [modalVisible]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssetCategories({ pageOptions: { page: 1, take } }));
  }, []);

  const onPageChange = (page: number) => {
    dispatch(
      fetchAssetCategories({
        pageOptions: { page, take },
        filterOptions: {
          filterText: searchTerm,
          filterFields: ['categoryName'],
        },
      })
    );
  };

  const onSearch = (searchTerm: string) => {
    dispatch(
      fetchAssetCategories({
        pageOptions: { page: 1 },
        filterOptions: {
          filterText: searchTerm,
          filterFields: ['categoryName'],
        },
      })
    );
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-asset-category') === 'true') {
      searchParams.delete('add-asset-category');
      setSearchParams(searchParams);
      toogleModalVisibility();
    }
    if (searchParams.get('edit-asset-category')) {
      toogleModalVisibility();
    }
  }, [searchParams]);

  return (
    <>
      <Content position="stretch">
        <DataGrid
          loading={loading}
          columns={columns}
          data={assetCategories || []}
          paginationName="AssetCategories"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
      </Content>
      {modalVisible && (
        <AddAssetCategoryForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-skill-save');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}

export default AssetCategoriesList;
