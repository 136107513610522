import type { ColumnsType } from 'antd/es/table';
import { AssetColumns as AssetsColumns } from 'table-configuration/AssetConfig';
import { CandidatesColums } from 'table-configuration/CandidatesConfig';
import { CertificationColumns } from 'table-configuration/CertificationsConfig';
import { DepartmentsColumns } from 'table-configuration/DepartmentsConfig';
import { EmployeesColums } from 'table-configuration/EmployeesConfig';
import { EvaluationPeriodsConfig } from 'table-configuration/EvaluationPeriodsConfig';
import { InterviewsColumns } from 'table-configuration/InterviewsConfig';
import { IpWhitelistingColumns } from 'table-configuration/IpWhitelistingConfig';
import { OnboardingColumns } from 'table-configuration/OnboardingConfig';
import { OpenPositionsColumns } from 'table-configuration/OpenPositionsConfig';
import { ProjectsColumns } from 'table-configuration/ProjectsConfig';
import { PublicSuperAdminColumns } from 'table-configuration/PublicSuperAdminConfig';
import { SkillsColumns } from 'table-configuration/SkillConfig';
import { TenantAccountColumns } from 'table-configuration/TenantAccountConfig';
import { TenantColumns } from 'table-configuration/tenantConfigs/TenantConfig';
import { TrainingsColumns } from 'table-configuration/TrainingsConfig';
import { UsersColumns } from 'table-configuration/UsersTableConfig';
import { WorkPositionsColumns } from 'table-configuration/WorkPositionsConfig';
import { TrackingHoursColumns } from 'table-configuration/trackingHoursConfigs/TrackingHoursConfig';
import { PayrollRuleColumns } from 'table-configuration/PayrollRuleConfig';
import { BenefitsColums } from 'table-configuration/BenefitsConfig';
import { PayrollDateColumns } from 'table-configuration/PayrollDateConfig';
import { QuestionnairesColumns } from 'table-configuration/QuestionnairesConfig';
import { AssetCategoriesColumns } from 'table-configuration/AssetCategoriesConfig';
import { PurchaseRequestColumns } from 'table-configuration/PurchaseRequestConfig';
import { VendorsColumns } from 'table-configuration/VendorsConfig';
import { VendorCategoriesColumns } from 'table-configuration/VendorCategoriesConfig';
import { PurchaseOrdersColumns } from 'table-configuration/PurchaseOrdersConfig';
import { VendorQuestionnaireColumns } from 'table-configuration/VendorQuestionnaireConfig';
import { InvoicingColumns } from 'table-configuration/InvoicingConfig';
import { IdeaboxColumns } from 'table-configuration/IdeaboxConfig';

export const getTblConfig: any = (entity: string) => {
  switch (entity) {
    case 'vendor-questionnaire':
      return VendorQuestionnaireColumns;
    case 'candidates':
      return CandidatesColums;
    case 'tenant-accounts':
      return TenantAccountColumns;
    case 'users':
      return UsersColumns;
    case 'skills':
      return SkillsColumns;
    case 'employees':
      return EmployeesColums;
    case 'interviews':
      return InterviewsColumns;
    case 'open-positions':
      return OpenPositionsColumns;
    case 'certifications':
      return CertificationColumns;
    case 'trainings':
      return TrainingsColumns;
    case 'departments':
      return DepartmentsColumns;
    case 'projects':
      return ProjectsColumns;
    case 'questionnaires':
      return QuestionnairesColumns;
    case 'ideabox':
      return IdeaboxColumns;
    case 'work-positions':
      return WorkPositionsColumns;
    case 'assets':
      return AssetsColumns;
    case 'vendors':
      return VendorsColumns;
    case 'evaluations':
      return EvaluationPeriodsConfig;
    case 'onboardings':
      return OnboardingColumns;
    case 'tenants':
      return TenantColumns;
    case 'ip-whitelisting':
      return IpWhitelistingColumns;
    case 'tracking':
      return TrackingHoursColumns;
    case 'employee-details':
      return TrackingHoursColumns;
    case 'public-super-admins':
      return PublicSuperAdminColumns;
    case 'payroll-rules':
      return PayrollRuleColumns;
    case 'payroll-report':
      return PayrollDateColumns;
    case 'benefits':
      return BenefitsColums;
    case 'asset-categories':
      return AssetCategoriesColumns;
    case 'purchase-requests':
      return PurchaseRequestColumns;
    case 'vendor-categories':
      return VendorCategoriesColumns;
    case 'purchase-orders':
      return PurchaseOrdersColumns;
    case 'invoicing':
      return InvoicingColumns;
    default:
      return [];
  }
};

export const routesWithFilters = [
  'candidates',
  'interviews',
  'onboardings',
  'open-positions',
  'employees',
  'tenants',
  'tenant-accounts',
  'tracking',
  'open-positions',
  'evaluations',
  'work-positions',
  'trainings',
  'certifications',
  'users',
  'projects',
  'vendors',
  'assets',
  'skills',
  'payroll-rules',
  'payroll-report',
  'invoicing',
];

export interface TblColumnType extends ColumnsType {
  default: boolean;
  order: number;
}
