import React from 'react';

export const FileOutlinedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27px"
    height="36px"
    viewBox="0 0 27 36"
    version="1.1"
  >
    <g id="surface1">
      <path
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: 'rgb(8.235294%,36.862745%,93.72549%)',
          strokeOpacity: '1',
          strokeMiterlimit: '4',
        }}
        d="M 7.333332 1.513021 L 7.333332 4.26604 C 7.333332 4.640323 7.333332 4.826578 7.406249 4.968486 C 7.470485 5.094429 7.572916 5.197313 7.697916 5.261171 C 7.840277 5.332126 8.026041 5.332126 8.399305 5.332126 L 11.152777 5.332126 M 11.333332 6.658967 L 11.333332 11.466108 C 11.333332 12.587183 11.333332 13.145947 11.116318 13.575219 C 10.925346 13.951276 10.618054 14.256378 10.241318 14.447954 C 9.814235 14.666138 9.253471 14.666138 8.13368 14.666138 L 3.866319 14.666138 C 2.746527 14.666138 2.1875 14.666138 1.75868 14.447954 C 1.381944 14.256378 1.076389 13.951276 0.885417 13.575219 C 0.666667 13.145947 0.666667 12.587183 0.666667 11.466108 L 0.666667 4.532118 C 0.666667 3.412817 0.666667 2.854053 0.885417 2.424781 C 1.076389 2.048724 1.381944 1.743622 1.75868 1.552046 C 2.1875 1.332088 2.746527 1.332088 3.866319 1.332088 L 6.00868 1.332088 C 6.496527 1.332088 6.741319 1.332088 6.972221 1.388851 C 7.175346 1.436745 7.371527 1.518342 7.550346 1.628321 C 7.751735 1.750717 7.925346 1.924555 8.270832 2.270456 L 10.397568 4.395531 C 10.743054 4.741433 10.914929 4.91527 11.038193 5.117489 C 11.147568 5.294875 11.229165 5.489998 11.277776 5.693992 C 11.333332 5.924592 11.333332 6.169384 11.333332 6.658967 Z M 11.333332 6.658967 "
        transform="matrix(2.25,0,0,2.202128,0,0.382979)"
      />
    </g>
  </svg>
);
