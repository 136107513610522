import { Reducer } from 'react';
import { Action } from 'redux';
import {
  VendorQuestionnaireActionType,
  VendorQuestionnaireState,
} from './types';

const initialState: VendorQuestionnaireState = {
  questionnaire: {
    list: {
      data: [],
      meta: null,
    },
    selectedQuestionnaire: null,
    loaded: false,
    loading: false,
    errored: false,
    error: null,
  },
  questionnaireCategory: {
    list: {
      data: [],
      meta: null,
    },
    selectedQuestionnaireCategory: null,
    loaded: false,
    loading: false,
    errored: false,
    error: null,
  },
  questionaireScore: {
    list: {
      data: [],
      meta: null,
    },
    selectedQuestionnaireScore: null,
    loaded: false,
    loading: false,
    errored: false,
    error: null,
  },
};

export interface VendorQuestionnaireReducedAction
  extends VendorQuestionnaireState,
    Action {}

const reducer: Reducer<
  VendorQuestionnaireState,
  VendorQuestionnaireReducedAction
> = (state = initialState, action: VendorQuestionnaireReducedAction) => {
  switch (action.type) {
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_START:
      state = {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: true,
          loaded: false,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SUCCESS:
      state = {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          list: {
            data: action?.questionnaire?.list?.data ?? [],
            meta: action?.questionnaire?.list?.meta,
          },
          loading: false,
          loaded: true,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_START:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          loading: true,
          loaded: false,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          selectedQuestionnaireCategory:
            action?.questionnaireCategory?.selectedQuestionnaireCategory,
          loading: false,
          loaded: true,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          loading: false,
          loaded: false,
          errored: true,
          error: action.questionnaireCategory?.error,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_ERRORED:
      state = {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: false,
          loaded: false,
          errored: true,
          error: action.questionnaire.error,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_START:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          loading: true,
          loaded: false,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          list: {
            data: action?.questionnaireCategory?.list?.data ?? [],
            meta: action?.questionnaireCategory?.list?.meta,
          },
          loading: false,
          loaded: true,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          loading: false,
          loaded: false,
          errored: true,
          error: action.questionnaire.error,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SCORE_START:
      state = {
        ...state,
        questionaireScore: {
          ...state.questionaireScore,
          loading: true,
          loaded: false,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SCORE_SUCCESS:
      state = {
        ...state,
        questionaireScore: {
          ...state.questionaireScore,
          list: {
            data: action?.questionaireScore?.list?.data ?? [],
            meta: action?.questionaireScore?.list?.meta,
          },
          loading: false,
          loaded: true,
          errored: false,
          error: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SCORE_ERRORED:
      state = {
        ...state,
        questionaireScore: {
          ...state.questionaireScore,
          loading: false,
          loaded: false,
          errored: true,
          error: action.questionnaire.error,
        },
      };
      break;

    case VendorQuestionnaireActionType.CLEAR_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_STATE:
      state = {
        ...state,
        questionnaireCategory: {
          ...state.questionnaireCategory,
          selectedQuestionnaireCategory: null,
        },
      };
      break;
    case VendorQuestionnaireActionType.CLEAR_VENDOR_QUESTIONNAIRE_CATEGORY_STATE:
      state = {
        ...state,
        questionnaireCategory: {
          ...initialState.questionnaireCategory,
        },
      };
      break;
    case VendorQuestionnaireActionType.CLEAR_VENDOR_QUESTIONNAIRE_STATE:
      state = {
        ...state,
        questionnaire: {
          ...initialState.questionnaire,
        },
      };
      break;
    case VendorQuestionnaireActionType.CLEAR_VENDOR_QUESTIONNAIRE_SCORE_STATE:
      state = {
        ...state,
        questionaireScore: {
          ...initialState.questionaireScore,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as VendorQuestionnaireReducer };
