import { Row, Col } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background: #f9fafb;
  min-height: 100vh;
  padding: 32px;
`;

export const RowItem = styled(Row)`
  width: 100%;
`;

export const Sticky = styled(Col)`
  position: sticky;
  top: 0;
  padding: 15px;
  z-index: 1;
  background: rgb(249, 250, 251);
`;
