/* eslint-disable indent */
import {
  getAllWorkPositions,
  getOneWorkPosition,
  getWorkPosition,
} from 'api/workPositionService';
import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import { WorkPositions } from './types';

export const fetchWorkPositions =
  (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: WorkPositions.FETCH_WORK_POSITIONS_START,
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getAllWorkPositions(queryParams!)
      .then((res) => {
        dispatch({
          type: WorkPositions.FETCH_WORK_POSITIONS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: WorkPositions.FETCH_WORK_POSITIONS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchWorkPositionDetails =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: WorkPositions.FETCH_WORK_POSITIONS_START,
    });
    getWorkPosition(id)
      .then((res) => {
        dispatch({
          type: WorkPositions.FETCH_WORK_POSITION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: WorkPositions.FETCH_WORK_POSITION_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleWorkPosition = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: WorkPositions.FETCH_WORK_POSITION_START,
  });
  getOneWorkPosition(id)
    .then((res) => {
      dispatch({
        type: WorkPositions.FETCH_WORK_POSITION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: WorkPositions.FETCH_WORK_POSITION_ERRORED,
        payload: e,
      });
    });
};

export const clearWorkPositionState = () => (dispatch: Dispatch) => {
  dispatch({
    type: WorkPositions.CLEAR_WORK_POSITION_STATE,
  });
};
