import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Item } from 'ui-v2/components/PersonalInfo';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { useInvoicingData } from 'ui-v2/hooks/useInvoicingData';
import { fetchSingleInvoice } from 'redux/invoicing/action';
import { PurchaseOrderType } from 'types/PurchaseOrder';
import InvoicingModalForm from 'components/NewForms/InvoicingForm';

export default function InvoicingDetails() {
  const { invoicing } = useInvoicingData();
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = searchParams.get('id') as string;

  useEffect(() => {
    dispatch(fetchSingleInvoice(id));
  }, [id]);

  useEffect(() => {
    if (searchParams.get('edit-invoice')) {
      setModalVisible(true);
      searchParams.delete('edit-invoice');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  if (!invoicing) return null;

  return (
    <>
      <Content position="grid">
        <>
          <>
            <CardItem title={t('invoiceDetails')}>
              <Row gutter={[0, 16]}>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('date')}
                      value={convertUTCtoFormatedLocalTime(
                        invoicing?.invoiceDate
                      )}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('dueDate')}
                      value={convertUTCtoFormatedLocalTime(invoicing?.dueDate)}
                    />
                  </Col>
                </Styled.RowItem>

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('paymentTerms')}
                      value={invoicing?.paymentTerms}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item label={t('purchaseOrders')} />
                    {invoicing?.purchaseOrders?.map(
                      (purchaseOrder: PurchaseOrderType) => (
                        <Item
                          label=""
                          value={purchaseOrder?.purchaseOrderNumber}
                        />
                      )
                    )}
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('vendor')}
                      value={invoicing?.vendor?.companyName}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('vendorEmail')}
                      value={invoicing?.vendor?.email}
                    />
                  </Col>
                </Styled.RowItem>
              </Row>
            </CardItem>
          </>
          <></>
        </>
      </Content>

      {modalVisible && (
        <InvoicingModalForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            setSearchParams(searchParams);
          }}
          selectedInvoice={invoicing}
        />
      )}
    </>
  );
}
