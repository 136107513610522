import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CandidateOfferType, { VerifyOfferType } from 'types/CandidateOffer';
import { verifyCanidateOffer } from 'api/openRoutesService';
import OfferCard from './OfferCard';
import OfferButtons from './OfferActions/OfferButtons';
import CustomOfferResult from './OfferActions/CustomOfferResult';
import OfferInformation from './OfferActions/OfferInfo';
import { deconstructOfferData } from './helpers';
import Container from './OfferContainer';

export type ActionStatus = {
  isDeclineView?: boolean;
  declined?: boolean;
  accepted?: boolean;
  errored?: boolean;
  isAlreadyAccepted?: boolean;
};

export default function OfferAccept() {
  const { token } = useParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [offer, setOffer] = useState<CandidateOfferType>();
  const [actionStatus, setActionStatus] = useState<ActionStatus>({
    declined: false,
    accepted: false,
    errored: false,
    isAlreadyAccepted: false,
    isDeclineView: false,
  });

  const {
    candidateName,
    openPositionName,
    employmentTypeName,
    offeredSalary,
    offerValidRange,
  } = deconstructOfferData(offer);

  async function fetchOffer(token: string) {
    isLoading(true);
    const payload: VerifyOfferType = {
      code: token,
    };
    verifyCanidateOffer(payload)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data as CandidateOfferType;
          setOffer(data);
          if (data.isAccepted) {
            setActionStatus({ isAlreadyAccepted: true });
          }
        }
      })
      .catch(() => {
        setActionStatus({
          errored: true,
        });
      })
      .finally(() => {
        isLoading(false);
      });
  }
  useEffect(() => {
    if (token) {
      fetchOffer(token as string);
    }
  }, [token]);

  if (actionStatus.accepted || actionStatus.declined || actionStatus.errored) {
    return (
      <Container>
        <CustomOfferResult actionStatus={actionStatus} />
      </Container>
    );
  }
  return (
    <Container>
      <OfferCard>
        <Skeleton
          active
          loading={loading}
          title={false}
          paragraph={{ rows: 10, width: '100%' }}
        >
          <OfferInformation
            candidateName={candidateName}
            openPositionName={openPositionName}
            employmentTypeName={employmentTypeName}
            offeredSalary={offeredSalary}
            offerValidRange={offerValidRange}
            actionStatus={actionStatus}
          />
          <OfferButtons
            id={offer?.id as string}
            isAccepted={actionStatus.isAlreadyAccepted ?? false}
            setLoading={isLoading}
            setActionStatus={setActionStatus}
            token={token}
          />
        </Skeleton>
      </OfferCard>
    </Container>
  );
}
