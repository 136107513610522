import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { RcFile } from 'antd/lib/upload';
import { RangePickerProps } from 'antd/lib/date-picker';

import {
  appEmployeeSidebarItems,
  appGuestTrackingHoursSidebarItems,
  appSidebarItems,
  AppSidebarItemType,
  appSuperAdminSidebarItems,
} from 'layouts/BasicLayout/AppSidebar/sidebarItems';
import { AUTH_ROLES } from 'types/Auth';
import { AuthUserState } from 'redux/authUser/types';
import { pathnamesForStickyHeader } from 'ui-v2/components/Header/helpers';
import { Benefit } from 'types/Benefit';
import { t } from 'i18next';
import { isEqual } from 'lodash';
import { getDepartmentsByHeadEmployeeId } from 'api/departmentService';
import { message } from 'antd';
import {
  colorsList,
  DEFAULT_USER_DATE_FORMAT,
  EMPLOYEE_ID,
  TENANT_ID,
  USER_DATE_FORMAT,
  USER_ROLE,
} from './constants';

export const isIncludedToErrorMsg = (keyword: string, err: any) =>
  err?.response?.config?.url?.includes(keyword) ?? false;

export const getDateDiffFromNow = (
  selectedDate: moment.Moment | undefined,
  selectedYear?: moment.Moment | undefined
) => {
  if (selectedYear) {
    const year = moment().year() - selectedYear.year();
    return year === 1 ? `${year} year` : `${year} years`;
  }
  const today = moment();
  const diff = today.diff(selectedDate, 'days');
  if (diff < 0) {
    return `${diff * -1} days from now`;
  }
  if (diff === 1) {
    return `${diff} day`;
  }
  if (diff === 0) {
    return '';
  }
  return `${diff} days`;
};

export const disableInvalidBirthDates: RangePickerProps['disabledDate'] = (
  current
) => !current || current.isAfter(moment().subtract(16, 'years'));

export const convertBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

export const formatDate = (d: Date | undefined, format?: string) => {
  if (!d) {
    return '-';
  }
  return moment(d).format(format || 'DD/MM/YYYY');
};

export const isDateRangeValid = (start: moment.Moment, end: moment.Moment) => {
  if (end.diff(start, 'days') <= 0) {
    return false;
  }
  return true;
};

export const isInterviewDatePassed = (interviewDate: Date | string) => {
  const currentDate = moment.utc();
  return moment(interviewDate).diff(moment(currentDate), 'minutes') > 0;
};

export const getValidFileContent = (content: string) => content.split(',')[1];

export const convertDateToUTC = (date: any) => moment.utc(date).format();

export const convertUTCtoLocalTime = (utc: Date | string | undefined) => {
  if (!utc) return null;
  return moment(utc).local();
};

export const convertUTCtoLocalHour = (utc: Date | string | undefined) => {
  if (!utc) return null;
  return moment(utc).local().format('HH:mm');
};

export const convertUTCtoFormatedLocalTime = (
  utc: Date | string | undefined | null
) => {
  if (!utc) return '-';
  return moment(utc).local().format(moment.localeData().longDateFormat('L'));
};

export const convertUTCtoFormatedLocalTimeMonthWithLetters = (
  utc: Date | string | undefined | null
) => {
  if (!utc) return '-';
  return moment(utc).local().format('MMM DD YYYY');
};

export const isSuperAdmin = (authState: AuthUserState) =>
  !localStorage.getItem(TENANT_ID) ||
  authState?.authUser?.isSuperAdmin ||
  false;

export const getUserDateFormat = () =>
  localStorage.getItem(USER_DATE_FORMAT) || DEFAULT_USER_DATE_FORMAT;

export const isAdmin = (authState: AuthUserState) => {
  const roles = authState?.authUser?.roles ?? [];
  if (!roles.length) return false;
  return roles.map((item) => item.name).includes(AUTH_ROLES.AMDIN);
};

export const isHR = (authState: AuthUserState) => {
  const roles = authState?.authUser?.roles ?? [];
  if (!roles.length) return false;
  return roles.map((item) => item.name).includes(AUTH_ROLES.HR);
};

export const getAuthUserRoles = (authUser: AuthUserState): string[] =>
  authUser?.authUser?.roles?.map((role) => role?.name) ?? [];

export const getAllowedTrackingHoursLoggedInUserRole = (
  authState: AuthUserState
) => {
  const roles = authState?.authUser?.roles ?? [];

  if (roles?.map((item) => item?.name).includes(AUTH_ROLES.HR)) {
    return AUTH_ROLES.HR;
  }
  if (roles?.map((item) => item?.name).includes(AUTH_ROLES.AMDIN)) {
    return AUTH_ROLES.AMDIN;
  }
  if (roles?.map((item) => item.name).includes(AUTH_ROLES.EMPLOYEE)) {
    return AUTH_ROLES.EMPLOYEE;
  }
  if (
    roles?.map((item) => item.name).includes(AUTH_ROLES.GUEST_TRACKING_HOURS)
  ) {
    return AUTH_ROLES.GUEST_TRACKING_HOURS;
  }
  return roles?.at(0)?.name;
};

export const isEditor = (authState: AuthUserState) => {
  const roles = authState?.authUser?.roles ?? [];
  if (!roles.length) return false;
  return roles.map((item) => item.name).includes(AUTH_ROLES.EDITOR);
};

export const isHeadDepartment = async (
  employeeId: string
): Promise<boolean> => {
  const data = await getDepartmentsByHeadEmployeeId(employeeId, {
    filterOptions: { filterText: '', filterFields: ['name'] },
    pageOptions: { page: 1 },
  });

  return data?.data?.data?.length !== 0;
};

export const isEmployee = (authState: AuthUserState) => {
  const roles = authState?.authUser?.roles ?? [];
  if (!roles.length) return false;
  return roles.map((item) => item.name).includes(AUTH_ROLES.EMPLOYEE);
};

export const isGuest = (authState: AuthUserState) => {
  const roles = authState?.authUser?.roles ?? [];
  if (!roles.length) return false;
  return roles.map((item) => item.name).includes(AUTH_ROLES.GUEST);
};

export const isGuestTrackingHours = (authState: AuthUserState) => {
  const roles = authState?.authUser?.roles ?? [];
  if (!roles.length) return false;
  return roles
    .map((item) => item.name)
    .includes(AUTH_ROLES.GUEST_TRACKING_HOURS);
};

export const isEmployeeOnboardingProcessComplete = (
  authService: AuthUserState,
  isEmployeeUser: boolean
) => {
  if (authService?.authUser?.employee?.id && isEmployeeUser) {
    return authService?.authUser?.employee.isOnBoardingCompleted;
  }
  return true;
};

export const isEmployeeUserPasswordSet = (
  authService: AuthUserState,
  isEmployeeUser: boolean
) => {
  if (authService?.authUser?.id && isEmployeeUser) {
    return authService.authUser?.isPasswordSet;
  }
  return true;
};

export const getPathnamesWithStickyHeader = (pathname: string) =>
  pathnamesForStickyHeader.includes(pathname);

export const getAppRoutes = (authState: AuthUserState) => {
  const userRole = localStorage.getItem(USER_ROLE);

  let sideBarItem: AppSidebarItemType[] = [];
  if (
    isAdmin(authState) ||
    isEditor(authState) ||
    isGuest(authState) ||
    isHR(authState) ||
    userRole === AUTH_ROLES.HR ||
    userRole === AUTH_ROLES.AMDIN ||
    userRole === AUTH_ROLES.EDITOR ||
    userRole === AUTH_ROLES.GUEST
  ) {
    sideBarItem = appSidebarItems;
  }
  if (isEmployee(authState) || userRole === AUTH_ROLES.EMPLOYEE) {
    sideBarItem = appEmployeeSidebarItems;
  }
  if (isSuperAdmin(authState)) {
    sideBarItem = appSuperAdminSidebarItems;
  }

  if (isGuestTrackingHours(authState)) {
    sideBarItem = appGuestTrackingHoursSidebarItems;
  }
  return {
    items: sideBarItem,
    userRole,
  };
};

export const getUserId = (authState: AuthUserState) => authState?.authUser?.id;

export const getUserEmployeeId = (authState: AuthUserState) =>
  authState?.authUser?.employee?.id ||
  (localStorage.getItem(EMPLOYEE_ID) as string);

export const getLoggedInUserId = (authState: AuthUserState) =>
  authState?.authUser?.id;

export const isOfUrlValid = (link: string) => {
  let url;
  try {
    url = new URL(link);
  } catch (_) {
    return false;
  }
  return url.protocol === 'https:';
};

export const isValidEmail = (email: any) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const isValidPhoneNumber = (phoneNumber: string) =>
  /^\d+$/.test(phoneNumber);

export const documentSizeInKb = () => {
  const url = ".pdf,'data:application/pdf;base64,'";
  const urlLength = url.length - 'data:application/pdf;base64,'.length;
  const urlSizeInBytes = Math.ceil(urlLength / 4) * 3;
  const urlSizeInKb = `${urlSizeInBytes} kB`;
  return urlSizeInKb;
};

export const getTime = (d: Date | undefined) => {
  const date = convertUTCtoLocalTime(d);
  if (!date) return null;
  const hours = date.get('hours');
  const minutes = date.get('minutes');
  return moment(`${hours}:${minutes}`, 'hh:mm');
};

export function groupByTable(data: Array<any>, fieldToGroupBy: string) {
  return data.reduce((acc: any[], current: any, index) => {
    const isField = acc.find(
      (item: any) => item[fieldToGroupBy] === current[fieldToGroupBy]
    );
    if (!isField) {
      return acc.concat({
        [fieldToGroupBy]: current[fieldToGroupBy],
        key: index,
      });
    }
    return acc;
  }, []);
}
export const convertToKB = (value: string): string => {
  const splitValue = value?.split(' ');
  if (!splitValue) return '0 kB';
  const num = Number(splitValue[0]);

  if (splitValue[1] === 'MB') {
    return `${Math.ceil(num * 1000)} kB`;
  }
  return `${num} kB`;
};

export const getDisabledHours = (selectedHour: number) => {
  const hours = [];
  for (let i = 0; i < selectedHour; i += 1) {
    hours.push(i);
  }
  return hours;
};

export const getDisabledMinutes = (
  selectedHour: number,
  endTimeSelectedHour: number,
  selectedMinutes: number
) => {
  const minutes = [];
  if (selectedHour === endTimeSelectedHour) {
    for (let i = 0; i < selectedMinutes; i += 1) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const isDuplicateEmailError = (error: any) => {
  if (error?.response?.status === 409) return true;
  if (
    error?.response?.status === 500 &&
    error?.response?.data?.includes('This email is already in use ')
  ) {
    return true;
  }

  return false;
};

export const toastErrorMessages = (error: any) => {
  const errorMessages: string[] | string | undefined =
    error?.response?.data?.error ?? error;
  if (typeof errorMessages === 'string') {
    toast.error(errorMessages);
  } else if (typeof errorMessages === 'object') {
    errorMessages?.forEach((msg) => toast.error(msg));
  } else {
    toast.error('Something went wrong!');
  }
};

export const getErrorMessageString = (error: any) => {
  let errorString = '';

  const errorMessages: string[] | string | undefined =
    error?.response?.data?.error ?? error;
  if (typeof errorMessages === 'string') {
    errorString = errorMessages;
  } else if (Array.isArray(errorMessages)) {
    errorMessages?.forEach((msg) => {
      errorString = msg;
    });
  } else {
    errorString = 'Something went wrong!';
  }

  return errorString;
};

export const isAppSidebarContainingPath = (path: string): boolean => {
  const foundAppSidebarPath: string | undefined = appSidebarItems
    ?.find((el) => el?.path?.split('/')?.at(1) === path)
    ?.path?.split('/')
    ?.at(1);

  if (!foundAppSidebarPath) {
    return false;
  }
  return true;
};

export const disableDatesExcludingCurrent = (current: moment.Moment): boolean =>
  current && current < moment().subtract(1, 'days').endOf('day');

export const convertDateTimeToUTC = (date: moment.Moment) =>
  date
    .hours(date.get('hour'))
    .minutes(date.get('minute'))
    .seconds(date.get('second'));

export function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * colorsList.length);
  const chosenColor = colorsList[randomIndex];
  colorsList.splice(randomIndex, 1);
  return chosenColor;
}

export function editBenefitSuccessMessage(
  selectedBenefit: Benefit,
  authState: AuthUserState
) {
  let actionMessage = '';
  if (selectedBenefit) {
    if (isAdmin(authState) || isHR(authState)) {
      actionMessage = 'edited';
    } else {
      actionMessage = 'sent an upgrade request for';
    }
  }
  const message = `Successfully ${actionMessage} benefit template`;
  toast.success(message);
}

export const validateFileSize = (file: RcFile) => {
  const fileSizeInMB = file.size / (1024 * 1024);
  if (
    fileSizeInMB > Number(process.env.REACT_APP_DEFAULT_UPLOAD_FILE_SIZE_LIMIT)
  ) {
    toast.warning(
      `${t('fileSizeCannotExceed')} ${
        process.env.REACT_APP_DEFAULT_UPLOAD_FILE_SIZE_LIMIT
      }MB!`
    );
    return false;
  }

  return true;
};
const MAX_DIMENSION = 500;

export const checkImageParameters = async (files: any[]) => {
  const file = await Promise.all(
    files.map(async (file) => {
      if (!validateFileSize(file)) {
        return null;
      }
      if (file.type.startsWith('image/')) {
        const blob = await new Response(file.originFileObj).blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
              if (
                image.width <= MAX_DIMENSION &&
                image.height <= MAX_DIMENSION
              ) {
                message.success(t('documentUploadedSuccessfully'));
                resolve(file);
              } else {
                message.info(
                  `Image dimensions should be ${MAX_DIMENSION}x${MAX_DIMENSION} pixels`
                );
                reject(Error);
              }
            };
          };
        });
      }
      return file;
    })
  );
  return file;
};

export const getTimeDifference = (
  startDate: Moment | null,
  endDate: Moment | null,
  t: (key: string) => string
): string => {
  if (startDate && endDate) {
    const days = endDate.diff(startDate, 'days');
    const months = endDate.diff(startDate, 'months');
    const years = endDate.diff(startDate, 'years');

    if (years > 0) {
      return `${years} ${years === 1 ? t('year') : t('years')}`;
    }
    if (months > 0) {
      return `${months} ${months === 1 ? t('month') : t('months')}`;
    }
    if (days > 0) {
      return `${days} ${days === 1 ? t('day') : t('days')}`;
    }
    return t('lessThanADay');
  }
  return '';
};

type DateObject = {
  date: string;
};
export const formatStartAndEndDate = (dates: DateObject[]): string => {
  if (!dates || dates.length === 0) return '';

  const startDate = moment(dates[0].date).format('DD/MM/YYYY');
  const endDate = moment(dates[dates.length - 1].date).format('DD/MM/YYYY');

  return `${startDate} - ${endDate}`;
};

export function checkEquality<T>(obj1: T, obj2: T): boolean {
  return isEqual(obj1, obj2);
}
