import { Col, Form, Row } from 'antd';
import { createBonus, updateBonus } from 'api/bonusService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CreateBonus } from 'types/Bonus';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeId: string;
  fetchEmployeeBonuses: (id: string) => void;
  id?: string;
  selectedBonus: any;
}

export default function AddBonusModal({
  open,
  closeModal,
  fetchEmployeeBonuses,
  employeeId,
  id,
  selectedBonus,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'value',
          label: t('value'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'number',
          },
          defaultValue: selectedBonus?.value,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'currencyId',
          label: t('currency'),
          type: InputTypes.SELECT,
          selectOptions: currencyOptions,
          defaultValue: currencyOptions[0]?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'date',
          label: t('date'),
          type: 'datepicker',
          defaultValue: moment(selectedBonus?.date),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'label',
          label: t('label'),
          type: 'input',
          defaultValue: selectedBonus?.label,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [currencyOptions, selectedBonus]
  );

  const onSubmit = async () => {
    if (loading || !employeeId) return;

    setLoading(true);
    const formValues: CreateBonus = form.getFieldsValue(true);

    if (id) {
      updateBonus(id, {
        ...formValues,
        value: Number(formValues.value),
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            fetchEmployeeBonuses(employeeId);
            toast.success(t('successfullyUpdatedBonus'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await createBonus({
        ...formValues,
        employeeId,
        value: Number(formValues.value),
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            fetchEmployeeBonuses(employeeId);
            toast.success(t('successfullyAddedBonus'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <GenericModal
      title={id ? t('editBonus') : t('addBonus')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onSubmit}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
