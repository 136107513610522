import styled from 'styled-components';
import { Avatar, Row } from 'antd';

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledAvatar = styled(Avatar)`
  background: #ccc;
  text-transform: capitalize;
`;
export const StyledCalendarHeader = styled(Row)`
  display: flex;
  justify-content: flex-end;
`;
