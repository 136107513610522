import React from 'react';

export const ViewFeedbackIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1E0FF" />
    <path
      d="M23 24.5H28M23 28H31M25.6837 34H32.2C33.8802 34 34.7202 34 35.362 33.673C35.9265 33.3854 36.3854 32.9265 36.673 32.362C37 31.7202 37 30.8802 37 29.2V23.8C37 22.1198 37 21.2798 36.673 20.638C36.3854 20.0735 35.9265 19.6146 35.362 19.327C34.7202 19 33.8802 19 32.2 19H23.8C22.1198 19 21.2798 19 20.638 19.327C20.0735 19.6146 19.6146 20.0735 19.327 20.638C19 21.2798 19 22.1198 19 23.8V36.3355C19 36.8684 19 37.1348 19.1092 37.2716C19.2042 37.3906 19.3483 37.4599 19.5005 37.4597C19.6756 37.4595 19.8837 37.2931 20.2998 36.9602L22.6852 35.0518C23.1725 34.662 23.4162 34.4671 23.6875 34.3285C23.9282 34.2055 24.1844 34.1156 24.4492 34.0613C24.7477 34 25.0597 34 25.6837 34Z"
      stroke="#155EEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#EFF4FF"
      strokeWidth="8"
    />
  </svg>
);
