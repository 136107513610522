import { deleteOpenPosition } from 'api/openPositions';
import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchOpenPositions } from 'redux/openPositions/actions';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { useOpenPositionsData } from 'ui-v2/hooks/useOpenPositionsData';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function OpenPositionsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useOpenPositionsData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const dispatch = useDispatch();

  async function removeOpenPosition(id: string | null) {
    if (!id) return;
    try {
      await deleteOpenPosition(id)
        .then(() => {
          toast.success('Open Position deleted successfully!');
          let currentPage = pagination?.page;
          if (list.length === 1) {
            currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
          }
          dispatch(fetchOpenPositions({ page: currentPage, take }));
        })
        .catch(() => toast.error('Failed to delete open position!'));
    } catch (error) {
      toastErrorMessages(error);
    }
  }

  useEffect(() => {
    if (!loading) {
      dispatch(fetchOpenPositions({ page: 1, take }));
    }
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchOpenPositions(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchOpenPositions(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('delete-open-position')) {
      removeOpenPosition(searchParams.get('delete-open-position'));
      searchParams.delete('delete-open-position');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await removeOpenPosition(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <DataGrid
        loading={loading}
        columns={columns}
        data={list}
        paginationName="Open Positions"
        rowSelectionType="checkbox"
        totalItems={pagination.itemCount}
        currentPage={pagination.page}
        totalPages={pagination.pageCount}
        onPageChange={onPageChange}
        onSelectedDelete={onDelete}
        showSearch
        onSearch={onSearch}
      />
    </Content>
  );
}
