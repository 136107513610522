import React from 'react';

export const AddTrainingIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1E0FF" />
    <path
      d="M21 25.9996V32.0107C21 32.3697 21 32.5492 21.0547 32.7076C21.103 32.8477 21.1819 32.9754 21.2856 33.0813C21.4029 33.201 21.5634 33.2813 21.8845 33.4418L27.2845 36.1418C27.5468 36.273 27.678 36.3386 27.8156 36.3644C27.9375 36.3873 28.0625 36.3873 28.1844 36.3644C28.322 36.3386 28.4532 36.273 28.7155 36.1418L34.1155 33.4418C34.4366 33.2813 34.5971 33.201 34.7144 33.0813C34.8181 32.9754 34.897 32.8477 34.9453 32.7076C35 32.5492 35 32.3697 35 32.0107V25.9996M18 24.4996L27.6422 19.6785C27.7734 19.6129 27.839 19.5801 27.9078 19.5672C27.9687 19.5557 28.0313 19.5557 28.0922 19.5672C28.161 19.5801 28.2266 19.6129 28.3578 19.6785L38 24.4996L28.3578 29.3207C28.2266 29.3863 28.161 29.4191 28.0922 29.432C28.0313 29.4434 27.9687 29.4434 27.9078 29.432C27.839 29.4191 27.7734 29.3863 27.6422 29.3207L18 24.4996Z"
      stroke="#155EEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#EFF4FF"
      strokeWidth="8"
    />
  </svg>
);
