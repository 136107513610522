import { useEffect, useState, useCallback } from 'react';
import { OptionType } from 'types/OptionTypes';
import { getWorkPositionSkim } from 'api/workPositionService';
import { toastErrorMessages } from 'utils/utilFunctions';

export function useWorkPositionsSkimData() {
  const [workPositions, setWorkPositions] = useState<OptionType[]>([]);
  const [filteredWorkPositions, setFilteredWorkPositions] = useState<
    OptionType[]
  >([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (!isDataLoaded) {
      getWorkPositionSkim()
        .then((response) => {
          if (response?.data) {
            setWorkPositions(response.data);
            setFilteredWorkPositions(response.data);
            setIsDataLoaded(true);
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    }
  }, [isDataLoaded, workPositions]);

  const getFilteredWorkPositionSkimOptions = useCallback(
    (textFilter: string) => {
      const filter = textFilter.trim().toLowerCase();
      if (!filter.length) {
        setFilteredWorkPositions(workPositions);
      } else {
        const filteredData = workPositions.filter((item) =>
          item.value.toLowerCase().includes(filter)
        );
        setFilteredWorkPositions(filteredData);
      }
    },
    [workPositions]
  );

  return {
    filteredWorkPositions,
    getFilteredWorkPositionSkimOptions,
  };
}
