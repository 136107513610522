import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchDepartmentsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { fetchWorkPositions } from 'redux/workPositions/actions';
import { getFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from 'api/constants';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function WorkPositonFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function onFormChange() {
    const department: string = form.getFieldValue('departmentId')?.value ?? '';
    const params = {
      filterOptions: {
        filterText: department,
        filterFields: getFields(apiURLs.workPosition),
      },
      pageOptionsDto: { page: 1, take: 10 },
    };
    dispatch(fetchWorkPositions(params));
  }

  const WorkPositionFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'departmentId',
          label: t('department'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchDepartmentsOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={WorkPositionFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchWorkPositions());
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
