import {
  Button,
  Card,
  Form,
  DatePicker,
  InputNumber,
  AutoComplete,
  Dropdown,
} from 'antd';
import Input from 'antd/lib/input/Input';
import { TableBtn } from 'components/common/DataGrid/DataGridStyles';
import Styled from 'styled-components';

export const StyledContentWrapper = Styled.div`
  background-color: #F9F9FB;
  min-height: 100vh;
`;

export const StyledArrowButton = Styled(TableBtn)`
  svg {
    margin: 0;
    order: 2;
    margin-left: 12px;
  }
`;

export const StyledAuthWrap = Styled('div')`
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`;

export const StyledAppLogo = Styled('div')`
  display: content;
  & img {
    height: 56px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledAuthCard = Styled(Card)`
max-width: 500px;
min-height: 999px;
width: 100%;
overflow: hidden;
box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
position: relative;
border: 20 none;
display: flex;
flex-direction: column;
border-radius: 5px;


@media screen and (min-width: 576px) {
    min-height: auto;
    
  }

  & .ant-card-body {
    padding: 10;
    display: flex;
    flex: 1;
    
  }
`;

export const StyledAuthMainContent = Styled('div')`
padding: 24px;
display: flex;
flex-direction: column;
width: 100%;
`;

export const StyledAuthCardHeader = Styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  & img {
    cursor: pointer;
    height: 36px;
    margin-right: 10px;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

export const StyledSignBtn = Styled(Button)`
border-radius: 5px;
width: 10rem;
font-weight: 400;
font-size: 14px;
text-transform: uppercase;
line-height: 1;
`;

export const StyledFormVertical = Styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  & .ant-form-item-label {
    text-align: left;
  }


  & .title {
    margin-bottom: 1.5rem;
  } 

  & .bottom-margin {
    margin-bottom: 1rem;
  }

  & .text-editor {
    margin: 0;
    max-width: 100% !important;
  }

  & .top-margin {
    margin-top: 1.5rem;
  }

  & .datepicker-full-width {
    width: 100%;
    border-radius: 5px;
  }

  & .align-text-center {
    text-align: center;
  }
`;

export const StyledForm = Styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  
`;

export const StyledFormFieldAction = Styled('div')`
    font-size: 14px;
    color: rgb(107, 114, 128);
    margin-top: auto;
    

    & span {
      margin-right: 8px;
      display: inline-block;
      

      [dir=rtl] & {
        margin-right: 0;
        margin-left: 8px;
      }
    }
`;

export const StyledInput = Styled(Input)`
border-radius: 5px
`;
export const DatePickerStyle = Styled(DatePicker)`
border-radius: 5px
`;
export const InputNumberStyle = Styled(InputNumber)`
border-radius: 5px
`;
export const AutoCompleteStyle = Styled(AutoComplete)`
border-radius: 5px
`;
export const DropdownStyle = Styled(Dropdown)`
border-radius: 5px
`;
