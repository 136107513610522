import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space } from 'antd';
import { StyledInput } from 'components/styledComponents/StyledLogin';
import { JobSubCategoryType } from 'types/JobCategory';
import { StyledRate } from 'components/NewForms/FormStyled';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface CandidateDynamicFormProps {
  categoryName: string;
  availableSkills: JobSubCategoryType[] | undefined;
  defaultSkills?: any[];
  onEditedSkillChange?: (index: number, field: any, deletedSkill?: any) => void;
}

const CandidateDynamicForm: React.FC<CandidateDynamicFormProps> = ({
  categoryName,
  availableSkills,
  defaultSkills,
  onEditedSkillChange,
}) => {
  const [defaultSkillsList, setDefaultSkillsList] = useState([]);

  useEffect(() => {
    setDefaultSkillsList(defaultSkills as any);
  }, [defaultSkills]);

  const { t } = useTranslation();

  return (
    <div>
      {defaultSkillsList.map((skill: any) => {
        if (
          categoryName === 'skills-matrix-other-skills' ||
          !availableSkills?.map((i) => i.id).includes(skill.skillSubCategoryId)
        ) {
          return null;
        }

        return (
          <Space
            key={skill.skillSubCategoryId}
            style={{
              alignItems: 'baseline',
              width: '100%',
              marginBottom: '25px',
            }}
          >
            <Select
              style={{ width: 180 }}
              placeholder={t('Technology')}
              defaultValue={skill.skillSubCategoryId}
              onSelect={(value: any) =>
                onEditedSkillChange &&
                onEditedSkillChange(
                  skill.index,
                  availableSkills.find((i) => i.id === value)
                )
              }
            >
              {availableSkills?.map((skill) => (
                <Option key={skill.id} value={skill.id}>
                  {skill.subCategoryName}
                </Option>
              ))}
            </Select>
            <StyledInput
              size="middle"
              type="number"
              style={{ width: 80 }}
              placeholder={t('years')}
              defaultValue={skill.yearsOfExperience}
              onChange={(e) =>
                onEditedSkillChange &&
                onEditedSkillChange(skill.index, e.target.value)
              }
              onWheel={(e: any) => e.target.blur()}
            />
            <StyledRate
              defaultValue={Number(skill.score)}
              onChange={(score) =>
                onEditedSkillChange && onEditedSkillChange(skill.index, score)
              }
            />

            <MinusCircleOutlined
              onClick={() => {
                setDefaultSkillsList(
                  defaultSkillsList?.filter((_, index) => index !== skill.index)
                );
                if (onEditedSkillChange) {
                  onEditedSkillChange(skill.index, undefined, skill);
                }
              }}
            />
          </Space>
        );
      })}

      <Form.List name={categoryName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space style={{ alignItems: 'baseline', width: '100%' }}>
                <Form.Item
                  name={[index, 'skillSubCategoryId']}
                  rules={[
                    { required: true, message: t('Please select a skill') },
                  ]}
                >
                  <Select style={{ width: 180 }} placeholder={t('Technology')}>
                    {availableSkills?.map((skill) => (
                      <Option key={skill.id} value={skill.id}>
                        {skill.subCategoryName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[index, 'yearsOfExperience']}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter years of experience'),
                    },
                    {
                      message: t('Minimum of 3 months (0.3) required'),
                      validator: (_, value) => {
                        if (value >= 0.3) {
                          return Promise.resolve();
                        }
                        toast.warning(t('Please enter years of experience'));
                        window.scrollTo(0, document.body.scrollHeight);
                        return Promise.reject();
                      },
                    },
                  ]}
                >
                  <StyledInput
                    size="middle"
                    type="number"
                    style={{ width: 80 }}
                    placeholder="Years"
                    onWheel={(e: any) => e.target.blur()}
                  />
                </Form.Item>

                <Form.Item
                  name={[index, 'score']}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a score!'),
                    },
                    {
                      message: t('Minimum score of 1 required'),
                      validator: (_, value) => {
                        if (value >= 1) {
                          return Promise.resolve();
                        }
                        toast.warning(t('Skill score is required!'));
                        window.scrollTo(0, document.body.scrollHeight);
                        return Promise.reject();
                      },
                    },
                  ]}
                >
                  <StyledRate />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(index)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                className="ant-btn.ant-btn-block "
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {t('addSkills')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};
export default CandidateDynamicForm;
