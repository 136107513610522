/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Row, Space, Typography, Image, Popover } from 'antd';
import { TenantDataType } from 'types/Tenant';
import { Item } from 'ui-v2/components/PersonalInfo';
import { ItemValue } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import { FallBackImage } from '../utils';

type IProps = {
  tenant?: TenantDataType;
};

export function TenantLogo({
  tenant,
  logoContent,
}: {
  tenant?: TenantDataType;
  logoContent?: string;
}) {
  return (
    <Space size="middle" align="baseline">
      <Image
        src={tenant?.logoContent || logoContent || FallBackImage}
        width={50}
        height={50}
        fallback={FallBackImage}
      />
      <Typography.Title level={5}>{tenant?.name ?? ''}</Typography.Title>
    </Space>
  );
}

export default function TenantInfo({ tenant }: IProps) {
  if (!tenant?.id) return null;

  const { t } = useTranslation();

  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <Row justify="center" gutter={[0, 20]}>
          <Col span={11}>
            <Item
              label={t('name')}
              value={tenant?.name ?? ''}
              children={undefined}
            />
          </Col>
          <Col span={11} offset={1}>
            <Item
              label={t('email')}
              children={
                <ItemValue ellipsis>
                  <Popover content={tenant?.email ?? ''} placement="topLeft">
                    {tenant?.email ?? ''}
                  </Popover>
                </ItemValue>
              }
            />
          </Col>
          <Col span={11}>
            <Item
              label={t('website')}
              children={
                <Typography.Link
                  ellipsis
                  href={tenant?.website ?? '#'}
                  target="_blank"
                >
                  {tenant?.website}
                </Typography.Link>
              }
            />
          </Col>
          <Col span={11} offset={1}>
            <Item
              label={t('sector')}
              value={tenant?.sector ?? ''}
              children={undefined}
            />
          </Col>

          <Col span={11}>
            <Item
              label={t('country')}
              value={tenant?.country ?? ''}
              children={undefined}
            />
          </Col>
          <Col span={11} offset={1}>
            <Item
              label={t('city')}
              value={tenant?.city ?? ''}
              children={undefined}
            />
          </Col>
          <Col span={11}>
            <Item
              label={t('phoneNumber')}
              value={tenant?.phoneNumber ?? ''}
              children={undefined}
            />
          </Col>
          <Col span={11} offset={1}>
            <Item
              label={t('address')}
              value={tenant?.address ?? ''}
              children={undefined}
            />
          </Col>
          <Col span={11}>
            <Item
              label={t('description')}
              children={
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: 'show more',
                  }}
                >
                  {tenant?.description ?? ''}
                </Typography.Paragraph>
              }
            />
          </Col>
          <Col span={11} offset={1}>
            <Item
              label={t('status')}
              children={
                <Typography.Paragraph>
                  {tenant?.approved ? t('approved') : t('Pending approval')}
                </Typography.Paragraph>
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
