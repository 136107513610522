import React from 'react';

export const AssignTaskIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <path
      d="M32 24V21.2C32 20.0799 32 19.5198 31.782 19.092C31.5903 18.7157 31.2843 18.4097 30.908 18.218C30.4802 18 29.9201 18 28.8 18H21.2C20.0799 18 19.5198 18 19.092 18.218C18.7157 18.4097 18.4097 18.7157 18.218 19.092C18 19.5198 18 20.0799 18 21.2V28.8C18 29.9201 18 30.4802 18.218 30.908C18.4097 31.2843 18.7157 31.5903 19.092 31.782C19.5198 32 20.0799 32 21.2 32H24M28 31L30 33L34.5 28.5M27.2 38H34.8C35.9201 38 36.4802 38 36.908 37.782C37.2843 37.5903 37.5903 37.2843 37.782 36.908C38 36.4802 38 35.9201 38 34.8V27.2C38 26.0799 38 25.5198 37.782 25.092C37.5903 24.7157 37.2843 24.4097 36.908 24.218C36.4802 24 35.9201 24 34.8 24H27.2C26.0799 24 25.5198 24 25.092 24.218C24.7157 24.4097 24.4097 24.7157 24.218 25.092C24 25.5198 24 26.0799 24 27.2V34.8C24 35.9201 24 36.4802 24.218 36.908C24.4097 37.2843 24.7157 37.5903 25.092 37.782C25.5198 38 26.0799 38 27.2 38Z"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#ECFDF3"
      strokeWidth="8"
    />
  </svg>
);
