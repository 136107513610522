import axios from 'axios';
import { ACCESS_TOKEN, TENANT_ID } from 'utils/constants';
import { logOutAndRedirect } from 'api/authService';
import { isIncludedToErrorMsg } from 'utils/utilFunctions';
import AppLayer from 'layouts/AppLayer';

const agent = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
});

agent.interceptors.request.use(
  (req) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const X_TENANT_ID = localStorage.getItem(TENANT_ID);
    const recaptcha = localStorage.getItem('_grecaptcha');

    let reqHeaders = {};

    if (recaptcha) {
      reqHeaders = {
        Recaptcha: recaptcha,
      };
    }

    if (accessToken) {
      reqHeaders = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    if (req.url !== '/auth/login') {
      reqHeaders = {
        ...reqHeaders,
        'x-tenant-id': X_TENANT_ID,
      };
    }

    req.headers = {
      ...reqHeaders,
    };
    return req;
  },
  (err) => Promise.reject(err)
);

agent.interceptors.response.use(
  (res) => res,
  (err) => {
    const {
      response: { status },
    } = err;
    if (window.location.pathname.includes('set-password')) {
      return Promise.resolve();
    }

    if (status === 401) {
      if (window.location.pathname !== '/login') {
        logOutAndRedirect();
      }
    }
    if (status === 500) {
      if (
        !isIncludedToErrorMsg('resume', err) &&
        !isIncludedToErrorMsg('login', err)
      ) {
        AppLayer.throwError();
      }
    }

    return Promise.reject(err);
  }
);

export default agent;
