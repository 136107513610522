import { Reducer } from 'react';
import { Users, UsersState, UserState } from './types';

const initialState: UsersState = {
  data: {
    data: [],
    meta: null,
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<UsersState, any> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Users.FETCH_USERS_START:
      state = { ...state, loading: true };
      break;
    case Users.FETCH_USERS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Users.FETCH_USERS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleState: UserState = {
  data: {},
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducerSingleUser: Reducer<UserState, any> = (
  state = initialSingleState,
  action: any
) => {
  switch (action.type) {
    case Users.FETCH_USER_START:
      state = { ...state, loading: true };
      break;
    case Users.FETCH_USER_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Users.FETCH_USER_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Users.CLEAR_USER_DATA:
      state = { ...state, loading: false, loaded: false, data: {} };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as UsersReducer, reducerSingleUser as UserReducer };
