import React, { useCallback, useState } from 'react';
import { Col, Empty, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import CardItem from 'ui-v2/components/Card';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { ProjectType } from 'types/Project';
import { StyledButton } from 'components/NewForms/FormStyled';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import ProjectEmployee from './ProjectEmployee';
import AssignEmployeeModal from './AssignEmployeeModal';
import { StyledProjectEmployeesRow } from './StyledProjectEmployees';

const ProjectEmployees: React.FC = () => {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const {
    project: { data, loading },
  } = useProjectsData();

  const toogleModalVisibility = useCallback(() => {
    setModalVisible((modalVisible) => !modalVisible);
  }, [modalVisible]);

  if (loading) {
    return (
      <Row justify="center">
        <Col>
          <Spin spinning />
        </Col>
      </Row>
    );
  }

  const projectData = data as ProjectType;

  return (
    <>
      <CardItem
        title={t('associatedEmployees')}
        rightButton={
          authUserRole === AUTH_ROLES.EMPLOYEE ? (
            <StyledButton />
          ) : (
            <StyledButton onClick={toogleModalVisibility} type="link">
              {t('assignEmployee')}
            </StyledButton>
          )
        }
      >
        {!projectData?.employees?.length ? (
          <Row justify="center">
            <Col>
              <Empty
                description={t(
                  'there are no employees assigned to this project yet'
                )}
              />
            </Col>
          </Row>
        ) : (
          <StyledProjectEmployeesRow gutter={[0, 10]}>
            {projectData?.employees?.map((employee, index) => (
              <ProjectEmployee
                key={employee?.id}
                employee={employee}
                isLast={index + 1 === projectData?.employees?.length}
              />
            ))}
          </StyledProjectEmployeesRow>
        )}
      </CardItem>
      {modalVisible && (
        <AssignEmployeeModal
          open={modalVisible}
          closeModal={toogleModalVisibility}
        />
      )}
    </>
  );
};

export default ProjectEmployees;
