import React from 'react';
import { Avatar, Col, List, Row, Skeleton } from 'antd';

import { TimeOffRequest } from 'types/TimeOff';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import * as Styled from './TimeOffRequestListStyles';

interface IProps {
  requests: any;
  loading: boolean;
  onRequest: (request: TimeOffRequest) => void;
}

export default function TimeOffRequestList({
  requests,
  loading,
  onRequest,
}: IProps) {
  const onItemClick = (request: TimeOffRequest) => {
    onRequest(request);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { t } = useTranslation();

  return (
    <CardItem title={t('requests')} wrapContentHeight>
      <List
        loading={loading}
        itemLayout="horizontal"
        // loadMore={loadMore}
        style={{ width: '100%' }}
        dataSource={requests}
        renderItem={(request: TimeOffRequest) => (
          <Styled.Item
            onClick={() => onItemClick(request)}
            actions={[
              <Row justify="center">
                <Styled.Status color={request.statusColor}>
                  {t(request.status)}
                </Styled.Status>
              </Row>,
            ]}
          >
            <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
                avatar={
                  <Avatar>
                    {request.employee?.firstName?.trim().charAt(0)}
                  </Avatar>
                }
                description={
                  <Row justify="end">
                    <Col span={12}>
                      <Styled.Description ellipsis>
                        {`${request.employee?.firstName} ${request.employee?.lastName}`}
                      </Styled.Description>
                    </Col>
                    <Col span={12}>
                      <Row justify="center">
                        <Styled.Reason ellipsis>
                          {t(request.reason)}
                        </Styled.Reason>
                      </Row>
                    </Col>
                  </Row>
                }
              />
            </Skeleton>
          </Styled.Item>
        )}
      />
    </CardItem>
  );
}
