import React from 'react';
import { UNAVAILABLE_MESSAGE } from 'utils/constants';

import styles from './index.module.scss';

const EmptyCell = () => (
  <span className={styles.container}>{UNAVAILABLE_MESSAGE}</span>
);

export default EmptyCell;
