/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Row } from 'antd';

import { Item } from 'ui-v2/components/PersonalInfo';
import { StatusTag } from 'ui-v2/components/DataGridComponents';
import { useWorkPositionsData } from 'ui-v2/hooks/useWorkPositionsData';
import { CheckIcon } from 'Icons/CheckIcon';
import { CloseIcon } from 'Icons/CloseIcon';

import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';

export default function WorkPositionInformation() {
  const {
    workPosition: { data },
  } = useWorkPositionsData();
  const { t } = useTranslation();

  if (!data?.id) return null;

  const requirementsArray = data?.requirements
    ? data.requirements.split('; ')
    : [];

  return (
    <CardItem title="Work Position Information">
      <Row gutter={[0, 16]}>
        <Styled.RowItem gutter={[0, 10]}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item label="Name" value={data?.name} children={undefined} />
          </Col>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Row>
              <Col span={24}>
                <Styled.ItemLabel>{t('headDepartment')}</Styled.ItemLabel>
              </Col>
              <StatusTag status={data?.isHeadDepartment ? 'Yes' : 'No'}>
                {data?.isHeadDepartment ? <CheckIcon /> : <CloseIcon />}
                <p>{data?.isHeadDepartment ? 'Yes' : 'No'}</p>
              </StatusTag>
            </Row>
          </Col>
        </Styled.RowItem>
        <Styled.RowItem gutter={[0, 10]} last>
          <Col md={{ span: 28 }} sm={{ span: 24 }}>
            <Item
              label={t('skills')}
              value={
                <ul style={{ fontWeight: 300 }}>
                  {data?.skills?.map((skill: { subCategoryName: string }) => (
                    <li>{skill?.subCategoryName}</li>
                  ))}
                </ul>
              }
              children={undefined}
            />
          </Col>
        </Styled.RowItem>
        <Styled.RowItem gutter={[0, 10]} last>
          <Col md={{ span: 28 }} sm={{ span: 24 }}>
            <Item
              label={t('requirements')}
              value={
                <ul style={{ fontWeight: 300 }}>
                  {requirementsArray.map((requirement: string) => (
                    <li>{requirement.trim()}</li>
                  ))}
                </ul>
              }
              children={undefined}
            />
          </Col>
        </Styled.RowItem>
      </Row>
    </CardItem>
  );
}
