import { Dispatch } from 'redux';
import { QueryParamsType } from 'types/QueryParams';
import { getVendorCategories, getVendorCategory } from 'api/vendorCategory';
import { VendorCategories } from './types';

export const fetchVendorCategories =
  (queryParams?: QueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: VendorCategories.FETCH_VENDOR_CATEGORIES_START,
    });
    getVendorCategories(queryParams)
      .then((res) => {
        dispatch({
          type: VendorCategories.FETCH_VENDOR_CATEGORIES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: VendorCategories.FETCH_VENDOR_CATEGORIES_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleVendorCategory =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: VendorCategories.FETCH_VENDOR_CATEGORY_START,
    });
    getVendorCategory(id)
      .then((res) => {
        dispatch({
          type: VendorCategories.FETCH_VENDOR_CATEGORY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: VendorCategories.FETCH_VENDOR_CATEGORY_ERRORED,
          payload: e,
        });
      });
  };

export const clearVendorCategory = () => (dispatch: Dispatch) => {
  dispatch({
    type: VendorCategories.CLEAR_VENDOR_CATEGORY_STATE,
  });
};
