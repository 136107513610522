import React from 'react';

export const WorkPositionsIcon = () => (
  <svg
    width="20"
    height="19.88"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9.09919L1 11.5992L10.6422 16.4203C10.7734 16.4859 10.839 16.5187 10.9078 16.5316C10.9687 16.543 11.0313 16.543 11.0922 16.5316C11.161 16.5187 11.2266 16.4859 11.3578 16.4203L21 11.5992L16 9.09919M6 14.0992L1 16.5992L10.6422 21.4203C10.7734 21.4859 10.839 21.5187 10.9078 21.5316C10.9687 21.543 11.0313 21.543 11.0922 21.5316C11.161 21.5187 11.2266 21.4859 11.3578 21.4203L21 16.5992L16 14.0992M1 6.59919L10.6422 1.77807C10.7734 1.71248 10.839 1.67968 10.9078 1.66678C10.9687 1.65535 11.0313 1.65535 11.0922 1.66678C11.161 1.67968 11.2266 1.71248 11.3578 1.77807L21 6.59919L11.3578 11.4203C11.2266 11.4859 11.161 11.5187 11.0922 11.5316C11.0313 11.543 10.9687 11.543 10.9078 11.5316C10.839 11.5187 10.7734 11.4859 10.6422 11.4203L1 6.59919Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
