import { Tag } from 'antd';
import React from 'react';
import { PayrollReport, PayrollVoice } from 'types/PayrollReport';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  TableHeader,
  TableRecordRow,
  TableRecordText,
  TableRow,
} from './ExpandedTableStyles';

interface ExpandedRowProps {
  report: PayrollReport;
}

export default function ExpandedTable({ report }: ExpandedRowProps) {
  const { t } = useTranslation();

  function findValueInParameters(voice: PayrollVoice, salary: number) {
    const parameters: any = voice?.payrollRule?.parameters ?? [];
    const selectedParameter = parameters.find(
      (parameter: any) =>
        parameter.valueType === 'percentage' &&
        parameter.from &&
        parameter.to &&
        salary >= parameter.from &&
        salary <= parameter.to
    );

    return selectedParameter
      ? `${selectedParameter.value}%`
      : `${voice.value}${
          report?.employee?.salaryEmployee?.symbol ||
          voice?.payrollRule?.currency?.symbol ||
          ''
        }`;
  }

  return (
    <TableContainer>
      <TableRow>
        <TableHeader width={25}>{t('voice')}</TableHeader>
        <TableHeader width={20}>{t('description')}</TableHeader>
        <TableHeader width={15}>{t('value')}</TableHeader>
        <TableHeader width={15}>{t('cost')}</TableHeader>
      </TableRow>
      <>
        {report.voices.map((voice: PayrollVoice) => (
          <TableRecordRow>
            <TableHeader width={25}>
              <Tag
                color="blue"
                style={{
                  borderRadius: '5px',
                  textAlign: 'center',
                  padding: '5px 10px',
                  width: 'fit-content',
                }}
              >
                {voice.payrollRule?.humanID}
              </Tag>
            </TableHeader>

            <TableRecordText style={{ width: '20%' }}>
              {voice.payrollRule ? voice.payrollRule?.description : ''}
              {voice.assignment ? voice.assignment?.project?.name : ''}
            </TableRecordText>
            {voice.payrollRule?.type === 'FIXED' && (
              <TableRecordText
                style={{
                  width: '15%',
                }}
              >
                {`${voice.value} ${
                  report?.employee?.salaryEmployee?.symbol ||
                  voice?.payrollRule?.currency?.symbol ||
                  ''
                }`}
              </TableRecordText>
            )}
            {voice.payrollRule?.type === 'PERCENTAGE' && (
              <TableRecordText
                style={{
                  width: '15%',
                }}
              >
                {`${voice.payrollRule?.parameters?.value}%`}
              </TableRecordText>
            )}
            {voice.payrollRule?.type === 'INTERVAL' && (
              <TableRecordText
                style={{
                  width: '15%',
                }}
              >
                {findValueInParameters(voice, report?.employee?.salary)}
              </TableRecordText>
            )}
            <TableRecordText
              style={{
                width: '15%',
              }}
            >
              {`${voice.value} ${
                voice?.payrollRule?.currency?.symbol ||
                report?.employee?.salaryEmployee?.symbol ||
                ''
              }`}
            </TableRecordText>
          </TableRecordRow>
        ))}
      </>
    </TableContainer>
  );
}
