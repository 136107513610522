import { PayrollReport } from 'types/PayrollReport';

export enum PayrollReportActions {
  FETCH_SINGLE_PAYROLL_REPORT_START = 'FETCH_SINGLE_PAYROLL_REPORT_START',
  FETCH_SINGLE_PAYROLL_REPORT_SUCCESS = 'FETCH_SINGLE_PAYROLL_REPORT_SUCCESS',
  FETCH_SINGLE_PAYROLL_REPORT_ERRORED = 'FETCH_SINGLE_PAYROLL_REPORT_ERRORED',

  CLEAR_SINGLE_PAYROLL_REPORT = 'CLEAR_SINGLE_PAYROLL_REPORT',
  CLEAR_ALL_PAYROLL_REPORT = 'CLEAR_ALL_PAYROLL_REPORT',

  CLEAR_SINGLE_PAYROLL_DATES = 'CLEAR_SINGLE_PAYROLL_DATES',
  CLEAR_ALL_PAYROLL_DATES = 'CLEAR_ALL_PAYROLL_DATES',

  FETCH_PAYROLL_DATES_START = 'FETCH_PAYROLL_DATES_START',
  FETCH_PAYROLL_DATES_SUCCESS = 'FETCH_PAYROLL_DATES_SUCCESS',
  FETCH_PAYROLL_DATES_ERRORED = 'FETCH_PAYROLL_DATES_ERRORED',
}

export interface PayrollReportState {
  data: Array<PayrollReport>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface PayrollReportSingleEmployeeState {
  data: PayrollReport | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
