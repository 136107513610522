import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';

import GenericForm from 'components/NewForms/Form';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { fetchTenantAccounts } from 'redux/tenants/actions';
import { TenantsAccountsQueryParamsType } from 'types/QueryParams';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function TenantAccountsFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenantId = new URLSearchParams(window.location.search).get('tenantId');

  const onClear = () => {
    form.resetFields();
    if (tenantId) {
      dispatch(fetchTenantAccounts(tenantId));
    }
  };

  const getFilteredTenantAccounts = (
    queryParams: TenantsAccountsQueryParamsType
  ) => {
    if (tenantId) {
      dispatch(
        fetchTenantAccounts(tenantId, {
          pageOptions: { page: 1 },
          searchOptions: queryParams?.searchOptions,
        })
      );
    }
  };

  const onStatusCheck = (checked: boolean) => {
    getFilteredTenantAccounts({ searchOptions: { accountStatus: !!checked } });
  };
  const onTwoFaCheck = (checked: boolean) => {
    getFilteredTenantAccounts({ searchOptions: { twoFaStatus: !!checked } });
  };

  const TenantAccountsFileterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'accountStatus',
          label: t('accountStatus:'),
          switchProps: {
            checkedChildren: 'Active',
            unCheckedChildren: 'Inactive',
          },
          type: InputTypes.SWITCH,
          onChecked: onStatusCheck,
        },
        {
          col: 24,
          offset: 0,
          name: 'twoFaStatus',
          label: t('twoFactorStatus'),
          switchProps: {
            checkedChildren: 'Enabled',
            unCheckedChildren: 'Disabled',
          },
          type: InputTypes.SWITCH,
          onChecked: onTwoFaCheck,
        },
      ],
    ],
    []
  );

  return (
    <Row justify="center">
      <Col>
        <GenericForm
          formConfiguration={TenantAccountsFileterConfig}
          form={form}
        />
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <StyledPopoverContentButton type="link" onClick={onClear}>
              {t('clearAll')}
            </StyledPopoverContentButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
