import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  convertUTCtoLocalTime,
  getUserDateFormat,
  toastErrorMessages,
} from 'utils/utilFunctions';

import {
  createPurchaseRequest,
  editPurchaseRequest,
} from 'api/purchaseRequest';
import { PurchaseRequestPayload } from 'types/PurchaseRequest';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { useDispatch } from 'react-redux';
import { fetchPurchaseRequests } from 'redux/purchaseRequest/actions';
import useHeader from 'ui-v2/hooks/useHeader';
import { usePurchaseRequestData } from 'ui-v2/hooks/usePurchaseRequestData';
import { getVendors } from 'api/vendorService';
import { isFormEdited } from '../Form/utils';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import { getFormatedTime } from '../InterviewModalForm/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function PurchaseRequestForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const { take } = useHeader();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { purchaseRequest } = usePurchaseRequestData();
  const [vendors, setVendors] = useState();

  const userDateFormat = getUserDateFormat();

  async function onFormFinish() {
    const formValues = form.getFieldsValue(true);
    const valuesToSend: PurchaseRequestPayload = {
      time: formValues.time,
      date: formValues.date,
      estimatedCost: formValues.estimatedCost,
      itemDescription: formValues.itemDescription,
      currencyId: formValues.currencyId,
      requestingPerson: formValues.requestingPerson,
      vendorId: formValues.vendorId,
    };

    isLoading(true);

    if (purchaseRequest?.data?.id) {
      if (
        isFormEdited({
          formValues: valuesToSend,
          valuesToCheck: purchaseRequest?.data,
          entity: 'purchaseRequest',
        })
      ) {
        isLoading(false);
        closeModal();
        return;
      }
      editPurchaseRequest(valuesToSend, purchaseRequest?.data?.id)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            toast.success(t('Successfully updated purchase request !'));
            if (response?.data?.id) {
              // navigate()
            } else {
              navigate('/purchase-requests');
            }
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          isLoading(false);
        });

      return;
    }

    createPurchaseRequest(valuesToSend)
      .then((response) => {
        toast.success(t('Successfully created purchase request!'));

        if (response.status === 200) {
          closeModal();
          dispatch(
            fetchPurchaseRequests({
              pageOptions: { page: 1, take },
            })
          );
        } else {
          navigate('/purchase-requests');
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  async function fetchAllVendors() {
    getVendors({
      filterText: '',
      filterFields: [],
      page: 1,
      take,
    })
      .then((response) => {
        setVendors(
          response.data?.data?.map((task: any) => ({
            id: task.id,
            value: task.id,
            label: task.companyName,
          }))
        );
      })
      .catch(() => {
        toast.error(t("Couldn't get onboarding tasks"));
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .finally(() => {});
  }

  useEffect(() => {
    fetchAllVendors();
  }, []);

  const VendorCategoryFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'date',
          label: t('date'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(purchaseRequest?.data?.date),
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 24,
          offset: 0,
          name: 'time',
          label: t('time'),
          type: InputTypes.TIMEPICKER,
          defaultValue: getFormatedTime(purchaseRequest?.data),
          dateProps: {
            format: `${userDateFormat}  ->  HH:mm`,
            showTime: true,
          },
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 24,
          offset: 0,
          name: 'itemDescription',
          label: t('description'),
          type: 'textarea',
          defaultValue: purchaseRequest?.data?.itemDescription,
          rules: [{ required: true, message: t('fieldRequired') }],
        },

        {
          col: 23,
          offset: 0,
          name: 'estimatedCost',
          label: t('Estimated Cost'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: purchaseRequest?.data?.estimatedCost,
          rules: [{ required: true, message: t('fieldRequired') }],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions?.length > 0 ? currencyOptions : [],
            placeholder: '',
            defaultValue:
              purchaseRequest?.data?.currency?.id || currencyOptions[1]?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        },
        {
          col: 24,
          offset: 0,
          name: 'requestingPerson',
          label: t('Requesting Person'),
          type: InputTypes.INPUT,
          defaultValue: purchaseRequest?.data?.requestingPerson,
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 24,
          offset: 0,
          name: 'vendorId',
          label: t('Vendor'),
          type: InputTypes.SELECT,
          defaultValue: purchaseRequest?.data?.vendor?.id,
          showSearch: true,
          selectOptions: vendors,
        },
      ],
    ],
    [currencyOptions, vendors, purchaseRequest]
  );

  return (
    <GenericModal
      title={t(
        `${purchaseRequest?.data?.id ? 'Edit' : 'Add'} purchase request`
      )}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={VendorCategoryFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default PurchaseRequestForm;
