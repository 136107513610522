import { Button } from 'antd';
import styled from 'styled-components';

export const StyledAddetDepatmentBox = styled.div(({ theme }) => ({
  padding: '.5rem 1rem',
  borderRadius: '.3rem',
  border: `1px solid ${theme.colors.palette.primary.gray['200']}`,
  ':hover': {
    backgroundColor: theme.colors.palette.primary.gray['100'],
  },
}));

export const StyledUnassignButton = styled(Button)`
  color: red;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.5;
    color: red !important;
    span {
      color: red !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;
