import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { VendorQuestionnaireAsyncAction } from 'redux/vendorQuestionnaire/actions';
import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import VendorQuestionnaireQuestionForm from 'components/NewForms/VendorQuestionnaireForm/VendorQuestionnaireQuestionForm';
import { VendorQuestionnaireApiClient } from 'api/vendorQuestionnaireService';
import { toastErrorMessages } from 'utils/utilFunctions';

import VendorQuestionaireQuestionListCard from './VendorQuestionaireQuestionListCard';
import EmptyQuestionnaireQuestionContent from './EmptyQuestionnaireQuestionContent';
import VendorQuestionnaireQuestionTable from './VendorQuestionnaireQuestionTable';

interface VendorQuestionnaireQuestionListProps {
  onSelect: (id: string) => void;
}

const VendorQuestionnaireQuestionList: React.FC<
  VendorQuestionnaireQuestionListProps
> = ({ onSelect }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>();
  const {
    questionnaireCategory: { selectedQuestionnaireCategory },
  } = useVendorQuestionnaireData();

  const toogleModalVisibility = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const onEdit = (id: string) => {
    setSelectedQuestionId(id);
    toogleModalVisibility();
  };

  const onDelete = useCallback(
    async (id: string) => {
      VendorQuestionnaireApiClient.deleteQuestionnaire({ id })
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('vendorQuestionnaireQuestionDeletedSuccessfully'));
            if (selectedQuestionnaireCategory?.id) {
              dispatch(
                VendorQuestionnaireAsyncAction.fetchCategorie({
                  id: selectedQuestionnaireCategory?.id,
                })
              );
            }
          }
        })
        .catch((e) => toastErrorMessages(e));
    },
    [selectedQuestionnaireCategory]
  );

  if (!selectedQuestionnaireCategory?.vendorQuestionnaire?.length) {
    return (
      <>
        <EmptyQuestionnaireQuestionContent onClick={toogleModalVisibility} />
        {modalVisible && (
          <VendorQuestionnaireQuestionForm
            open={modalVisible}
            closeModal={toogleModalVisibility}
          />
        )}
      </>
    );
  }

  return (
    <>
      <VendorQuestionaireQuestionListCard onClick={toogleModalVisibility}>
        <VendorQuestionnaireQuestionTable
          onEdit={onEdit}
          onDelete={onDelete}
          onSelect={onSelect}
        />
      </VendorQuestionaireQuestionListCard>
      {modalVisible && (
        <VendorQuestionnaireQuestionForm
          id={selectedQuestionId}
          open={modalVisible}
          closeModal={toogleModalVisibility}
        />
      )}
    </>
  );
};

export default VendorQuestionnaireQuestionList;
