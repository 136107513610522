import React from 'react';
import { Col, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';

import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import SettingsActionIcon from 'Icons/SettingsActionIcon';
import InfoIcon from 'Icons/InfoIcon';

type TProps = {
  id: string;
};

const TenantAccountAction: React.FC<TProps> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function onDetailsClick() {
    searchParams.set('viewtenantaccountid', id);
    setSearchParams(searchParams);
  }

  function onSettingsClick() {
    searchParams.set('tenantaccountsettings', id);
    setSearchParams(searchParams);
  }

  return (
    <Row justify="center" align="middle" gutter={[30, 0]}>
      <Col>
        <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
          <StyledIcon onClick={onDetailsClick}>
            <InfoIcon />
          </StyledIcon>
        </RoleBasedButton>
      </Col>
      <Col>
        <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
          <StyledIcon onClick={onSettingsClick}>
            <SettingsActionIcon />
          </StyledIcon>
        </RoleBasedButton>
      </Col>
    </Row>
  );
};

export default TenantAccountAction;
