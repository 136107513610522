import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { ResponseSkillCategoryType } from 'types/Skills';
import {
  ButtonStyle,
  NodataLabel,
} from 'ui-v2/components/WorkExperience/WorkExperienceStyles';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import * as Styled from '../SkillsStyles';
import SkillTag from './SkillTag';
import i18next from '../../../../i18n';

type IProps = {
  skills: Array<ResponseSkillCategoryType> | [];
  loading: boolean;
  isAddSkillBtnVisible: boolean;
  onAddSkillBtnClick: () => void;
  onDeleteSkill: (skill: ResponseSkillCategoryType) => void;
};

const SkillsWrapper: React.FC<IProps> = ({
  skills,
  loading,
  isAddSkillBtnVisible,
  onAddSkillBtnClick,
  onDeleteSkill,
}) => (
  <Skeleton
    loading={loading}
    active
    title={false}
    paragraph={{ rows: 6, width: '100%' }}
  >
    <Row justify={skills.length ? 'start' : 'center'} gutter={[0, 24]}>
      {skills.length ? (
        <>
          <Col span={24}>
            <Styled.Subtitle>{i18next.t('skills(Years)')}</Styled.Subtitle>
          </Col>
          <Col>
            <Row gutter={[18, 8]}>
              {skills.map((skill: any) => (
                <Col>
                  <SkillTag skill={skill} onDelete={onDeleteSkill} />
                </Col>
              ))}
            </Row>
          </Col>
        </>
      ) : (
        <Col>
          <NodataLabel>{i18next.t('there are no skills yet.')}</NodataLabel>
        </Col>
      )}

      {isAddSkillBtnVisible && (
        <Col span={24}>
          <Row justify="center">
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col>
                <ButtonStyle
                  icon={<PlusCircleOutlined />}
                  size="large"
                  onClick={onAddSkillBtnClick}
                >
                  {i18next.t('addSkill')}
                </ButtonStyle>
              </Col>
            </RoleBasedButton>
          </Row>
        </Col>
      )}
    </Row>
  </Skeleton>
);

export default SkillsWrapper;
