import React from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import PayrollDateModalContent from './PayrollDateModalContent';

export type PayrollHoliday = {
  date: moment.Moment;
  name: string;
};

interface IProps {
  open: boolean;
  closeModal: () => void;
  onFinish: (
    nationalHolidays: Date[],
    date: Date,
    tenantLocationId: string
  ) => void;
}

function PayrollGenerateReportModal({ open, closeModal, onFinish }: IProps) {
  const { t } = useTranslation();

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <GenericModal
      title={t('setReportDate')}
      open={open}
      closeModal={onCloseModal}
    >
      <PayrollDateModalContent
        onCloseModal={onCloseModal}
        onFinish={onFinish}
      />
    </GenericModal>
  );
}

export default PayrollGenerateReportModal;
