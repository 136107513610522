import React from 'react';
import { PayrollReport } from 'types/PayrollReport';
import { Table } from 'antd';
import moment from 'moment';
import i18next from '../i18n';

export const PayrollReportColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'employee',
    key: 'employee',
    render: (_: string, record: PayrollReport) => (
      <p>{`${record.employee.firstName} ${record.employee.lastName}`}</p>
    ),
    order: 0,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    order: 1,
  },
  {
    title: i18next.t('employeeCode'),
    dataIndex: 'employeeIdentifier',
    key: 'employeeIdentifier',
    order: 2,
    render: (_: string, record: PayrollReport) => (
      <p>{`${record.employee?.employeeIdentifier}`}</p>
    ),
  },
  {
    title: i18next.t('date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (_: string, record: PayrollReport) => (
      <p>{moment(record.date).format('DD/MM/YYYY')}</p>
    ),
    order: 0,
  },
  {
    title: i18next.t('brutoSalary'),
    dataIndex: 'salaryBruto',
    key: 'salaryBruto',
    order: 2,
    render: (_: string, record: PayrollReport) => (
      <p>
        {`${record.salaryBruto} ${
          record?.employee?.salaryEmployee?.symbol ||
          record?.voices[0]?.payrollRule?.currency?.symbol ||
          ''
        }`}
      </p>
    ),
  },
  {
    title: i18next.t('netoSalary'),
    dataIndex: 'salaryNeto',
    key: 'salaryNeto',
    order: 3,
    render: (_: string, record: PayrollReport) => (
      <p>
        {`${record.salaryNeto} ${
          record?.employee?.salaryEmployee?.symbol ||
          record?.voices[0]?.payrollRule?.currency?.symbol ||
          ''
        } `}
      </p>
    ),
  },
  Table.EXPAND_COLUMN,
];
