import agent from 'api/agent';
import { SkillSubCategoryCreateDTO } from 'types/Skills';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getAllJobCategories = async () => agent.get(apiURLs.jobCategories);

export const getAllJobCategoriesWithSubCategories = async () =>
  agent.get(`${apiURLs.skillsCategory}/sub-categories`);

export const findAllSkillSubCategorySelectOptions = (
  queryParams?: QueryParamsDTO
) =>
  agent.post(`${apiURLs.skillsSubCategory}/selectoptions`, {
    pageOptionsDto: queryParams?.pageOptions,
    filterOptions: queryParams?.filterOptions,
  });

export const getAllJobCategoriesWithSubCategoriesPublic = async () =>
  agent.get(`${apiURLs.skillsCategory}/sub-categories-anonymous`);

export const createSkillSubcategory = async (
  values: SkillSubCategoryCreateDTO
) => agent.post(`${apiURLs.skillsCategory}/subCategory`, values);

export const getSkillsMatrixChartData = async (skillId: string) =>
  agent.get(`${apiURLs.dashboard}/matrixChart/${skillId}`);
