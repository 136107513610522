import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import PurchaseOrderAction from 'components/Actions/PurchaseOrderAction';
import { PurchaseOrderType } from 'types/PurchaseOrder';

export const PurchaseOrdersColumns = [
  {
    title: i18next.t('item'),
    dataIndex: 'item',
    key: 'item',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.item}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('purchaseOrderNumber'),
    dataIndex: 'purchaseOrderNumber',
    key: 'purchaseOrderNumber',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.purchaseOrderNumber}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.description}</Styled.TextBold>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('quantity'),
    dataIndex: 'quantity',
    key: 'quantity',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.quantity}</Styled.TextBold>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('totalPrice'),
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{`${item?.currency?.symbol}${item?.totalPrice}`}</Styled.TextBold>
    ),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('status'),
    dataIndex: 'status',
    key: 'status',
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{`${item?.status}`}</Styled.TextBold>
    ),
    default: false,
    order: 4,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => <PurchaseOrderAction id={row.id} />,
    default: false,
    order: 5,
  },
];
