import { Col, Form, Row } from 'antd';
import { updateCandidate } from 'api/candidateService';
import GenericForm from 'components/NewForms/Form';
import {
  FormItemStyled,
  StyledButton,
  AddSpaceIcon,
} from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCandidate } from 'redux/candidates/actions';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export default function AddCandidateNote({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const { candidate } = useCandidateData();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'notes',
          label: t('note'),
          type: 'textarea',
          defaultValue: candidate?.notes,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    []
  );

  const onSubmit = () => {
    if (loading || !candidate?.id) return;

    setLoading(true);

    updateCandidate(candidate?.id, {
      notes: form.getFieldValue('notes'),
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 && response.data) {
          dispatch(fetchCandidate(candidate?.id));
          toast.success('Successfully updated note!');
          closeModal();
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error('Failed to add note!');
      });
  };

  return (
    <GenericModal
      title={!candidate?.notes ? t('addNote') : t('editNote')}
      open={open}
      closeModal={closeModal}
      icon={
        <AddSpaceIcon>
          <AssetsIcon />
        </AddSpaceIcon>
      }
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
