import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    font-size: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
    font-size: 15px;
    font-weight: 700;
  }
  && span.ant-select-selection-item {
    font-weight: 500;
  }
  && .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
  }
  && span.ant-select-arrow {
    font-size: 14px;
    font-weight: 700;
    color: black;
    margin-left: 6px;
  }
  && .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: none;
  }
  && svg#Layer_1 {
    padding-top: 16px;
    padding-left: 6px;
  }
`;
