import React from 'react';
import { TabsProps } from 'antd';
import { TabsStyled } from './BaseTabsStyled';

interface BaseTabsProps extends TabsProps {
  compact?: boolean;
}

const BaseTabs: React.FC<BaseTabsProps> = (props) => <TabsStyled {...props} />;

export default BaseTabs;
