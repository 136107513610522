import React from 'react';
import { Col } from 'antd';
import * as Styled from './CardStyles';

interface IProps {
  title?: string | React.ReactNode;
  minHeight?: number;
  children: JSX.Element;
  rightButton?: JSX.Element | undefined;
  disabled?: boolean;
  wrapContentHeight?: boolean;
  rightButtonContent?: number;
}

export default function CardItem({
  title,
  minHeight = 170,
  children,
  rightButton,
  disabled,
  wrapContentHeight,
  rightButtonContent = 8,
}: IProps) {
  return (
    <Styled.Container
      direction="vertical"
      size="middle"
      disabled={disabled}
      wrapContentHeight={wrapContentHeight}
    >
      <Styled.RowCardItem>
        {title && (
          <Col span={rightButton ? 24 - rightButtonContent : 24}>
            <Styled.Title>{title}</Styled.Title>
          </Col>
        )}
        {rightButton && <Col span={rightButtonContent}>{rightButton}</Col>}
      </Styled.RowCardItem>
      <Styled.Children minHeight={minHeight}>{children}</Styled.Children>
    </Styled.Container>
  );
}

CardItem.defaultProps = {
  title: '',
  minHeight: 0,
};
