import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Spin } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import 'App.css';
import CandidateFormRegistration from 'components/Candidate/CandidateRegistrationForm';

import AppLayout from 'layouts/BasicLayout';
import SetUserPassword from 'pages/SetUserPassword/SetUserPassword';
import OfferAccept from 'ui-v2/routes/OfferAccept/OfferAccept';
import SetEmployeePassword from 'pages/SetEmployeePassword';
import GuestCompaniesList from 'pages/GuestPages/GuestCompanies/GuestCompaniesList';
import GuestOpenPositionsList from 'pages/GuestPages/GuestOpenPositions';
import ErrorBoundary from 'ErrorBoundary';
import Login from 'ui-v2/components/NoAuth/Login';
import RegisterCompany from 'ui-v2/components/NoAuth/RegisterCompany';
import ForgotPassword from './components/NewForms/ForgotPassword';
import './i18n';

const App = () => (
  <>
    <Suspense fallback={<Spin spinning />} />
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}>
            <Route path="" />
            <Route path="super-admin" />
          </Route>
          <Route path="/register" element={<RegisterCompany />} />
          <Route path="/set-password/:token" element={<SetUserPassword />} />
          <Route
            path="/set-password/employee/:token"
            element={<SetEmployeePassword />}
          />
          <Route
            path="/candidate-registration"
            element={<CandidateFormRegistration />}
          >
            <Route path=":tenantId" element={<CandidateFormRegistration />} />
          </Route>
          <Route path="/jobs/companies">
            <Route path="" element={<GuestCompaniesList />} />
            <Route
              path="FirstCompany"
              element={
                <GuestOpenPositionsList hardcodedTenantId="4b4f4e3d-17d8-40f8-8735-dff621d07321" />
              }
            />
            <Route
              path="Atrax"
              element={
                <GuestOpenPositionsList hardcodedTenantId="a502f4d2-22ee-4c72-8f42-7c35fada6440" />
              }
            />
            <Route path=":tenantId" element={<GuestOpenPositionsList />} />
          </Route>
          <Route path="/accept-offer/:token" element={<OfferAccept />} />
          <Route path="/register-company" element={<RegisterCompany />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/*" element={<AppLayout />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </ErrorBoundary>
  </>
);
export default App;
