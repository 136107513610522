import { WorkPositionDtoType } from 'types/WorkPosition';

import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getWorkPosition = (id: string) =>
  agent.get(`${apiURLs.workPosition}/${id}`);

export const getAllWorkPositions = (queryParams: QueryParamsDTO) =>
  agent.post(
    `${apiURLs.workPosition}/get`,
    searchFields(queryParams, apiURLs.workPosition)
  );

export const getWorkPositionSkim = () =>
  agent.get(`${apiURLs.workPosition}/skim`);

export const searchWorkPositions = (searchText: string | null) =>
  agent.get(`${apiURLs.workPosition}?name=${searchText}`);

export const createNewWorkPosition = (values: WorkPositionDtoType) =>
  agent.post(apiURLs.workPosition, values);

export const updateWorkPosition = (values: WorkPositionDtoType, id: string) =>
  agent.put(`${apiURLs.workPosition}/${id}`, values);

export const getOneWorkPosition = (id: string) =>
  agent.get(`${apiURLs.workPosition}/${id}`);

export const deleteWorkPosition = (id: string) =>
  agent.delete(`${apiURLs.workPosition}/${id}`);
