import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { deleteOnboarding } from 'api/onBoardingService';
import OnboardingModalForm from 'components/NewForms/OnboardingModalForm';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  clearOnboardingState,
  fetchOnBoardings,
} from 'redux/onboardings/actions';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { useOnboardingsData } from 'ui-v2/hooks/useOnboardingsData';

export default function OnboardingsList() {
  const dispatch = useDispatch();
  const { columns, take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, loading, pagination } = useOnboardingsData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const toogleModal = () => setModalVisible((modalVisible) => !modalVisible);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchOnBoardings(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchOnBoardings(params));
    setSearchTerm(searchTerm);
  };

  async function removeOnboarding(id: string) {
    deleteOnboarding(id)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Onboarding deleted successfully!');
          let currentPage = pagination?.page;
          if (list.length === 1) {
            currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
          }
          dispatch(fetchOnBoardings({ page: currentPage, take }));
        }
      })
      .catch(() => {
        toast.error('Onboarding failed to delete!');
      });
  }

  const onModalClose = () => {
    toogleModal();
    searchParams.delete('edit-onboarding');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('add-new-onboarding') === 'true') {
      searchParams.delete('add-new-onboarding');
      setSearchParams(searchParams);
      toogleModal();
    }
    if (searchParams.get('edit-onboarding')) {
      toogleModal();
    }

    if (searchParams.get('delete-onboarding')) {
      removeOnboarding(searchParams.get('delete-onboarding') as string);
      searchParams.delete('delete-onboarding');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const getOnboardings = useCallback(async () => {
    dispatch(fetchOnBoardings({ page: 1, take }));
  }, [take]);

  useEffect(() => {
    dispatch(clearOnboardingState());
  }, []);

  useEffect(() => {
    getOnboardings();
  }, [take]);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await removeOnboarding(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
          paginationName="On Boardings"
        />
        {modalVisible && (
          <OnboardingModalForm open={modalVisible} closeModal={onModalClose} />
        )}
      </>
    </Content>
  );
}
