import React from 'react';

export const EmployeesIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 19.3994V17.3994C21 15.5356 19.7252 13.9695 18 13.5254M14.5 1.69017C15.9659 2.28356 17 3.72073 17 5.39941C17 7.0781 15.9659 8.51527 14.5 9.10866M16 19.3994C16 17.5356 16 16.6038 15.6955 15.8687C15.2895 14.8886 14.5108 14.1099 13.5307 13.7039C12.7956 13.3994 11.8638 13.3994 10 13.3994H7C5.13623 13.3994 4.20435 13.3994 3.46927 13.7039C2.48915 14.1099 1.71046 14.8886 1.30448 15.8687C1 16.6038 1 17.5356 1 19.3994M12.5 5.39941C12.5 7.60855 10.7091 9.39941 8.5 9.39941C6.29086 9.39941 4.5 7.60855 4.5 5.39941C4.5 3.19027 6.29086 1.39941 8.5 1.39941C10.7091 1.39941 12.5 3.19027 12.5 5.39941Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
