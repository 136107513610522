/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  getProfileId,
  HEADER_BUTTON_TYPE,
} from 'ui-v2/components/HeaderActions/config';
import {
  StyledButton,
  StyledButtonPrimary,
  StyledScorecardEditButton,
} from 'components/NewForms/FormStyled';
import { EdititingIcon } from 'Icons/EditIcon';
import { PlusIcon } from 'Icons/PlusIcon';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { UserDisqualifyIcon } from 'Icons/UserDisqualifyIcon';
import { UserHireIcon } from 'Icons/UserHireIcon';
import { CalendarIcon } from 'Icons/CalendarIcon';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { FilterByIcon } from 'Icons/FilterByIcon';
import CandidateOfferHeaderButton from 'ui-v2/routes/CandidateProfile/CandidateOffer/CandidateOfferButton';
import { useTranslation } from 'react-i18next';
import { TimeIcon } from 'Icons/TimeIcon';
import CalendarPopover from '../CalendarPopover';
import TableConfigurePopover from '../TableConfigurePopover';
import TableFilterPopover from '../TableFilterPopover';

interface IProps {
  label: string;
  onClick?: any;
  href?: string;
  disabled?: boolean;
}

export default function CustomButton({
  label,
  href,
  onClick,
  disabled,
}: IProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { candidate } = useCandidateData();
  const { employee } = useEmployeesData();
  const { t } = useTranslation();

  switch (label) {
    case HEADER_BUTTON_TYPE.ADD_NEW_CANDIDATE:
      return (
        <StyledButton
          onClick={() => navigate(href || '')}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButton>
      );
      break;

    case HEADER_BUTTON_TYPE.ADD_NEW_WORK_POSITION:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_PURCHASE_REQUEST:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.ADD_VENDOR_QUESTIONNAIRE:
      return (
        <StyledButton type="primary" icon={<PlusIcon />} onClick={onClick}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_VENDOR_QUESTIONNAIRE:
      return (
        <StyledButton type="link" icon={<EdititingIcon />} onClick={onClick}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.REGISTER_NEW_TENANT_ACCOUNT:
      return (
        <StyledButton type="primary" icon={<PlusIcon />} onClick={onClick}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_ONBOARDING:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_PROJECT:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(HEADER_BUTTON_TYPE.ADD_NEW_PROJECT)}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.ADD_NEW_QUESTIONNAIRE:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(HEADER_BUTTON_TYPE.ADD_NEW_QUESTIONNAIRE)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_IDEABOX:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(HEADER_BUTTON_TYPE.ADD_NEW_IDEABOX)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_VENDOR:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(HEADER_BUTTON_TYPE.EDIT_VENDOR)}
        </StyledButton>
      );
      break;

    case HEADER_BUTTON_TYPE.ADD_NEW_VENDOR:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(HEADER_BUTTON_TYPE.ADD_NEW_VENDOR)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_SKILL:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButtonPrimary>
      );

    case HEADER_BUTTON_TYPE.ADD_NEW_ASSET_CATEGORY:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('addAssetCategory')}
        </StyledButtonPrimary>
      );

    case HEADER_BUTTON_TYPE.ADD_NEW_DEPARTMENT:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButtonPrimary>
      );
      break;
    case HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_PERIOD:
      return (
        <StyledButton
          onClick={() => navigate(href || '')}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.ADD_NEW_ASSET:
      return (
        <StyledButton
          onClick={() => navigate(href || '')}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_ASSET:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `edit-asset?id=${searchParams.get('id') || getProfileId()}`
            )
          }
          type="link"
          icon={<EdititingIcon />}
        >
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.ADD_NEW_OPEN_POSITION:
      return (
        <StyledButton
          onClick={() => navigate(href || '')}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.CONFIGURE:
      return <TableConfigurePopover />;
      break;
    case HEADER_BUTTON_TYPE.FILTER_BY:
      return <TableFilterPopover />;
      break;
    case HEADER_BUTTON_TYPE.ADD_OPEN_POSITION_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_WORK_POSITION_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_CANDIDATE_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_ASSET_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_USER_SAVE:
      return (
        <StyledButton type="primary" onClick={onClick}>
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_USER_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_DEPARTMENT_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_ONBOARDING_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_ONBOARDING_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_OPEN_POSITION_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_ASSET_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_DEPARTMENT_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_WORK_POSITION_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_CANDIDATE_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_OPEN_POSITION_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_ONBOARDING_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.SETTINGS_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_ONBOARDING_CANCEL:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `onboarding-details?id=${
                searchParams.get('id') || getProfileId()
              }`
            )
          }
          type="link"
        >
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_PUBLIC_SUPER_ADMIN:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(HEADER_BUTTON_TYPE.ADD_PUBLIC_SUPER_ADMIN)}
        </StyledButtonPrimary>
      );

    case HEADER_BUTTON_TYPE.ADD_IP_WHITELISTING:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(HEADER_BUTTON_TYPE.ADD_IP_WHITELISTING)}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.EDIT_SUPER_ADMIN_USER_PROFILE:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t('editProfile')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_USER_PROFILE:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_USER_PROFILE_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_USER_PROFILE_CANCEL:
      return (
        <StyledButton onClick={() => navigate(href || '')} type="link">
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_USER:
      return (
        <StyledButton
          onClick={() => navigate('edit-user')}
          type="link"
          icon={<EdititingIcon />}
        >
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_USER_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_USER_CANCEL:
      return (
        <StyledButton onClick={() => navigate(href || '')} type="link">
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_OPEN_POSITION_CANCEL:
      return (
        <StyledButton onClick={() => navigate(href || '')} type="link">
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_WORK_POSITION_CANCEL:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `work-position-details?id=${
                searchParams.get('id') || getProfileId()
              }`
            )
          }
          type="link"
        >
          {t('cancel')}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.EDIT_WORK_POSITION_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_CANDIDATE_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_CANDIDATE_CANCEL:
      return (
        <StyledButton
          onClick={() =>
            navigate(`profile?id=${searchParams.get('id') || getProfileId()}`)
          }
          type="link"
        >
          {t('cancel')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.SCHEDULE_INTERVIEW:
      return <CalendarPopover />;

    case HEADER_BUTTON_TYPE.EDIT_ACCOUNT:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `edit-account?id=${searchParams.get('id') || getProfileId()}`
            )
          }
          type="link"
          icon={<EdititingIcon />}
        >
          {t(label)}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.EDIT_ONBOARDING:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_WORK_POSITION:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(label)}
        </StyledButton>
      );
      break;

    case HEADER_BUTTON_TYPE.EDIT_TRAINING:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_TRAINING_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_TRAINING_CANCEL:
      return (
        <StyledButton onClick={onClick} type="link">
          {t('cancel')}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.EDIT_DEPARTMENT:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_DEPARTMENT_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_DEPARTMENT_CANCEL:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `department-details?id=${
                searchParams.get('id') || getProfileId()
              }`
            )
          }
          type="link"
        >
          {t('cancel')}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.EDIT_PROJECT:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(HEADER_BUTTON_TYPE.EDIT_PROJECT)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_QUESTIONNAIRE:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(HEADER_BUTTON_TYPE.EDIT_QUESTIONNAIRE)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_IDEABOX:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(HEADER_BUTTON_TYPE.EDIT_IDEABOX)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_OPEN_POSITION:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `edit-open-position?id=${
                searchParams.get('id') || getProfileId()
              }`
            )
          }
          type="link"
          icon={<EdititingIcon />}
        >
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.START_DATE:
      return (
        <StyledButton onClick={onClick} type="link" icon={<TimeIcon />}>
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.END_DATE:
      return (
        <StyledButton onClick={onClick} type="link" icon={<TimeIcon />}>
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.EDIT_CANDIDATE:
      return (
        <StyledButton
          onClick={onClick}
          type="link"
          icon={<EdititingIcon />}
          disabled={disabled}
        >
          {t(label)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.DISQUALIFY_CANDIDATE:
      return (
        <StyledButton
          onClick={onClick}
          danger
          icon={<UserDisqualifyIcon />}
          disabled={disabled}
        >
          {t('disqualify')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.DISQUALIFY_CANDIDATE_APPROVE:
      return (
        <StyledButton onClick={onClick} danger>
          {t('yes')}, {t('disqualify')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.DISQUALIFY_CANDIDATE_CANCEL:
      return (
        <StyledButton onClick={onClick}>
          {t('no')}, {t('cancel')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.OFFER_CANDIDATE:
      return <CandidateOfferHeaderButton disabled={disabled} />;
    case HEADER_BUTTON_TYPE.HIRE_CANDIDATE:
      return (
        <StyledButton
          onClick={onClick}
          type="primary"
          icon={<UserHireIcon />}
          disabled={disabled}
        >
          {t('hire')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.HIRE_CANDIDATE_APPROVE:
      return (
        <StyledButton type="primary">
          {t('yes')}, {t('hire')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.HIRE_CANDIDATE_CANCEL:
      return (
        <StyledButton onClick={onClick}>
          {t('no')}, {t('cancel')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.HIRE_CANDIDATE_FORM_CANCEL_BUTTON:
      return (
        <StyledButton
          type="link"
          onClick={() =>
            navigate(`profile?id=${searchParams.get('id') || getProfileId()}`)
          }
        >
          {t('no')}, {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.HIRE_CANDIDATE_FORM_SAVE:
      return <StyledButton onClick={onClick}>{label}</StyledButton>;
      break;
    case HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION:
      return (
        <StyledButton type="primary" icon={<PlusIcon />} onClick={onClick}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.GO_TO_EMPLOYEE_PROFILE:
      return (
        <StyledButton
          type="link"
          onClick={() =>
            navigate(`/employees/employee-profile?id=${candidate?.employeeId}`)
          }
        >
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_EVALUATION_SAVE:
      return (
        <StyledButton type="primary" onClick={onClick}>
          {t(label)}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.ADD_USER_LIST:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButtonPrimary>
      );

    case HEADER_BUTTON_TYPE.EDIT_USERS:
      return (
        <StyledButton type="primary" onClick={onClick}>
          {t(label)}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.DELETE_USERS:
      return (
        <StyledButton type="primary" onClick={onClick}>
          {t(label)}
        </StyledButton>
      );

    case HEADER_BUTTON_TYPE.ADD_NEW_CERTIFICATION:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(HEADER_BUTTON_TYPE.ADD_NEW_CERTIFICATION)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_BENEFIT:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(HEADER_BUTTON_TYPE.ADD_NEW_BENEFIT)}
        </StyledButton>
      );

    case 'Edit score':
      return (
        <StyledScorecardEditButton
          type="link"
          icon={<EdititingIcon />}
          size="large"
          onClick={onClick}
        >
          {label}
        </StyledScorecardEditButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_EVALUATION:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `edit-evaluation?id=${searchParams.get('id') || getProfileId()}`
            )
          }
          type="link"
          icon={<EdititingIcon />}
        >
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.SCHEDULE_NEW_INTERVIEW:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<CalendarIcon />}
        >
          {t(HEADER_BUTTON_TYPE.SCHEDULE_INTERVIEW)}
        </StyledButtonPrimary>
      );

    case HEADER_BUTTON_TYPE.ADD_NEW_TRAINING:
      return (
        <StyledButton onClick={onClick} type="primary" icon={<PlusIcon />}>
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_TRAINING_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_TRAINING_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_EMPLOYEE:
      return (
        <StyledButton
          onClick={() => navigate(href || '')}
          type="primary"
          icon={<PlusIcon />}
        >
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_EMPLOYEE_CANCLE:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.ADD_EMPLOYEE_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('save')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_EMPLOYEE:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `edit-employee?id=${searchParams.get('id') || getProfileId()}`
            )
          }
          type="link"
          icon={<EdititingIcon />}
        >
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_EMPLOYEE_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_EMPLOYEE_CANCEL:
      return (
        <StyledButton
          onClick={() =>
            navigate(
              `employee-profile?id=${searchParams.get('id') || getProfileId()}`
            )
          }
          type="link"
        >
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_CERTIFICATION:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t(HEADER_BUTTON_TYPE.EDIT_CERTIFICATION)}
        </StyledButton>
      );
      break;
    case HEADER_BUTTON_TYPE.ADD_WORK_LOG_FOR_ALL:
      return (
        <StyledButton
          onClick={() => navigate('/tracking?add-work-log=true')}
          type="primary"
        >
          {t('logHour')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_WORK_LOG:
      return (
        <StyledButton
          onClick={() =>
            navigate('/employee-details/tracker?add-work-log=true')
          }
          type="primary"
        >
          {t('logHour')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.TRACKING_HOURS_FILTER:
      return (
        <StyledButton
          onClick={() => navigate('/tracking?open-tracking-filter=true')}
          id="filter-popover"
          icon={<FilterByIcon />}
        >
          {t('filterBy')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EMPLOYEE_VIEW_CANDIDATE:
      return (
        <StyledButton
          onClick={() =>
            navigate(`/candidates/profile?id=${employee?.candidateId}`)
          }
          type="link"
        >
          {t(HEADER_BUTTON_TYPE.EMPLOYEE_VIEW_CANDIDATE)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.CHANGE_USER_PASSWORD:
      return (
        <StyledButton
          type="primary"
          onClick={() => navigate('change-password')}
        >
          {t(label)}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.CHANGE_SUPER_ADMIN_USER_PASSWORD:
      return (
        <StyledButton type="primary" onClick={onClick}>
          {t('changePassword')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.CHANGE_USER_PASSWORD_SAVE:
      return (
        <StyledButton onClick={onClick} type="primary">
          {t('saveChanges')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.CHANGE_USER_PASSWORD_CANCEL:
      return (
        <StyledButton type="link" onClick={() => navigate(href || '')}>
          {t('cancel')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_PAYROLL_RULE:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('Add Rule')}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.NEW_REPORT:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('newReport')}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.DATA_ENTRY_SAVE_EMPLOYEE:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('saveEmployees')}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.EDIT_PAYROLL_RULE:
      return (
        <StyledButton type="primary" onClick={onClick}>
          {t('Edit Rule')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_VENDOR_CATEGORY:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('addVendorCategory')}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.EDIT_VENDOR_CATEGORY:
      return (
        <StyledButton onClick={onClick} type="link">
          {t('editVendorCategory')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_PURCHASE_ORDER:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('addPurchaseOrder')}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.ADD_NEW_INVOICE:
      return (
        <StyledButtonPrimary
          onClick={onClick}
          type="primary"
          icon={<PlusIcon />}
        >
          {t('addInvoice')}
        </StyledButtonPrimary>
      );
    case HEADER_BUTTON_TYPE.EDIT_PURCHASE_ORDER:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t('editPurchaseOrder')}
        </StyledButton>
      );
    case HEADER_BUTTON_TYPE.EDIT_INVOICE:
      return (
        <StyledButton onClick={onClick} type="link" icon={<EdititingIcon />}>
          {t('editInvoice')}
        </StyledButton>
      );
    default:
      return null;
  }
}

CustomButton.defaultProps = {
  onClick: () => ({}),
  href: '',
};
