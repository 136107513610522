/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Avatar, Col, Row, Skeleton, Space } from 'antd';

import {
  CalendarOutlined,
  PushpinOutlined,
  UserOutlined,
} from '@ant-design/icons';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useSelector } from 'react-redux';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { EmployeeWelcomeScreen } from 'types/Employee';
import { UserAuthDataType } from 'redux/authUser/types';

interface IProps {
  data: EmployeeWelcomeScreen | undefined;
}

export default function WelcomeScreen({ data }: IProps) {
  const { authUser: userData } = useSelector(
    (state: { authUser: UserAuthDataType }) => state.authUser
  );

  if (typeof data === 'undefined') {
    return (
      <CardItem>
        <Skeleton title loading active paragraph={{ rows: 10, width: 0 }} />
      </CardItem>
    );
  }

  return (
    <CardItem
      title={`Welcome aboard ${userData?.employee?.firstName} ${userData?.employee?.lastName}`}
    >
      <Row gutter={[0, 20]}>
        <Col>
          <Styled.RowItem justify="start" gutter={[10, 0]}>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Row gutter={[0, 10]} align="middle">
                  <Col>
                    <Space align="center">
                      <Styled.ItemLabel>
                        We are so glad you have joined our team. You are about
                        to embark on an exciting journey of learning, growing,
                        and making a difference. At CompanyName, we believe that
                        people are our most valuable asset. That is why we want
                        to make sure you have a positive and productive
                        onboarding experience. Onboarding is the process of
                        introducing you to our organization, culture, and
                        values. It is also a time for you to get familiar with
                        your role, your team, and and your goals.
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        We have designed a simple and engaging onboarding
                        program that will help you:
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                </Row>

                <Row gutter={[0, 10]} align="middle">
                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        - Complete all the necessary paperwork and enroll in
                        benefits
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        - Learn about our mission, vision, and values
                      </Styled.ItemLabel>
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        - Meet your manager and coworkers
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        - Understand your expectations and responsibilities
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space align="center">
                      <Styled.ItemLabel>
                        We are thrilled to have you on board. Let us make this
                        the best first day ever! To get started, please click on
                        the button below. You will be taken to the first step of
                        the onboarding process.
                      </Styled.ItemLabel>
                    </Space>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Styled.RowItem>
        </Col>
        <Col span={24}>
          <Styled.RowItem justify="space-between" last>
            <Col>
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <Styled.ItemValue>Your First Day</Styled.ItemValue>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 10]} align="middle">
                    <Col span={24}>
                      <Space align="center">
                        <CalendarOutlined />
                        <Styled.ItemLabel>Your hire date:</Styled.ItemLabel>
                        <Styled.ItemLabel>
                          {convertUTCtoFormatedLocalTime(data?.hireDate)}
                        </Styled.ItemLabel>
                      </Space>
                    </Col>

                    <Col span={24}>
                      <Space align="center">
                        <PushpinOutlined />
                        <Styled.ItemLabel>Your location:</Styled.ItemLabel>
                        <Styled.ItemLabel>{data?.location}</Styled.ItemLabel>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Styled.ItemValue>Who to Contact</Styled.ItemValue>
                </Col>
                <Col span={24}>
                  <Row align="middle">
                    <Col>
                      <Avatar
                        icon={<UserOutlined />}
                        size="large"
                        shape="square"
                        className="bg-avatar"
                      />
                    </Col>
                    <Col offset={1}>
                      <Space direction="vertical" size="small">
                        <Styled.ItemValue>
                          {`${data?.firstName} ${data?.lastName}`}
                        </Styled.ItemValue>
                        <Styled.ItemLabel>HR - Role</Styled.ItemLabel>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Styled.RowItem>
        </Col>
      </Row>
    </CardItem>
  );
}
