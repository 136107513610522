// import { Tag } from 'antd';
import React from 'react';
import OpenPositionActions from 'components/Actions/OpenPositionActions';
import { OpenPositionType } from 'interfaces/OpenPosition';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import i18next from '../i18n';

export const OpenPositionsColumns = [
  {
    title: i18next.t('positionName'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a: OpenPositionType, b: OpenPositionType) =>
      a.name.localeCompare(b.name),
    render: (_: string, item: OpenPositionType) => (
      <Styled.TextBold>{item?.name}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('workPosition'),
    dataIndex: 'workPositions',
    key: 'workPositions',
    ellipsis: true,
    render: (_record: string, row: OpenPositionType) => (
      <Styled.Text key={`${row.id}`}>{row?.workPosition?.name}</Styled.Text>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('employmentType'),
    dataIndex: 'Employment Type',
    key: 'employmentType',
    render: (_record: string, row: OpenPositionType) => (
      <Styled.Text key={`${row.id}`}>{row?.employeeType?.name}</Styled.Text>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('validFrom'),
    dataIndex: 'validFrom',
    key: 'validFrom',
    render: (record: string) => convertUTCtoFormatedLocalTime(record),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('validTo'),
    dataIndex: 'validTo',
    key: 'validTo',
    // eslint-disable-next-line no-confusing-arrow
    render: (record: string) => convertUTCtoFormatedLocalTime(record),
    default: false,
    order: 4,
  },
  {
    title: i18next.t('action'),
    key: 'action',
    width: 120,
    render: (record: string, row: OpenPositionType) => (
      <OpenPositionActions id={row.id} />
    ),
    default: false,
    order: 5,
  },
];
