import { Reducer } from 'react';
import { Action } from 'redux';

import { Vendors, VendorsState } from './types';

const initialState: VendorsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<VendorsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Vendors.FETCH_VENDORS_START:
      state = { ...state, loading: true };
      break;
    case Vendors.FETCH_VENDORS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Vendors.FETCH_VENDORS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const singleVendorReducer: Reducer<VendorsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Vendors.FETCH_VENDOR_START:
      state = { ...state, loading: true };
      break;
    case Vendors.FETCH_VENDOR_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Vendors.FETCH_VENDOR_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Vendors.CLEAR_SINGLE_VENDOR:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as VendorsReducer };
export { singleVendorReducer as VendorReducer };
