import { Col, Row } from 'antd';
import React from 'react';
import { EmployeeType } from 'types/Employee';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import { EmployeeItem } from './EmployeeItem';

interface IProps {
  list: EmployeeType[];
  title: string;
  rightButton?: JSX.Element | undefined;
}

export default function EmployeeList({ list, title, rightButton }: IProps) {
  const { t } = useTranslation();

  return (
    <>
      <CardItem title={title} rightButton={rightButton}>
        <Row gutter={[0, 24]}>
          <Styled.RowItem gutter={[0, 10]} last>
            <Col
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <>
                {list && !list?.length && (
                  <Row>{t('thereAreNoEmployeesYet')}</Row>
                )}
              </>

              <>
                <Styled.RowItem last>
                  {list?.map((item: EmployeeType) => (
                    <EmployeeItem key={item.id} employee={item} />
                  ))}
                </Styled.RowItem>
              </>
            </Col>
          </Styled.RowItem>
        </Row>
      </CardItem>
    </>
  );
}
