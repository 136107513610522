import { Reducer } from 'react';
import { Action } from 'redux';

import { Projects, ProjectsState, SingleProjectState } from './types';

const initialState: ProjectsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
  employeeProject: {
    data: [],
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  },
  employeeAssignment: {
    data: [],
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  },
};

const ProjectsReducer: Reducer<ProjectsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Projects.FETCH_PROJECTS_START:
      state = { ...state, loading: true };
      break;
    case Projects.FETCH_PROJECTS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Projects.FETCH_PROJECTS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Projects.FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_START:
      state = {
        ...state,
        employeeAssignment: {
          ...state.employeeAssignment,
          loading: true,
        },
      };
      break;
    case Projects.FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        employeeAssignment: {
          ...state.employeeAssignment,
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
      break;
    case Projects.FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_ERRORED:
      state = {
        ...state,
        employeeAssignment: {
          ...state.employeeAssignment,
          loading: false,
          loaded: false,
          errored: true,
          error: action.payload,
        },
      };
      break;
    case Projects.FETCH_PROJECTS_OF_EMPLOYEE_START:
      state = {
        ...state,
        employeeProject: {
          ...state.employeeProject,
          loading: true,
        },
      };
      break;
    case Projects.FETCH_PROJECTS_OF_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        employeeProject: {
          ...state.employeeProject,
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
      break;
    case Projects.FETCH_PROJECTS_OF_EMPLOYEE_ERRORED:
      state = {
        ...state,
        employeeProject: {
          ...state.employeeProject,
          loading: false,
          loaded: false,
          errored: true,
          error: action.payload,
        },
      };
      break;
    case Projects.CLEAR_ALL_PROJECT_ASSIGNMENT_OF_EMPLOYEE:
      state = {
        ...state,
        employeeAssignment: { ...initialState.employeeAssignment },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleProjectState: SingleProjectState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SingleProjectReducer: Reducer<SingleProjectState, Action> = (
  state = initialSingleProjectState,
  action: any
) => {
  switch (action.type) {
    case Projects.FETCH_SINGLE_PROJECT_START:
      state = { ...state, loading: true };
      break;
    case Projects.FETCH_SINGLE_PROJECT_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Projects.FETCH_SINGLE_PROJECT_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Projects.CLEAR_SINGLE_PROJECT:
      state = initialSingleProjectState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { ProjectsReducer, SingleProjectReducer };
