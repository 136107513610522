/* eslint-disable indent */
import { getCandidateOpenPositionOffer } from 'api/candidateOfferService';
import {
  getAllCandidates,
  getCandidate,
  getCandidateOpenPositionStage,
} from 'api/candidateService';
import {
  createNewInterview,
  getCandidateInterviews,
} from 'api/interviewService';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { CandidateOpenPositionObj } from 'types/Candidates';
import { InterviewTypeDTO } from 'types/Interview';
import { CandidatesQueryParamsType } from 'types/QueryParams';
import { Candidates } from './types';
import i18next from '../../i18n';

export const fetchCandidates =
  (queryParams?: CandidatesQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Candidates.FETCH_CANDIDATES_START,
    });
    getAllCandidates(queryParams)
      .then((res) => {
        dispatch({
          type: Candidates.FETCH_CANDIDATES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Candidates.FETCH_CANDIDATES_ERRORED,
          payload: e,
        });
      });
  };

export const fetchCandidate = (candidateId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Candidates.FETCH_SINGLE_CANDIDATE_START,
  });
  getCandidate(candidateId)
    .then((res) => {
      dispatch({
        type: Candidates.FETCH_SINGLE_CANDIDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Candidates.FETCH_SINGLE_CANDIDATE_ERRORED,
        payload: e,
      });
    });
};

export const fetchCandidateOffer =
  (candidateOPId: string) => (dispatch: Dispatch) => {
    dispatch({ type: Candidates.FETCH_CANDIDATE_OFFERS_START });
    getCandidateOpenPositionOffer(candidateOPId)
      .then((res) => {
        dispatch({
          type: Candidates.FETCH_CANDIDATE_OFFERS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Candidates.FETCH_CANDIDATE_OFFERS_ERRORED,
          payload: e,
        });
      });
  };

export const clearCandidateOffers = () => (dispatch: Dispatch) => {
  dispatch({
    type: Candidates.CLEAR_CANDIDATE_OFFERS,
  });
};

export const clearSingleCandidate = () => (dispatch: Dispatch) => {
  dispatch({
    type: Candidates.CLEAR_SINGLE_CANDIDATE,
  });
};

export const scheduleNewInterview =
  (params: InterviewTypeDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: Candidates.SCHEDULE_INTERVIEW_START,
    });
    return createNewInterview(params)
      .then((res) => {
        dispatch({
          type: Candidates.SCHEDULE_INTERVIEW_SUCCESS,
          payload: res.data,
        });
        toast.success(i18next.t('interviewCreatedSuccessfully'));
        return res.data;
      })
      .catch((e) => {
        dispatch({
          type: Candidates.SCHEDULE_INTERVIEW_ERRORED,
          payload: e,
        });
        throw e;
      });
  };

export const clearScheduleInterview = () => (dispatch: Dispatch) => {
  dispatch({
    type: Candidates.CLEAR_SCHEDULE_INTERVIEW,
  });
};

export const setSelectedOpenPosition =
  (params: CandidateOpenPositionObj) => (dispatch: Dispatch) => {
    dispatch({
      type: Candidates.CANDIDATE_SELECTED_OPEN_POSITION,
      payload: params,
    });
  };

export const fetchCandidateOpenPositionInterviews =
  (candidateOpenPositionId: string) => (dispatch: Dispatch) => {
    dispatch({
      type: Candidates.FETCH_INTERVIEWS_START,
    });
    getCandidateInterviews(candidateOpenPositionId)
      .then((res) => {
        dispatch({
          type: Candidates.FETCH_INTERVIEWS_SUCCESSS,
          payload: {
            interviews: res.data,
            totInterview: res.data.length,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: Candidates.FETCH_INTERVIEWS_ERRORED,
          payload: e,
        });
      });
  };

export const clearInterviews = () => (dispatch: Dispatch) => {
  dispatch({
    type: Candidates.CLEAR_INTERVIEWS,
  });
};

export const fetchOpenPositionStage = () => (dispatch: Dispatch) => {
  dispatch({ type: Candidates.FETCH_OPEN_POSITION_STAGES_START });
  getCandidateOpenPositionStage()
    .then((res) => {
      dispatch({
        type: Candidates.FETCH_OPEN_POSITION_STAGES_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Candidates.FETCH_OPEN_POSITION_STAGES_ERRORED,
        payload: e,
      });
    });
};
