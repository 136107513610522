import React from 'react';
import styled from 'styled-components';
import { ImportOutlined } from '@ant-design/icons';

const StyledBtnContainer = styled.div`
  background: #ffffff;
  height: 35px;
  width: 35px;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items; center;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; // Ensure cursor remains pointer when hovering over hidden input
  }
 
  svg {
    transform: rotate(90deg);
    font-size: 16px;
    margin-top: 10px;
    transform: rotate(270deg)
  }

  
  :hover {
    cursor: pointer;
    opacity: 0.5;
    color: #004eeb !important;
    span {
      color: #004eeb !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;

interface DownloadTempalteProps {
  entity: string;
}

const handleLinkClick = () => {
  window.location.href = process.env.REACT_APP_ASSET_TEMPLATE_LINK as string;
};

function DownloadTemplate({ entity }: DownloadTempalteProps) {
  if (entity === 'assets') {
    return (
      <StyledBtnContainer>
        <ImportOutlined onClick={handleLinkClick} />
      </StyledBtnContainer>
    );
  }

  return null;
}

export default DownloadTemplate;
