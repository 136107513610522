import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import {
  AssetCategoryCreateDto,
  CreateSubAssetDto,
  AssetCategorySubcategoryCreateDto,
} from 'types/AssetCategories';
import {
  createAssetCategory,
  createAssetSubCategory,
} from 'api/assetCategories';

interface IProps {
  open: boolean;
  closeModal: () => void;
  setCategory: (categoryId: string, name: string) => void;
  setSubCategory: (subCategoryId: string, name: string) => void;
}

function CategorySubcategoryForm({
  open,
  closeModal,
  setCategory,
  setSubCategory,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  function onFormFinish(values: AssetCategorySubcategoryCreateDto) {
    const valuesToSend: AssetCategoryCreateDto = {
      ...values,
      categoryName: values.categoryName,
    };

    setLoading(true);

    createAssetCategory(valuesToSend)
      .then((res) => {
        if (res.status === 200) {
          setCategory(res?.data?.id, res?.data?.categoryName);

          const assetSubCategory: CreateSubAssetDto = {
            ...values,
            subCategoryName: values.subCategoryName,
            assetCategoryId: res?.data?.id,
          };

          createAssetSubCategory(assetSubCategory)
            .then((res) => {
              if (res.status === 200) {
                setSubCategory(res?.data?.id, res?.data?.subCategoryName);
              }
            })
            .catch(() => {
              toast.error(t('assetSubCategoryNotCreated'));
            })
            .finally(() => {
              setLoading(false);
            });
          closeModal();
        }
      })
      .catch(() => {
        toast.error(t('assetCategoryNotCreated'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const AssetCategoryFormConfiguration: FormConfigurationType[][] = [
    [
      {
        col: 24,
        offset: 0,
        name: 'name',
        label: t('assetCategoryName'),
        type: InputTypes.INPUT,
        rules: [
          {
            required: true,
            message: t('fieldRequired'),
          },
        ],
      },
      {
        col: 24,
        offset: 0,
        name: 'subCategoryName',
        label: t('assetSubCategory'),
        type: InputTypes.INPUT,
        rules: [
          {
            required: true,
            message: t('fieldRequired'),
          },
        ],
      },
    ],
  ];
  return (
    <GenericModal
      title={t('addAssetCategory')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={AssetCategoryFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default CategorySubcategoryForm;
