import { Col, Form, Row } from 'antd';
import {
  createCandidateOffer,
  getCandidateOffer,
  updateCandiadteOffer,
} from 'api/candidateOfferService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCandidateOffer } from 'redux/candidates/actions';
import CandidateOfferType, { CandidateOfferCreate } from 'types/CandidateOffer';
import { InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
} from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';

type ModalPropType = {
  open: boolean;
  offerId?: string | undefined;
  closeModal: () => void;
};

export default function CandidateOfferModal({
  open,
  offerId,
  closeModal,
}: ModalPropType) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<CandidateOfferType>();
  const { selectedCandidateOpenPosition } = useCandidateData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const { optionsType: employmentTypeOptions } = useGetSelectOptions({
    type: 'employement-type',
  });

  const FormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'validFrom',
          label: t('validFrom'),
          placeholder: 'Select a date',
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(offer?.validFrom),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            today: false,
            disabledDate: (current: moment.Moment) =>
              current && current.valueOf() < moment.now(),
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'validTo',
          label: t('validTo'),
          placeholder: t('selectExpirationDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(offer?.validTo),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('validFrom')),
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'salary',
          label: t('grossSalary'),
          type: 'selectPrefix',
          defaultValue: offer?.salary,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions,
            placeholder: '',
            defaultValue: offer ? offer?.currency?.id : currencyOptions[1]?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        },
        {
          col: 11,
          offset: 2,
          name: 'employmentTypeId',
          label: t('employmentType'),
          type: InputTypes.SELECT,
          selectOptions: employmentTypeOptions,
          defaultValue:
            offer &&
            employmentTypeOptions.find(
              (e) => e?.id === offer?.employmentType?.id
            )?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],

      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('offerDescription'),
          type: InputTypes.TEXTAREA,
          defaultValue: offer?.description,
        },
      ],
    ],
    [employmentTypeOptions, currencyOptions, offer]
  );

  function onSubmit(values: CandidateOfferCreate) {
    if (selectedCandidateOpenPosition?.data?.id === null) {
      return;
    }

    setLoading(true);

    const payload: CandidateOfferCreate = {
      ...values,
      salary: Number(values.salary),
      candidateOpenPositionId: selectedCandidateOpenPosition?.data
        ?.id as string,
      validFrom: convertDateToUTC(values.validFrom),
      validTo: convertDateToUTC(values.validTo),
    };

    if (offerId) {
      updateCandiadteOffer(offerId, payload)
        .then((respose) => {
          if (respose.status === 200) {
            toast.success('Successfully updated offer!');
            dispatch(
              fetchCandidateOffer(
                selectedCandidateOpenPosition?.data?.id as string
              )
            );
          }
        })
        .catch(() => {
          toast.error('Something went wrong, this offer could not be updated!');
        })
        .finally(() => {
          setLoading(false);
          form.resetFields();
          closeModal();
        });
      return;
    }

    createCandidateOffer(payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`${t('successfullyCreatedOffer')}!`);
          setLoading(false);
          form.resetFields();
          dispatch(
            fetchCandidateOffer(
              selectedCandidateOpenPosition?.data?.id as string
            )
          );
          closeModal();
        }
      })
      .catch(() => {
        toast.error(`${t('anOfferfortheGivenOpenPositionAlreadyExists')}!`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchOffer(offerId: string) {
    setLoading(true);
    getCandidateOffer(offerId)
      .then((respose) => {
        if (respose.status === 200) {
          setOffer(respose.data);
        }
      })
      .catch(() => {
        toast.error(t('failedtoGetOffer'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (offerId) {
      fetchOffer(offerId);
    }
  }, [offerId]);

  return (
    <GenericModal
      title={t('candidateOffer')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={FormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => closeModal()}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {offerId ? t('updateoffer') : t('submitOffer')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
