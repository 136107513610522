import { Reducer } from 'react';
import { Action } from 'redux';

import { Employees, EmployeesState } from './types';

const initialState: EmployeesState = {
  data: {
    data: [],
    meta: [],
  },
  documents: {
    data: [],
    loaded: false,
    loading: false,
    errored: false,
    error: null,
  },
  loaded: false,
  loading: false,
  errored: false,
  error: null,
  employeeDetail: null,
  employeeAssignments: null,
  employeeAssignmentsLoading: false,
  employeeAssignmentsLoaded: true,
};

const reducer: Reducer<EmployeesState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    // EMPLOYEES
    case Employees.FETCH_EMPLOYEES_START:
      state = { ...state, loading: true };

      break;

    case Employees.FETCH_EMPLOYEES_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case Employees.FETCH_EMPLOYEES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    // SPECIFIC EMPLOYEE DOCUMENTS
    case Employees.FETCH_EMPLOYEE_DOCUMENTS_START:
      state = {
        ...state,
        documents: { ...state.documents, loading: true, loaded: false },
      };
      break;
    case Employees.FETCH_EMPLOYEE_DOCUMENTS_SUCCESS:
      state = {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };
      break;
    case Employees.FETCH_EMPLOYEE_DOCUMENTS_ERRORED:
      state = {
        ...state,
        documents: {
          ...state.documents,
          error: action.payload,
          errored: true,
        },
      };
      break;
    // SPECIFIC EMPLOYEE DETAILS
    case Employees.FETCH_EMPLOYEES_DETAIL_START:
      state = { ...state, loading: true };
      break;
    case Employees.FETCH_EMPLOYEES_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        employeeDetail: action.payload,
      };
      break;
    case Employees.FETCH_EMPLOYEES_DETAIL_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    // SPECIFIC EMPLOYEE ASSIGNMENTS
    case Employees.FETCH_EMPLOYEE_ASSIGNMENTS_START:
      state = {
        ...state,
        employeeAssignmentsLoading: true,
        employeeAssignmentsLoaded: false,
      };
      break;
    case Employees.FETCH_EMPLOYEE_ASSIGNMENTS_SUCCESS:
      state = {
        ...state,
        employeeAssignmentsLoading: false,
        employeeAssignmentsLoaded: true,
        employeeAssignments: action.payload,
      };
      break;
    case Employees.FETCH_EMPLOYEE_ASSIGNMENTS_ERRORED:
      state = {
        ...state,
        employeeAssignmentsLoading: false,
        employeeAssignmentsLoaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Employees.CLEAR_SINGLE_EMPLOYEE:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as EmployeesReducer };
