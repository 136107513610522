/* eslint-disable indent */
import {
  getAllCertifications,
  getOneCertification,
} from 'api/certificationService';
import { Dispatch } from 'redux';
import { CertificationsQueryParamsType } from 'types/QueryParams';
import { Certifications } from './types';

export const fetchCertifications =
  (queryParams: CertificationsQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Certifications.FETCH_CERTIFICATIONS_START,
    });
    getAllCertifications(queryParams)
      .then((res) => {
        dispatch({
          type: Certifications.FETCH_CERTIFICATIONS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Certifications.FETCH_CERTIFICATIONS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleCertification =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: Certifications.FETCH_SINGLE_CERTIFICATION_START,
    });
    getOneCertification(id)
      .then((res) => {
        dispatch({
          type: Certifications.FETCH_SINGLE_CERTIFICATION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Certifications.FETCH_SINGLE_CERTIFICATION_ERRORED,
          payload: e,
        });
      });
  };

export const clearSingleCertification = () => (dispatch: Dispatch) => {
  dispatch({
    type: Certifications.CLEAR_SINGLE_CERTIFICATION,
  });
};
