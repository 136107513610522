/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Row } from 'antd';
import { CheckIcon } from 'Icons/CheckIcon';
import { CloseIcon } from 'Icons/CloseIcon';
import { Item } from 'ui-v2/components/PersonalInfo';
import { StatusTag } from 'ui-v2/components/DataGridComponents';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';
import { UpdateQuestionnaireAlternativeDTO } from 'types/Questionnaires';

export default function QuestionnaireInformation() {
  const { singleQuestionnaire, isPastDeadline } = useQuestionnairesData();

  const { t } = useTranslation();

  if (!singleQuestionnaire?.id) return null;

  return (
    <CardItem title={t('reviewInformation')}>
      <Row gutter={[0, 16]}>
        <Styled.RowItem gutter={[0, 10]}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('avgEmployeesScore')}
              value={singleQuestionnaire?.avgScore}
              children={undefined}
            />
          </Col>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Row>
              <Col span={24}>
                <Styled.ItemLabel>{t('deadline')}</Styled.ItemLabel>
              </Col>
              <StatusTag status={isPastDeadline ? t('yes') : t('no')}>
                {isPastDeadline ? <CheckIcon /> : <CloseIcon />}
                <p>{isPastDeadline ? t('yes') : t('no')}</p>
              </StatusTag>
            </Row>
          </Col>
        </Styled.RowItem>
        <Styled.RowItem gutter={[0, 10]}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('startDate')}
              value={
                singleQuestionnaire?.startDate &&
                convertUTCtoFormatedLocalTime(singleQuestionnaire?.startDate)
              }
              children={undefined}
            />
          </Col>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('endDate')}
              value={
                singleQuestionnaire?.endDate &&
                convertUTCtoFormatedLocalTime(singleQuestionnaire?.endDate)
              }
              children={undefined}
            />
          </Col>
        </Styled.RowItem>
        <Styled.RowItem gutter={[0, 10]}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('department')}
              value={
                singleQuestionnaire?.department?.map(
                  (dep: { name: string }) => dep?.name
                ) ?? ''
              }
              children={undefined}
            />
          </Col>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('employee')}
              value={
                singleQuestionnaire?.employee?.map(
                  (emp: { firstName: string; lastName: string }) =>
                    `${emp?.firstName} ${emp?.lastName}`
                ) ?? ''
              }
              children={undefined}
            />
          </Col>
        </Styled.RowItem>
        {singleQuestionnaire?.questionnaireQuestion?.map(
          (question: {
            content: string;
            questionnaireAlternative: Array<UpdateQuestionnaireAlternativeDTO>;
          }) => (
            <>
              <Styled.RowItem gutter={[0, 10]} last>
                <Item
                  label={t('question')}
                  value={question?.content}
                  children={undefined}
                />
              </Styled.RowItem>
              <React.Fragment key={question?.content}>
                {question?.questionnaireAlternative?.map(
                  (alternative: UpdateQuestionnaireAlternativeDTO) => (
                    <React.Fragment key={alternative?.content}>
                      <Styled.RowItem gutter={[0, 10]}>
                        <Col md={{ span: 12 }} sm={{ span: 24 }}>
                          <Item label={t('alternative')}>
                            {alternative?.content}
                          </Item>
                        </Col>
                        <Col md={{ span: 12 }} sm={{ span: 24 }}>
                          <Item label={t('score')}>{alternative?.score}</Item>
                        </Col>
                      </Styled.RowItem>
                    </React.Fragment>
                  )
                )}
              </React.Fragment>
            </>
          )
        )}
      </Row>
    </CardItem>
  );
}
