import React from 'react';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';

import { AUTH_ROLES } from 'types/Auth';
import { FallBackImage } from 'ui-v2/components/Tenant/utils';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { useTranslation } from 'react-i18next';
import {
  StyledSidebarLogoContent,
  StyledSidebarLogoImage,
  StyledSidebarLogoName,
} from './StyledSidebarLogo';

interface SiderabLogoProps {
  collapsed: boolean;
}

interface SidebarLogoNameProps {
  name?: string;
}

const SidebarLogoName: React.FC<SidebarLogoNameProps> = ({ name }) => {
  if (!name) {
    return null;
  }
  return (
    <Tooltip placement="bottom" title={name}>
      <StyledSidebarLogoName>{name}</StyledSidebarLogoName>
    </Tooltip>
  );
};

interface SidebarLogoImageProps extends SiderabLogoProps {
  src?: string;
}

const SidebarLogoImage: React.FC<SidebarLogoImageProps> = ({
  src,
  collapsed,
}) => (
  <StyledSidebarLogoImage
    collapsed={collapsed}
    src={src || FallBackImage}
    alt="tenant-logo"
  />
);

const SidebarLogo: React.FC<SiderabLogoProps> = ({ collapsed }) => {
  const { tenantPersisted: tenant } = useTenantsData();
  const { t } = useTranslation();
  const { authUser, authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const isSuperAdmin = authUserRole === AUTH_ROLES.SUPER_ADMIN;

  const fullName =
    authUser?.firstName && authUser?.lastName
      ? `${authUser?.firstName} ${authUser?.lastName}`
      : t('superAdmin');
  const logoName = isSuperAdmin ? fullName : tenant?.name;

  const src = isSuperAdmin ? authUser?.avatar : tenant?.logoContent;

  return (
    <StyledSidebarLogoContent collapsed={collapsed}>
      {src && <SidebarLogoImage src={src} collapsed={collapsed} />}
      {!collapsed && <SidebarLogoName name={logoName} />}
    </StyledSidebarLogoContent>
  );
};

export default SidebarLogo;
