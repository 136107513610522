import React from 'react';

import { StyledButton } from 'components/NewForms/FormStyled';

interface Props {
  name: string;
  label: string;
  buttonType: string | undefined;
  onClick: any;
}

export default function ButtonComponent({
  name,
  label,
  buttonType,
  onClick,
}: Props) {
  switch (buttonType) {
    case 'default':
      return (
        <StyledButton name={name} onClick={onClick} type="default">
          {label}
        </StyledButton>
      );
    case 'primary':
      return (
        <StyledButton name={name} onClick={onClick} type="primary">
          {label}
        </StyledButton>
      );
    default:
      return (
        <StyledButton name={name} onClick={onClick} type="default">
          {label}
        </StyledButton>
      );
  }
}
