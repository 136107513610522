import { Col, Typography } from 'antd';
import styled from 'styled-components';
import { VendorQuestionPriority } from 'types/VendorQuestionnaire';

export const StyledVendorQuestionnaireScoreCol = styled(Col)(({ theme }) => ({
  ...theme.typography.body.bold,
  padding: '1rem',
  borderRadius: '8px',
  border: `1px solid ${theme.colors.palette.primary.gray[200]}`,
  ':hover': {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.palette.primary.gray[300]}`,
  },
}));

export const StyledVendorQuestionnaireQuestionsTableText = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.body.bold,
  })
);

export const StyledVendorQuestionnaireQuestionsTablePriorityTag = styled(
  StyledVendorQuestionnaireQuestionsTableText
)<{
  priority: VendorQuestionPriority;
}>(({ theme, priority }) => ({
  ...theme.typography.body.bold,
  padding: '0.2rem .5rem',
  width: '5rem',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '4px',
  ...(priority === VendorQuestionPriority.STANDARD && {
    border: `1px solid ${theme.colors.palette.primary.success[600]}`,
    backgroundColor: theme.colors.palette.primary.success[600],
    color: theme.colors.palette.base.white,
  }),
  ...(priority === VendorQuestionPriority.CRITICAL && {
    backgroundColor: theme.colors.palette.primary.error[600],
    border: `1px solid ${theme.colors.palette.primary.error[600]}`,
    color: theme.colors.palette.base.white,
  }),
}));
