/* eslint-disable @typescript-eslint/no-namespace */
import { Dispatch } from 'redux';

import { VendorQuestionnaireApiClient } from 'api/vendorQuestionnaireService';
import { QueryParamsDTO } from 'types/QueryParams';
import { VendorQuestionnaireActionType } from './types';

export namespace VendorQuestionnaireAsyncAction {
  export const fetchQuestionnaires =
    (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
      dispatch({
        type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_START,
      });
      VendorQuestionnaireApiClient.getQuestionnaires(queryParams)
        .then((res) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SUCCESS,
            questionnaire: {
              list: {
                data: res?.data?.data,
                meta: res?.data?.meta,
              },
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_ERRORED,
            questionnaire: {
              error: e,
            },
          });
        });
    };

  export const fetchCategories =
    (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
      dispatch({
        type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_START,
      });
      VendorQuestionnaireApiClient.getCategories(queryParams)
        .then((res) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS,
            questionnaireCategory: {
              list: {
                data: res?.data?.data,
                meta: res?.data?.meta,
              },
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED,
            questionnaireCategory: {
              error: e,
            },
          });
        });
    };

  export const fetchCategorie =
    (payload: VendorQuestionnaireApiClient.CategoryRequest) =>
    (dispatch: Dispatch) => {
      dispatch({
        type: VendorQuestionnaireActionType.FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_START,
      });
      VendorQuestionnaireApiClient.getCategorie(payload)
        .then((res) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS,
            questionnaireCategory: {
              selectedQuestionnaireCategory: res?.data,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED,
            questionnaireCategory: {
              error: e,
            },
          });
        });
    };

  export const fetchScores =
    (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
      dispatch({
        type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SCORE_START,
      });
      VendorQuestionnaireApiClient.getScores(queryParams)
        .then((res) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SCORE_SUCCESS,
            questionaireScore: {
              list: {
                data: res?.data?.data,
                meta: res?.data?.meta,
              },
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: VendorQuestionnaireActionType.FETCH_VENDOR_QUESTIONNAIRE_SCORE_ERRORED,
            questionaireScore: {
              error: e,
            },
          });
        });
    };

  export const clearQuestionnaireState = () => (dispatch: Dispatch) => {
    dispatch({
      type: VendorQuestionnaireActionType.CLEAR_VENDOR_QUESTIONNAIRE_STATE,
    });
  };

  export const clearCategoriesState = () => (dispatch: Dispatch) => {
    dispatch({
      type: VendorQuestionnaireActionType.CLEAR_VENDOR_QUESTIONNAIRE_CATEGORY_STATE,
    });
  };

  export const clearSingleCategorieState = () => (dispatch: Dispatch) => {
    dispatch({
      type: VendorQuestionnaireActionType.CLEAR_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_STATE,
    });
  };

  export const clearScoreState = () => (dispatch: Dispatch) => {
    dispatch({
      type: VendorQuestionnaireActionType.CLEAR_VENDOR_QUESTIONNAIRE_SCORE_STATE,
    });
  };
}
