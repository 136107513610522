import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIpWhitelisting } from 'redux/ipAccess/actions';
import { IpAccess } from 'types/IpAccess';
import useHeader from './useHeader';

export function useIpWhitelistingData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.ipWhitelisting
  );
  const [searchParams] = useSearchParams();
  const { entity } = useHeader();
  const dispatch = useDispatch();

  useEffect(() => {
    if (entity === 'ip-whitelisting' && !loaded && !loading) {
      dispatch(fetchIpWhitelisting({ page: 1, take: 10 }));
    }
  }, []);

  return {
    list: data?.data ?? [],
    // pagination: data?.meta,
    ipAccess: data?.data?.find(
      (item: IpAccess) => item.id === searchParams.get('id')
    ),
    loading,
    loaded,
    errored,
    error,
  };
}
