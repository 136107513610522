import { Knowledge } from 'types/Knowledge';

export enum KnowledgeAction {
  FETCH_KNOWLEDGE_START = 'FETCH_KNOWLEDGE_START',
  FETCH_KNOWLEDGE_SUCCESS = 'FETCH_KNOWLEDGE_SUCCESS',
  FETCH_KNOWLEDGE_ERRORED = 'FETCH_KNOWLEDGE_ERRORED',

  CLEAR_KNOWLEDGE = 'CLEAR_KNOWLEDGE',
}

export interface KnowledgeState {
  data: Knowledge | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
