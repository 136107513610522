import moment from 'moment';
import { convertDateToUTC } from 'utils/utilFunctions';

export const formatTotalMinutes = (totalMinutes: number) => {
  if (totalMinutes === undefined || totalMinutes === null) {
    return '00:00';
  }

  const minutes = Math.floor(totalMinutes % 60);
  const hours = Math.floor(totalMinutes / 60);

  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;

  if (hours === 0) {
    return `00:${minutesStr}`;
  }

  const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
  return `${hoursStr}:${minutesStr}`;
};

export const getUTCConvertedDateTime = ({
  date,
  time,
  customTime,
}: {
  date: moment.Moment;
  time?: moment.Moment;
  customTime?: { hours: number; minutes: number; seconds: number };
}) =>
  convertDateToUTC(
    moment(date)
      .set('hours', customTime?.hours ?? moment(time).get('hours'))
      .set('minutes', customTime?.minutes ?? moment(time).get('minutes'))
      .set('seconds', customTime?.seconds ?? moment(time).get('seconds'))
  );
