import { RcFile } from 'antd/lib/upload';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { TenantDataType } from 'types/Tenant';
import {
  TenantConfigDescriptionDto,
  TenantConfigUpdateDTO,
  TenantConfiguration,
  TenantConfigurationDto,
} from 'types/TenantConfig';
import { Image as ImageType } from 'types/Image';

import {
  convertBase64,
  convertDateToUTC,
  getValidFileContent,
} from 'utils/utilFunctions';
import { dateFormatOptions } from 'utils/constants';
import { OptionType } from 'types/OptionTypes';
import moment from 'moment-timezone';
import { FormInstance } from 'antd';
import i18next from '../../../i18n';

export const getTenantOnboardingFormConfig = ({
  tenant,
  tenantConfig,
  handleVideoUrlChange,
  uploadAction,
}: {
  tenant: TenantDataType | null;
  tenantConfig: TenantConfiguration | null;
  handleVideoUrlChange?: (e: any) => void;
  uploadAction: (file: RcFile) => void;
}): FormConfigurationType[][] => {
  const formConfig: FormConfigurationType[][] = [
    [
      {
        col: 11,
        offset: 0,
        name: 'passwordAtempts',
        label: i18next.t('passwordAttempts'),
        type: InputTypes.INPUT,
        defaultValue:
          tenantConfig?.passwordAttempts && tenantConfig.passwordAttempts,
      },
      {
        col: 11,
        offset: 2,
        name: 'overtimePercentage',
        label: `${i18next.t('overTime')} (%)`,
        defaultValue: tenantConfig?.overtimePercentage,
        type: InputTypes.INPUT,
        inputProps: {
          type: 'number',
          min: 0,
        },
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
    ],
    [
      {
        col: 24,
        offset: 0,
        name: 'passwordRules',
        label: i18next.t('passwordRules'),
        type: InputTypes.TEXTAREA,
        defaultValue: tenantConfig?.passwordRules,
      },
    ],
    [
      {
        col: 24,
        offset: 0,
        name: 'upload',
        label: i18next.t('logo'),
        type: InputTypes.UPLOAD,
        defaultValue: tenantConfig?.logo,
        uploadProps: {
          accept: 'image/png, image/jpeg',
          beforeUpload: (file: RcFile) => uploadAction(file),
          maxCount: 1,
          listType: 'picture',
          defaultFileList: [
            {
              uid: '1',
              name: tenant?.name || '',
              url: tenantConfig?.logo || tenant?.logoContent,
            },
          ],
        },
      },
    ],
    [
      {
        col: 24,
        offset: 0,
        name: 'videoUrl',
        label: i18next.t('video'),
        type: InputTypes.INPUT,
        defaultValue: tenantConfig?.videoUrl,
        onChange: handleVideoUrlChange,
        inputProps: {
          type: 'text',
        },
      },
    ],
  ];
  return formConfig;
};

export const getTenantMissionVisionValuesFormConfig = (
  missionVisionValues?: string
): FormConfigurationType[][] => {
  const parsedValues: TenantConfigDescriptionDto =
    JSON.parse(missionVisionValues || '[]')?.at(0) || null;
  const config: FormConfigurationType[][] = [
    [
      {
        col: 24,
        offset: 0,
        name: 'mission',
        label: i18next.t('mission'),
        type: InputTypes.TEXTAREA,
        defaultValue: parsedValues?.mission,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 24,
        offset: 0,
        name: 'vision',
        label: i18next.t('vision'),
        type: InputTypes.TEXTAREA,
        defaultValue: parsedValues?.vision,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 24,
        offset: 0,
        name: 'values',
        label: i18next.t('values'),
        type: InputTypes.TEXTAREA,
        defaultValue: parsedValues?.values,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
    ],
  ];
  return config;
};

export const getTenantLocationPanelFormConfig = ({
  countryOptions,
  cityOptions,
  currencyOptions,
  onCountrySelect,
}: {
  form: FormInstance;
  countryOptions: OptionType[];
  cityOptions: OptionType[];
  currencyOptions: OptionType[];
  onCountrySelect?: (id: string, fieldName?: string) => void;
}): FormConfigurationType[][] => {
  const config: FormConfigurationType[][] = [
    [
      {
        col: 11,
        offset: 0,
        name: 'country',
        label: i18next.t('country'),
        type: InputTypes.SELECT,
        onSelect: onCountrySelect,
        selectOptions: countryOptions,
        showSearch: true,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 11,
        offset: 2,
        name: 'city',
        label: i18next.t('city'),
        type: InputTypes.SELECT,
        selectOptions: cityOptions,
        showSearch: true,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 24,
        offset: 0,
        name: 'streetName',
        label: i18next.t('streetName'),
        type: InputTypes.INPUT,
        showSearch: true,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 11,
        offset: 0,
        name: 'countryCode',
        label: i18next.t('countryCode'),
        type: InputTypes.INPUT,
        inputProps: {
          type: 'text',
        },
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 11,
        offset: 2,
        name: 'postalCode',
        label: i18next.t('postalCode'),
        type: InputTypes.INPUT,
        inputProps: {
          type: 'number',
          min: 0,
        },
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
    ],
    [
      {
        col: 24,
        offset: 0,
        name: 'timezone',
        label: i18next.t('timezone'),
        type: InputTypes.SELECT,
        showSearch: true,
        selectOptions: moment.tz.names().map((name) => ({
          label: name,
          value: name,
          id: name,
        })),
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 11,
        offset: 0,
        name: 'workingHourStart',
        label: i18next.t('workdaystartTime'),
        type: InputTypes.TIMEPICKER,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 11,
        offset: 2,
        name: 'workingHourEnd',
        label: i18next.t('workdayendTime'),
        type: InputTypes.TIMEPICKER,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
    ],
    [
      {
        col: 11,
        offset: 0,
        name: 'currency',
        label: i18next.t('currency'),
        type: InputTypes.SELECT,
        selectOptions: currencyOptions,
        showSearch: true,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      {
        col: 11,
        offset: 2,
        name: 'dateTimeFormat',
        label: i18next.t('dateTimeFormat'),
        type: InputTypes.SELECT,
        selectOptions: dateFormatOptions,
        showSearch: true,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
    ],
  ];
  return config;
};

export async function uploadTenantConfigAction(
  file: RcFile,
  callbackFn: (file: ImageType) => void
) {
  const base64 = (await convertBase64(file)) as string;
  const validBase64 = getValidFileContent(base64);
  callbackFn({ name: file.name, content: validBase64, type: file.type });
  return false;
}

export const getTenantConfigDto = ({
  formValues,
  tenantConfig,
  fileToUpload,
}: {
  formValues: any;
  tenantConfig: TenantConfiguration;
  fileToUpload?: ImageType;
}) => {
  delete tenantConfig?.logo;
  let valuesToSubmit: TenantConfiguration = {
    ...tenantConfig,
  };
  valuesToSubmit = {
    ...valuesToSubmit,
    ...(formValues?.videoUrl && { videoUrl: formValues?.videoUrl }),
    ...(fileToUpload && {
      logo: fileToUpload,
    }),
    ...(formValues?.passwordRules && {
      passwordRules: formValues.passwordRules,
    }),
    ...(formValues?.passwordAtempts && {
      passwordAtempts: formValues.passwordAtempts,
    }),
    ...(formValues?.overtimePercentage && {
      overtimePercentage: parseInt(formValues.overtimePercentage, 10),
    }),
  };
  return valuesToSubmit as TenantConfigUpdateDTO;
};

export const getTenantLocationConfigValues = ({
  panelKey,
  formValues,
}: {
  panelKey: string;
  formValues: any;
}) => {
  const companyDescriptionPanelKeys =
    Object.keys(formValues)?.filter((key) => key?.includes(panelKey)) || [];
  let indexes = companyDescriptionPanelKeys?.map(
    (key) => key?.split(`${panelKey}-`)?.[1]?.split('-')?.[0]
  );
  indexes = Array.from(new Set(indexes ?? []));

  const companyDescriptionValues = indexes
    .map((index) => {
      if (!formValues?.[`${panelKey}-${index}-currency`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-dateTimeFormat`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-timezone`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-workingHourStart`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-workingHourEnd`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-city`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-country`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-countryCode`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-postalCode`]) {
        return null;
      }
      if (!formValues?.[`${panelKey}-${index}-streetName`]) {
        return null;
      }
      return {
        config: {
          currency: formValues?.[`${panelKey}-${index}-currency`],
          dateTimeFormat: formValues?.[`${panelKey}-${index}-dateTimeFormat`],
          timezone: formValues?.[`${panelKey}-${index}-timezone`],
          workingHourStart: convertDateToUTC(
            formValues?.[`${panelKey}-${index}-workingHourStart`]
          ),
          workingHourEnd: convertDateToUTC(
            formValues?.[`${panelKey}-${index}-workingHourEnd`]
          ),
        },
        locationConfiguration: {
          city: formValues?.[`${panelKey}-${index}-city`],
          country: formValues?.[`${panelKey}-${index}-country`],
          countryCode: formValues?.[`${panelKey}-${index}-countryCode`],
          postalCode: formValues?.[`${panelKey}-${index}-postalCode`],
          streetName: formValues?.[`${panelKey}-${index}-streetName`],
        },
      };
    })
    .filter(Boolean) as TenantConfigurationDto[];

  return companyDescriptionValues ?? [];
};
