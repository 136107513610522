import agent from 'api/agent';

export const getOptionType = (type: string) => {
  switch (type) {
    case 'stages':
      return agent.post(`/${type}/get`);
    case 'open-positions/admin':
      return agent.post(`/${type}/get`);
    case 'certifications':
      return agent.post(`/${type}/get`);
    case 'employement-type':
      return agent.post(`/${type}/get`);
    case 'work-position':
      return agent.post(`/${type}/get`);
    case 'vendor-category':
      return agent.post(`/${type}/get`);
    case 'project':
      return agent.post(`/${type}/get`);
    case 'vendor':
      return agent.post(`/${type}/get`);
    case 'purchase-order':
      return agent.post(`/${type}/get`);
    default:
      return agent.get(`/${type}`);
  }
};
