import React, { useEffect, useState } from 'react';
import { Col, Result, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SuccessFeedBack() {
  const [count, setCount] = useState<number>(5);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => count - 1);
    }, 1000);
    if (count === 0) {
      navigate('/login');
    }
    return () => clearInterval(interval);
  }, [count]);
  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <Result
          status="success"
          title=""
          subTitle={`${t('Password was set successfully!')} ${t(
            'You will be redirected in'
          )} ${count} ${t('seconds')}!`}
        />
      </Col>
    </Row>
  );
}
