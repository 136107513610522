import styled from 'styled-components';
import { Button, Row, Typography } from 'antd';

export const List = styled.div`
  width: 100%;
  position: relative;
  overflow-y: scroll;
  max-height: 200px;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

export const RowItemAll = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
`;

export const RowItem = styled(Row)<{
  last?: boolean;
}>`
  border-bottom: ${(props) => (props.last ? '' : '1px solid #eaecf0')};
  padding-bottom: ${(props) => (props.last ? '' : '16px')};
  width: 100%;
`;

export const ButtonStyle = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #344054;
  &:hover svg {
    filter: invert(57%) sepia(26%) saturate(1848%) hue-rotate(180deg)
      brightness(101%) contrast(102%);
  }
`;

export const ItemLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
`;

export const ItemCompany = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #155eef;
`;

export const ItemValue = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
`;

export const NodataLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;

export const Link = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  font-style: italic;
  a {
    padding-left: 5px;
  }
`;
export const PlusIcon = styled(Typography.Text)`
  margin: 8px;
`;

export const TaskName = styled(Typography.Text)`
overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '120px',
`;
