import { Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Styled from 'styled-components';

interface IProps {
  main: string;
  seccond: string;
  employeeId: string;
}

const StyledDiv = Styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export default function FirsRow({ main, seccond, employeeId }: IProps) {
  const navigate = useNavigate();

  return (
    <StyledDiv onClick={() => navigate(`employee-profile?id=${employeeId}`)}>
      <Typography
        style={{ fontWeight: 600, color: '#101828', cursor: 'pointer' }}
        className="main"
      >
        {main}
      </Typography>
      <Typography className="ant-table-cell">{seccond}</Typography>
    </StyledDiv>
  );
}
