import React from 'react';
import { Col, Popconfirm, Row } from 'antd';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import styled from 'styled-components';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { useTranslation } from 'react-i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  id: string;
  isHired: boolean;
};

const InterviewAction: React.FC<Props> = ({ id, isHired }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  function editInterview() {
    if (isHired) {
      toast.warning(t('This candidate is hired!'));
    } else {
      searchParams.append('edit-interview', id);
      setSearchParams(searchParams);
    }
  }

  function deleteInterviewById() {
    searchParams.append('delete-interview', id);
    setSearchParams(searchParams);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="space-around">
            <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
              <Col>
                <IconButton onClick={editInterview}>
                  <EdititingIcon />
                </IconButton>
              </Col>
            </RoleBasedButton>
            <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
              <Col>
                <Popconfirm
                  placement="topLeft"
                  title={t('doYouWantToDeleteThisInterview?')}
                  onConfirm={deleteInterviewById}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <StyledIcon>
                    <DeleteIcon />
                  </StyledIcon>
                </Popconfirm>
              </Col>
            </RoleBasedButton>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InterviewAction;
