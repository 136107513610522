import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { VendorType } from 'types/Vendor';
import { QueryParamsType } from 'types/QueryParams';
import agent from './agent';
import { apiURLs } from './constants';

export const getVendors = (payload: QueryParamsType) =>
  agent.post(`${apiURLs.vendor}/get`, searchFields(payload, apiURLs.vendor));
export const getVendor = (id: string) => agent.get(`${apiURLs.vendor}/${id}`);

export const updateVendor = (id: string, vendorData: VendorType) =>
  agent.patch(`${apiURLs.vendor}/${id}`, vendorData);

export const deleteVendor = (id: string) =>
  agent.delete(`${apiURLs.vendor}/${id}`);

export const createVendor = (vendorData: VendorType) =>
  agent.post(apiURLs.vendor, vendorData);

export const getVendorById = (vendorId: string) =>
  agent.get(`${apiURLs.vendor}/${vendorId}`);

export const getVendorCategory = (payload: string) =>
  agent.post(
    `/${apiURLs.vendorCategories}/get`,
    searchFields(payload, apiURLs.vendorCategories)
  );
