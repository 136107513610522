import styled from 'styled-components';

import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

export const StyledCircleOutlinedIcon = styled(PlusCircleOutlined)`
  color: #ff4d4f !important;
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: green !important;
`;
