import { Questionnaires } from 'types/Questionnaires';

export enum QuestionnairesActions {
  FETCH_QUESTIONNAIRES_START = 'FETCH_QUESTIONNAIRES_START',
  FETCH_QUESTIONNAIRES_SUCCESS = 'FETCH_QUESTIONNAIRES_SUCCESS',
  FETCH_QUESTIONNAIRES_ERRORED = 'FETCH_QUESTIONNAIRES_ERRORED',

  FETCH_SINGLE_QUESTIONNAIRE_START = 'FETCH_SINGLE_QUESTIONNAIRE_START',
  FETCH_SINGLE_QUESTIONNAIRE_SUCCESS = 'FETCH_SINGLE_QUESTIONNAIRE_SUCCESS',
  FETCH_SINGLE_QUESTIONNAIRE_ERRORED = 'FETCH_SINGLE_QUESTIONNAIRE_ERRORED',

  CLEAR_SINGLE_QUESTIONNAIRE = 'CLEAR_SINGLE_QUESTIONNAIRE',
  CLEAR_ALL_QUESTIONNAIRES = 'CLEAR_ALL_QUESTIONNAIRES',
}

export interface QuestionnairesState {
  data: Array<Questionnaires>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface QuestionnaireSingleState {
  data: Questionnaires | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
