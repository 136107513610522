import { Col, Popconfirm, Row, Tooltip } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Id } from 'types/Id';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import { useTranslation } from 'react-i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const BenefitsActions: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const onEditClick = () => {
    searchParams.set('edited-benefit-template', id);
    setSearchParams(searchParams);
  };

  const onDeleteClick = () => {
    searchParams.set('deleted-benefit-template', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('editBenefit')}>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title={`${t('deleteThisBenefit')}?`}
              onConfirm={onDeleteClick}
              okText={t('yes')}
              cancelText={t('no')}
              placement="left"
            >
              <Tooltip title={t('deleteBenefit')}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BenefitsActions;
