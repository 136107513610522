import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { QuestionnaireAssignmentDTO } from 'types/QuestionnaireAssignment';
import { apiURLs } from './constants';

export const getSingleQuestionnaireAssignment = (id: string) =>
  agent.get(`${apiURLs.questionnaireAssignment}/${id}`);

export const getQuestionnaireAssignment = (id: string) =>
  agent.get(`${apiURLs.questionnaireAssignment}/questionnaire/${id}`);

export const getQuestionnaireAssignmentByReviwerId = (reviewerId: string) =>
  agent.get(
    `${apiURLs.questionnaireAssignment}/feedbackReceiver/${reviewerId}`
  );

export const getQuestionnaireAssignments = (payload?: QueryParamsDTO) => {
  const p = searchFields(payload, apiURLs.questionnaires);
  return agent.post(`${apiURLs.questionnaireAssignment}/get`, p);
};

export const createQuestionnaireAssignment = (
  payload: QuestionnaireAssignmentDTO
) => agent.post(apiURLs.questionnaireAssignment, payload);

export const getQuestionnaireFeedback = (
  id: string,
  revieweeId: string,
  reviewerId: string
) =>
  agent.post(`${apiURLs.questionnaireAssignment}/feedback/${id}`, {
    revieweeId,
    reviewerId,
  });

export const removeQuestionnaireAssignment = (id: string) =>
  agent.delete(`${apiURLs.questionnaireAssignment}/${id}`);

export const updateQuestionnaireAssignment = (
  id: string,
  payload: QuestionnaireAssignmentDTO
) => agent.put(`${apiURLs.questionnaireAssignment}/${id}`, payload);
