import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { IpAccess } from 'types/IpAccess';
import IpWhitelistingAction from 'components/Actions/IpWhitelistingAction';
import i18next from '../i18n';

export const IpWhitelistingColumns = [
  {
    title: i18next.t('singleIp'),
    dataIndex: 'singleIP',
    key: 'singleIP',
    ellipsis: true,
    sorter: (a: IpAccess, b: IpAccess) => a.singleIP.localeCompare(b.singleIP),
    render: (_: string, item: IpAccess) => (
      <Styled.TextBold>{item?.singleIP || 'No single IP'}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('range'),
    dataIndex: 'range',
    key: 'range',
    render: (_: string, item: IpAccess) => (
      <Styled.TextBold>{`${item?.range || ''}`}</Styled.TextBold>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('wildcard'),
    dataIndex: 'wildcard',
    key: 'wildcard',
    render: (_: string, item: IpAccess) => (
      <Styled.TextBold>{item?.wildcard || 'No wildcard'}</Styled.TextBold>
    ),
    default: true,
    order: 2,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: IpAccess) => <IpWhitelistingAction id={row.id} />,
    default: false,
    order: 3,
  },
];
