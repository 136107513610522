import React from 'react';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { useTranslation } from 'react-i18next';
import { getTrackingPopoverFormatedDate } from './utils';
import { StyledTrackingDateFilterDateLabel } from './StyledTrackingDateFilter';

interface TrackingDateFilterLabelProps {
  isPopoverOpen?: boolean;
}

const TrackingDateFilterLabel: React.FC<TrackingDateFilterLabelProps> = ({
  isPopoverOpen = false,
}) => {
  const {
    urlState: { startDate, endDate },
  } = useTrackedHoursData();
  const { t } = useTranslation();

  const label = getTrackingPopoverFormatedDate({
    open: isPopoverOpen,
    urlStart: startDate,
    urlEnd: endDate,
  });

  return (
    <StyledTrackingDateFilterDateLabel ellipsis>
      {t(label)}
    </StyledTrackingDateFilterDateLabel>
  );
};

export default TrackingDateFilterLabel;
