/* eslint-disable react/no-children-prop */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import {
  convertDateToUTC,
  convertUTCtoFormatedLocalTime,
} from 'utils/utilFunctions';
import { OnboardingTaskModelType } from 'types/OnboardingTask';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { useTranslation } from 'react-i18next';
import { IconButton, StatusTagLabel } from './OnBoardingTaskStyles';

interface IProps {
  onBoardingTask: OnboardingTaskModelType;
  onEdit: () => void;
  onDelete: () => void;
}

export default function OnBoardingTaskDetails({
  onBoardingTask,
  onEdit,
  onDelete,
}: IProps) {
  const duration = convertDateToUTC(onBoardingTask?.duration);
  const durationtoLocaleTime = convertUTCtoFormatedLocalTime(duration);
  const taskStatus =
    onBoardingTask.status.charAt(0).toUpperCase() +
    onBoardingTask.status.slice(1);

  const { t } = useTranslation();

  return (
    <>
      <CardItem
        title={
          <Row justify="start">
            <StatusTagLabel
              status={onBoardingTask.status || ''}
              style={{ borderRadius: '16px' }}
            >
              {taskStatus}
            </StatusTagLabel>
          </Row>
        }
        rightButton={
          <Row justify="end">
            <Col span={7}>
              <Row justify="space-around">
                <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
                  <IconButton onClick={onEdit}>
                    <EditOutlined />
                  </IconButton>
                </RoleBasedButton>
                <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
                  <Popconfirm
                    placement="topLeft"
                    title={t('areYouSureYouWanttoDelete')}
                    onConfirm={onDelete}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <IconButton>
                      <DeleteOutlined />
                    </IconButton>
                  </Popconfirm>
                </RoleBasedButton>
              </Row>
            </Col>
          </Row>
        }
      >
        <Row gutter={[0, 16]}>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('taskType')}
                value={onBoardingTask?.boardingTaskType || 'No Type'}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              {onBoardingTask?.duration ? (
                <Item
                  label={t('deadline')}
                  value={durationtoLocaleTime}
                  children={undefined}
                />
              ) : (
                'No deadline'
              )}
            </Col>
          </Styled.RowItem>
          <Styled.RowItem last>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('category')}
                value={onBoardingTask?.category || 'No category'}
                children={undefined}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('mentor')}
                value={`${
                  onBoardingTask?.mentor
                    ? `${onBoardingTask?.mentor?.firstName} ${onBoardingTask?.mentor?.lastName}`
                    : t('noMentor')
                }`}
                children={undefined}
              />
            </Col>
          </Styled.RowItem>
        </Row>
      </CardItem>
    </>
  );
}
