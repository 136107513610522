import TenantAccountRegistrationForm from 'components/NewForms/TenantAccountRegistrationForm';
import React from 'react';
import GenericModal from 'ui-v2/components/GenericModal';

interface RegisterTenantAccountProps {
  onClose: () => void;
}

const RegisterTenantAccount: React.FC<RegisterTenantAccountProps> = ({
  onClose,
}) => (
  <GenericModal open title="New account" closeModal={onClose}>
    <TenantAccountRegistrationForm onSubmitted={onClose} />
  </GenericModal>
);

export default RegisterTenantAccount;
