import { Button, Col, Row, Skeleton } from 'antd';
import React, { useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import { EdititingIcon } from 'Icons/EditIcon';
import * as Styled from './CandidateNotesStyles';

import AddCandidateNote from './AddCandidateNote';

interface CandidateNoteProps {
  isDisabled: boolean;
}

export default function CandidateNote({ isDisabled }: CandidateNoteProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const { candidate, loading } = useCandidateData();
  const { t } = useTranslation();

  const onToggleModal = () => setModalVisible((state) => !state);

  return (
    <CardItem title={t('notes')} disabled={isDisabled}>
      <>
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={{ rows: 6, width: '100%' }}
        >
          <Styled.NoteItem gutter={[0, 24]} justify="center" align="middle">
            {candidate?.notes && (
              <Col span={24}>
                <Row justify="start" gutter={10}>
                  <Col>
                    <Styled.NoteName>{candidate?.notes}</Styled.NoteName>
                  </Col>
                </Row>
              </Col>
            )}
            {!candidate?.notes && (
              <Col>
                <Styled.Label>{`${t('assignNoteToCandidate')}!`}</Styled.Label>
              </Col>
            )}
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col span={24}>
                <Button
                  icon={<EdititingIcon />}
                  size="large"
                  style={{
                    borderRadius: '8px',
                    width: '100%',
                    fontWeight: '600',
                    fontSize: '16px',
                    color: '#344054',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                  onClick={onToggleModal}
                >
                  {candidate?.notes ? t('editNote') : t('addNote')}
                </Button>
              </Col>
            </RoleBasedButton>
          </Styled.NoteItem>
          {modalVisible && (
            <AddCandidateNote
              open={modalVisible}
              closeModal={() => setModalVisible(false)}
            />
          )}
        </Skeleton>
      </>
    </CardItem>
  );
}
