import { Dispatch } from 'redux';
import { getHrDuties } from 'api/hrDuties';
import {
  CHECK_HR_DUTIES_COMPLETION_REQUEST,
  CHECK_HR_DUTIES_COMPLETION_SUCCESS,
  CHECK_HR_DUTIES_COMPLETION_FAILURE,
  CLOSE_BANNER,
} from './types';

export const checkHrDutiesCompletion = () => (dispatch: Dispatch) => {
  dispatch({
    type: CHECK_HR_DUTIES_COMPLETION_REQUEST,
  });
  getHrDuties()
    .then((res) => {
      dispatch({
        type: CHECK_HR_DUTIES_COMPLETION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: CHECK_HR_DUTIES_COMPLETION_FAILURE,
        payload: e,
      });
    });
};

export const closeBanner = () => ({
  type: CLOSE_BANNER,
});
