import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { fetchCandidates } from 'redux/candidates/actions';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { CandidatesQueryParamsType } from 'types/QueryParams';
import { fetchOpenPositionOptions } from 'components/NewForms/SelectWithLoad/utils';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';
import i18next from '../../../../i18n';

export default function CandidateFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();

  async function onFormChange() {
    const openPosition: string =
      form.getFieldValue('openPositionId')?.value ?? '';

    const filterCandidatesTable: CandidatesQueryParamsType = {
      openPosition,
    };
    dispatch(fetchCandidates(filterCandidatesTable));
  }

  const CandidateFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'openPositionId',
          label: i18next.t('openPosition'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchOpenPositionOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={CandidateFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchCandidates());
            }}
          >
            {i18next.t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
