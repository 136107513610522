import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { EmployeeAssignmnetsType, EmployeeType } from 'types/Employee';
import { EMPLOYEE_ID } from 'utils/constants';

export function useEmployeesData() {
  const {
    data,
    documents,
    loading,
    loaded,
    errored,
    error,
    employeeDetail,
    employeeAssignments,
    employeeAssignmentsLoaded,
    employeeAssignmentsLoading,
  } = useSelector((state: any) => state.employees);
  const [searchParams] = useSearchParams();

  const employeeId =
    searchParams.get('id') || (localStorage.getItem(EMPLOYEE_ID) as string);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    employeeId,
    employee:
      (employeeDetail as EmployeeType) ||
      data?.employees?.find(
        (item: EmployeeType) => item.id === searchParams.get('id')
      ),
    employeeBasicProfile: data?.data?.find(
      (item: EmployeeType) => item.id === searchParams.get('id')
    ),
    loading,
    loaded,
    error,
    errored,
    employeeDetail,
    employeeAssignments: employeeAssignments as EmployeeAssignmnetsType,
    employeeAssignmentsLoaded,
    employeeAssignmentsLoading,
    documents,
  };
}
