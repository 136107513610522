import agent from 'api/agent';

import { InterviewTypeDTO } from 'types/Interview';
import { InterviewsQueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllInterviews = async (
  queryParams?: InterviewsQueryParamsType
) =>
  agent.post(
    `${apiURLs.interviews}/get`,
    searchFields(queryParams, apiURLs.interviews)
  );

export const getCandidateInterviews = async (candidateOpenPositionId: string) =>
  agent.get(
    `${apiURLs.interviews}/candidateOpenPosition/${candidateOpenPositionId}`
  );

export const searchInterviews = (searchText: string | null) =>
  agent.get(`${apiURLs.interviews}?comment=${searchText}`);

export const getOneInterview = async (id: string) =>
  agent.get(`${apiURLs.interviews}/${id}`);

export const createNewInterview = (values: InterviewTypeDTO) =>
  agent.post(apiURLs.interviews, values);

export const updateInterview = async (id: string, values: InterviewTypeDTO) =>
  agent.patch(`${apiURLs.interviews}/${id}`, values);

export const deleteInterview = async (id: string) =>
  agent.delete(`${apiURLs.interviews}/${id}`);
