import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Item } from 'ui-v2/components/PersonalInfo';
import { usePurchaseOrdersData } from 'ui-v2/hooks/usePurchaseOrdersData';
import { fetchSinglePurchaseOrder } from 'redux/purchaseOrders/action';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';

export default function PurchaseOrderDetails() {
  const { purchaseOrder } = usePurchaseOrdersData();
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get('id') as string;

  useEffect(() => {
    dispatch(fetchSinglePurchaseOrder(id));
  }, [id]);

  useEffect(() => {
    if (searchParams.get('edit-purchase-order')) {
      setModalVisible(true);
      searchParams.delete('edit-purchase-order');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  if (!purchaseOrder) return null;

  return (
    <>
      <Content position="grid">
        <>
          <>
            <CardItem title={t('purchaseOrderDetails')}>
              <Row gutter={[0, 16]}>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('orderDate')}
                      value={convertUTCtoFormatedLocalTime(
                        purchaseOrder?.orderDate
                      )}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('purchaseOrderNumber')}
                      value={purchaseOrder?.purchaseOrderNumber}
                    />
                  </Col>
                </Styled.RowItem>

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('vendorCompanyName')}
                      value={purchaseOrder?.vendorCompanyName}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('vendorAddress')}
                      value={purchaseOrder?.vendorAddress}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('vendorPhoneNumber')}
                      value={`${purchaseOrder?.vendorPhoneNumberPrefix} ${purchaseOrder?.vendorPhoneNumber}`}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('vendorEmail')}
                      value={purchaseOrder?.vendorEmail}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('receiverCompanyName')}
                      value={purchaseOrder?.receiverCompanyName}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('receiverName')}
                      value={purchaseOrder?.receiverName}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('receiverAddress')}
                      value={purchaseOrder?.receiverAddress}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('receiverPhoneNumber')}
                      value={`${purchaseOrder?.receiverPhoneNumberPrefix} ${purchaseOrder?.receiverPhoneNumber}`}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('receiverEmail')}
                      value={purchaseOrder?.receiverEmail}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('tenderNumber')}
                      value={purchaseOrder?.tenderNumber}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item label={t('item')} value={purchaseOrder?.item} />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('pricePerUnit')}
                      value={`${purchaseOrder?.currency?.symbol} ${purchaseOrder?.pricePerUnit}`}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('quantity')}
                      value={purchaseOrder?.quantity}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('totalPrice')}
                      value={`${purchaseOrder?.currency?.symbol} ${purchaseOrder?.totalPrice}`}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('description')}
                      value={purchaseOrder?.description}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item label={t('vat')} value={purchaseOrder?.vat} />
                  </Col>
                </Styled.RowItem>
              </Row>
            </CardItem>
          </>
          <></>
        </>
      </Content>

      {modalVisible &&
        navigate(`/purchase-orders/edit-purchase-order?id=${id}`)}
    </>
  );
}
