import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useHeader from 'ui-v2/hooks/useHeader';
import useIdeaboxData from 'ui-v2/hooks/useIdeaboxData';
import { fetchIdeabox } from 'redux/ideabox/actions';
import { removeIdeabox } from 'api/ideaboxService';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { Ideabox } from 'types/Ideabox';
import { AUTH_ROLES } from 'types/Auth';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

interface IdeaboxActionsProps {
  id: string;
  data: Ideabox;
}

const IdeaboxActions: React.FC<IdeaboxActionsProps> = ({ id, data }) => {
  const { take } = useHeader();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pagination } = useIdeaboxData();

  const { authUserRole, authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const canEdit: boolean =
    authUserRole === AUTH_ROLES.AMDIN || authUserRole === AUTH_ROLES.HR
      ? true
      : data?.employee?.id === authUser?.employee?.id;

  const onViewClick = () => {
    navigate(`ideabox-details?id=${id}`);
  };

  const onEditClick = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'edit-ideabox',
        value: id,
      })
    );
  };

  const onDeleteClick = async () => {
    const response = await removeIdeabox(id);

    if (response.status === 200) {
      toast.success(t('deletedIdeabox'));
      dispatch(
        fetchIdeabox({
          pageOptions: { page: pagination?.page || 1, take },
        })
      );
      return;
    }

    toast.warning(t('couldNotDeleteThisIdeabox'));
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <IconButton onClick={onViewClick}>
              <ViewProfileIcon />
            </IconButton>
          </Col>

          {canEdit && (
            <>
              <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
                <Col>
                  <IconButton onClick={onEditClick}>
                    <EdititingIcon />
                  </IconButton>
                </Col>
              </RoleBasedButton>
              <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
                <Col>
                  <Popconfirm
                    placement="topLeft"
                    title={t('areYouSureYouWantToDeleteThisIdeabox')}
                    onConfirm={onDeleteClick}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <StyledIcon>
                      <DeleteIcon />
                    </StyledIcon>
                  </Popconfirm>
                </Col>
              </RoleBasedButton>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default IdeaboxActions;
