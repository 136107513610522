import { Row, Typography } from 'antd';
import styled from 'styled-components';
import { TimeOffStatus } from 'types/TimeOff';

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #cff9fe;
`;

export const RowItem = styled(Row)`
  background: #cff9fe;
`;

export const TimeLabel = styled(Typography.Text)`
  display: block;
  text-align: center;
  font-weight: 300;
`;
export const FullDayLabel = styled(Typography.Text)`
  font-weight: 300;
`;
export const HolidayCell = styled.div<{ selected?: boolean }>`
  background-color: ${(props) => (props.selected ? 'blue' : 'lightblue')};
`;

export const Disabled = styled.div<{ status?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  padding-bottom: 20px;

  ${({ status }) =>
    status === TimeOffStatus.APPROVED && ' background: #52c41a;'}
  ${({ status }) => status === TimeOffStatus.PENDING && ' background: #1890ff;'}
  ${({ status }) =>
    status === TimeOffStatus.REJECTED && ' background: #FF4D4F;'}
`;

export const DisabledFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  text-align: center;
`;

export const RequestedLabel = styled(Typography.Text)`
  font-weight: 200;
  font-size: 10px;
  font-style: italic;
`;
