import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useOnboardingsData } from 'ui-v2/hooks/useOnboardingsData';
import Content from 'ui-v2/components/Content';
import OnboardingInformation from 'ui-v2/components/Onboarding/OnboardingInformation';
import OnboardingTasksList from 'ui-v2/components/Onboarding/OnboardingTasksList';
import OnboardingModalForm from 'components/NewForms/OnboardingModalForm';
import { fetchOnboardingDetail } from 'redux/onboardings/actions';

export default function OnboardingDetails() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, isModalVisible] = useState<boolean>(false);
  const {
    onboarding: { data, loaded },
  } = useOnboardingsData();

  const onboardingId = data?.id ?? (searchParams.get('id') as string);

  const onModalClose = () => {
    isModalVisible((modalVisible) => !modalVisible);
    if (onboardingId) {
      dispatch(fetchOnboardingDetail(onboardingId));
    }
  };

  useEffect(() => {
    if (searchParams.get('id') && !loaded) {
      dispatch(fetchOnboardingDetail(searchParams.get('id') as string));
    }
  }, [searchParams, loaded]);

  useEffect(() => {
    if (searchParams.get('edit-onboarding') === 'true') {
      isModalVisible((editModalVisible) => !editModalVisible);
      searchParams.delete('edit-onboarding');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <Content position="grid">
        <>
          <OnboardingTasksList />
          <OnboardingInformation />
        </>
      </Content>
      {modalVisible && (
        <OnboardingModalForm open={modalVisible} closeModal={onModalClose} />
      )}
    </>
  );
}
