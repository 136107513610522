import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clearSingleAsset } from 'redux/assets/actions';
import { AssetType } from 'types/Asset';
import { RootState } from 'redux/store';
import { AssetState } from 'redux/assets/types';

export function useAssetsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.assets
  );
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const singleAsset: AssetState = useSelector(
    (state: RootState) => state.asset
  );

  useEffect(
    () => () => {
      dispatch(clearSingleAsset());
    },
    []
  );

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    asset: data?.data?.find(
      (item: AssetType) => item.id === searchParams.get('id')
    ),
    singleAsset: {
      data: singleAsset?.data,
      loading: singleAsset.loading,
      loaded: singleAsset.loaded,
      errored: singleAsset.errored,
      error: singleAsset.error,
    },
    loading,
    loaded,
    errored,
    error,
  };
}
