import { MetaPagination } from 'types/GridTable';
import { InterviewCurrentType } from 'types/Interview';

export enum InterviewsPage {
  FETCH_INTERVIEWS_START = 'FETCH_INTERVIEWS_START',
  FETCH_INTERVIEWS_SUCCESS = 'FETCH_INTERVIEWS_SUCCESS',
  FETCH_INTERVIEWS_ERRORED = 'FETCH_INTERVIEWS_ERRORED',
}

export interface InterviewsPageState {
  data: {
    data: Array<InterviewCurrentType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface InterviewTypeOrderParams {
  candidateOpenPositionId?: string;
}
