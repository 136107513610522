import { Tag } from 'antd';
import { updateOffboardingTaskStatus } from 'api/offboarding';
import { StyledButton } from 'components/NewForms/FormStyled';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchOffboarding } from 'redux/offboarding/actions';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { OffboardingTaskStatus } from 'types/Offboarding';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';

const StatusTag = ({ status }: { status: OffboardingTaskStatus }) => {
  let color;
  switch (status) {
    case OffboardingTaskStatus.COMPLETED:
      color = 'green';
      break;
    case OffboardingTaskStatus.IN_PROGRESS:
      color = 'blue';
      break;
    case OffboardingTaskStatus.PENDING:
      color = 'blue';
      break;
    default:
      color = 'default';
  }

  return <Tag color={color}>{status}</Tag>;
};

export default function TabWrapper({
  type,
  children,
  onNext,
}: {
  type: OffboardingTaskType;
  children: React.ReactElement;
  onNext: () => void;
}) {
  const { data, getTabStatus, getTaskId } = useOffboardingData();
  const [loading] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const status = getTabStatus(type);
  const taskId = getTaskId(type);

  const onMarkComplete = async () => {
    if (!data?.id) {
      return;
    }

    if (status === OffboardingTaskStatus.COMPLETED) {
      onNext();
      return;
    }

    /** call api here if you need for succesor etc */

    try {
      const response = await updateOffboardingTaskStatus({
        taskId,
        offboardingId: data?.id,
        status: OffboardingTaskStatus.COMPLETED,
      });
      if (response.status === 200) {
        dispatch(fetchOffboarding(searchParams.get('offboardingId') as string));
        onNext();
      }
    } catch {
      toast.error('Failed to finish this step!');
    }
  };

  return (
    <>
      <Content position="stretch">
        <>
          <div>
            <CardItem rightButton={<StatusTag status={status} />}>
              {children}
            </CardItem>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <StyledButton
                loading={loading}
                style={{ width: '250px' }}
                onClick={onMarkComplete}
              >
                {status === OffboardingTaskStatus.COMPLETED
                  ? 'Next'
                  : 'Mark as Complete'}
              </StyledButton>
            </div>
          </div>
        </>
      </Content>
    </>
  );
}
