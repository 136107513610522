import {
  CertificationCeateDto,
  CertificationUpdateDto,
} from 'types/Certification';
import agent from 'api/agent';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllCertifications = (queryParams: QueryParamsType) =>
  agent.post(
    `${apiURLs.certifications}/get`,
    searchFields(queryParams, apiURLs.certifications)
  );

export const createCertification = (data: CertificationCeateDto) =>
  agent.post(apiURLs.certifications, data);

export const getOneCertification = async (id: string) =>
  agent.get(`${apiURLs.certifications}/${id}`);

export const updateCertification = async (
  id: string,
  values: CertificationUpdateDto
) => agent.patch(`${apiURLs.certifications}/${id}`, values);

export const deleteCertification = async (id: string) =>
  agent.delete(`${apiURLs.certifications}/${id}`);
