import { Typography, Image, Avatar } from 'antd';
import styled from 'styled-components';

export const StyledSidebarLogoName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body.semibold.xl,
  color: theme.colors.palette.primary.gray['100'],
  width: '80%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const StyledSidebarLogoImage = styled(Image).attrs(
  (props: { collapsed?: boolean; circle?: boolean }) => props
)(({ circle, collapsed }) => ({
  width: '2.5rem',
  height: '2.5rem',
  objectFit: 'cover',
  ...(circle && { borderRadius: '20px' }),
  ...(collapsed && { alignContent: 'center' }),
}));

export const StyledSidebarAvatar = styled(Avatar)({
  background: '#0074c1',
  width: '2.5rem',
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledSidebarLogoContent = styled.div.attrs(
  (props: { collapsed?: boolean }) => props
)(({ collapsed }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  ...(collapsed && { justifyContent: 'center' }),
}));
