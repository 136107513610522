export const destructNotification = async (value: any) => {
  const decoder = new TextDecoder('utf-8');
  const sse = decoder.decode(value);
  const dataObjects = sse.split('data: ').slice(1)[0];

  try {
    return JSON.parse(dataObjects);
  } catch {
    /**/
  }

  if (!dataObjects) {
    return null;
  }

  return Promise.reject(new Error('Unable to parse json.'));
};
