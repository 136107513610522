import CandidateForm from 'components/NewForms/NewCandidateForm';
import React from 'react';
import Content from 'ui-v2/components/Content';

export default function AddCandidate() {
  return (
    <Content position="center">
      <CandidateForm />
    </Content>
  );
}
