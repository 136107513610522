import { Button } from 'antd';
import styled from 'styled-components';

export const StyledProjectInformation = styled.div``;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #344054;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const LinkButtonStyled = styled(Button)`
  margin-left: 10px;
  cursor: pointer;
  color: #1890ff;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-size: inherit;
  font-family: inherit;
`;
