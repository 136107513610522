import { TenantDataType } from 'types/Tenant';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const storageKey = 'dextrio-Storage';

interface TenantState {
  tenant: TenantDataType | null;
  setTenant: (tenant: TenantDataType) => void;
  clearTenant: () => void;
}

export const useTenantStore = create<TenantState>()(
  persist(
    (set) => ({
      tenant: null,
      setTenant: (tenant: TenantDataType) => {
        set({ tenant });
      },
      clearTenant: () => {
        localStorage.setItem(storageKey, '');
        set({ tenant: null });
      },
    }),
    {
      name: storageKey,
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useTenantStore;
