import { Avatar, Col, Row, Typography } from 'antd';
import styled from 'styled-components';

export const EmergencyContactContainer = styled.div<{ isLast: boolean }>`
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  padding: 0 0 10px 0;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;
export const ColItem = styled(Col)<{ isLast: boolean }>`
  border-bottom: 1px solid #eaecf0;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;

export const EmergencyContactItemDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
  margin-bottom: 0.625rem;
`;

export const StyledText = styled(Typography)`
  font-size: 0.875rem;
  color: #475467;
  font-weight: 600 !important;
`;

export const Name = styled(Typography.Text)`
  font-weight: bold;
  text-overflow: ellipsis;
  font-size: 15px;
  margin-top: 3.5px;
`;
export const Relationship = styled(Typography.Text)`
  font-size: 13px;
  font-weight: 400;
  color: #475467;
  text-transform: capitalize;
`;
export const PhoneNumber = styled(Typography.Link)`
  font-weight: 600;
  font-size: 13px;
  row-justify: end;
`;
export const AvatarStyle = styled(Avatar)`
  &.ant-avatar {
    width: 100px;
    font-size: 100%;
    color: #f56a00;
    background-color: #fde3cf;
  }
`;
export const EmailAddressValues = styled(Typography.Text)`
  font-weight: 500;
  color: #475467;
  padding-left: 20px;
  font-size: 13px;
  margin-right: 10px;
`;

export const StyledRow = styled(Row)<{ isLast: boolean }>`
  cursor: pointer;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;
