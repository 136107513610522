import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import TrackingTabs from './trackingTabs';
import TrackingFilterPanel from './trackingFilters/trackingFilterPanel';
import TrackingTabContent from './trackingTabs/trackingTabContent';
import TrackingDateFilter from './trackingFilters/trackingDateFilter';

const TrackingPage: React.FC = () => {
  const [componentDidMount, setComponentDidMount] = useState(false);
  const { urlState, TabLabel } = useTrackedHoursData();

  useEffect(() => {
    if (!componentDidMount) {
      setComponentDidMount(true);
    }
  }, []);

  if (!componentDidMount) {
    return null;
  }

  return (
    <>
      <Row gutter={[0, 50]}>
        <Col span={24}>
          <Row justify="space-between" gutter={[0, 30]}>
            <Col>
              <TrackingTabs />
            </Col>
            {urlState.tab !== TabLabel.timeTracker && (
              <Col
                xs={{ span: 24 }}
                md={{ span: 10, order: 0 }}
                lg={{ span: 7 }}
              >
                <TrackingDateFilter />
              </Col>
            )}
          </Row>
        </Col>
        {urlState.tab !== TabLabel.timeTracker && (
          <Col span={24}>
            <TrackingFilterPanel />
          </Col>
        )}
        <Col span={24}>
          <TrackingTabContent />
        </Col>
      </Row>
    </>
  );
};

export default TrackingPage;
