import { Offboarding } from 'types/Offboarding';

export enum OffboardingType {
  FETCH_OFFBOARDING_START = 'FETCH_OFFBOARDING_START',
  FETCH_OFFBOARDING_SUCCESS = 'FETCH_OFFBOARDING_SUCCESS',
  FETCH_OFFBOARDING_ERRORED = 'FETCH_OFFBOARDING_ERRORED',

  CLEAR_OFFBOARDING = 'CLEAR_OFFBOARDING',
}

export interface OffboardingState {
  data: Offboarding | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export enum OffboardingTaskType {
  SUCCESSOR = 'SUCCESSOR',
  KNOWLEDGE = 'KNOWLEDGE',
  SIGNATURE = 'SIGNATURE',
  EXIT_INTERVIEW = 'EXIT_INTERVIEW',
  PAYROLL = 'PAYROLL',
  ASSETS = 'ASSETS',
  REVOCATION = 'REVOCATION',
  BALANCE = 'PAYROLL',
}
