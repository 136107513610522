import { getEvaluations, getEvaluation } from 'api/evaluationService';
import { Dispatch } from 'redux';
import { EvaluationPeriodQueryParamType } from 'types/QueryParams';
import { EvaluationPeriods } from './types';

export const fetchEvaluationPeriods =
  (queryParams?: EvaluationPeriodQueryParamType) => (dispatch: Dispatch) => {
    dispatch({
      type: EvaluationPeriods.FETCH_EVALUATION_PERIODS_START,
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getEvaluations(queryParams!)
      .then((res) => {
        dispatch({
          type: EvaluationPeriods.FETCH_EVALUATION_PERIODS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: EvaluationPeriods.FETCH_EVALUATION_PERIODS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleEvaluation = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: EvaluationPeriods.FETCH_EVALUATION_PERIOD_START,
  });
  getEvaluation(id)
    .then((res) => {
      dispatch({
        type: EvaluationPeriods.FETCH_EVALUATION_PERIOD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: EvaluationPeriods.FETCH_EVALUATION_PERIOD_ERRORED,
        payload: e,
      });
    });
};

export const clearEvaluationPeriod = () => (dispatch: Dispatch) => {
  dispatch({
    type: EvaluationPeriods.CLEAR_EVALUATION_PERIOD,
  });
};
