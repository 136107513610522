import React from 'react';
import { Col, Popconfirm, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};

const IpWhitelistingAction: React.FC<Props> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  function deleteIp() {
    searchParams.append('delete-ip-whitelisting', id);
    setSearchParams(searchParams);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="space-around">
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('doYouWantToDeleteThisInterview?')}
                onConfirm={deleteIp}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default IpWhitelistingAction;
