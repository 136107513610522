import React, { useCallback, useEffect, useState } from 'react';

import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import { useDispatch, useSelector } from 'react-redux';
import useHeader from 'ui-v2/hooks/useHeader';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';

import { deleteSkill } from 'api/skillService';
import { fetchSkills } from 'redux/skills/actions';
import { RootState } from 'redux/store';
import { SkillsState } from 'redux/skills/types';
import { useTranslation } from 'react-i18next';
import AddSkillForm from '../AddSkillCategory/SkillCategoryForm';

function SkillCategoriesList() {
  const { columns, take } = useHeader();
  const skillSelector: SkillsState = useSelector(
    (state: RootState) => state?.skills
  );

  const { meta: pagination, loading } = skillSelector.skillCategories;
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const toogleModalVisibility = useCallback(
    () => setModalVisible(!modalVisible),
    [modalVisible]
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function deleteSkillById(id: string | null) {
    if (!id) return;
    deleteSkill(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('skillDeletedSuccessfully'));
          dispatch(
            fetchSkills({ pageOptions: { page: pagination?.page || 1, take } })
          );
        }
      })
      .catch(toastErrorMessages);
  }

  useEffect(() => {
    dispatch(fetchSkills({ pageOptions: { page: 1, take } }));
  }, []);

  const onPageChange = (page: number) => {
    dispatch(
      fetchSkills({
        pageOptions: { page, take },
        filterOptions: {
          filterText: searchTerm,
          filterFields: ['categoryName'],
        },
      })
    );
  };

  const onSearch = (searchTerm: string) => {
    dispatch(
      fetchSkills({
        pageOptions: { page: 1 },
        filterOptions: {
          filterText: searchTerm,
          filterFields: ['categoryName'],
        },
      })
    );
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-skill-save') === 'true') {
      searchParams.delete('add-skill-save');
      setSearchParams(searchParams);
      toogleModalVisibility();
    }
    if (searchParams.get('edit-skill')) {
      toogleModalVisibility();
    }
  }, [searchParams]);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteSkillById(id?.toString());
    });
  }

  return (
    <>
      <Content position="stretch">
        <DataGrid
          loading={loading}
          columns={columns}
          data={skillSelector?.skillCategories?.data || []}
          paginationName="Skills"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
        />
      </Content>
      {modalVisible && (
        <AddSkillForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-skill-save');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}

export default SkillCategoriesList;
