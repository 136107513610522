import { Col, Row, Skeleton, Avatar, Typography } from 'antd';
import React from 'react';
import { CompanyDetailsStyled } from '../SetUserPasswordStyles';

export function CompanyDetails({
  logo = '',
  name = '',
  loading,
}: {
  logo: string;
  name: string;
  loading: boolean;
}) {
  return (
    <CompanyDetailsStyled gutter={[0, 20]}>
      <Col span={24}>
        <Row justify="center">
          {loading ? (
            <Skeleton.Avatar active shape="circle" />
          ) : (
            <Avatar shape="circle" src={logo} />
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="center">
          {loading ? (
            <Skeleton.Input active />
          ) : (
            <Typography.Title level={4}>{name}</Typography.Title>
          )}
        </Row>
      </Col>
    </CompanyDetailsStyled>
  );
}
