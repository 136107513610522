import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Empty, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

import { RulesType } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';
import { FormItemStyled, SelectStyled } from '../FormStyled';

export type ValueType = {
  key?: string;
  label: React.ReactNode;
  value: string | number;
};

export interface SelectLoadPayload {
  data: Array<ValueType>;
  pageCount: number;
}
export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string, page: number) => Promise<SelectLoadPayload>;
  debounceTimeout?: number;
}

export interface FormSelectProps extends DebounceSelectProps {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  disabled?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  onSelect?: any;
  hidePlaceholder?: boolean;
  hint?: React.ReactNode | string;
}

export default function DebounceSelect<ValueType>({
  fetchOptions,
  debounceTimeout = 800,
  name,
  rules,
  placeholder,
  disabled,
  mode,
  onSelect,
  hidePlaceholder,
  hint,
}: FormSelectProps) {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [options, setOptions] = useState<ValueType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [searchString, setSearchString] = useState('');
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string, page?: number) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value, page || 1).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        if (newOptions?.data) {
          setFetching(false);
          setOptions(newOptions.data as any);
          setPageCount(newOptions.pageCount || 1);
        }
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    debounceFetcher('', page);
  }, []);

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = event;
    if (
      !fetching &&
      currentTarget.scrollTop + currentTarget.offsetHeight ===
        currentTarget.scrollHeight
    ) {
      currentTarget.scrollTo(0, currentTarget.scrollHeight);
      setFetching(true);
      if (page < pageCount) {
        fetchOptions(searchString, page + 1).then((newOptions) => {
          setOptions([...options, ...(newOptions?.data || [])] as ValueType[]);
          setFetching(false);
        });
        setPage((page) => page + 1);
      }
    }
  };

  const onSearch = (search: string) => {
    debounceFetcher(search, 1);
    setPage(1);
    setSearchString(search);
  };

  return (
    <FormItemStyled
      name={name}
      label={!hidePlaceholder && placeholder}
      rules={rules}
      {...(hint && { tooltip: hint })}
    >
      <SelectStyled
        labelInValue
        placeholder={fetching ? <Spin size="small" /> : placeholder}
        filterOption={false}
        onSearch={onSearch}
        notFoundContent={
          fetching ? <Spin size="small" /> : <Empty description={t('noData')} />
        }
        getPopupContainer={(trigger) => trigger?.parentNode?.parentNode}
        options={options as any}
        disabled={disabled}
        onPopupScroll={onScroll}
        className="ant-styled-select"
        showSearch
        mode={mode}
        onDropdownVisibleChange={() => setPage(1)}
        onSelect={(id: any) => onSelect(id, name)}
        onDeselect={onSelect}
        allowClear
      />
    </FormItemStyled>
  );
}
