/* eslint-disable @typescript-eslint/no-namespace */
import agent from 'api/agent';

import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { QueryParamsDTO } from 'types/QueryParams';
import {
  VendorQuestionnaireCategoryCreateDto,
  VendorQuestionnaireCategoryUpdateDto,
  VendorQuestionnaireCreateDto,
  VendorQuestionnaireUpdateDto,
} from 'types/VendorQuestionnaire';
import { apiURLs } from './constants';

export namespace VendorQuestionnaireApiClient {
  export interface CategoryRequest {
    id: string;
  }
  export interface QuestionnaireCreateQuestionRequest {
    payload: VendorQuestionnaireCreateDto;
  }

  export interface QuestionnaireQuestionRequest {
    id: string;
  }

  export interface QuestionnaireUpdateQuestionRequest {
    id: string;
    payload: VendorQuestionnaireUpdateDto;
  }
  export interface CategoryCreateRequest {
    payload: VendorQuestionnaireCategoryCreateDto;
  }

  export interface CategoryUpdateRequest extends CategoryRequest {
    payload: VendorQuestionnaireCategoryUpdateDto;
  }

  export const createQuestionnaire = async ({
    payload,
  }: QuestionnaireCreateQuestionRequest) =>
    agent.post(`${apiURLs.vendorQuestionnaire}`, payload);

  export const updateQuestionnaire = async ({
    id,
    payload,
  }: QuestionnaireUpdateQuestionRequest) =>
    agent.put(`${apiURLs.vendorQuestionnaire}/${id}`, payload);

  export const deleteQuestionnaire = async ({
    id,
  }: QuestionnaireQuestionRequest) =>
    agent.delete(`${apiURLs.vendorQuestionnaire}/${id}`);

  export const getQuestionnaires = async (queryParams?: QueryParamsDTO) =>
    agent.post(
      `${apiURLs.vendorQuestionnaire}/get`,
      searchFields(queryParams, apiURLs.vendorQuestionnaire)
    );

  export const getCategories = async (queryParams?: QueryParamsDTO) =>
    agent.post(
      `${apiURLs.vendorQuestionnaire}-category/get`,
      searchFields(queryParams, `${apiURLs.vendorQuestionnaire}-category`)
    );

  export const getCategorie = async ({ id }: CategoryRequest) =>
    agent.get(`${apiURLs.vendorQuestionnaire}-category/${id}`);

  export const getScores = async (queryParams?: QueryParamsDTO) =>
    agent.post(
      `${apiURLs.vendorQuestionnaire}-score/get`,
      searchFields(queryParams, `${apiURLs.vendorQuestionnaire}-score`)
    );

  export const updateCategorie = async ({
    id,
    payload,
  }: CategoryUpdateRequest) =>
    agent.put(`${apiURLs.vendorQuestionnaire}-category/${id}`, payload);

  export const deleteCategorie = async ({ id }: CategoryRequest) =>
    agent.delete(`${apiURLs.vendorQuestionnaire}-category/${id}`);

  export const createCategorie = async ({ payload }: CategoryCreateRequest) =>
    agent.post(`${apiURLs.vendorQuestionnaire}-category`, payload);
}
