import React from 'react';

export const FilterByIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6665 2.83333C1.6665 2.36662 1.6665 2.13327 1.75733 1.95501C1.83723 1.79821 1.96471 1.67072 2.12151 1.59083C2.29977 1.5 2.53313 1.5 2.99984 1.5H16.9998C17.4665 1.5 17.6999 1.5 17.8782 1.59083C18.035 1.67072 18.1624 1.79821 18.2423 1.95501C18.3332 2.13327 18.3332 2.36662 18.3332 2.83333V3.39116C18.3332 3.61516 18.3332 3.72716 18.3058 3.8313C18.2815 3.92359 18.2416 4.01103 18.1878 4.0898C18.127 4.17869 18.0424 4.25204 17.8731 4.39875L12.5433 9.01792C12.374 9.16462 12.2894 9.23797 12.2286 9.32687C12.1747 9.40564 12.1348 9.49308 12.1105 9.58536C12.0832 9.68951 12.0832 9.80151 12.0832 10.0255V14.382C12.0832 14.5449 12.0832 14.6264 12.0569 14.6969C12.0337 14.7591 11.9959 14.8149 11.9467 14.8596C11.8911 14.9102 11.8154 14.9404 11.6641 15.001L8.83076 16.1343C8.52448 16.2568 8.37133 16.3181 8.2484 16.2925C8.14089 16.2702 8.04655 16.2063 7.98588 16.1148C7.9165 16.0101 7.9165 15.8452 7.9165 15.5153V10.0255C7.9165 9.80151 7.9165 9.68951 7.88913 9.58536C7.86488 9.49308 7.82495 9.40564 7.77109 9.32687C7.71032 9.23797 7.62568 9.16462 7.45641 9.01792L2.1266 4.39875C1.95732 4.25204 1.87269 4.17869 1.81191 4.0898C1.75806 4.01103 1.71813 3.92359 1.69388 3.8313C1.6665 3.72716 1.6665 3.61516 1.6665 3.39116V2.83333Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
