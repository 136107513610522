import { Dispatch } from 'redux';
import { getAllRequests } from 'api/request';
import { Requests } from './types';

export const fetchAllRequests = () => (dispatch: Dispatch) => {
  dispatch({
    type: Requests.FETCH_REQUESTS_START,
  });

  getAllRequests()
    .then((res: any) => {
      dispatch({
        type: Requests.FETCH_REQUESTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e: any) => {
      dispatch({
        type: Requests.FETCH_REQUESTS_ERRORED,
        payload: e,
      });
    });
};
