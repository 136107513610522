import { EmployeeAssignmnetsType, EmployeeType, PayType } from 'types/Employee';

export function getEmployeeSalary(
  emp?: EmployeeType,
  employeeAssignments?: EmployeeAssignmnetsType
): string {
  if (
    emp?.payType === PayType.MONTHLY ||
    emp?.payType === PayType.COMMISION ||
    emp?.payType === PayType.COMMISION_BASE
  ) {
    return `${emp?.salary || ''} ${
      employeeAssignments?.salaryEmployee?.symbol || ''
    }`;
  }
  return `${emp?.hourlyRate || ''} ${
    employeeAssignments?.salaryEmployee?.symbol || ''
  }`;
}
