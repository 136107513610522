/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col } from 'antd';

import { ResponseSkillCategoryType } from 'types/Skills';
import { Item } from 'ui-v2/components/PersonalInfo';
import { RowItem } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { Container } from 'ui-v2/components/Card/CardStyles';
import { StyledRate } from 'components/NewForms/FormStyled';
import { SkillDetailsRateStyles } from '../../SkillsStyles';
import i18next from '../../../../../i18n';

type IProps = {
  skill: ResponseSkillCategoryType;
};

const SkillDetails: React.FC<IProps> = ({ skill }) => (
  <Container
    direction="vertical"
    size="middle"
    className="popover-card-container"
  >
    <RowItem last gutter={[0, 20]}>
      <Col md={{ span: 8 }} sm={{ span: 24 }}>
        <Item
          label={i18next.t('skill')}
          value={skill?.skillsSubCategory?.subCategoryName}
          children={undefined}
        />
      </Col>
      <Col md={{ span: 8, offset: 4 }} sm={{ span: 24 }}>
        <Item
          label={i18next.t('exp(Years)')}
          value={skill?.yearsOfExperience}
          children={undefined}
        />
      </Col>
      <Col md={{ span: 24 }} sm={{ span: 24 }}>
        <Item
          label={i18next.t('score')}
          value={
            <StyledRate
              disabled
              defaultValue={skill.score}
              style={SkillDetailsRateStyles}
            />
          }
          children={undefined}
        />
      </Col>
    </RowItem>
  </Container>
);

export default SkillDetails;
