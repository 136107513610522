import { Reducer } from 'react';
import { Action } from 'redux';

import { OnBoardings, OnBoardingsState, SingleOnboardingState } from './types';

const initialState: OnBoardingsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const OnboardingsReducer: Reducer<OnBoardingsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case OnBoardings.FETCH_ON_BOARDINGS_START:
      state = { ...state, loading: true };
      break;
    case OnBoardings.FETCH_ON_BOARDINGS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case OnBoardings.FETCH_ON_BOARDINGS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleOnboardingState: SingleOnboardingState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SingleOnboardingReducer: Reducer<SingleOnboardingState, Action> = (
  state = initialSingleOnboardingState,
  action: any
) => {
  switch (action.type) {
    case OnBoardings.FETCH_ON_BOARDINGS_DETAILS_START:
      state = { ...state, loading: true };
      break;
    case OnBoardings.FETCH_ON_BOARDINGS_DETAILS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case OnBoardings.FETCH_ON_BOARDINGS_DETAILS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case OnBoardings.CLEAR_SINGLE_ONBOARDING:
      state = initialSingleOnboardingState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { OnboardingsReducer, SingleOnboardingReducer };
