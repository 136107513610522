import React from 'react';
import { Col, Popconfirm, Row } from 'antd';

import { Id } from 'types/Id';
import { ViewEvaluationIcon } from 'Icons/ViewEvaluationIcon';
import { useSearchParams } from 'react-router-dom';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import styled from 'styled-components';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  id: string;
};

const EvaluationActions: React.FC<Id> = ({ id }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const onViewClick = () => {
    searchParams.append('view-evaluation', id);
    setSearchParams(searchParams);
  };

  const onEditClick = () => {
    searchParams.append('edit-evaluation', id);
    setSearchParams(searchParams);
  };

  function deleteEvaluationById() {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'delete-evaluation',
        value: id,
      })
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <IconButton onClick={onViewClick}>
              <ViewEvaluationIcon />
            </IconButton>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Popconfirm
              placement="topLeft"
              title={t('areYouSureYouWanttoDelete')}
              onConfirm={deleteEvaluationById}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Popconfirm>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default EvaluationActions;
