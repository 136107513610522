import { EditOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover, Row } from 'antd';
import React from 'react';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import { MinusCircleIcon } from 'Icons/MinusCircleIcon';
import { IconButton, NodataLabel, RowItemAsset } from './AssetItemStyled';

interface IProps {
  name: string;
  dateLoaded: string;
  dateReturned: string;
  assetCost: string;
  currency: string;
  serialNumber: string;
  notes: string;
  onView: () => void;
  onDelete: () => void;
}

export function AssetItem({
  name,
  dateLoaded,
  dateReturned,
  assetCost,
  currency,
  serialNumber,
  notes,
  onView,
  onDelete,
}: IProps) {
  const truncatedSerialNumber = serialNumber?.slice(0, 5);
  const fullSerialNumber = serialNumber?.length > 5 ? serialNumber : null;
  const { t } = useTranslation();

  return (
    <RowItemAsset>
      <Col span={3}>
        <NodataLabel>{name}</NodataLabel>
      </Col>
      <Col span={4}>
        <Popover content={fullSerialNumber}>
          <NodataLabel>
            {truncatedSerialNumber}
            {serialNumber?.length > 5 && '...'}
          </NodataLabel>
        </Popover>
      </Col>
      <Col span={3}>
        <NodataLabel>{notes}</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>{dateLoaded}</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>{dateReturned}</NodataLabel>
      </Col>
      <Col span={3}>
        <NodataLabel>{`${assetCost} ${currency}`}</NodataLabel>
      </Col>
      <RoleBasedButton btn={BUTTONS.EDIT_ASSET}>
        <Col span={4}>
          <Row justify="space-around">
            <IconButton onClick={onView}>
              <EditOutlined />
            </IconButton>
            <Popconfirm
              placement="topLeft"
              title={t('areYouSureYouWantToUnassignThisAsset')}
              onConfirm={onDelete}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <IconButton>
                <MinusCircleIcon />
              </IconButton>
            </Popconfirm>
          </Row>
        </Col>
      </RoleBasedButton>
    </RowItemAsset>
  );
}
