import { Reducer } from 'react';
import { Action } from 'redux';

import {
  CandidateOffersState,
  CandidateOpenPositionStage,
  Candidates,
  CandidateScheduleInterviewState,
  CandidateSelectedOpenPosition,
  CandidatesState,
  InterviewsState,
  SingleCandidateState,
} from './types';

const initialState: CandidatesState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const CandidatesReducer: Reducer<CandidatesState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Candidates.FETCH_CANDIDATES_START:
      state = { ...state, loading: true, loaded: false };
      break;
    case Candidates.FETCH_CANDIDATES_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Candidates.FETCH_CANDIDATES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialCandidateOffersState: CandidateOffersState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const CandidateOffersReducer: Reducer<CandidateOffersState, Action> = (
  state = initialCandidateOffersState,
  action: any
) => {
  switch (action.type) {
    case Candidates.FETCH_CANDIDATE_OFFERS_START:
      state = { ...state, loading: true };
      break;
    case Candidates.FETCH_CANDIDATE_OFFERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case Candidates.FETCH_CANDIDATE_OFFERS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Candidates.CLEAR_CANDIDATE_OFFERS:
      state = initialCandidateOffersState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleCandidateState: SingleCandidateState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SingleCandidatesReducer: Reducer<SingleCandidateState, Action> = (
  state = initialSingleCandidateState,
  action: any
) => {
  switch (action.type) {
    case Candidates.FETCH_SINGLE_CANDIDATE_START:
      state = { ...state, loading: true };
      break;
    case Candidates.FETCH_SINGLE_CANDIDATE_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Candidates.FETCH_SINGLE_CANDIDATE_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Candidates.CLEAR_SINGLE_CANDIDATE:
      state = initialSingleCandidateState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialStateScheduleInterview: CandidateScheduleInterviewState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const ScheduleInterviewReducer: Reducer<
  CandidateScheduleInterviewState,
  Action
> = (state = initialStateScheduleInterview, action: any) => {
  switch (action.type) {
    case Candidates.SCHEDULE_INTERVIEW_START:
      state = { ...state, loading: true, loaded: false };
      break;
    case Candidates.SCHEDULE_INTERVIEW_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Candidates.SCHEDULE_INTERVIEW_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Candidates.CLEAR_SCHEDULE_INTERVIEW:
      state = initialStateScheduleInterview;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialStateInterviews: CandidateScheduleInterviewState = {
  data: {
    interviews: [],
    totInterview: 0,
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const InterviewsReducer: Reducer<InterviewsState, Action> = (
  state = initialStateInterviews,
  action: any
) => {
  switch (action.type) {
    case Candidates.FETCH_INTERVIEWS_START:
      state = { ...state, loading: true };
      break;
    case Candidates.FETCH_INTERVIEWS_SUCCESSS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Candidates.FETCH_INTERVIEWS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Candidates.CLEAR_INTERVIEWS:
      return initialStateInterviews;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialOpenPositionStage: CandidateOpenPositionStage = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};
const OpenPositionStageReducer: Reducer<CandidateOpenPositionStage, Action> = (
  state = initialOpenPositionStage,
  action: any
) => {
  switch (action.type) {
    case Candidates.FETCH_OPEN_POSITION_STAGES_START:
      state = { ...state, loading: true };
      break;
    case Candidates.FETCH_OPEN_POSITION_STAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case Candidates.FETCH_OPEN_POSITION_STAGES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Candidates.CLEAR_OPEN_POSITION_STAGES:
      state = initialOpenPositionStage;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSelectedOpenPositionState: CandidateSelectedOpenPosition = {
  data: null,
};

const SelectedOpenPosition: Reducer<CandidateSelectedOpenPosition, Action> = (
  state = initialSelectedOpenPositionState,
  action: any
) => {
  switch (action.type) {
    case Candidates.CANDIDATE_SELECTED_OPEN_POSITION:
      state = { ...state, data: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export {
  CandidateOffersReducer,
  SingleCandidatesReducer,
  CandidatesReducer,
  ScheduleInterviewReducer,
  InterviewsReducer,
  OpenPositionStageReducer,
  SelectedOpenPosition,
};
