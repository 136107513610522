/* eslint-disable no-nested-ternary */
import { Row, Col } from 'antd';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getPathnamesWithStickyHeader } from 'utils/utilFunctions';

import BreadCrumbs from 'ui-v2/components/BreadCrumbs';
import Header from 'ui-v2/components/Header';
import useHeader from 'ui-v2/hooks/useHeader';
import HrDutiesBanner from 'components/HRDutiesBanner/HRDutiesBanner';

import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import * as Styled from './MainLayoutStyles';

export default function MainLayout() {
  const { pathname } = useLocation();
  const { visibleHeader, hideDetails } = useHeader();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const list = pathname?.split('/').filter((item) => item !== '');

  const canViewHrDutiesBanner = [AUTH_ROLES.AMDIN, AUTH_ROLES.HR].includes(
    authUserRole as AUTH_ROLES
  );

  return (
    <Styled.Container>
      <BreadCrumbs />
      <Row gutter={[10, hideDetails ? 0 : 15]}>
        {canViewHrDutiesBanner && (
          <Col span={24}>{list.length === 1 && <HrDutiesBanner />}</Col>
        )}
        <Styled.RowItem gutter={[0, 15]}>
          {getPathnamesWithStickyHeader(pathname) ? (
            <Styled.Sticky span={24}>
              {visibleHeader && <Header />}
            </Styled.Sticky>
          ) : (
            <Col span={24}>{visibleHeader && <Header />}</Col>
          )}
          <Col span={24}>
            <Outlet />
          </Col>
        </Styled.RowItem>
      </Row>
    </Styled.Container>
  );
}
