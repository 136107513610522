import {
  getProjectById,
  getAllProjects,
  geProjectAssignmentOfEmployee,
  getEmployeeOwnProjects,
} from 'api/projectService';
import { Dispatch } from 'redux';
import { ProjectQueryParamsType } from 'types/QueryParams';
import { Projects } from './types';

export const fetchProjects =
  (queryParams: ProjectQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Projects.FETCH_PROJECTS_START,
    });
    getAllProjects(queryParams)
      .then((res) => {
        dispatch({
          type: Projects.FETCH_PROJECTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Projects.FETCH_PROJECTS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleProject = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Projects.FETCH_SINGLE_PROJECT_START,
  });

  getProjectById(id)
    .then((res) => {
      dispatch({
        type: Projects.FETCH_SINGLE_PROJECT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({ type: Projects.FETCH_SINGLE_PROJECT_ERRORED, payload: e });
    });
};

export const fetchProjectsOfEmployee = () => (dispatch: Dispatch) => {
  dispatch({
    type: Projects.FETCH_PROJECTS_OF_EMPLOYEE_START,
  });

  getEmployeeOwnProjects()
    .then((res) => {
      dispatch({
        type: Projects.FETCH_PROJECTS_OF_EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Projects.FETCH_PROJECTS_OF_EMPLOYEE_ERRORED,
        payload: e,
      });
    });
};

export const fetchPrjoectEmployeeAssignments =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: Projects.FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_START,
    });

    geProjectAssignmentOfEmployee(id)
      .then((res) => {
        dispatch({
          type: Projects.FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Projects.FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_ERRORED,
          payload: e,
        });
      });
  };

export const clearPrjoectEmployeeAssignments = () => (dispatch: Dispatch) => {
  dispatch({
    type: Projects.CLEAR_ALL_PROJECT_ASSIGNMENT_OF_EMPLOYEE,
  });
};

export const clearSingleProject = () => (dispatch: Dispatch) => {
  dispatch({
    type: Projects.CLEAR_SINGLE_PROJECT,
  });
};
