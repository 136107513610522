import { Col, Row } from 'antd';
import React from 'react';

import { SettingTab } from 'types/settings';
import CardItem from 'ui-v2/components/Card';

interface TenantSettingsPageTabLayoutProps {
  tab: SettingTab;
  children: React.ReactNode;
}

const TenantSettingsPageTabLayout: React.FC<
  TenantSettingsPageTabLayoutProps
> = ({ tab, children }) => (
  <Row justify="center" gutter={[0, 20]}>
    <Col span={24} />
    <Col
      xs={{ span: 24 }}
      sm={{ span: 18 }}
      md={{ span: 16 }}
      lg={{ span: 14 }}
    >
      <CardItem title={tab !== SettingTab.LOCATION ? tab : undefined}>
        <>{children}</>
      </CardItem>
    </Col>
  </Row>
);

export default TenantSettingsPageTabLayout;
