import { Space } from 'antd';
import styled from 'styled-components';

export const Container = styled(Space)`
  background: #fff;
  gap: 0px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 10px;
  width: 100%;

  cursor: pointer;
`;
