import { Button, Col, Popconfirm, Row } from 'antd';
import { ArrowDownIcon } from 'Icons/ArrowDownIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { FileCVIcon } from 'Icons/FileCVIcon';
import React from 'react';
import styled from 'styled-components';
import { convertToKB } from 'utils/utilFunctions';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import {
  Actions,
  FileItemContainer,
  FileItemName,
  FileItemSize,
} from '../PersonalInfo/PersonalInfoStyles';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import i18next from '../../../i18n';

export const IconButton = styled(Button)`
  border: none;
  box-shadow: none;
  &:hover {
    cursor: pointer;
  }
`;

export function DocumentFileItem({
  filename = 'Document.pdf',
  filesize = '20 kB',
  filecontent,
  noActions,
  onDelete,
  preview,
}: {
  filename?: string;
  filesize?: string;
  filecontent: string | undefined;
  noActions?: boolean;
  onDelete?: () => void;
  preview?: boolean;
}) {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  return (
    <FileItemContainer noBorder={noActions} align="middle">
      <Col span={4}>
        <FileCVIcon />
      </Col>
      <Col span={16}>
        <FileItemName ellipsis={{ tooltip: true }}>{filename}</FileItemName>
        <FileItemSize>{convertToKB(filesize)}</FileItemSize>
      </Col>
      <Actions span={4} noActions={noActions}>
        <Row justify="space-around" align="top">
          <IconButton
            type="default"
            icon={<ArrowDownIcon />}
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              if (filecontent) {
                window.open(filecontent);
              }
            }}
          />
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            {authUserRole === AUTH_ROLES.EMPLOYEE ? (
              ''
            ) : (
              <Popconfirm
                placement="topLeft"
                title={i18next.t(
                  'Are you sure you want to delete this document?'
                )}
                onConfirm={onDelete}
                okText={i18next.t('yes')}
                cancelText={i18next.t('no')}
              >
                {!preview && (
                  <IconButton
                    type="default"
                    icon={<DeleteIcon />}
                    size="middle"
                  />
                )}
              </Popconfirm>
            )}
          </RoleBasedButton>
        </Row>
      </Actions>
    </FileItemContainer>
  );
}
