import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { fetchSingleWorkPosition } from 'redux/workPositions/actions';
import { useSearchParams } from 'react-router-dom';
import WorkPositionForm from 'components/NewForms/WorkPositionForm';
import WorkPositionInformation from './WorkPositionInformation';
import WorkPositionDepartmentInformation from './WorkPositionDepartmentInformation';

export default function WorkPositionDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.get('edit-work-position') !== null) {
      setModalVisible(true);
      searchParams.delete('edit-work-position');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(fetchSingleWorkPosition(searchParams.get('id') as string));
    }
  }, [searchParams, modalVisible]);

  return (
    <>
      <Content position="grid">
        <>
          <WorkPositionInformation />
          <WorkPositionDepartmentInformation />
        </>
      </Content>
      {modalVisible && (
        <WorkPositionForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-work-position');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
