import React from 'react';

const DatabaseIcon: React.FC = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 4C19 5.65685 14.9706 7 10 7C5.02944 7 1 5.65685 1 4M19 4C19 2.34315 14.9706 1 10 1C5.02944 1 1 2.34315 1 4M19 4V18C19 19.66 15 21 10 21C5 21 1 19.66 1 18V4M19 8.72021C19 10.3802 15 11.7202 10 11.7202C5 11.7202 1 10.3802 1 8.72021M19 13.44C19 15.1 15 16.44 10 16.44C5 16.44 1 15.1 1 13.44"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DatabaseIcon;
