import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { updateCandidate } from 'api/candidateService';
import { useDispatch } from 'react-redux';
import { clearSingleCandidate, fetchCandidate } from 'redux/candidates/actions';
import {
  ResponseSkillCategoryType,
  SkillCategoryWithSubCategoriesType,
  SkillSubCategoryInputType,
  SkillSubCategoryType,
} from 'types/Skills';
import { OptionType } from 'types/OptionTypes';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';

import { toastErrorMessages } from 'utils/utilFunctions';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { updateEmployeeSkills } from 'api/employeeService';
import {
  clearSingleEmployee,
  fetchEmployeesDetail,
} from 'redux/employees/actions';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTranslation } from 'react-i18next';
import { useSkillsData } from 'ui-v2/hooks/useSkillsData';
import CardItem from '../Card';
import AddSkill from './AddSkillModal';

import SkillsWrapper from './SkillsWrapper';

type IProps = {
  isEmployeePortal: boolean;
  isDisabled: boolean;
};

export default function SkillList({ isEmployeePortal, isDisabled }: IProps) {
  const dispatch = useDispatch();
  const {
    skills: { data: skillData },
  } = useSkillsData();
  const { currentRoute } = useHeader();
  const { candidate, candidateLoaded } = useCandidateData();
  const { employee } = useEmployeesData();
  const [skillOptions, setSkillOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isEmployeePortal) {
      dispatch(clearSingleCandidate());
    } else {
      dispatch(clearSingleEmployee());
    }
  }, []);

  const getAvailableSubSkills = (
    skillCategoriesWithSubCategories:
      | Array<SkillCategoryWithSubCategoriesType>
      | []
  ) => {
    if (!skillCategoriesWithSubCategories?.length) return;
    const currentSkills: Array<ResponseSkillCategoryType> = isEmployeePortal
      ? employee?.skillSubCategoriesEmployees ?? []
      : candidate?.skillSubCategoriesCandidates ?? [];
    const allSkillsOptions: Array<OptionType> = skillCategoriesWithSubCategories
      .map((category: SkillCategoryWithSubCategoriesType) => [
        ...category.subCategories.map((subCategory: SkillSubCategoryType) => ({
          id: subCategory.id,
          label: subCategory.subCategoryName,
          value: subCategory.id,
        })),
      ])
      .flat();

    if (!currentSkills.length) {
      setSkillOptions(allSkillsOptions);
      return;
    }
    const skillIds: Array<string> = currentSkills.map(
      (el) => el.skillsSubCategory.id
    );

    setSkillOptions(
      allSkillsOptions.filter((option) => skillIds.indexOf(option.id) === -1)
    );
  };

  useEffect(() => {
    if (
      currentRoute === 'profile' &&
      candidate?.id &&
      candidateLoaded &&
      skillData?.length
    ) {
      getAvailableSubSkills(skillData);
    }
  }, [currentRoute, candidate?.id, candidateLoaded, skillData?.length]);

  useEffect(() => {
    if (
      currentRoute === 'employee-profile' &&
      employee?.id &&
      skillData?.length
    ) {
      getAvailableSubSkills(skillData);
    }
  }, [currentRoute, employee?.id, skillData?.length]);

  const onToggleModal = () => setModalVisible((state) => !state);

  const onDeleteSkill = (skill: ResponseSkillCategoryType) => {
    setLoading(true);
    const isSkillResponseNotEmpty: boolean = isEmployeePortal
      ? !employee?.skillSubCategoriesEmployees?.length
      : !candidate?.skillSubCategoriesCandidates?.length;

    if (isSkillResponseNotEmpty) {
      return;
    }
    const remainingSkills: Array<ResponseSkillCategoryType> = isEmployeePortal
      ? employee?.skillSubCategoriesEmployees?.filter(
          (item: ResponseSkillCategoryType) => item?.id !== skill?.id
        )
      : candidate?.skillSubCategoriesCandidates.filter(
          (item: ResponseSkillCategoryType) => item?.id !== skill?.id
        );

    const skillSubCategories: Array<SkillSubCategoryInputType> = [
      ...remainingSkills.map((el) => ({
        skillSubCategoryId: el.skillsSubCategory.id,
        yearsOfExperience: Number(el.yearsOfExperience),
        score: el.score,
      })),
    ];

    if (!isEmployeePortal && candidate?.id) {
      updateCandidate(candidate?.id, {
        skillSubCategories,
      })
        .then((response) => {
          if (response.status === 200 && response?.data) {
            toast.success(t('successfullyDeletedSkill!'));
            dispatch(fetchCandidate(candidate?.id));
            setSkillOptions((skillOptions) => [
              ...skillOptions,
              {
                id: skill.skillsSubCategory.id,
                value: skill.id,
                label: skill.skillsSubCategory.subCategoryName,
              },
            ]);
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => setLoading(false));
      return;
    }
    if (employee?.id) {
      updateEmployeeSkills({
        employeeId: employee?.id,
        skillSubCategories,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyDeletedSkill!'));
            dispatch(fetchEmployeesDetail(employee?.id));
            setSkillOptions((skillOptions) => [
              ...skillOptions,
              {
                id: skill.skillsSubCategory.id,
                value: skill.id,
                label: skill.skillsSubCategory.subCategoryName,
              },
            ]);
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onSubmit = (formValues: SkillSubCategoryInputType) => {
    formValues = {
      ...formValues,
      yearsOfExperience: Number(formValues?.yearsOfExperience),
      skillSubCategoryId: formValues.skillSubCategoryId,
    };
    if (formValues.score === 0) {
      toast.warning(`${t('pleaseProvideAScore')}!`);

      return;
    }
    setLoading(true);
    let payload: Array<SkillSubCategoryInputType> | [] = [];

    // Update Employee
    if (isEmployeePortal && employee?.id) {
      if (employee?.skillSubCategoriesEmployees?.length) {
        payload = [
          ...(employee?.skillSubCategoriesEmployees?.map((el: any) => ({
            skillSubCategoryId: el?.skillsSubCategory?.id,
            yearsOfExperience: Number(el?.yearsOfExperience),

            score: Number(el?.score),
          })) ?? []),
          formValues,
        ];
      } else {
        payload = [formValues];
      }
      updateEmployeeSkills({
        employeeId: employee?.id,
        skillSubCategories: payload,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyAddedSkill!'));
            dispatch(fetchEmployeesDetail(employee?.id));
            setSkillOptions(
              skillOptions.filter(
                (el) => el?.id !== formValues?.skillSubCategoryId
              )
            );
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          onToggleModal();
          setLoading(false);
        });
    }

    // Update Candidate
    if (candidate?.id) {
      if (candidate?.skillSubCategoriesCandidates?.length) {
        payload = [
          ...(candidate?.skillSubCategoriesCandidates?.map((el: any) => ({
            skillSubCategoryId: el?.skillsSubCategory.id,
            yearsOfExperience: Number(el?.yearsOfExperience),
            score: el.score,
          })) ?? []),
          formValues,
        ];
      } else {
        payload = [formValues];
      }
      updateCandidate(candidate?.id, {
        skillSubCategories: payload,
      })
        .then((response: any) => {
          if (response.status === 200 && response.data) {
            toast.success(t('successfullyAddedSkill!'));
            dispatch(fetchCandidate(candidate?.id));
            setSkillOptions(
              skillOptions.filter(
                (el) => el?.id !== formValues?.skillSubCategoryId
              )
            );
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          onToggleModal();
          setLoading(false);
        });
    }
  };

  const skills =
    employee?.skillSubCategoriesEmployees ||
    candidate?.skillSubCategoriesCandidates ||
    [];

  return (
    <CardItem title={t('addSkill')} disabled={isDisabled}>
      <>
        <SkillsWrapper
          loading={loading}
          onDeleteSkill={onDeleteSkill}
          skills={skills}
          onAddSkillBtnClick={onToggleModal}
          isAddSkillBtnVisible={skillOptions.length >= 0}
        />

        {modalVisible && (
          <AddSkill
            open={modalVisible}
            skillOptions={skillOptions}
            closeModal={() => onToggleModal()}
            onSubmit={onSubmit}
            loading={loading}
          />
        )}
      </>
    </CardItem>
  );
}
