import React from 'react';
import { Avatar, Col, Popconfirm, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { DeleteIcon } from 'Icons/DeleteIcon';
import styled from 'styled-components';
import { EmployeeNotesType } from 'types/EmployeeNotes';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import {
  ColItem,
  Note,
  NoteDate,
  NoteItemContainer,
  NoteName,
  NoteText,
} from './NoteItemStyled';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
const StyledCol = styled(Col)`
  margin-right: -20px;
`;
interface IProps extends EmployeeNotesType {
  isLast: boolean;
  onDelete: () => void;
  item: any;
  onItemClick: (id: string) => void;
}
export function NoteItem({
  name,
  createdAt,
  description,
  isLast,
  onDelete,
  item,
  onItemClick,
}: IProps) {
  const handleItemClick = () => {
    onItemClick(item.id);
  };
  const handleDeleteButtonClick = (event: any) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };
  const { t } = useTranslation();
  return (
    <NoteItemContainer isLast={isLast}>
      <ColItem span={24} onClick={handleItemClick} isLast={isLast}>
        <Note gutter={[0, 4]} isLast>
          <Col span={24}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col span={21}>
                <NoteDate>{convertUTCtoFormatedLocalTime(createdAt)}</NoteDate>
              </Col>
              <StyledCol span={3}>
                <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                  <Popconfirm
                    placement="topLeft"
                    title={t('areYouSureYouWanttoDelete')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={(e) => handleDeleteButtonClick(e)}
                  >
                    <IconButton onClick={(e) => e.stopPropagation()}>
                      <DeleteIcon />
                    </IconButton>
                  </Popconfirm>
                </RoleBasedButton>
              </StyledCol>
            </Row>
            <Row>
              <Avatar
                style={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                  marginTop: '20px',
                }}
                icon={<UserOutlined />}
                size={30}
              />
              <Row gutter={[0, 20]}>
                <NoteName>{name}</NoteName>
              </Row>
            </Row>
            <Col>
              <Row>
                <NoteText>{description}</NoteText>
              </Row>
            </Col>
          </Col>
        </Note>
      </ColItem>
    </NoteItemContainer>
  );
}
