import { getCandidatesResumeFiles } from 'api/candidateService';
import { getEmployeeResumeFiles } from 'api/employeeService';
import { IResume } from 'types/IResume';
import { prepareExportData } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/exportUtils';

export const handleResumeResponse = (
  data: any,
  entity: string,
  resumes: IResume[]
) =>
  prepareExportData(data, entity).map((item: any) => {
    const resume = resumes.find(
      (file: IResume) => file.name === item.id
    )?.content;
    return resume ? { ...item, resume } : item;
  });

const getResumeFilesApi = (entity: string, ids: string[]) => {
  switch (entity) {
    case 'employees':
      return getEmployeeResumeFiles({ employeeIds: ids });
    default:
      return getCandidatesResumeFiles({ candidateIds: ids });
  }
};

export const getResumeFiles = async (entity: string, ids: string[]) => {
  const data = await getResumeFilesApi(entity, ids);
  return data;
};

export const isExportPathEntity = (entity: string) =>
  entity === 'candidates' || entity === 'employees';
