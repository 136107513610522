import { MetaPagination } from 'types/GridTable';
import {
  VendorQuestionnaire,
  VendorQuestionnaireCategory,
  VendorQuestionnaireScore,
} from 'types/VendorQuestionnaire';

export enum VendorQuestionnaireActionType {
  FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_START = 'FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_START',
  FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS = 'FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS',
  FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED = 'FETCH_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED',

  FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_START = 'FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_START',
  FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS = 'FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_SUCCESS',
  FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED = 'FETCH_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_ERRORED',

  FETCH_VENDOR_QUESTIONNAIRE_START = 'FETCH_VENDOR_QUESTIONNAIRE_START',
  FETCH_VENDOR_QUESTIONNAIRE_SUCCESS = 'FETCH_VENDOR_QUESTIONNAIRE_SUCCESS',
  FETCH_VENDOR_QUESTIONNAIRE_ERRORED = 'FETCH_VENDOR_QUESTIONNAIRE_ERRORED',

  FETCH_VENDOR_QUESTIONNAIRE_SCORE_START = 'FETCH_VENDOR_QUESTIONNAIRE_SCORE_START',
  FETCH_VENDOR_QUESTIONNAIRE_SCORE_SUCCESS = 'FETCH_VENDOR_QUESTIONNAIRE_SCORE_SUCCESS',
  FETCH_VENDOR_QUESTIONNAIRE_SCORE_ERRORED = 'FETCH_VENDOR_QUESTIONNAIRE_SCORE_ERRORED',

  CLEAR_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_STATE = 'CLEAR_SINGLE_VENDOR_QUESTIONNAIRE_CATEGORY_STATE',
  CLEAR_VENDOR_QUESTIONNAIRE_CATEGORY_STATE = 'CLEAR_VENDOR_QUESTIONNAIRE_CATEGORY_STATE',
  CLEAR_VENDOR_QUESTIONNAIRE_SCORE_STATE = 'CLEAR_VENDOR_QUESTIONNAIRE_SCORE_STATE',
  CLEAR_VENDOR_QUESTIONNAIRE_STATE = 'CLEAR_VENDOR_QUESTIONNAIRE_STATE',
}

export interface VendorQuestionnaireState {
  questionnaireCategory: {
    list: {
      data: Array<VendorQuestionnaireCategory>;
      meta: MetaPagination | null;
    };
    selectedQuestionnaireCategory: VendorQuestionnaireCategory | null;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  questionnaire: {
    list: {
      data: Array<VendorQuestionnaire>;
      meta: MetaPagination | null;
    };
    selectedQuestionnaire: VendorQuestionnaire | null;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  questionaireScore: {
    list: {
      data: Array<VendorQuestionnaireScore>;
      meta: MetaPagination | null;
    };
    selectedQuestionnaireScore: VendorQuestionnaireScore | null;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
}
