import React, { useEffect, useState } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import VendorCategoryForm from 'components/NewForms/VendorCategoryForm/VendorCategoryForm';
import {
  clearVendorCategory,
  fetchSingleVendorCategory,
  fetchVendorCategories,
} from 'redux/vendorCategories/actions';
import { useVendorCategoriesData } from 'ui-v2/hooks/useVendorCategoriesData';
import { deleteVendorCategory } from 'api/vendorCategory';

export default function VendorCategoryList() {
  const dispatch = useDispatch();
  const { columns, take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, loading, pagination, vendorCategory } =
    useVendorCategoriesData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const toogleModal = () => setModalVisible((modalVisible) => !modalVisible);

  const onModalClose = () => {
    toogleModal();
    searchParams.delete('edit-vendor-category');
    setSearchParams(searchParams);
    dispatch(clearVendorCategory());
  };
  useEffect(() => {
    let currentPage = pagination?.page ?? 1;
    if (list.length === 1) {
      currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
    }
    dispatch(fetchVendorCategories({ page: currentPage, take }));
  }, [take]);

  useEffect(() => {
    if (searchParams.get('add-vendor-category') === 'true') {
      searchParams.delete('add-vendor-category');
      setSearchParams(searchParams);
      toogleModal();
    }
    if (searchParams.get('edit-vendor-category')) {
      dispatch(
        fetchSingleVendorCategory(
          searchParams.get('edit-vendor-category') ?? ''
        )
      );
      toogleModal();
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    dispatch(
      fetchVendorCategories({
        page,
        take,
        filterText: searchTerm,
        filterFields: ['categoryName'],
      })
    );
  };

  const onSearch = (searchTerm: string) => {
    dispatch(
      fetchVendorCategories({
        page: 1,
        filterText: searchTerm,
        filterFields: ['categoryName'],
      })
    );
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteVendorCategory(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSelectedDelete={onDelete}
          onSearch={onSearch}
          paginationName="VendorCategories"
        />
        {modalVisible && (
          <VendorCategoryForm
            open={modalVisible}
            closeModal={onModalClose}
            selectedVendorCategory={vendorCategory}
          />
        )}
      </>
    </Content>
  );
}
