import { Badge, List, Typography, Button, Menu } from 'antd';
import styled from 'styled-components';

export const NotificationsSidebar = styled.div`
  margin-bottom: 10px;
`;

export const Notifications = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SidebarMenuText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body.semibold.sm,
  color: theme.colors.palette.primary.gray['100'],
  fontSize: '0.875rem',
  width: 'auto',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const Item = styled(List)`
  &:hover {
    cursor: pointer;
    background: #f8f8f9;
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const NotificationDetails = styled.div.attrs(
  (props: { isRead: boolean; onClick?: () => void }) => props
)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${(props) => (props.isRead ? 'transparent' : '#F2F4F7')};
  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  }
`;

export const NotificationTitle = styled(Typography)`
  font-weight: 600;
`;

export const NotificationDescription = styled(Typography)`
  font-weight: 400;
  font-size: 13px;
`;

export const StyledNotificationBadge = styled(Badge)(({ theme }) => ({
  '.ant-badge-count': {
    backgroundColor: theme.colors.palette.primary.gray['500'],
    color: theme.colors.palette.base.white,
  },
}));

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 10px;
`;

export const ActionButton = styled(Button)`
  border: none;
  box-shadow: none;
  color: #40a9ff;
`;

export const ClearButton = styled(Button)`
  border: none;
  box-shadow: none;
  color: #40a9ff;
  padding: 0;
`;

export const Actions = styled(Menu)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NotificationCreatedTime = styled(Typography)`
  font-weight: 400;
  font-size: 11px;
  font-style: italic;
`;

export const ReviewNotification = styled(Button)`
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  font-weight: bold;
`;
