import styled from 'styled-components';
import { Row, Typography } from 'antd';

export const RowItem = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
`;

export const RowItemEmployeeTraining = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
  margin-bottom: 10px;
`;

export const NodataLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
  text-transform: capitalize;
`;

export const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const StatusTagLabel = styled.div<{
  status: string;
}>`
  border-radius: 16px;
  background: #edeff3;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #344054;
  border: none;
  margin-left: 0px;

  ${({ status }) =>
    status === 'new' && 'background-color:#edeff3; color:#344054'};
  ${({ status }) =>
    status === 'in progress' && 'background-color: #dee1e9; color:#344054'};
  ${({ status }) =>
    status === 'done' && 'background-color:#ECFDF3; color:#027A48 '};
`;
