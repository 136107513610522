import moment from 'moment';
import React from 'react';
import { PayrollHoliday } from 'pages/PayrollReport/PayrollDateModal/PayrollDateModal';
import { Tooltip } from 'antd';
import { DEFAULT_USER_DATE_FORMAT } from 'utils/constants';
import { StyledCalendarDate } from './StyledCellRender';

interface CellRenderProps {
  date: moment.Moment;
  holiday?: PayrollHoliday;
  isSelected?: boolean;
}

const CellRender: React.FC<CellRenderProps> = ({
  date,
  holiday,
  isSelected = false,
}) => {
  const formatedDate = date.format('DD');
  const isCurrentDate =
    date.format(DEFAULT_USER_DATE_FORMAT) ===
    moment().format(DEFAULT_USER_DATE_FORMAT);

  return (
    <Tooltip title={holiday ? holiday.name : ''}>
      <StyledCalendarDate
        iscurrentdate={isCurrentDate}
        isholiday={!!holiday}
        isselected={isSelected}
      >
        {formatedDate}
      </StyledCalendarDate>
    </Tooltip>
  );
};

export default CellRender;
