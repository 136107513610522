import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataGrid from 'components/common/DataGrid/DataGrid';
import InterviewModalForm from 'components/NewForms/InterviewModalForm';
import { fetchInterviews } from 'redux/interviewsPage/actions';
import { useInterviewsData } from 'ui-v2/hooks/useInterviewsData';
import { deleteInterview } from 'api/interviewService';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function InterviewsList() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { columns, take } = useHeader();
  const { list: interviews, loading, pagination } = useInterviewsData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>();

  async function deleteInterviewById(id: string | null) {
    if (!id) return;

    deleteInterview(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Interview deleted successfully!');
          dispatch(fetchInterviews({ page: pagination?.page, take: 10 }));
        }
      })
      .catch(toastErrorMessages);
  }

  useEffect(() => {
    if (searchParams.get('schedule-new-interview') === 'true') {
      searchParams.delete('schedule-new-interview');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-interview')) {
      setModalVisible(true);
    }
    if (searchParams.get('delete-interview')) {
      deleteInterviewById(searchParams.get('delete-interview'));
      searchParams.delete('delete-interview');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(fetchInterviews({ page: 1, take }));
  }, []);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchInterviews(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchInterviews(params));
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteInterviewById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={interviews}
          paginationName="Interviews"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSelectedDelete={onDelete}
          onSearch={onSearch}
        />
        {modalVisible && (
          <InterviewModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-interview');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
