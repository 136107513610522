type AvatarBgType = {
  initials: string;
  hue?: number;
  saturation?: number;
  lightness?: number;
};

export const getTenantAvatarStyle = ({
  initials,
  saturation = 30,
  lightness = 60,
}: AvatarBgType) => {
  let hash = 0;
  for (let i = 0; i < initials.length; i += 1) {
    hash = initials.charCodeAt(i) + (hash || 40);
  }
  const hue = hash % 360;
  return { backgroundColor: `hsl(${hue}, ${saturation}%, ${lightness}%)` };
};
