import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTenantConfigPanelContainer } from 'components/NewForms/EditableFormPanel/StyledEditableFormPanel';
import EditableFormPanelCollapse from 'components/NewForms/EditableFormPanel/EditableFormPanelCollapse';
import GenericForm from 'components/NewForms/Form';
import { FormInstance } from 'antd';
import { FormConfigurationType } from 'types/FormTypes';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import { getTenantMissionVisionValuesFormConfig } from '../utils';

interface TenantConfigMissionVisionPanelProps {
  formInstance: FormInstance;
}

const TenantConfigMissionVisionPanel: React.FC<
  TenantConfigMissionVisionPanelProps
> = ({ formInstance }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const { data: tenantConfig } = useTenantConfigData();

  const toogleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const formConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      ...getTenantMissionVisionValuesFormConfig(
        tenantConfig?.missionVisionValues
      ),
    ],
    [tenantConfig?.missionVisionValues]
  );

  return (
    <StyledTenantConfigPanelContainer gutter={[0, 20]}>
      <EditableFormPanelCollapse
        title={t('companyDescription')}
        collapsed={collapsed}
        onClick={toogleCollapse}
      />
      {collapsed && (
        <GenericForm
          form={formInstance}
          formConfiguration={formConfiguration}
        />
      )}
    </StyledTenantConfigPanelContainer>
  );
};

export default TenantConfigMissionVisionPanel;
