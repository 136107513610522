import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import moment from 'moment';

import { VendorQuestionnaireCategory } from 'types/VendorQuestionnaire';
import VendorQuestionnaireActions from 'components/Actions/VendorQuestionnaireActions';

export const VendorQuestionnaireColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a: VendorQuestionnaireCategory, b: VendorQuestionnaireCategory) =>
      moment(a?.createdAt).diff(moment(b?.createdAt)),
    render: (_: string, item: VendorQuestionnaireCategory) => (
      <Styled.TextBold>{item?.name}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('numberOfQuestionnaires'),
    dataIndex: 'vendorQuestionnaires',
    key: 'vendorQuestionnaires',
    ellipsis: true,
    sorter: (a: VendorQuestionnaireCategory, b: VendorQuestionnaireCategory) =>
      (a?.vendorQuestionnaireCount || 0) - (b?.vendorQuestionnaireCount || 0),
    render: (_: string, item: VendorQuestionnaireCategory) => (
      <Styled.TextBold>{item?.vendorQuestionnaireCount || 0}</Styled.TextBold>
    ),
    order: 1,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: VendorQuestionnaireCategory) => (
      <VendorQuestionnaireActions id={row.id} />
    ),
    default: false,
    order: 3,
  },
];
