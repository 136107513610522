import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { AssetCategoryCreateDto, AssetCategory } from 'types/AssetCategories';
import { createAssetCategory, updateAssetCategory } from 'api/assetCategories';
import { fetchAssetCategories } from 'redux/assetCategories/actions';
import { useAssetCategoriesData } from 'ui-v2/hooks/useAssetCategoriesData';

import { toastErrorMessages } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function AddAssetCategoryForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [assetCategory, setAssetCategory] = useState<AssetCategory>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list: assetCategories } = useAssetCategoriesData();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onFormFinish(values: AssetCategoryCreateDto) {
    const valuesToSend: AssetCategoryCreateDto = {
      ...values,
      categoryName: values.categoryName,
    };
    setLoading(true);

    if (assetCategory?.id) {
      updateAssetCategory(assetCategory?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('assetCategoryUpdatedSuccessfully'));
            dispatch(
              fetchAssetCategories({ pageOptions: { page: 1, take: 10 } })
            );
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createAssetCategory(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('assetCategoryCreatedSuccessfully'));
            dispatch(
              fetchAssetCategories({ pageOptions: { page: 1, take: 10 } })
            );
            closeModal();
          }
        })
        .catch(() => {
          toast.error(t('assetCategoryNotCreated'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (searchParams.get('edit-asset-category')) {
      setAssetCategory(
        assetCategories?.find(
          (el: any) => el.id === searchParams.get('edit-asset-category')
        )
      );
      searchParams.delete('edit-asset-category');
      setSearchParams(searchParams);
    }

    if (searchParams.get('add-asset-category') === 'true') {
      searchParams.delete('add-asset-category');
      setSearchParams(searchParams);
      form.submit();
    }

    if (searchParams.get('edit-asset-category-save') === 'true') {
      searchParams.delete('edit-asset-category-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams, form, setSearchParams, assetCategories]);

  const AssetCategoryFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('assetCategoryName'),
          type: InputTypes.INPUT,
          defaultValue: assetCategory?.categoryName || '',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [assetCategory]
  );

  return (
    <GenericModal
      title={assetCategory?.id ? t('editAssetCategory') : t('addAssetCategory')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={AssetCategoryFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default AddAssetCategoryForm;
