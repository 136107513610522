import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/reducers';
import { Request } from 'types/Request';

export function useRequestData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: RootState) => state.requestReducer
  );

  return {
    requests: data as Request[],
    loading,
    loaded,
    errored,
    error,
  };
}
