import HireCandidateForm from 'components/NewForms/HireCandidateForm';
import React from 'react';

import Content from 'ui-v2/components/Content';

export default function HireCandidate() {
  return (
    <Content position="center">
      <HireCandidateForm />
    </Content>
  );
}
