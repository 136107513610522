import styled from 'styled-components';
import { Row, Tag, Typography } from 'antd';

export const RowItemAll = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
`;

export const RowItem = styled(Row)<{
  last?: boolean;
}>`
  border-bottom: ${(props) => (props.last ? '' : '1px solid #eaecf0')};
  padding-bottom: ${(props) => (props.last ? '' : '16px')};
  width: 100%;
  background-color: #eaecf0;
  height: 3.25rem;
  align-items: center;
  border-radius: 12px;
`;

export const ItemLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
`;

export const ItemValue = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
`;

export const NodataLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;

export const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const HireDateStyle = styled(Tag)`
  align-items: center;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
