import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchOpenPositions } from 'redux/openPositions/actions';
import {
  fetchEmploymentTypeOptions,
  fetchWorkPositionsOptions,
} from 'components/NewForms/SelectWithLoad/utils';
import { OpenPositionQueryParamsType } from 'types/QueryParams';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function OpenPositionFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function onFormChange() {
    const workPositions: string[] = form.getFieldValue('workPositionId')?.value
      ? [form.getFieldValue('workPositionId')?.value]
      : [];

    const employmentType: string[] = form.getFieldValue('employmentTypeId')
      ?.value
      ? [form.getFieldValue('employmentTypeId')?.value]
      : [];

    const filterOpenPositionsTable: OpenPositionQueryParamsType = {
      workPositions,
      employmentType,
    };
    if (filterOpenPositionsTable) {
      dispatch(fetchOpenPositions(filterOpenPositionsTable));
    }
  }

  const OpenPositionFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'workPositionId',
          label: t('workPosition'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchWorkPositionsOptions,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'employmentTypeId',
          label: t('employmentType'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchEmploymentTypeOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={OpenPositionFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchOpenPositions());
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
