import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';

import GenericModal from 'ui-v2/components/GenericModal';
import { InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import {
  OnboardingTaskCreateType,
  OnboardingTaskModelType,
  OnboardingTaskStatusOptions,
} from 'types/OnboardingTask';
import { convertDateToUTC, convertUTCtoLocalTime } from 'utils/utilFunctions';
import {
  createOnBoardingTask,
  updateOnBoardingTask,
} from 'api/onBoardingTaskService';
import { toast } from 'react-toastify';
import { getAllEmployees } from 'api/employeeService';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { useTranslation } from 'react-i18next';

export default function OnboardingTaskModal({
  open,
  closeModal,
  onboardingId,
  onboardingTask,
}: {
  open: boolean;
  closeModal: () => void;
  onboardingId: string;
  onboardingTask?: OnboardingTaskModelType;
}) {
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  async function fetchMentors(
    name: string,
    page: number
  ): Promise<SelectLoadPayload> {
    return getAllEmployees({ name, page }).then((response) => ({
      data: response.data?.data?.map((mentor: any) => ({
        key: mentor?.id,
        label: `${mentor?.firstName} ${mentor?.lastName}`,
        value: mentor?.id,
      })),
      pageCount: response?.data?.meta?.pageCount || 1,
    }));
  }

  const OnBoardingTaskConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'boardingTaskType',
          label: t('taskType'),
          type: InputTypes.TEXTAREA,
          defaultValue: onboardingTask?.boardingTaskType,
          rules: [
            {
              required: true,
              message: t('Task type is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'status',
          label: t('status'),
          type: InputTypes.SELECT,
          selectOptions: OnboardingTaskStatusOptions,
          defaultValue: onboardingTask?.status,
          rules: [
            {
              required: true,
              message: t('Status is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'duration',
          label: t('duration'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(onboardingTask?.duration),
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'category',
          label: t('category'),
          type: InputTypes.INPUT,
          defaultValue: onboardingTask?.category,
          rules: [
            {
              required: true,
              message: t('Category is required'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'mentor',
          label: t('mentor'),
          type: 'select-load',
          fetchOptions: fetchMentors,
          defaultValue: onboardingTask?.mentor?.id,
          rules: [
            {
              required: true,
              message: t('Mentor is required'),
            },
          ],
        },
      ],
    ],
    []
  );

  const onFormFinish = async (formValues: OnboardingTaskCreateType) => {
    if (loading && !onboardingId) return;
    isLoading(true);

    const payload: OnboardingTaskCreateType = {
      ...formValues,
      status: formValues.status,
      onBoardingId: onboardingId,
      duration: convertDateToUTC(formValues.duration),
      mentor: formValues?.mentor?.value,
    };

    if (!formValues.duration) {
      delete payload.duration;
    }

    if (onboardingTask?.id) {
      updateOnBoardingTask(onboardingTask?.id, payload)
        .then(() => {
          toast.success(t('taskUpdatedSuccessfully'));
          closeModal();
        })
        .catch(() => {
          toast.error(t('taskFailedtoUpdate'));
        })
        .finally(() => {
          isLoading(false);
        });
      return;
    }

    createOnBoardingTask(payload)
      .then(() => {
        toast.success(t('taskCreatedSuccessfully'));
        closeModal();
      })
      .catch(() => {
        toast.error(t('taskFailedtoCreate'));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  return (
    <GenericModal
      title={
        onboardingTask?.id ? t('editOnboardingTask') : t('addOnboardingTask')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={OnBoardingTaskConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
