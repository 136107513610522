import React from 'react';

export const EvaluationPeriodsIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2.59961H5.8C4.11984 2.59961 3.27976 2.59961 2.63803 2.92659C2.07354 3.21421 1.6146 3.67315 1.32698 4.23764C1 4.87937 1 5.71945 1 7.39961V14.7996C1 16.4798 1 17.3198 1.32698 17.9616C1.6146 18.5261 2.07354 18.985 2.63803 19.2726C3.27976 19.5996 4.11984 19.5996 5.8 19.5996H13.2C14.8802 19.5996 15.7202 19.5996 16.362 19.2726C16.9265 18.985 17.3854 18.5261 17.673 17.9616C18 17.3198 18 16.4798 18 14.7996V11.5996M18.1213 2.47829C19.2929 3.64986 19.2929 5.54936 18.1213 6.72093C16.9497 7.8925 15.0503 7.8925 13.8787 6.72093C12.7071 5.54936 12.7071 3.64986 13.8787 2.47829C15.0503 1.30672 16.9497 1.30672 18.1213 2.47829Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
