import { Task } from 'types/Task';

export enum TasksAction {
  FETCH_TASKS_START = 'FETCH_TASKS_START',
  FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS',
  FETCH_TASKS_ERRORED = 'FETCH_TASKS_ERRORED',

  CLEAR_TASKS = 'CLEAR_TASKS',
}

export interface TasksState {
  data: Array<Task>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
