import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import { getIdeabox, getSingleIdeabox } from 'api/ideaboxService';
import { IdeaboxActions } from './types';

export const fetchIdeabox =
  (queryParams: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: IdeaboxActions.FETCH_IDEABOX_START,
    });
    getIdeabox(queryParams)
      .then((res) => {
        dispatch({
          type: IdeaboxActions.FETCH_IDEABOX_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: IdeaboxActions.FETCH_IDEABOX_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleIdeabox = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: IdeaboxActions.FETCH_SINGLE_IDEABOX_START,
  });
  getSingleIdeabox(id)
    .then((res) => {
      dispatch({
        type: IdeaboxActions.FETCH_SINGLE_IDEABOX_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: IdeaboxActions.FETCH_SINGLE_IDEABOX_ERRORED,
        payload: e,
      });
    });
};

export const clearFetchIdeabox = () => (dispatch: Dispatch) => {
  dispatch({
    type: IdeaboxActions.CLEAR_ALL_IDEABOX,
  });
};

export const clearFetchSingleIdeabox = () => (dispatch: Dispatch) => {
  dispatch({
    type: IdeaboxActions.CLEAR_SINGLE_IDEABOX,
  });
};
