import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
  getUserEmployeeId,
  isAdmin,
  isHeadDepartment,
} from 'utils/utilFunctions';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { RootState } from 'redux/store';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';
import GenericModal from 'ui-v2/components/GenericModal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AddReviewIcon } from 'Icons/AddReviewIcon';
import { fetchDepartmentsList } from 'ui-v2/routes/ProtectedRoutes/Employee/TimeOff/Filters/utils';
import {
  createQuestionnaire,
  updateQuestionnaire,
} from 'api/questionnairesService';
import {
  CreateQuestionnaires,
  UpdateQuestionnaireQuestionDTO,
} from 'types/Questionnaires';
import { clearFetchSingleReivew } from 'redux/questionnaires/actions';
import { AuthUserState } from 'redux/authUser/types';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import QuestionnairesQuestions from './QuestionnairesQuestions';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function QuestionnairesModalForm({ open, closeModal }: IProps) {
  const auth = useSelector((state: RootState) => state.authUser);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdminUser = isAdmin(auth);
  const dispatch = useDispatch();
  const [loading, isLoading] = useState<boolean>(false);
  const [questionnaireQuestionsData, setQuestionnaireQuestionsData] = useState<
    Array<UpdateQuestionnaireQuestionDTO>
  >([]);
  const { t } = useTranslation();
  const { singleQuestionnaire } = useQuestionnairesData();
  const navigate = useNavigate();
  const userDateFormat = getUserDateFormat();
  const [isHeadOfDepartment, setIsHeadOfDepartment] = useState<boolean>(false);

  useEffect(() => {
    const employeeId = (auth as AuthUserState)?.authUser?.employee
      ?.id as string;
    if (employeeId) {
      isHeadDepartment(employeeId).then((res: boolean) => {
        setIsHeadOfDepartment(res);
      });
    }
  }, [(auth as AuthUserState)?.authUser?.id]);

  useEffect(() => {
    if (searchParams.get('add-save-review') === 'true') {
      searchParams.delete('add-save-review');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-save-review') === 'true') {
      searchParams.delete('edit-save-review');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  function onFormFinish(values: CreateQuestionnaires) {
    const valuesToSend: any = {
      departmentId: values?.departments?.value || values?.departments,
      startDate: convertDateToUTC(values?.startDate),
      endDate: convertDateToUTC(values?.endDate),
      employeeId: getUserEmployeeId(auth),
      questions: questionnaireQuestionsData,
    };

    isLoading(true);

    if (singleQuestionnaire?.id) {
      updateQuestionnaire(singleQuestionnaire?.id, valuesToSend)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            toast.success(t('questionnaireUpdatedSuccessfully'));
            navigate(
              `/questionnaires/questionnaire-details?id=${singleQuestionnaire?.id}`
            );
          }
        })
        .catch(() => {
          toast.error(t('questionnaireFailedToUpdate'));
        })
        .finally(() => {
          dispatch(clearFetchSingleReivew());
          isLoading(false);
        });
      return;
    }

    createQuestionnaire(valuesToSend)
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data &&
          valuesToSend?.questions?.length >= 3
        ) {
          closeModal();
          toast.success(t('questionnaireCreatedSuccessfully'));
          navigate(
            `/questionnaires/questionnaire-details?id=${response?.data?.id}`
          );
        }
        if (valuesToSend?.questions?.length < 3) {
          toast.error(t('addThreeQuestions'));
        }
      })
      .catch(() => {
        toast.error(t('questionnaireCreationFailed'));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const QuestionnaireFormConfiguration: FormConfigurationType[][] = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () =>
      isHeadOfDepartment || isAdminUser
        ? [
            [
              {
                col: 24,
                offset: 0,
                name: 'departments',
                label: t('departments'),
                type: InputTypes.SELECT_LOAD,
                fetchOptions: fetchDepartmentsList,
                defaultValue: singleQuestionnaire?.department[0]?.id,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
            ],
            [
              {
                col: 11,
                offset: 0,
                name: 'startDate',
                label: t('startDate'),
                type: InputTypes.DATEPICKER,
                defaultValue: convertUTCtoLocalTime(
                  singleQuestionnaire?.startDate
                ),
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
                dateProps: {
                  format: userDateFormat,
                  showTime: false,
                },
              },
              {
                col: 11,
                offset: 2,
                name: 'endDate',
                label: t('endDate'),
                type: InputTypes.DATEPICKER,
                defaultValue: convertUTCtoLocalTime(
                  singleQuestionnaire?.endDate
                ),
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
                dateProps: {
                  format: userDateFormat,
                  showTime: false,
                  disabledDate: (current: moment.Moment) =>
                    moment(current).isSameOrBefore(
                      form.getFieldValue('startDate')
                    ),
                },
              },
            ],
          ]
        : [
            [
              {
                col: 11,
                offset: 0,
                name: 'startDate',
                label: t('startDate'),
                type: InputTypes.DATEPICKER,
                defaultValue: convertUTCtoLocalTime(
                  singleQuestionnaire?.startDate
                ),
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
              {
                col: 11,
                offset: 2,
                name: 'endDate',
                label: t('endDate'),
                type: InputTypes.DATEPICKER,
                defaultValue: convertUTCtoLocalTime(
                  singleQuestionnaire?.endDate
                ),
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
                dateProps: {
                  format: userDateFormat,
                  showTime: false,
                  disabledDate: (current: moment.Moment) =>
                    moment(current).isSameOrBefore(
                      form.getFieldValue('startDate')
                    ),
                },
              },
            ],
          ],
    [singleQuestionnaire, isHeadOfDepartment]
  );

  return (
    <GenericModal
      title={
        singleQuestionnaire?.id ? t('editQuestionnaire') : t('addQuestionnaire')
      }
      open={open}
      closeModal={closeModal}
      icon={<AddReviewIcon />}
    >
      <GenericForm
        formConfiguration={QuestionnaireFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 10, marginBottom: 0 }}>
          <Styled.TextBold>{t('questions')}</Styled.TextBold>
          <QuestionnairesQuestions
            setQuestionnaireQuestionsData={setQuestionnaireQuestionsData}
          />
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default QuestionnairesModalForm;
