import { Reducer } from 'react';
import { Action } from 'redux';
import { Skills, SkillsState } from './types';

const initialSkillState: SkillsState = {
  skills: {
    data: [],
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  },
  data: {
    data: [],
    meta: null,
  },
  skillCategories: {
    data: [],
    categoryName: null,
    meta: null,
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  },
  skill: {
    data: [],
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SkillsReducer: Reducer<SkillsState, Action> = (
  state = initialSkillState,
  action: any
) => {
  switch (action.type) {
    case Skills.FETCH_ALL_SKILLS_START:
      return {
        ...state,
        skills: { ...state.skills, loading: true, loaded: false },
      };
    case Skills.FETCH_ALL_SKILLS_SUCCESS:
      return {
        ...state,
        skills: {
          ...state.skills,
          loading: false,
          loaded: true,
          data: action?.payload,
        },
      };
    case Skills.FETCH_ALL_SKILLS_ERRORED:
      return {
        ...state,
        skills: {
          ...state.skills,
          loading: false,
          loaded: false,
          errored: true,
          error: action?.payload,
        },
      };
    case Skills.FETCH_SKILLS_START:
      return {
        ...state,
        skillCategories: { ...state.skillCategories, loading: true },
      };
    case Skills.FETCH_SKILLS_SUCCESS:
      return {
        ...state,
        skillCategories: {
          ...state.skillCategories,
          data: action.payload?.data,
          meta: action.payload?.meta,
          loading: false,
          loaded: true,
        },
      };
    case Skills.FETCH_SKILLS_ERRORED:
      return {
        ...state,
        skillCategories: {
          ...state.skillCategories,
          loading: false,
          loaded: false,
          errored: true,
          error: action.payload,
        },
      };
    case Skills.FETCH_SKILL_START:
      return { ...state, skill: { ...state.skill, loading: true } };
    case Skills.FETCH_SKILL_SUCCESS:
      return {
        ...state,
        skill: {
          ...state.skill,
          loading: false,
          loaded: true,
          data: action?.payload?.data,
        },
      };
    case Skills.FETCH_SKILL_ERRORED:
      return {
        ...state,
        skill: {
          ...state.skill,
          loading: false,
          loaded: false,
          errored: true,
          error: action.payload,
        },
      };
    case Skills.FETCH_SUB_SKILLS_START:
      return {
        ...state,
        skillCategories: {
          ...state.skillCategories,
          loading: true,
          errored: false,
        },
      };
    case Skills.FETCH_SUB_SKILLS_SUCCESS:
      return {
        ...state,
        skillCategories: {
          ...state.skillCategories,
          data: action.payload?.skillCategories,
          categoryName: action.payload?.categoryName,
          meta: action.payload?.meta,
          loading: false,
          loaded: true,
          errored: false,
        },
      };
    case Skills.FETCH_SUB_SKILLS_ERRORED:
      return {
        ...state,
        skillCategories: {
          ...state.skillCategories,
          loading: false,
          loaded: false,
          errored: true,
          error: action.payload,
          categoryName: null,
        },
      };
    case Skills.CLEAR_SKILL_DATA:
      return { ...state, skill: { ...initialSkillState.skill } };
    default:
      return state;
  }
};

export { SkillsReducer };
