import { FormInstance } from 'antd';
import i18next from '../../../../i18n';

export const getPopConfirmMsg = (valid: boolean, form: FormInstance) => {
  const username = `${form.getFieldValue('username')}: ${form.getFieldValue(
    'password'
  )}@${form.getFieldValue('host')}:${form.getFieldValue('port')}/db`;

  if (valid) {
    return `${i18next.t('Successfully connected to')} ${username}`;
  }
  return `${i18next.t('Connecting to')} ${username} ${i18next.t('failed')}`;
};
