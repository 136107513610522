import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';

import GenericForm from 'components/NewForms/Form';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { fetchTenants } from 'redux/tenants/actions';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export enum TenantStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export default function TenantsFilter() {
  const [form] = useForm();
  const [clear, setClear] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function onFormSelect(status: string) {
    dispatch(
      fetchTenants({
        queryParams: {
          pageOptions: { page: 1 },
          searchOptions: { status: status as TenantStatus },
        },
      })
    );
  }

  const onClear = () => {
    setClear(() => !clear);
    dispatch(fetchTenants({ queryParams: { pageOptions: { page: 1 } } }));
  };

  const TenantFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'status',
          label: t('status'),
          type: InputTypes.CHECKBOX_GROUP,
          checkboxOptions: [
            {
              id: TenantStatus.APPROVED,
              label: t('approved'),
              value: TenantStatus.APPROVED,
            },
            {
              id: TenantStatus.PENDING,
              label: t('pending'),
              value: TenantStatus.PENDING,
            },
            {
              id: TenantStatus.REJECTED,
              label: t('rejected'),
              value: TenantStatus.REJECTED,
            },
          ],
          checkedOptions: [],
          onSelect: onFormSelect,
        },
      ],
    ],
    [clear]
  );

  return (
    <Row justify="center">
      <Col>
        <GenericForm formConfiguration={TenantFilterConfig} form={form} />
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <StyledPopoverContentButton type="link" onClick={onClear}>
              {t('clearAll')}
            </StyledPopoverContentButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
