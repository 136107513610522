import { Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VendorQuestionaireQuestionListCard from './VendorQuestionaireQuestionListCard';

interface EmptyQuestionnaireQuestionContentProps {
  onClick: () => void;
}
const EmptyQuestionnaireQuestionContent: React.FC<
  EmptyQuestionnaireQuestionContentProps
> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <VendorQuestionaireQuestionListCard onClick={onClick}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('vendorQuestionnaireDetails.noQuestions')}
      />
    </VendorQuestionaireQuestionListCard>
  );
};

export default EmptyQuestionnaireQuestionContent;
