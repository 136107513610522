/* eslint-disable indent */
import { getAllInterviews } from 'api/interviewService';
import { Dispatch } from 'redux';
import { AssetQueryParamsType } from 'types/QueryParams';
import { InterviewsPage } from './types';

export const fetchInterviews =
  (queryParams?: AssetQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: InterviewsPage.FETCH_INTERVIEWS_START,
    });
    getAllInterviews(queryParams)
      .then((res) => {
        dispatch({
          type: InterviewsPage.FETCH_INTERVIEWS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: InterviewsPage.FETCH_INTERVIEWS_ERRORED,
          payload: e,
        });
      });
  };
