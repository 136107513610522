import { AssetLogs } from 'types/AssetLogs';
import { MetaPagination } from 'types/GridTable';

export enum AssetsLogs {
  FETCH_ASSET_LOG_START = 'FETCH_ASSET_LOG_START',
  FETCH_ASSET_LOG_SUCCESS = 'FETCH_ASSET_LOG_SUCCESS',
  FETCH_ASSET_LOG_ERRORED = 'FETCH_ASSET_LOG_ERRORED',

  CLEAR_ASSET_LOG = 'CLEAR_ASSET_LOG',
}

export interface AssetLogsState {
  data: {
    data: Array<AssetLogs>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
