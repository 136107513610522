import React, { useCallback, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useGoogleReCaptcha } from 'react-google-recaptcha-hook';
import { toastErrorMessages } from 'utils/utilFunctions';

interface ReCaptchaProps {
  verify: boolean;
  children: React.ReactNode;
  onVerify: (token: string) => void;
}

export default function ReCaptcha({
  verify,
  children,
  onVerify,
}: ReCaptchaProps) {
  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    process.env?.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string
  );

  const verifyRecaptcha = useCallback(async () => {
    executeGoogleReCaptcha('submit').then(onVerify).catch(toastErrorMessages);
  }, [verify]);

  useEffect(() => {
    if (verify) {
      verifyRecaptcha();
    }
  }, [verify]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env?.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}
