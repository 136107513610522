import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';
import { CreateSkillDto } from 'redux/skills/types';
import { createSkill, updateSkill } from 'api/skillService';
import { fetchSkills } from 'redux/skills/actions';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useSkillsData } from 'ui-v2/hooks/useSkillsData';
import { SkillCategory } from 'types/Skills';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function AddSkillForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [skill, setSkill] = useState<SkillCategory>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { skillCategories } = useSkillsData();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onFormFinish(values: CreateSkillDto) {
    const valuesToSend: CreateSkillDto = {
      ...values,
      categoryName: values.categoryName,
    };
    setLoading(true);

    if (skill?.skills_category_id) {
      updateSkill(skill?.skills_category_id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('skillupdatedSuccessfully'));
            dispatch(fetchSkills({ pageOptions: { page: 1, take: 10 } }));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createSkill(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('skillCreatedSuccessfully'));
            dispatch(fetchSkills({ pageOptions: { page: 1, take: 10 } }));
            closeModal();
          }
        })
        .catch(() => {
          toast.error(t('failedToCreateSkill'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (searchParams.get('edit-skill')) {
      setSkill(
        skillCategories?.data?.find(
          (el) => el.skills_category_id === searchParams.get('edit-skill')
        )
      );
      searchParams.delete('edit-skill');
      setSearchParams(searchParams);
    }

    if (searchParams.get('add-skill-save') === 'true') {
      searchParams.delete('add-skill-save');
      setSearchParams(searchParams);
      form.submit();
    }

    if (searchParams.get('edit-skill-save') === 'true') {
      searchParams.delete('edit-skill-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams, form, setSearchParams, skillCategories]);

  const SkillFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('skillcategoryName'),
          type: InputTypes.INPUT,
          defaultValue: skill?.skills_category_categoryName || '',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [skill, useSkillsData]
  );

  return (
    <GenericModal
      title={skill?.skills_category_id ? t('editCategory') : t('addCategory')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={SkillFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default AddSkillForm;
