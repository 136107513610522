import React, { useCallback } from 'react';
import { Tooltip } from 'antd';
import { LogOutIcon } from 'Icons/LogOutIcon';
import { AuthUserState } from 'redux/authUser/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useSidebarItems } from 'ui-v2/hooks/useSidebarItems';
import { useNavigate } from 'react-router-dom';
import { clearAuthState } from 'redux/authUser/actions';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/Actions/AssetAction';
import useTenantStore from 'redux/persisted-state/tenant-store';
import {
  StyledSidebarMenuItem,
  StyledSidebarMenuText,
} from '../SidebarMenu/StyledSidebarMenu';
import {
  StyledSidebarFooterContent,
  StyledSidebarFooterExit,
  StyledSidebarFooterUserContent,
  StyledSidebarFooterUserCredentialsContent,
  StyledSidebarFooterUserCredentialsText,
  StyledSidebarFooterUserInfo,
} from './StyledSidebarFooter';
import {
  StyledSidebarAvatar,
  StyledSidebarLogoImage,
} from '../SidebarLogo/StyledSidebarLogo';

interface SidebarFooterProps {
  collapsed: boolean;
}

const SidebarFooter: React.FC<SidebarFooterProps> = ({ collapsed }) => {
  const { items, isActivePath } = useSidebarItems();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUser, authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { clearTenant } = useTenantStore();

  const userFullName =
    authUser?.firstName && authUser?.lastName
      ? `${authUser?.firstName} ${authUser?.lastName}`
      : null;

  const userEmail = authUser?.id ? authUser.email : '';

  const settingsItem = items.find((item) => item.path === '/settings');

  const onLogoutClicked = useCallback(() => {
    dispatch(clearAuthState(authUserRole === AUTH_ROLES.SUPER_ADMIN));
    clearTenant();
  }, [authUserRole]);

  const onProfileInfoClick = useCallback(() => {
    if (authUserRole !== AUTH_ROLES.SUPER_ADMIN) {
      navigate('my-profile');
    } else {
      navigate('super-admin-profile');
    }
  }, [authUserRole]);

  return (
    <StyledSidebarFooterContent>
      {settingsItem && (
        <StyledSidebarMenuItem
          collapsed={collapsed}
          onClick={() => navigate(settingsItem.path)}
          active={isActivePath({ items: [settingsItem] })}
        >
          {collapsed ? (
            <Tooltip placement="right" title={t(settingsItem.name)}>
              <span>{settingsItem?.icon}</span>
            </Tooltip>
          ) : (
            settingsItem.icon
          )}
          {!collapsed && (
            <StyledSidebarMenuText>
              {t(settingsItem.name)}
            </StyledSidebarMenuText>
          )}
        </StyledSidebarMenuItem>
      )}
      <StyledSidebarFooterUserContent collapsed={collapsed}>
        <StyledSidebarFooterUserInfo>
          {authUser?.avatar ? (
            <StyledSidebarLogoImage circle src={authUser.avatar} />
          ) : (
            <StyledSidebarAvatar
              src={authUser?.avatar}
              icon={
                authUser?.firstName
                  ? authUser?.firstName?.charAt(0)?.toUpperCase()
                  : 'S'
              }
            />
          )}
          {!collapsed && (
            <StyledSidebarFooterUserCredentialsContent
              onClick={onProfileInfoClick}
            >
              <Tooltip placement="top" title={userFullName}>
                <StyledSidebarFooterUserCredentialsText bold>
                  {userFullName}
                </StyledSidebarFooterUserCredentialsText>
              </Tooltip>
              <Tooltip placement="top" title={userEmail}>
                <StyledSidebarFooterUserCredentialsText>
                  {userEmail}
                </StyledSidebarFooterUserCredentialsText>
              </Tooltip>
            </StyledSidebarFooterUserCredentialsContent>
          )}
        </StyledSidebarFooterUserInfo>

        <StyledSidebarFooterExit>
          <Tooltip placement="right" title="Log Out">
            <IconButton onClick={onLogoutClicked}>
              <LogOutIcon />
            </IconButton>
          </Tooltip>
        </StyledSidebarFooterExit>
      </StyledSidebarFooterUserContent>
    </StyledSidebarFooterContent>
  );
};

export default SidebarFooter;
