import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';

import { UsersQueryParamsType } from 'types/QueryParams';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { fetchUsers } from 'redux/users/actions';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function UsersFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { optionsType: roles } = useGetSelectOptions({
    type: 'roles',
    transform: (item) => ({
      id: item.id,
      value: item.id,
      label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
    }),
  });

  async function onFormChange() {
    const roleId: string = form.getFieldValue('roleId') ?? '';

    const filterUsersTable: UsersQueryParamsType = {
      roleId,
    };

    if (filterUsersTable) {
      if (roleId) dispatch(fetchUsers({ roleId }));
    }
  }

  const UserFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'roleId',
          label: t('roles'),
          type: InputTypes.SELECT,
          selectOptions: roles?.filter(
            (i) => `${i?.label}`.toLocaleLowerCase() !== 'super_admin'
          ),
        },
      ],
    ],
    [roles]
  );
  return (
    <>
      <GenericForm
        formConfiguration={UserFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchUsers());
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
