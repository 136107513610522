import { Col, Row, Tooltip, Popconfirm } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { useTrainingsData } from 'ui-v2/hooks/useTrainingsData';
import { useTranslation } from 'react-i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
type Props = {
  id: string;
};

const TrainingAction = ({ id }: Props) => {
  const navigate = useNavigate();
  const { training } = useTrainingsData();
  const [disable, setDisable] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const onEditTraining = () => {
    if (training?.status === 'inactive') {
      setDisable(false);
    } else {
      searchParams.append('edit-training', id);
      setSearchParams(searchParams);
    }
  };

  const onViewTraining = () => {
    navigate(`training-details?id=${id}`);
  };

  const onDelete = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'delete-training',
        value: id,
      })
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <IconButton onClick={onViewTraining}>
              <ViewProfileIcon />
            </IconButton>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              {disable ? (
                <IconButton onClick={onEditTraining}>
                  <EdititingIcon />
                </IconButton>
              ) : (
                <Tooltip
                  title={t('canNotEditBeacuseIsInactive')}
                  placement="topRight"
                >
                  <IconButton>
                    <EdititingIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Col>
          </RoleBasedButton>
          <Col>
            <Popconfirm
              placement="topLeft"
              title="Do you want to delete this?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <StyledIcon>
                <DeleteIcon />
              </StyledIcon>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrainingAction;
