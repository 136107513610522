import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const Message = styled(Typography.Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
`;

export const StyledRow = styled(Row)`
  flex-wrap: wrap !important;
  @media (max-width: 1568px) {
    flex-wrap: nowrap !important;
  }
  @media (max-width: 1380px) {
    flex-wrap: wrap !important;
  }
`;
