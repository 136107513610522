/* eslint-disable react/no-array-index-key */
import { Col, Form, FormInstance, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import InputSwitcher from '../InputSwitcher';

interface Props {
  formConfiguration: FormConfigurationType[][];
  onFieldsChange?: (values: any) => void;
  onFinish?: (values: any) => void;
  form: FormInstance;
  loading?: boolean;
}

const GenericForm: React.FC<Props> = ({
  children,
  formConfiguration,
  onFieldsChange,
  onFinish,
  form,
  loading,
}) => {
  useEffect(() => {
    let fieldsValue = {};

    formConfiguration.forEach((row) => {
      row.forEach((rowItem) => {
        if (rowItem.defaultValue) {
          fieldsValue = {
            ...fieldsValue,
            [rowItem.name]: rowItem.defaultValue,
          };
        }

        if (rowItem.prefix?.defaultValue) {
          fieldsValue = {
            ...fieldsValue,
            [rowItem.prefix.name]: rowItem.prefix?.defaultValue,
          };
        }
      });
    });

    form.setFieldsValue(fieldsValue);
  }, [formConfiguration]);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        onFinish={!loading ? onFinish : undefined}
        validateTrigger="onChange"
      >
        {formConfiguration.map(
          (rows: FormConfigurationType[], index: number) => (
            <Row key={index}>
              {rows.map((formItem: FormConfigurationType, index: number) => (
                <Col
                  key={`${formItem.col}.${index}`}
                  span={formItem.col}
                  offset={formItem.offset}
                  style={
                    formItem.type === InputTypes.LABEL
                      ? {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }
                      : undefined
                  }
                >
                  <InputSwitcher
                    name={formItem.name}
                    label={formItem.label}
                    checkboxOptions={formItem?.checkboxOptions}
                    checkedOptions={formItem?.checkedOptions}
                    defaultValue={formItem.defaultValue}
                    inputType={formItem.type}
                    placeholder={formItem.label}
                    selectOptions={formItem.selectOptions}
                    prefix={formItem.prefix}
                    rules={formItem?.rules}
                    isMultiSelect={formItem?.isMultiSelect}
                    uploadProps={formItem?.uploadProps}
                    inputProps={formItem?.inputProps}
                    dateProps={formItem?.dateProps}
                    textAreaProps={formItem?.textAreaProps}
                    disabled={formItem?.disabled}
                    birthday={formItem?.birthday}
                    validateStatus={formItem?.validateStatus}
                    help={formItem?.help}
                    showSearch={formItem?.showSearch}
                    fetchOptions={formItem?.fetchOptions}
                    checked={formItem?.checked}
                    mode={formItem?.mode}
                    disableEmployeeReferal={formItem?.disableEmployeeReferal}
                    timeProps={formItem?.timeProps}
                    styles={formItem?.styles}
                    onSelect={formItem?.onSelect}
                    onDeselect={formItem.onDeselect}
                    onSelectChange={formItem.onSelectChange}
                    onChange={formItem?.onChange}
                    onChecked={formItem?.onChecked}
                    direction={formItem?.direction}
                    defaultChecked={formItem?.defaultChecked}
                    hideLabel={formItem?.hideLabel}
                    switchProps={formItem?.switchProps}
                    value={formItem?.defaultValue}
                    hint={formItem?.hint}
                    loading={formItem?.loading}
                  />
                </Col>
              ))}
            </Row>
          )
        )}
        {children}
      </Form>
    </Spin>
  );
};

GenericForm.defaultProps = {
  onFieldsChange: () => {
    console.log();
  },
  onFinish: () => {
    console.log();
  },
  loading: false,
};

export default GenericForm;
