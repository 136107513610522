/* eslint-disable indent */
import { getAllTrainings, getTraining } from 'api/trainingService';
import { Dispatch } from 'redux';
import { TrainingQueryParamsType } from 'types/QueryParams';
import { Trainings } from './types';

export const fetchTrainings =
  (queryParams: TrainingQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Trainings.FETCH_TRAININGS_START,
    });
    getAllTrainings(queryParams)
      .then((res) => {
        dispatch({
          type: Trainings.FETCH_TRAININGS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Trainings.FETCH_TRAININGS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchTraining = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Trainings.FETCH_TRAINING_START,
  });
  getTraining(id)
    .then((res) => {
      dispatch({
        type: Trainings.FETCH_TRAINING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Trainings.FETCH_TRAINING_ERRORED,
        payload: e,
      });
    });
};
