import React from 'react';

export const ProjectsIcon = () => (
  <svg
    width="20"
    height="14.88"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9.39899L1 11.899L10.6422 16.7201C10.7734 16.7857 10.839 16.8185 10.9078 16.8314C10.9687 16.8428 11.0313 16.8428 11.0922 16.8314C11.161 16.8185 11.2266 16.7857 11.3578 16.7201L21 11.899L16 9.39899M1 6.89899L10.6422 2.07788C10.7734 2.01229 10.839 1.97949 10.9078 1.96658C10.9687 1.95515 11.0313 1.95515 11.0922 1.96658C11.161 1.97949 11.2266 2.01229 11.3578 2.07788L21 6.89899L11.3578 11.7201C11.2266 11.7857 11.161 11.8185 11.0922 11.8314C11.0313 11.8428 10.9687 11.8428 10.9078 11.8314C10.839 11.8185 10.7734 11.7857 10.6422 11.7201L1 6.89899Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
