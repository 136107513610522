import { Button, Row, Typography } from 'antd';
import styled from 'styled-components';

export const NavItem = styled(Typography.Link)``;

export const StyledHeaderRow = styled(Row)`
  border-bottom: 1px solid #d0d5dd;
  background: #f9f9f9;
  padding: 1rem 0;
`;

export const StyledHeaderLogoBtn = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg path {
    fill: #0050b3;
  }
`;
export const StyledHeaderLinkBtn = styled(Button)`
  align-items: baseline;
  cursor: pointer;
  [class*='typography'] {
    color: #475467;
  }
  svg path {
    fill: #475467;
  }
  ::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #0050b3;
    transition: width 0.3s;
  }
  :hover {
    [class*='typography'] {
        color: #0050b3;
    }
    svg path {
        fill: #0050b3;
    }
  }
  :hover::after {
    width: 100%;
  }
}
`;
