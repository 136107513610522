import { AUTH_ROLES } from 'types/Auth';
import { UserEmployeeDataType } from 'types/User';

export enum AuthActionTypes {
  ON_SUPER_ADMIN_LOGIN = '@@authUser/ON_SUPER_ADMIN_LOGIN',
  ON_LOGIN = '@@authUser/ON_LOGIN',
  ON_TENANTED_LOGIN = '@@authUser/ON_TENANTED_LOGIN',
  ON_REGISTER = '@@authUser/ON_REGISTER',
  GET_USER = '@@authUser/GET_USER',
  ON_LOGOUT = '@@authUser/ON_LOGOUT',
  ON_TWO_FACTOR_AUTHENTICATION = '@@authUser/ON_TWO_FACTOR_AUTHENTICATION',
  ACTION_COMPLETED = '@@authUser/ACTION_COMPLETED',
  ACTION_TWO_FACTOR_REQUIRED = '@@authUser/ACTION_TWO_FACTOR_REQUIRED',
  UPDATE_AUTH_STATE = '@@authUser/UPDATE_AUTH_STATE',
  ACTION_TWO_FACTOR_QR_CODE_REQUIRED = '@@authUser/ACTION_TWO_FACTOR_QR_CODE_REQUIRED',
  ACTION_FAILED = '@@authUser/ACTION_FAILED',
  ACTION_LOGGED_IN_USER_ROLE = '@@authUser/ACTION_LOGGED_IN_USER_ROLE',
  FIRST_LOGIN = '@@authUser/FIRST_LOGIN',
  ACTION_ACCOUNT_BLOCKED = '@@authUser/ACTION_ACCOUNT_BLOCKED',
  CLEAR_AUTH_STATE = '@@authUser/CLEAR_AUTH_STATE',
}

export interface RolesType {
  id: string;
  name: string;
}

export interface UserDataType {
  avatar: string;
  birthDate: Date;
  birthday?: Date;
  email: string;
  firstName: string;
  id: string;
  isActive: boolean;
  lastName: string;
  password: string;
  phoneNumber: string;
  phoneNumberPrefix: number;
  roles: RolesType[];
  employee: UserEmployeeDataType;
  isPasswordSet: boolean;
  isSuperAdmin: boolean;
  isTwoFAComplete: boolean;
  gender: string;
  lang: string | null;
}
export interface ErrorType {
  message: string;
}

export interface UserAuthDataType {
  [x: string]: any;
  data: UserDataType | null;
  error: ErrorType | null;
  loading: boolean;
}
export interface AuthUserCredentials {
  email: string;
  password: string;
  timezone: string;
}

export interface AuthActionPayload {
  type: AuthActionTypes;
  userCredentials: AuthUserCredentials;
}
export enum PermissionAction {
  CAN_VIEW = 'canView',
  CAN_EDIT = 'canEdit',
  CAN_HIRE = 'canHire',
  CAN_DELETE = 'canDelete',
}
export interface AuthPermission {
  id: string;
  action: PermissionAction;
}

export interface AuthUserState {
  authUser?: UserDataType | null;
  authUserRole?: AUTH_ROLES | null;
  authUserPermissions?: Array<AuthPermission> | null;
  twoFactorRequired?: boolean;
  twoFactorSetupRequired?: boolean;
  accountBlocked?: boolean;
  loading?: boolean;
  phoneNumberPrefix?: number;
  error?: ErrorType | null;
}

export interface SuperAdminLoginPaylod {
  superAdmin: {
    id: string;
    email: string;
    isSuperAdmin: boolean;
    isTwoFAComplete: boolean;
  };
  token: string;
}

export interface TwoFactorAuthenticationPayload {
  authentionCode: string;
  isSuperAdminLogin: boolean;
}
