import React from 'react';
import moment from 'moment';
import { Calendar } from 'antd';
import { DEFAULT_USER_DATE_FORMAT } from 'utils/constants';
import { isEqual } from 'lodash';
import HeaderRender from './HeaderRender';
import CellRender from './CellRender';
import { PayrollHoliday } from '../PayrollDateModal/PayrollDateModal';

interface PayrollDateCalendarProps {
  selectedDate: moment.Moment;
  holidays: PayrollHoliday[];
  setDate: (date: moment.Moment) => void;
}

const PayrollDateCalendar: React.FC<PayrollDateCalendarProps> = ({
  holidays,
  selectedDate,
  setDate,
}) => (
  <Calendar
    fullscreen={false}
    value={selectedDate}
    headerRender={(config) => (
      <HeaderRender headerConfig={config} setDate={setDate} />
    )}
    onSelect={setDate}
    dateFullCellRender={(date) => (
      <CellRender
        date={date}
        holiday={holidays?.find((holiday) =>
          isEqual(
            holiday.date.format(DEFAULT_USER_DATE_FORMAT),
            date.format(DEFAULT_USER_DATE_FORMAT)
          )
        )}
        isSelected={isEqual(
          date.format(DEFAULT_USER_DATE_FORMAT),
          selectedDate?.format(DEFAULT_USER_DATE_FORMAT)
        )}
      />
    )}
  />
);

export default PayrollDateCalendar;
