/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import {
  getAllEmployees,
  getEmployee,
  getEmployeeAssignments,
  getEmployeeDocuments,
  getEmployeesBulkUploadDetails,
} from 'api/employeeService';
import { Dispatch } from 'redux';
import { EmployeesQueryParamsType } from 'types/QueryParams';
import { Employees } from './types';

export const clearSingleEmployee = () => (dispatch: Dispatch) => {
  dispatch({
    type: Employees.CLEAR_SINGLE_EMPLOYEE
  })
}

// GETS EMPLOYEE DOCUMENTS
export const fetchEmployeeDocuments =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: Employees.FETCH_EMPLOYEE_DOCUMENTS_START,
    });

    getEmployeeDocuments(id)
      .then((res) => {
        dispatch({
          type: Employees.FETCH_EMPLOYEE_DOCUMENTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({ type: Employees.FETCH_EMPLOYEE_DOCUMENTS_ERRORED, payload: e });
      });
  };

// GETS All EMPLOYEES
export const fetchEmployees =
  (queryParams?: EmployeesQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Employees.FETCH_EMPLOYEES_START,
    });

    getAllEmployees(queryParams)
      .then((res) => {
        dispatch({
          type: Employees.FETCH_EMPLOYEES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({ type: Employees.FETCH_EMPLOYEES_ERRORED, payload: e });
      });
  };

  export const fetchEmployeesBulkUploadDetails =
  () => (dispatch: Dispatch) => {
    dispatch({
      type: Employees.FETCH_BULK_UPLOAD_START,
    });

    getEmployeesBulkUploadDetails({})
      .then((res) => {
        dispatch({
          type: Employees.FETCH_BULK_UPLOAD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({ type: Employees.FETCH_BULK_UPLOAD_ERRORED, payload: e });
      });
  };

// GETS SPECIFIC EMPLOYEE DETAILS

export const fetchEmployeesDetail = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Employees.FETCH_EMPLOYEES_DETAIL_START,
  });

  getEmployee(id)
    .then((res) => {
      dispatch({
        type: Employees.FETCH_EMPLOYEES_DETAIL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({ type: Employees.FETCH_EMPLOYEES_DETAIL_ERRORED, payload: e });
    });
};

// GETS SPECIFIC EMPLOYEE ALL ASSIGNMNETS

export const fetchEmployeeAssignments =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: Employees.FETCH_EMPLOYEE_ASSIGNMENTS_START,
    });

    getEmployeeAssignments(id)
      .then((res) => {
        dispatch({
          type: Employees.FETCH_EMPLOYEE_ASSIGNMENTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Employees.FETCH_EMPLOYEE_ASSIGNMENTS_ERRORED,
          payload: e,
        });
      });
  };
