import React from 'react';
import { GetTimeOffToApproveDTO, TimeOffRequest } from 'types/TimeOff';

import CardItem from 'ui-v2/components/Card';
import TimeOffCalendarAdmin from 'ui-v2/components/TimeOffCalendarAdmin';
import Filters from '../Filters';

interface IProps {
  requests: Array<TimeOffRequest>;
  setRequests: (requests: Array<TimeOffRequest>) => void;
  fetchDataApprover: (data: GetTimeOffToApproveDTO) => void;
}

export default function TimeOffCalendarAdminCard({
  requests,
  setRequests,
  fetchDataApprover,
}: IProps) {
  return (
    <CardItem
      wrapContentHeight
      rightButton={<Filters fetchDataApprover={fetchDataApprover} />}
      rightButtonContent={10}
    >
      <TimeOffCalendarAdmin requests={requests} setRequests={setRequests} />
    </CardItem>
  );
}
