import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const OpenPositionActions: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const onDelete = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'delete-open-position',
        value: id,
      })
    );
  };

  const onEditClick = () => {
    navigate(`edit-open-position?id=${id}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('Do you want to delete this?')}
                onConfirm={onDelete}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default OpenPositionActions;
