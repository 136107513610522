/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import { Row, Tag, Typography, Button } from 'antd';
import styled from 'styled-components';

export const StyleText = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #475467;
  font-style: italic;
  text-transform: capitalize;
`;

export const BenefitItemText = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #101828;
`;
export const TypeTag = styled(Typography.Text)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  padding: 2px 8px;
  color: #475467;
  border-radius: 16px;
  font-weight: 500;
  background-color: #edeff3 !important;
`;

export const Info = styled(Row)`
  padding-top: 5px;
`;

export const PlanTag = styled(Tag)`
  background-color: #e6f7ff;
  color: #1890ff;
  border-color: #91d5ff;
  border-radius: 20px;
`;

export const Status = styled(Tag)<{
  status: string;
}>`
  border-radius: 8px;
  ${({ status }) =>
    status === 'APPROVED'
      ? 'color: green'
      : status === 'REJECTED'
      ? 'color: red'
      : 'border: 1px solid #EFF4FF; background: #EFF4FF;color: #00bfff; svg{path{stroke: #004EEB}'};
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  width: 100%;
  svg {
  }
  ${(props) => {
    if (props.type === 'primary') {
      return 'background: #155EEF; color: #FFFFFF; svg{path{stroke: white} ; margin-right: 8px}';
    }
    if (props.type === 'link') {
      return 'border: 1px solid #EFF4FF; background: #EFF4FF;color: #004EEB; svg{path{stroke: #004EEB} margin-right: 8px}';
    }
    if (props.danger) {
      return 'border: 1px solid #FDA29B; color: #B42318;svg{path{stroke: #B42318} margin-right: 8px}';
    }
    return 'border: 1px solid #D0D5DD; color: #344054;svg{path{stroke: #344054} margin-right: 8px} ';
  }}
  :hover {
    span {
      color: unset !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;
