import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { fetchEmployees } from 'redux/employees/actions';
import {
  JobCategoryType,
  JobSubCategoryType,
  JobSubCategoryWithJobCategoryType,
} from 'types/JobCategory';
import { getAllJobCategoriesWithSubCategories } from 'api/jobCategoryService';
import { OptionType } from 'types/OptionTypes';
import { toast } from 'react-toastify';
import { fetchEmploymentTypeOptions } from 'components/NewForms/SelectWithLoad/utils';
import { EmployeesQueryParamsType } from 'types/QueryParams';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function EmployeesFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [skillsList, setSkillList] = useState<OptionType[]>([]);
  const [filters, setFilters] = useState<EmployeesQueryParamsType>({});

  const applyFilters = useCallback(() => {
    dispatch(fetchEmployees(filters));
  }, [filters]);

  useEffect(() => {
    applyFilters();
  }, [filters]);

  async function onFormChange() {
    setFilters({
      skillSubCategories: form.getFieldValue('skill') ?? [],
      employmentTypes: form.getFieldValue('employmentTypeId')?.value && [
        form.getFieldValue('employmentTypeId')?.value,
      ],
      archived: form.getFieldValue('archived') ?? false,
    });
  }

  const fetchSkills = async () => {
    await getAllJobCategoriesWithSubCategories()
      .then((response) => {
        if (response.status === 200 && response.data) {
          const allSubCategories = response.data
            .map((item: JobCategoryType) => item.subCategories)
            .flat();
          const uniqueSubs: Array<string> = [];
          allSubCategories.map((item: JobSubCategoryType) => {
            if (!uniqueSubs.includes(item.id)) {
              uniqueSubs.push(item.id);
            }
            return item;
          });
          const skillsFound: JobSubCategoryWithJobCategoryType[] =
            uniqueSubs.map((item) =>
              allSubCategories.find((i: JobSubCategoryType) => i.id === item)
            );
          const transformedSkills: OptionType[] = skillsFound.map(
            (skill: JobSubCategoryWithJobCategoryType) => ({
              id: skill.id,
              value: skill.id,
              label: skill.subCategoryName,
            })
          );
          setSkillList(transformedSkills);
        }
      })
      .catch(() => {
        toast.error(t("Something went wrong, can't get all skills"));
      });
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const EmployeesConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'employmentTypeId',
          label: t('employmentType'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchEmploymentTypeOptions,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'skill',
          label: t('skills'),
          type: InputTypes.SELECT,
          selectOptions: skillsList,
          isMultiSelect: true,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'archived',
          label: t('archived'),
          type: InputTypes.SWITCH,
        },
      ],
    ],
    [skillsList]
  );

  return (
    <>
      <GenericForm
        formConfiguration={EmployeesConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchEmployees());
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
