import React, { useEffect, useState } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import useHeader from 'ui-v2/hooks/useHeader';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchAsset, fetchAssets } from 'redux/assets/actions';

import { useAssetsData } from 'ui-v2/hooks/useAssetsData';
import { deleteAsset } from 'api/assetService';
import { useTranslation } from 'react-i18next';

export default function AssetsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useAssetsData();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteAssetById = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await deleteAsset(id);
      if (response.status === 200) {
        toast.success(`${t('assetDeletedSuccessfully')}!`);
        let currentPage = pagination?.page;
        if (list.length === 1) {
          currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
        }
        dispatch(fetchAssets({ page: currentPage, take }));
      }
    } catch (error) {
      toast.error(`${t('assetFailedToDelete')}!`);
    }
  };

  useEffect(() => {
    if (searchParams.get('add-asset')) {
      searchParams.delete('add-asset');
      setSearchParams(searchParams);
    }
    if (searchParams.get('edit-asset')) {
      dispatch(fetchAsset(searchParams.get('edit-asset') ?? ''));
      setSearchParams(searchParams);
    }
    if (searchParams.get('delete-asset')) {
      deleteAssetById(searchParams.get('delete-asset'));
      searchParams.delete('delete-asset');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchAssets(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchAssets(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    dispatch(fetchAssets({ page: 1, take: 10 }));
  }, []);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteAssetById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Assets"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onSelectedDelete={onDelete}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
      </>
    </Content>
  );
}
