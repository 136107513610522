import { Notification } from 'types/Notification';

export enum Notifications {
  FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_ERRORED = 'FETCH_NOTIFICATIONS_ERRORED',

  PUSH_NOTIFICATION_START = 'PUSH_NOTIFICATION_START',
}

export interface NotificationsState {
  data: Array<Notification>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
