import { Col, Form, Row } from 'antd';
import { RcFile } from 'antd/es/upload';
import { createProjectDocument } from 'api/projectService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import { UploadMOdalIcon } from 'Icons/UploadModalIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchSingleProject } from 'redux/projects/actions';
import { FormConfigurationType } from 'types/FormTypes';
import { FileType, ProjectDocumentCreate } from 'types/ProjectDocument';
import GenericModal from 'ui-v2/components/GenericModal';
import { convertBase64, getValidFileContent } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export default function AddProjectDocumentModal({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState<FileType>({
    content: '',
    fileName: '',
  });
  const { t } = useTranslation();

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFile({ fileName: file.name, content: validBase64 });
    return false;
  }

  const onSubmit = async () => {
    const projectId = searchParams.get('id');
    if (loading || !projectId) return;

    setLoading(true);
    const formValues: ProjectDocumentCreate = form.getFieldsValue(true);
    await createProjectDocument({ ...formValues, name: file, projectId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          toast.success(t('successfullyAddedDocument'));
        }
      })
      .catch(() => {
        toast.error(t('failedtoAddDocument'));
      })
      .finally(() => {
        setLoading(false);
        closeModal();
        dispatch(fetchSingleProject(projectId));
      });
  };

  const AddProjectDocFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: 'textarea',
          rules: [
            {
              required: true,
              message: t('Please enter a description'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          type: 'upload',
          label: t('upload'),
          rules: [
            {
              required: true,
              message: t('please select or drag and drop a document!'),
            },
          ],
          uploadProps: {
            accept: '.pdf,.doc,.docx',
            beforeUpload: (file: RcFile) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            iconRender: () => <FileOutlinedIcon />,
          },
        },
      ],
    ],
    []
  );

  return (
    <GenericModal
      title={t('addDocument')}
      open={open}
      closeModal={closeModal}
      icon={<UploadMOdalIcon />}
    >
      <GenericForm
        formConfiguration={AddProjectDocFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
