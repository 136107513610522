/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  PointElement,
  Filler,
  LineElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import CardItem from 'ui-v2/components/Card';
import { DashboardPieStatsType } from 'types/DashboardStats';
import { showDashboardPieStats } from 'api/dashboardStats';
import { Col, Row, Spin, Typography } from 'antd';
import { FAVORITE_PALETTE, PALETE } from 'components/styledComponents/colors';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const PieChart = () => {
  const [dashboardPieStats, setDashboardPieStats] =
    useState<DashboardPieStatsType>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  async function fetchDashboardPieStats() {
    try {
      const { data } = await showDashboardPieStats();
      setDashboardPieStats(data);
      setLoading(false);
    } catch (error) {
      console.log('error');
    }
  }

  useEffect(() => {
    fetchDashboardPieStats();
  }, []);

  const data: ChartData<'pie', number[], string> = useMemo(
    () => ({
      labels: dashboardPieStats
        ? dashboardPieStats.map((item) => item.name)
        : [],
      datasets: [
        {
          label: t('openPositions'),
          data: dashboardPieStats
            ? dashboardPieStats.map((item) => item.candidate_count)
            : [],
          backgroundColor: dashboardPieStats
            ? dashboardPieStats.map(
                (_, index) =>
                  FAVORITE_PALETTE[index] ??
                  Object.values(PALETE)[
                    Math.floor(Math.random() * Object.values(PALETE).length)
                  ]
              )
            : [],
          borderWidth: 1,
        },
      ],
    }),
    [dashboardPieStats]
  );

  return (
    <CardItem title={t('candidatebyPositions')} minHeight={375}>
      <Spin spinning={loading}>
        {!loading && dashboardPieStats?.length === 0 ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 350,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {t('noDataYet.')}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="candidates/add-candidate"
              >
                {t('addSomeCandidatesFirst!')}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <Pie data={data} />
        )}
      </Spin>
    </CardItem>
  );
};

export default PieChart;
