import React, { useEffect } from 'react';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { useTranslation } from 'react-i18next';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import {
  StyledTrackingTabsCol,
  StyledTrackingTabsLabel,
  StyledTrackingTabsRow,
} from './StyledTrackingTabs';

const TrackingTabs: React.FC = () => {
  const {
    urlState: { tab },
    tabLabels,
    setUrlState,
  } = useTrackedHoursData();
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  useEffect(() => {
    if (!tab && tabLabels?.length >= 0) {
      setUrlState({ tab: tabLabels[0] });
    }
  }, [tab, tabLabels]);

  if (authUserRole === AUTH_ROLES.GUEST_TRACKING_HOURS) {
    return null;
  }

  return (
    <StyledTrackingTabsRow align="middle" cols={tabLabels.length}>
      {tabLabels.map((label, index) => (
        <StyledTrackingTabsCol
          key={label}
          active={label === tab}
          {...(index !== 0 &&
            index < tabLabels.length - 1 && { bordered: true })}
          onClick={() => setUrlState({ tab: label })}
        >
          <StyledTrackingTabsLabel>{t(label)}</StyledTrackingTabsLabel>
        </StyledTrackingTabsCol>
      ))}
    </StyledTrackingTabsRow>
  );
};

export default TrackingTabs;
