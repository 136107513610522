import { OpenPositionType } from 'interfaces/OpenPosition';
import { MetaPagination } from 'types/GridTable';

export enum OpenPositions {
  FETCH_OPEN_POSITION_START = 'FETCH_OPEN_POSITION_START',
  FETCH_OPEN_POSITION_SUCCESS = 'FETCH_OPEN_POSITION_SUCCESS',
  FETCH_OPEN_POSITION_ERRORED = 'FETCH_OPEN_POSITION_ERRORED',
}

export interface OpenPositionsState {
  data: {
    data: Array<OpenPositionType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
