import { getAssetById } from 'api/assetService';
import AssetForm from 'components/NewForms/AssetForm/AssetForm';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AssetType } from 'types/Asset';
import Content from 'ui-v2/components/Content';

export default function EditAsset() {
  const [assetData, setAssetData] = useState<AssetType>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const getAssetData = async (id: string) => {
    if (id) {
      getAssetById(id)
        .then((response) => {
          const { data } = response;
          setAssetData(data);
        })
        .catch(() => {
          toast.error(t('errorFetchingAssets'));
        });
    }
  };

  useEffect(() => {
    if (id) {
      getAssetData(id);
    }
  }, [id]);
  return (
    <Content position="center">
      <AssetForm selectedAsset={assetData} />
    </Content>
  );
}
