import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { StyledDateRange } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import {
  StyledTrackingDateFilterPopoverDefaultRangeWrapper,
  StyledTrackingDateFilterPopoverLabel,
} from './StyledTrackingDateFilter';
import {
  TRACKING_DATE_FORMAT_TYPE,
  compareTrackingDates,
  getTrackingDatePopoverDefaultRangeOptions,
} from './utils';

interface TrackingDatePopoverContentProps {
  tooglePopoverVisibility: () => void;
}

const TrackingDatePopoverContent: React.FC<TrackingDatePopoverContentProps> = ({
  tooglePopoverVisibility,
}) => {
  const {
    urlState: { startDate, endDate, employeeIds, projectIds },
    setUrlState,
    fetch,
  } = useTrackedHoursData();
  const { t } = useTranslation();

  const defaultRanges = getTrackingDatePopoverDefaultRangeOptions();

  const checkIfActiveOption = ({
    optStart,
    optEnd,
  }: {
    optStart: string;
    optEnd: string;
  }) => {
    if (!startDate || !endDate) {
      return false;
    }
    if (
      compareTrackingDates(optStart, startDate) &&
      compareTrackingDates(optEnd, endDate)
    ) {
      return true;
    }
    return false;
  };

  const onDefaultRangeClick = useCallback(
    ({ optStart, optEnd }: { optStart: string; optEnd: string }) => {
      setUrlState({ startDate: optStart, endDate: optEnd });
      fetch({
        searchOptions: {
          startDate: optStart,
          endDate: optEnd,
          employees: employeeIds,
          projects: projectIds,
        },
      });
      tooglePopoverVisibility();
    },
    [employeeIds, projectIds, startDate, endDate]
  );

  const onCustomRangeSelected = useCallback(
    (data: moment.Moment[]) => {
      const [startDate, endDate] = data;
      const start = startDate.format(TRACKING_DATE_FORMAT_TYPE.URL);
      const end = endDate.format(TRACKING_DATE_FORMAT_TYPE.URL);
      setUrlState({
        startDate: start,
        endDate: end,
      });
      fetch({
        searchOptions: {
          startDate: start,
          endDate: end,
          employees: employeeIds,
          projects: projectIds,
        },
      });
    },
    [employeeIds, projectIds, startDate, endDate]
  );

  return (
    <Row>
      <StyledTrackingDateFilterPopoverDefaultRangeWrapper span={8}>
        <Row>
          {defaultRanges.map((option) => (
            <StyledTrackingDateFilterPopoverLabel
              span={24}
              key={option.id}
              active={checkIfActiveOption({
                optStart: option.start,
                optEnd: option.end,
              })}
              onClick={() =>
                onDefaultRangeClick({
                  optStart: option.start,
                  optEnd: option.end,
                })
              }
            >
              {t(option.label)}
            </StyledTrackingDateFilterPopoverLabel>
          ))}
        </Row>
      </StyledTrackingDateFilterPopoverDefaultRangeWrapper>
      <Col span={16}>
        <Row justify="center">
          <Col>
            <StyledDateRange
              value={[moment(startDate), moment(endDate)]}
              format={TRACKING_DATE_FORMAT_TYPE.POPOVER_OPEN}
              onChange={(values) =>
                onCustomRangeSelected([
                  moment(values?.[0]),
                  moment(values?.[1]),
                ])
              }
              onOpenChange={(open) => {
                if (!open) {
                  tooglePopoverVisibility();
                }
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrackingDatePopoverContent;
