import { Reducer } from 'react';
import { Action } from 'redux';

import { TableConfig, TableConfigState } from './types';

const initialState: TableConfigState = {
  config: {},
  take: 10,
};

const reducer: Reducer<TableConfigState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case TableConfig.SET_TABLE_CONFIG:
      state = {
        ...state,
        config: {
          ...state.config,
          ...{ [action.payload.entity]: action.payload.columns },
        },
        take: action?.payload?.take ?? 10,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as TableConfigReducer };
