import React, { useCallback, useEffect, useState } from 'react';
import { RulesType } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isNil } from 'lodash';
import { FormItemStyled } from '../FormStyled';
import { StyledCheckboxGroup } from './StyledCheckboxGroup';

interface CheckboxGroupProps {
  label: string;
  name: string;
  mode?: 'multiple' | 'tags';
  rules?: RulesType[];
  onSelect?: (id: string) => void;
  checkboxOptions?: Array<OptionType>;
  checkedOptions?: Array<OptionType>;
  hint?: React.ReactNode | string;
}
const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  name,
  mode,
  rules,
  onSelect,
  checkboxOptions,
  checkedOptions,
  hint,
}) => {
  const [checkedItems, setCheckedItems] = useState<OptionType[]>([]);

  const onChange = useCallback(
    (e: CheckboxChangeEvent, option: OptionType) => {
      if (e.target.checked) {
        onSelect?.(option?.id);
        setCheckedItems(
          mode === 'multiple' ? [...(checkedItems ?? []), option] : [option]
        );
      } else {
        setCheckedItems(
          mode === 'multiple'
            ? [...(checkedItems?.filter((el) => el?.id !== option?.id) ?? [])]
            : []
        );
      }
    },
    [checkedItems, mode]
  );

  useEffect(() => {
    if (!isNil(checkedOptions) && checkedOptions?.length) {
      setCheckedItems(checkedOptions as OptionType[]);
    } else {
      setCheckedItems([]);
    }
  }, [checkedOptions]);

  return (
    <FormItemStyled
      name={name}
      rules={rules}
      label={label}
      {...(hint && { tooltip: hint })}
    >
      <Row gutter={[0, 5]}>
        {checkboxOptions?.map((option) => (
          <Col span={24}>
            <StyledCheckboxGroup
              key={option?.id}
              checked={!!checkedItems?.find((item) => item?.id === option?.id)}
              onChange={(e: CheckboxChangeEvent) => onChange(e, option)}
            >
              {option?.label}
            </StyledCheckboxGroup>
          </Col>
        ))}
      </Row>
    </FormItemStyled>
  );
};

CheckboxGroup.defaultProps = {
  rules: [],
  checkboxOptions: [],
  checkedOptions: [],
};

export default CheckboxGroup;
