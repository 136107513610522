export const prefixSelector = [
  { id: '+1', value: '+1', label: '+1' }, // USA, Canada
  { id: '+7', value: '+7', label: '+7' }, // Russia, Kazakhstan
  { id: '+20', value: '+20', label: '+20' }, // Egypt
  { id: '+30', value: '+30', label: '+30' }, // Greece
  { id: '+31', value: '+31', label: '+31' }, // Netherlands
  { id: '+32', value: '+32', label: '+32' }, // Belgium
  { id: '+33', value: '+33', label: '+33' }, // France
  { id: '+34', value: '+34', label: '+34' }, // Spain
  { id: '+36', value: '+36', label: '+36' }, // Hungary
  { id: '+39', value: '+39', label: '+39' }, // Italy
  { id: '+40', value: '+40', label: '+40' }, // Romania
  { id: '+41', value: '+41', label: '+41' }, // Switzerland
  { id: '+44', value: '+44', label: '+44' }, // United Kingdom
  { id: '+45', value: '+45', label: '+45' }, // Denmark
  { id: '+46', value: '+46', label: '+46' }, // Sweden
  { id: '+47', value: '+47', label: '+47' }, // Norway
  { id: '+48', value: '+48', label: '+48' }, // Poland
  { id: '+49', value: '+49', label: '+49' }, // Germany
  { id: '+51', value: '+51', label: '+51' }, // Peru
  { id: '+52', value: '+52', label: '+52' }, // Mexico
  { id: '+53', value: '+53', label: '+53' }, // Cuba
  { id: '+54', value: '+54', label: '+54' }, // Argentina
  { id: '+55', value: '+55', label: '+55' }, // Brazil
  { id: '+56', value: '+56', label: '+56' }, // Chile
  { id: '+57', value: '+57', label: '+57' }, // Colombia
  { id: '+58', value: '+58', label: '+58' }, // Venezuela
  { id: '+60', value: '+60', label: '+60' }, // Malaysia
  { id: '+61', value: '+61', label: '+61' }, // Australia
  { id: '+62', value: '+62', label: '+62' }, // Indonesia
  { id: '+63', value: '+63', label: '+63' }, // Philippines
  { id: '+64', value: '+64', label: '+64' }, // New Zealand
  { id: '+65', value: '+65', label: '+65' }, // Singapore
  { id: '+66', value: '+66', label: '+66' }, // Thailand
  { id: '+81', value: '+81', label: '+81' }, // Japan
  { id: '+82', value: '+82', label: '+82' }, // South Korea
  { id: '+84', value: '+84', label: '+84' }, // Vietnam
  { id: '+86', value: '+86', label: '+86' }, // China
  { id: '+90', value: '+90', label: '+90' }, // Turkey
  { id: '+91', value: '+91', label: '+91' }, // India
  { id: '+92', value: '+92', label: '+92' }, // Pakistan
  { id: '+93', value: '+93', label: '+93' }, // Afghanistan
  { id: '+94', value: '+94', label: '+94' }, // Sri Lanka
  { id: '+95', value: '+95', label: '+95' }, // Myanmar
  { id: '+98', value: '+98', label: '+98' }, // Iran
  { id: '+351', value: '+351', label: '+351' }, // Portugal
  { id: '+355', value: '+355', label: '+355' }, // Albania
  { id: '+356', value: '+356', label: '+356' }, // Malta
  { id: '+357', value: '+357', label: '+357' }, // Cyprus
  { id: '+358', value: '+358', label: '+358' }, // Finland
  { id: '+359', value: '+359', label: '+359' }, // Bulgaria
  { id: '+370', value: '+370', label: '+370' }, // Lithuania
  { id: '+371', value: '+371', label: '+371' }, // Latvia
  { id: '+372', value: '+372', label: '+372' }, // Estonia
  { id: '+373', value: '+373', label: '+373' }, // Moldova
  { id: '+374', value: '+374', label: '+374' }, // Armenia
  { id: '+375', value: '+375', label: '+375' }, // Belarus
  { id: '+376', value: '+376', label: '+376' }, // Andorra
  { id: '+377', value: '+377', label: '+377' }, // Monaco
  { id: '+378', value: '+378', label: '+378' }, // San Marino
  { id: '+379', value: '+379', label: '+379' }, // Vatican City
  { id: '+380', value: '+380', label: '+380' }, // Ukraine
  { id: '+381', value: '+381', label: '+381' }, // Serbia
  { id: '+382', value: '+382', label: '+382' }, // Montenegro
  { id: '+383', value: '+383', label: '+383' }, // Kosovo
  { id: '+385', value: '+385', label: '+385' }, // Croatia
  { id: '+386', value: '+386', label: '+386' }, // Slovenia
  { id: '+387', value: '+387', label: '+387' }, // Bosnia and Herzegovina
  { id: '+389', value: '+389', label: '+389' }, // North Macedonia
  { id: '+420', value: '+420', label: '+420' }, // Czech Republic
  { id: '+421', value: '+421', label: '+421' }, // Slovakia
  { id: '+423', value: '+423', label: '+423' }, // Liechtenstein
  { id: '+500', value: '+500', label: '+500' }, // Falkland Islands
  { id: '+501', value: '+501', label: '+501' }, // Belize
  { id: '+502', value: '+502', label: '+502' }, // Guatemala
  { id: '+503', value: '+503', label: '+503' }, // El Salvador
  { id: '+504', value: '+504', label: '+504' }, // Honduras
  { id: '+505', value: '+505', label: '+505' }, // Nicaragua
  { id: '+506', value: '+506', label: '+506' }, // Costa Rica
  { id: '+507', value: '+507', label: '+507' }, // Panama
  { id: '+509', value: '+509', label: '+509' }, // Haiti
  { id: '+590', value: '+590', label: '+590' }, // Guadeloupe
  { id: '+591', value: '+591', label: '+591' }, // Bolivia
  { id: '+592', value: '+592', label: '+592' }, // Guyana
  { id: '+593', value: '+593', label: '+593' }, // Ecuador
  { id: '+595', value: '+595', label: '+595' }, // Paraguay
  { id: '+597', value: '+597', label: '+597' }, // Suriname
  { id: '+598', value: '+598', label: '+598' }, // Uruguay
  { id: '+670', value: '+670', label: '+670' }, // East Timor
  { id: '+672', value: '+672', label: '+672' }, // Antarctica
  { id: '+673', value: '+673', label: '+673' }, // Brunei
  { id: '+674', value: '+674', label: '+674' }, // Nauru
  { id: '+675', value: '+675', label: '+675' }, // Papua New Guinea
  { id: '+676', value: '+676', label: '+676' }, // Tonga
  { id: '+677', value: '+677', label: '+677' }, // Solomon Islands
  { id: '+678', value: '+678', label: '+678' }, // Vanuatu
  { id: '+679', value: '+679', label: '+679' }, // Fiji
  { id: '+680', value: '+680', label: '+680' }, // Palau
  { id: '+681', value: '+681', label: '+681' }, // Wallis and Futuna
  { id: '+682', value: '+682', label: '+682' }, // Cook Islands
  { id: '+683', value: '+683', label: '+683' }, // Niue
  { id: '+685', value: '+685', label: '+685' }, // Samoa
  { id: '+686', value: '+686', label: '+686' }, // Kiribati
  { id: '+687', value: '+687', label: '+687' }, // New Caledonia
  { id: '+688', value: '+688', label: '+688' }, // Tuvalu
  { id: '+689', value: '+689', label: '+689' }, // French Polynesia
  { id: '+690', value: '+690', label: '+690' }, // Tokelau
  { id: '+691', value: '+691', label: '+691' }, // Micronesia
  { id: '+692', value: '+692', label: '+692' }, // Marshall Islands
  { id: '+850', value: '+850', label: '+850' }, // North Korea
  { id: '+852', value: '+852', label: '+852' }, // Hong Kong
  { id: '+853', value: '+853', label: '+853' }, // Macau
  { id: '+855', value: '+855', label: '+855' }, // Cambodia
  { id: '+856', value: '+856', label: '+856' }, // Laos
  { id: '+880', value: '+880', label: '+880' }, // Bangladesh
  { id: '+886', value: '+886', label: '+886' }, // Taiwan
  { id: '+960', value: '+960', label: '+960' }, // Maldives
  { id: '+961', value: '+961', label: '+961' }, // Lebanon
  { id: '+962', value: '+962', label: '+962' }, // Jordan
  { id: '+963', value: '+963', label: '+963' }, // Syria
  { id: '+964', value: '+964', label: '+964' }, // Iraq
  { id: '+965', value: '+965', label: '+965' }, // Kuwait
  { id: '+966', value: '+966', label: '+966' }, // Saudi Arabia
  { id: '+967', value: '+967', label: '+967' }, // Yemen
  { id: '+968', value: '+968', label: '+968' }, // Oman
  { id: '+970', value: '+970', label: '+970' }, // Palestine
  { id: '+971', value: '+971', label: '+971' }, // United Arab Emirates
  { id: '+972', value: '+972', label: '+972' }, // Israel
  { id: '+973', value: '+973', label: '+973' }, // Bahrain
  { id: '+974', value: '+974', label: '+974' }, // Qatar
  { id: '+975', value: '+975', label: '+975' }, // Bhutan
  { id: '+976', value: '+976', label: '+976' }, // Mongolia
  { id: '+977', value: '+977', label: '+977' }, // Nepal
  { id: '+992', value: '+992', label: '+992' }, // Tajikistan
  { id: '+993', value: '+993', label: '+993' }, // Turkmenistan
  { id: '+994', value: '+994', label: '+994' }, // Azerbaijan
  { id: '+995', value: '+995', label: '+995' }, // Georgia
  { id: '+996', value: '+996', label: '+996' }, // Kyrgyzstan
  { id: '+998', value: '+998', label: '+998' }, // Uzbekistan
];

export const phoneNumberValidator = (_: any, value: string) => {
  if (value && !/^[0-9]+$/.test(value)) {
    return Promise.reject(new Error('Please enter only numbers'));
  }
  return Promise.resolve();
};
