import agent from 'api/agent';
import {
  EmployeeWorkExperienceType,
  EmployeeWorkExperienceUpdateType,
} from 'types/EmployeeWorkExperience';
import { apiURLs } from './constants';

export const createEmployeeWorkExperience = (
  payloadCreate: EmployeeWorkExperienceType
) => agent.post(apiURLs.workExperience, payloadCreate);

export const updateEmployeeWorkExperience = (
  payloadUpdate: EmployeeWorkExperienceUpdateType,
  workExperienceId: string
) =>
  agent.patch(`${apiURLs.workExperience}/${workExperienceId}`, payloadUpdate);
