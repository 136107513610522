import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import {
  SideBarFooter,
  StyledSiderCollapseToggle,
  StyledSiderContent,
  StyledSiderContentGroup,
} from './StyledAppSidebar';
import SidebarFooter from './SidebarFooter';
import SidebarLogo from './SidebarLogo';
import SidebarMenu from './SidebarMenu';

const AppSidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleWindowSizeChange = useCallback(() => {
    if (window.innerWidth < 1192) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <Layout.Sider collapsed={collapsed} width={collapsed ? 100 : 250}>
      <StyledSiderCollapseToggle collapsed={collapsed}>
        {collapsed ? (
          <RightOutlined onClick={toggleCollapsed} />
        ) : (
          <LeftOutlined onClick={toggleCollapsed} />
        )}
      </StyledSiderCollapseToggle>
      <StyledSiderContent collapsed={collapsed}>
        <StyledSiderContentGroup>
          <SidebarLogo collapsed={collapsed} />
          <SidebarMenu collapsed={collapsed} setCollapsed={setCollapsed} />
        </StyledSiderContentGroup>
        <SideBarFooter>
          <SidebarFooter collapsed={collapsed} />
        </SideBarFooter>
      </StyledSiderContent>
    </Layout.Sider>
  );
};

export default React.memo(AppSidebar);
