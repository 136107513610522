import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  clearSingleDepartment,
  fetchDepartments,
  fetchSingleDepartment,
} from 'redux/departments/actions';
import { DepartmentModelType } from 'types/Department';
import useHeader from './useHeader';

export function useDepartmentData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.departments
  );
  const { data: department } = useSelector(
    (state: any) => state.singleDepartment
  );

  const [searchParams] = useSearchParams();
  const { entity } = useHeader();
  const dispatch = useDispatch();

  if (entity === 'departments' && !loaded && !loading) {
    dispatch(fetchDepartments({ page: 1, take: 10 }));
  }

  const depId = searchParams.get('id');

  useEffect(() => {
    if (depId && entity === 'departments') {
      dispatch(fetchSingleDepartment(depId));
    }

    return () => {
      dispatch(clearSingleDepartment());
    };
  }, [depId, entity]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    department:
      data?.data?.find(
        (item: DepartmentModelType) =>
          item.department_id === searchParams.get('edit-department')
      ) || department,
    loading,
    loaded,
    errored,
    error,
  };
}
