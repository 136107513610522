import { useDispatch, useSelector } from 'react-redux';
import { fetchOpenPositions } from 'redux/openPositions/actions';

export function useOpenPositionsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.openPositions
  );
  const dispatch = useDispatch();

  function fetch() {
    dispatch(fetchOpenPositions({ page: 1, take: 10 }));
  }

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
    fetch,
  };
}
