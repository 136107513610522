import { OpenPositionPayloadType } from 'types/OpenRoutes';
import { CandidateOfferUpdate, VerifyOfferType } from 'types/CandidateOffer';
import { CompanyCreateDTO } from 'types/Company';
import { OpenCandidateRegistration } from 'types/Candidates';
import { UserSetPassword, UserVerifyToken } from 'types/User';
import agent from './agent';
import { apiURLs } from './constants';

export const getOpenPositionsByTenantId = (tenantId: string) =>
  agent.get(`${apiURLs.openRoutes}${apiURLs.openPositions}/${tenantId}`);

export const getOpenPositionById = (payload: OpenPositionPayloadType) =>
  agent.post(`${apiURLs.openRoutes}/open-position`, payload);
export const getCurrenciesByTenantId = (tenantId: string) =>
  agent.post(`${apiURLs.openRoutes}${apiURLs.currency}`, tenantId);

export const getCertificationsByTenantId = (tenantId: string) =>
  agent.post(`${apiURLs.openRoutes}${apiURLs.certifications}`, tenantId);

export const getSkillsByTenantId = (tenantId: string) =>
  agent.post(`${apiURLs.openRoutes}${apiURLs.skillsCategory}`, tenantId);

export const verifyCanidateOffer = (payload: VerifyOfferType) =>
  agent.post(`${apiURLs.openRoutes}${apiURLs.candidateOffers}/verify`, payload);

export const updateCanidateOffer = (
  id: string,
  token: string,
  values: CandidateOfferUpdate
) =>
  agent.patch(`${apiURLs.openRoutes}${apiURLs.candidateOffers}/${id}`, {
    ...values,
    token,
  });

export const deleteCanidateOffer = (id: string, token: string) =>
  agent.post(
    `${apiURLs.openRoutes}${apiURLs.candidateOffers}/remove/${id}`,
    token
  );

export const registerCompany = (company: CompanyCreateDTO) =>
  agent.post(`${apiURLs.openRoutes}/register-company`, company);

export const candidateRegistration = (payload: OpenCandidateRegistration) =>
  agent.post(`${apiURLs.openRoutes}/candidate-registration`, {
    payload,
  });

export const getUserPasswordRules = async () =>
  agent.get(`${apiURLs.openRoutes}/password-rules`);

export const verifyUserToken = (token: UserVerifyToken) =>
  agent.post(`${apiURLs.openRoutes}/verify-account`, token);

export const setUserPassword = (payload: UserSetPassword) =>
  agent.post(`${apiURLs.openRoutes}/set-password`, payload);
