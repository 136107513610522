import { RcFile } from 'antd/lib/upload';
import { Image } from './types/Image';

export const isUndefinedNullOrEmpty = (value: unknown) =>
  typeof value === 'undefined' || !value;
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const prepareImageForUpload = async (file: RcFile): Promise<Image> =>
  getBase64(file).then((base64) => ({
    name: file.name,
    content: base64.split(',')[1],
    type: file.type,
  }));
