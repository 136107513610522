import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

export const ColItem = styled(Col)<{ isLast: boolean }>`
  border-bottom: 1px solid #eaecf0;
  ${({ isLast }) => isLast && 'border-bottom: none'};
`;

export const Task = styled(Row)<{ isLast: boolean }>`
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    div,
    button {
      background: #fafafa;
    }
  }
`;

export const Status = styled.div`
  background: #f2f4f7;
  border-radius: 16px;
  padding: 0 10px;
  display: flex;
  aling-items: center;
  justify-content: center;
  width: fit-content;
  align-items: center;
  margin-left: -2px;
`;

export const Icon = styled.div`
  padding-top: 4px;
`;

export const StatusLabel = styled(Typography.Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-left: 5px;
  color: #344054;
`;

export const Name = styled(Typography.Text)`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
`;

export const InterviewName = styled(Typography.Text)`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #101828;
`;

export const InterviewDate = styled(Typography.Text)`
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #101828;
`;

export const Divider = styled.div`
  width: 1px;
  height: 22px;
`;

export const DueDate = styled(Typography.Text)<{
  past?: boolean;
}>`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #101828;
  ${({ past }) => past && 'color: #ff4d4f'};
`;

export const Date = styled(Typography.Text)<{
  past?: boolean;
}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  ${({ past }) => past && 'color: #ff4d4f'};
`;

export const Span = styled.div`
  &:hover {
    cursor: pointer;
    background: #fafafa;
    border: 1px solid #f0f0f0;
  }
`;
