import {
  PayrollIntervalValueType,
  PayrollRuleParameters,
  PayrollRuleType,
} from 'types/PayrollRule';

function validateIntervalParametersArray(arr: Array<PayrollRuleParameters>) {
  return arr.every(
    (obj: PayrollRuleParameters) =>
      'from' in obj && 'to' in obj && 'value' in obj && 'valueType' in obj
  );
}

export function validateParameters(
  type: PayrollRuleType,
  parameters: Array<PayrollRuleParameters> | PayrollRuleParameters
) {
  if (type === PayrollRuleType.FIXED || type === PayrollRuleType.PERCENTAGE) {
    if ('value' in parameters) return true;
  }
  if (type === PayrollRuleType.INTERVAL && Array.isArray(parameters)) {
    return validateIntervalParametersArray(parameters);
  }

  return false;
}

export function getIntervalFormValues(
  values: any,
  valueType: PayrollIntervalValueType
): Array<PayrollRuleParameters> {
  const array: Array<PayrollRuleParameters> = [];
  let data: any = {};

  Object.keys(values).forEach((key) => {
    const keyParts = key.split('-');
    const keyIndex = keyParts[keyParts.length - 1];

    data = {
      ...data,
      [`interval-${keyIndex}`]: {
        ...data[`interval-${keyIndex}`],
        [key]: values[key],
      },
    };
  });

  Object.keys(data).forEach((interval) => {
    const item: any = {
      valueType: valueType?.toLowerCase(),
    };

    Object.keys(data[interval]).forEach((intervalKey) => {
      const key = intervalKey.split('-')[0];

      item[key] = data[interval][intervalKey];
    });

    array.push(item);
  });

  return array;
}

export const normalizeString = (str: string) => str.trim().toLowerCase();
