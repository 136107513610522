/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Item } from 'ui-v2/components/PersonalInfo';
import { ProjectDocumentType } from 'types/ProjectDocument';
import { RowItem } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { DocumentFileItem } from 'ui-v2/components/Documents/FileItem';
import { deleteProjectDocument } from 'api/projectService';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { fetchSingleProject } from 'redux/projects/actions';

import * as Styled from 'ui-v2/components/WorkExperience/WorkExperienceStyles';
import RightSection from 'ui-v2/components/RightSection';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import AddProjectDocumentModal from './AddProjectDocumentModal';

export default function ProjectDocuments() {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const {
    project: { data },
  } = useProjectsData();
  const onToggleModal = () => setModalVisible((state) => !state);
  const dispatch = useDispatch();

  if (!data?.id) return null;

  const onDeleteCallBack = async (id: string) => {
    await deleteProjectDocument(id)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('Document was deleted successfully!'));
        }
      })
      .catch(() => {
        toast.error(t("Something went wrong, document didn't delete!"));
      })
      .finally(() => dispatch(fetchSingleProject(data?.id)));
  };

  const onButtonClick = () => {
    onToggleModal();
  };

  return (
    <CardItem
      title={t('documents')}
      rightButton={
        data?.projectDocuments?.length ? (
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            {authUserRole === AUTH_ROLES.EMPLOYEE ? (
              ''
            ) : (
              <RightSection
                buttonCallBack={onToggleModal}
                buttonText={t('addDocuments')}
              />
            )}
          </RoleBasedButton>
        ) : undefined
      }
    >
      <>
        <Row gutter={[0, 24]}>
          {!data?.projectDocuments?.length && (
            <>
              <Styled.RowItem justify="center" last>
                <Col>
                  <Styled.NodataLabel>
                    {t('There are no project documents yet')}
                  </Styled.NodataLabel>
                </Col>
              </Styled.RowItem>
              <Styled.RowItem last justify="center">
                <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                  {authUserRole === AUTH_ROLES.EMPLOYEE ? (
                    ''
                  ) : (
                    <Col>
                      <Button
                        icon={<PlusCircleOutlined />}
                        size="large"
                        style={{
                          borderRadius: '8px',
                          fontWeight: '600',
                          fontSize: '16px',
                          color: '344054',
                        }}
                        onClick={onButtonClick}
                      >
                        {t('addDocument')}
                      </Button>
                    </Col>
                  )}
                </RoleBasedButton>
              </Styled.RowItem>
            </>
          )}

          {data?.projectDocuments &&
            data?.projectDocuments?.map((document: ProjectDocumentType) => (
              <RowItem last gutter={[0, 10]} align="middle">
                <Col span={24}>
                  <Item label="" value={document?.description}>
                    <DocumentFileItem
                      filename={document?.name}
                      filecontent={document?.url}
                      onDelete={() => onDeleteCallBack(document?.id)}
                    />
                  </Item>
                </Col>
              </RowItem>
            ))}
        </Row>

        {modalVisible && data?.projectDocuments && (
          <AddProjectDocumentModal
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
            }}
          />
        )}
      </>
    </CardItem>
  );
}
