import React, { useMemo, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';

import { CertificationCeateDto } from 'types/Certification';
import { FormConfigurationType } from 'types/FormTypes';
import { useNavigate } from 'react-router-dom';
import {
  createCertification,
  updateCertification,
} from 'api/certificationService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { getAllJobCategoriesWithSubCategories } from 'api/jobCategoryService';
import { convertDateToUTC, toastErrorMessages } from 'utils/utilFunctions';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchCertifications } from 'redux/certifications/actions';
import GenericModal from 'ui-v2/components/GenericModal';
import { CertificationsIcon } from 'Icons/CertificationsIcon';
import { useCertificationsData } from 'ui-v2/hooks/useCertificationsData';
import { getAllSubSkills } from '../DynamicForm/SkillsMatrix';
import GenericForm from '../Form/GenericForm';
import i18next from '../../../i18n';

import { FormItemStyled, StyledButton } from '../FormStyled';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

const trainingDurationOptions = [
  {
    id: '1',
    label: `1 ${i18next.t('month')}`,
    value: '1 Month',
  },
  {
    id: '2',
    label: `3 ${i18next.t('months')}`,
    value: '3 Months',
  },
  {
    id: '3',
    label: `6 ${i18next.t('months')}`,
    value: '6 Months',
  },
  {
    id: '4',
    label: `1 ${i18next.t('year')}`,
    value: '1 Year',
  },
  {
    id: '5',
    label: `2 ${i18next.t('years')}`,
    value: '2 Years',
  },
  {
    id: '6',
    label: `3 ${i18next.t('years')}`,
    value: '3 Years',
  },
];

function CertificationModalForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const { certification } = useCertificationsData();
  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });
  const [loading, isLoading] = useState<boolean>(false);
  const [allSubSkills, setAllSubSkills] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getAllJobCategoriesWithSubCategories()
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setAllSubSkills(getAllSubSkills(response.data) as any);
        }
      })
      .catch(toastErrorMessages);
  }, []);

  function onFormFinish(values: CertificationCeateDto) {
    const trainingDuration = trainingDurationOptions.find(
      (item: any) => item.id === values.trainingDuration
    );
    const valuesToSend: any = {
      ...values,
      currencyId: values.currencyId,
      validation: convertDateToUTC(values.validation),
      cost: values.cost.toString(),
      trainingDuration: trainingDuration?.label,
      certificationSkills: values.certificationSkills,
    };
    isLoading(true);
    try {
      if (!certification?.id) {
        createCertification({ ...valuesToSend })
          .then(() => {
            toast.success(t('certificationcreatedSuccessfully'));
            dispatch(fetchCertifications({ page: 1 }));
            closeModal();
            navigate('/certifications');
          })
          .catch(() => {
            toast.error(t('Error during creating'));
          })
          .finally(() => {
            isLoading(false);
          });
      } else {
        updateCertification(certification.id, valuesToSend)
          .then(() => {
            toast.success(t('Certification updated successfully'));
            dispatch(fetchCertifications({ page: 1 }));
            navigate('/certifications');
            closeModal();
          })
          .catch(() => {
            toast.error(t('Error during updating'));
          })
          .finally(() => {
            isLoading(false);
          });
      }
    } catch (error) {
      toast.error(t('somethingWentWrong'));
      isLoading(false);
    }
  }

  const CertificationFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: 'input',
          defaultValue: certification?.name,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'companyName',
          label: t('companyName'),
          type: 'input',
          defaultValue: certification?.companyName,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'validation',
          label: t('expiresIn'),
          type: 'datepicker',
          defaultValue: certification?.validation
            ? moment(certification?.validation)
            : null,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'cost',
          label: t('cost'),
          type: 'selectPrefix',
          defaultValue: certification?.cost,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions,
            placeholder: '',
            defaultValue:
              certification?.currency?.id ||
              currencyOptions?.find((el) => el.label === 'EUR')?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          loading: currencyLoading,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'trainingDuration',
          label: t('trainingDuration'),
          type: 'select',
          showSearch: true,
          selectOptions: trainingDurationOptions,
          defaultValue:
            certification &&
            trainingDurationOptions.find(
              (t) => t.label === certification.trainingDuration
            )?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'certificationSkills',
          label: t('certificationSkills'),
          type: 'select',
          showSearch: true,
          isMultiSelect: true,
          selectOptions: allSubSkills.map((item: any) => ({
            ...item,
            label: item.subCategoryName,
            value: item.id,
          })),
          defaultValue: certification?.certificationSkills?.map(
            (item: any) => item?.subCategoryName ?? item?.id
          ),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [currencyOptions, allSubSkills, trainingDurationOptions, certification]
  );

  return (
    <GenericModal
      title={certification?.id ? t('editCertification') : t('addCertification')}
      open={open}
      closeModal={closeModal}
      icon={<CertificationsIcon />}
    >
      <GenericForm
        formConfiguration={CertificationFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('schedule')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default CertificationModalForm;
