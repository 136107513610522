import React, { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { SelectWrapper } from './CustomSelectStyled';

type CustomSelectProps = SelectProps & {
  prefixIcon?: ReactNode;
};

const CustomSelect = ({ prefixIcon, children, ...rest }: CustomSelectProps) => (
  <SelectWrapper>
    {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
    <Select {...rest}>{children}</Select>
  </SelectWrapper>
);

export default CustomSelect;
