import { Reducer } from 'react';
import { Action } from 'redux';
import { Requests, RequestsState } from './types';

const initialState: RequestsState = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<RequestsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Requests.FETCH_REQUESTS_START:
      state = { ...state, loading: true };
      break;
    case Requests.FETCH_REQUESTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case Requests.FETCH_REQUESTS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as RequestReducer };
