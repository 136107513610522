import { Col, Row } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { IconButton } from './QuestionniareFeedbackItemStyled';

const QuestionnaireFeedbackItemAction: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onEditClick = () => {
    searchParams.append('revieweeId', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={10}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default QuestionnaireFeedbackItemAction;
