import { Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getAuthUser, onLogout } from 'redux/authUser/actions';
import { IS_LOGGEDIN, TENANT_ID, USER_ROLE } from 'utils/constants';
import EmployeeList from 'pages/Employee/EmployeesList/EmployeeList';
import EmployeeDetails from 'pages/Employee/EmployeeDetails/EmployeeDetails';
import Users from 'pages/Users';

import MainLayout from 'ui-v2/components/MainLayout';
import CandidateProfile from 'ui-v2/routes/CandidateProfile';
import EditProfile from 'ui-v2/routes/EditProfile';
import AddCandidate from 'ui-v2/routes/AddCandidate';
import Archived from 'ui-v2/routes/Archived';
import CandidatesList from 'ui-v2/routes/CandidatesList';
import DepartmentsList from 'ui-v2/routes/DepartmentsList';
import HireCandidate from 'ui-v2/routes/HireCandidate.tsx';
import WorkPositionsList from 'ui-v2/routes/WorkPositionsList/';
import WorkPositionDetails from 'ui-v2/routes/WorkPositionDetails/WorkPositionDetails';
import ProjectDetails from 'ui-v2/routes/ProjectDetails';
import ProjectsList from 'ui-v2/routes/ProjectsList';
import AssetsList from 'ui-v2/routes/AssetsList';
import AssetDetails from 'ui-v2/routes/AssetDetails';
import Files from 'ui-v2/routes/Files';
import InterviewsList from 'ui-v2/routes/InterviewsList';
import OpenPositionsList from 'ui-v2/routes/OpenPositionList';
import TrainingsList from 'ui-v2/routes/TrainingsList';
import CertificationsList from 'ui-v2/routes/CertificationsList';
import DashboardPage from 'pages/Dashboard';
import EvaluationPeriodsList from 'ui-v2/routes/EvaluationPeriods';
import AddOpenPosition from 'ui-v2/routes/AddOpenPosition';
import EditOpenPosition from 'ui-v2/routes/EditOpenPosition';
import AddEmployee from 'ui-v2/routes/AddEmployee';
import EditEmployee from 'ui-v2/routes/EditEmployee';
import CertificationDetails from 'ui-v2/routes/CertificationDetails';
import EmployeeArchive from 'ui-v2/routes/EmployeeArchive';
import ProtectedRoute from 'ui-v2/components/ProtectedRoute';
import {
  isAdmin,
  isEmployee,
  isEmployeeOnboardingProcessComplete,
  isGuest,
  isGuestTrackingHours,
  isHR,
  isHeadDepartment,
} from 'utils/utilFunctions';
import TimeOff from 'ui-v2/routes/ProtectedRoutes/Employee/TimeOff';
import AddUserForm from 'pages/Users/UsersList/AddUserForm';
import EditUser from 'pages/Users/UserEdit/EditUser';

import TimeOffRequests from 'ui-v2/routes/TimeOffRequests';
import Profile from 'ui-v2/routes/ProtectedRoutes/Employee/Profile';
import EmployeeProfileSetting from 'ui-v2/routes/ProtectedRoutes/Employee/Profile/Profile';
import OnboardingsList from 'ui-v2/routes/OnboardingsList';
import OnboardingDetails from 'ui-v2/routes/OnboardingDetails';
import UserProfile from 'components/UserInfo/UserProfile';
import ChangeUserPassword from 'components/UserInfo/UserProfile/ChangeUserPassword';
import TrainingDetails from 'ui-v2/routes/TrainingDetails';
import DepartmentDetails from 'ui-v2/routes/DepartmentDetails';
import IpAccessList from 'ui-v2/routes/IPAccessList';
import Requests from 'ui-v2/routes/Requests/Requests';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import ErrorFallbackUI from 'ErrorFallbackUI';
import OnboardingSteps from 'ui-v2/routes/ProtectedRoutes/Employee/OnboardingSteps';
import BenefitList from 'pages/Benefits/BenefitList';
import Renumaration from 'ui-v2/components/Employees/Renumaration';
import { AuthUserState } from 'redux/authUser/types';
import SkillCategoriesList from 'pages/Skills';
import SkillCategoriesDetails from 'pages/Skills/SkillCategoryDetails/SkillCategoriesDetails';
import TenantsList from 'ui-v2/routes/superAdmin/TenantsList';
import TenantAccountsList from 'ui-v2/routes/superAdmin/TenantAccountsList';
import SuperAdminsUsersList from 'ui-v2/routes/superAdmin/SuperAdminsUsersList';
import SuperAdminProfile from 'ui-v2/routes/superAdmin/SuperAdminProfile';
import { usePublicSuperAdminData } from 'ui-v2/hooks/usePublicSuperAdminData';
import TrackingPage from 'ui-v2/routes/tracking';
import TenantOnboardingSteps from 'ui-v2/routes/TenantOnboardingSteps';
import PayrollRulesList from 'pages/PayrollRules';
import PayrollGenerateReport from 'pages/PayrollReport/PayrollViewReport';
import BulkUploadCreateEmployeeForm from 'components/NewForms/EmployeeForm/components/forms/BulkUploadCreateEmployeeForm';
import { fetchEmployeesSkim } from 'redux/employeeSkim/actions';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import PayrollDate from 'pages/PayrollReport/PayrollDate';
import PayrollRuleDetail from 'pages/PayrollRules/PayrollRuleDetails';
import QuestionnairesList from 'ui-v2/routes/QuestionnairesList';
import QuestionnaireDetails from 'ui-v2/routes/QuestionnaireDetails';
import useHeader from 'ui-v2/hooks/useHeader';
import AssetLogs from 'ui-v2/routes/AssetLogs';
import AssetCategoriesList from 'pages/Assets/AssetCategoriesList/AssetCategoriesList';
import AssetCategoriesDetails from 'pages/Assets/AssetCategoriesDeatils/AssetCategoriesDetails';
import PurchaseRequestList from 'ui-v2/routes/PurchaseRequestList';
import VendorDetails from 'ui-v2/routes/Vendor/VendorDetails';
import VendorsList from 'ui-v2/routes/Vendor/VendorList';
import VendorCategoryList from 'ui-v2/routes/VendorCategoryList/VendorCategoryList';
import VendorCategoryDetails from 'ui-v2/routes/VendorCategoryDetails/VendorCategoryDetails';
import PurchaseOrdersList from 'ui-v2/routes/PurchaseOrders';
import PurchaseOrderDetails from 'ui-v2/routes/PurchaseOrderDetails';
import VendorQuestionnaireList from 'ui-v2/routes/VendorQuestionnaireList';
import VendorQuestionnaireDetails from 'ui-v2/routes/VendorQuestionnaireDetails';
import Settings from 'ui-v2/routes/Settings';
import InvoicingList from 'ui-v2/routes/Invoicing';
import InvoicingDetails from 'ui-v2/routes/InvoicingDetails';
import AddAsset from 'ui-v2/routes/AddAsset';
import EditAsset from 'ui-v2/routes/EditAsset';
import EditPurchaseOrder from 'ui-v2/routes/EditPurchaseOrder';
import Offboarding from 'ui-v2/routes/Offboarding';
import IdeaboxList from 'ui-v2/routes/IdeaboxList';
import IdeaboxDetails from 'ui-v2/routes/IdeaboxDetails';
import { Center } from './AppLoading/AppLoadingStyles';
import { SCREEN_SIZE, getWindowSize } from './AppSidebar/utils';
import AppSidebar from './AppSidebar';

const AppLayout: React.FC = () => {
  const navigate = useNavigate();
  const { entity } = useHeader();
  const dispatch = useDispatch();
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { tenantPersisted: tenant, getTenantDetails } = useTenantsData();
  const { filteredEmployeeSkimOptions } = useEmployeeSkimData();
  const { isSuperAdmin } = usePublicSuperAdminData();
  const [windowSize, setWindowSize] = useState<
    SCREEN_SIZE.SMALL | SCREEN_SIZE.MEDIUM
  >(getWindowSize());
  const [isHeadOfDepartment, setIsHeadOfDepartment] = useState<boolean>(false);

  const tenantId = localStorage.getItem(TENANT_ID);
  const isEmployeeUser = isEmployee(authUserState);

  useEffect(() => {
    const employeeId = authUserState?.authUser?.employee?.id as string;
    if (employeeId) {
      isHeadDepartment(employeeId).then((res: boolean) => {
        setIsHeadOfDepartment(res);
      });
    }
  }, [authUserState?.authUser?.employee?.id]);

  const isAdminUser = isAdmin(authUserState);
  const isGuestUser = isGuest(authUserState);
  const isHr = isHR(authUserState);
  const isGuestTrackingHoursUser = isGuestTrackingHours(authUserState);
  const isUserLoggedIn = localStorage.getItem(IS_LOGGEDIN);
  const isOnboardingCompleted = isEmployeeOnboardingProcessComplete(
    authUserState,
    isEmployeeUser
  );
  const userRole = localStorage.getItem(USER_ROLE);

  useEffect(() => {
    if (!entity?.length) {
      switch (userRole) {
        case AUTH_ROLES.AMDIN:
        case AUTH_ROLES.HR:
          navigate('/dashboard');
          break;
        case AUTH_ROLES.EMPLOYEE:
          navigate('/employee-details');
          break;
        case AUTH_ROLES.GUEST:
        case AUTH_ROLES.GUEST_TRACKING_HOURS:
        case AUTH_ROLES.EDITOR:
          navigate('/tracking');
          break;
        case AUTH_ROLES.SUPER_ADMIN:
          navigate('/tenants');
          break;
        default:
          dispatch(onLogout());
          break;
      }
    }
  }, [entity]);

  useEffect(() => {
    if (
      (tenantId && !tenant?.id) ||
      (tenantId && tenant?.id && tenantId !== tenant?.id)
    ) {
      getTenantDetails(tenantId);
    }
  }, [tenantId, tenant?.id]);

  useEffect(() => {
    if (
      [AUTH_ROLES.AMDIN, AUTH_ROLES.HR].includes(
        authUserState?.authUserRole as AUTH_ROLES
      ) &&
      tenant?.id &&
      !filteredEmployeeSkimOptions?.length
    ) {
      dispatch(fetchEmployeesSkim());
    }
  }, [
    authUserState?.authUserRole,
    tenant?.id,
    filteredEmployeeSkimOptions?.length,
  ]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(getAuthUser());
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate('/login');
    }
  }, [authUserState]);

  if (tenantId) {
    if (
      !tenant?.id ||
      tenantId !== tenant?.id ||
      !authUserState?.authUser?.id
    ) {
      return (
        <Center>
          <Spin />
        </Center>
      );
    }
  } else if (!authUserState?.authUser?.id) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  if (!isOnboardingCompleted) {
    return <OnboardingSteps />;
  }

  if (
    authUserState?.authUserRole === AUTH_ROLES.AMDIN &&
    tenant?.id &&
    !tenant.isOnBoardingCompleted
  ) {
    return <TenantOnboardingSteps />;
  }

  return (
    <Layout>
      <Layout>
        <AppSidebar />
        <Layout
          className="site-layout"
          style={{
            display: 'block',
            marginLeft: windowSize === SCREEN_SIZE.SMALL ? '0em' : '',
          }}
        >
          <Routes>
            <Route
              path="/candidates"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<CandidatesList />} />
              <Route path="profile" element={<CandidateProfile />} />
              <Route path="add-candidate" element={<AddCandidate />} />
              <Route path="edit-profile" element={<EditProfile />} />
              <Route path="hire-candidate" element={<HireCandidate />} />
              <Route path="archived" element={<Archived />} />
            </Route>
            <Route
              path="/employees"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<EmployeeList />} />
              <Route path="add-employee" element={<AddEmployee />} />
              <Route path="employee-profile" element={<EmployeeDetails />} />
              <Route path="offboarding" element={<Offboarding />} />
              <Route path="edit-employee" element={<EditEmployee />} />
              <Route path="employee-archive" element={<EmployeeArchive />} />
            </Route>
            <Route
              path="/evaluations"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<EvaluationPeriodsList />} />
            </Route>

            <Route
              path="/users"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<Users />} />
              <Route path="add-user" element={<AddUserForm />} />
              <Route path="edit-user" element={<EditUser />} />
            </Route>
            <Route path="/open-positions" element={<OpenPositionsList />} />
            <Route
              path="/interviews"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<InterviewsList />} />
            </Route>
            <Route
              path="/vendors"
              element={
                <ProtectedRoute isAllowed={isAdminUser}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<VendorsList />} />
              <Route path="vendor-details" element={<VendorDetails />} />
            </Route>

            <Route
              path="/time-off"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<TimeOffRequests />} />
            </Route>
            <Route
              path="/requests"
              element={
                <ProtectedRoute
                  isAllowed={
                    isAdminUser || isGuestUser || isEmployeeUser || isHr
                  }
                >
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<Requests />} />
            </Route>
            <Route
              path="/onboardings"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<OnboardingsList />} />
              <Route
                path="onboarding-details"
                element={<OnboardingDetails />}
              />
            </Route>
            <Route
              path="/departments"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<DepartmentsList />} />
              <Route
                path="department-details"
                element={<DepartmentDetails />}
              />
            </Route>
            <Route
              path="/projects"
              element={
                <ProtectedRoute
                  isAllowed={
                    isAdminUser || isGuestUser || isHr || isEmployeeUser
                  }
                >
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<ProjectsList />} />
              <Route path="project-details" element={<ProjectDetails />} />
            </Route>

            <Route
              path="/questionnaires"
              element={
                <ProtectedRoute
                  isAllowed={
                    isAdminUser ||
                    isHr ||
                    (isEmployeeUser && isHeadOfDepartment)
                  }
                >
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<QuestionnairesList />} />
              <Route
                path="questionnaire-details"
                element={<QuestionnaireDetails />}
              />
            </Route>

            <Route
              path="/assets"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<AssetsList />} />
              <Route path="asset-details" element={<AssetDetails />} />
              <Route path="asset-logs" element={<AssetLogs />} />
              <Route path="add-asset" element={<AddAsset />} />
              <Route path="edit-asset" element={<EditAsset />} />
            </Route>
            <Route
              path="/asset-categories"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<AssetCategoriesList />} />
              <Route path="details" element={<AssetCategoriesDetails />} />
            </Route>
            <Route
              path="/vendor-questionnaire"
              element={
                <ProtectedRoute isAllowed={isAdminUser}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<VendorQuestionnaireList />} />
              <Route
                path="vendor-questionnaire-details"
                element={<VendorQuestionnaireDetails />}
              />
            </Route>
            <Route
              path="/vendor-categories"
              element={
                <ProtectedRoute isAllowed={isAdminUser}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<VendorCategoryList />} />
              <Route
                path="vendor-category-details"
                element={<VendorCategoryDetails />}
              />
            </Route>
            <Route
              path="/work-positions"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<WorkPositionsList />} />
              <Route
                path="work-position-details"
                element={<WorkPositionDetails />}
              />
            </Route>
            <Route
              path="/purchase-requests"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<PurchaseRequestList />} />
              {/* <Route
                path="work-position-details"
                element={<WorkPositionDetails />}
              /> */}
            </Route>
            <Route
              path="/open-positions"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<OpenPositionsList />} />
              <Route path="add-open-position" element={<AddOpenPosition />} />
              <Route path="edit-open-position" element={<EditOpenPosition />} />
            </Route>
            <Route
              path="/certifications"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<CertificationsList />} />
              <Route
                path="certification-details"
                element={<CertificationDetails />}
              />
            </Route>
            <Route
              path="/purchase-orders"
              element={
                <ProtectedRoute isAllowed={isAdminUser}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<PurchaseOrdersList />} />
              <Route
                path="purchase-order-details"
                element={<PurchaseOrderDetails />}
              />
              <Route
                path="edit-purchase-order"
                element={<EditPurchaseOrder />}
              />
            </Route>
            <Route
              path="/invoicing"
              element={
                <ProtectedRoute isAllowed={isAdminUser}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<InvoicingList />} />
              <Route path="invoice-details" element={<InvoicingDetails />} />
            </Route>
            <Route
              path="/trainings"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<TrainingsList />} />
              <Route path="training-details" element={<TrainingDetails />} />
            </Route>
            <Route
              path="/reports-analytics"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<Renumaration />} />
            </Route>

            <Route
              path="/benefits"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<BenefitList />} />
            </Route>
            <Route
              path="/payroll-rules"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<PayrollRulesList />} />
              <Route
                path="payroll-rule-details"
                element={<PayrollRuleDetail />}
              />
            </Route>

            <Route
              path="/ideabox"
              element={
                <ProtectedRoute
                  isAllowed={isAdminUser || isHr || isEmployeeUser}
                >
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<IdeaboxList />} />
              <Route path="ideabox-details" element={<IdeaboxDetails />} />
            </Route>

            <Route
              path="/payroll-report"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<PayrollDate />} />
              <Route path="view-report" element={<PayrollGenerateReport />} />
            </Route>

            <Route
              path="/data-entry"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<BulkUploadCreateEmployeeForm />} />
            </Route>
            <Route
              path="/tracking"
              element={
                <ProtectedRoute
                  isAllowed={
                    isAdminUser ||
                    isGuestUser ||
                    isGuestTrackingHoursUser ||
                    isHr
                  }
                >
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<TrackingPage />} />
            </Route>
            <Route path="files" element={<Files />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<DashboardPage />} />
            </Route>

            <Route
              path="/employee"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="profile" element={<Profile />} />
            </Route>
            <Route
              path="settings"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isGuestUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<Settings />} />
            </Route>
            <Route
              path="/employee-details"
              element={
                <ProtectedRoute isAllowed={isEmployeeUser || isGuestUser}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<EmployeeDetails />} />
              <Route path="tracker" element={<TrackingPage />} />
              <Route path="time-off" element={<TimeOff />} />
              <Route path="requests" element={<TimeOffRequests />} />
              <Route path="settings" element={<EmployeeProfileSetting />} />
            </Route>

            <Route
              path="/my-profile"
              element={
                <ProtectedRoute
                  isAllowed={
                    isAdminUser ||
                    isEmployeeUser ||
                    isHr ||
                    isGuestTrackingHoursUser
                  }
                >
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<UserProfile />} />
              <Route path="edit-user-profile" element={<EditProfile />} />
              <Route path="change-password" element={<ChangeUserPassword />} />
            </Route>

            <Route
              path="/skills"
              element={
                <ProtectedRoute isAllowed={isAdminUser || isHr}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<SkillCategoriesList />} />
              <Route
                path="category-details"
                element={<SkillCategoriesDetails />}
              />
            </Route>

            <Route
              path="/tenants"
              element={
                <ProtectedRoute isAllowed={isSuperAdmin}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<TenantsList />} />
            </Route>
            <Route
              path="/tenant-accounts"
              element={
                <ProtectedRoute isAllowed={isSuperAdmin}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<TenantAccountsList />} />
            </Route>
            <Route
              path="/ip-whitelisting"
              element={
                <ProtectedRoute isAllowed={isSuperAdmin}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<IpAccessList />} />
            </Route>

            <Route
              path="/public-super-admins"
              element={
                <ProtectedRoute isAllowed={isSuperAdmin}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<SuperAdminsUsersList />} />
            </Route>
            <Route
              path="/super-admin-profile"
              element={
                <ProtectedRoute isAllowed={isSuperAdmin}>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route path="" element={<SuperAdminProfile />} />
            </Route>
            <Route path="/error" element={<ErrorFallbackUI />} />
          </Routes>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AppLayout;
