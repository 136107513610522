import React, { useMemo, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputTypes } from 'types/FormTypes';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import GenericModal from 'ui-v2/components/GenericModal';
import { useDispatch } from 'react-redux';
import { usePayrollRuleData } from 'ui-v2/hooks/usePayrollRule';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import GenericForm from 'components/NewForms/Form';
import { createPayrollRule, updatePayrollRule } from 'api/payrollRulesService';
import {
  CreatePayrollRuleDTO,
  PayrollIntervalValueType,
  PayrollRuleType,
} from 'types/PayrollRule';
import {
  clearSinglePayrollRule,
  fetchPayrollRules,
} from 'redux/payrollRules/actions';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { isFormEdited } from 'components/NewForms/Form/utils';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import { fetchTenantLocationsOptions } from 'components/NewForms/SelectWithLoad/utils';
import PayrollRuleIntervalForm from './PayrollRuleIntervalForm';
import { getIntervalFormValues, validateParameters } from './utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

function AddPayrollRuleForm({ open, closeModal }: IProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, isLoading] = useState<boolean>(false);
  const { singlePayrollRule } = usePayrollRuleData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<PayrollRuleType | null>(
    singlePayrollRule?.type
  );
  const [formIntevalValues, onFormIntervalChange] = useState<any>();
  const [intervalValueType, setIntervalValueType] =
    useState<PayrollIntervalValueType>(PayrollIntervalValueType.PERCENTAGE);
  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });
  const [currencyId, setCurrencyId] = useState<string | undefined>(
    singlePayrollRule?.currency?.id || undefined
  );
  const [currencyLabel, setCurrencyLabel] = useState<string | undefined>(
    singlePayrollRule?.currency?.name || undefined
  );
  const { data: tenantConfig } = useTenantConfigData();

  useEffect(() => {
    if (searchParams.get('add-save-payroll-rules') === 'true') {
      searchParams.delete('add-save-payroll-rules');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-save-payroll-rules') === 'true') {
      searchParams.delete('edit-save-payroll-rules');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (singlePayrollRule?.type) {
      setType(singlePayrollRule?.type);
    }
    if (singlePayrollRule?.currency?.id) {
      setCurrencyId(singlePayrollRule?.currency?.id);
    }
    if (singlePayrollRule?.parameters[0]?.valueType) {
      if (
        singlePayrollRule?.parameters[0]?.valueType ===
        PayrollIntervalValueType.FIXED
      ) {
        setIntervalValueType(PayrollIntervalValueType.FIXED);
      }
      if (
        singlePayrollRule?.parameters[0]?.valueType ===
        PayrollIntervalValueType.PERCENTAGE
      ) {
        setIntervalValueType(PayrollIntervalValueType.PERCENTAGE);
      }
    }
  }, [singlePayrollRule?.type]);

  function onFormFinish(values: any) {
    if (
      values.type === PayrollRuleType.INTERVAL &&
      values.valueType.toLowerCase() === PayrollIntervalValueType.PERCENTAGE
    ) {
      setCurrencyId(undefined);
      values.currencyId = undefined;
    }

    const {
      humanID,
      name,
      description,
      type,
      value,
      from,
      to,
      valueType,
      currencyId,
      tenantLocationId,
    } = values;

    isLoading(true);

    const payload: CreatePayrollRuleDTO = {
      humanID,
      name,
      description,
      tenantLocationId:
        tenantLocationId?.value || singlePayrollRule?.tenantLocation?.id,
      type,
      currencyId,
      parameters:
        type === PayrollRuleType.INTERVAL
          ? getIntervalFormValues(formIntevalValues, valueType)
          : {
              from,
              to,
              value,
              valueType,
            },
    };

    if (!validateParameters(payload.type, payload.parameters)) return;

    if (singlePayrollRule?.id) {
      if (
        isFormEdited({
          formValues: payload,
          valuesToCheck: singlePayrollRule,
          entity: 'payrollRule',
        })
      ) {
        isLoading(false);
        closeModal();
        return;
      }
      updatePayrollRule(payload)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            toast.success(t('ruleUpdatedSuccessfully'));
            dispatch(fetchPayrollRules({ page: 1, take: 10 }));
            navigate(
              `/payroll-rules/payroll-rule-details?id=${response.data?.id}`
            );
          }
        })
        .catch(() => {
          toast.error(t('ruleFailedtoUpdate'));
        })
        .finally(() => {
          dispatch(clearSinglePayrollRule());
          isLoading(false);
        });
      return;
    }

    createPayrollRule(payload)
      .then((response) => {
        if (response.status === 200 && response.data) {
          closeModal();
          toast.success(t('ruleCreatedSuccessfully'));
          navigate(
            `/payroll-rules/payroll-rule-details?id=${response.data?.id}`
          );
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const showCurrency =
    form.getFieldValue('type') === PayrollRuleType.INTERVAL &&
    form.getFieldValue('valueType')?.toLowerCase() ===
      PayrollIntervalValueType.FIXED.toLowerCase();

  const PayrollRuleFormConfig: any = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'humanID',
          label: t('Rule ID'),
          type: InputTypes.INPUT,
          defaultValue: singlePayrollRule?.humanID,
          disabled: !!singlePayrollRule?.humanID,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'type',
          label: t('Rule Type'),
          type: InputTypes.SELECT,
          defaultValue: singlePayrollRule?.type,
          selectOptions: Object.keys(PayrollRuleType).map((item) => ({
            id: item,
            value: item,
            label: item,
          })),
          disabled: !!singlePayrollRule?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'name',
          label: t('Rule Name'),
          type: InputTypes.INPUT,
          defaultValue: singlePayrollRule?.name,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'description',
          label: t('Rule Description'),
          type: InputTypes.INPUT,
          defaultValue: singlePayrollRule?.description,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],

      type === PayrollRuleType.FIXED
        ? [
            {
              col: 11,
              offset: 0,
              name: 'value',
              label: t('Rule Value'),
              type: 'selectPrefix',
              defaultValue: singlePayrollRule?.parameters?.value,
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
              inputProps: {
                type: 'number',
                rows: 1,
                min: 0,
              },
              prefix: {
                name: 'currencyId',
                selectOptions: currencyOptions,
                placeholder: '',
                defaultValue: singlePayrollRule?.currency?.id,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
            },
          ]
        : [],

      type === PayrollRuleType.PERCENTAGE
        ? [
            {
              col: 11,
              offset: 0,
              name: 'value',
              label: `${t('Rule Value')}%`,
              type: InputTypes.INPUT,
              defaultValue: singlePayrollRule?.parameters?.value,
              inputProps: {
                type: 'number',
              },
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
          ]
        : [],

      type === PayrollRuleType.INTERVAL
        ? [
            {
              col: 11,
              offset: 0,
              name: 'valueType',
              label: 'Value Type',
              type: InputTypes.SELECT,
              defaultValue: intervalValueType?.toUpperCase(),
              selectOptions: Object.keys(PayrollIntervalValueType).map(
                (item) => ({
                  id: item,
                  value: item,
                  label: item,
                })
              ),
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
            {
              col: 11,
              offset: 2,
              name: 'currencyId',
              label: t('currency'),
              type: InputTypes.SELECT,
              selectOptions: currencyOptions,
              defaultValue: currencyId,
              disabled: !showCurrency,
              rules: [
                {
                  required: showCurrency,
                  message: t('fieldRequired'),
                },
              ],
            },
          ]
        : [],

      [
        {
          col: 24,
          offset: 0,
          name: 'tenantLocationId',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          defaultValue: singlePayrollRule?.tenantLocation?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [
      singlePayrollRule,
      type,
      currencyOptions,
      intervalValueType,
      showCurrency,
      tenantConfig,
      fetchTenantLocationsOptions,
    ]
  );

  const onFieldsChange = () => {
    setType(form.getFieldValue('type'));
    const currencyLabel = currencyOptions?.find(
      (item) => item.id === form.getFieldValue('currencyId')
    )?.label;
    setCurrencyLabel(`${currencyLabel}`);

    setIntervalValueType(form.getFieldValue('valueType'));
    if (
      intervalValueType?.toLowerCase() === PayrollIntervalValueType.PERCENTAGE
    ) {
      setCurrencyId(undefined);
    } else {
      setCurrencyId(form.getFieldValue('currencyId'));
    }
  };

  return (
    <GenericModal
      title={singlePayrollRule?.id ? t('Edit Rule') : t('Add Rule')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={PayrollRuleFormConfig}
        onFinish={onFormFinish}
        onFieldsChange={onFieldsChange}
        form={form}
        loading={loading}
      >
        {type === PayrollRuleType.INTERVAL && (
          <PayrollRuleIntervalForm
            ref={form2 as any}
            onFormIntervalChange={onFormIntervalChange}
            valueType={form.getFieldValue('valueType')}
            currency={currencyLabel}
            intervalData={singlePayrollRule?.parameters.map(
              (
                item: {
                  from: number;
                  to: number;
                  value: number;
                },
                index: number
              ) => ({
                key: index,
                item,
              })
            )}
          />
        )}
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('save')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default AddPayrollRuleForm;
