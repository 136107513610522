import agent from 'api/agent';
import { DepartmentDTO, WorkPositionEmployees } from 'types/Department';
import { QueryParamsDTO, QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getAllDepartments = (queryParams: QueryParamsType) =>
  agent.post(
    `${apiURLs.department}/get`,
    searchFields(queryParams, apiURLs.department)
  );

export const searchDepartments = (searchText: string | null) =>
  agent.get(`${apiURLs.department}?name=${searchText}`);

export const createDepartmentsApi = (values: DepartmentDTO) =>
  agent.post(apiURLs.department, values);

export const getDepartmentEmployees = (departmentId: string) =>
  agent.get(`${apiURLs.departmentEmployees}/${departmentId}`);

export const getDepartmentWorkPositions = (departmentId: string) =>
  agent.get(`${apiURLs.departmentWorkPositions}/${departmentId}`);

export const getWorkPositionEmployees = (payload: WorkPositionEmployees) =>
  agent.post(`${apiURLs.workPositionEmployees}`, payload);

export const updateDepartment = (id: string, values: DepartmentDTO) =>
  agent.patch(`${apiURLs.department}/${id}`, values);

export const getOneDepartment = (id: string) =>
  agent.get(`${apiURLs.department}/${id}`);

export const getDepartmentHeadEmployee = (departmentId: string) =>
  agent.get(`${apiURLs.department}/head/employee/${departmentId}`);

export const getDepartmentsByHeadEmployeeId = (
  employeeId: string,
  queryParams: QueryParamsDTO
) =>
  agent.post(
    `${apiURLs.department}/head/department/${employeeId}`,
    queryParams
  );

export const deleteDepartment = (id: string) =>
  agent.delete(`${apiURLs.department}/${id}`);

export const getDepartmentSelectOptions = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.department}/selectOptions`, queryParams);

export const createDepartmentHeadEmployee = ({
  departmentId,
  employeeId,
}: {
  departmentId: string;
  employeeId: string;
}) =>
  agent.post(`${apiURLs.department}/head/employee`, {
    departmentId,
    employeeId,
  });

export const deleteDepartmentHeadEmployee = (departmentId: string) =>
  agent.delete(`${apiURLs.department}/head/employee/${departmentId}`);
