import React, { useCallback, useEffect, useState } from 'react';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import { TenantsQueryParamsType } from 'types/QueryParams';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearSingleTenant, clearTenantAccounts } from 'redux/tenants/actions';
import TenantInfoModal from './TenantModal';

const TenantsList: React.FC = () => {
  const { columns, take } = useHeader();
  const dispatch = useDispatch();
  const [tenantId, setTenantId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [queryParams, setQueryParams] = useState<TenantsQueryParamsType>({
    pageOptions: { take },
  });
  const { tenants, getTenants } = useTenantsData();
  const toogleModalVisibility = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const onSearch = (text: string) => {
    setQueryParams({
      filterOptions: { filterText: text },
    });
  };

  const onPageChange = (page: number) => {
    setQueryParams({
      pageOptions: { take, page },
    });
  };

  useEffect(() => {
    dispatch(clearSingleTenant());
    dispatch(clearTenantAccounts());
  }, []);

  useEffect(() => {
    getTenants(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (searchParams.get('viewtenantId')) {
      setTenantId(searchParams.get('viewtenantId') as string);
      searchParams.delete('viewtenantId');
      setSearchParams(searchParams);
      toogleModalVisibility();
    }
  }, [searchParams]);

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={tenants?.loading}
          columns={columns}
          data={tenants?.data}
          totalItems={tenants?.meta?.itemCount}
          currentPage={tenants?.meta?.page}
          totalPages={tenants?.meta?.pageCount}
          paginationName="Tenants"
          rowSelectionType="checkbox"
          onPageChange={onPageChange}
          onSearch={onSearch}
          showSearch
        />
        {modalVisible && (
          <TenantInfoModal
            tenant={tenants?.data?.find((tenant) => tenant?.id === tenantId)}
            onClose={toogleModalVisibility}
          />
        )}
      </>
    </Content>
  );
};
export default TenantsList;
