import { useSelector } from 'react-redux';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { OffboardingTask } from 'types/Offboarding';

export function useOffboardingData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.offboarding
  );

  const getTabStatus = (tab: OffboardingTaskType) => {
    if (!data) {
      return null;
    }

    const task = data?.offBoardingTasks?.find(
      (item: OffboardingTask) => item.type === tab
    );
    return task?.status;
  };

  const getTaskId = (tab: OffboardingTaskType) => {
    if (!data) {
      return null;
    }

    const task = data?.offBoardingTasks?.find(
      (item: OffboardingTask) => item.type === tab
    );
    return task?.id;
  };

  return {
    data,
    loading,
    loaded,
    errored,
    error,
    getTabStatus,
    getTaskId,
  };
}
