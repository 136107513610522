import React from 'react';
import PinInput from 'react-pin-input';

interface AuthenticatorPinInputProps {
  onComplete: (authenticationCode: string) => void;
}
const AuthenticatorPinInput: React.FC<AuthenticatorPinInputProps> = ({
  onComplete,
}) => (
  <PinInput
    length={6}
    secret
    secretDelay={350}
    type="numeric"
    inputMode="number"
    inputFocusStyle={{
      border: '1px solid #0050b3',
    }}
    inputStyle={{
      borderRadius: '8px',
      border: '1px solid #d0d5dd',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    }}
    onComplete={onComplete}
  />
);

export default AuthenticatorPinInput;
