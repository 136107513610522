import React, { useCallback, useMemo, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

import GenericForm from 'components/NewForms/Form';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { CheckBox } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/ConfigureComponentStyles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import {
  StyledTrackingFilterPanelCol,
  StyledTrackingFilterPanelLabel,
} from '../StyledTrackingFilterPanel';
import {
  TrackingFilterPanelLabel,
  TrackingFilterPanelPopoverInnerOverlayStyle,
} from '../utils';

interface DescriptionFilterPanelItemProps {
  borderRight?: boolean;
}

const DescriptionFilterPanelItem: React.FC<DescriptionFilterPanelItemProps> = ({
  borderRight,
}) => {
  const [visible, setVisible] = useState(false);
  const {
    urlState: { filterText },
    setUrlState,
  } = useTrackedHoursData();
  const { t } = useTranslation();

  const [form] = useForm();

  const FormConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('searchDescription'),
          defaultValue: filterText,
          type: InputTypes.INPUT,
          hideLabel: true,
        },
      ],
    ],
    [filterText]
  );

  const tooglePopoverVisibility = useCallback(() => {
    if (visible) {
      setUrlState({ filterText: form.getFieldValue('description') });
    }
    setVisible(!visible);
  }, [visible]);

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setUrlState({ filterText: '' });
      form.resetFields();
    }
  };

  return (
    <Popover
      overlayInnerStyle={TrackingFilterPanelPopoverInnerOverlayStyle}
      trigger="click"
      placement="bottom"
      popupVisible={visible}
      onVisibleChange={tooglePopoverVisibility}
      content={
        <Row gutter={[10, 0]}>
          <Col span={24}>
            <GenericForm form={form} formConfiguration={FormConfig} />
          </Col>
          <Col span={24}>
            <CheckBox onChange={onCheckboxChange}>
              {t('keepDefaultDescription')}
            </CheckBox>
          </Col>
        </Row>
      }
    >
      <StyledTrackingFilterPanelCol borderRight={borderRight}>
        <StyledTrackingFilterPanelLabel>
          <Row align="middle" gutter={[5, 0]}>
            <Col>{t(TrackingFilterPanelLabel.DESCRIPTION)}</Col>
            <Col>
              <CaretDownOutlined />
            </Col>
          </Row>
        </StyledTrackingFilterPanelLabel>
      </StyledTrackingFilterPanelCol>
    </Popover>
  );
};

export default DescriptionFilterPanelItem;
