import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import { getAssetLogsById } from 'api/assetLogsService';
import { AssetsLogs } from './types';

export const fetchAssetLogsById =
  (id: string, queryParams: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: AssetsLogs.FETCH_ASSET_LOG_START,
    });
    getAssetLogsById(id, queryParams)
      .then((res) => {
        dispatch({
          type: AssetsLogs.FETCH_ASSET_LOG_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: AssetsLogs.FETCH_ASSET_LOG_ERRORED,
          payload: e,
        });
      });
  };

export const clearSingleAssetLogs = () => (dispatch: Dispatch) => {
  dispatch({
    type: AssetsLogs.CLEAR_ASSET_LOG,
  });
};
