import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchPayrollDates } from 'redux/payrollReport/actions';
import { fetchTenantLocationsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';
import i18next from '../../../../i18n';

export default function PayrollReportsFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onFormChange() {
    const startDate: string = form
      .getFieldValue('startDate')
      ?.format('YYYY-MM-DD');
    const endDate: string = form.getFieldValue('endDate')?.format('YYYY-MM-DD');

    const tenantLocationId = form.getFieldValue('tenantLocationId')?.value;

    dispatch(fetchPayrollDates({ startDate, endDate, tenantLocationId }));
  }

  const PayrollReportFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'tenantLocationId',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'startDate',
          label: i18next.t('startDate'),
          type: InputTypes.DATEPICKER,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'endDate',
          label: i18next.t('endDate'),
          type: InputTypes.DATEPICKER,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={PayrollReportFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchPayrollDates());
            }}
          >
            {i18next.t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
