import { useSelector } from 'react-redux';

export default function useIdeaboxData() {
  const ideabox = useSelector((state: any) => state.ideabox);

  const {
    data: singleIdeabox,
    loading: singleIdeaboxLoading,
    loaded: singleIdeaboxLoaded,
    errored: singleIdeaboxErrored,
    error: singleIdeaboxError,
  } = useSelector((state: any) => state.singleIdeabox);

  return {
    data: ideabox?.data?.data || [],
    loading: ideabox?.loading,
    loaded: ideabox?.loaded,
    error: ideabox?.error,
    pagination: ideabox?.data?.meta,

    singleIdeabox,
    singleIdeaboxLoading,
    singleIdeaboxLoaded,
    singleIdeaboxErrored,
    singleIdeaboxError,
  };
}
