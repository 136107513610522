import { MetaPagination } from 'types/GridTable';
import { TenantAccountType, TenantDataType } from 'types/Tenant';

export enum Tenants {
  FETCH_TENANTS_START = 'FETCH_TENANTS_START',
  FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS',
  FETCH_TENANTS_ERRORED = 'FETCH_TENANTS_ERRORED',

  CLEAR_TENANTS = 'CLEAR_TENANTS',
  CLEAR_SINGLE_TENANT = 'CLEAR_SINGLE_TENANT',
  CLEAR_TENANT_ACCOUNTS = 'CLEAR_TENANT_ACCOUNTS',

  FETCH_TENANT_ACCOUNTS_START = 'FETCH_TENANTS_ACCOUNTS_START',
  FETCH_TENANTS_ACCOUNTS_SUCCESS = 'FETCH_TENANTS_ACCOUNTS_SUCCESS',
  FETCH_TENANTS_ACCOUNTS_ERRORED = 'FETCH_TENANTS_ACCOUNTS_ERRORED',

  FETCH_SINGLE_TENANT_START = 'FETCH_SINGLE_TENANT_START',
  FETCH_SINGLE_TENANT_SUCCESS = 'FETCH_SINGLE_TENANT_SUCCESS',
  FETCH_SINGLE_TENANT_ERRORED = 'FETCH_SINGLE_TENANT_ERRORED',
}

export interface TenantsState {
  tenants: {
    data?: Array<TenantDataType>;
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  singleTenanat: {
    data?: TenantDataType;
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  tenantAccounts: {
    data?: Array<TenantAccountType>;
    meta?: MetaPagination;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
}
