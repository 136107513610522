import { Avatar, Col, List, Popconfirm, Row, Skeleton, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { IApprover, TimeOffRequest } from 'types/TimeOff';
import CardItem from 'ui-v2/components/Card';
import { IconButton } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import TrashIcon from 'Icons/TrashIcon';

import { removeTimeOff } from 'api/timeOffService';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { useDispatch } from 'react-redux';
import useTimeOffRequestsData from 'ui-v2/hooks/useTimeOffRequestsData';
import { useTranslation } from 'react-i18next';
import { formatStartAndEndDate } from 'utils/utilFunctions';
import * as Styled from './TimeOffStyles';

export default function EmployeeTimeOff({
  loading,
  employeeId,
}: {
  loading: any;
  employeeId: string;
}) {
  const dispatch = useDispatch();
  const {
    employeeTimeOffList,
    onPageChange,
    PAGE_SIZE,
    currentPage,
    total,
    loading: timeOffLoading,
  } = useTimeOffRequestsData();
  const { t } = useTranslation();

  const onDeleteRequest = (timeOffId: string) => {
    removeTimeOff(timeOffId)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('timeoffDeleted'));
          dispatch(fetchEmployeesDetail(employeeId));
        }
      })
      .catch(() => {
        toast.error(t('failedtoDeleteTimeoff'));
      });
  };

  const parseDates = () => {
    if (employeeTimeOffList?.length > 0) {
      const allDates = employeeTimeOffList
        .map((e: any) => JSON.parse(e.dates))
        .flat();
      return allDates;
    }

    return [];
  };

  const dates = formatStartAndEndDate(parseDates());

  const paginationProps = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total,
    onChange: onPageChange,
  };

  return (
    <CardItem title={t('employeeRequests')} wrapContentHeight>
      <List
        loading={timeOffLoading || loading}
        itemLayout="horizontal"
        style={{ width: '100%' }}
        dataSource={employeeTimeOffList}
        pagination={paginationProps}
        renderItem={(request: TimeOffRequest, index: number) => (
          <Skeleton avatar title={false} loading={loading} active>
            <Styled.Item
              align="middle"
              last={index === employeeTimeOffList.length - 1}
            >
              <Col span={24}>
                <Row>
                  <Styled.Reason>{request.reason}</Styled.Reason>
                </Row>
                <Row>
                  <Col span={11}>
                    {JSON.parse(request.dates).map((item: any) => (
                      <Row>
                        <Col span={24}>
                          {item.date && item.timeOffStart && item.timeOffEnd ? (
                            <Row>
                              <Col span={6}>
                                <Styled.Record>
                                  {moment(item.date).format('DD/MM/YYYY')}
                                </Styled.Record>
                              </Col>
                              <Col span={7}>
                                <Row>
                                  <Col span={13}>
                                    <Row justify="center">
                                      <Styled.Record>
                                        {`${moment(item.timeOffStart).format(
                                          'HH:mm'
                                        )} - ${moment(item.timeOffEnd).format(
                                          'HH:mm'
                                        )}`}
                                      </Styled.Record>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ) : (
                            <Col span={6}>
                              <Styled.Record>
                                {JSON.parse(request?.dates).length === 1 &&
                                  moment(item.date).format('DD/MM/YYYY')}
                              </Styled.Record>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Col span={24}>
                        <Styled.Record>
                          {JSON.parse(request?.dates).length > 1 && dates}
                        </Styled.Record>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={3} offset={5}>
                    <Row justify="end">
                      <Avatar.Group>
                        {request.approvers.map((approver: IApprover) => (
                          <Tooltip
                            title={`${approver.employee?.firstName} ${approver.employee?.lastName}`}
                            placement="top"
                          >
                            <Avatar style={{ backgroundColor: approver.color }}>
                              {approver.employee?.firstName?.trim().charAt(0)}
                            </Avatar>
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row justify="center">
                      <Styled.Status color={request.statusColor}>
                        {request.status}
                      </Styled.Status>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <Row justify="end">
                      <Popconfirm
                        placement="topLeft"
                        title={t('areYouSureYouWanttoDelete')}
                        onConfirm={() => onDeleteRequest(request.id)}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <IconButton>
                          <TrashIcon />
                        </IconButton>
                      </Popconfirm>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Styled.Item>
          </Skeleton>
        )}
      />
    </CardItem>
  );
}
