import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import TrackingCard from 'ui-v2/routes/tracking/trackingCard';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { getTenantAvatarStyle } from 'ui-v2/routes/superAdmin/TenantAccountsList/utils';
import {
  StyledTrackingPoint,
  StyledTrackingText,
} from 'ui-v2/routes/tracking/StyledTracking';
import { formatTotalMinutes } from 'ui-v2/routes/tracking/utils';
import { TrackedHourSummaryDTO } from 'types/tracking';
import { useTranslation } from 'react-i18next';
import {
  StyledTrackingPieChart,
  StyledTrackingSummaryBarChart,
  StyledTrackingSummaryContent,
  StyledTrackingSummaryListContent,
  StyledTrackingSummaryListContentItem,
  StyledTrackingSummaryListContentItemButton,
  StyledTrackingSummaryListDetailContent,
  StyledTrackingSummaryListDetailItemContent,
} from './StyledTrackingSummary';
import { barChartOptions, doughnutOptions } from './utils';

interface TrackingSummaryListItemProps {
  item: TrackedHourSummaryDTO;
}

const TrackingSummaryListItem: React.FC<TrackingSummaryListItemProps> = ({
  item,
}) => {
  const [visible, setVisible] = useState(false);
  const {
    urlState: { startDate, endDate, employeeIds, filterText },
    setUrlState,
    TabLabel,
  } = useTrackedHoursData();
  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onProjectClick = useCallback(() => {
    if (!item?.projectId.includes('StandartWorkingHours')) {
      setUrlState({
        employeeIds,
        startDate,
        endDate,
        filterText,
        projectIds: [item.projectId],
        tab: TabLabel.detailed,
      });
    }
  }, [startDate, endDate, employeeIds, filterText]);

  return (
    <StyledTrackingSummaryListContentItem
      key={item.projectName}
      isDetailsOpen={visible}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Row align="middle" gutter={[20, 0]}>
            <Col>
              <StyledTrackingSummaryListContentItemButton
                onClick={toggleVisibility}
              >
                {item.totalCount}
              </StyledTrackingSummaryListContentItemButton>
            </Col>
            <Col>
              <Row justify="space-between">
                <Col>
                  <Row align="middle" gutter={[10, 0]}>
                    <Col>
                      <StyledTrackingPoint
                        color={
                          getTenantAvatarStyle({
                            initials: item.projectName,
                          }).backgroundColor
                        }
                      />
                    </Col>
                    <Col>
                      <StyledTrackingText
                        cursorPointer
                        onClick={onProjectClick}
                        color={
                          getTenantAvatarStyle({
                            initials: item.projectName,
                          }).backgroundColor
                        }
                      >
                        {item.projectName}
                      </StyledTrackingText>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <StyledTrackingText bold>
            {formatTotalMinutes(item.duration)}
          </StyledTrackingText>
        </Col>
        {visible && item.details && (
          <Col span={24}>
            <StyledTrackingSummaryListDetailContent>
              {item.details.map((detail, index) => (
                <Col key={`${detail.description}_${detail.duration}`} span={24}>
                  <StyledTrackingSummaryListDetailItemContent
                    justify="space-between"
                    isLast={index + 1 === item.details?.length}
                  >
                    <Col>
                      <StyledTrackingText>
                        {detail.description}
                      </StyledTrackingText>
                    </Col>
                    <Col>
                      <StyledTrackingText bold>
                        {formatTotalMinutes(detail.duration)}
                      </StyledTrackingText>
                    </Col>
                  </StyledTrackingSummaryListDetailItemContent>
                </Col>
              ))}
            </StyledTrackingSummaryListDetailContent>
          </Col>
        )}
      </Row>
    </StyledTrackingSummaryListContentItem>
  );
};

interface TrackingSummaryListProps {
  data?: TrackedHourSummaryDTO[];
}
const TrackingSummaryList: React.FC<TrackingSummaryListProps> = ({ data }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <>
      {data?.map((item) => (
        <TrackingSummaryListItem key={item.projectId} item={item} />
      ))}
    </>
  );
};

const TrackingSummary: React.FC = () => {
  const { t } = useTranslation();
  const {
    barChart: { chartData: barChartData, loading: barChartLoading },
    pieChart: { chartData: pieChartData, loading: pieChartLoading },
    trackedHoursSummary: { data: summary },
    urlState: { tab },
    TabLabel,
    fetch,
  } = useTrackedHoursData();

  useEffect(() => {
    if (tab === TabLabel.summary) {
      fetch({});
    }
  }, [tab]);

  return (
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <TrackingCard
          overallTitle="Overall approved hours:"
          perPageTitle={t('hoursPerSection')}
          pageTotal={summary?.sectionTotal}
          overallTotal={summary?.overallTotal}
          loading={barChartLoading}
          isEmpty={!barChartData.labels?.length}
        >
          <StyledTrackingSummaryBarChart
            data={barChartData}
            options={barChartOptions}
          />
        </TrackingCard>
      </Col>
      <Col span={24}>
        <TrackingCard
          overallTitle="Overall approved hours:"
          perPageTitle={t('hoursPerSection')}
          pageTotal={summary?.sectionTotal}
          overallTotal={summary?.overallTotal}
          loading={pieChartLoading}
          isEmpty={!pieChartData.labels?.length}
        >
          <StyledTrackingSummaryContent>
            <StyledTrackingSummaryListContent>
              <TrackingSummaryList data={summary?.data} />
            </StyledTrackingSummaryListContent>
            <StyledTrackingPieChart
              data={pieChartData}
              options={doughnutOptions}
            />
          </StyledTrackingSummaryContent>
        </TrackingCard>
      </Col>
    </Row>
  );
};

export default TrackingSummary;
