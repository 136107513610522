import React, { useCallback, useState } from 'react';
import { Col, Row, Image } from 'antd';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { TenantDataType } from 'types/Tenant';
import GenericModal from 'ui-v2/components/GenericModal';
import { FallBackImage } from 'ui-v2/components/Tenant/utils';
import { StyledButton } from 'components/NewForms/FormStyled';
import TenantApproveModalForm from 'components/NewForms/TenantApproveModalForm/TenantApproveModalForm';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { rejectTenantById } from 'api/tenantService';
import { useDispatch } from 'react-redux';
import { fetchTenants } from 'redux/tenants/actions';
import { toastErrorMessages } from 'utils/utilFunctions';
import TenantInfo from './TenantInfo';
import { StyledTenantApproveBadge } from './StyledTenantModal';

const TenantModalIcon = ({
  name,
  logoContent,
  approved,
  rejected,
}: {
  name: string;
  approved?: boolean;
  logoContent?: string;
  rejected: boolean;
}) => (
  <Row align="top" gutter={[10, 10]}>
    <Col>
      <Image
        width={50}
        height={50}
        src={logoContent}
        fallback={FallBackImage}
      />
    </Col>
    <Col>
      <Row gutter={[0, 5]}>
        <Col span={24}>{`${name}`}</Col>
        <Col span={24}>
          <StyledTenantApproveBadge approved={`${!!approved}`}>
            {rejected && 'Rejected'}
            {!rejected && approved && 'Approved'}
            {!rejected && !approved && 'Pending approval'}
          </StyledTenantApproveBadge>
        </Col>
      </Row>
    </Col>
  </Row>
);

interface TenantInfoModal {
  tenant?: TenantDataType;
  onClose: () => void;
}

const TenantInfoModal: React.FC<TenantInfoModal> = ({ tenant, onClose }) => {
  const [approved, setApproved] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (isNil(tenant)) {
    return <></>;
  }

  const onViewAccount = () => {
    onClose();
    navigate(`/tenant-accounts?tenantId=${tenant?.id}`);
  };

  const onReject = () => {
    if (tenant?.approved) {
      toastErrorMessages(t('tenantIsAlreadyApproved'));
      return;
    }

    if (tenant?.deletedAt) {
      toastErrorMessages(t('tenantIsAlreadyRejected'));
      return;
    }
    rejectTenantById(tenant?.id)
      .then(() => {
        onClose();
        toast.success(t('successfullyRejectedTenant'));
        dispatch(
          fetchTenants({
            queryParams: { pageOptions: { page: 1 } },
          })
        );
      })
      .catch((error) => {
        toastErrorMessages(error);
      });
  };

  const toogleApprove = useCallback(() => {
    setApproved(!approved);
  }, [approved]);

  return (
    <GenericModal
      title=""
      icon={
        <TenantModalIcon
          name={tenant?.name}
          logoContent={tenant?.logoContent}
          approved={tenant?.approved}
          rejected={!!tenant?.deletedAt}
        />
      }
      open
      closeModal={onClose}
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          {!approved ? (
            <TenantInfo tenant={tenant} />
          ) : (
            <TenantApproveModalForm
              onClose={toogleApprove}
              onSuccess={onClose}
              tenant={tenant}
            />
          )}
        </Col>
        {!approved && (
          <Col span={24}>
            <Row justify="space-between">
              <Col span={11}>
                <StyledButton
                  hidden={!!tenant?.deletedAt || tenant?.approved}
                  type="ghost"
                  onClick={onReject}
                >
                  {t('reject')}
                </StyledButton>
              </Col>
              {tenant?.approved ? (
                <Col span={11}>
                  <StyledButton type="primary" ghost onClick={onViewAccount}>
                    {t('viewAccounts')}
                  </StyledButton>
                </Col>
              ) : (
                <Col span={11}>
                  <StyledButton type="primary" onClick={toogleApprove}>
                    {t('Approve')}
                  </StyledButton>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </GenericModal>
  );
};

export default TenantInfoModal;
