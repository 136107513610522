import { Col, Form, Row } from 'antd';
import { createBankDetails, updateBankDetailsById } from 'api/bankDetails';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BankDetail, BankDetailsCreate } from 'types/BankDetails';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeId: string;
  fetchBankDetails: (value: string) => void;
  selectedBankDetails?: BankDetail;
}

export default function AddBankDetails({
  open,
  closeModal,
  employeeId,
  fetchBankDetails,
  selectedBankDetails,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });
  const { t } = useTranslation();

  const AddBankDetailsFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 8,
          offset: 0,
          name: 'bankName',
          label: t('bankName'),
          type: 'input',
          defaultValue: selectedBankDetails?.bankName,
          rules: [
            {
              required: true,
              message: t('bankNameisRequired'),
            },
          ],
        },
        {
          col: 8,
          offset: 2,
          name: 'bankAddress',
          label: t('bankAddress'),
          type: 'input',
          defaultValue: selectedBankDetails?.bankAddress,
          rules: [
            {
              required: true,
              message: t('bankAddressisRequired'),
            },
          ],
        },
        {
          col: 4,
          offset: 2,
          name: 'currencyId',
          label: t('currency'),
          type: 'select',
          selectOptions: currencyOptions,
          defaultValue:
            selectedBankDetails?.currency?.id || currencyOptions[1]?.id,
          rules: [
            {
              required: true,
              message: t('currencyisRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'iban',
          label: 'IBAN',
          type: 'input',
          defaultValue: selectedBankDetails?.iban,
          rules: [
            {
              required: true,
              message: t('IBANisRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'swift',
          label: 'SWIFT',
          type: 'input',
          defaultValue: selectedBankDetails?.swift,
          rules: [
            {
              required: true,
              message: t('SWIFTisRequired'),
            },
          ],
        },
      ],
    ],
    [currencyOptions]
  );
  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const formValues: BankDetailsCreate = form.getFieldsValue(true);

    const valuesToSend = {
      ...formValues,
      employeeId,
    };

    if (selectedBankDetails) {
      await updateBankDetailsById(selectedBankDetails?.id, valuesToSend)
        .then((response: { status: number; data: BankDetail }) => {
          if (response.status === 200 && response.data) {
            fetchBankDetails(employeeId);
            closeModal();
            setLoading(false);
            toast.success(t('succesfully edited bank details'));
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    } else {
      await createBankDetails(valuesToSend)
        .then((response: { status: number; data: BankDetail }) => {
          if (response.status === 200 && response.data) {
            fetchBankDetails(employeeId);
            closeModal();
            setLoading(false);
            toast.success(t('Succesfully added bank details'));
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    }
  };

  return (
    <GenericModal
      title={
        selectedBankDetails ? t('edit bank details') : t('add bank details')
      }
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddBankDetailsFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
