import CandidateForm from 'components/NewForms/NewCandidateForm';
import React from 'react';
import Content from 'ui-v2/components/Content';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';

export default function EditProfile() {
  const { candidate } = useCandidateData();

  return (
    <Content position="center">
      <CandidateForm candidate={candidate as any} />
    </Content>
  );
}
