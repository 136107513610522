import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TenantConfigAsyncActions } from 'redux/tenantConfig/actions';
import { TenantConfigUpdateDTO } from 'types/TenantConfig';
import { TenantConfigApiClient } from 'api/tenantConfigService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import TenantConfigFormContent, {
  TenantOnboardingConfigProps,
} from './TenantConfigFormContent';

interface TenantConfigFormProps {
  tenantOnboardingConfigProps?: TenantOnboardingConfigProps;
  toggleSubmit?: () => void;
}

export default function TenantConfigForm({
  tenantOnboardingConfigProps,
  toggleSubmit,
}: TenantConfigFormProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { tenantPersisted: tenant } = useTenantsData();

  useEffect(() => {
    dispatch(TenantConfigAsyncActions.fetchCurrentTenantConfigurations());
  }, []);

  const onSubmit = useCallback(
    async (values: TenantConfigUpdateDTO) => {
      setLoading(true);
      TenantConfigApiClient.updateTenantConfig({
        payload: values,
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              TenantConfigAsyncActions.fetchCurrentTenantConfigurations()
            );
            toast.success(
              `${t('Successfully set up')} ${tenant?.name ?? ''} ${t(
                'configurations'
              )}!`
            );
          }
          toggleSubmit?.();
        })
        .catch((e) => toastErrorMessages(e))
        .finally(() => setLoading(false));
    },
    [tenant]
  );

  return (
    <TenantConfigFormContent
      loading={loading}
      onSubmit={onSubmit}
      tenantOnboardingConfigProps={tenantOnboardingConfigProps}
    />
  );
}
