import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { CreateQuestionnaireFormDto } from 'types/Questionnaires';
import { createQuestionnaireSaveAnswers } from 'api/questionnairesService';
import { Questionnaire } from './types';
import i18next from '../../i18n';

export const createQuestionnaire =
  (formValues: CreateQuestionnaireFormDto, currentEmployee: { id: string }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: Questionnaire.CREATE_QUESTIONNAIRE_QUESTION_START });

    try {
      const reviewerId = localStorage.getItem('EMPLOYEE_ID') as string;

      const employeeReviewPayload: CreateQuestionnaireFormDto = {
        ...formValues,
        reviewerId,
        employeeId: currentEmployee.id,
      };

      await createQuestionnaireSaveAnswers(employeeReviewPayload);

      dispatch({
        type: Questionnaire.CREATE_QUESTIONNAIRE_QUESTION_SUCCESS,
        payload: employeeReviewPayload,
      });

      toast.success(i18next.t('reviewCreatedSuccessfully'));
    } catch (error) {
      dispatch({
        type: Questionnaire.CREATE_QUESTIONNAIRE_QUESTION_ERRORED,
        payload: error,
      });

      toast.error(i18next.t('somethingWentWrong'));
    }
  };
