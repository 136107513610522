import { VendorCategory } from './VendorCategory';

export interface VendorType {
  categoryName: any;
  id: string;
  taxId: string;
  companyName: string;
  country: string;
  city: string;
  street: string;
  zipCode: string;
  state: string;
  contactPerson: string;
  contactPersonPrefix: string;
  email: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  deletedAt: Date;
  vendorCategories: VendorCategory[];
}

export enum UserRole {
  Employee = 'employee',
}
