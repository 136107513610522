import { Form } from 'antd';
import React, { useMemo, useEffect, useState } from 'react';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import CardItem from 'ui-v2/components/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RcFile } from 'antd/lib/upload/interface';
import {
  convertBase64,
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
  getValidFileContent,
} from 'utils/utilFunctions';
import {
  OpenPositionCreateDto,
  OpenPositionType,
} from 'interfaces/OpenPosition';
import moment, { Moment } from 'moment';
import { Image } from 'types/Image';
import { createNewOpenPosition, updateOpenPosition } from 'api/openPositions';
import { useTranslation } from 'react-i18next';
import { InputTypes } from 'types/FormTypes';
import { useWorkPositionsSkimData } from 'ui-v2/hooks/useWorkPositionsSkimData';
import GenericForm from '../Form/GenericForm';
import { isFormEdited } from '../Form/utils';

interface IProps {
  openPosition?: OpenPositionType;
}

function OpenPositionForm({ openPosition }: IProps) {
  const { filteredWorkPositions } = useWorkPositionsSkimData();
  const [form] = Form.useForm();
  const [fileToUpload, setFileToUpload] = useState<Image>();
  const [loading, isLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const { optionsType: employmentTypes } = useGetSelectOptions({
    type: 'employement-type',
  });

  async function onFormFinish(values: any) {
    delete values.upload;
    if (values.validTo) {
      if (moment(values.validTo).diff(moment(values.validFrom), 'days') < 0) {
        toast.warning(t('Please enter valid dates correctly!'));
        isLoading(false);
        return;
      }
    }
    let valuesToSend: OpenPositionCreateDto = {
      ...values,
      image: {
        ...fileToUpload,
        content: fileToUpload?.content,
      },
      workPositionId: form.getFieldValue('workPositionId'),
      validFrom: convertDateToUTC(values.validFrom),
      validTo: convertDateToUTC(values.validTo),
    };

    if (!fileToUpload?.content) {
      delete valuesToSend.image;
    }
    if (!values.validTo) {
      valuesToSend = { ...valuesToSend, noEndPeriod: true };
      delete valuesToSend.validTo;
    }

    isLoading(true);
    if (openPosition?.id) {
      if (
        isFormEdited({
          formValues: valuesToSend,
          valuesToCheck: openPosition,
          entity: 'openPosition',
        })
      ) {
        navigate('/open-positions');
        isLoading(false);
        return;
      }
      updateOpenPosition(openPosition.id, valuesToSend)
        .then(() => {
          toast.success(t('Open Position updated successfully!'));
          navigate('/open-positions');
        })
        .catch(() => {
          toast.error(t('Failed to create Open Position!'));
        })
        .finally(() => {
          isLoading(false);
        });
      return;
    }

    createNewOpenPosition(valuesToSend)
      .then(() => {
        toast.success(t('New Open Position created successfully!'));
        navigate('/open-positions');
      })
      .catch(() => {
        toast.error(t('Failed to create Open Position!'));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFileToUpload({ name: file.name, content: validBase64, type: file.type });
    return false;
  }

  const OpenPositionFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: 'input',
          defaultValue: openPosition?.name,
          rules: [
            {
              required: true,
              message: t('Open Position name is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'isOpenPosDefault',
          label: t('defaultopenPosition'),
          type: 'switch',
          defaultValue: openPosition?.isOpenPosDefault
            ? String(openPosition?.isOpenPosDefault)
            : 'false',
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: 'textarea',
          defaultValue: openPosition?.description,
          rules: [
            {
              required: true,
              message: t('Open Position description is required'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'validFrom',
          label: t('validFrom'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(openPosition?.validFrom),
          dateProps: {
            format: userDateFormat,
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'validTo',
          label: t('validTo'),
          type: 'datepicker',
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('validFrom')),
          },
          defaultValue: convertUTCtoLocalTime(openPosition?.validTo),
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'workPositionId',
          label: t('workPosition'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: filteredWorkPositions,
          defaultValue: openPosition && openPosition?.workPosition.id,
          rules: [
            {
              required: true,
              message: t('Work Positions is required!'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'employmentTypeId',
          label: t('employmentType'),
          showSearch: true,
          type: 'select',
          selectOptions: employmentTypes,
          defaultValue: openPosition && openPosition?.employeeType.id,
          rules: [
            {
              required: true,
              message: t('Employement type is required'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'upload',
          label: t('upload'),
          type: InputTypes.UPLOAD,
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (file: RcFile) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            defaultFileList:
              openPosition?.image &&
              ([
                {
                  uid: '1',
                  name: `${openPosition?.name} Image`,
                  url: openPosition?.image,
                },
              ] as any),
          },
        },
      ],
    ],
    [employmentTypes, openPosition?.image, filteredWorkPositions]
  );

  useEffect(() => {
    if (searchParams.get('add-save-open-position') === 'true') {
      searchParams.delete('add-save-open-position');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-save-open-position') === 'true') {
      searchParams.delete('edit-save-open-position');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <CardItem title="">
      <GenericForm
        formConfiguration={OpenPositionFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      />
    </CardItem>
  );
}

export default OpenPositionForm;
