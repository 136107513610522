import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  NodataLabel,
  RowItemAsset,
} from 'ui-v2/components/Trainings/TrainingItemStyled';
import { TeanantLocation } from 'types/TenantLocation';
import { StatusTag } from 'ui-v2/components/DataGridComponents';

interface IProps {
  data: TeanantLocation;
  onEdit: () => void;
  onDelete: () => void;
}

export function TenantLocationItem({ data, onEdit, onDelete }: IProps) {
  const { t } = useTranslation();
  return (
    <>
      <RowItemAsset>
        <Col span={4}>
          <NodataLabel ellipsis style={{ width: '80%' }}>
            <Popover
              content={data.country}
              placement="topLeft"
              style={{ width: '30%', height: '30%', maxWidth: '200px' }}
            >
              {data.country}
            </Popover>
          </NodataLabel>
        </Col>
        <Col span={4}>
          <NodataLabel ellipsis style={{ width: '80%' }}>
            <Popover
              content={data.city}
              placement="topLeft"
              style={{ width: '30%', height: '30%', maxWidth: '200px' }}
            >
              {data.city}
            </Popover>
          </NodataLabel>
        </Col>
        <Col span={5}>
          <NodataLabel ellipsis style={{ width: '80%' }}>
            <Popover
              content={data.streetName}
              placement="topLeft"
              style={{ width: '30%', height: '30%', maxWidth: '200px' }}
            >
              {data.streetName}
            </Popover>
          </NodataLabel>
        </Col>
        <Col span={4}>
          <NodataLabel>{data.postalCode}</NodataLabel>
        </Col>
        <Col span={4}>
          <NodataLabel>
            <StatusTag status={data.isDefaultLocation ? 'verify' : 'new'}>
              {data.isDefaultLocation ? t('yes') : t('no')}
            </StatusTag>
          </NodataLabel>
        </Col>
        <Col>
          <Row gutter={[10, 0]}>
            <Col>
              <IconButton onClick={onEdit}>
                <EditOutlined />
              </IconButton>
            </Col>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={onDelete}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <IconButton>
                  <DeleteOutlined />
                </IconButton>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </RowItemAsset>
    </>
  );
}
