import { Col, Result, Row } from 'antd';
import * as React from 'react';
import { ButtonStyled } from '../SetUserPasswordStyles';
import i18next from '../../../i18n';

export function PasswordValidationFail({
  resendDisabled,
  countDown,
  resendEmail,
}: {
  resendDisabled: boolean;
  countDown: number;
  resendEmail: () => void;
}) {
  return (
    <>
      <Result
        title={i18next.t('somethingWentWrong')}
        status="403"
        subTitle={i18next.t(
          'Click the button below to receive another email for setting up your password.'
        )}
      />
      <Row justify="center" align="middle">
        <Col>
          <ButtonStyled
            type="primary"
            onClick={resendEmail}
            disabled={resendDisabled}
          >
            {`${i18next.t('resendCode')} ${
              countDown > 0 ? `(${countDown})` : ''
            }`}
          </ButtonStyled>
        </Col>
      </Row>
    </>
  );
}
