import { AssetCategoryCreateDto } from 'types/AssetCategories';
import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getAssetCategories = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.assetCategories}/get`, queryParams);

export const getAssetCategoriesSelectOptions = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.assetCategories}/selectOptions`, queryParams);

export const getAssetSubCategoriesSelectOptions = (
  queryParams?: QueryParamsDTO
) =>
  agent.post(`${apiURLs.assetCategories}/selectoptions`, {
    pageOptionsDto: queryParams?.pageOptions,
    filterOptions: queryParams?.filterOptions,
  });

export const createAssetCategory = (data: AssetCategoryCreateDto) =>
  agent.post(apiURLs.assetCategories, data);

export const updateAssetCategory = async (id: string, values: any) =>
  agent.patch(`${apiURLs.assetCategories}/${id}`, values);

export const createAssetSubCategory = async (values: any) =>
  agent.post(`${apiURLs.assetSubCategory}`, values);

export const updateAssetSubCategory = async (id: string, values: any) =>
  agent.patch(`${apiURLs.assetSubCategory}/${id}`, values);

export const getAssetSubCategories = async (id: string) =>
  agent.get(`${apiURLs.assetCategories}/${id}/asset-sub-categories`);

export const deleteAssetSubCategory = async (id: string) =>
  agent.delete(`${apiURLs.assetSubCategory}/${id}`);
