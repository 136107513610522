import AssetForm from 'components/NewForms/AssetForm/AssetForm';
import React from 'react';
import Content from 'ui-v2/components/Content';

export default function AddAsset() {
  return (
    <Content position="center">
      <AssetForm />
    </Content>
  );
}
