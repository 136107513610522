import React, { useCallback, useEffect, useState } from 'react';
import { StyledSidebarMenuItem } from 'layouts/BasicLayout/AppSidebar/SidebarMenu/StyledSidebarMenu';
import { Tooltip } from 'antd';
import { NotificationsIcon } from 'Icons/NotificationsIcon';
import { useTranslation } from 'react-i18next';
import { useNotificationsData } from 'ui-v2/hooks/useNotificationsData';
import { getUserNotificationsCount } from 'api/notificationService';
import NotificationsModal from './NotificationsModal';
import {
  Notifications,
  NotificationsSidebar,
  SidebarMenuText,
  StyledNotificationBadge,
} from './NotificationStyles';

interface IProps {
  collapsed: boolean;
}
export default function NotificationsComponent({ collapsed }: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    useState<number>();
  const { initNotifications, data, loaded } = useNotificationsData();

  useEffect(() => {
    initNotifications();
  }, []);

  const onToggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  async function getUreadNotifications() {
    try {
      await getUserNotificationsCount()
        .then((response) => {
          setUnreadNotificationsCount(response.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUreadNotifications();
  }, [data]);

  return (
    <NotificationsSidebar>
      <StyledSidebarMenuItem
        collapsed={collapsed}
        onClick={() => onToggleModal()}
        active={modalVisible}
      >
        {collapsed ? (
          <Tooltip placement="right" title={t('Notifications')}>
            <span>
              <NotificationsIcon />
            </span>
          </Tooltip>
        ) : (
          <NotificationsIcon />
        )}
        {!collapsed && (
          <Notifications>
            <SidebarMenuText>{t('Notifications')}</SidebarMenuText>
            {loaded && unreadNotificationsCount !== 0 && (
              <StyledNotificationBadge count={unreadNotificationsCount} />
            )}
          </Notifications>
        )}
      </StyledSidebarMenuItem>
      {modalVisible && (
        <NotificationsModal
          loaded={loaded}
          open={modalVisible}
          closeModal={() => setModalVisible(false)}
        />
      )}
    </NotificationsSidebar>
  );
}
