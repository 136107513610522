import agent from 'api/agent';
import { VendorCategory } from 'types/VendorCategory';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const getVendorCategories = async (queryParams?: QueryParamsType) =>
  agent.post(
    `${apiURLs.vendorCategories}/get`,
    searchFields(queryParams, apiURLs.vendorCategories)
  );

export const createVendorCategory = (payload: VendorCategory) =>
  agent.post(`${apiURLs.vendorCategories}`, payload);

export const deleteVendorCategory = (id: string) =>
  agent.delete(`${apiURLs.vendorCategories}/${id}`);

export const editVendorCategory = (
  payload: VendorCategory,
  vendorCategoryId: string
) => agent.patch(`${apiURLs.vendorCategories}/${vendorCategoryId}`, payload);

export const getVendorCategory = (id: string) =>
  agent.get(`${apiURLs.vendorCategories}/${id}`);
