import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SettingTab } from 'types/settings';
import TenantSettingsPageContent from './TenantSettingsPageContent';

const TenantSettingsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab =
    Object.values(SettingTab).find((tab) => tab === searchParams.get('tab')) ||
    SettingTab.CONFIGURATIONS;

  useEffect(() => {
    if (searchParams.get('settings-save') === 'true' && activeTab) {
      searchParams.set('settings-save', activeTab);
      setSearchParams(searchParams);
    }
  }, [searchParams, activeTab]);

  return <TenantSettingsPageContent />;
};

export default TenantSettingsPage;
