import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';

const ErrorFallbackUI = () => {
  const navigate = useNavigate();

  const onGoHome = () => {
    navigate('/dashboard');
  };

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" onClick={onGoHome}>
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorFallbackUI;
