import { AssetDTO, AssetUpdateTYpe } from 'types/Asset';
import agent from 'api/agent';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

export const createAsset = (asset: AssetDTO) =>
  agent.post(apiURLs.asset, asset).then((data) => data);

export const getAssetById = (assetId: string) =>
  agent.get(`${apiURLs.asset}/${assetId}`);

export const getAllAssets = (queryParams?: QueryParamsType) =>
  agent.post(`${apiURLs.asset}/get`, searchFields(queryParams, apiURLs.asset));

export const updateAssetById = (id: string, values: AssetUpdateTYpe) =>
  agent.patch(`${apiURLs.asset}/${id}`, values);

export const deleteAsset = (id: string) =>
  agent.delete(`${apiURLs.asset}/${id}`);

export const buldUploadAssets = (payload: { file: string }) =>
  agent.post(`${apiURLs.asset}/upload`, payload);
