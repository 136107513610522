import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ResendEmailSuccess() {
  const { t } = useTranslation();
  return (
    <Result
      title={t('E-mail was successfully sent!')}
      status="success"
      subTitle={t(
        'Please check your e-mail to retry setting up your password.'
      )}
    />
  );
}
