import agent from 'api/agent';
import { CreateBonus } from 'types/Bonus';
import { apiURLs } from './constants';

export const getAllBonuses = () => agent.post(`${apiURLs.bonus}/get`);

export const createBonus = (payload: CreateBonus) =>
  agent.post(`${apiURLs.bonus}`, payload);

export const updateBonus = (id: string, payload: CreateBonus) =>
  agent.put(`${apiURLs.bonus}/${id}`, payload);

export const deleteBonus = (bonusId: string) =>
  agent.delete(`${apiURLs.bonus}/${bonusId}`);

export const getEmployeeBonus = (employeeId: string) =>
  agent.get(`${apiURLs.bonus}${apiURLs.employee}/${employeeId}`);
