import React, { useState, useCallback, useRef, useEffect } from 'react';
import { InputRef, Tooltip } from 'antd';
import { useSidebarItems } from 'ui-v2/hooks/useSidebarItems';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import NotificationsComponent from 'ui-v2/components/Notifications';
import SearchIcon from 'Icons/SearchIcon';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { SidebarItem } from '../utils';
import {
  StyledSidebarSubMenuItem,
  StyledSiderbarMenu,
  StyledSiderbarMenuGroupItem,
  StyledSidebarMenuItem,
  StyledSidebarMenuText,
} from './StyledSidebarMenu';
import SiderbarSubMenu from './SidebarSubMenu';
import { StyledSiderDivider } from '../StyledAppSidebar';
import SidebarSearch from '../SidebarSearch';

export interface SidebarMenuProps {
  collapsed: boolean;
  setCollapsed: any;
}

const CustomItemComponent: React.FC<{
  wrappedItemName: string;
  filterValue?: string;
}> = ({ wrappedItemName, children, filterValue = '' }) => {
  if (
    wrappedItemName
      .toLocaleLowerCase()
      .startsWith(filterValue.toLocaleLowerCase()) ||
    !filterValue?.length
  ) {
    return <>{children}</>;
  }
  return null;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState('');
  const { items, groupedItems, isActivePath, onSearch } = useSidebarItems();
  const [isClicked, setIsClicked] = useState(false);

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const inputRef = useRef<InputRef>(null);

  const onItemClick = (item: SidebarItem) => {
    navigate(item.path);
  };

  const onItemSearch = (searchedValue: string) => {
    onSearch({ searchedValue, t });
    setFilterValue(t(searchedValue));
  };

  const onSearchClick = useCallback(() => {
    setCollapsed(!collapsed);
    setIsClicked(!isClicked);
  }, [collapsed]);

  useEffect(() => {
    if (isClicked) {
      inputRef.current?.focus();
    }
  }, [collapsed, inputRef]);

  const canViewNotifications = [
    AUTH_ROLES.AMDIN,
    AUTH_ROLES.HR,
    AUTH_ROLES.EMPLOYEE,
  ].includes(authUserRole as AUTH_ROLES);

  return (
    <StyledSiderbarMenu>
      {collapsed && (
        <StyledSidebarMenuItem collapsed={collapsed} onClick={onSearchClick}>
          <Tooltip placement="right" title={t('search')}>
            <span>
              <SearchIcon />
            </span>
          </Tooltip>
        </StyledSidebarMenuItem>
      )}
      {!collapsed && (
        <SidebarSearch onSearch={onItemSearch} elementRef={inputRef} />
      )}
      {canViewNotifications && (
        <CustomItemComponent
          filterValue={filterValue}
          wrappedItemName={t('Notifications')}
        >
          <NotificationsComponent collapsed={collapsed} />
        </CustomItemComponent>
      )}

      {items.map((item) => (
        <span key={item.name}>
          <StyledSidebarMenuItem
            collapsed={collapsed}
            onClick={() => onItemClick(item)}
            active={isActivePath({ items: [item] })}
          >
            {collapsed ? (
              <Tooltip placement="right" title={t('Dashboard')}>
                <span>{item.icon}</span>
              </Tooltip>
            ) : (
              item.icon
            )}
            {!collapsed && (
              <StyledSidebarMenuText>{t(item.name)}</StyledSidebarMenuText>
            )}
          </StyledSidebarMenuItem>
          <StyledSiderDivider />
        </span>
      ))}
      {groupedItems.map((groupedItem) => (
        <StyledSiderbarMenuGroupItem key={t(groupedItem.title)}>
          <SiderbarSubMenu
            active={isActivePath({ items: groupedItem.items, grouped: true })}
            title={t(groupedItem.title)}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          >
            {groupedItem.items.map((item) => (
              <StyledSidebarSubMenuItem
                key={item.name}
                collapsed={collapsed}
                onClick={() => onItemClick(item)}
                $active={isActivePath({ items: [item], grouped: true })}
              >
                {collapsed ? (
                  <Tooltip placement="right" title={t(item.name)}>
                    <span>{item.icon}</span>
                  </Tooltip>
                ) : (
                  item.icon
                )}
                {!collapsed && (
                  <StyledSidebarMenuText>{t(item.name)}</StyledSidebarMenuText>
                )}
              </StyledSidebarSubMenuItem>
            ))}
          </SiderbarSubMenu>
          <StyledSiderDivider />
        </StyledSiderbarMenuGroupItem>
      ))}
    </StyledSiderbarMenu>
  );
};

export default SidebarMenu;
