import * as React from 'react';
import { Col } from 'antd';
import { StyledContainer } from '../SetEmployeePasswordStyled';

type IProps = {
  children: React.ReactElement;
};

export default function Container({ children }: IProps) {
  return (
    <StyledContainer justify="center">
      <Col span={8} className="card">
        {children}
      </Col>
    </StyledContainer>
  );
}
