import OpenPositionForm from 'components/NewForms/OpenPositionForm/OpenPositionForm';
import React from 'react';
import Content from 'ui-v2/components/Content';

export default function AddOpenPosition() {
  return (
    <Content position="center">
      <OpenPositionForm />
    </Content>
  );
}
