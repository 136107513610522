import React from 'react';
import { Ideabox } from 'types/Ideabox';
import IdeaboxActions from 'components/Actions/IdeaboxActions';
import i18next from '../i18n';

export const IdeaboxColumns = [
  {
    title: i18next.t('visibility'),
    dataIndex: 'private',
    key: 'private',
    default: true,
    render: (_: string, item: Ideabox) => (
      <>{item?.private ? i18next.t('private') : i18next.t('public')}</>
    ),
    order: 0,
  },
  {
    title: i18next.t('ideaText'),
    dataIndex: 'ideaText',
    key: 'ideaText',
    default: true,
    order: 1,
  },
  {
    title: i18next.t('creator'),
    dataIndex: 'employee',
    key: 'employee',
    default: true,
    render: (_: string, item: Ideabox) => (
      <>{`${item?.employee?.firstName} ${item?.employee?.lastName}`}</>
    ),
    order: 2,
  },
  {
    title: i18next.t('tags'),
    dataIndex: 'tags',
    key: 'tags',
    default: true,
    render: (_: string, item: Ideabox) => (
      <>
        {item?.tags?.length > 0
          ? item.tags
              .map((emp) => `${emp?.firstName} ${emp?.lastName}`)
              .join(', ')
          : i18next.t('noTags')}
      </>
    ),
    order: 2,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (_: string, row: Ideabox) => (
      <IdeaboxActions id={row?.id} data={row} />
    ),
    default: false,
    order: 3,
    fixed: 'right',
  },
];
