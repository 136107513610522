import { toast } from 'react-toastify';

import { getInterviewTypeOrder } from 'api/interviewTypeService';
import { InterviewType } from 'types/Interview';

export async function fetchInterviewTypeOrder(candidateOpenPositionId: string) {
  let data: Array<InterviewType> = [];

  try {
    const response = await getInterviewTypeOrder({
      candidateOpenPositionId,
    });
    data = response.data;
  } catch (e) {
    toast.error('Failed to get Interview Types!');
  }

  return data;
}

export function transformInterviewTypeOrderToOptionType(
  data: Array<InterviewType>
) {
  return data.map((item: InterviewType) => ({
    id: item.id,
    value: item.id,
    label: item.name || item.id,
  }));
}
