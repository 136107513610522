/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CreateTenantAccountType,
  TenantAccountSettingsDTO,
  TenantAccountTwoFactorType,
  TenantApproveInputType,
  TenantDbType,
  TenantKey,
  TenantUserDTO,
} from 'types/Tenant';
import {
  TenantsAccountsQueryParamsType,
  TenantsQueryParamsType,
} from 'types/QueryParams';
import { CompanyUpdateDTO } from 'types/Company';
import { apiURLs } from './constants';
import agent from './agent';

export const updateTenantAccountSettings = (
  payload: TenantAccountSettingsDTO
) => agent.post(`${apiURLs.tenants}/account/settings`, payload);

export const toogleTenantAccountTwoFactor = (
  payload: TenantAccountTwoFactorType
) => agent.post(`${apiURLs.tenants}/twoFactor`, payload);

export const getTenants = (queryParams?: TenantsQueryParamsType) =>
  agent.post(`${apiURLs.tenants}/get`, queryParams);

export const getTenantDetails = (tenantId: string) =>
  agent.get(`${apiURLs.tenants}/${tenantId}`);

export const updateTenantDetails = (
  tenantId: string,
  updateDTO: CompanyUpdateDTO
) => agent.patch(`${apiURLs.tenants}/${tenantId}`, updateDTO);

export const approveTenantById = (payload: TenantApproveInputType) =>
  agent.post(`${apiURLs.tenants}/approve`, payload);

export const rejectTenantById = (tenantId: any) =>
  agent.delete(`${apiURLs.tenants}/${tenantId}/reject`, tenantId);

export const getTenantUsers = (
  tenantId: string,
  queryParams?: TenantsAccountsQueryParamsType
) => agent.post(`${apiURLs.tenants}/users/${tenantId}`, queryParams);
export const getTenantUser = (payload: TenantUserDTO) =>
  agent.post(`${apiURLs.tenants}/user`, payload);

export const registerTenantAccount = (
  tenantId: string,
  payload: CreateTenantAccountType
) => agent.post(`${apiURLs.publicSuperAdmin}/createUser/${tenantId}`, payload);

export const getAllTenantRoles = (tenantId: string) =>
  agent.get(`${apiURLs.roles}/tenant/${tenantId}`);

export const testTenantDbConnection = (payload: TenantDbType) =>
  agent.post(`${apiURLs.tenants}/test-connection`, payload);

export const getTenantApiKey = (payload: TenantKey) =>
  agent.post(`${apiURLs.tenants}/api-key`, payload);
