/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Steps, Image, Tooltip } from 'antd';
import { clearAuthState } from 'redux/authUser/actions';
import { LogOutIcon } from 'Icons/LogOutIcon';
import { IconButton } from 'components/Actions/AssetAction';
import { useDispatch } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TenantConfigUpdateDTO } from 'types/TenantConfig';
import { toastErrorMessages } from 'utils/utilFunctions';
import { createUser } from 'api/usersService';
import { CreateUserDto } from 'redux/users/types';
import { clearSingleTenant, fetchSingleTenant } from 'redux/tenants/actions';
import { TenantDataType } from 'types/Tenant';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import useTenantStore from 'redux/persisted-state/tenant-store';
import { completeTenantOnboardingConfig } from 'api/tenantConfigService';
import TenantOnboardingWelcome from './TenantOnboardingWelcome';
import {
  StyledOnboardingSteps,
  StyledOnboardingStepsArrow,
  StyledOnboardingStepsContent,
  StyledOnboardingStepsHeader,
  StyledOnboardingStepsTenantName,
} from './StyledTenantOnboardingSteps';
import TenantOnboardingConfiguration from './TenantOnboardingConfiguration';
import TenantOnboardingSetup from './TenantOnboardingSetup';
import i18next from '../../../i18n';

const { Step } = Steps;

export const TenantOnboardingLabels = [
  'Welcome on board',
  'Company Configuration',
  'Human Resources',
];

export interface TenantOnboardinStepsLayoutProps {
  currentStep: number;
  onPreviousStep: () => void;
  onNextStep: () => void;
  loading: boolean;
  children?: React.ReactNode;
  tenant: TenantDataType | null;
}

export const TenantOnboardinStepsLayout: React.FC<
  TenantOnboardinStepsLayoutProps
> = ({ currentStep, loading, onNextStep, onPreviousStep, children }) => (
  <Row justify="center" gutter={[0, 30]}>
    <StyledOnboardingStepsContent xs={22} sm={22} md={16}>
      {children || null}
    </StyledOnboardingStepsContent>
    <Col xs={22} sm={22} md={16}>
      <Row justify={currentStep !== 0 ? 'space-between' : 'end'} align="middle">
        {currentStep !== 0 && (
          <Col>
            <StyledOnboardingStepsArrow
              onClick={onPreviousStep}
              icon={
                <Row align="middle" gutter={[5, 0]}>
                  <Col>
                    <LeftOutlined />
                  </Col>
                  <Col>{i18next.t('previous')}</Col>
                </Row>
              }
            />
          </Col>
        )}
        <Col>
          <StyledOnboardingStepsArrow
            onClick={onNextStep}
            loading={
              loading && currentStep === TenantOnboardingLabels.length - 1
            }
            type={
              currentStep === TenantOnboardingLabels.length - 1
                ? 'primary'
                : 'default'
            }
            icon={
              <>
                {currentStep !== TenantOnboardingLabels.length - 1 && (
                  <Row align="middle" justify="center" gutter={[10, 0]}>
                    <Col>{i18next.t('next')}</Col>
                    <Col>
                      <RightOutlined />
                    </Col>
                  </Row>
                )}
              </>
            }
          >
            {currentStep === TenantOnboardingLabels.length - 1 &&
              i18next.t('finish')}
          </StyledOnboardingStepsArrow>
        </Col>
      </Row>
    </Col>
  </Row>
);

const TenantOnboardingSteps: React.FC = () => {
  const [tenantConfigPayload, setTenantConfigPayload] =
    useState<TenantConfigUpdateDTO>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const { tenantPersisted: tenant } = useTenantsData();
  const { clearTenant } = useTenantStore();

  const onLogout = () => {
    dispatch(clearAuthState());
    clearTenant();
  };

  useEffect(() => {
    dispatch(clearSingleTenant());
  }, []);

  const completeOnboarding = async (createUserPayload?: CreateUserDto) => {
    setLoading(true);
    try {
      if (createUserPayload) {
        await createUser(createUserPayload);
      }
      if (tenant?.id) {
        await completeTenantOnboardingConfig({
          tenantId: tenant.id,
        });
        dispatch(fetchSingleTenant(tenant?.id));
      }
    } catch (e) {
      toastErrorMessages(e);
    } finally {
      setLoading(false);
    }
  };

  const onNextStep = useCallback(async () => {
    if (currentStep === TenantOnboardingLabels.length - 1) {
      completeOnboarding();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, tenantConfigPayload]);

  const onPreviousStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const props = {
    tenant,
    loading,
    currentStep,
    onNextStep,
    onPreviousStep,
  };

  return (
    <StyledOnboardingSteps justify="center" gutter={[0, 30]}>
      {tenant?.id && (
        <Col span={24}>
          <StyledOnboardingStepsHeader justify="space-between" align="middle">
            <Col>
              <Row gutter={[20, 0]}>
                <Col>
                  <Image
                    src={tenant.logoContent}
                    width={40}
                    height={32}
                    alt={tenant.logoContent}
                  />
                </Col>
                <StyledOnboardingStepsTenantName>
                  {tenant.name}
                </StyledOnboardingStepsTenantName>
              </Row>
            </Col>
            <Col>
              <Tooltip title={i18next.t('logout')}>
                <IconButton onClick={onLogout}>
                  <LogOutIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </StyledOnboardingStepsHeader>
        </Col>
      )}
      <Col xs={22} sm={22} md={16}>
        <Steps current={currentStep}>
          {TenantOnboardingLabels.map((label) => (
            <Step key={label} title={i18next.t(label)} />
          ))}
        </Steps>
      </Col>
      <Col span={24}>
        {TenantOnboardingLabels[currentStep] === TenantOnboardingLabels[0] && (
          <TenantOnboardingWelcome {...props} />
        )}
        {TenantOnboardingLabels[currentStep] === TenantOnboardingLabels[1] && (
          <TenantOnboardingConfiguration
            {...props}
            onSubmit={setTenantConfigPayload}
          />
        )}
        {TenantOnboardingLabels[currentStep] === TenantOnboardingLabels[2] && (
          <TenantOnboardingSetup {...props} onSubmit={completeOnboarding} />
        )}
      </Col>
    </StyledOnboardingSteps>
  );
};

export default TenantOnboardingSteps;
