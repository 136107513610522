import { Form, Popconfirm } from 'antd';
import { updateCandidateOpenPositionStage } from 'api/candidateService';
import RateComponent from 'components/NewForms/RateComponent';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';

interface IProps {
  id: string | undefined;
  score: number;
  disabled: boolean;
  onScored: () => void;
}

export default function CandidateScore({
  id,
  score,
  disabled,
  onScored,
}: IProps) {
  const { selectedCandidateOpenPosition } = useCandidateData();
  const [loading, setLoading] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [newScore, setNewScore] = useState<number>(score);
  const { t } = useTranslation();

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);

    if (id) {
      await updateCandidateOpenPositionStage({
        candidateOpenPosId: selectedCandidateOpenPosition?.data?.id || '',
        score: newScore,
      })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            toast.success(t('Successfully rated this open position'));
            onScored();
            setNewScore(newScore);
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('Failed to rate this open position'));
        });
    }
    setPopconfirmVisible(false);
  };
  return (
    <Popconfirm
      placement="top"
      title={t('Are you sure you want to give this rating?')}
      onConfirm={() => onSubmit()}
      onCancel={() => setPopconfirmVisible(!popconfirmVisible)}
      okText={t('yes')}
      cancelText={t('no')}
      visible={popconfirmVisible}
    >
      <Form>
        <RateComponent
          key={id}
          placeholder=""
          styles={{
            padding: '0',
            fontSize: '50px',
          }}
          disabled={disabled}
          name="score"
          defaultValue={score || 0}
          onChange={(value) => {
            setNewScore(value);
            setPopconfirmVisible(true);
          }}
        />
      </Form>
    </Popconfirm>
  );
}
