import { getOptionType } from 'api/selectOptions';
import { useEffect, useState } from 'react';
import { OptionType } from 'types/OptionTypes';

interface Params {
  type: string;
  transform?: (item: any) => OptionType;
}

const responseMap = {
  workPositions: 'work-position',
  vendorCategories: 'vendor-category',
  employees: 'employees',
  candidates: 'candidates',
  certifications: 'certifications',
  departments: 'department',
  assets: 'assets',
  trainings: 'trainings',
  projects: 'project',
  stages: 'stages',
  roles: 'roles',
  rolesAssignments: 'roles-assignment',
};

type MapKey = keyof typeof responseMap;

const useGetSelectOptions = ({ type, transform }: Params) => {
  const [optionsType, setoptionsType] = useState<OptionType[]>([]);
  const [error, setError] = useState<any>('');
  const [loading, setLoading] = useState(true);

  const formatResponse = (data: any) => {
    let response = data.data;
    if (
      type === 'currency' ||
      type === 'interviews/interviewType/order' ||
      type === 'roles' ||
      type === responseMap.rolesAssignments
    ) {
      response = data;
    }
    const result = Array.isArray(response)
      ? response
      : data[responseMap[type as MapKey]];

    return result;
  };

  const fetchData = async () => {
    getOptionType(type)
      .then(({ data }) => {
        const result = formatResponse(data);
        if (result) {
          setLoading(false);
          const transformedData: OptionType[] = result.map((item: any) => {
            if (transform) {
              return transform(item);
            }
            return {
              id: item.id,
              value: item.id,
              label: item.name,
            };
          });
          setoptionsType(transformedData);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [type]);

  return { optionsType, error, loading };
};

export default useGetSelectOptions;
