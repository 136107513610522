import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover, Row } from 'antd';
import React from 'react';
import { EmployeeType } from 'types/Employee';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { IconButton } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { StatusTag } from 'ui-v2/components/Employees/Onboarding/EmployeeOnboardingStyles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'components/NewForms/FormStyled';
import * as Styled from './EmployeeOnboardingTaskItemStyles';

interface IProps {
  name: string;
  assignedDate: Date;
  dueDate: Date;
  status: string;
  type: string;
  employee: EmployeeType;
  onEdit: () => void;
  onView: () => void;
  onDelete: () => void;
  visibility: boolean;
}

export function EmployeeOnboardingTaskItem({
  name,
  assignedDate,
  dueDate,
  status,
  type,
  employee,
  onEdit,
  onView,
  onDelete,
  visibility,
}: IProps) {
  const { t } = useTranslation();
  const mentorFirstName = employee?.firstName
    ? `${employee.firstName.charAt(0).toUpperCase()}${employee.firstName.slice(
        1
      )}`
    : t('noMentor');
  const mentorLastName = employee?.lastName
    ? `${employee.lastName.charAt(0).toUpperCase()}${employee.lastName.slice(
        1
      )}`
    : '';
  const navigate = useNavigate();
  return (
    <Styled.RowItemAll>
      <Col span={4}>
        <Styled.NodataLabel ellipsis style={{ width: '80%' }}>
          <Popover
            content={name}
            placement="topLeft"
            style={{ width: '30%', height: '30%', maxWidth: '200px' }}
          >
            {name}
          </Popover>
        </Styled.NodataLabel>
      </Col>
      <Col span={3}>
        <Styled.DataStyle>
          {convertUTCtoFormatedLocalTime(assignedDate)}
        </Styled.DataStyle>
      </Col>
      <Col span={4}>
        <Styled.NodataLabel>{type}</Styled.NodataLabel>
      </Col>
      <Col span={3}>
        <Styled.DataStyle>
          {convertUTCtoFormatedLocalTime(dueDate)}
        </Styled.DataStyle>
      </Col>
      <Col span={3}>
        <StatusTag status={status}>
          <p>{status}</p>
        </StatusTag>
      </Col>
      <Col span={4}>
        <Styled.NodataLabel>
          <StyledButton
            onClick={() => {
              navigate(`/employees/employee-profile?id=${employee.id}`);
              window.location.reload();
            }}
            type="link"
          >
            {`${mentorFirstName} ${mentorLastName}`}
          </StyledButton>
        </Styled.NodataLabel>
      </Col>
      <Col span={3}>
        {visibility ? (
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <Row justify="space-around">
              <IconButton onClick={onEdit}>
                <EditOutlined />
              </IconButton>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={onDelete}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <IconButton>
                  <DeleteOutlined />
                </IconButton>
              </Popconfirm>
            </Row>
          </RoleBasedButton>
        ) : (
          <Row justify="end">
            <IconButton onClick={onView}>
              <EditOutlined />
            </IconButton>
          </Row>
        )}
      </Col>
    </Styled.RowItemAll>
  );
}
