import { Col, Form, Row } from 'antd';
import { createAssignment } from 'api/assignmentService';
import { updateEmployee } from 'api/employeeService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { getAllProjects } from 'api/projectService';
import { prefixSelector } from 'components/NewForms/EmployeeForm/components/utils';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { AssetsIcon } from 'Icons/AssetsIcon';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeeAssignments } from 'redux/employees/actions';
import { fetchProjects } from 'redux/projects/actions';
import { AssignmentCreate } from 'types/Assigment';
import { InputTypes } from 'types/FormTypes';
import { ProjectType } from 'types/Project';
import GenericModal from 'ui-v2/components/GenericModal';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { convertDateToUTC, getUserDateFormat } from 'utils/utilFunctions';

interface IProps {
  currentProjects: ProjectType[];
  open: boolean;
  closeModal: () => void;
}

export default function AssignProjectModal({
  open,
  closeModal,
  currentProjects,
}: IProps) {
  const [form] = Form.useForm();
  const { employeeAssignments } = useEmployeesData();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  // Returns all the project that employee is not assigned to.
  async function fetchProjectList(
    string: string,
    page: number
  ): Promise<SelectLoadPayload> {
    return getAllProjects({ string, page }).then((response) => ({
      data: response.data?.data?.map((item: ProjectType) => ({
        label: item?.name,
        value: item?.id,
      })),
      pageCount: response?.data?.meta?.pageCount || 1,
    }));
  }

  const { optionsType: rolesAssignmentOptions } = useGetSelectOptions({
    type: 'roles-assignment',
    transform: (item) => ({
      id: item.id,
      value: item.id,
      label: item.name,
    }),
  });

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const AddWorkExperienceFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'projectId',
          label: t('project'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchProjectList,
          rules: [
            {
              required: true,
              message: t('Project is required!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'rolesAssignment',
          label: t('role'),
          type: InputTypes.SELECT,
          selectOptions: rolesAssignmentOptions,
          rules: [
            {
              required: true,
              message: t('Project is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
          },
          rules: [
            {
              required: true,
              message: t('Start Date is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('endDate'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
          rules: [
            {
              required: true,
              message: t('End Date is required'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'employeeRate',
          label: t('employeeRate'),
          type: 'selectPrefix',
          inputProps: {
            type: 'number',
            rows: 1,
            min: 1,
          },
          prefix: {
            name: 'currecyEmployeeId',
            selectOptions: currencyOptions,
            defaultValue: currencyOptions[1]?.id,
            placeholder: '',
          },
          rules: [
            {
              required: true,
              message: t('employeeRateIsRequired!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'clientRate',
          label: t('clientRate'),
          type: 'selectPrefix',
          inputProps: {
            type: 'number',
            rows: 1,
            min: 1,
          },
          prefix: {
            name: 'currecyClientId',
            selectOptions: currencyOptions,
            defaultValue: currencyOptions[1]?.id,
            placeholder: '',
          },
          rules: [
            {
              required: true,
              message: t('clientRateisRequired!'),
            },
          ],
        },
      ],
    ],
    [rolesAssignmentOptions, prefixSelector, currencyOptions]
  );

  const addAssignment = async (valuesToSend: AssignmentCreate) => {
    await createAssignment(valuesToSend)
      .then((response) => {
        if (response.status === 200 && response.data) {
          toast.success(t('Successfully assigned to project'));
        }
      })
      .catch(() => toast.error(t('Failed to assign employee to project')));
  };

  const onSubmit = async (values: any) => {
    if (loading || !employeeAssignments?.id) return;
    if (!Number(values.clientRate)) {
      delete values.clientRate;
    }
    const assignmentValues: AssignmentCreate = {
      ...values,
      startDate: convertDateToUTC(values.startDate),
      endDate: convertDateToUTC(values.endDate),
      employeeId: employeeAssignments.id,
      projectId: form.getFieldValue('projectId').value,
      employeeRate: Number(values.employeeRate),
      clientRate: Number(values.clientRate),
    };
    const employeeUpdateValues = {
      salary: Number(assignmentValues.employeeRate),
      currencyId: assignmentValues.currecyEmployeeId,
      gender: employeeAssignments.gender,
      projects: [...currentProjects, assignmentValues.projectId],
    };

    setLoading(true);
    await updateEmployee(employeeAssignments.id, employeeUpdateValues)
      .then((response) => {
        if (response.status === 200 && response.data) {
          addAssignment(assignmentValues);
        }
      })
      .catch(() => {
        toast.error(t('Failed to assign employee to project'));
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEmployeeAssignments(employeeAssignments.id));
        dispatch(fetchProjects({ page: 1 }));
        dispatch(closeModal());
      });
  };

  return (
    <GenericModal
      title={t('assignProject')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
