import React, { useEffect, useState } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useInvoicingData } from 'ui-v2/hooks/useInvoicingData';
import {
  clearInvoicingState,
  fetchSingleInvoice,
  fetchInvoices,
} from 'redux/invoicing/action';
import InvoicingModalForm from 'components/NewForms/InvoicingForm';
import { deleteInvoice } from 'api/invoicing';

export default function InvoicingList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { columns, take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, loading, pagination, invoicing } = useInvoicingData();

  const [searchTerm, setSearchTerm] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const toogleModal = () => setModalVisible((modalVisible) => !modalVisible);

  const onModalClose = () => {
    toogleModal();
    searchParams.delete('edit-invoice');
    setSearchParams(searchParams);
    dispatch(clearInvoicingState());
  };

  async function deleteInvoiceById(id: string | null) {
    if (!id) return;
    try {
      await deleteInvoice(id);
      toast.success(t('successfullyDeletedInvoice'));
      let currentPage = pagination?.page ?? 1;
      if (list.length === 1) {
        currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      }
      dispatch(fetchInvoices({ pageOptionsDto: { page: currentPage, take } }));
    } catch (error) {
      toastErrorMessages(t('failedDeleteInvoice'));
    }
  }

  useEffect(() => {
    if (!loading) {
      dispatch(fetchInvoices({ pageOptionsDto: { page: 1, take: 10 } }));
    }
  }, [take]);

  useEffect(() => {
    if (searchParams.get('add-invoice') === 'true') {
      searchParams.delete('add-invoice');
      setSearchParams(searchParams);
      toogleModal();
    }
    if (searchParams.get('edit-invoice')) {
      dispatch(fetchSingleInvoice(searchParams.get('edit-invoice') ?? ''));
      toogleModal();
    }

    if (searchParams.get('delete-invoice')) {
      deleteInvoiceById(searchParams.get('delete-invoice'));
      searchParams.delete('delete-invoice');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    dispatch(
      fetchInvoices({
        pageOptionsDto: {
          page,
          take,
        },
        searchOptions: { invoiceNumber: searchTerm },
      })
    );
  };

  const onSearch = (searchTerm: string) => {
    dispatch(
      fetchInvoices({
        pageOptionsDto: {
          page: 1,
        },
        searchOptions: { invoiceNumber: searchTerm },
      })
    );
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteInvoiceById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
          paginationName="Invoicing"
          onSelectedDelete={onDelete}
        />
        {modalVisible && (
          <InvoicingModalForm
            open={modalVisible}
            closeModal={onModalClose}
            selectedInvoice={invoicing}
          />
        )}
      </>
    </Content>
  );
}
