import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useVendorQuestionnaireData } from 'ui-v2/hooks/useVendorQuestionnaireData';
import Content from 'ui-v2/components/Content';
import VendorQuestionnaireForm from 'components/NewForms/VendorQuestionnaireForm/VendorQuestionnaireForm';
import VendorQuestionnaireQuestionList from './components/VendorQuestionnaireQuestionList';
import SelectedVendorQuestionnaireQuestionInfo from './components/SelectedVendorQuestionnaireQuestionInfo';

const VendorQuestionnaireDetailsContent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    questionnaireCategory: { selectedQuestionnaireCategory },
  } = useVendorQuestionnaireData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>();

  const toogleModalVisibility = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible, searchParams]);

  useEffect(() => {
    if (searchParams.get('edit-vendor-questionnaire')) {
      toogleModalVisibility();
      searchParams.delete('edit-vendor-questionnaire');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const questionnaireQuestion =
    selectedQuestionnaireCategory?.vendorQuestionnaire?.find(
      (question) => question.id === selectedQuestionId
    );
  return (
    <>
      <Content position="grid">
        <>
          <VendorQuestionnaireQuestionList onSelect={setSelectedQuestionId} />
          <SelectedVendorQuestionnaireQuestionInfo
            questionnaireQuestion={questionnaireQuestion}
          />
        </>
      </Content>
      <>
        {modalVisible && (
          <VendorQuestionnaireForm
            id={selectedQuestionnaireCategory?.id}
            open={modalVisible}
            closeModal={toogleModalVisibility}
          />
        )}
      </>
    </>
  );
};

export default VendorQuestionnaireDetailsContent;
