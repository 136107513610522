import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  clearSingleAssetLogs,
  fetchAssetLogsById,
} from 'redux/assetLogs/actions';
import { useSearchParams } from 'react-router-dom';
import { QueryParamsDTO } from 'types/QueryParams';

export function useAssetLogsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.assetLogsReducer
  );
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const id = searchParams.get('id') as string;

  const params: QueryParamsDTO = {
    pageOptions: {
      page: 1,
      take: 10,
    },
    filterOptions: {
      filterFields: [''],
      filterText: '',
    },
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchAssetLogsById(id, params));
    }

    return () => {
      dispatch(clearSingleAssetLogs());
    };
  }, [id]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
  };
}
