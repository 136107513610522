import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import React, { useMemo } from 'react';
import { CompanyType } from 'types/Company';
import { FormConfigurationType } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';
import { CompanyDetails } from './CompanyDetails';

export function SetPasswordForm({
  company,
  loading,
  onFinish,
}: {
  company: CompanyType | undefined;
  loading: boolean;
  onFinish: (values: any) => void;
}) {
  const [form] = useForm();
  const { t } = useTranslation();
  const SetPasswordFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'password',
          label: t('password'),
          type: 'input',
          inputProps: { type: 'password' },
          rules: [
            {
              required: true,
              message: t('Password is required!'),
            },
          ],
        },

        {
          col: 24,
          offset: 0,
          name: 'confirmPassword',
          label: t('confirmPassword'),
          type: 'input',
          inputProps: { type: 'password' },
          rules: [
            {
              required: true,
              message: t('Please confirm your password'),
            },
          ],
        },
      ],
    ],
    []
  );

  return (
    <>
      <CompanyDetails
        name={company?.name as string}
        logo={company?.logoContent as string}
        loading={loading}
      />
      <GenericForm
        formConfiguration={SetPasswordFormConfiguration}
        onFinish={() => onFinish(form.getFieldsValue())}
        form={form}
        loading={company?.logoContent ? loading : false}
      >
        <FormItemStyled style={{ marginTop: 30 }}>
          <Row>
            <Col span={11}>
              <StyledButton htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('save')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </>
  );
}
