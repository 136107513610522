import { Reducer } from 'react';
import { Action } from 'redux';

import { Assets, AssetsState, AssetState } from './types';

const initialState: AssetsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<AssetsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Assets.FETCH_ASSETS_START:
      state = { ...state, loading: true };
      break;
    case Assets.FETCH_ASSETS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Assets.FETCH_ASSETS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const initialSingleState: AssetState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const singleAssetReducer: Reducer<AssetState, Action> = (
  state = initialSingleState,
  action: any
) => {
  switch (action.type) {
    case Assets.FETCH_ASSET_START:
      state = { ...state, loading: true };
      break;
    case Assets.FETCH_ASSET_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Assets.FETCH_ASSET_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Assets.CLEAR_SINGLE_ASSET:
      state = { ...initialSingleState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as AssetsReducer };
export { singleAssetReducer as AssetReducer };
