import { Col, Result, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormConfigurationType } from 'types/FormTypes';

export function SendVaidationEmailForm({
  resendDisabled,
  countDown,
  onFinish,
}: {
  resendDisabled: boolean;
  countDown: number;
  onFinish: (values: any) => void;
}) {
  const [form] = useForm();
  const { t } = useTranslation();
  const ResendEmailFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: 'input',
          inputProps: { type: 'email' },
          rules: [
            {
              required: true,
              message: t('requiredEmail'),
            },
          ],
        },
      ],
    ],
    []
  );

  return (
    <>
      <Result
        title={t('somethingWentWrong')}
        status="403"
        subTitle={t('setUpPassword')}
      />
      <GenericForm
        formConfiguration={ResendEmailFormConfiguration}
        onFinish={() => onFinish(form.getFieldsValue())}
        form={form}
      >
        <FormItemStyled style={{ marginTop: 30 }}>
          <Row>
            <Col span={11}>
              <StyledButton htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                type="primary"
                htmlType="submit"
                disabled={resendDisabled}
              >
                {resendDisabled
                  ? `${t('Send again in')}  ${
                      countDown > 0 ? `(${countDown})` : ''
                    }`
                  : t('send')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </>
  );
}
