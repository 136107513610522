import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  clearCandidateOffers,
  clearScheduleInterview,
  clearSingleCandidate,
  fetchCandidate,
} from 'redux/candidates/actions';
import {
  CandidateOffersState,
  CandidateOpenPositionStage,
  CandidateSelectedOpenPosition,
  SingleCandidateState,
} from 'redux/candidates/types';
import { RootState } from 'redux/store';
import { clearFetchTasks } from 'redux/tasks/actions';
import { CandidateOpenPositionObj, CandidateType } from 'types/Candidates';
import useHeader from './useHeader';

export function useCandidateData() {
  const [openPosId, setOpenPosId] = useState<string>();
  const [openPosStage, setOpenPosStage] = useState<string>();
  const [candidatePositionsOptions, setCandidatePositionsOptions] =
    useState<any>([]);
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.candidates
  );

  const selectedCandidateOpenPosition: CandidateSelectedOpenPosition =
    useSelector((state: RootState) => state.selectedCandidateOpenPosition);

  const candidateOffers: CandidateOffersState = useSelector(
    (state: RootState) => state.candidateOffers
  );

  const candidateOpenPositionStage: CandidateOpenPositionStage = useSelector(
    (state: RootState) => state.candidateOpenPositionStage
  );

  const singleCandidate: SingleCandidateState = useSelector(
    (state: RootState) => state.singleCandidate
  );

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { entity } = useHeader();
  const candidateId = searchParams.get('id');

  useEffect(() => {
    if (
      pathname === '/candidates' ||
      (candidateId && candidateId !== singleCandidate?.data?.id)
    ) {
      dispatch(clearScheduleInterview());
      dispatch(clearFetchTasks());
      dispatch(clearCandidateOffers());
      dispatch(clearSingleCandidate());
    }
  }, [pathname]);

  if (
    entity === 'candidates' &&
    candidateId &&
    !singleCandidate.loaded &&
    !singleCandidate.loading
  ) {
    dispatch(fetchCandidate(candidateId));
  }

  useEffect(() => {
    if (singleCandidate && singleCandidate.data) {
      setCandidatePositionsOptions(
        singleCandidate?.data?.candidateOpenPositions?.map((item) => ({
          id: item.id,
          label: item.openPositions?.name,
          value: item.stages?.name,
        }))
      );
    }
  }, [singleCandidate]);

  useEffect(() => {
    if (singleCandidate && openPosId) {
      const candidateOpenPositions =
        singleCandidate?.data?.candidateOpenPositions;
      const selectedOpenPos = candidateOpenPositions?.find(
        (item: CandidateOpenPositionObj) => item.id === openPosId
      );

      const stageName = selectedOpenPos?.stages.name;
      setOpenPosStage(stageName);
    }
  }, [openPosId]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    candidate:
      singleCandidate?.data ||
      data?.data?.find((i: CandidateType) => i.id === searchParams.get('id')),
    candidateLoaded: singleCandidate?.loaded,
    openPositionStage: {
      list: candidateOpenPositionStage?.data || [],
      loading: candidateOpenPositionStage.loading,
      loaded: candidateOpenPositionStage.loaded,
    },
    loading: loading || singleCandidate.loading,
    loaded,
    errored,
    error,
    openPosId,
    setOpenPosId,
    openPosStage,
    setOpenPosStage,
    selectedCandidateOpenPosition,
    candidatePos: candidatePositionsOptions,
    candidateOffer: {
      data: candidateOffers?.data,
      loading: candidateOffers?.loading,
      loaded: candidateOffers?.loaded,
    },
  };
}
