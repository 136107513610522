import React from 'react';

export const UploadMOdalIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1E0FF" />
    <path
      d="M30 18.2695V22.4C30 22.9601 30 23.2401 30.109 23.454C30.2049 23.6422 30.3578 23.7951 30.546 23.891C30.7599 24 31.0399 24 31.6 24H35.7305M36 25.9882V33.2C36 34.8802 36 35.7202 35.673 36.362C35.3854 36.9265 34.9265 37.3854 34.362 37.673C33.7202 38 32.8802 38 31.2 38H24.8C23.1198 38 22.2798 38 21.638 37.673C21.0735 37.3854 20.6146 36.9265 20.327 36.362C20 35.7202 20 34.8802 20 33.2V22.8C20 21.1198 20 20.2798 20.327 19.638C20.6146 19.0735 21.0735 18.6146 21.638 18.327C22.2798 18 23.1198 18 24.8 18H28.0118C28.7455 18 29.1124 18 29.4577 18.0829C29.7638 18.1564 30.0564 18.2776 30.3249 18.4421C30.6276 18.6276 30.887 18.887 31.4059 19.4059L34.5941 22.5941C35.113 23.113 35.3724 23.3724 35.5579 23.6751C35.7224 23.9436 35.8436 24.2362 35.9171 24.5423C36 24.8876 36 25.2545 36 25.9882Z"
      stroke="#155EEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#EFF4FF"
      strokeWidth="8"
    />
  </svg>
);
