import agent from 'api/agent';

import { QueryParamsType } from 'types/QueryParams';
import { CreateIpAccessDTO } from 'types/IpAccess';
import { apiURLs } from './constants';

export const getAllIpWhiteListing = async (queryParams?: QueryParamsType) =>
  agent.get(
    `${apiURLs.ipWhiteListing}?sortType=ASC&${
      queryParams && new URLSearchParams(queryParams as string).toString()
    }&sortBy=ASC`
  );

export const createIpWhiteListing = (payload: CreateIpAccessDTO) =>
  agent.post(apiURLs.ipWhiteListing, payload);

export const deleteIpWhitelisting = async (id: string) =>
  agent.delete(`${apiURLs.ipWhiteListing}/${id}`);
