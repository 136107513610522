import { useSelector } from 'react-redux';

export function useEmployeeReviewData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.employeeReviews
  );

  return {
    list: data ?? [],
    loading,
    loaded,
    error,
    errored,
  };
}
