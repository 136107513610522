/* eslint-disable react/no-children-prop */
import { Col, Popover, Row } from 'antd';
import React from 'react';
import { Item } from 'ui-v2/components/PersonalInfo';
import GenericModal from 'ui-v2/components/GenericModal';
import {
  ItemLabel,
  ItemValue,
} from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { TenantLogo } from 'ui-v2/components/Tenant/TenantInfo/TenantInfo';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { TenantAccountType } from 'types/Tenant';
import { isNil } from 'lodash';

interface TenantAccountDetailModalProps {
  onClose: () => void;
  tenantAccount?: TenantAccountType;
}

const TenantAccountDetailModal: React.FC<TenantAccountDetailModalProps> = ({
  tenantAccount,
  onClose,
}) => {
  if (isNil(tenantAccount)) {
    return null;
  }

  return (
    <GenericModal
      title=""
      open
      closeModal={onClose}
      icon={
        <Row align="middle" gutter={[10, 10]}>
          <Col>
            <TenantLogo logoContent={tenantAccount?.avatar} />
          </Col>
          <Col>
            <Row>
              <Col>{`${tenantAccount?.firstName} ${tenantAccount?.lastName}`}</Col>
              <Col span={24}>
                <ItemLabel>Account Info</ItemLabel>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Row justify="center" gutter={[0, 20]}>
            <Col span={11}>
              <Item
                label="First Name"
                value={tenantAccount?.firstName ?? ''}
                children={undefined}
              />
            </Col>

            <Col span={11} offset={1}>
              <Item
                label="Last Name"
                value={tenantAccount?.lastName ?? ''}
                children={undefined}
              />
            </Col>
            <Col span={11}>
              <Item
                label="Email"
                children={
                  <ItemValue ellipsis>
                    <Popover
                      content={tenantAccount?.email ?? ''}
                      placement="topLeft"
                    >
                      {tenantAccount?.email ?? ''}
                    </Popover>
                  </ItemValue>
                }
              />
            </Col>
            <Col span={11} offset={1}>
              <Item
                label="Phone Number"
                value={tenantAccount?.phoneNumber}
                children={undefined}
              />
            </Col>

            <Col span={11}>
              <Item
                label="Birth Date"
                value={convertUTCtoFormatedLocalTime(tenantAccount?.birthDate)}
                children={undefined}
              />
            </Col>

            <Col span={11} offset={1}>
              <Item
                label="Status"
                value={tenantAccount?.isActive ? 'Active' : 'Inactive'}
                children={undefined}
              />
            </Col>
            <Col span={24} offset={1}>
              <Item
                label="Timezone"
                value={tenantAccount?.timezone ?? '_'}
                children={undefined}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </GenericModal>
  );
};

export default TenantAccountDetailModal;
