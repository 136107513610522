import React, { useState } from 'react';
import { Button, Col, Row, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { toast } from 'react-toastify';
import { removeEvaluation } from 'api/evaluationService';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { EvaluationItem } from 'types/Evaluation';
import { useTranslation } from 'react-i18next';
import { AUTH_ROLES } from 'types/Auth';
import CardItem from '../Card';
import * as Styled from '../Task/TaskStyles';
import AddEmployeeEvaluationModal from './AddEmployeeEvaluation';
import { EmployeeEvaluationItem } from './EmployeeEvaluationItem';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';

interface IProps {
  employeeEvaluation: EvaluationItem[];
  employeeId: string;
  loading: boolean;
}
export default function EmployeeEvaluation({
  employeeEvaluation,
  employeeId,
  loading,
}: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [editEvaluationId, setEditEvaluationId] = useState('');
  const onToggleModal = () => setModalVisible((state) => !state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const onDeleteEmployeeEvaluation = async (evaluationId: string) => {
    await removeEvaluation(evaluationId)
      .then(() => {
        toast.success(t('employeeEvaluationDeleted'));
        dispatch(fetchEmployeesDetail(employeeId));
        setEditEvaluationId('');
      })
      .catch(() => {
        toast.error(t('errorDeletingEmployeeEvaluation'));
      });
  };
  const onEditClose = () => {
    setEditEvaluationId('');
  };

  const data = employeeEvaluation?.filter((item) => !item.deletedAt) || [];

  return (
    <CardItem title={t('evaluationDetails')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Styled.RowItem gutter={[0, 24]} justify="center">
          <Col span={24}>
            {data.map((list: any, index: number) => (
              <EmployeeEvaluationItem
                key={list.id}
                item={list}
                onDelete={() => onDeleteEmployeeEvaluation(list.id)}
                last={index === data.length - 1}
                onItemClick={setEditEvaluationId}
              />
            ))}
          </Col>
          {!data.length && (
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col span={24}>
                <Row justify="center">
                  {authUserRole === AUTH_ROLES.EMPLOYEE ? (
                    <Styled.Label>
                      {t('youDontHaveAnyEvaluations')}
                    </Styled.Label>
                  ) : (
                    <Styled.Label>
                      {t('addAnEvaluationToThisEmployee')}
                    </Styled.Label>
                  )}
                </Row>
              </Col>
            </RoleBasedButton>
          )}
          <RoleBasedButton btn={BUTTONS.ADD_EVALUATION_DETAILS}>
            <Col span={24}>
              <Button
                icon={
                  <Styled.AddSpaceIcon>
                    <PlusCircleOutlined />
                  </Styled.AddSpaceIcon>
                }
                size="large"
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  fontWeight: '600',
                  fontSize: '16px',
                  color: '344054',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={onToggleModal}
              >
                {t('addEvaluation')}
              </Button>
            </Col>
          </RoleBasedButton>
        </Styled.RowItem>
        <RoleBasedButton btn={BUTTONS.EDIT_EVALUATION_DETAILS}>
          {modalVisible && (
            <AddEmployeeEvaluationModal
              open={modalVisible}
              closeModal={() => setModalVisible(false)}
              employeeId={employeeId}
              id=""
            />
          )}
          {editEvaluationId && (
            <AddEmployeeEvaluationModal
              id={editEvaluationId}
              open
              closeModal={onEditClose}
              employeeId={employeeId}
            />
          )}
        </RoleBasedButton>
      </Skeleton>
    </CardItem>
  );
}
