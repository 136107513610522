import React from 'react';
import moment from 'moment';

import { CandidatesType } from 'types/Candidates';
import { convertUTCtoLocalTime } from 'utils/utilFunctions';
import { TenantDataType, TenantAccountType } from 'types/Tenant';
import { getCandidateStatusIcon } from 'ui-v2/components/Header/helpers';
import * as Styled from 'ui-v2/components/DataGridComponents';
import TenantAccountAction from 'components/Actions/TenantAccountActions';
import i18next from '../i18n';

export const TenantAccountColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    sorter: (a: TenantAccountType, b: TenantAccountType) =>
      a.firstName.localeCompare(b.firstName),
    render: (_: string, item: CandidatesType) => (
      <Styled.TextBold>
        {`${item.firstName} ${item.lastName}, ${moment().diff(
          convertUTCtoLocalTime(item.birthDate),
          'years',
          false
        )}`}
      </Styled.TextBold>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('email'),
    dataIndex: 'email',
    key: 'email',
    render: (_: string, row: TenantDataType) => (
      <Styled.Text>{row.email}</Styled.Text>
    ),
    default: true,
    order: 3,
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    ellipsis: true,
    default: false,
    order: 4,
  },
  {
    title: i18next.t('accountStatus'),
    dataIndex: 'isActive',
    key: 'isActive',
    default: true,
    render: (_: string, row: TenantAccountType) => (
      <Styled.StatusTag
        status={row.isActive ? i18next.t('verify') : i18next.t('new')}
      >
        <p>
          {getCandidateStatusIcon(
            row.isActive ? i18next.t('verify') : i18next.t('new')
          )}
        </p>
        {row.isActive ? i18next.t('active') : i18next.t('Inactive')}
      </Styled.StatusTag>
    ),
    order: 5,
  },
  {
    title: i18next.t('twoFactorAuthentication'),
    dataIndex: 'twoFactorEnabled',
    key: 'twoFactorEnabled',
    ellipsis: true,
    default: false,
    render: (_: string, row: TenantAccountType) => (
      <Styled.StatusTag
        status={row.twoFactorEnabled ? i18next.t('verify') : i18next.t('new')}
      >
        <p>
          {getCandidateStatusIcon(
            row.twoFactorEnabled ? i18next.t('verify') : i18next.t('new')
          )}
        </p>
        {row.twoFactorEnabled ? i18next.t('enabled') : i18next.t('disabled')}
      </Styled.StatusTag>
    ),
    order: 6,
  },
  {
    title: '',
    key: 'action',
    dataIndex: 'action',
    width: 200,
    render: (_: string, row: TenantAccountType) => (
      <TenantAccountAction id={row?.id} />
    ),
    default: false,
    order: 7,
  },
];
