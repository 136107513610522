import React from 'react';
import { Col, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import InfoIcon from 'Icons/InfoIcon';
import { Id } from 'types/Id';

const TenantActions: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onInfoIconClick = () => {
    searchParams.append('viewtenantId', id);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Row justify="center" align="middle" gutter={[30, 0]}>
        <Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <StyledIcon onClick={onInfoIconClick}>
              <InfoIcon />
            </StyledIcon>
          </RoleBasedButton>
        </Col>
      </Row>
    </>
  );
};

export default TenantActions;
