import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

import { EmployeesSkimState } from 'redux/employeeSkim/types';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { OptionType } from 'types/OptionTypes';
import { EmployeesSkim } from '../../types/Employee';
import { RootState } from '../../redux/store';

export function useEmployeeSkimData() {
  const { data }: EmployeesSkimState = useSelector(
    (state: RootState) => state.employeesSkim
  );

  const [filteredEmployeeSkimOptions, setFilteredEmployeeSkimOptions] =
    useState<OptionType[]>([]);

  const getOptions = useCallback(
    (): OptionType[] =>
      data?.map((skim: EmployeesSkim) => ({
        id: skim?.id,
        label: `${skim?.firstName} ${skim?.lastName}`,
        value: skim?.id,
      })) || [],
    [data]
  );

  const getFilteredEmployeeSkimOptions = (textFilter: string): OptionType[] => {
    const options = getOptions();
    const filter = textFilter.trim();

    if (!filter?.length) {
      return options;
    }

    return options.filter((item) =>
      `${item.label}`.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase())
    );
  };

  async function getEmployeeSkimSelectWithLoadOptions(
    filterText: string
  ): Promise<SelectLoadPayload> {
    const filteredOptions = getFilteredEmployeeSkimOptions(filterText);
    setFilteredEmployeeSkimOptions(filteredOptions);
    return {
      data: filteredOptions,
      pageCount: 1,
    };
  }
  return {
    data,
    filteredEmployeeSkimOptions,
    getEmployeeSkimSelectWithLoadOptions,
  };
}
