import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleUser } from 'redux/users/actions';

export function useUsersData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.users
  );
  const { data: user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('id');

  useEffect(() => {
    if (userId) {
      dispatch(fetchSingleUser(userId));
    }
  }, [userId]);

  return {
    list: data?.data ?? [],
    user:
      data?.data?.find(
        (item: any) => item.id === searchParams.get('edit-user')
      ) || user,
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
  };
}
