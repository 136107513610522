import { Dispatch } from 'redux';
import { QueryParamsType } from 'types/QueryParams';
import { getPurchaseOrder, getPurchaseOrders } from 'api/purchaseOrder';
import { PurchaseOrders } from './types';

export const fetchPurchaseOrders =
  (queryParams?: QueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: PurchaseOrders.FETCH_PURCHASE_ORDERS_START,
    });
    getPurchaseOrders(queryParams)
      .then((res) => {
        dispatch({
          type: PurchaseOrders.FETCH_PURCHASE_ORDERS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PurchaseOrders.FETCH_PURCHASE_ORDERS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSinglePurchaseOrder =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: PurchaseOrders.FETCH_PURCHASE_ORDER_START,
    });
    getPurchaseOrder(id)
      .then((res) => {
        dispatch({
          type: PurchaseOrders.FETCH_PURCHASE_ORDER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PurchaseOrders.FETCH_PURCHASE_ORDER_ERRORED,
          payload: e,
        });
      });
  };

export const clearPurchaseOrderState = () => (dispatch: Dispatch) => {
  dispatch({
    type: PurchaseOrders.CLEAR_PURCHASE_ORDER_STATE,
  });
};
