import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { CheckIcon } from 'Icons/CheckIcon';
import { CloseIcon } from 'Icons/CloseIcon';
import { WorkPositionListModelType } from 'types/WorkPosition';
import WorkPositionActions from 'components/Actions/WorkPositonActions';
import i18next from '../i18n';

export const WorkPositionsColumns = [
  {
    title: i18next.t('workPosition'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a: WorkPositionListModelType, b: WorkPositionListModelType) =>
      a.name.localeCompare(b.name),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('headDepartment'),
    dataIndex: 'isHeadDepartment',
    key: 'isHeadDepartment',
    render: (_: string, row: WorkPositionListModelType) => (
      <Styled.StatusTag status={row.departmentId ? 'Yes' : 'No'}>
        {row.isHeadDepartment ? <CheckIcon /> : <CloseIcon />}
        <p>{row.isHeadDepartment ? 'Yes' : 'No'}</p>
      </Styled.StatusTag>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('department'),
    key: 'department',
    render: (_: string, item: WorkPositionListModelType) => (
      <Styled.Text>{item?.department?.name}</Styled.Text>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: WorkPositionListModelType) => (
      <WorkPositionActions id={row.id} />
    ),
    default: false,
    order: 3,
  },
];
