import agent from 'api/agent';
import { InvoicingQueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { InvoicingType } from 'types/Invoicing';
import { apiURLs } from './constants';

export const getInvoices = async (queryParams?: InvoicingQueryParamsType) =>
  agent.post(
    `${apiURLs.invoice}/get`,
    searchFields(queryParams, apiURLs.invoice)
  );

export const createInvoice = (payload: InvoicingType) =>
  agent.post(`${apiURLs.invoice}`, payload);

export const editInvoice = (payload: InvoicingType, invoiceId: string) =>
  agent.patch(`${apiURLs.invoice}/${invoiceId}`, payload);

export const getInvoice = (id: string) => agent.get(`${apiURLs.invoice}/${id}`);

export const deleteInvoice = (id: string) =>
  agent.delete(`${apiURLs.invoice}/${id}`);
