import { Reducer } from 'react';
import { ModalTypes } from 'types/Modal/Modal';

import { ModalState, ACTION_MODAL_TYPES } from './types';

const initialState: ModalTypes = {
  isVisible: false,
  modalContainer: undefined,
  modalProps: {
    showLinkButton: false,
    buttonLink: '',
    buttonText: '',
    modalTitle: '',
  },
};

const reducer: Reducer<ModalState, any> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case ACTION_MODAL_TYPES.CLOSE:
      state = { ...state, isVisible: false };
      break;
    case ACTION_MODAL_TYPES.SHOW:
      state = {
        ...state,
        isVisible: true,
        modalContainer: action.modalContainer,
        modalProps: action.modalProps,
      };
      break;
    default:
      break;
  }
  return state;
};

export { reducer as ModalReducer };
