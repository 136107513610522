import React from 'react';
import { Col, Row } from 'antd';

interface TenantConfigFormContentLayoutProps {
  companyDescriptionPanelContent: React.ReactNode;
  children: React.ReactNode;
}

const TenantConfigFormContentLayout: React.FC<
  TenantConfigFormContentLayoutProps
> = ({ companyDescriptionPanelContent, children }) => (
  <Row>
    <Col span={24}>{children}</Col>
    <Col span={24}>
      <Row gutter={[0, 20]}>
        <Col span={24} />
        <Col span={24}>{companyDescriptionPanelContent}</Col>
        <Col span={24} />
      </Row>
    </Col>
  </Row>
);

export default TenantConfigFormContentLayout;
