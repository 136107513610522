import { Col, Form, Row } from 'antd';
import { getAllEmployees } from 'api/employeeService';
import {
  createEmployeeTask,
  updateEmployeeTask,
} from 'api/employeeTaskService';
import { getAllOnBoardingTasks } from 'api/onBoardingTaskService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { AddOnboardingIcon } from 'Icons/AddOnboardingIcon';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  EmployeeTaskModelType,
  EmployeeTaskStatusOptions,
  EmployeeTaskTypeOptions,
} from 'types/EmployeeTask';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { OnboardingTaskModelType } from 'types/OnboardingTask';
import { OptionType } from 'types/OptionTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
} from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  employeeId: string;
  task?: EmployeeTaskModelType;
  closeModal: () => void;
  fetch: () => void;
}

export default function AddOnboardingTaskModal({
  open,
  employeeId,
  closeModal,
  task,
  fetch,
}: IProps) {
  const [form] = Form.useForm();
  const [onBoardingTasks, setOnBoardingTasks] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  async function fetchMentors(
    string: string,
    page: number
  ): Promise<SelectLoadPayload> {
    return getAllEmployees({ string, page }).then((response) => ({
      data: response.data?.data?.map((mentor: any) => ({
        id: mentor.id,
        label: `${mentor?.firstName} ${mentor?.lastName}`,
        value: mentor?.id,
      })),
      pageCount: response?.data?.meta?.pageCount || 1,
    }));
  }

  const AddOnboardingFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: InputTypes.TEXTAREA,
          defaultValue: task?.name,
          rules: [
            {
              required: true,
              message: t('requestedTaskName'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'assignedDate',
          label: t('assignDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(task?.assignedDate),
          rules: [
            {
              required: true,
              message: t('Assign Date is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'dueDate',
          label: t('dueDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(task?.dueDate),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('assignDate')),
          },
          rules: [
            {
              required: true,
              message: t('Due Date is required'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'status',
          label: t('status'),
          type: InputTypes.SELECT,
          selectOptions: EmployeeTaskStatusOptions,
          defaultValue: task?.status,
          rules: [
            {
              required: true,
              message: t('Status is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'type',
          label: t('type'),
          type: InputTypes.SELECT,
          selectOptions: EmployeeTaskTypeOptions,
          defaultValue: task?.type,
          rules: [
            {
              required: true,
              message: t('Task type is required'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'onBoardingTaskId',
          label: t('selectOnboardingTask'),
          type: InputTypes.SELECT,
          selectOptions: onBoardingTasks,
          defaultValue: task?.onBoardingTasks?.id,
        },
        {
          col: 24,
          offset: 0,
          name: 'mentor',
          label: t('mentor'),
          type: 'select-load',
          fetchOptions: fetchMentors,
          defaultValue: task?.mentor
            ? `${task?.mentor?.firstName} ${task?.mentor?.lastName}`
            : '',
        },
      ],
    ],
    [onBoardingTasks]
  );

  const onFinish = async (formValues: any) => {
    setLoading(true);
    const payload: any = {
      ...formValues,
      employeeId,
      mentor: formValues.mentor?.value,
      assignedDate: new Date(convertDateToUTC(formValues.assignedDate)),
      dueDate: new Date(convertDateToUTC(formValues.dueDate)),
    };

    if (!payload.onBoardingTaskId) {
      delete payload.onBoardingTaskId;
    }
    if (task?.id) {
      updateEmployeeTask(task.id, payload)
        .then(() => {
          fetch();
          toast.success(t('taskUpdatedSuccessfully'));
          closeModal();
          setLoading(false);
        })
        .catch(() => {
          toast.error(t('taskFailedtoUpdate'));
          setLoading(false);
        });
      return;
    }
    createEmployeeTask(payload)
      .then(() => {
        fetch();
        toast.success(t('taskCreatedSuccessfully'));
        closeModal();
        setLoading(false);
      })
      .catch(() => {
        toast.error(t('taskFailedtoCreate'));
        setLoading(false);
      });
  };

  async function fetchOnboardingTasks() {
    getAllOnBoardingTasks()
      .then((response) => {
        setOnBoardingTasks(
          response.data?.data?.map((task: OnboardingTaskModelType) => ({
            id: task.id,
            value: task.id,
            label: task.boardingTaskType,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        toast.error(t("Couldn't get onboarding tasks"));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchOnboardingTasks();
  }, []);

  return (
    <GenericModal
      title={
        task?.id
          ? t('editEmployeeOnboardingTask')
          : t('addEmployeeOnboardingTask')
      }
      open={open}
      closeModal={closeModal}
      icon={<AddOnboardingIcon />}
    >
      <GenericForm
        formConfiguration={AddOnboardingFormConfiguration}
        onFinish={onFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
