import ChangeUserPasswordForm from 'components/NewForms/ChangePasswordForm';
import React from 'react';
import { useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import Content from 'ui-v2/components/Content';

export default function ChangeUserPassword() {
  const { authUser } = useSelector(
    (state: { authUser: AuthUserState }) => state.authUser
  );

  return (
    <Content position="center">
      <ChangeUserPasswordForm userId={authUser?.id} />
    </Content>
  );
}
