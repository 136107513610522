/* eslint-disable indent */
import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import {
  getAllPurchaseRequests,
  getOnePurchaseRequest,
} from 'api/purchaseRequest';
import { PurchaseRequest } from './types';

export const fetchPurchaseRequests =
  (queryParams?: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: PurchaseRequest.FETCH_PURCHASE_REQUESTS_START,
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getAllPurchaseRequests(queryParams)
      .then((res) => {
        dispatch({
          type: PurchaseRequest.FETCH_PURCHASE_REQUESTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PurchaseRequest.FETCH_PURCHASE_REQUESTS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSinglePurchaseRequest =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: PurchaseRequest.FETCH_PURCHASE_REQUEST_START,
    });
    getOnePurchaseRequest(id)
      .then((res) => {
        dispatch({
          type: PurchaseRequest.FETCH_PURCHASE_REQUEST_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PurchaseRequest.FETCH_PURCHASE_REQUEST_ERRORED,
          payload: e,
        });
      });
  };

export const clearPurchaseRequest = () => (dispatch: Dispatch) => {
  dispatch({
    type: PurchaseRequest.CLEAR_WPURCHASE_REQUEST_STATE,
  });
};
