import 'moment/locale/af';
import 'moment/locale/ar';
import 'moment/locale/az';
import 'moment/locale/be';
import 'moment/locale/bg';
import 'moment/locale/bm';
import 'moment/locale/bn';
import 'moment/locale/bo';
import 'moment/locale/br';
import 'moment/locale/bs';
import 'moment/locale/ca';
import 'moment/locale/cs';
import 'moment/locale/cv';
import 'moment/locale/cy';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/dv';
import 'moment/locale/el';
import 'moment/locale/eo';
import 'moment/locale/es';
import 'moment/locale/et';
import 'moment/locale/eu';
import 'moment/locale/fa';
import 'moment/locale/fi';
import 'moment/locale/fo';
import 'moment/locale/fr';
import 'moment/locale/fy';
import 'moment/locale/gd';
import 'moment/locale/gl';
import 'moment/locale/ml';
import 'moment/locale/nl';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import 'moment/locale/sq';
import 'moment/locale/it';

const localesConfig = [
  { key: 'af', isoCode: 'za', value: 'af' },
  { key: 'ar', isoCode: 'ar', value: 'ar' },
  { key: 'az', isoCode: 'az', value: 'az' },
  { key: 'be', isoCode: 'by', value: 'be' },
  { key: 'bg', isoCode: 'bg', value: 'bg' },
  { key: 'bm', isoCode: 'ml', value: 'bm' },
  { key: 'bn', isoCode: 'bd', value: 'bn' },
  { key: 'bo', isoCode: 'bt', value: 'bo' },
  { key: 'br', isoCode: 'br', value: 'br' },
  { key: 'bs', isoCode: 'ba', value: 'bs' },
  { key: 'ca', isoCode: 'ca', value: 'ca' },
  { key: 'cs', isoCode: 'cz', value: 'cs' },
  { key: 'cv', isoCode: 'ru', value: 'cv' },
  { key: 'cy', isoCode: 'gb', value: 'cy' },
  { key: 'da', isoCode: 'dk', value: 'da' },
  { key: 'de', isoCode: 'de', value: 'de' },
  { key: 'dv', isoCode: 'mv', value: 'dv' },
  { key: 'el', isoCode: 'gr', value: 'el' },
  { key: 'en-gb', isoCode: 'gb', value: 'en-gb' },
  { key: 'eo', isoCode: 'eo', value: 'eo' },
  { key: 'es', isoCode: 'es', value: 'es' },
  { key: 'et', isoCode: 'ee', value: 'et' },
  { key: 'eu', isoCode: 'es', value: 'eu' },
  { key: 'fa', isoCode: 'ir', value: 'fa' },
  { key: 'fi', isoCode: 'fi', value: 'fi' },
  { key: 'fo', isoCode: 'fo', value: 'fo' },
  { key: 'fr', isoCode: 'fr', value: 'fr' },
  { key: 'fy', isoCode: 'nl', value: 'fy' },
  { key: 'gd', isoCode: 'gb', value: 'gd' },
  { key: 'gl', isoCode: 'es', value: 'es' },
  { key: 'sq', isoCode: 'al', value: 'sq' },
  { key: 'it', isoCode: 'it', value: 'it' },
];

export default localesConfig;
