import { Ideabox } from 'types/Ideabox';

export enum IdeaboxActions {
  FETCH_IDEABOX_START = 'FETCH_IDEABOX_START',
  FETCH_IDEABOX_SUCCESS = 'FETCH_IDEABOX_SUCCESS',
  FETCH_IDEABOX_ERRORED = 'FETCH_IDEABOX_ERRORED',

  FETCH_SINGLE_IDEABOX_START = 'FETCH_SINGLE_IDEABOX_START',
  FETCH_SINGLE_IDEABOX_SUCCESS = 'FETCH_SINGLE_IDEABOX_SUCCESS',
  FETCH_SINGLE_IDEABOX_ERRORED = 'FETCH_SINGLE_IDEABOX_ERRORED',

  CLEAR_SINGLE_IDEABOX = 'CLEAR_SINGLE_IDEABOX',
  CLEAR_ALL_IDEABOX = 'CLEAR_ALL_IDEABOX',
}

export interface IdeaboxState {
  data: Array<Ideabox>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface IdeaboxSingleState {
  data: Ideabox | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
