import { Row } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled(Row)`
  min-height: 100vh;
  background-color: #f9f9fb;

  .set-password-form {
    margin-top: 30px;
  }

  .card {
    display: flex;
    align-self: center;
    justify-content: center;
  }
`;
