import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import GenericForm from 'components/NewForms/Form';
import GenericModal from 'ui-v2/components/GenericModal';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { TimeOffRecord } from 'types/TimeOff';
import { fetchTimeOffSubstitutes, requestTimeOff } from 'api/timeOffService';
import { InputTypes } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { useTranslation } from 'react-i18next';
import TimeOffList from './TimeOffList';
import { RequestTimeOffFormConfiguration } from '../utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
  timeOff: Array<TimeOffRecord>;
  fetch: any;
  onClear: () => void;
}

export default function RequestTimeoffModal({
  open,
  closeModal,
  timeOff,
  fetch,
  onClear,
}: IProps) {
  const [form] = Form.useForm();
  const authUserState = useSelector((state: any) => state.authUser);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState<Array<TimeOffRecord>>();
  const [subsituteEmployeesOptions, setSubsituteEmployeesOptions] = useState<
    OptionType[]
  >([]);
  const { t } = useTranslation();

  const onSelectTime = (data: any) => {
    setDates([
      {
        date: timeOff[0].date as any,
        timeOffStart: data[0],
        timeOffEnd: data[1],
      },
    ]);
  };

  const getSubstitutes = async (employeeId: string) => {
    fetchTimeOffSubstitutes(employeeId).then((res) => {
      if (res.status === 200) {
        setSubsituteEmployeesOptions(
          res.data?.map((item: any) => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          }))
        );
      }
    });
  };

  useEffect(() => {
    if (authUserState?.authUser?.employee?.id) {
      getSubstitutes(authUserState?.authUser?.employee?.id);
    }
  }, [authUserState?.authUser?.employee?.id]);

  const FormConfig: any[][] = useMemo(
    () => [
      ...RequestTimeOffFormConfiguration,
      [
        {
          col: 24,
          offset: 0,
          name: 'substitutes',
          label: t('substitutedBy'),
          type: InputTypes.SELECT,
          selectOptions: subsituteEmployeesOptions,
        },
      ],
    ],
    [subsituteEmployeesOptions]
  );

  const onFormFinish = async () => {
    const employeeId = authUserState?.authUser?.employee?.id;
    if (!employeeId || loading) {
      return;
    }
    setLoading(true);
    const formValues = await form.getFieldsValue(true);

    await requestTimeOff({
      dates: dates ?? timeOff,
      reason: formValues.reason,
      approvers: formValues.approvers?.map((item: any) => item.value),
      substitues: formValues.substitutes,
      description: formValues.description || '',
      employeeId,
    })
      .then((res) => {
        setLoading(false);
        closeModal();
        if (res.status === 200) {
          toast.success(`${t('successfullyrequestedTimeoff')}!`);
          closeModal();
          fetch();
          onClear();
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('timeoffRequestFailed'));
      });
  };

  return (
    <GenericModal
      title={t('requesttimeOff')}
      open={open}
      closeModal={closeModal}
    >
      <Row>
        <TimeOffList list={timeOff} onChange={onSelectTime} />
      </Row>
      <GenericForm
        formConfiguration={FormConfig}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
