/* eslint-disable indent */
import { getAllAssets, getAssetById } from 'api/assetService';
import { Dispatch } from 'redux';
import { AssetQueryParamsType } from 'types/QueryParams';
import { Assets } from './types';

export const fetchAssets =
  (queryParams: AssetQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Assets.FETCH_ASSETS_START,
    });
    getAllAssets(queryParams)
      .then((res) => {
        dispatch({
          type: Assets.FETCH_ASSETS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Assets.FETCH_ASSETS_ERRORED,
          payload: e,
        });
      });
  };

export const fetchAsset = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Assets.FETCH_ASSET_START,
  });
  getAssetById(id)
    .then((res) => {
      dispatch({
        type: Assets.FETCH_ASSET_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Assets.FETCH_ASSET_ERRORED,
        payload: e,
      });
    });
};

export const clearSingleAsset = () => (dispatch: Dispatch) => {
  dispatch({
    type: Assets.CLEAR_SINGLE_ASSET,
  });
};
