import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledQuestion = styled(Typography.Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 0;
`;

export const StyledAnswer = styled(Typography.Text)`
  font-style: regular;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 0;
  margin-left: 20px;
`;
