import agent from 'api/agent';
import { PurchaseRequestPayload } from 'types/PurchaseRequest';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const createPurchaseRequest = (payload: PurchaseRequestPayload) =>
  agent.post(`${apiURLs.purchaseRequests}`, payload);

export const editPurchaseRequest = (
  payload: PurchaseRequestPayload,
  purchaseRequestId: string
) => agent.patch(`${apiURLs.purchaseRequests}/${purchaseRequestId}`, payload);

export const getAllPurchaseRequests = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.purchaseRequests}/get`, queryParams);

export const getOnePurchaseRequest = (id: string) =>
  agent.get(`${apiURLs.purchaseRequests}/${id}`);
