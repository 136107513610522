import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  NodataLabel,
  RowItemAsset,
} from 'ui-v2/components/Trainings/TrainingItemStyled';

interface IProps {
  name: string;
  onEdit: () => void;
  onDelete: () => void;
}
export function AssetSubCategoryItem({ name, onEdit, onDelete }: IProps) {
  const { t } = useTranslation();
  return (
    <RowItemAsset>
      <Col span={18}>
        <NodataLabel>{name}</NodataLabel>
      </Col>
      <Row justify="space-between" gutter={[40, 0]}>
        <Col>
          <IconButton onClick={onEdit}>
            <EditOutlined />
          </IconButton>
        </Col>
        <Col>
          <Popconfirm
            placement="topLeft"
            title={t('areYouSureYouWanttoDelete')}
            onConfirm={onDelete}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <IconButton>
              <DeleteOutlined />
            </IconButton>
          </Popconfirm>
        </Col>
      </Row>
    </RowItemAsset>
  );
}
