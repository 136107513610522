import React, { useEffect, useState } from 'react';
import { Button, Col } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createOffboarding } from 'api/offboarding';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchOffboardingByEmployeeId } from 'redux/offboarding/actions';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import { OffboardingStatus } from 'types/Offboarding';

import * as Styled from '../Task/TaskStyles';
import CardItem from '../Card';
import { StatusTag } from '../Employees/Onboarding/EmployeeOnboardingStyles';

interface IProps {
  employeeId: string;
  workPositionId: string;
  locationId: string;
}

export default function OffboardingComponent({
  employeeId,
  workPositionId,
  locationId,
}: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data } = useOffboardingData();

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchOffboardingByEmployeeId(employeeId));
    }
  }, [employeeId]);

  const onCreateOffboarding = async () => {
    if (data?.id) {
      navigate(
        `/employees/offboarding?offboardingId=${data?.id}&employeeId=${employeeId}&locationId=${locationId}`
      );
      return;
    }

    try {
      setLoading(true);
      const today = new Date();
      const endDate = new Date(today);
      endDate.setMonth(today.getMonth() + 1);

      const response = await createOffboarding({
        employeeId,
        workPositionId,
        startDate: today,
        endDate,
      });

      setLoading(false);
      if (response.status === 200 && response?.data?.id) {
        navigate(
          `/employees/offboarding?offboardingId=${response?.data?.id}&employeeId=${employeeId}&locationId=${locationId}`
        );
      }
    } catch (e) {
      setLoading(false);
      toast.error('Can not create Offboarding!');
    }
  };

  return (
    <CardItem
      title={t('offboarding')}
      rightButton={<StatusTag status={data?.status}>{data?.status}</StatusTag>}
    >
      <Styled.RowItem gutter={[0, 24]} justify="center">
        <Col span={24}>
          {data?.status === OffboardingStatus.COMPLETED ? (
            <p>Offboarding Completed</p>
          ) : (
            <Button
              icon={
                <Styled.AddSpaceIcon>
                  <PlusCircleOutlined />
                </Styled.AddSpaceIcon>
              }
              size="large"
              style={{
                borderRadius: '8px',
                width: '100%',
                fontWeight: '600',
                fontSize: '16px',
                color: '344054',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              loading={loading}
              onClick={onCreateOffboarding}
            >
              {data?.id ? t('Continue') : t('Start')}
            </Button>
          )}
        </Col>
      </Styled.RowItem>
    </CardItem>
  );
}
