/* eslint-disable no-empty-pattern */
import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSiderContent = styled.div.attrs(
  (props: { collapsed?: boolean }) => props
)(({ theme, collapsed }) => ({
  height: '100vh',
  position: 'fixed',
  color: theme.colors.palette.primary.gray['100'],
  backgroundColor: theme.colors.palette.primary.gray['900'],
  width: collapsed ? '5rem' : '15.7rem',
  padding: '2rem 1rem 2rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: collapsed ? 'center' : 'start',
  svg: {
    stroke: theme.colors.palette.primary.gray['100'],
  },
  overflowX: 'hidden',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const StyledSiderCollapseToggle = styled.div.attrs(
  (props: { collapsed?: boolean }) => props
)(({ theme, collapsed }) => ({
  position: 'fixed',
  top: '2rem',
  left: '15.3rem',
  cursor: 'pointer',
  padding: '.2rem .2rem',
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  ...(collapsed && { left: '4.5rem' }),
  color: theme.colors.palette.primary.gray['100'],
  backgroundColor: theme.colors.palette.primary.gray['900'],
}));

export const StyledSiderContentGroup = styled.div(({}) => ({
  width: '100%',
}));

export const StyledLanguageSelect = styled(Select)`
  color: black;
  width: 50%;
  height: 35px;
  margin-top: 10px;
`;

export const SideBarFooter = styled.div`
  position: sticky;
  top: calc(100vh - 150px);
  margin-top: 20px;
`;

export const StyledSiderDivider = styled.div(({}) => ({
  width: '100%',
  height: '1.375rem',
}));
