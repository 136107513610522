import { AssetSidebarIcon } from 'Icons/AssetSidebarIcon';
import { BenefitIcon } from 'Icons/BenefitIcon';
import { CalendarIcon } from 'Icons/CalendarIcon';
import { CandidatesIcon } from 'Icons/CandidatesIcon';
import { CertificationsIcon } from 'Icons/CertificationsIcon';
import { DashboardIcon } from 'Icons/DashboardIcon';
import DatabaseIcon from 'Icons/DatabaseIcon';
import { DepartmentsIcon } from 'Icons/DepartmentsIcon';
import { EmployeesIcon } from 'Icons/EmployeesIcon';
import { EvaluationPeriodsIcon } from 'Icons/EvaluationPeriodsIcon';
import { FilesIcon } from 'Icons/FilesIcon';
import { InterviewsIcon } from 'Icons/InterviewsIcon';
import { OpenPositionsIcon } from 'Icons/OpenPositionsIcon';
import { PlusIcon } from 'Icons/PlusIcon';
import { ProjectsIcon } from 'Icons/ProjectsIcon';
import { SettingsIcon } from 'Icons/SettingsIcon';
import { TimeEntryIcon } from 'Icons/TimeEntryIcon';
import { TrainingsIcon } from 'Icons/TrainingsIcon';
import { UserIcon } from 'Icons/UserIcon';
import { WorkPositionsIcon } from 'Icons/WorkPositionsIcon';
import React from 'react';
import { OnboardingIcon } from '../../../Icons/OnboardingIcon';

export type AppSidebarItemType = {
  icon: React.ReactNode;
  key: number;
  path: string;
  title: string;
};

export const appSidebarItems: AppSidebarItemType[] = [
  {
    icon: <DashboardIcon />,
    key: 1,
    path: '/dashboard',
    title: 'Dashboard',
  },
  {
    icon: <CandidatesIcon />,
    key: 2,
    path: '/candidates',
    title: 'Candidates',
  },
  {
    icon: <OpenPositionsIcon />,
    key: 3,
    path: '/open-positions',
    title: 'Open Positions',
  },
  {
    icon: <InterviewsIcon />,
    key: 4,
    path: '/interviews',
    title: 'Interviews',
  },

  {
    icon: <EmployeesIcon />,
    key: 6,
    path: '/employees',
    title: 'Employees',
  },
  {
    icon: <EvaluationPeriodsIcon />,
    key: 7,
    path: '/evaluations',
    title: 'Evaluations',
  },
  {
    icon: <TimeEntryIcon />,
    key: 8,
    path: '/tracking',
    title: 'Tracking Hours',
  },

  {
    icon: <InterviewsIcon />,
    key: 5,
    path: '/time-off',
    title: 'Time Off',
  },
  {
    icon: <EmployeesIcon />,
    key: 18,
    path: '/users',
    title: 'Users List',
  },
  {
    icon: <TrainingsIcon />,
    key: 9,
    path: '/trainings',
    title: 'Trainings',
  },
  {
    icon: <DepartmentsIcon />,
    key: 10,
    path: '/departments',
    title: 'Departments',
  },
  {
    icon: <ProjectsIcon />,
    key: 11,
    path: '/projects',
    title: 'Projects',
  },
  {
    icon: <OnboardingIcon />,
    key: 12,
    path: '/onboardings',
    title: 'Onboardings',
  },
  {
    icon: <WorkPositionsIcon />,
    key: 13,
    path: '/work-positions',
    title: 'Work Positions',
  },
  {
    icon: <AssetSidebarIcon />,
    key: 14,
    path: '/assets',
    title: 'Assets',
  },
  {
    icon: <CertificationsIcon />,
    key: 15,
    path: '/certifications',
    title: 'Certifications',
  },
  {
    icon: <FilesIcon />,
    key: 16,
    path: '/files',
    title: 'Files',
  },
  {
    icon: <DashboardIcon />,
    key: 17,
    path: '/reports-analytics',
    title: 'Reports & Analytics',
  },
  {
    icon: <DashboardIcon />,
    key: 22,
    path: '/payroll-rules',
    title: 'Payroll Rules',
  },
  {
    icon: <DashboardIcon />,
    key: 24,
    path: '/payroll-report',
    title: 'Payroll Report',
  },
  {
    icon: <DashboardIcon />,
    key: 24,
    path: '/data-entry',
    title: 'Data Entry',
  },
  {
    icon: <TimeEntryIcon />,
    key: 18,
    path: '/requests',
    title: 'Requests',
  },
  {
    icon: <WorkPositionsIcon />,
    key: 21,
    path: '/skills',
    title: 'Skill Categories',
  },
  {
    icon: <BenefitIcon />,
    key: 19,
    path: '/benefits',
    title: 'Benefits',
  },
  {
    icon: <SettingsIcon />,
    key: 20,
    path: '/settings',
    title: 'Settings',
  },
];

export const appEmployeeSidebarItems: AppSidebarItemType[] = [
  {
    icon: <UserIcon color="#FFF" />,
    key: 1,
    path: '/employee-details',
    title: 'Profile',
  },
  {
    icon: <PlusIcon />,
    key: 2,
    path: '/employee-details/tracker',
    title: 'Tracker',
  },
  {
    icon: <CalendarIcon />,
    key: 3,
    path: '/employee-details/time-off',
    title: 'Calendar',
  },
  {
    icon: <TimeEntryIcon />,
    key: 4,
    path: '/requests',
    title: 'All Requests',
  },
];

export const appSuperAdminSidebarItems: AppSidebarItemType[] = [
  {
    icon: <DatabaseIcon />,
    key: 1,
    path: '/tenants',
    title: 'Tenants',
  },
  {
    icon: <CertificationsIcon />,
    key: 2,
    path: '/ip-whitelisting',
    title: 'IP Whitelisting',
  },
  {
    icon: <EmployeesIcon />,
    key: 3,
    path: '/public-super-admins',
    title: 'Users List',
  },
];

export const appGuestTrackingHoursSidebarItems: AppSidebarItemType[] = [
  {
    icon: <TimeEntryIcon />,
    key: 1,
    path: '/tracking',
    title: 'Tracking Hours',
  },
];
