import React from 'react';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { Id } from 'types/Id';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeleteIcon } from 'Icons/DeleteIcon';

const InvoicingAction: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const onEdit = async () => {
    searchParams.append('edit-invoice', id);
    setSearchParams(searchParams);
  };

  const deleteInvoice = () => {
    searchParams.append('delete-invoice', id);
    setSearchParams(searchParams);
  };
  const onViewInvoice = () => {
    navigate(`invoice-details?id=${id}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editInvoice')}>
                <StyledIcon onClick={onEdit}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('details')}>
                <StyledIcon onClick={onViewInvoice}>
                  <ViewProfileIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={deleteInvoice}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default InvoicingAction;
