import EmployeeForm from 'components/NewForms/EmployeeForm';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  fetchEmployeeAssignments,
  fetchEmployeesDetail,
} from 'redux/employees/actions';
import Content from 'ui-v2/components/Content';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { EMPLOYEE_ID } from 'utils/constants';

export default function EditEmployee() {
  const { employeeDetail, employeeAssignments } = useEmployeesData();
  const authUserState = useSelector((state: any) => state.authUser);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchEmployeesDetail(
        (searchParams.get('id') as string) ||
          (localStorage.getItem(EMPLOYEE_ID) as string)
      )
    );
    dispatch(
      fetchEmployeeAssignments(
        (searchParams.get('id') as string) ||
          (localStorage.getItem(EMPLOYEE_ID) as string)
      )
    );
  }, [authUserState?.data?.employee?.id]);
  return (
    <Content position="center">
      <EmployeeForm
        employee={employeeDetail}
        assignments={employeeAssignments}
      />
    </Content>
  );
}
