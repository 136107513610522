import React, { useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Form, Space } from 'antd';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import GenericForm from 'components/NewForms/Form';
import { completeSuccessorTask, updateTaskStatus } from 'api/offboarding';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { OffboardingTaskStatus, SuccessorDto } from 'types/Offboarding';
import { CheckCircleOutlined, UserSwitchOutlined } from '@ant-design/icons';
import TabWrapper from './TabWrapper';

export default function SuccessorAssignent({ onNext }: { onNext: () => void }) {
  const [searchParams] = useSearchParams();
  const offboardingId = searchParams.get('offboardingId');
  const { getTabStatus, data } = useOffboardingData();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { getEmployeeSkimSelectWithLoadOptions, data: successors } =
    useEmployeeSkimData();
  const [loading, setLoading] = useState<boolean>(false);
  const status = getTabStatus(OffboardingTaskType.SUCCESSOR);
  const defaultSuccessor = successors?.find(
    (item) => item.id === data?.successorId
  );

  const SuccessorAssignmentConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'successor',
          label: t(''),
          type: InputTypes.SELECT_LOAD,
          showSearch: true,
          defaultValue: defaultSuccessor
            ? `${defaultSuccessor.firstName} ${defaultSuccessor.lastName}`
            : undefined,
          fetchOptions: getEmployeeSkimSelectWithLoadOptions,
          rules: [
            {
              required: true,
              message: t('Successor is required!'),
            },
          ],
        },
      ],
    ],
    [getEmployeeSkimSelectWithLoadOptions, status]
  );

  const onSubmit = () => {
    if (status === OffboardingTaskStatus.COMPLETED) {
      onNext();
      return;
    }

    const employeeId = form.getFieldValue('successor')?.value;
    const valuesToSend: SuccessorDto = { employeeId };

    if (offboardingId) {
      setLoading(true);
      completeSuccessorTask(offboardingId, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            updateTaskStatus({
              taskId: data?.offBoardingTasks[0]?.id,
              status: OffboardingTaskStatus.COMPLETED,
              offboardingId,
            })
              .then((updateRes) => {
                if (updateRes.status === 200) {
                  toast.success(t('Task completed successfully!'));
                  onNext();
                }
              })
              .catch((updateError) => {
                toastErrorMessages(updateError);
              });
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <TabWrapper onNext={onSubmit} type={OffboardingTaskType.SUCCESSOR}>
      <>
        {status === OffboardingTaskStatus.COMPLETED && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <CheckCircleOutlined
                style={{ fontSize: '64px', color: '#52c41a' }}
              />
              <h2>Successor Assignment Completed</h2>
              <p>
                The successor assignment has been successfully completed. The
                leaving employee has selected another employee to replace them.
              </p>
            </Space>
          </div>
        )}
        {[
          OffboardingTaskStatus.IN_PROGRESS,
          OffboardingTaskStatus.PENDING,
        ].includes(status) && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <UserSwitchOutlined
                style={{ fontSize: '64px', color: '#faad14' }}
              />
              <h2>Successor Assignment Pending</h2>
              <p>
                The successor assignment is pending. Please ensure that the
                leaving employee selects another employee to replace them.
              </p>
            </Space>
          </div>
        )}
        <div
          style={{
            maxWidth: '600px',
            margin: '50px auto',
            textAlign: 'center',
            pointerEvents:
              status === OffboardingTaskStatus.COMPLETED ? 'none' : 'auto',
          }}
        >
          <GenericForm
            formConfiguration={SuccessorAssignmentConfig}
            onFinish={onSubmit}
            form={form}
            loading={loading}
          />
        </div>
      </>
    </TabWrapper>
  );
}
