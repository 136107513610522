import { Moment } from 'moment';
import { EmployeeType } from './Employee';

export enum ReasonType {
  Vacation = 'Vacation',
  Health = 'Health/Disability',
  Break = 'Break',
  Maternity = 'Maternity',
  Absence = 'Absence',
}

export enum TimeOffStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface TimeOff {
  dates?: any;
  timeOff?: string[];
  status: TimeOffStatus;
  statusColor: string;
  approvedBy?: string;
  reason?: ReasonType;
  description?: string;
  employeeId: string;
  approver: any;
  employee?: EmployeeType;
}

export interface IApprover {
  id: string;
  action: TimeOffStatus;
  actionDate: Date;
  employee: EmployeeType;
  color?: string;
  approverid: string;
}

export interface DateType {
  date: Date;
  timeOffStart: Date;
  timeOffEnd: Date;
}

interface Substitutes {
  employeeId: string;
  substitutes: EmployeeType[];
}

export interface TimeOffRequest {
  id: string;
  dates: string;
  reason: ReasonType;
  approvers: IApprover[];
  description: string;
  employeeId: string;
  status: TimeOffStatus;
  statusColor: string;
  employee: EmployeeType;
  substitutes: Substitutes[];
  preferedSubstitute: EmployeeType[];
}

export interface ITimeOffRequestDTO {
  timeOffs: TimeOff[];
  employees: EmployeeType[];
  substitutes: Substitutes[];
}

export interface TimeOffCreateDTO {
  dates: string;
  reason: ReasonType;
  approvers: string[];
  description: string;
  employeeId: string;
}
export interface TimeOffRecord {
  date?: Moment;
  timeOffStart?: Moment;
  timeOffEnd?: Moment;
}

export interface IApproverUpdateDTO {
  timeOffId: string;
  employeeId: string;
  action: TimeOffStatus;
  admin?: boolean;
}

export interface GetTimeOffToApproveDTO {
  employees?: string[];
  projects?: string[];
  departments?: string[];
}
