import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Rate,
  Row,
  Select,
  TimePicker,
  Typography,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled, { CSSProp, css } from 'styled-components';

const BaseInputStyles: CSSProp = css`
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 44px;
  padding: 10px 14px;

  [class*='ant-input'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
`;

export const StyledScorecardEditButton = styled(Button)`
  font-size: 1.4rem;
  display: inline-flex;
  float: right;
  align-items: top;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  gap: 10px;
`;

export const StyledButtonPrimary = styled(Button)`
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  width: 100%;
  svg {
    margin-right: 1rem;
  }
  background: #155eef;
  color: #ffffff;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  width: 100%;
  svg {
  }
  ${(props) => {
    if (props.type === 'primary') {
      return 'background: #155EEF; color: #FFFFFF; svg{path{stroke: white} ; margin-right: 8px}';
    }
    if (props.type === 'link') {
      return 'border: 1px solid #EFF4FF; background: #EFF4FF;color: #004EEB; svg{path{stroke: #004EEB} margin-right: 8px}';
    }
    if (props.danger) {
      return 'border: 1px solid #FDA29B; color: #B42318;svg{path{stroke: #B42318} margin-right: 8px}';
    }
    return 'border: 1px solid #D0D5DD; color: #344054;svg{path{stroke: #344054} margin-right: 8px} ';
  }}
  :hover {
    span {
      color: unset !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledTimeTrackerButton = styled(Button)`
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  svg {
    margin-right: 8px;
  }
  ${(props) => {
    if (props.type === 'primary') {
      return 'background: #155EEF; color: #FFFFFF; svg{path{stroke: white}}';
    }
    if (props.type === 'link') {
      return 'border: 1px solid #EFF4FF; background: #EFF4FF;color: #004EEB; svg{path{stroke: #004EEB}}';
    }
    if (props.danger) {
      return 'border: 1px solid #FDA29B; background: #B42318; color: #FFFFFF; svg{path{stroke: #FFFFFF}}';
    }
    if (props.className === 'start-button') {
      return 'background: #15803d; color: #FFFFFF; svg{path{stroke: white}}';
    }
    return 'border: 1px solid #D0D5DD; color: #344054;svg{path{stroke: #344054}}';
  }}
  :hover {
    span {
      color: unset !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledIconButton = styled(Button)`
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  width: 100%;
  svg {
    margin-right: 0rem;
  }
  ${(props) => {
    if (props.type === 'primary') {
      return 'background: #155EEF; color: #FFFFFF; svg{path{stroke: white}}';
    }
    if (props.type === 'link') {
      return 'border: 1px solid #EFF4FF; background: #EFF4FF;color: #004EEB; svg{path{stroke: #004EEB}}';
    }
    if (props.danger) {
      return 'border: 1px solid #FDA29B; color: #B42318;svg{path{stroke: #B42318}}';
    }
    return 'border: 1px solid #D0D5DD; color: #344054;svg{path{stroke: #344054}} ';
  }}
  :hover {
    span {
      color: unset !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledUploadButton = styled(Button)`
  color: #004eeb;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.5;
    color: #004eeb !important;
    span {
      color: #004eeb !important;
    }
    svg {
      path {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledUploaDocButton = styled(Button)`
  background: #ffffff;
  height: 40px;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.6563rem;
  svg {
    path {
      stroke: #344054;
    }
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  [class*='ant-picker-input'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
`;

export const StyledDatepicker = styled(DatePicker)`
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 44px;
  padding: 10px 14px;

  width: 100%;
  [class*='ant-picker-input'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
`;

export const StyledDateRange = styled(DatePicker.RangePicker)`
  height: 44px;

  padding: 10px 14px;

  border: 1px solid #d0d5dd;
  border-radius: 8px;

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }

  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }

  [class*='ant-picker-input'] {
    display: flex;
    align-self: center;

    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
`;

export const StyledRow = styled(Row)`
  [class*='ant-col-12']:first-child {
    .ant-select {
      border-radius: 5px 0px 0px 5px;
    }
  }
  [class*='ant-col-12']:last-child {
    .ant-select,
    .ant-input {
      border-radius: 0px 5px 5px 0px;
      border-left: none;
    }
  }
  [class*='ant-table-wrapper'] {
    height: 50vh !important;
    overflow-y: auto;
    ul {
      display: none;
    }
  }
`;
export const StyledLabel = styled(Row)`
  position: relative;
`;

export const StyledInput = styled(Input)(() => ({
  ...BaseInputStyles,
}));

export const StyledPasswordInput = styled(Input.Password)(() => ({
  ...BaseInputStyles,
}));

export const StyledEyeBUtton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
`;

export const SelectStyled = styled(Select)`
  border: 1px solid #d0d5dd;
  height: 44px;
  padding: ${({ mode }) => (mode === 'multiple' ? '' : '5px')};
  border-radius: 8px;

  &.ant-styled-select {
    .ant-select-selector {
      padding: ${({ mode }) => mode === 'multiple' && '5px  !important'};
      .ant-select-selection-overflow {
        gap: 10px;
        .ant-select-selection-overflow-item {
          width: fit-content;
          height: fit-content;
          .ant-select-selection-item {
            width: fit-content;
            height: auto;
            border-radius: 5px;
            padding: 5px;
            margin: 0 0 5px 0;
            .ant-select-selection-item-content {
              padding: 0 3px;
            }
          }
        }
      }
    }
  }

  &[class*='ant-select'] {
    overflow-x: scroll;
    overflow-y: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
  .ant-select-selector {
    border: unset !important;
    border-radius: 8px !important;
    padding: 5px 14px !important;
    height: 44px;
  }
  .ant-select-selection-item {
    display: flex;
    align-self: center;
    color: #667085;
    font-size: 16px;
    display: ${({ disabled }) => disabled && 'none'};
  }
  &[class*='ant-select-focused'] {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
  [class*='ant-select-selection-placeholder'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }

  &.ant-select-multiple .ant-select-selection-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .ant-select-selection-overflow-item {
      flex-shrink: 0;
      margin-right: 6px;
    }
  }

  .ant-select-selector {
    padding-right: 32px; // Adjust as needed
  }
`;

export const PrefixInputStyle = styled(Input)`
  height: 47px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  align-items: center;

  [class*='ant-input'] {
    padding: 10px 14px;
    border: unset;
    &:focus {
      box-shadow: none !important;
      background-color: white !important;
    }
    &:hover {
      box-shadow: none !important;
      border-left: 0px;
    }
  }
  [class*='ant-select-focused'] {
    [class*='ant-select-selector'] {
      box-shadow: unset !important;
      border: unset !important;
    }
    *.ant-select-selection-item {
      box-shadow: unset !important;
    }
  }
`;

export const FormItemStyled = styled(Form.Item)<{ direction?: string }>`
  margin-top: 0.625rem;
  color: #344054;
  font-weight: 500;
  display: block;
  .ant-picker {
    width: 100%;
  }
  .set-password-form {
    margin-top: 30px;
    margin-bottom: 0;
  }

  ${({ direction }) => {
    if (direction === 'vertical') {
      return [
        'display: flex !important;',
        'flex-direction: row !important;',
        'gap: 10px !important;',
        'align-items: center !important;',
        'div {padding: unset !important};',
      ];
    }
    return [];
  }};
`;

export const UploadStyled = styled(Upload.Dragger)`
  background: #ffffff !important;
  border: 1px dashed #eaecf0 !important;
  border-radius: 12px !important;
  & .upload__container {
    color: #475467;
    font-size: 14px;
    & .link {
      color: #004eeb;
    }
  }
`;

export const StyledTextArea = styled(TextArea)`
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 44px;
  width: 830px;
  [class*='ant-input'] {
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`;

export const StyledRate = styled(Rate)`
  color: #85e13a;
  display: flex;
  font-size: 30px;
  justify-content: space-between;

  @media (max-width: 812px) {
    font-size: 16px !important;
  }

  @media (max-width: 1390px) {
    font-size: 25px !important;
  }
`;
export const AddSpaceIcon = styled.div`
  margin-left: 10px;
`;
export const StyledRemoveButton = styled(Button)`
  border-radius: 8px;
  height: 35px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  margin-left: auto;
  svg {
    margin-right: 1rem;
  }
  background: #155eef;
  color: #ffffff;
`;

export const StyledAddButton = styled(Button)`
  border-radius: 8px;
  height: 35px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-left: auto;
  svg {
    margin-right: 1rem;
  }
  background: #ffffff;
  color: #155eef;
`;
export const RequirementText = styled(Typography.Text)`
  font-size: 16px;
  font-weight: bold;
  font-family: Poppins, sans-serif;
  margin-bottom: 18px;
  margin-left: 2px;
  margin-top: 25px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 40px;
`;
