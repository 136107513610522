import { createTask, updateTask } from 'api/taskService';
import { Task } from 'types/Task';

export async function submitTask(formValues: any, task?: Task) {
  if (task?.id) {
    if (!formValues?.fileTaskName?.content && !formValues?.fileTaskName?.name) {
      formValues = {
        ...formValues,
        fileTaskName: {
          name: task?.taskDocuments?.at(0)?.name,
          content: task?.taskDocuments?.at(0)?.url,
        },
      };
    }
    return updateTask(task?.id, formValues)
      .then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          return true;
        }
        return false;
      })
      .catch(() => ({ error: 'Failed to update task!' }));
  }

  return createTask({
    name: formValues.name,
    description: formValues.description,
    progress: formValues.progress,
    interviewId: formValues?.interviewId,
    dueDate: formValues.dueDate,
    evaluation: formValues.evaluation,
    fileTaskName: formValues?.fileTaskName,
    score: 1,
  } as any)
    .then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        return true;
      }
      return false;
    })
    .catch(() => ({ error: 'Failed to create task!' }));
}

export const taskSelectOptions = [
  {
    id: 'To Do',
    label: 'To Do',
    value: 'To Do',
  },
  {
    id: 'In Progress',
    label: 'In Progress',
    value: 'In Progress',
  },
  {
    id: 'Done',
    label: 'Done',
    value: 'Done',
  },
];
export const convertBase64 = (file: Blob): any =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
