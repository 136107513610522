/* eslint-disable no-confusing-arrow */
import { Menu } from 'antd';
import Dropdown from 'antd/lib/dropdown';

import styled from 'styled-components';

export const DropDownStyle = styled(Dropdown)<{ status?: string }>`
  border-radius: 16px;
  background: #edeff3;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #344054;
  border: none;
  margin-left: 0px;
  text-transform: capitalize;

  ${({ status }) =>
    status === 'new' && 'background-color:#edeff3; color:#344054'};
  ${({ status }) =>
    status === 'verify' && 'background-color:#ECFDF3; color:#027A48 '};
  ${({ status }) =>
    status === 'hired' && 'background-color:#ECFDF3; color:#027A48 '};
  ${({ status }) =>
    status === 'rejected' && 'background-color:#FEF3F2; color: #B42318 '};
`;

export const MenuStyle = styled(Menu)`
  border-radius: 16px;
  background: #ffffff;
  padding: 10px;
`;

export const MenuItemStyle = styled(Menu.Item)<{
  status?: string;
  disabled?: boolean;
}>`
  border-radius: 16px;
  text-transform: capitalize;
  margin-top: 4px;
  font-size: 14px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${({ status }) =>
    status === 'new' && 'background-color: #edeff3; color: #344054'};
  ${({ status }) =>
    status === 'verify' && 'background-color: #ECFDF3; color: #027A48'};
  ${({ status }) =>
    status === 'hired' && 'background-color: #ECFDF3; color: #027A48'};
  ${({ status }) =>
    status === 'rejected' && 'background-color: #FEF3F2; color: #B42318'};
`;
