import { Switch, SwitchProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { RulesType } from 'types/FormTypes';
import { FormItemStyled } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  defaultChecked?: boolean | undefined;
  switchProps?: SwitchProps;
  rules?: RulesType[];
  disabled?: boolean;
  direction?: 'vertical' | 'horizontal';
  onChecked?: (checked: boolean) => void;
  hint?: React.ReactNode | string;
}
const SwitchComponent: React.FC<Props> = ({
  placeholder,
  name,
  switchProps,
  defaultChecked,
  rules,
  disabled,
  onChecked,
  direction,
  hint,
}) => {
  const [checked, isChecked] = useState(false);

  const toogleChecked = () => {
    isChecked((checked) => !checked);
  };

  useEffect(() => {
    isChecked(defaultChecked ?? false);
  }, [defaultChecked]);

  return (
    <FormItemStyled
      name={name}
      label={placeholder}
      direction={direction}
      rules={rules}
      {...(hint && { tooltip: hint })}
    >
      <Switch
        disabled={disabled}
        onClick={onChecked}
        checked={checked}
        onChange={toogleChecked}
        {...switchProps}
      />
    </FormItemStyled>
  );
};

SwitchComponent.defaultProps = {
  defaultChecked: false,
  direction: 'horizontal',
  rules: [],
};

export default SwitchComponent;
