/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-v2/components/GenericModal';
import { InputTypes } from 'types/FormTypes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { PurchaseOrderType } from 'types/PurchaseOrder';
import useHeader from 'ui-v2/hooks/useHeader';
import { usePurchaseOrdersData } from 'ui-v2/hooks/usePurchaseOrdersData';
import { useDispatch } from 'react-redux';
import { createPurchaseOrder, editPurchaseOrder } from 'api/purchaseOrder';
import { toast } from 'react-toastify';
import {
  fetchPurchaseOrders,
  fetchSinglePurchaseOrder,
} from 'redux/purchaseOrders/action';
import { convertUTCtoLocalTime, toastErrorMessages } from 'utils/utilFunctions';
import CardItem from 'ui-v2/components/Card';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import {
  phoneNumberValidator,
  prefixSelector,
} from '../EmployeeForm/components/utils';

interface IProps {
  open?: boolean;
  closeModal?: () => void;
  selectedPurchaseOrder?: PurchaseOrderType | null;
}

function PurchaseOrderModalForm({
  open,
  closeModal,
  selectedPurchaseOrder,
}: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { take } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list: purchaseOrders, pagination } = usePurchaseOrdersData();
  const navigate = useNavigate();
  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  const onFormFinish = async () => {
    const formValues = form.getFieldsValue(true);
    const valuesToSend: PurchaseOrderType = {
      orderDate: formValues.orderDate,
      vendorCompanyName: formValues.vendorCompanyName,
      vendorAddress: formValues.vendorAddress,
      vendorPhoneNumber: formValues.vendorPhoneNumber,
      vendorPhoneNumberPrefix: formValues.vendorPhoneNumberPrefix,
      vendorEmail: formValues.vendorEmail,
      receiverCompanyName: formValues.receiverCompanyName,
      receiverName: formValues.receiverName,
      receiverAddress: formValues.receiverAddress,
      receiverPhoneNumber: formValues.receiverPhoneNumber,
      receiverPhoneNumberPrefix: formValues.receiverPhoneNumberPrefix,
      receiverEmail: formValues.receiverEmail,
      tenderNumber: formValues.tenderNumber,
      item: formValues.item,
      description: formValues.description,
      quantity: Number(formValues.quantity),
      pricePerUnit: Number(formValues.pricePerUnit),
      totalPrice: Number(formValues.quantity) * Number(formValues.pricePerUnit),
      vat: formValues.vat,
      currencyId: formValues.currencyId,
    };

    setLoading(true);

    if (selectedPurchaseOrder?.id) {
      editPurchaseOrder(valuesToSend, selectedPurchaseOrder?.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyUpdatedOrder'));
            dispatch(fetchSinglePurchaseOrder(selectedPurchaseOrder?.id ?? ''));
            const currentPage = pagination?.page ?? 1;
            dispatch(fetchPurchaseOrders({ page: currentPage, take }));
            closeModal?.();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createPurchaseOrder(valuesToSend)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('successfullyCreatedOrder'));
            const currentPage = pagination?.page ?? 1;
            dispatch(fetchPurchaseOrders({ page: currentPage, take }));
            closeModal?.();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (searchParams.get('edit-purchase-order')) {
      searchParams.delete('edit-purchase-order');
      setSearchParams(searchParams);
    }
    if (searchParams.get('add-purchase-order') === 'true') {
      searchParams.delete('add-purchase-order');
      setSearchParams(searchParams);
    }
    if (searchParams.get('edit-purchase-order-save') === 'true') {
      searchParams.delete('edit-purchase-order-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams, form, setSearchParams, purchaseOrders]);

  const onSubmit = () => {
    form.submit();
    navigate('/purchase-orders');
  };

  const PurchaseOrderFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'orderDate',
          label: t('orderDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(
            selectedPurchaseOrder?.orderDate || ''
          ),
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'vendorCompanyName',
          label: t('vendorCompanyName'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.vendorCompanyName || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'vendorAddress',
          label: t('vendorAddress'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.vendorAddress || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'vendorEmail',
          label: t('vendorEmail'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.vendorEmail || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'vendorPhoneNumber',
          label: t('vendorPhoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: selectedPurchaseOrder?.vendorPhoneNumber,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'vendorPhoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue:
              selectedPurchaseOrder?.vendorPhoneNumberPrefix ||
              prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'item',
          label: t('item'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.item || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'quantity',
          label: t('quantity'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'number',
            rows: 1,
            min: 1,
          },
          defaultValue: selectedPurchaseOrder?.quantity || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: InputTypes.TEXTAREA,
          defaultValue: selectedPurchaseOrder?.description || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'pricePerUnit',
          label: t('pricePerUnit'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: selectedPurchaseOrder?.pricePerUnit || '',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions,
            placeholder: '',
            defaultValue:
              selectedPurchaseOrder?.currency?.id || currencyOptions[1]?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          loading: currencyLoading,
        },
        {
          col: 11,
          offset: 2,
          name: 'vat',
          label: t('vat'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.vat || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'receiverName',
          label: t('receiverName'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.receiverName || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'receiverCompanyName',
          label: t('receiverCompanyName'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.receiverCompanyName || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'receiverAddress',
          label: t('receiverAddress'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.receiverAddress || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'receiverPhoneNumber',
          label: t('receiverPhoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: selectedPurchaseOrder?.receiverPhoneNumber,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'receiverPhoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue:
              selectedPurchaseOrder?.receiverPhoneNumberPrefix ||
              prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'receiverEmail',
          label: t('receiverEmail'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.receiverEmail || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
        {
          col: 11,
          offset: 2,
          name: 'tenderNumber',
          label: t('tenderNumber'),
          type: InputTypes.INPUT,
          defaultValue: selectedPurchaseOrder?.tenderNumber || '',
          rules: [{ required: true, message: t('fieldRequired') }],
        },
      ],
    ],
    [currencyOptions, selectedPurchaseOrder]
  );

  return selectedPurchaseOrder?.id ? (
    <CardItem title={t('editPurchaseOrder')}>
      <GenericForm
        formConfiguration={PurchaseOrderFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={loading || !selectedPurchaseOrder?.id}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => navigate('/purchase-orders')}
                type="link"
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton onClick={onSubmit} type="primary" htmlType="submit">
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </CardItem>
  ) : (
    <GenericModal
      title={t('addPurchaseOrder')}
      open={open ?? false}
      closeModal={closeModal ?? (() => {})}
    >
      <GenericForm
        formConfiguration={PurchaseOrderFormConfiguration}
        form={form}
        onFinish={onFormFinish}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default PurchaseOrderModalForm;
