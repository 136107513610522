import { Dispatch } from 'redux';
import { getOffboarding, getOffboardingByEmployeeId } from 'api/offboarding';
import { OffboardingType } from './types';

export const fetchOffboarding = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: OffboardingType.FETCH_OFFBOARDING_START,
  });
  getOffboarding(id)
    .then((res) => {
      dispatch({
        type: OffboardingType.FETCH_OFFBOARDING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: OffboardingType.FETCH_OFFBOARDING_ERRORED,
        payload: e,
      });
    });
};

export const fetchOffboardingByEmployeeId =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: OffboardingType.FETCH_OFFBOARDING_START,
    });
    getOffboardingByEmployeeId(id)
      .then((res) => {
        dispatch({
          type: OffboardingType.FETCH_OFFBOARDING_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: OffboardingType.FETCH_OFFBOARDING_ERRORED,
          payload: e,
        });
      });
  };

export const clearSingleOffboarding = () => (dispatch: Dispatch) => {
  dispatch({
    type: OffboardingType.CLEAR_OFFBOARDING,
  });
};
