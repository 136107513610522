import agent from 'api/agent';
import { BankDetailsCreate } from 'types/BankDetails';
import { apiURLs } from './constants';

export const createBankDetails = (values: BankDetailsCreate) =>
  agent.post(`${apiURLs.paymentDetails}`, values);

export const getBankDetailsForOneEmployee = (employeeId: string) =>
  agent.get(`${apiURLs.paymentDetails}${apiURLs.employee}/${employeeId}`);

export const getBankDetailsById = (id: string) =>
  agent.get(`${apiURLs.paymentDetails}/${id}`);

export const updateBankDetailsById = (id: string, values: BankDetailsCreate) =>
  agent.patch(`${apiURLs.paymentDetails}/${id}`, values);

export const deleteBankDetailsById = (id: string) =>
  agent.delete(`${apiURLs.paymentDetails}/${id}`);
