import React from 'react';
import moment from 'moment';
import { Col, List, Row, TimePicker } from 'antd';

import { CalendarIcon } from 'Icons/CalendarIcon';
import { TimeIcon } from 'Icons/TimeIcon';
import { TimeOffRecord } from 'types/TimeOff';
import { useTranslation } from 'react-i18next';
import * as Styled from './TimeOffListStyles';

export default function TimeOffList({
  list,
  onChange,
}: {
  list: Array<TimeOffRecord>;
  onChange: (date: any) => void;
}) {
  const daysOff = list.filter((item: TimeOffRecord) => !item.timeOffStart);
  const hoursOff = list.filter((item: TimeOffRecord) => item.timeOffStart);
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Row>
        {daysOff.length > 0 && (
          <Col span={12}>
            <Styled.CustomList
              size="small"
              header={
                <Row gutter={[6, 0]}>
                  <Col>
                    <CalendarIcon stroke="#667085" />
                  </Col>
                  <Col>
                    <Styled.DayLabel>{t('days')}</Styled.DayLabel>
                  </Col>
                </Row>
              }
              dataSource={daysOff}
              renderItem={(item: any) => (
                <List.Item>
                  <Col span={24}>
                    <Row gutter={[6, 0]}>
                      <Col>
                        <Styled.Record>
                          {moment(item.date).format('DD/MM/YYYY')}
                        </Styled.Record>
                      </Col>
                      <Styled.Duration>
                        {moment(item.date).format('dddd')}
                      </Styled.Duration>
                    </Row>
                  </Col>
                </List.Item>
              )}
            />
          </Col>
        )}
        {daysOff.length === 1 && hoursOff.length === 0 && (
          <Col span={10} offset={2}>
            <Row gutter={[0, 15]}>
              <Styled.TimePickerLabel>
                {`${t('selectTimeoffHours')}!`}
              </Styled.TimePickerLabel>
              <Styled.TimePickerRange>
                <TimePicker.RangePicker
                  style={{ borderRadius: '8px' }}
                  format="HH:mm"
                  showNow
                  onChange={onChange}
                />
              </Styled.TimePickerRange>
            </Row>
          </Col>
        )}

        <Col span={12}>
          {hoursOff.length > 0 && (
            <Styled.CustomList
              size="small"
              header={
                <Row gutter={[6, 0]}>
                  <Col>
                    <TimeIcon />
                  </Col>
                  <Col>
                    <Styled.DayLabel>{t('hours')}</Styled.DayLabel>
                  </Col>
                </Row>
              }
              dataSource={hoursOff}
              renderItem={(item: any) => (
                <List.Item>
                  <Col span={24}>
                    <Row gutter={[6, 0]}>
                      <Col>
                        <Row gutter={[6, 0]}>
                          <Col>
                            <Styled.Record>
                              {moment(item.timeOffStart).format('DD/MM/YYYY')}
                            </Styled.Record>
                          </Col>
                          <Col>
                            <Styled.Duration>
                              {moment(item.timeOffStart).format('HH:MM')}
                            </Styled.Duration>
                          </Col>
                          {' - '}
                          <Styled.Duration>
                            {`${moment(item.timeOffEnd).format('HH:MM')}`}
                          </Styled.Duration>
                        </Row>
                      </Col>
                      <Col>
                        <Styled.Record>
                          {moment(
                            moment
                              .duration(item.timeOffStart.diff(item.timeOffEnd))
                              .asHours()
                          ).format('HH:mm')}
                        </Styled.Record>
                      </Col>
                    </Row>
                  </Col>
                </List.Item>
              )}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
}
