import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Calendar } from 'antd';
import DateHolidays from 'date-holidays';

import { TimeOffRecord, TimeOffRequest } from 'types/TimeOff';
import CellRender from './CellRender';
import {
  findDate,
  findDateInTimeOffRequests,
  getDaysBetweenDates,
  isDayIncluded,
  isInRange,
} from './utils';

export default function TimeOffCalendar({
  timeOff,
  onChange,
  timeOffList,
}: {
  timeOff: Array<TimeOffRecord>;
  onChange: (data: any) => void;
  timeOffList: Array<TimeOffRequest>;
}) {
  const [a, setA] = useState<any>('');
  const [b, setB] = useState<any>('');
  const [holidays, setHolidays] = useState<Moment[]>([]);
  const [holidayNames, setHolidayNames] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!a && !b) return;

    // When a or b changes - IF theres already selected options in that day, store them again

    if (a && !b) {
      onChange((data: any) => [
        ...data,
        {
          date: a,
        },
      ]);
      return;
    }

    if (a && b) {
      const range = getDaysBetweenDates(a, b);

      onChange(
        range.map((item) => {
          const exists = findDate(timeOff, item);
          if (exists) {
            console.log(111, 'MATCH', exists);
            return exists;
          }
          return {
            date: item,
          };
        })
      );
    }
  }, [a, b]);

  useEffect(() => {
    if (!timeOff.length) {
      setA('');
      setB('');
    }
  }, [timeOff]);
  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const country = 'AL'; // Replace with the desired country code
        const year = new Date().getFullYear();

        const hd = new DateHolidays(country);
        hd.setLanguages('al'); // Set the desired language

        const holidays = hd.getHolidays(year);
        const holidayDates = holidays.map((holiday) => moment(holiday.date));
        setHolidays(holidayDates);

        const holidayNamesMap: Record<string, string> = {};
        holidays.forEach((holiday) => {
          holidayNamesMap[moment(holiday.date).format('YYYY-MM-DD')] =
            holiday.name;
        });
        setHolidayNames(holidayNamesMap);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };

    fetchHolidays();
  }, []);

  const dateList: Array<Moment> = useMemo(
    () =>
      timeOffList
        .map((t) => t.dates)
        .map((i) => JSON.parse(i))
        .flat()
        .map((i) => moment(i.date)),
    [timeOffList]
  );

  const onPanelChange = (value: Moment) => {
    if (moment(value).isSame(a)) {
      setA('');
      setB('');
    }
    if (!a) {
      setA(value);
      return;
    }

    setB(value);
  };

  const onTimes = (startTime: Moment, endTime: Moment) => {
    onChange([
      ...timeOff.filter((item: any) => {
        if (!moment(item.date).isSame(startTime, 'date')) {
          return item;
        }
        return false;
      }),
      {
        date: startTime,
        timeOffStart: startTime,
        timeOffEnd: endTime,
      },
    ]);
  };

  const isDateDisabled = (date: Moment) =>
    isDayIncluded(date, dateList) ||
    holidays.some((holiday) => holiday.isSame(date, 'day'));

  const dateCellRender = (cellDate: any) => {
    if (isDateDisabled(cellDate)) {
      const result: any = findDateInTimeOffRequests(timeOffList, cellDate);

      const holidayName = holidayNames[cellDate.format('YYYY-MM-DD')];
      let reason = 'No reason provided';
      let status = 'Unknown';

      if (result !== null) {
        reason = result.reason !== null ? result.reason : reason;
        status = result.status !== null ? result.status : status;
      }

      return (
        <CellRender
          holidayName={holidayName}
          cellDate={cellDate}
          disabled
          description={reason || holidayName}
          status={status}
          holidays={holidays}
        />
      );
    }

    if (moment(a).isSame(cellDate) || isInRange(a, b, cellDate)) {
      const holidayName = holidayNames[cellDate.format('YYYY-MM-DD')];
      return (
        <CellRender
          holidayName={holidayName}
          cellDate={cellDate}
          selected
          onTimes={onTimes}
          holidays={holidays}
        />
      );
    }

    return null;
  };

  return (
    <Calendar
      dateCellRender={dateCellRender}
      disabledDate={isDateDisabled as any}
      onChange={onPanelChange}
    />
  );
}
