import { Col, Row } from 'antd';
import React, { forwardRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { InputTypes } from 'types/FormTypes';
import { PayrollIntervalValueType } from 'types/PayrollRule';

type IntervalFormItem = {
  key: number;
  item: {
    from: number;
    to: number;
    value: number;
  };
};

interface PayrollRuleIntervalFormProps {
  valueType: PayrollIntervalValueType;
  currency: any;
  intervalData: Partial<IntervalFormItem>;
  onFormIntervalChange: (values: any) => void;
}

const PayrollRuleIntervalForm = forwardRef(
  (
    {
      valueType,
      currency,
      onFormIntervalChange,
      intervalData,
    }: PayrollRuleIntervalFormProps,
    form
  ) => {
    const { t } = useTranslation();

    const defaultItemValues = {
      from: 0,
      to: 0,
      value: 0,
    };

    const [intervalFields, setIntervalFields] = useState<
      Array<IntervalFormItem>
    >([{ key: 0, item: { ...defaultItemValues } }]);

    const onAddInterval = async () => {
      const { validateFields } = form as any;
      await validateFields();
      setIntervalFields((prev) => [
        ...prev,
        { key: prev.length, item: { ...defaultItemValues } },
      ]);
    };

    useEffect(() => {
      if (intervalData) {
        setIntervalFields(intervalData as IntervalFormItem[]);
      }
    }, []);

    const PayrollRuleIntervalFormConfig: any = useMemo(
      () => [
        ...intervalFields.map((el: IntervalFormItem) => [
          {
            col: 7,
            offset: 0,
            name: `from-${el.key}`,
            label: t('fromSalary'),
            type: InputTypes.INPUT,
            defaultValue: el?.item.from,
            inputProps: {
              type: 'number',
            },
            rules: [
              {
                required: true,
                message: 'This field is required',
              },
            ],
          },
          {
            col: 7,
            offset: 1,
            name: `to-${el.key}`,
            label: t('toSalary'),
            defaultValue: el?.item.to,
            inputProps: {
              type: 'number',
            },
            type: InputTypes.INPUT,
            rules: [
              {
                required: true,
                message: 'This field is required',
              },
            ],
          },
          {
            col: 6,
            offset: 3,
            name: `value-${el.key}`,
            label: `${t('value')} ${
              valueType?.toLowerCase() ===
              PayrollIntervalValueType.FIXED.toLowerCase()
                ? currency || ''
                : '%'
            }`,
            inputProps: {
              type: 'number',
            },
            type: InputTypes.INPUT,
            defaultValue: el?.item.value,
            rules: [
              {
                required: true,
                message: 'This field is required',
              },
            ],
          },
        ]),
      ],
      [intervalFields, valueType]
    );

    const onFieldsChange = () => {
      const { getFieldsValue } = form as any;
      onFormIntervalChange(getFieldsValue());
    };

    const { getFieldsValue } = form as any;
    const formValues = getFieldsValue();

    useEffect(() => {
      onFormIntervalChange(formValues);
    }, [formValues['from-0']]);

    return (
      <GenericForm
        formConfiguration={PayrollRuleIntervalFormConfig}
        form={form as any}
        onFieldsChange={onFieldsChange}
      >
        <FormItemStyled style={{ marginBottom: 0 }}>
          <Row>
            <Col span={6} offset={9}>
              <StyledButton onClick={onAddInterval} type="default">
                Add interval
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    );
  }
);

export default PayrollRuleIntervalForm;
