import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Id } from 'types/Id';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';

import { DeleteIcon } from 'Icons/DeleteIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import { deleteUser } from 'api/usersService';
import { fetchUsers } from 'redux/users/actions';
import Popconfirm from 'antd/es/popconfirm';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { useTranslation } from 'react-i18next';
import { useUsersData } from 'ui-v2/hooks/useUsersData';

const UsersActions: React.FC<Id> = ({ id }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { pagination } = useUsersData();

  const onEdit = async () => {
    searchParams.append('edit-user', id);
    setSearchParams(searchParams);
  };

  const onDeleteClick = async () => {
    const response = await deleteUser(id);

    if (response.status === 200) {
      toast.success(t('Deleted user!'));
      dispatch(fetchUsers({ page: pagination?.page || 1, take: 10 }));
      return;
    }

    toast.warning(t('Could not delete user!'));
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('Edit User')}>
                <StyledIcon onClick={onEdit}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={onDeleteClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default UsersActions;
