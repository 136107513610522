import styled from 'styled-components';
import { Typography } from 'antd';

export const Label = styled(Typography.Text)`
  font-weight: 600;
  font-size: 14px;
  color: #101828;
`;

export const Description = styled(Typography.Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
`;
export const TextStyle = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  font-style: italic;
`;
