/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Avatar, Col, List, Popconfirm, Row, Skeleton } from 'antd';

import { Request, updateRequest } from 'types/Request';
import CardItem from 'ui-v2/components/Card';
import GenericModal from 'ui-v2/components/GenericModal';
import { StyledButton } from 'components/NewForms/FormStyled';
import { updateRequestStatus } from 'api/request';
import { toastErrorMessages } from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchAllRequests } from 'redux/requests/actions';
import { useTranslation } from 'react-i18next';
import * as Styled from './RequestListStyles';

interface IProps {
  requests: Request[];
  loading: boolean;
  filterByType?: string;
  children?: any;
}

export default function TimeOffRequestList({
  requests,
  loading,
  filterByType,
  children,
}: IProps) {
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null);
  const dispatch = useDispatch();

  const filteredRequests = filterByType
    ? requests.filter((request: Request) => request.type === filterByType)
    : requests;

  const handleRequestClick = (request: Request) => {
    setSelectedRequest(request);
  };

  const handleModalClose = () => {
    setSelectedRequest(null);
  };

  const onSubmit = async (vote: boolean) => {
    if (selectedRequest) {
      const payload: updateRequest = {
        requestId: selectedRequest?.id,
        message: '',
        approved: vote,
      };
      try {
        const response = await updateRequestStatus(payload);
        if (response.status === 200) {
          if (response.data.approved === 'true') {
            toast.success('Succesfully rejected request');
          } else toast.success('Succesfully approved request');
          setSelectedRequest(null);
          dispatch(fetchAllRequests());
        }
      } catch (error) {
        toastErrorMessages(error);
      }
    }
  };

  const onApproveRequest = () => {
    onSubmit(true);
  };

  const onCancelRequest = () => {
    onSubmit(false);
  };

  const { t } = useTranslation();

  return (
    <>
      {children || (
        <CardItem title={t('requests')} wrapContentHeight>
          <List
            loading={loading}
            itemLayout="horizontal"
            style={{ width: '100%' }}
            dataSource={filteredRequests}
            renderItem={(request: Request) => (
              <Styled.Item
                onClick={() => handleRequestClick(request)}
                actions={[
                  <Row justify="center">
                    <Styled.FixedWidthContainer>
                      <Styled.Reason ellipsis>
                        {t(request.type).toUpperCase()}
                      </Styled.Reason>
                    </Styled.FixedWidthContainer>
                  </Row>,
                ]}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar>
                        {request?.from?.firstName?.trim().charAt(0)}
                      </Avatar>
                    }
                    description={
                      <Row justify="end">
                        <Col span={12}>
                          <Styled.Description ellipsis>
                            {`${request?.from?.firstName} ${request?.from?.lastName}`}
                          </Styled.Description>
                        </Col>
                        <Col span={12}>
                          <Row justify="center">
                            <Styled.Status status={request?.approved}>
                              {request.approved === true
                                ? t('approved')
                                : request?.approved === false
                                ? t('rejected')
                                : t('pending')}
                            </Styled.Status>
                          </Row>
                        </Col>
                      </Row>
                    }
                  />
                </Skeleton>
              </Styled.Item>
            )}
          />
        </CardItem>
      )}

      {selectedRequest && (
        <GenericModal
          title={t('requestDetails')}
          closeModal={handleModalClose}
          open
        >
          <Row justify="space-between">
            <Col>
              <p>
                {t('requestFrom')}:{' '}
                {selectedRequest.from?.firstName.toUpperCase()}{' '}
                {selectedRequest.from?.lastName.toUpperCase()}
              </p>
            </Col>
            <Col>
              <Styled.Status status={selectedRequest.approved}>
                {selectedRequest.approved === true
                  ? t('approved')
                  : selectedRequest?.approved === false
                  ? t('rejected')
                  : t('pending')}
              </Styled.Status>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p style={{ fontSize: 14 }}>
                {t('title')}: {t(selectedRequest.title).toUpperCase()}
              </p>
            </Col>
            <Col span={12}>
              <p style={{ fontSize: 14 }}>
                {t('type')}: {t(selectedRequest.type).toUpperCase()}
              </p>
            </Col>
          </Row>
          {selectedRequest?.approved === null && (
            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
              <Col span={5} offset={12} style={{ left: 20 }}>
                <Popconfirm
                  title={t('rejectThisRequest')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={onCancelRequest}
                >
                  <StyledButton type="link">{t('reject')}</StyledButton>
                </Popconfirm>
              </Col>
              <Col span={6} offset={1}>
                <Popconfirm
                  title={t('approveThisRequest')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={onApproveRequest}
                >
                  <StyledButton type="primary">{t('Approve')}</StyledButton>
                </Popconfirm>
              </Col>
            </Row>
          )}
        </GenericModal>
      )}
    </>
  );
}
