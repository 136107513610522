import { Col, Row } from 'antd';
import * as React from 'react';

type IProps = {
  children: React.ReactElement;
};

export default function Container({ children }: IProps) {
  return (
    <Row
      justify="center"
      style={{ minHeight: '100vh', backgroundColor: '#F9F9FB' }}
    >
      <Col
        xs={10}
        sm={10}
        md={8}
        lg={7}
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Col>
    </Row>
  );
}
