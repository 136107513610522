/* eslint-disable @typescript-eslint/no-unused-vars */
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Col, Form, Row, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  superAdminPasswordRules,
  updatePublicSuperAdmin,
} from 'api/publicSuperAdminServise';
import GenericForm from 'components/NewForms/Form';
import {
  FormItemStyled,
  StyledButton,
  StyledEyeBUtton,
  StyledInput,
  StyledLabel,
} from 'components/NewForms/FormStyled';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getAuthUser } from 'redux/authUser/actions';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { PasswordRulesType } from 'types/PasswordRules';
import GenericModal from 'ui-v2/components/GenericModal';
import { usePublicSuperAdminData } from 'ui-v2/hooks/usePublicSuperAdminData';
import { toastErrorMessages } from 'utils/utilFunctions';

interface SuperAdminChangePasswordModal {
  onClose: () => void;
}

const SuperAdminChangePasswordModal: React.FC<
  SuperAdminChangePasswordModal
> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { me } = usePublicSuperAdminData();
  const [loading, setLoading] = useState(false);
  const [passwordRules, setPasswordRoles] = useState<PasswordRulesType>();
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [form] = useForm();
  const [rules, setRules] = useState<string[]>();
  const [regex, setRegex] = useState();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { t } = useTranslation();

  const getPasswordRules = async () => {
    try {
      const { data } = await superAdminPasswordRules();
      setRegex(data.regex);
      setRules(data.rules);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPasswordRules();
  }, []);

  const EditSuperAdminFormPasswodConfig: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'newPassword',
          label: 'New password',
          type: InputTypes.INPUT,
          inputProps: {
            type: 'password',
          },
          rules: [
            {
              required: true,
              message: 'Please enter your new password',
            },
            {
              validator(_: any, value: any): Promise<any> {
                const isValid = regex && new RegExp(regex).test(value);
                if (!isValid) {
                  setIsValidPassword(false);
                  return Promise.reject(new Error(rules?.join('')));
                }
                setIsValidPassword(true);
                return Promise.resolve();
              },
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'confirmPassword',
          label: 'Confirm Password',
          type: InputTypes.INPUT,
          inputProps: {
            type: 'password',
          },
          rules: [
            {
              required: true,
              message: 'Please confirm your password!',
            },
            {
              validator(_: any, value: string): Promise<any> {
                if (!value || form.getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              },
            },
          ],
        },
      ],
    ],
    [isValidPassword, rules, me]
  );

  const onSubmit = async () => {
    if (!me?.id) {
      return;
    }
    const values = form.getFieldsValue(true);
    console.log({ values });
    const valuesToSend = {
      password: values?.newPassword,
    };
    setLoading(true);
    updatePublicSuperAdmin(me?.id, valuesToSend)
      .then((res) => {
        if (res.status === 200) {
          onClose();
          toast.success('Successfully changed passwod!');
          dispatch(getAuthUser());
        }
      })
      .catch(toastErrorMessages)
      .finally(() => setLoading(false));
  };

  return (
    <GenericModal open title={t('changePassword')} closeModal={onClose}>
      <Spin spinning={loading}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <GenericForm
              form={form}
              formConfiguration={EditSuperAdminFormPasswodConfig}
              onFinish={onSubmit}
            />
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={11}>
                <StyledButton type="ghost" onClick={onClose}>
                  {t('cancel')}
                </StyledButton>
              </Col>
              <Col span={11}>
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  {t('submit')}
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </GenericModal>
  );
};

export default SuperAdminChangePasswordModal;
