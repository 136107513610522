import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { ReferalType } from 'types/Candidates';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { StyledRow } from '../FormStyled';
import InputComponent from '../Input';
import SelectComponents from '../Select/Select';
import SelectWithLoad from '../SelectWithLoad/SelectWithLoad';

interface Props {
  placeholder: string;
  defaultValue?: string;
  name: string;
  disableEmployeeReferal?: boolean;
  hint?: React.ReactNode | string;
}

const referalOptions = Object.keys(ReferalType).map((value) => ({
  id: value,
  value,
  label: value.charAt(0) + value.slice(1).toLowerCase(),
}));

const PrefixReferal: React.FC<Props> = ({
  placeholder,
  defaultValue,
  disableEmployeeReferal,
  name,
  hint,
}) => {
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();

  const [selectedReferal, setSelectedReferal] = useState<string | null>(
    defaultValue?.toUpperCase() || null
  );
  const isEqualToReferal = (index: number): boolean =>
    selectedReferal === Object.keys(ReferalType)[index];

  const isDivided = (): boolean => isEqualToReferal(3) || isEqualToReferal(4);

  return (
    <StyledRow justify="start" align="middle">
      <Col span={isDivided() ? 12 : 24}>
        <SelectComponents
          placeholder={placeholder}
          name={name}
          selectOptions={
            disableEmployeeReferal
              ? referalOptions.filter((r) => r.value !== 'EMPLOYEE')
              : referalOptions
          }
          defaultValue={defaultValue}
          onSelect={(value: string) => setSelectedReferal(value)}
          onClear={() => setSelectedReferal(null)}
          hint={hint}
        />
      </Col>
      <Col span={isDivided() ? 12 : 0}>
        <Row align="stretch" gutter={[0, isEqualToReferal(4) ? 23 : 28]}>
          <Col span={24} />
          <Col span={24}>
            {selectedReferal === Object.keys(ReferalType)[3] && (
              <SelectWithLoad
                className="remove-border-left"
                placeholder=""
                name="employeeId"
                fetchOptions={getEmployeeSkimSelectWithLoadOptions}
                hint={hint}
              />
            )}
            {isEqualToReferal(4) && (
              <InputComponent placeholder="" name="referedBy" />
            )}
          </Col>
        </Row>
      </Col>
    </StyledRow>
  );
};

PrefixReferal.defaultProps = {
  disableEmployeeReferal: false,
};

export default PrefixReferal;
