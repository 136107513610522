import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormConfigurationType } from 'types/FormTypes';

interface IProps {
  closeModal: () => void;
}

export function ChangePassword({ closeModal }: IProps) {
  // const authUserState = useSelector((state: any) => state.authUser);
  const [form] = Form.useForm();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { t } = useTranslation();

  const changeEmployeePasswordFormConfiguration: FormConfigurationType[][] =
    useMemo(
      () => [
        [
          {
            col: 24,
            offset: 0,
            name: 'password',
            label: 'New Password',
            type: 'input',
            inputProps: {
              type: 'password',
            },
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'confirm_password',
            label: 'Confirm New Password',
            type: 'input',
            inputProps: {
              type: 'password',
            },
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
      ],
      []
    );

  const onSubmit = () => {
    // const values = form.getFieldsValue();
    // const employeeId =
    //   authUserState?.employee?.id ||
    //   (localStorage.getItem(EMPLOYEE_ID) as string);
    // changeEmployeePassword({ id: employeeId, password: values.password })
    //   .then((response: any) => {
    //     if (response.status === 200) {
    //       toast.success('Password successfully changed');
    //       closeModal();
    //       dispatch(getAuthUser());
    //       navigate('/employee-details');
    //     }
    //   })
    //   .catch(() => {
    //     toast.error('Could not change password!');
    //   })
    //   .finally(() => {
    //     console.log();
    //   });
  };

  return (
    <GenericForm
      formConfiguration={changeEmployeePasswordFormConfiguration}
      onFinish={onSubmit}
      form={form}
    >
      <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
        <Row>
          <Col span={11}>
            <StyledButton onClick={closeModal} htmlType="reset" danger>
              {t('cancel')}
            </StyledButton>
          </Col>
          <Col span={11} offset={2}>
            <StyledButton
              onClick={() => form.submit()}
              type="primary"
              htmlType="submit"
            >
              Confirm
            </StyledButton>
          </Col>
        </Row>
      </FormItemStyled>
    </GenericForm>
  );
}
