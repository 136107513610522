import React from 'react';
import { Row } from 'antd';

import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { PublicSuperAdminType } from 'types/PublicSuperAdmin';
import PublicSuperAdminAction from 'components/Actions/PublicSuperAdminAction';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from '../i18n';

export const PublicSuperAdminColumns = [
  {
    title: i18next.t('email'),
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    render: (_: string, item: PublicSuperAdminType) => (
      <Styled.TextBold>{item?.email}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('twoFactorAuthentication'),
    dataIndex: 'isTwoFAComplete',
    key: 'isTwoFAComplete',
    render: (_: string, item: PublicSuperAdminType) => (
      <Row justify="start">
        <Styled.StatusTag status={item?.isTwoFAComplete ? 'verify' : 'new'}>
          <p>
            {`${
              item?.isTwoFAComplete
                ? i18next.t('enabled')
                : i18next.t('disabled')
            }`}
          </p>
        </Styled.StatusTag>
      </Row>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('createdAt'),
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a: PublicSuperAdminType, b: PublicSuperAdminType) =>
      a.createdAt.localeCompare(b.createdAt),
    render: (_: string, item: PublicSuperAdminType) => (
      <Styled.TextBold>
        {convertUTCtoFormatedLocalTime(item?.createdAt)}
      </Styled.TextBold>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('updatedAt'),
    dataIndex: 'updated_at',
    key: 'updated_at',
    sorter: (a: PublicSuperAdminType, b: PublicSuperAdminType) =>
      a.updatedAt.localeCompare(b.updatedAt),
    render: (_: string, item: PublicSuperAdminType) => (
      <Styled.TextBold>
        {convertUTCtoFormatedLocalTime(item?.updatedAt)}
      </Styled.TextBold>
    ),
    default: true,
    order: 3,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: PublicSuperAdminType) => (
      <PublicSuperAdminAction id={row.id} />
    ),
    default: false,
    order: 4,
  },
];
