import { Reducer } from 'react';
import { Action } from 'redux';

import {
  Certifications,
  CertificationsState,
  SingleCertificationState,
} from './types';

const initialState: CertificationsState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<CertificationsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case Certifications.FETCH_CERTIFICATIONS_START:
      state = { ...state, loading: true };
      break;
    case Certifications.FETCH_CERTIFICATIONS_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case Certifications.FETCH_CERTIFICATIONS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialStateSingleCertification: SingleCertificationState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const singleCertification: Reducer<SingleCertificationState, Action> = (
  state = initialStateSingleCertification,
  action: any
) => {
  switch (action.type) {
    case Certifications.FETCH_SINGLE_CERTIFICATION_START:
      state = { ...state, loading: true };
      break;
    case Certifications.FETCH_SINGLE_CERTIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case Certifications.FETCH_SINGLE_CERTIFICATION_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case Certifications.CLEAR_SINGLE_CERTIFICATION:
      state = initialStateSingleCertification;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as CertificationsReducer, singleCertification };
