import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InterviewResponseType } from 'types/Interview';

export function useInterviewsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.interviewsPage
  );
  const [searchParams] = useSearchParams();

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    interview: data?.data?.find(
      (el: any) => el?.id === searchParams.get('edit-interview')
    ) as InterviewResponseType | undefined,
    loading,
    loaded,
    errored,
    error,
  };
}
