import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

import { updateTenantAccountSettings } from 'api/tenantService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { fetchTenantAccounts } from 'redux/tenants/actions';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { StatusType } from 'types/Status';
import { TenantAccountType } from 'types/Tenant';
import GenericModal from 'ui-v2/components/GenericModal';
import { ItemLabel } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { TenantLogo } from 'ui-v2/components/Tenant/TenantInfo/TenantInfo';
import useHeader from 'ui-v2/hooks/useHeader';
import { toastErrorMessages } from 'utils/utilFunctions';

type TenantAccountSettingsModal = {
  onClose: () => void;
  tenantAccount?: TenantAccountType;
};

const TenantAccountSettingsModal: React.FC<TenantAccountSettingsModal> = ({
  onClose,
  tenantAccount,
}) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { take } = useHeader();
  const [submitted, setSubmitted] = useState(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const TenantAccountDetailFormConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'twoFactorEnabled',
          label: 'Two Factor Authentication',
          direction: 'vertical',
          type: InputTypes.SWITCH,
          switchProps: {
            checkedChildren: StatusType.ENABLED,
            unCheckedChildren: StatusType.Disabled,
          },
          defaultChecked: !!tenantAccount?.twoFactorEnabled,
        },
        {
          col: 11,
          offset: 2,
          name: 'isActive',
          label: 'Account Status',
          type: InputTypes.SWITCH,
          switchProps: {
            checkedChildren: StatusType.ACTIVE,
            unCheckedChildren: StatusType.INACTIVE,
          },
          defaultChecked: !!tenantAccount?.isActive,
        },
      ],
    ],
    [tenantAccount]
  );

  const onSubmit = async () => {
    const tenantId = searchParams.get('tenantId');
    if (!submitted || !tenantAccount?.id || !tenantId) {
      setSubmitted(true);
      return;
    }
    setSubmitted(false);
    const isActive = form?.getFieldValue('isActive') ?? tenantAccount?.isActive;
    const twoFactorEnabled =
      form?.getFieldValue('twoFactorEnabled') ??
      tenantAccount?.twoFactorEnabled;
    setLoading(true);

    updateTenantAccountSettings({
      tenantId,
      isActive,
      twoFactorEnabled,
      userId: tenantAccount?.id,
    })
      .then((res) => {
        if (res.status === 201) {
          toast.success('Account settings updated successfully!');
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        dispatch(
          fetchTenantAccounts(tenantId, {
            pageOptions: { page: 1, take },
          })
        );
        setLoading(false);
        onClose();
      });
  };

  return (
    <GenericModal
      title=""
      icon={
        <Row align="middle" gutter={[10, 10]}>
          <Col>
            <TenantLogo logoContent={tenantAccount?.avatar} />
          </Col>
          <Col>
            <Row>
              <Col>{`${tenantAccount?.firstName} ${tenantAccount?.lastName}`}</Col>
              <Col span={24}>
                <ItemLabel>Account Settings</ItemLabel>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      open
      closeModal={onClose}
    >
      <GenericForm
        form={form}
        loading={loading}
        formConfiguration={TenantAccountDetailFormConfig}
        onFinish={onSubmit}
      >
        <Row gutter={[0, 30]}>
          <Col span={24} />
          <Col span={24}>
            <FormItemStyled>
              <Row justify="space-between">
                <Col span={11}>
                  <StyledButton onClick={onClose}>Canel</StyledButton>
                </Col>
                <Col span={11}>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    onClick={onSubmit}
                  >
                    Save
                  </StyledButton>
                </Col>
              </Row>
            </FormItemStyled>
          </Col>
        </Row>
      </GenericForm>
    </GenericModal>
  );
};

export default TenantAccountSettingsModal;
