import { Col, Row } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';
import styled from 'styled-components';

export const StyledTenantOnboardingTitle = styled.div(({ theme }) => ({
  ...theme.typography.title.semibold.xs,
  color: theme.colors.palette.primary.gray['700'],
}));

export const StyledTenantOnboardingContent = styled.div(({ theme }) => ({
  ...theme.typography.body.medium.md,
  color: theme.colors.palette.primary.gray['500'],
}));

export const StyledOnboardingSteps = styled(Row)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.colors.palette.primary.gray['50'],
  width: '100vw',
  height: '100%',
  overflowY: 'scroll',
  paddingBottom: '2rem',
}));

export const StyledOnboardingStepsHeader = styled(Row)(({ theme }) => ({
  backgroundColor: theme.colors.palette.primary.gray['900'],
  padding: '1.5rem 2.5rem',
  color: theme.colors.palette.base.white,
}));

export const StyledOnboardingStepsTenantName = styled(Col)(({ theme }) => ({
  ...theme.typography.title.semibold.xs,
}));

export const StyledOnboardingStepsArrow = styled(StyledButton)<{
  success?: boolean;
}>(({ success, theme }) => ({
  minWidth: '10rem',
  ...(success && {
    backgroundColor: theme.colors.palette.primary.success['300'],
  }),
}));

export const StyledOnboardingStepsContent = styled(Col)(({ theme }) => ({
  minHeight: '60vh',
  backgroundColor: theme.colors.palette.base.white,
  padding: '1rem 0.5rem',
  borderRadius: '6px',
  border: `1px solid ${theme.colors.palette.primary.gray['200']}`,
}));
