import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { VendorCategoryState } from 'redux/vendorCategories/types';
import { VendorCategoryType } from 'types/VendorCategory';

export function useVendorCategoriesData() {
  const {
    data,
    loading,
    loaded,
    errored,
    error,
    vendorCategoryDetail,
  }: VendorCategoryState = useSelector(
    (state: RootState) => state.vendorCategories
  );
  const [searchParams] = useSearchParams();

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    vendorCategory:
      (vendorCategoryDetail as VendorCategoryType) ||
      data?.data?.find(
        (item: VendorCategoryType) => item.id === searchParams.get('id')
      ),
    loading,
    loaded,
    error,
    errored,
  };
}
