import { MetaPagination } from 'types/GridTable';
import {
  SkillCategory,
  SkillCategoryWithSubCategoriesType,
} from 'types/Skills';

export enum Skills {
  FETCH_ALL_SKILLS_START = 'FETCH_ALL_SKILLS_START',
  FETCH_ALL_SKILLS_SUCCESS = 'FETCH_ALL_SKILLS_SUCCESS',
  FETCH_ALL_SKILLS_ERRORED = 'FETCH_ALL_SKILLS_ERRORED',
  FETCH_SKILLS_START = 'FETCH_SKILLS_START',
  FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS',
  FETCH_SKILLS_ERRORED = 'FETCH_SKILLS_ERRORED',

  FETCH_SKILL_START = 'FETCH_SKILL_START',
  FETCH_SKILL_SUCCESS = 'FETCH_SKILL_SUCCESS',
  FETCH_SKILL_ERRORED = 'FETCH_SKILL_ERRORED',

  CLEAR_SKILL_DATA = 'CLEAR_SKILL_DATA',

  DELETE_SKILL = 'DELETE_SKILL',

  FETCH_SUB_SKILLS_START = 'FETCH_SUB_SKILLS_START',
  FETCH_SUB_SKILLS_SUCCESS = 'FETCH_SUB_SKILLS_SUCCESS',
  FETCH_SUB_SKILLS_ERRORED = 'FETCH_SUB_SKILLS_ERRORED',
}

export interface CreateSkillDto {
  id: string;
  categoryName: string;
}

export interface SkillsState {
  data: {
    data: SkillCategory[];
    meta: MetaPagination | null;
  };
  skills: {
    data: Array<SkillCategoryWithSubCategoriesType>;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  skillCategories: {
    data: SkillCategory[];
    categoryName: string | null;
    meta: MetaPagination | null;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  skill: {
    data: any;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };

  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
