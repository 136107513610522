import React from 'react';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import EmployeeAssets from 'ui-v2/components/Employees/Assets';
import useEmployeeAssetsData from 'ui-v2/hooks/useEmployeeAssetsData';
import { useSearchParams } from 'react-router-dom';
import { OffboardingTaskStatus } from 'types/Offboarding';
import { Space } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import TabWrapper from './TabWrapper';

export default function Assets({ onNext }: { onNext: () => void }) {
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  if (!employeeId) return null;

  const { getTabStatus } = useOffboardingData();
  const status = getTabStatus(OffboardingTaskType.ASSETS);
  const { assets, fetchEmployeeAssets } = useEmployeeAssetsData({ employeeId });
  return (
    <TabWrapper onNext={onNext} type={OffboardingTaskType.ASSETS}>
      {status === OffboardingTaskStatus.COMPLETED || !assets?.length ? (
        <div
          style={{
            maxWidth: '600px',
            margin: '50px auto',
            textAlign: 'center',
            pointerEvents:
              status === OffboardingTaskStatus.COMPLETED ? 'none' : 'auto',
          }}
        >
          <Space direction="vertical" size="large">
            <CheckCircleOutlined
              style={{ fontSize: '64px', color: '#52c41a' }}
            />
            <h2>Return of Assets Completed</h2>
            <p>
              The return of company assets has been successfully completed.
              Thank you for ensuring that all assigned assets such as computer,
              monitor, and other equipment have been returned.
            </p>
          </Space>
        </div>
      ) : (
        <>
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <ExclamationCircleOutlined
                style={{ fontSize: '64px', color: '#faad14' }}
              />
              <h2>Return of Assets Pending</h2>
              <p>
                The return of company assets is pending. Please ensure the
                employee returns all assigned assets such as computer, monitor,
                and other equipment.
              </p>
            </Space>
          </div>
          <EmployeeAssets
            employeeId={employeeId}
            assets={assets}
            visibility={false}
            onAssetDeleted={() => fetchEmployeeAssets(employeeId)}
          />
        </>
      )}
    </TabWrapper>
  );
}
