import React, { useCallback, useEffect, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { SidebarMenuProps } from './SidebarMenu';
import {
  StyledSidebarSubMenu,
  StyledSidebarMenuText,
} from './StyledSidebarMenu';

interface SiderbarSubMenuProps extends SidebarMenuProps {
  title: string;
  active: boolean;
  children: React.ReactNode;
}

const SiderbarSubMenu: React.FC<SiderbarSubMenuProps> = ({
  collapsed,
  title,
  active,
  children,
}) => {
  const [visible, setVisible] = useState(false);

  const toogleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  useEffect(() => {
    if (active) {
      setVisible(active);
    }
  }, [active]);

  return (
    <>
      <StyledSidebarSubMenu onClick={toogleVisibility}>
        {!collapsed && <StyledSidebarMenuText>{title}</StyledSidebarMenuText>}
        {collapsed ? null : <>{visible ? <UpOutlined /> : <DownOutlined />}</>}
      </StyledSidebarSubMenu>
      {(collapsed || visible) && children}
    </>
  );
};

export default SiderbarSubMenu;
