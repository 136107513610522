import agent from 'api/agent';
import {
  ICreateOffBoardingDTO,
  IUpdateOffboardingTaskStatusDTO,
  SuccessorDto,
  UpdateTaskStatusDto,
} from 'types/Offboarding';
import { apiURLs } from './constants';

export const createOffboarding = (payload: ICreateOffBoardingDTO) =>
  agent.post(apiURLs.offboarding, payload).then((data) => data);

export const getOffboarding = (id: string) =>
  agent.get(`${apiURLs.offboarding}/${id}`);

export const getOffboardingByEmployeeId = (id: string) =>
  agent.get(`${apiURLs.offboarding}/employee/${id}`);

export const updateOffboardingTaskStatus = (
  payload: IUpdateOffboardingTaskStatusDTO
) => agent.post(`${apiURLs.offboarding}/update-task-status`, payload);

export const completeSuccessorTask = (
  offboardingId: string,
  body: SuccessorDto
) => agent.patch(`${apiURLs.offboardingTask}/${offboardingId}/successor`, body);

export const updateTaskStatus = (body: UpdateTaskStatusDto) =>
  agent.post(`${apiURLs.offboarding}/update-task-status`, body);

export const getEmployeeOffBoardingDoc = (employeeId: string) =>
  agent.get(`${apiURLs.offboardingTask}/unsigned/${employeeId}`);

export const signEmployeeOffboardingNDA = (employeeId: string) =>
  agent.post(`${apiURLs.offboardingTask}/sign/${employeeId}`);

export const getSignedDocument = (employeeId: string) =>
  agent.get(`${apiURLs.offboardingTask}/signed/${employeeId}`);

export const getExitInterview = (offboardingId: string) =>
  agent.get(`${apiURLs.offboarding}/getExitInverview/${offboardingId}`);
