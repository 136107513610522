import { Reducer } from 'react';
import { Action } from 'redux';
import { AssetCategories, AssetCategoryState } from './types';

const initialState: AssetCategoryState = {
  data: {
    data: [],
    meta: [],
  },
  subCategories: {
    data: [],
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const reducer: Reducer<AssetCategoryState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case AssetCategories.FETCH_ASSET_CATEGORIES_START:
      state = { ...state, loading: true };

      break;
    case AssetCategories.FETCH_ASSET_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case AssetCategories.FETCH_ASSET_CATEGORIES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case AssetCategories.FETCH_ASSET_SUBCATEGORIES_START:
      state = {
        ...state,
        subCategories: {
          ...state.subCategories,
          loading: true,
        },
      };
      break;
    case AssetCategories.FETCH_ASSET_SUBCATEGORIES_SUCCESS:
      state = {
        ...state,
        subCategories: {
          ...state.subCategories,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };
      break;
    case AssetCategories.FETCH_ASSET_SUBCATEGORIES_ERRORED:
      state = {
        ...state,
        subCategories: {
          ...state.subCategories,
          loading: false,
          loaded: false,
          errored: true,
          error: action.payload,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { reducer as AssetCategoriesReducer };
