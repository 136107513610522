export const CANDIDATE_SEARCH_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'currentJobTitle',
];

export const OPEN_POSITION_SEARCH_FIELDS = ['name'];

export const INTERVIEW_SEARCH_FIELDS = ['comments'];

export const EMPLOYE_SEARCH_FIELDS = ['firstName', 'lastName', 'email', 'ssn'];

export const EVALUATION_SEARCH_FIELDS = ['employee'];

export const USER_SEARCH_FIELDS = ['firstName', 'lastName', 'email'];

export const TRAINING_SEARCH_FIELDS = [
  'type',
  'name',
  'certificationName',
  'skills',
];

export const DEPARTMENT_SEARCH_FIELDS = ['name'];

export const PROJECT_SEARCH_FIELDS = ['name', 'description'];

export const ONBOARDING_SEARCH_FIELDS = ['name'];

export const WORK_POSITION_SEARCH_FIELDS = ['name'];

export const QUESTIONNAIRES_SEARCH_FIELDS = ['department'];

export const IDEABOX_SEARCH_FIELDS = ['employee'];

export const ASSET_SEARCH_FIELDS = [
  'name',
  'type',
  'description',
  'serialNumber',
];

export const CERTIFICATION_SEARCH_FIELDS = ['name'];

export const VENDOR_SEARCH_FIELDS = [
  'companyName',
  'email',
  'contactPerson',
  'taxId',
];
