import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchVendors } from 'redux/vendor/action';
import useHeader from 'ui-v2/hooks/useHeader';
import { useVendorsData } from 'ui-v2/hooks/useVendorsData';
import Content from 'ui-v2/components/Content';
import AddVendorForm from 'components/NewForms/VendorModalForm';
import { deleteVendor } from 'api/vendorService';

export default function VendorsList() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { columns, take } = useHeader();
  const { list: vendors, loading, pagination } = useVendorsData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    if (searchParams.get('add-vendor') === 'true') {
      searchParams.delete('add-vendor');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-vendor')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(fetchVendors({ page: 1, take }));
  }, []);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchVendors(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchVendors(params));
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteVendor(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={vendors}
          paginationName="Vendors"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          showSearch
          onSelectedDelete={onDelete}
          onSearch={onSearch}
        />
        {modalVisible && (
          <AddVendorForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-vendor');
              setSearchParams(searchParams);
            }}
          />
        )}
      </>
    </Content>
  );
}
