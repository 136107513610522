import React from 'react';

export const DepartmentsIcon = () => (
  <svg
    width="20"
    height="9.88"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3578 1.37768C11.2266 1.31209 11.161 1.27929 11.0922 1.26639C11.0313 1.25495 10.9687 1.25495 10.9078 1.26639C10.839 1.27929 10.7734 1.31209 10.6422 1.37768L1 6.1988L10.6422 11.0199C10.7734 11.0855 10.839 11.1183 10.9078 11.1312C10.9687 11.1426 11.0313 11.1426 11.0922 11.1312C11.161 11.1183 11.2266 11.0855 11.3578 11.0199L21 6.1988L11.3578 1.37768Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
