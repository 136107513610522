import { Task, TaskUpdate } from 'types/Task';
import agent from 'api/agent';
import { apiURLs } from './constants';

export const createTask = async (values: Task) =>
  agent.post(apiURLs.tasks, values);

export const getTask = async (id: string) =>
  agent.get(`${apiURLs.tasks}/${id}`);

export const getInterviewTasks = async (id: string) =>
  agent.get(`${apiURLs.tasks}/interview/${id}`);

export const updateTask = async (id: string, values: TaskUpdate) =>
  agent.patch(`${apiURLs.tasks}/${id}`, values);
