/* eslint-disable indent */
import { Dispatch } from 'redux';
import { getAllNotifications } from 'api/notificationService';
import { Notifications } from './types';

export const fetchNotifications = () => (dispatch: Dispatch) => {
  dispatch({
    type: Notifications.FETCH_NOTIFICATIONS_START,
  });
  getAllNotifications()
    .then((res) => {
      dispatch({
        type: Notifications.FETCH_NOTIFICATIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Notifications.FETCH_NOTIFICATIONS_ERRORED,
        payload: e,
      });
    });
};

export const pushNotification = (item: any) => (dispatch: Dispatch) => {
  dispatch({
    type: Notifications.PUSH_NOTIFICATION_START,
    payload: item,
  });
};
