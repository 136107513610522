/*eslint-disable*/
import React from 'react';
import i18next from '../i18n';

class AppLayer extends React.Component {
  constructor(props: any, context: any) {
    super(props, context);

    this.state = {};
  }

  static throwError(message?: string, cb?: () => void) {
    this.showPopover(message, cb);
  }

  static showPopover(message?: string, cb?: () => void) {
    const body = document.getElementsByTagName('body')[0];
    const popoverBackdrop = document.getElementById('app-popover-backdrop');
    const popover = document.getElementById('app-popover-content');
    const messageTag = document.getElementById('app-popover-message');
    const btn = document.getElementById('app-popover-btn');

    if (body) {
      body.style.overflow = 'hidden';
    }

    if (popoverBackdrop) {
      popoverBackdrop.style.display = 'block';
      popoverBackdrop.addEventListener('click', () => {
        popoverBackdrop.style.display = 'none';
        if (popover) {
          popover.style.display = 'none';
        }
        body.style.overflow = 'scroll';
      });
    }

    if (popover) {
      popover.style.display = 'block';
      popover.style.opacity = '1';
      if (messageTag) {
        messageTag.innerText = message || i18next.t('somethingWentWrong');
      }

      if (btn) {
        btn.addEventListener('click', () => {
          if (cb) {
            cb();
          }
          if (popoverBackdrop) {
            popoverBackdrop.style.display = 'none';
          }
          popover.style.display = 'none';
          body.style.overflow = 'scroll';
        });
      }
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default AppLayer;
