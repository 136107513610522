import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchSinglePayrollRule } from 'redux/payrollRules/actions';

export function usePayrollRuleData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.payrollRules
  );
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    data: singlePayrollRule,
    loading: singlePayrollRuleLoading,
    loaded: singlePayrollRuleLoaded,
    errored: singlePayrollRuleErrored,
    error: singlePayrollRuleError,
  } = useSelector((state: any) => state.payrollRule);
  const id =
    (searchParams.get('id') as string) ||
    (searchParams.get('edit-rule') as string);

  useEffect(() => {
    if (id) {
      dispatch(fetchSinglePayrollRule(id));
    }
  }, [id]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,

    singlePayrollRule,
    singlePayrollRuleLoading,
    singlePayrollRuleLoaded,
    singlePayrollRuleErrored,
    singlePayrollRuleError,
  };
}
