import React, { useEffect, useState } from 'react';
import { Avatar, Col, List, Row } from 'antd';
import { ViewFeedbackIcon } from 'Icons/ViewFeedbackIcon';
import { EmployeeEvaluationDataItem } from 'types/Evaluation';

import GenericModal from 'ui-v2/components/GenericModal';
import { getEvaluation } from 'api/evaluationService';

import { toast } from 'react-toastify';
import { EmployeeName } from 'ui-v2/components/EmployeeEvaluationDetails/EmployeeEvaluationItem/EmployeeEvaluationItemStyled';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Styled from './ViewEvaluationFeedbackModalStyles';

export default function ViewEvaluationFeedbackModal({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [evaluation, setEvaluation] = useState<any>();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const evaluationId = searchParams.get('view-evaluation');

  async function fetchEvaluationById(id: string) {
    setLoading(true);
    getEvaluation(id)
      .then((response) => {
        setEvaluation(response.data);
      })
      .catch(() => {
        toast.error("Couldn't get evaluation!");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchEvaluationById(evaluationId || '');
  }, []);

  return (
    <GenericModal
      title={t('viewFeedback')}
      open={open}
      closeModal={closeModal}
      icon={<ViewFeedbackIcon />}
    >
      <>
        <Row gutter={[0, 20]}>
          {/* <Col span={24}>
            <Row gutter={[0, 4]}>
              <Col span={24}>
                <Styled.Label>Employee Feedback</Styled.Label>
              </Col>
              {evaluation?.employeeFeedback ? (
                <List
                  itemLayout="horizontal"
                  loading={loading}
                  dataSource={evaluation.emplyeeFeedback}
                  style={{
                    width: '100%',
                  }}
                  renderItem={(item: EmployeeEvaluationDataItem) => {
                    if (!item) return null;
                    return (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              style={{
                                color: '#f56a00',
                                backgroundColor: '#fde3cf',
                              }}
                            />
                          }
                          description={
                            <Styled.Description>
                              {item.employeeFeedback ?? ''}
                            </Styled.Description>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              ) : (
                <Styled.TextStyle>No evaluation yet</Styled.TextStyle>
              )}
            </Row>
          </Col> */}

          <Col span={24}>
            <Row gutter={[0, 4]}>
              <Col span={24}>
                <Styled.Label>{t('feedback')}</Styled.Label>
              </Col>
              <List
                itemLayout="horizontal"
                loading={loading}
                dataSource={evaluation?.feedback}
                style={{
                  width: '100%',
                }}
                renderItem={(item: EmployeeEvaluationDataItem) => {
                  if (!item) return null;
                  return (
                    <List.Item key={item?.id}>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              color: '#f56a00',
                              backgroundColor: '#fde3cf',
                            }}
                          >
                            {`${item?.evaluator?.firstName
                              ?.substring(0, 1)
                              ?.toUpperCase()}${item?.evaluator?.lastName
                              ?.substring(0, 1)
                              ?.toUpperCase()}`}
                          </Avatar>
                        }
                        title={
                          <EmployeeName>
                            {`${item?.evaluator?.firstName} ${item?.evaluator?.lastName}`}
                          </EmployeeName>
                        }
                        description={
                          <Styled.Description>
                            {item?.feedback ?? ''}
                          </Styled.Description>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </Row>
          </Col>
        </Row>
      </>
    </GenericModal>
  );
}
