import agent from 'api/agent';
import { BenefitPlanInterface, BenefitTemplate } from 'types/Benefit';
import { QueryParamsType } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getBenefitTemplates = (queryParams?: QueryParamsType) =>
  agent.post(`${apiURLs.benefitTemplates}/get`, queryParams);

export const getOneBenefitTemplate = (benefitTemplateId: string) =>
  agent.get(`${apiURLs.benefitTemplates}/${benefitTemplateId}`);
export const createBenefitTemplate = (payload: BenefitPlanInterface) =>
  agent.post(`${apiURLs.benefitTemplates}`, payload);

export const deleteBenefitTemplate = (benefitTemplateId: string) =>
  agent.delete(`${apiURLs.benefitTemplates}/${benefitTemplateId}`);

export const assignBenefitTemplateToEmployee = (payload: any) =>
  agent.post(`${apiURLs.benefits}`, payload);

export const getEmployeeBenefit = (employeeId: string) =>
  agent.get(`${apiURLs.benefits}/${employeeId}`);

export const getEmployeeAvailableBenefitTemplates = (employeeId: string) =>
  agent.get(`${apiURLs.benefitTemplates}/employee/${employeeId}`);

export const getBenefitTemplatePlans = (templateId: string) =>
  agent.get(`${apiURLs.benefitTemplatePlans}/${templateId}`);

export const upgradeEmployeeBenefitPlan = (payload: any) =>
  agent.put(`${apiURLs.benefits}`, payload);

export const updateBenefitTemplate = (
  templateId: string,
  payload: BenefitTemplate
) => agent.put(`${apiURLs.benefitTemplates}/${templateId}`, payload);

export const fetchUnselectedWorkPositions = (
  benefitTemplateId: string | undefined
) =>
  agent.get(
    `${apiURLs.benefitTemplates}/unselected-work-positions/${
      benefitTemplateId ?? ''
    }`
  );

export const findBenefitTemplatePlanCurrncies = async (id: string) =>
  agent.get(`${apiURLs.benefitTemplates}/${id}`);

export const findRequestBenefitByEmployeeId = (employeeId: string) =>
  agent.get(`${apiURLs.requestBenefits}/${employeeId}`);

export const rejectBenefitRequest = (id: string) =>
  agent.patch(`${apiURLs.requestBenefits}/reject/${id}`);
