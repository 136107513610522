import React from 'react';
import { StyledButton } from 'components/NewForms/FormStyled';
import { ConfigureIcon } from 'Icons/ConfigureIcon';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import ConfigureComponent from './ConfigreComponent';
import {
  PopoverOverlayInnerStyles,
  PopoverOverlayStyles,
} from '../TableFilterPopover/TableFilterPopoverStyles';

export default function ConfigurePopover() {
  const { t } = useTranslation();

  return (
    <Popover
      overlayClassName="popover-content"
      overlayStyle={PopoverOverlayStyles}
      overlayInnerStyle={PopoverOverlayInnerStyles}
      content={<ConfigureComponent />}
      trigger="click"
    >
      <StyledButton id="configure-popover" icon={<ConfigureIcon />}>
        {t('configure')}
      </StyledButton>
    </Popover>
  );
}
