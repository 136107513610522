import { Reducer } from 'react';
import { Action } from 'redux';

import { OffboardingType, OffboardingState } from './types';

const initialState: OffboardingState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const OffboardingReducer: Reducer<OffboardingState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case OffboardingType.FETCH_OFFBOARDING_START:
      state = { ...state, loading: true };
      break;
    case OffboardingType.FETCH_OFFBOARDING_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case OffboardingType.FETCH_OFFBOARDING_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case OffboardingType.CLEAR_OFFBOARDING:
      state = {
        ...state,
        data: null,
        loaded: false,
        loading: false,
        error: null,
        errored: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { OffboardingReducer };
