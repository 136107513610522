import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { OnboardingModelType } from 'types/Onboarding';

export function useOnboardingsData() {
  const [searchParams] = useSearchParams();

  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.onBoardings
  );

  const {
    data: singleOnboarding,
    loading: singleOnboardingLoading,
    loaded: singleOnboardingLoaded,
    errored: singleOnboardingErrored,
    error: singleOnboardingError,
  } = useSelector((state: any) => state.singleOnboarding);

  const id = searchParams.get('edit-onboarding');

  return {
    onboarding: {
      data:
        singleOnboarding ??
        data?.data?.find((item: OnboardingModelType) => item.id === id),
      loading: singleOnboardingLoading,
      loaded: singleOnboardingLoaded,
      errored: singleOnboardingErrored,
      error: singleOnboardingError,
    },
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
  };
}
