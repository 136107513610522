import { deleteTraining, getTraining } from 'api/trainingService';
import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchTrainings } from 'redux/trainings/actions';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTrainingsData } from 'ui-v2/hooks/useTrainingsData';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useSearchParams } from 'react-router-dom';
import { TrainingType } from 'types/Training';
import NewTrainingForm from 'components/NewForms/NewTrainingForm';

export default function TrainingsList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useTrainingsData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [trainingSelected, setTrainingSelected] = useState<TrainingType>();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTrainings({ page: 1, take }));
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchTrainings(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchTrainings(params));
    setSearchTerm(searchTerm);
  };

  async function deleteTrainingById(id: string | null) {
    if (!id) return;
    deleteTraining(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Training deleted successfully!');
          let currentPage = pagination?.page;
          if (list.length === 1) {
            currentPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
          }
          dispatch(fetchTrainings({ page: currentPage, take }));
        }
      })
      .catch(toastErrorMessages);
  }

  const fetchTrainingById = async (id: string | null) => {
    if (!id) return;
    try {
      setModalLoading(true);
      const response = await getTraining(id);
      if (response.status === 200) {
        setTrainingSelected(response.data);
        setModalLoading(false);
      }
    } catch (error) {
      toast.error('Asset failed to fetch by Id!');
      setModalLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get('add-training')) {
      searchParams.delete('add-training');
      setSearchParams(searchParams);
      setTrainingSelected(undefined);
      setModalVisible(true);
    }
    if (searchParams.get('edit-training')) {
      setModalVisible(true);
      fetchTrainingById(searchParams.get('edit-training') ?? '');
      setSearchParams(searchParams);
    }
    if (searchParams.get('delete-training')) {
      deleteTrainingById(searchParams.get('delete-training'));
      searchParams.delete('delete-training');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteTrainingById(id?.toString());
    });
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={list}
          paginationName="Trainings"
          rowSelectionType="checkbox"
          onPageChange={onPageChange}
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onSelectedDelete={onDelete}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <NewTrainingForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-training');
              setSearchParams(searchParams);
            }}
            selectedTraining={trainingSelected}
            modalLoading={modalLoading}
          />
        )}
      </>
    </Content>
  );
}
