/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Row } from 'antd';

import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from 'ui-v2/components/Employees/ProjectInformation/ProjectInformationItem';
import { TextStyle } from 'ui-v2/components/DataGridComponents';
import { CloseIcon } from 'Icons/CloseIcon';
import Content from 'ui-v2/components/Content';
import { useOnboardingsData } from 'ui-v2/hooks/useOnboardingsData';
import { useTranslation } from 'react-i18next';

export default function OnboardingInformation() {
  const navigate = useNavigate();
  const {
    onboarding: { data: onboardingDetails },
  } = useOnboardingsData();
  const { t } = useTranslation();

  return (
    <Content position="stretch">
      <CardItem title={t('details')}>
        <Row gutter={[0, 16]}>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 24 }} sm={{ span: 24 }} xl={24} xxl={24}>
              <Row>
                <Item
                  label={t('name')}
                  value={onboardingDetails?.name}
                  children={undefined}
                />
              </Row>
            </Col>
          </Styled.RowItem>
          <Styled.RowItem last>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Row>
                <Col span={24}>
                  <Styled.ItemLabel>{t('department')}</Styled.ItemLabel>
                </Col>
                {onboardingDetails?.department?.id ? (
                  <Styled.ItemValue>
                    {onboardingDetails?.department?.name}
                  </Styled.ItemValue>
                ) : (
                  <TextStyle>
                    <CloseIcon />
                    {t('noDepartmentSelected')}
                  </TextStyle>
                )}
              </Row>
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Row>
                <Col span={24}>
                  <Styled.ItemLabel>{t('workPosition')}</Styled.ItemLabel>
                </Col>
                {onboardingDetails?.workPosition?.id ? (
                  <LinkButton
                    onClick={() =>
                      navigate(
                        `/work-positions/work-position-details?id=${onboardingDetails?.workPosition?.id}`
                      )
                    }
                    type="link"
                  >
                    {onboardingDetails?.workPosition?.name}
                  </LinkButton>
                ) : (
                  <TextStyle>
                    <CloseIcon />
                    {t('noWorkPositionSelected')}
                  </TextStyle>
                )}
              </Row>
            </Col>
          </Styled.RowItem>
        </Row>
      </CardItem>
    </Content>
  );
}
