import { getOpenPositionById } from 'api/openPositions';
import OpenPositionForm from 'components/NewForms/OpenPositionForm/OpenPositionForm';
import { t } from 'i18next';
import { OpenPositionType } from 'interfaces/OpenPosition';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Content from 'ui-v2/components/Content';

export default function EditOpenPosition() {
  const [openPositionData, setOpenPositionData] = useState<OpenPositionType>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const getOpenPositionData = async (id: string) => {
    if (id) {
      getOpenPositionById(id)
        .then((response) => {
          const { data } = response;
          setOpenPositionData(data);
        })
        .catch(() => {
          toast.error(t('errorFetchingOpenPosition'));
        });
    }
  };

  useEffect(() => {
    if (id) {
      getOpenPositionData(id);
    }
  }, [id]);

  return (
    <Content position="center">
      <OpenPositionForm openPosition={openPositionData} />
    </Content>
  );
}
