import React, { useEffect, useState } from 'react';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import CalendarPopover from 'ui-v2/components/CalendarPopover';
import { OffboardingTaskStatus } from 'types/Offboarding';
import { Avatar, Col, Row, Space, Spin } from 'antd';
import { CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getExitInterview } from 'api/offboarding';
import {
  convertUTCtoFormatedLocalTime,
  convertUTCtoLocalHour,
  isInterviewDatePassed,
  toastErrorMessages,
} from 'utils/utilFunctions';
import moment from 'moment';
import { InterviewType } from 'types/Interview';
import * as Styled from '../../CandidateProfile/InterviewList/InterviewStyles';
import TabWrapper from './TabWrapper';

export default function ExitInterview({ onNext }: { onNext: () => void }) {
  const { getTabStatus, data } = useOffboardingData();
  const [interview, setInterviewData] = useState<InterviewType | null>(null);
  const [loading, setLoading] = useState(false);

  const status = getTabStatus(OffboardingTaskType.EXIT_INTERVIEW);

  const interviewDetails = async () => {
    setLoading(true);
    try {
      const response = await getExitInterview(data?.id);
      setInterviewData(response?.data);
    } catch (error) {
      toastErrorMessages(error || 'Error fetching interview details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    interviewDetails();
  }, []);

  const handleInterviewScheduled = () => {
    interviewDetails();
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <TabWrapper onNext={onNext} type={OffboardingTaskType.EXIT_INTERVIEW}>
        <>
          {status === OffboardingTaskStatus.COMPLETED && interview && (
            <div
              style={{
                maxWidth: '600px',
                margin: '50px auto',
                textAlign: 'center',
              }}
            >
              <Space direction="vertical" size="large">
                <CheckCircleOutlined
                  style={{ fontSize: '64px', color: '#52c41a' }}
                />
                <h2>Exit Interview Scheduled</h2>
                <p>
                  The exit interview has been successfully scheduled. Please
                  ensure all necessary arrangements are made for the final
                  interview with the employee.
                </p>
              </Space>
              <Styled.List span={120}>
                <Row gutter={[0, 10]}>
                  <Styled.InterviewItem span={24}>
                    <Row justify="space-between" align="middle">
                      <Col span={3}>
                        <Row>
                          <Avatar.Group maxCount={1}>
                            {interview?.partecipants?.map((item: any) => (
                              <Avatar key={item.id}>
                                {item.firstName.substring(0, 1).toUpperCase()}
                                {item.lastName.substring(0, 1).toUpperCase()}
                              </Avatar>
                            ))}
                          </Avatar.Group>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Col>
                          <Row justify="end">
                            <Styled.InterviewItemDate
                              date={
                                isInterviewDatePassed(interview?.date)
                                  ? ''
                                  : 'passed'
                              }
                            >
                              {convertUTCtoFormatedLocalTime(interview?.date)}
                            </Styled.InterviewItemDate>
                          </Row>
                        </Col>
                        <Col>
                          <Row justify="end">
                            <Styled.InterviewItemTime
                              date={
                                isInterviewDatePassed(interview?.date)
                                  ? ''
                                  : 'passed'
                              }
                            >
                              {`${moment
                                .utc(interview?.date)
                                .format('HH:mm')} UTC ${convertUTCtoLocalHour(
                                interview?.date
                              )}`}
                            </Styled.InterviewItemTime>
                          </Row>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Styled.BadgeStyle
                          count={Number(interview?.score)}
                          offset={[20, 10]}
                          color="#a3a9b7"
                        >
                          <Styled.InterviewItemLabel>
                            {interview?.interviewType?.name}
                          </Styled.InterviewItemLabel>
                        </Styled.BadgeStyle>
                        <Styled.InterviewEmployeeLabel>
                          {interview?.partecipants
                            ?.map((i: any) => `${i.firstName} ${i.lastName}`)
                            .join(', ')}
                        </Styled.InterviewEmployeeLabel>
                      </Col>
                    </Row>
                  </Styled.InterviewItem>
                </Row>
              </Styled.List>
            </div>
          )}
          {[
            OffboardingTaskStatus.IN_PROGRESS,
            OffboardingTaskStatus.PENDING,
          ].includes(status) && (
            <>
              {interview ? (
                <div
                  style={{
                    maxWidth: '600px',
                    margin: '50px auto',
                    textAlign: 'center',
                  }}
                >
                  <CheckCircleOutlined
                    style={{ fontSize: '64px', color: '#52c41a' }}
                  />
                  <h2>Exit Interview Scheduled</h2>
                  <Styled.List span={120}>
                    <Row gutter={[0, 10]}>
                      <Styled.InterviewItem span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={3}>
                            <Row>
                              <Avatar.Group maxCount={1}>
                                {interview?.partecipants?.map((item: any) => (
                                  <Avatar key={item.id}>
                                    {item.firstName
                                      .substring(0, 1)
                                      .toUpperCase()}
                                    {item.lastName
                                      .substring(0, 1)
                                      .toUpperCase()}
                                  </Avatar>
                                ))}
                              </Avatar.Group>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Col>
                              <Row justify="end">
                                <Styled.InterviewItemDate
                                  date={
                                    isInterviewDatePassed(interview?.date)
                                      ? ''
                                      : 'passed'
                                  }
                                >
                                  {convertUTCtoFormatedLocalTime(
                                    interview?.date
                                  )}
                                </Styled.InterviewItemDate>
                              </Row>
                            </Col>
                            <Col>
                              <Row justify="end">
                                <Styled.InterviewItemTime
                                  date={
                                    isInterviewDatePassed(interview?.date)
                                      ? ''
                                      : 'passed'
                                  }
                                >
                                  {`${moment
                                    .utc(interview?.date)
                                    .format(
                                      'HH:mm'
                                    )} UTC ${convertUTCtoLocalHour(
                                    interview?.date
                                  )}`}
                                </Styled.InterviewItemTime>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Styled.BadgeStyle
                              count={Number(interview?.score)}
                              offset={[20, 10]}
                              color="#a3a9b7"
                            >
                              <Styled.InterviewItemLabel>
                                {interview?.interviewType?.name}
                              </Styled.InterviewItemLabel>
                            </Styled.BadgeStyle>
                            <Styled.InterviewEmployeeLabel>
                              {interview?.partecipants
                                ?.map(
                                  (i: any) => `${i.firstName} ${i.lastName}`
                                )
                                .join(', ')}
                            </Styled.InterviewEmployeeLabel>
                          </Col>
                        </Row>
                      </Styled.InterviewItem>
                    </Row>

                    <Space direction="vertical" size="large">
                      <p>
                        The exit interview has been successfully scheduled.
                        Please ensure all necessary arrangements are made for
                        the final interview with the employee.
                      </p>
                    </Space>
                  </Styled.List>
                </div>
              ) : (
                <div
                  style={{
                    maxWidth: '600px',
                    margin: '50px auto',
                    textAlign: 'center',
                  }}
                >
                  <Space direction="vertical" size="large">
                    <CalendarOutlined
                      style={{ fontSize: '64px', color: '#faad14' }}
                    />
                    <h2>Schedule Exit Interview</h2>
                    <CalendarPopover
                      offboarding
                      onInterviewScheduled={handleInterviewScheduled}
                    />
                    <p>
                      The exit interview has not been scheduled yet. Please set
                      a date to conduct the final interview with the employee.
                    </p>
                  </Space>
                </div>
              )}
            </>
          )}
        </>
      </TabWrapper>
    </Spin>
  );
}
