import { EmployeeType } from 'types/Employee';
import { MetaPagination } from 'types/GridTable';

export enum Employees {
  // EMPLOYEES
  FETCH_EMPLOYEES_START = 'FETCH_EMPLOYEES_START',
  FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS',
  FETCH_EMPLOYEES_ERRORED = 'FETCH_EMPLOYEES_ERRORED',
  // SPECIFIC EMPLOYEE DETAILS
  FETCH_EMPLOYEES_DETAIL_START = 'FETCH_EMPLOYEES_DETAIL_START',
  FETCH_EMPLOYEES_DETAIL_SUCCESS = 'FETCH_EMPLOYEES_DETAIL_SUCCESS',
  FETCH_EMPLOYEES_DETAIL_ERRORED = 'FETCH_EMPLOYEES_DETAIL_ERRORED',
  // SPECIFIC EMPLOYEE DOCUMENTS
  FETCH_EMPLOYEE_DOCUMENTS_START = 'FETCH_EMPLOYEE_DOCUMENTS_START',
  FETCH_EMPLOYEE_DOCUMENTS_SUCCESS = 'FETCH_EMPLOYEE_DOCUMENTS_SUCCESS',
  FETCH_EMPLOYEE_DOCUMENTS_ERRORED = 'FETCH_EMPLOYEE_DOCUMENTS_ERRORED',
  // SPECIFIC EMPLOYEE ASSIGNMENTS
  FETCH_EMPLOYEE_ASSIGNMENTS_START = 'FETCH_EMPLOYEE_ASSIGNMENTS_START',
  FETCH_EMPLOYEE_ASSIGNMENTS_SUCCESS = 'FETCH_EMPLOYEE_ASSIGNMENTS_SUCCESS',
  FETCH_EMPLOYEE_ASSIGNMENTS_ERRORED = 'FETCH_EMPLOYEE_ASSIGNMENTS_ERRORED',
  CLEAR_SINGLE_EMPLOYEE = 'CLEAR_SINGLE_EMPLOYEE',

  FETCH_BULK_UPLOAD_START = 'FETCH_BULK_UPLOAD_START',
  FETCH_BULK_UPLOAD_SUCCESS = 'FETCH_BULK_UPLOAD_SUCCESS',
  FETCH_BULK_UPLOAD_ERRORED = 'FETCH_BULK_UPLOAD_ERRORED',
}

export interface EmployeesState {
  data: {
    data: Array<EmployeeType>;
    meta: Array<MetaPagination>;
  };
  documents: {
    data: DocumentType[];
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  employeeDetail: any;
  employeeAssignments: any;
  employeeAssignmentsLoading: boolean;
  employeeAssignmentsLoaded: boolean;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
