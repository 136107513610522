import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import OnBoardingActions from 'components/Actions/OnBoardingActions';
import { CloseIcon } from 'Icons/CloseIcon';
import { OnboardingModelType } from 'types/Onboarding';
import i18next from '../i18n';

export const OnboardingColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    default: true,
    ellipsis: true,
    render: (_: string, item: OnboardingModelType) => (
      <Styled.TextBold>{item.name}</Styled.TextBold>
    ),
    order: 0,
  },
  {
    title: i18next.t('department'),
    dataIndex: 'department',
    key: 'department',
    default: true,
    render: (_: string, item: OnboardingModelType) => (
      <Styled.Text>
        {item?.department?.id ? (
          item.department.name
        ) : (
          <Styled.TextStyle>
            <CloseIcon />
            {i18next.t('noDepartmentSelected')}
          </Styled.TextStyle>
        )}
      </Styled.Text>
    ),
    order: 1,
  },
  {
    title: i18next.t('workPosition'),
    dataIndex: 'workPosition',
    key: 'workPosition',
    default: true,
    render: (_: string, item: OnboardingModelType) => (
      <Styled.Text>
        {item?.workPosition?.name ? (
          item.workPosition.name
        ) : (
          <Styled.TextStyle>
            <CloseIcon />
            {i18next.t('noWorkPositionSelected')}
          </Styled.TextStyle>
        )}
      </Styled.Text>
    ),
    order: 2,
  },
  {
    title: i18next.t('action'),
    key: 'action',
    width: 150,
    render: (record: string, row: OnboardingModelType) => (
      <OnBoardingActions id={row.id} />
    ),
    default: false,
    order: 3,
    fixed: 'right',
  },
];
