import { Reducer } from 'react';
import { Action } from 'redux';
import { VendorCategories, VendorCategoryState } from './types';

const initialState: VendorCategoryState = {
  data: {
    data: [],
    meta: null,
  },
  vendorCategoryDetail: null,
  loaded: false,
  loading: false,
  errored: false,
  error: null,
};

const reducer: Reducer<VendorCategoryState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case VendorCategories.FETCH_VENDOR_CATEGORIES_START:
      state = { ...state, loading: true };

      break;

    case VendorCategories.FETCH_VENDOR_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case VendorCategories.FETCH_VENDOR_CATEGORIES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;

    case VendorCategories.FETCH_VENDOR_CATEGORY_START:
      state = { ...state, loading: true };
      break;
    case VendorCategories.FETCH_VENDOR_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        vendorCategoryDetail: action.payload,
      };
      break;
    case VendorCategories.FETCH_VENDOR_CATEGORY_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;

    case VendorCategories.CLEAR_VENDOR_CATEGORY_STATE:
      state = { ...state, vendorCategoryDetail: null };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as VendorCategoryReducer };
