import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const createSkill = async (values: any) =>
  agent.post(apiURLs.skillsCategory, values);

export const createSubSkill = async (values: any) =>
  agent.post('/skills-sub-category', values);

export const updateSubSkill = async (id: string, values: any) =>
  agent.patch(`/skills-sub-category/${id}`, values);

export const getSkills = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.skillsCategory}/get`, queryParams);

export const getSingleSkill = (id: string) =>
  agent.get(`${apiURLs.skillsCategory}/${id}`);

export const deleteSkill = async (id: string) =>
  agent.delete(`${apiURLs.skillsCategory}/${id}`);

export const updateSkill = async (id: string, values: any) =>
  agent.patch(`${apiURLs.skillsCategory}/${id}`, values);

export const getSkillsForCategories = async (id: string) =>
  agent.get(`${apiURLs.skillsCategory}/sub-category/${id}`);

export const deleteSkillSubCategory = async (id: string) =>
  agent.delete(`/skills-sub-category/${id}`);
