import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton, Space } from 'antd';
import { EdititingIcon } from 'Icons/EditIcon';
import React, { useState } from 'react';
import { WorkExperience } from 'types/Candidates';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';

import CardItem from '../Card';
import WorkExperienceModal from './WorkExperienceModal';
import * as Styled from './WorkExperienceStyles';
import { ButtonStyle } from './WorkExperienceStyles';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import i18next from '../../../i18n';

interface WorkExperienceProps {
  isDisabled: boolean;
}

export const Item = ({
  label,
  value,
  company,
  setWorkExperience,
}: {
  label: string;
  value: string;
  company: string;
  setWorkExperience?: () => void;
}) => (
  <Row gutter={[0, 0]} justify="space-between" align="top">
    <Col>
      <Space direction="vertical">
        <Styled.ItemLabel>
          {`${label}`}
          <Styled.ItemCompany>{` @${company}`}</Styled.ItemCompany>
        </Styled.ItemLabel>
        <Styled.ItemValue>{value}</Styled.ItemValue>
      </Space>
    </Col>
    <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
      <Col>
        <ButtonStyle
          icon={<EdititingIcon />}
          size="large"
          onClick={setWorkExperience}
        >
          {i18next.t('edit')}
        </ButtonStyle>
      </Col>
    </RoleBasedButton>
  </Row>
);

export default function WorkExperienceCard({
  isDisabled,
}: WorkExperienceProps) {
  const { candidate, loading } = useCandidateData();
  const [workExperience, setWorkExperience] = useState<WorkExperience>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const experience: Array<WorkExperience> | [] = candidate?.experience ?? [];

  const onToggleModal = () => setModalVisible((state) => !state);

  const getValue = (startDate: any, endDate: any) => {
    const formatedStartDate = convertUTCtoFormatedLocalTime(startDate);
    const formatedEndDate = convertUTCtoFormatedLocalTime(endDate);
    return `${formatedStartDate} - ${endDate ? formatedEndDate : 'Present'}`;
  };

  const { t } = useTranslation();

  return (
    <CardItem title={t('workExperience')} disabled={isDisabled}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        {experience.length > 0 ? (
          <Row gutter={[0, 16]}>
            {experience.map((workExp: WorkExperience, index: any) => (
              <Styled.RowItem
                key={workExp.id}
                last={index === experience.length - 1}
              >
                <Col span={24}>
                  <Item
                    label={workExp.workTitle}
                    company={workExp.companyName}
                    value={getValue(workExp.startDate, workExp.endDate)}
                    setWorkExperience={() => {
                      setWorkExperience(
                        experience.find((e) => e.id === workExp.id)
                      );
                      setModalVisible(true);
                      setIsEditing(true);
                    }}
                  />
                </Col>
              </Styled.RowItem>
            ))}
            <Styled.RowItem last justify="center">
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col>
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="large"
                    style={{
                      borderRadius: '8px',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '344054',
                    }}
                    onClick={onToggleModal}
                  >
                    {t('addworkExperience')}
                  </Button>
                </Col>
              </RoleBasedButton>
            </Styled.RowItem>
          </Row>
        ) : (
          <Row gutter={[0, 24]}>
            <Styled.RowItem justify="center" last>
              <Col>
                <Styled.NodataLabel>
                  {t('this candidate has no work experience yet')}
                </Styled.NodataLabel>
              </Col>
            </Styled.RowItem>
            <Styled.RowItem last justify="center">
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col>
                  <Row justify="center">
                    <Button
                      icon={
                        <Styled.PlusIcon>
                          <PlusCircleOutlined />
                        </Styled.PlusIcon>
                      }
                      size="large"
                      style={{
                        borderRadius: '8px',
                        width: '100%',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '344054',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={onToggleModal}
                    >
                      {t('addworkExperience')}
                    </Button>
                  </Row>
                </Col>
              </RoleBasedButton>
            </Styled.RowItem>
          </Row>
        )}
        {modalVisible && (
          <WorkExperienceModal
            open={modalVisible}
            workExperience={isEditing ? workExperience : undefined}
            isEditing={isEditing}
            closeModal={() => {
              setModalVisible(false);
              setIsEditing(false);
              setWorkExperience(undefined);
            }}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
