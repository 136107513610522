/* eslint-disable react/no-children-prop */
import { Col, Button, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Item } from 'ui-v2/components/PersonalInfo';
import { RowItem } from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';

interface IProps {
  projectId: string;
  description: string;
  hireDate: string;
  project: string;
  isLast?: boolean;
}

export const LinkButton = styled(Button)`
  font-size: 1rem !important;
  padding: 0px;
`;

export default function ProjectInformationItem({
  projectId,
  description,
  hireDate,
  project,
  isLast = false,
}: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <RowItem last={isLast} gutter={[0, 10]}>
      <Col md={{ span: 11 }} sm={{ span: 24 }}>
        <Item
          label={t('project')}
          children={
            <LinkButton
              onClick={() =>
                navigate(`/projects/project-details?id=${projectId}`)
              }
              type="link"
            >
              {project}
            </LinkButton>
          }
        />
      </Col>
      <Col md={{ span: 5 }} sm={{ span: 24 }}>
        <Item
          label={t('projectStartDate')}
          value={hireDate}
          children={undefined}
        />
      </Col>
      <Col md={{ span: 8 }} sm={{ span: 24 }}>
        <Item
          label={t('description')}
          children={
            <Typography
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {description}
            </Typography>
          }
        />
      </Col>
    </RowItem>
  );
}
