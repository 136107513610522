import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { EmployeeTrainingStatus } from '../../../types/EmployeeTask';

export const RowItemAsset = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
`;

export const RowItemEmployee = styled(Row)`
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 3.25rem;
  align-items: center;
  padding-left: 0.625rem;
  margin-bottom: 10px;
`;

export const NodataLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;
export const TrainingStatusTag = styled.div<{
  status: string;
}>`
  width: fit-content;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ status }) =>
    status === EmployeeTrainingStatus.STARTED && 'background-color: #c9ffe1;'};
  ${({ status }) =>
    status === EmployeeTrainingStatus.ATTENDING &&
    'background-color: #00ffff23;'};
  ${({ status }) =>
    status === EmployeeTrainingStatus.COMPLETED &&
    'background-color: #eaecf0;'};

  p {
    margin-bottom: 0;
    margin-left: 6px;
  }
`;

export const EmployeeName = styled(Typography)`
  font-weight: 400;
  text-transform: capitalize;
`;

export const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
