import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useRequestData } from 'ui-v2/hooks/useRequestData';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { fetchAllRequests } from 'redux/requests/actions';
import { TabsStyled } from 'ui-v2/components/TabsStyled';
import { useTranslation } from 'react-i18next';
import { SettingTab } from 'types/settings';
import { useSearchParams } from 'react-router-dom';
import RequestList from './RequestList';
import TimeOffRequests from '../TimeOffRequests';
import QuestionnaireFeedback from '../QuestionnaireFeedback/QuestionnaireFeedback';

export default function Requests() {
  const { requests, loading } = useRequestData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(
    searchParams?.get('tab') ?? `${SettingTab.CONFIGURATIONS}`
  );

  const onTabClick = useCallback(
    (activeKey: string) => {
      setActiveTab(activeKey);
      searchParams.set('tab', activeKey);
      setSearchParams(searchParams);
    },
    [searchParams, activeTab]
  );

  const { TabPane } = Tabs;

  useEffect(() => {
    if (loading) {
      return;
    }
    dispatch(fetchAllRequests());
  }, []);

  return (
    <Content position="strech">
      <TabsStyled defaultActiveKey={activeTab} onTabClick={onTabClick}>
        <TabPane tab={t('all')} key="1">
          <RequestList requests={requests} loading={loading} />
        </TabPane>
        <TabPane tab={t('Time Off')} key="2">
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="timeoff"
          >
            <TimeOffRequests />
          </RequestList>
        </TabPane>
        <TabPane tab={t('benefits')} key="3">
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="benefits"
          />
        </TabPane>
        <TabPane tab={t('information')} key="4">
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="information"
          />
        </TabPane>
        <TabPane tab={t('assets')} key="5">
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="assets"
          />
        </TabPane>
        <TabPane tab={t('compensation')} key="6">
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="compensation"
          />
        </TabPane>
        <TabPane tab={t('Requests')} key="7">
          <TimeOffRequests />
        </TabPane>
        <TabPane tab={t('questionnaires')} key="8">
          <QuestionnaireFeedback />
        </TabPane>
        <TabPane tab={t('other')} key="9">
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="other"
          />
        </TabPane>
      </TabsStyled>
    </Content>
  );
}
