import styled from 'styled-components';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Rate, Input } from 'antd';
import {
  StyledAddButton,
  StyledButton,
  StyledRemoveButton,
} from '../FormStyled';

export const CustomStyledRemoveButton = styled(StyledRemoveButton)`
  margin-top: 1rem;
  width: 30%;
  margin-right: 10rem;
`;

export const CustomStyledButton = styled(StyledButton)`
  margin-bottom: 1rem;
  max-width: 10rem;
`;

export const CustomStyledAddButton = styled(StyledAddButton)`
  margin-top: 1rem;
`;

export const CustomMinusCircleOutlined = styled(MinusCircleOutlined)`
  margin-top: 0.6rem;
  margin-left: 2rem;
`;

export const CustomStyledRate = styled(Rate)`
  margin-top: -0.9rem;
  margin-left: 2rem;
`;

export const CustomStyledInput = styled(Input)`
  max-width: 10rem;
  max-height: 2rem;
`;
