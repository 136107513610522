import { Dispatch } from 'redux';

import { PayrollRulesQueryParamsType } from 'types/QueryParams';
import { getPayrollRules, getSinglePayrollRule } from 'api/payrollRulesService';
import { PAYROLL_RULE } from './types';

export const fetchPayrollRules =
  (queryParams: PayrollRulesQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: PAYROLL_RULE.FETCH_PAYROLL_RULE_START,
    });
    getPayrollRules(queryParams)
      .then((res) => {
        dispatch({
          type: PAYROLL_RULE.FETCH_PAYROLL_RULE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PAYROLL_RULE.FETCH_PAYROLL_RULE_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSinglePayrollRule = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: PAYROLL_RULE.FETCH_SINGLE_PAYROLL_RULE_START,
  });

  getSinglePayrollRule(id)
    .then((res) => {
      dispatch({
        type: PAYROLL_RULE.FETCH_SINGLE_PAYROLL_RULE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: PAYROLL_RULE.FETCH_SINGLE_PAYROLL_RULE_ERRORED,
        payload: e,
      });
    });
};

export const clearPayrollRules = () => (dispatch: Dispatch) => {
  dispatch({
    type: PAYROLL_RULE.CLEAR_ALL_PAYROLL_RULE,
  });
};

export const clearSinglePayrollRule = () => (dispatch: Dispatch) => {
  dispatch({
    type: PAYROLL_RULE.CLEAR_SINGLE_PAYROLL_RULE,
  });
};
