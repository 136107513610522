import { useEffect } from 'react';

import {
  clearWorkPositionState,
  fetchSingleWorkPosition,
} from 'redux/workPositions/actions';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { WorkPositiontModelType } from 'types/WorkPosition';
import useHeader from './useHeader';

export function useWorkPositionsData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.workPositions
  );
  const dispatch = useDispatch();
  const { entity } = useHeader();

  const {
    data: workPosition,
    loading: workPositionLoading,
    loaded: workPositionLoaded,
    errored: workPositionErrored,
    error: workPositionError,
  } = useSelector((state: any) => state.singleWorkPosition);
  const [searchParams] = useSearchParams();

  const workPositionId =
    workPosition?.data?.id ??
    (searchParams.get('edit-work-position') as string);

  useEffect(() => {
    if (workPositionId && entity === 'work-position') {
      dispatch(fetchSingleWorkPosition(workPositionId));
    }

    return () => {
      dispatch(clearWorkPositionState());
    };
  }, [workPositionId]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
    workPosition: {
      data:
        workPosition ??
        data?.data?.find(
          (item: WorkPositiontModelType) => item.id === workPositionId
        ),
      loading: workPositionLoading,
      loaded: workPositionLoaded,
      errored: workPositionErrored,
      error: workPositionError,
    },
  };
}
