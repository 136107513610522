import { Button, Popover } from 'antd';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const PopoverOverlayInnerStyles: CSSProperties = {
  width: '250px',
  borderRadius: '8px',
  boxShadow:
    '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  border: '1px solid #EAECF0',
};

export const PopoverOverlayStyles = {
  paddingTop: '5px',
};

export const StyledPopoverContentButton = styled(Button)`
  &.ant-btn-link {
    color: #155eef;
  }
`;

export const StyledPopover = styled(Popover)`
  [class*='ant-form-item-label'] {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: red;
  }
`;
