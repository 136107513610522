/* eslint-disable radix */

const singleIPRegex =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const wildcardRegex =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|\*)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|\*)$/;

const isSingleIp = (value: string) => singleIPRegex.test(value);

const isWildcard = (value: string) => wildcardRegex.test(value);

export const isRangeValid = (value: string) => {
  const [startIP, endIP] = value.split('-');
  return isSingleIp(startIP?.trim()) && isSingleIp(endIP?.trim());
};

export const validateRange = (range: string) => {
  const [startIP, endIP] = range.split('-');

  const startIp = startIP.split('.');
  const startLastDigit = startIp.pop();

  const endIp = endIP.split('.');
  const endLastDigit = endIp.pop();

  if (startIp.join('.') !== endIp.join('.')) return {};
  if (Number(startLastDigit) >= Number(endLastDigit)) return {};
  return { startIP, endIP };
};

export const validateIpAddress = (value: string) => {
  if (isRangeValid(value)) {
    const { startIP } = validateRange(value);
    if (startIP) {
      return true;
    }
  } else if (isSingleIp(value) || isWildcard(value)) {
    return true;
  }
  return false;
};

export const getIPType = (value: any) => {
  if (isSingleIp(value)) return 'singleIP';
  if (isWildcard(value)) return 'wildcard';
  if (validateRange(value)) return 'range';

  return '';
};
