import {
  AddEvaluationPayload,
  EvaluationItem,
  GetEvaluationsResponse,
  PeriodUpdateType,
} from 'types/Evaluation';

import agent from 'api/agent';
import { EvaluationPeriodQueryParamType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { apiURLs } from './constants';

/**
 * Get Evaluation by ID
 * @param id Evaluation Id
 * @returns Evaluation
 */
export const getEvaluation = (id: string): Promise<{ data: EvaluationItem }> =>
  agent.get(`${apiURLs.evaluation}/${id}`);

/**
 * Get All Evaluations
 * @returns List of Evaluations
 */
// perPage, page, name
export const getEvaluations = (
  queryParams: EvaluationPeriodQueryParamType
): Promise<{ data: GetEvaluationsResponse }> => {
  const p = searchFields(queryParams, apiURLs.evaluation);
  return agent.post(`${apiURLs.evaluation}/get`, p);
};

/**
 * Add Evaluation
 * @returns Created Evaluation
 */
export const addEvaluation = (payload: AddEvaluationPayload) =>
  agent.post(apiURLs.evaluation, payload);

/**
 * Update Evaluation by ID
 * @param id Evaluation Id
 * @returns Updated Evaluation
 */
export const updateEvaluation = (id: string, periodData: PeriodUpdateType) =>
  agent.patch(`${apiURLs.evaluation}/${id}`, periodData);

/**
 * Delete Evaluation by ID
 * @param id Evaluation Id
 * @returns Evaluation
 */
export const removeEvaluation = (id: string) =>
  agent.delete(`${apiURLs.evaluation}/${id}`);
