import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SkillsState } from 'redux/skills/types';
import { useState } from 'react';

export function useSkillsData() {
  const [skillCategoryId, setSkillCategoryId] = useState<string>();
  const skillSelector: SkillsState = useSelector(
    (state: RootState) => state?.skills
  );

  return {
    ...skillSelector,
    skillCategoryId,
    setSkillCategoryId,
  };
}
