import styled from 'styled-components';

export const MonthCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6f7ff;
  height: 100%;
`;

export const TimeOffDetails = styled.div`
  width: 90%;
  margin-bottom: 50px;
`;
