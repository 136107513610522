import CandidateOfferType from 'types/CandidateOffer';
import { CandidateOpenPositionObj, CandidateType } from 'types/Candidates';
import { MetaPagination } from 'types/GridTable';
import { InterviewTypeDTO } from 'types/Interview';
import { Stage } from 'types/Stages';

export enum Candidates {
  FETCH_CANDIDATES_START = 'FETCH_CANDIDATES_START',
  FETCH_CANDIDATES_SUCCESS = 'FETCH_CANDIDATES_SUCCESS',
  FETCH_CANDIDATES_ERRORED = 'FETCH_CANDIDATES_ERRORED',

  FETCH_SINGLE_CANDIDATE_START = 'FETCH_SINGLE_CANDIDATE_START',
  FETCH_SINGLE_CANDIDATE_SUCCESS = 'FETCH_SINGLE_CANDIDATE_SUCCESS',
  FETCH_SINGLE_CANDIDATE_ERRORED = 'FETCH_SINGLE_CANDIDATE_ERRORED',

  FETCH_CANDIDATE_OFFERS_START = 'FETCH_CANDIDATE_OFFERS_START',
  FETCH_CANDIDATE_OFFERS_SUCCESS = 'FETCH_CANDIDATE_OFFERS_SUCCESS',
  FETCH_CANDIDATE_OFFERS_ERRORED = 'FETCH_CANDIDATE_OFFERS_ERRORED',

  CLEAR_CANDIDATE_OFFERS = 'CLEAR_CANDIDATE_OFFERS',

  CLEAR_SINGLE_CANDIDATE = 'CLEAR_SINGLE_CANDIDATE',

  SCHEDULE_INTERVIEW_START = 'SCHEDULE_INTERVIEW_START',
  SCHEDULE_INTERVIEW_SUCCESS = 'SCHEDULE_INTERVIEW_SUCCESS',
  SCHEDULE_INTERVIEW_ERRORED = 'SCHEDULE_INTERVIEW_ERRORED',

  CLEAR_SCHEDULE_INTERVIEW = 'CLEAR_SCHEDULE_INTERVIEW',

  CANDIDATE_SELECTED_OPEN_POSITION = 'CANDIDATE_SELECTED_OPEN_POSITION',

  FETCH_INTERVIEWS_START = 'FETCH_INTERVIEWS_START',
  FETCH_INTERVIEWS_SUCCESSS = 'FETCH_INTERVIEWS_SUCCESSS',
  FETCH_INTERVIEWS_ERRORED = 'FETCH_INTERVIEWS_ERRORED',

  CLEAR_INTERVIEWS = 'CLEAR_INTERVIEWS',

  FETCH_OPEN_POSITION_STAGES_START = 'FETCH_OPEN_POSITION_STAGE_START',
  FETCH_OPEN_POSITION_STAGES_SUCCESS = 'FETCH_OPEN_POSITION_STAGE_SUCCESS',
  FETCH_OPEN_POSITION_STAGES_ERRORED = 'FETCH_OPEN_POSITION_STAGE_ERRORED',
  CLEAR_OPEN_POSITION_STAGES = 'CLEAR_OPEN_POSITION_STAGE',
}

export interface CandidateSelectedOpenPosition {
  data: CandidateOpenPositionObj | null;
}

export interface CandidateOffersState {
  data: CandidateOfferType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface CandidateOpenPositionStage {
  data: Array<Stage>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface SingleCandidateState {
  data: CandidateType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface CandidatesState {
  data: {
    data: Array<CandidateType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface CandidateScheduleInterviewState {
  data: any;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface InterviewsState {
  data: {
    interviews: Array<InterviewTypeDTO>;
    totInterview: number;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
