import React, { useMemo, useState } from 'react';
import { FormInstance } from 'antd';

import { Image as ImageType } from 'types/Image';
import { FormConfigurationType } from 'types/FormTypes';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import GenericForm from 'components/NewForms/Form';
import TenantConfigVideoField from './TenantConfigVideoField';
import {
  getTenantOnboardingFormConfig,
  uploadTenantConfigAction,
} from '../utils';

interface TenantGeneralConfigProps {
  generalConfigForm: FormInstance;
  companyDescriptionPanelForm: FormInstance;
  onFinish: (values: any) => void;
  setFileToUpload: (file: ImageType) => void;
}

const TenantGeneralConfig: React.FC<TenantGeneralConfigProps> = ({
  generalConfigForm,
  companyDescriptionPanelForm,
  setFileToUpload,
  onFinish,
}) => {
  const { tenantPersisted: tenant } = useTenantsData();

  const [videoUrl, setVideoUrl] = useState('');

  const { data: tenantConfig } = useTenantConfigData();

  const handleVideoUrlChange = (e: any) => {
    setVideoUrl(e.target.value);
  };

  const generalFormConfiguration: FormConfigurationType[][] = useMemo(() => {
    const formConfig: FormConfigurationType[][] = [
      ...getTenantOnboardingFormConfig({
        tenantConfig,
        tenant,
        handleVideoUrlChange,
        uploadAction: (file) => uploadTenantConfigAction(file, setFileToUpload),
      }),
    ];
    return formConfig;
  }, [tenantConfig, tenant, videoUrl, companyDescriptionPanelForm]);

  return (
    <GenericForm
      form={generalConfigForm}
      formConfiguration={generalFormConfiguration}
      onFinish={onFinish}
    >
      <TenantConfigVideoField videoUrl={videoUrl} />
    </GenericForm>
  );
};

export default TenantGeneralConfig;
