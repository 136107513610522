import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { RootState } from 'redux/store';
import { updateCandidate } from 'api/candidateService';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import { fetchCandidate } from 'redux/candidates/actions';
import { WorkExperience } from 'types/Candidates';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { convertUTCtoLocalTime, getUserDateFormat } from 'utils/utilFunctions';

import GenericForm from 'components/NewForms/Form';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  workExperience?: WorkExperience;
  isEditing?: boolean;
  closeModal: () => void;
}

export default function WorkExperienceModal({
  open,
  workExperience,
  isEditing,
  closeModal,
}: IProps) {
  const [form] = Form.useForm();
  const { candidate } = useCandidateData();
  const [endDateDisabled, setEndDateDisabled] = useState(
    workExperience?.ongoing ?? false
  );
  const [startDateDisabled] = useState(workExperience?.ongoing ?? false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const { loading: candidatesLoading } = useSelector(
    (state: RootState) => state.candidates
  );
  const dispatch = useDispatch();

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'workTitle',
          label: t('title'),
          type: InputTypes.INPUT,
          defaultValue: workExperience?.workTitle,
          rules: [
            {
              required: true,
              message: t('experienceTitleisRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'companyName',
          label: t('company'),
          type: InputTypes.INPUT,
          defaultValue: workExperience?.companyName,
          rules: [
            {
              required: true,
              message: t('companyNameisRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: form.getFieldValue('present') ? 'disabled' : t('startDate'),
          type: InputTypes.DATEPICKER,
          disabled: startDateDisabled,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrAfter(moment()),
          },
          defaultValue: convertUTCtoLocalTime(workExperience?.startDate),
          rules: [
            {
              required: true,
              message: t('requiredStartDate'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('endDate'),
          type: InputTypes.DATEPICKER,
          disabled: endDateDisabled,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
          defaultValue: convertUTCtoLocalTime(workExperience?.endDate),
          rules: [
            {
              required: !endDateDisabled,
              message: t('requiredEndDate'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'present',
          label: t('stillWorkingHere'),
          type: InputTypes.CHECKBOX,
          checked: endDateDisabled,
        },
      ],
    ],
    [endDateDisabled, workExperience]
  );

  const onSubmit = async () => {
    if (loading || !candidate?.id) return;

    const formValues = form.getFieldsValue(true);
    setLoading(true);

    if (endDateDisabled) {
      delete formValues.endDate;
    }

    let payload = [];

    if (isEditing) {
      const experience = candidate.experience.filter(
        (e: any) => e.id !== workExperience?.id
      );
      payload = [...experience, formValues];
    } else {
      payload = [...candidate.experience, formValues];
    }

    await updateCandidate(candidate?.id, {
      workExperience: [...payload],
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 && response.data) {
          if (!candidatesLoading) {
            dispatch(fetchCandidate(candidate?.id));
          }
          toast.success(
            !isEditing
              ? `${t('Succesfully added work experience')}!`
              : `${t('Succesfully updated work experience')}!`
          );
          closeModal();
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(
          !isEditing
            ? `${t('failedtoAddWorkEpxerience')}!`
            : `${t('failedtoUpdateWorkExperience')}!`
        );
      });
  };

  const onFieldsChange = (values: any) => {
    if (values[0]?.name[0] === 'present') {
      setEndDateDisabled((state) => !state);
    }
  };

  return (
    <GenericModal
      title={`${isEditing ? t('edit') : t('add')} ${t('addworkexperience')}`}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
