import { TenantConfiguration } from 'types/TenantConfig';

export enum TenantConfig {
  FETCH_TENANT_CONFIG_START = 'FETCH_TENANT_CONFIG_START',
  FETCH_TENANT_CONFIG_SUCCESS = 'FETCH_TENANT_CONFIG_SUCCESS',
  FETCH_TENANT_CONFIG_ERRORED = 'FETCH_TENANT_CONFIG_ERRORED',

  CLEAR_TENANT_CONFIG = 'CLEAR_TENANT_CONFIG',
}

export interface TenantConfigState {
  data: TenantConfiguration | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
