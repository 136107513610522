import React from 'react';
import { Col, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { ViewEvaluationIcon } from 'Icons/ViewEvaluationIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { AUTH_ROLES } from 'types/Auth';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { IconButton } from './AssetAction';

type Props = {
  trackingHoursId: string;
};

const TrackingHoursToApproveActions: React.FC<Props> = ({
  trackingHoursId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const onViewClick = () => {
    searchParams.append('view-trackingHour', trackingHoursId);
    setSearchParams(searchParams);
  };

  return (
    <>
      {authUserRole && authUserRole !== AUTH_ROLES.GUEST_TRACKING_HOURS && (
        <Row>
          <Col span={24}>
            <Row justify="space-around">
              <Col>
                <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
                  <IconButton onClick={onViewClick}>
                    <ViewEvaluationIcon />
                  </IconButton>
                </RoleBasedButton>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TrackingHoursToApproveActions;
