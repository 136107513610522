import React from 'react';
import { useTranslation } from 'react-i18next';

import CardItem from 'ui-v2/components/Card';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import { IconButton } from 'components/Actions/AssetAction';
import { PopoverOverlayInnerStyles } from 'ui-v2/components/TableFilterPopover/TableFilterPopoverStyles';
import AddVendrorQuestionnaireQuestionButton from './AddVendrorQuestionnaireQuestionButton';

const VendorQuestionaireQuestionListCardTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[10, 0]}>
      <Col>{t('questions')}</Col>
      <Col>
        <sup>
          <Popover
            trigger="hover"
            placement="bottomLeft"
            overlayInnerStyle={PopoverOverlayInnerStyles}
            content={t('vendorQuestionnaireQuestionsInfo')}
          >
            <IconButton>
              <InfoCircleOutlined />
            </IconButton>
          </Popover>
        </sup>
      </Col>
    </Row>
  );
};

interface VendorQuestionaireQuestionListCardProps {
  children: React.ReactNode;
  onClick: () => void;
}

const VendorQuestionaireQuestionListCard: React.FC<
  VendorQuestionaireQuestionListCardProps
> = ({ children, onClick }) => (
  <CardItem
    title={<VendorQuestionaireQuestionListCardTitle />}
    rightButton={<AddVendrorQuestionnaireQuestionButton onClick={onClick} />}
  >
    <>{children}</>
  </CardItem>
);

export default VendorQuestionaireQuestionListCard;
