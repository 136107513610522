import { AssetSubCategory } from './AssetCategories';
import { CurrencyType } from './Candidates';
import { DepartmentSingleType } from './Department';
import { EmployeeType } from './Employee';

export enum AssetStatusTag {
  IN_USE = 'in use',
  UNDER_MAINTENANCE = 'under maintenance',
  SHARED = 'shared',
  RETIRED = 'retired',
}

export enum AssetConditionTag {
  NEW = 'new',
  USED = 'used',
}

export interface AssetType {
  id: string;
  name: string;
  description: string;
  serialNumber: string;
  notes: string;
  purchaseDate: Date;
  purchasePrice: number;
  barcode: string;
  condition: string;
  type: string;
  isAssigned: boolean;
  companyId: string;
  currency: CurrencyType;
  warrantyStartDate: Date;
  warrantyEndDate: Date;
  employeeAssets?: {
    id: string;
    startDate: Date;
    endDate: Date;
    notes: string;
    asset: AssetType;
    employee: EmployeeType;
  }[];
  department: DepartmentSingleType;
  employee: EmployeeType;
  location: string;
  status: AssetStatusTag;
  assetSubCategory: AssetSubCategory;
}

export interface AssetView extends AssetType {
  employeeName?: string;
}

export interface EmployeeAssetType {
  id: string;
  employeeId: string;
  assetId: string;
  startDate: Date;
  endDate: Date;
  notes: string;
  asset: AssetType;
}
export interface EmployeeAssetDetailsType {
  id: string;
  startDate: Date;
  endDate: Date;
  notes: string;
  asset: AssetType;
  employee: EmployeeType;
}

export interface AssetDTO {
  notes: string;
  name: string;
  description: string;
  serialNumber: string;
  purchaseDate: Date;
  purchasePrice: number;
  condition: string;
  type: string;
  isAssigned: boolean;
  currencyId: string;
  location: string;
  status: AssetStatusTag;
  departmentId?: string;
  employeeId?: string;
  warrantyStartDate: Date;
  warrantyEndDate: Date;
  assetSubCategory: AssetSubCategory;
}

export interface EmployeeAssetDTO {
  employeeId: string;
  assetId: string;
  startDate: Date;
  endDate: Date;
  notes: string;
}

export interface AssetUpdateTYpe {
  name?: string;
  description?: string;
  notes?: string;
  serialNumber?: string;
  purchaseDate?: Date;
  purchasePrice?: number;
  condition?: string;
  type?: string;
  employeeId?: string;
  isAssigned?: boolean;
  currencyId?: string;
  location?: string;
  status?: AssetStatusTag;
  departmentId?: string;
  warrantyStartDate?: Date;
  warrantyEndDate?: Date;
}

export interface EmployeeAssetUpdateTYpe {
  startDate?: Date;
  endDate?: Date;
  notes?: string;
}
