import { List, Tag, Typography } from 'antd';
import styled from 'styled-components';

export const Item = styled(List.Item)`
  &:hover {
    cursor: pointer;
    background: #f8f8f9;
  }
  .ant-list-item-meta {
    align-items: center !important;
  }
`;

export const Description = styled(Typography.Text)`
  color: #000000;
  opacity: 0.45;
`;

export const Reason = styled(Typography.Text)`
  font-weight: 300;
  color: #475467;
`;

export const Status = styled(Tag)`
  border-radius: 8px;
`;
