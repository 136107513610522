import { Reducer } from 'react';
import { Action } from 'redux';

import { EmployeesReviewsState, EmployeesReviews } from './types';

const initialState: EmployeesReviewsState = {
  data: [],
  loaded: false,
  loading: false,
  errored: false,
  error: null,
};

const reducer: Reducer<EmployeesReviewsState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case EmployeesReviews.FETCH_REVIEWS_QUESTIONS_START:
      state = { ...state, loading: true };
      break;

    case EmployeesReviews.FETCH_REVIEWS_QUESTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
      break;
    case EmployeesReviews.FETCH_REVIEWS_QUESTIONS_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export { reducer as EmployeesReviewsReducer };
