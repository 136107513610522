import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { TimeOffRequest } from 'types/TimeOff';
import Content from 'ui-v2/components/Content';
import useTimeOffRequestsData from 'ui-v2/hooks/useTimeOffRequestsData';
import TimeOffRequestDetails from './TimeOffRequestDetails';
import TimeOffRequestList from './TimeOffRequestList';
import TimeOffCalendarAdminCard from '../ProtectedRoutes/Employee/TimeOff/TimeOffCalendarAdminCard/TimeOffCalendarAdminCard';
import { FullWidthRow } from './TimeOffRequestsStyles';

export default function TimeOffRequests() {
  const { requests, loading, fetchDataApprover } = useTimeOffRequestsData();
  const [selectedRequest, setSelectedRequest] =
    useState<TimeOffRequest | null>();
  const [data, setData] = useState<TimeOffRequest[]>([]);
  const [currentData, setCurrentData] = useState<TimeOffRequest[] | null>(null);

  useEffect(() => {
    setData(requests);
  }, [requests]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentData]);

  const setRequests = (requests: TimeOffRequest[]) => {
    setCurrentData(requests);
    setSelectedRequest(null);
  };

  return (
    <Content position="stretch">
      <Row gutter={[0, 16]}>
        <FullWidthRow gutter={16}>
          <Col span={10}>
            <TimeOffRequestList
              requests={currentData || data}
              loading={loading}
              onRequest={setSelectedRequest}
            />
          </Col>

          <Col span={14}>
            {selectedRequest?.id && (
              <TimeOffRequestDetails
                fetchDataApprover={fetchDataApprover}
                requests={requests}
                selectedRequestId={selectedRequest?.id}
              />
            )}
          </Col>
        </FullWidthRow>
        <TimeOffCalendarAdminCard
          requests={requests}
          setRequests={setRequests}
          fetchDataApprover={fetchDataApprover}
        />
      </Row>
    </Content>
  );
}
