import React from 'react';
import { PayrollRule, PayrollRuleType } from 'types/PayrollRule';
import PayrollRulesActions from 'components/Actions/PayrollActions';
import i18next from '../i18n';

export const PayrollRuleColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'humanID',
    key: 'humanID',
    default: true,
    order: 0,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    default: true,
    order: 1,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    default: false,
    order: 2,
  },
  {
    title: i18next.t('type'),
    dataIndex: 'type',
    key: 'type',
    default: false,
    order: 3,
  },
  {
    title: i18next.t('params'),
    dataIndex: 'parameters',
    key: 'parameters',
    width: 250,
    render: (record: string, item: PayrollRule | any) => {
      if (item?.type === PayrollRuleType.FIXED) {
        return (
          <p>{`Value: ${item?.parameters?.value}${item?.currency?.symbol}`}</p>
        );
      }
      if (item?.type === PayrollRuleType.PERCENTAGE) {
        return <p>{`Value: ${item?.parameters?.value}%`}</p>;
      }
      if (item?.type === PayrollRuleType.INTERVAL) {
        return (
          <>
            {item?.currency?.symbol && (
              <p>{`Currency: ${item?.currency?.symbol}`}</p>
            )}
            {item?.parameters?.map(
              (el: {
                value: number;
                from: number;
                to: number;
                valueType: string;
              }) => (
                <p style={{ margin: '0.1rem' }}>
                  {`${el?.from} - ${el?.to} | Value: ${el?.value}`}
                  {el?.valueType === PayrollRuleType.FIXED.toLowerCase()
                    ? item?.currency?.symbol
                    : '%'}
                </p>
              )
            )}
          </>
        );
      }
      return '';
    },
    default: false,
    order: 4,
  },
  {
    title: i18next.t('startDate'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    default: false,
    order: 5,
  },

  {
    title: i18next.t('endDate'),
    dataIndex: 'endDate',
    key: 'endDate',
    default: false,
    order: 5,
  },
  {
    title: i18next.t('location'),
    dataIndex: 'location',
    key: 'location',
    default: false,
    order: 2,
    render: (_: string, row: PayrollRule) => {
      if (!row?.tenantLocation) return null;

      return (
        <p>{`${row?.tenantLocation?.city}, ${row?.tenantLocation?.country}`}</p>
      );
    },
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: PayrollRule) => (
      <PayrollRulesActions id={row?.id} />
    ),
    default: false,
    order: 6,
  },
];
