import styled from 'styled-components';
import { PlusSquareOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

export const StyledTenantConfigPanelContainer = styled(Row)(({ theme }) => ({
  padding: '1rem',
  border: `1px solid ${theme.colors.palette.primary.gray[300]}`,
  borderRadius: '8px',
}));

export const StyledEditableFormPanelTitleContainer = styled(Col)(
  ({ theme }) => ({
    ...theme.typography.body.medium.sm,
  })
);

export const StyledEditableFormPanelTitleRow = styled(Row)<{
  showUnderline?: boolean;
}>(({ theme, showUnderline }) => ({
  cursor: 'pointer',
  ...(showUnderline && {
    paddingBottom: '1rem',
    borderBottom: `1px solid ${theme.colors.palette.primary.gray[300]}`,
  }),
}));

export const StyledEditableFormPanelItemTitleTextCol = styled(Col)<{
  hasTitle?: boolean;
}>(({ hasTitle }) => ({
  opacity: hasTitle ? 1 : '.5',
}));

export const StyledEditableFormPanelItemTitlePlusSquareOutlinedIcon = styled(
  PlusSquareOutlined
)<{
  collapsed?: boolean;
}>(({ collapsed }) => ({
  transform: collapsed ? 'rotate(180deg)' : 'unset',
  transition: 'transform 0.3s',
}));

export const StyledEditableFormPanelItemCollapseCol = styled(Col)<{
  collapsed?: boolean;
}>(({ collapsed }) => ({
  paddingBottom: '1rem',
  borderBottom: collapsed ? '1px solid grey' : 'unset',
}));

export const StyledEditableFormPanelItemContentCol = styled(Col)({
  padding: '1rem',
});
