import agent from 'api/agent';
import { GetTimeOffToApproveDTO, IApproverUpdateDTO } from 'types/TimeOff';
import { apiURLs } from './constants';

export const requestTimeOff = (timeOffRequest: any) =>
  agent.post(apiURLs.timeOff, timeOffRequest);

export const fetchTimeOff = (payload: any) =>
  agent.post(`${apiURLs.timeOff}/employee`, payload);

export const fetchTimeOffToApprove = (
  approverEmployeeId: string,
  payload?: GetTimeOffToApproveDTO
) => agent.post(`${apiURLs.timeOff}/toApprove/${approverEmployeeId}`, payload);

export const getEmployeesByProjectOrDepartmentIds = (
  payload?: GetTimeOffToApproveDTO
) =>
  agent.post(
    `${apiURLs.timeOff}/getEmployeesByProjectOrDepartmentIds`,
    payload
  );

export const updateTimeoffRequest = (params: any) =>
  agent.put('/dayOff', params);

export const updateVoteTimeOff = (params: IApproverUpdateDTO) =>
  agent.put('/timeOff', params);

export const removeTimeOff = (id: string) => agent.delete(`/timeOff/${id}`);

export const fetchTimeOffSubstitutes = (employeeId: string) =>
  agent.get(`${apiURLs.timeOff}/substitute/${employeeId}`);
