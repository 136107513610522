import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const RowItem = styled(Row)`
  width: 100%;
  padding-top: 0px;
`;

export const Label = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
  margin-top: -15px;
`;
export const AddSpaceIcon = styled(Typography.Text)`
  margin: 6px;
  padding-top: 0px;
  align-content: center;
`;
export const PlusIcon = styled(Typography.Text)`
  margin: 8px;
`;
export const Item = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;
export const NoteItem = styled(Row)`
  width: 100%;
  padding-top: 0px;
`;
export const IconSpace = styled.span`
  margin-left: -10px;
`;

export const InterviewItemLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 0;
  text-transform: capitalize;
`;
