import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { RolesButtonType } from '../HeaderActions/config';

function useRoleBasedButton({ btn }: { btn: RolesButtonType }) {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const currentUserRole = authUserRole as AUTH_ROLES;

  return {
    disabled: btn.disabledRoles.includes(currentUserRole),
  };
}

const RoleBasedButton: React.FC<{ btn: RolesButtonType }> = ({
  btn,
  children,
}) => {
  const { disabled } = useRoleBasedButton({ btn });
  if (disabled) return null;
  return <>{children}</>;
};

export default RoleBasedButton;
