import { useTranslation } from 'react-i18next';
import CandidateOfferType from 'types/CandidateOffer';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';

export const deconstructOfferData = (
  offer: CandidateOfferType | undefined
) => ({
  candidateName: `${offer?.candidateOpenPosition?.candidate?.firstName ?? ''} ${
    offer?.candidateOpenPosition?.candidate?.lastName ?? ''
  }`,
  openPositionName: `${offer?.candidateOpenPosition?.openPositions.name ?? ''}`,
  employmentTypeName: `${offer?.employmentType?.name ?? ''}`,
  offeredSalary: `${offer?.salary ?? ''} ${offer?.currency?.symbol ?? ''} `,
  offerValidRange: `${convertUTCtoFormatedLocalTime(
    offer?.validFrom
  )} - ${convertUTCtoFormatedLocalTime(offer?.validTo)} `,
});

export const getOfferResultFeedback = (status: boolean) => {
  const { t } = useTranslation();
  return {
    title: status
      ? t('successfullyAcceptedTheOffer')
      : t('successfullyDeclinedTheOffer'),
    subTitle: `${
      status
        ? `${t('lookingForwardToHavingYouOnOurTeam')} ${t(
            'youWillReceiveAnEmailShortly'
          )}`
        : `${t('thankYouForYourTime')}`
    } ${t('youMayCloseThisPage')}`,
  };
};
