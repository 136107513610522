import { Col, Row } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';
import { TimeIcon } from 'Icons/TimeIcon';
import React, { useEffect, useState } from 'react';
import { InterviewTask } from 'types/Task';
import { FileItem } from 'ui-v2/components/PersonalInfo';
import {
  convertUTCtoFormatedLocalTime,
  documentSizeInKb,
  isInterviewDatePassed,
} from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';

import * as Styled from './TaskListStyles';

const PAGE_SIZE = 3;

export default function TaskList({
  list,
  onItemClick,
}: {
  list: Array<InterviewTask>;
  onItemClick: (id: string) => void;
}) {
  const [renderList, setRenderList] = useState<Array<InterviewTask>>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setRenderList(list.slice(0, page * PAGE_SIZE));
  }, [list, page]);

  const onLoadMore = () => {
    setPage((page) => page + 1);
  };

  const { t } = useTranslation();

  return (
    <Row gutter={[0, 24]}>
      {renderList.map((item: InterviewTask, index: number) => (
        <Styled.ColItem
          key={item.id}
          span={24}
          onClick={() => item.id && onItemClick(item.id)}
          isLast={index === renderList.length - 1}
        >
          <Styled.Task gutter={[0, 4]} isLast>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Styled.Name ellipsis>{item.name}</Styled.Name>
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    <Styled.Status>
                      <Styled.Icon>
                        <TimeIcon />
                      </Styled.Icon>
                      <Styled.StatusLabel>{item.progress}</Styled.StatusLabel>
                    </Styled.Status>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Styled.DueDate>Task Due Date</Styled.DueDate>
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    <Styled.Date past={!isInterviewDatePassed(item.dueDate)}>
                      {convertUTCtoFormatedLocalTime(item.dueDate)}
                    </Styled.Date>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Styled.DueDate>Interview Date</Styled.DueDate>
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    <Styled.Date
                      past={!isInterviewDatePassed(item.interviewDate)}
                    >
                      {convertUTCtoFormatedLocalTime(item.interviewDate)}
                    </Styled.Date>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Styled.DueDate>{t('interviewType')}</Styled.DueDate>
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    <Styled.InterviewName ellipsis>
                      {item.interviewName}
                    </Styled.InterviewName>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Styled.Divider />
            <Col span={24}>
              <FileItem
                filename={item.taskDocuments[0]?.name}
                filesize={documentSizeInKb()}
                filecontent={item.taskDocuments[0]?.url}
                noActions
                preview
              />
            </Col>
          </Styled.Task>
        </Styled.ColItem>
      ))}
      {list.length > renderList.length && (
        <StyledButton onClick={onLoadMore}>Load more</StyledButton>
      )}
    </Row>
  );
}
