import React from 'react';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import TrackingDetailed from './trackingDetailed';
import TrackingSummary from './trackingSummary';
import TrackingToApprove from './trackingToApprove';
import TimeTracker from './trackingTime';

const TrackingTabContent: React.FC = () => {
  const {
    urlState: { tab },
    TabLabel,
  } = useTrackedHoursData();

  switch (tab) {
    case TabLabel.summary:
      return <TrackingSummary />;
    case TabLabel.detailed:
      return <TrackingDetailed />;
    case TabLabel.toApprove:
      return <TrackingToApprove />;
    case TabLabel.timeTracker:
      return <TimeTracker />;
    default:
      return <></>;
  }
};

export default TrackingTabContent;
