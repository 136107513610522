/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import { List, Tag, Typography } from 'antd';
import styled from 'styled-components';

export const Item = styled(List.Item)`
  &:hover {
    cursor: pointer;
    background: #f8f8f9;
  }
  .ant-list-item-meta {
    align-items: center !important;
  }
`;

export const Description = styled(Typography.Text)`
  color: #000000;
  opacity: 0.45;
`;

export const Reason = styled(Typography.Text)`
  font-weight: 300;
  color: #475467;
`;
export const FixedWidthContainer = styled.div`
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Status = styled(Tag)<{
  status: boolean;
}>`
  border-radius: 8px;
  ${({ status }) =>
    status === true
      ? 'color: green'
      : status === false
      ? 'color: red'
      : 'border: 1px solid #EFF4FF; background: #EFF4FF;color: #00bfff; svg{path{stroke: #004EEB}'};
`;
