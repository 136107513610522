import { StyledButton } from 'components/NewForms/FormStyled';
import { UserHireIcon } from 'Icons/UserHireIcon';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { HEADER_BUTTON_TYPE } from 'ui-v2/components/HeaderActions/config';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import CandidateOfferModal from '../CandidateOfferModal';

interface CandidateOfferButtonProps {
  disabled: boolean | undefined;
}

export default function CandidateOfferButton({
  disabled,
}: CandidateOfferButtonProps) {
  const [visible, setVisible] = useState(false);
  const { candidateOffer } = useCandidateData();

  const onClick = () => {
    if (candidateOffer.data !== null) {
      toast.info('An offer already exists for the selected open position!');
      return;
    }
    setVisible((visible) => !visible);
  };

  const { t } = useTranslation();

  return (
    <>
      <StyledButton
        id="candidate-offer-modal"
        onClick={onClick}
        icon={<UserHireIcon />}
        disabled={disabled}
      >
        {t(HEADER_BUTTON_TYPE.OFFER_CANDIDATE)}
      </StyledButton>
      {visible && (
        <CandidateOfferModal
          open={visible}
          closeModal={() => setVisible(!visible)}
        />
      )}
    </>
  );
}
