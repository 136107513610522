import UserForm from 'components/NewForms/UserForm';
import React from 'react';

function EditUser() {
  return (
    <div>
      <UserForm open={false} closeModal={() => console.log('close')} />
    </div>
  );
}

export default EditUser;
