import { AssetCategory, AssetSubCategory } from 'types/AssetCategories';
import { MetaPagination } from 'types/GridTable';

export enum AssetCategories {
  FETCH_ASSET_CATEGORIES_START = 'FETCH_ASSET_CATEGORIES_START',
  FETCH_ASSET_CATEGORIES_SUCCESS = 'FETCH_ASSET_CATEGORIES_SUCCESS',
  FETCH_ASSET_CATEGORIES_ERRORED = 'FETCH_ASSET_CATEGORIES_ERRORED',

  FETCH_ASSET_SUBCATEGORIES_START = 'FETCH_ASSET_SUBCATEGORIES_START',
  FETCH_ASSET_SUBCATEGORIES_SUCCESS = 'FETCH_ASSET_SUBCATEGORIES_SUCCESS',
  FETCH_ASSET_SUBCATEGORIES_ERRORED = 'FETCH_ASSET_SUBCATEGORIES_ERRORED',

  CLEAR_ASSET_CATEGORY = 'CLEAR_ASSET_CATEGORY',
}

export interface AssetCategoryState {
  data: {
    data: Array<AssetCategory>;
    meta: MetaPagination | [];
  };
  subCategories: {
    data: AssetSubCategory[];
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
