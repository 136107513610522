import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getOfferResultFeedback } from '../../helpers';
import { ActionStatus } from '../../OfferAccept';

type IProps = {
  actionStatus: ActionStatus;
};

export default function CustomOfferResult({ actionStatus }: IProps) {
  const { t } = useTranslation();

  const { title, subTitle } = getOfferResultFeedback(
    actionStatus.accepted || false
  );

  return (
    <>
      <Result
        status={`${actionStatus.errored ? 'error' : 'success'}`}
        title={
          actionStatus.errored
            ? t('Something went wrong. Please try again later.')
            : title
        }
        subTitle={subTitle}
      />
    </>
  );
}
