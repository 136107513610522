import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Skeleton } from 'antd';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import CardItem from 'ui-v2/components/Card';
import { isInterviewDatePassed as isOfferValid } from 'utils/utilFunctions';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidateOffer } from 'redux/candidates/actions';
import CandidateOfferType from 'types/CandidateOffer';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { InterviewsState } from 'redux/candidates/types';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import CandidateOfferModal from '../CandidateOfferModal';
import { OfferLabel } from '../CandidateOfferStyles';
import { Label as NoOffersLabel } from '../../../../components/Task/TaskStyles';
import {
  InterviewItem as OfferItem,
  RowItem,
} from '../../InterviewList/InterviewStyles';

interface CandidateOfferListProps {
  isDisabled: boolean;
}

const OfferStatus = ({ data }: { data: CandidateOfferType | null }) => {
  if (!data) return null;

  return (
    <Row justify="end">
      <Styled.StatusTag status={data?.isAccepted ? 'verify' : 'new'}>
        <p>{data?.isAccepted ? 'Accepted' : 'Pending'}</p>
      </Styled.StatusTag>
    </Row>
  );
};
export default function CandidateOfferList({
  isDisabled,
}: CandidateOfferListProps) {
  const {
    candidateOffer,
    selectedCandidateOpenPosition,
    loading,
    candidateLoaded,
  } = useCandidateData();
  const { loaded } = useSelector(
    (state: RootState) => state.interviews as InterviewsState
  );
  const [selectedOfferId, setSelectedOfferId] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCandidateOpenPosition?.data?.id && candidateLoaded && loaded) {
      dispatch(
        fetchCandidateOffer(selectedCandidateOpenPosition?.data?.id as string)
      );
    }
  }, [selectedCandidateOpenPosition?.data?.id, candidateLoaded, loaded]);

  const onAddOffer = () => {
    document.getElementById('candidate-offer-modal')?.click();
  };

  const onOfferClick = (id: string | undefined) => {
    if (!id) return;
    setSelectedOfferId(id);
  };

  const onModalClose = () => {
    setSelectedOfferId('');
  };

  const { t } = useTranslation();

  return (
    <CardItem
      title={t('offer')}
      disabled={isDisabled}
      rightButton={<OfferStatus data={candidateOffer?.data} />}
    >
      <Skeleton
        loading={candidateOffer.loading || loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <RowItem gutter={[0, 24]} justify="center" align="middle">
          {candidateOffer?.data ? (
            <OfferItem
              span={24}
              onClick={() => onOfferClick(candidateOffer?.data?.id)}
            >
              <Row justify="space-between" align="middle">
                <Col>
                  <OfferLabel
                    isValid={isOfferValid(candidateOffer?.data?.validTo)}
                  >
                    {selectedCandidateOpenPosition?.data?.openPositions?.name ??
                      ''}
                  </OfferLabel>
                </Col>
                <Col>
                  <OfferLabel
                    isValid={isOfferValid(candidateOffer?.data?.validTo)}
                  >
                    {candidateOffer?.data?.employmentType?.name ?? ''}
                  </OfferLabel>
                </Col>
              </Row>
            </OfferItem>
          ) : (
            <>
              <Col span={24}>
                <Row justify="center">
                  <NoOffersLabel>{t('candidateHasNoOfferYet')}</NoOffersLabel>
                </Row>
              </Col>
            </>
          )}
          {!candidateOffer?.data && (
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Col span={24}>
                <Row justify="center">
                  <Button
                    id="candidate-offer-modal"
                    icon={<PlusCircleOutlined />}
                    size="large"
                    onClick={onAddOffer}
                    style={{
                      borderRadius: '8px',
                      width: '100%',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '#344054',
                    }}
                  >
                    {t('addOffer')}
                  </Button>
                </Row>
              </Col>
            </RoleBasedButton>
          )}
        </RowItem>

        {selectedOfferId && (
          <CandidateOfferModal
            open={!!selectedOfferId}
            offerId={selectedOfferId}
            closeModal={onModalClose}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
