/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import React, { useEffect, useMemo, useState } from 'react';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { BenefitIcon } from 'Icons/BenefitIcon';
import { BenefitPlanInterface, BenefitTemplate } from 'types/Benefit';
import {
  createBenefitTemplate,
  fetchUnselectedWorkPositions,
  findBenefitTemplatePlanCurrncies,
  updateBenefitTemplate,
} from 'api/benefitTemplates';
import { toastErrorMessages } from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { OptionType } from 'types/OptionTypes';
import { useTranslation } from 'react-i18next';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { useWorkPositionsSkimData } from 'ui-v2/hooks/useWorkPositionsSkimData';
import { BenefitPlanText } from '../BenefitList/BenefitListStyles';
import BenefitPlan from './BenefitPlan';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedBenefitTemplate?: BenefitTemplate | undefined;
  page: number;
  take: number;
  fetchBenefitTemplates: (page: number, take: number) => void;
}

export default function BenefitModalForm({
  open,
  closeModal,
  selectedBenefitTemplate,
  fetchBenefitTemplates,
  page,
  take,
}: IProps) {
  const defaultBenefitPlan = [
    {
      key: 0,
      plan_name: 'Basic',
      plan_value: '100',
    },
  ];
  const [benefitPlans, setBenefitPlans] = useState(
    defaultBenefitPlan || selectedBenefitTemplate?.plans
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { filteredWorkPositions } = useWorkPositionsSkimData();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [selectedBenefitTemplatePlan, setSelectedBenefitTemplatePlan] =
    useState<any>();
  const [unselectedWorkPositions, setUnselectedWorkPositions] = useState<any>();

  useEffect(() => {
    const loadUnselectedWorkPositions = async () => {
      try {
        const unselectedPositions = await fetchUnselectedWorkPositions(
          selectedBenefitTemplate?.id ?? ''
        );
        const response = unselectedPositions?.data?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          id: item?.id,
        }));

        setUnselectedWorkPositions(response);
      } catch (error) {
        toast.error(t('Error loading unselected work position'));
      }
    };

    loadUnselectedWorkPositions();
  }, [selectedBenefitTemplate?.id]);

  const { optionsType: workPositions } = useGetSelectOptions({
    type: 'work-position',
  });

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'title',
          label: t('title'),
          type: InputTypes.INPUT,
          defaultValue: selectedBenefitTemplate?.title || '',
          rules: [
            {
              required: true,
              message: t('titleisRrequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'workPosition',
          label: t('workPosition'),
          type: InputTypes.SELECT,
          rules: [
            {
              required: true,
              message: t('Work Positions is required!'),
            },
          ],
          showSearch: true,
          isMultiSelect: true,
          allowClear: true,
          mode: 'multiple',
          selectOptions: filteredWorkPositions,
          defaultValue: selectedBenefitTemplate
            ? selectedBenefitTemplate?.workPositions?.map((item) => ({
                id: item?.id,
                label: item.name,
                value: item.id,
              }))
            : '',
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'specifications',
          label: t('specifications'),
          defaultValue: selectedBenefitTemplate?.specifications || '',
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('requiredSpecifications'),
            },
          ],
        },
      ],
    ],
    [workPositions, selectedBenefitTemplate, unselectedWorkPositions]
  );

  useEffect(() => {
    const fetchBenefitTemplate = async () => {
      if (selectedBenefitTemplate) {
        try {
          const response = await findBenefitTemplatePlanCurrncies(
            selectedBenefitTemplate?.id
          );
          if (response.status === 200) {
            setSelectedBenefitTemplatePlan(response?.data);
          }
        } catch (error) {
          toastErrorMessages(error);
        }
      }
    };

    fetchBenefitTemplate();
  }, [selectedBenefitTemplate]);

  const createOneBenefitTemplate = async (
    valuesToSubmit: BenefitPlanInterface
  ) => {
    try {
      const response = await createBenefitTemplate(valuesToSubmit);
      if (response.status === 200) {
        toast.success(t('Successfully created benefit template!'));
        searchParams.append('added-benefit-template', 'true');
        setSearchParams(searchParams);
        fetchBenefitTemplates(page, take);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const editOneBenefitTemplate = async (
    id: string,
    valuesToSubmit: BenefitTemplate
  ) => {
    try {
      const response = await updateBenefitTemplate(id, valuesToSubmit);
      if (response.status === 200) {
        toast.success(t('Successfully edited benefit template!'));
        searchParams.append('edited-benefit-template', 'true');
        setSearchParams(searchParams);
        fetchBenefitTemplates(page, take);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const formValues = form.getFieldsValue();

    if (!formValues?.workPosition?.length) {
      setLoading(false);
      return;
    }

    if (!benefitPlans.length) {
      toast.warning(t('Add a benefit plan!'));
      setLoading(false);
      return;
    }

    const emptyPlans = benefitPlans.find((plan) => {
      if (!plan.plan_name || !plan.plan_value) {
        return true;
      }
      return false;
    });

    if (emptyPlans) {
      toast.warning(t('somePlansHaveEmptyFields'));
      setLoading(false);
      return;
    }

    const updatedBenefitPlans = benefitPlans.map((plan) => ({
      ...plan,
      title: plan.plan_name,
    }));

    const filteredFormValues = Object.keys(formValues)
      .filter(
        (key) =>
          !key.startsWith('name') &&
          !key.startsWith('value') &&
          (!selectedBenefitTemplate || key !== 'workPosition')
      )
      .reduce((obj: any, key) => {
        obj[key] = formValues[key];
        return obj;
      }, {});

    const formValuesToSubmitCreate = {
      ...filteredFormValues,
      title: form.getFieldValue('title'),
      plans: updatedBenefitPlans,
      specifications: form.getFieldValue('specifications'),
      type: form.getFieldValue('title'),
    };

    if (formValuesToSubmitCreate.specifications === undefined) {
      setLoading(false);
      return;
    }

    const formValuesToSubmitEdit = {
      ...filteredFormValues,
      title: formValues.title || selectedBenefitTemplate?.title,
      type: formValues.type || selectedBenefitTemplate?.type,

      specifications:
        form.getFieldValue('specifications') ||
        selectedBenefitTemplate?.specifications,
      plans: updatedBenefitPlans || selectedBenefitTemplate?.plans,
      workPosition: (formValues.workPosition || []).map(
        (wp: OptionType) => wp?.id || wp
      ),
    };

    if (selectedBenefitTemplate?.id) {
      await editOneBenefitTemplate(
        selectedBenefitTemplate?.id,
        formValuesToSubmitEdit
      );
    } else {
      await createOneBenefitTemplate(formValuesToSubmitCreate);
    }

    setLoading(false);
    closeModal();
  };

  return (
    <GenericModal
      title={`${
        selectedBenefitTemplate
          ? t('editbenefitTemplate')
          : t('addbenefitTemplate')
      }`}
      open={open}
      closeModal={closeModal}
      icon={<BenefitIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        form={form}
        onFinish={onSubmit}
        loading={loading}
      >
        <Col span={24} style={{ marginTop: '-20px' }}>
          <BenefitPlanText>{t('plans')}</BenefitPlanText>
          <BenefitPlan
            form={form}
            benefitPlans={benefitPlans}
            setBenefitPlans={setBenefitPlans}
            selectedBenefitTemplate={selectedBenefitTemplate}
            selectedBenefitTemplatePlan={selectedBenefitTemplatePlan}
          />
        </Col>

        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={onSubmit}
              >
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
