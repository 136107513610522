import { Row, Col, Typography, Badge } from 'antd';
import styled from 'styled-components';

export const RowItem = styled(Row)`
  width: 100%;
  padding-top: 10px;
`;

export const Label = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;

export const TimeContainer = styled(Col)`
  position: relative;
`;

export const BadgeStyle = styled(Badge)`
  border: '1px solid #d9d9d9';
`;

export const TimeSpan = styled.span`
  position: absolute;
  right: -16px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const List = styled(Col)`
  min-height: 40px;
  max-height: 200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

export const InterviewItem = styled(Col)`
  border-radius: 5px;
  border: 1px solid #fff;
  &:hover {
    cursor: pointer;
    background: #fafafa;
    border: 1px solid #f0f0f0;
  }
  [class*='ant-row ant-row-space-between'] {
    padding: 10px;
  }
`;

export const InterviewItemLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 0;
  text-transform: capitalize;
`;

export const InterviewEmployeeLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: #000000;
  display: block;
`;

export const InterviewItemDate = styled(Typography.Text)<{
  date: string;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  ${({ date }) => date === 'passed' && 'color: #ff4d4f'};
`;

export const InterviewItemTime = styled(Typography.Text)<{
  date: string;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  ${({ date }) => date === 'passed' && 'color: #B42318'};
`;

export const IconSpace = styled.span`
  margin-left: 8px;
`;
