import i18next from 'i18next';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  VendorQuestionPriority,
  VendorQuestionnaire,
  VendorQuestionnaireCategory,
} from 'types/VendorQuestionnaire';

type VendorQuestionnaireCategoryFormConfigDto = {
  selectedQuestionnaireCategory: VendorQuestionnaireCategory | null;
};

export const getVendorQuestionnaireCategoryFormConfig = ({
  selectedQuestionnaireCategory,
}: VendorQuestionnaireCategoryFormConfigDto) => {
  const formConfigs: FormConfigurationType[][] = [
    [
      {
        col: 24,
        offset: 0,
        name: 'name',
        label: i18next.t('name'),
        type: InputTypes.INPUT,
        defaultValue: selectedQuestionnaireCategory?.name || '',
        rules: [{ required: true, message: i18next.t('fieldRequired') }],
      },
    ],
  ];
  return formConfigs;
};

type VendorQuestionnaireQuestionFormConfigDto = {
  questionnaire: VendorQuestionnaire | null;
};

export const getVendorQuestionnaireQuestionFormConfig = ({
  questionnaire,
}: VendorQuestionnaireQuestionFormConfigDto) => {
  const formConfigs: FormConfigurationType[][] = [
    [
      {
        col: 24,
        offset: 0,
        name: 'priority',
        label: i18next.t('priority'),
        type: InputTypes.SELECT,
        selectOptions: Object.values(VendorQuestionPriority).map(
          (priority) => ({
            value: priority,
            label: i18next.t(priority),
            id: priority,
          })
        ),
        defaultValue: questionnaire?.priority || '',
        rules: [{ required: true, message: i18next.t('fieldRequired') }],
      },
      {
        col: 24,
        offset: 0,
        name: 'question',
        label: i18next.t('question'),
        type: InputTypes.TEXTAREA,
        defaultValue: questionnaire?.question || '',
        rules: [{ required: true, message: i18next.t('fieldRequired') }],
      },
    ],
  ];
  return formConfigs;
};
