import {
  OpenPositionCreateDto,
  OpenPositionPayload,
  OpenPositionUpdateDto,
} from 'interfaces/OpenPosition';
import agent from 'api/agent';
import { OpenPositionTypeQueryParamType } from 'types/QueryParams';
import { apiURLs } from './constants';
import { searchFields } from '../ui-v2/table-search/utils-search-functions';

export const getAllOpenPositionsforAdmin = async (
  queryParams?: OpenPositionTypeQueryParamType
) =>
  agent.post(
    `${apiURLs.openPositions}/admin/get`,
    searchFields(queryParams, apiURLs.openPositions)
  );

export const getOpenPositions = async () =>
  agent.get(`${apiURLs.openPositions}/company`);

export const getOpenPosition = async (id: string, getImage = false) =>
  agent.get(`${apiURLs.openPositions}/${id}?getImage=${getImage}`);

export const getOpenPositionById = async (id: string) =>
  agent.get(`${apiURLs.openPositions}/${id}`);

export const createNewOpenPosition = (values: OpenPositionCreateDto) =>
  agent.post(apiURLs.openPositions, values);

export const getJobCategories = (values: OpenPositionPayload) =>
  agent.post(`${apiURLs.openPositions}/jobCategories`, values);

export const updateOpenPosition = (id: string, values: OpenPositionUpdateDto) =>
  agent.patch(`${apiURLs.openPositions}/${id}`, values);

export const deleteOpenPosition = async (id: string) =>
  agent.delete(`${apiURLs.openPositions}/${id}`);
