/* eslint-disable max-len */
/* eslint-disable radix */
import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { CreateIpAccessDTO } from 'types/IpAccess';
import { createIpWhiteListing } from 'api/ipAccess';
import { fetchIpWhitelisting } from 'redux/ipAccess/actions';
import { AssetsIcon } from 'Icons/AssetsIcon';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import { getIPType, validateIpAddress, validateRange } from './utils';
import { IpNotes } from './IpWhitelistingStyles';

export default function IpWhitelistingModalForm({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onFormFinish(values: any) {
    const IPType = getIPType(values.input);

    if (!IPType) {
      toast.error(t('Invalid IP specified!'));
      return;
    }
    const valuesToSend: CreateIpAccessDTO = {};
    if (IPType === 'range') {
      const { startIP, endIP } = validateRange(values.input);
      valuesToSend.startRange = startIP;
      valuesToSend.endRange = endIP;
    } else {
      valuesToSend[IPType] = values.input;
    }

    setLoading(true);

    createIpWhiteListing(valuesToSend)
      .then(() => {
        toast.success(t('Ip whitelisting created successfully!'));
        dispatch(fetchIpWhitelisting({ page: 1 }));
        navigate('/ip-whitelisting');
        closeModal();
      })
      .catch(() => {
        toast.error(t('Failed to create ip whitelisting!'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (searchParams.get('add-ip-whitelisting') === 'true') {
      searchParams.delete('add-ip-whitelisting');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const IpWhitelistingFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'input',
          label: t('type IP address, wildcard or range'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              validator(_: any, value: any): Promise<any> {
                if (validateIpAddress(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Invalid address!'));
              },
            },
          ],
        },
      ],
    ],
    []
  );

  return (
    <GenericModal
      title={t('createIPWhitelisting')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={IpWhitelistingFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <Row justify="start">
          <Col>
            <IpNotes>{t('ipExample')}</IpNotes>
          </Col>
        </Row>
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
