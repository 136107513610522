import moment from 'moment';

import { EmployeeType } from 'types/Employee';
import { OptionType } from 'types/OptionTypes';
import { InterviewResponseType } from 'types/Interview';
import { convertUTCtoLocalTime } from 'utils/utilFunctions';
import { CandidateOpenPositionObj, CandidateType } from 'types/Candidates';

export const getFormatedDateTime = (
  date: string,
  time: string
): moment.Moment =>
  moment(date).set({
    hour: moment(time, 'HH:MM:ss').get('hour'),
    minute: moment(time, 'HH:MM:ss').get('minute'),
    second: moment(time, 'HH:MM:ss').get('second'),
  });

export const getFormatedTime = (
  interview: InterviewResponseType | undefined
): moment.Moment | null => {
  if (!interview?.id) {
    return null;
  }
  const date = convertUTCtoLocalTime(interview.date);
  if (!date) return null;
  const hours = date.get('hours');
  const minutes = date.get('minutes');
  return moment(`${hours}:${minutes}`, 'hh:mm');
};

export const getOptionTypeMappingContainingFullName = (
  data: CandidateType[] | EmployeeType[]
): OptionType[] => {
  if (!data?.length) return [];
  return data?.map((el) => ({
    id: el?.id ?? '',
    value: el?.id ?? '',
    label: `${el?.firstName ?? ''} ${el?.lastName ?? ''}`,
  }));
};

export const getOptionTypeMappingContainingOpenPositionName = (
  data: CandidateOpenPositionObj[]
): OptionType[] => {
  if (!data?.length) {
    return [];
  }
  return data.map((el) => ({
    id: el?.id ?? '',
    value: el?.id ?? '',
    label: el?.openPositions?.name ?? '',
  }));
};
