import React, { useCallback } from 'react';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { Id } from 'types/Id';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import useHeader from 'ui-v2/hooks/useHeader';

const VendorQuestionnaireActions: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const { take } = useHeader();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const onEdit = async () => {
    searchParams.append('edit-vendor-questionnaire', id);
    setSearchParams(searchParams);
  };

  const onViewVendorCategory = () => {
    navigate(`vendor-questionnaire-details?id=${id}`);
  };

  const onDeleteClick = useCallback(async () => {
    searchParams.append('delete-vendor-questionnaire', id);
    setSearchParams(searchParams);
  }, [take]);

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editVendorQuestionnaire')}>
                <StyledIcon onClick={onEdit}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('details')}>
                <StyledIcon onClick={onViewVendorCategory}>
                  <ViewProfileIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t(
                  'areYouSureYouWantToArchiveThisVendorQuestionnaireCategory'
                )}
                onConfirm={onDeleteClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default VendorQuestionnaireActions;
