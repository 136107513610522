import { MetaPagination } from 'types/GridTable';
import { PublicSuperAdminType } from 'types/PublicSuperAdmin';

export enum PublicSuperAdmins {
  FETCH_SUPER_ADMINS_START = 'FETCH_SUPER_ADMINS_START',
  FETCH_SUPER_ADMINS_SUCCESS = 'FETCH_SUPER_ADMINS_SUCCESS',
  FETCH_SUPER_ADMINS_ERRORED = 'FETCH_SUPER_ADMINS_ERRORED',
}

export interface PublicSuperAdminsState {
  data?: Array<PublicSuperAdminType>;
  meta?: MetaPagination;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error?: any;
}
