import { AssignmentType } from 'types/Assignment';
import { MetaPagination } from 'types/GridTable';
import { ProjectType } from 'types/Project';

export enum Projects {
  FETCH_PROJECTS_START = 'FETCH_PROJECTS_START',
  FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS',
  FETCH_PROJECTS_ERRORED = 'FETCH_PROJECTS_ERRORED',

  FETCH_SINGLE_PROJECT_START = 'FETCH_SINGLE_PROJECT_START',
  FETCH_SINGLE_PROJECT_SUCCESS = 'FETCH_SINGLE_PROJECT_SUCCESS',
  FETCH_SINGLE_PROJECT_ERRORED = 'FETCH_SINGLE_PROJECT_ERRORED',

  FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_START = 'FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_START',
  FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_SUCCESS = 'FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_SUCCESS',
  FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_ERRORED = 'FETCH_PROJECT_ASSIGNMENTS_OF_EMPLOYEE_ERRORED',

  FETCH_PROJECTS_OF_EMPLOYEE_START = 'FETCH_PROJECTS_OF_EMPLOYEE_START',
  FETCH_PROJECTS_OF_EMPLOYEE_SUCCESS = 'FETCH_PROJECTS_OF_EMPLOYEE_SUCCESS',
  FETCH_PROJECTS_OF_EMPLOYEE_ERRORED = 'FETCH_PROJECTS_OF_EMPLOYEE_ERRORED',

  CLEAR_SINGLE_PROJECT = 'CLEAR_SINGLE_PROJECT',
  CLEAR_ALL_PROJECT_ASSIGNMENT_OF_EMPLOYEE = 'CLEAR_ALL_PROJECT_ASSIGNMENT_OF_EMPLOYEE',
}

export interface ProjectsState {
  data: {
    data: Array<ProjectType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;

  employeeProject: {
    data: Array<ProjectType>;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };

  employeeAssignment: {
    data: Array<AssignmentType>;
    loading: boolean;
    loaded: boolean;
    errored: boolean;
    error: any;
  };
}

export interface SingleProjectState {
  data: ProjectType | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
