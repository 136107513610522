import styled from 'styled-components';
import { Col, Popover, Row } from 'antd';

import { StyledButton } from 'components/NewForms/FormStyled';

export const CustomPopover = styled(Popover)``;

export const TimeWrapper = styled(Row)`
  padding-top: 20px;
  [class*='ant-picker-panel-container'] {
    box-shadow: none !important;
  }
  [class*='ant-picker-input'] {
    width: 140px;
  }
`;

export const RowItem = styled(Row)<{
  height?: number;
}>`
  width: 100%;
  height: ${({ height }) => height && `${height}px`};
`;

export const FullHeight = styled(Col)``;

export const Container = styled(Row)`
  position: relative;
  height: 490px;
  [class*='ant-picker-panel-container']:first-child {
    box-shadow: none;
  }
`;

export const Bottom = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Button = styled(StyledButton)`
  width: 100%;
  justify-content: center;
`;
export const MonthAndYear = styled(Row)`
  display: flex;
  alignitems: center;
  justifycontent: center;
  padding: 10px 0;
`;
