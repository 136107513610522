import { Dispatch } from 'redux';
import { PayrollDatesDTO, PayrollReportRequestDTO } from 'types/PayrollReport';
import {
  getPayrollReportSingleEmployee,
  getPayrollDates,
} from 'api/payrollReportService';
import { PayrollReportActions } from './types';

export const fetchPayrollDates =
  (payload?: PayrollDatesDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: PayrollReportActions.FETCH_PAYROLL_DATES_START,
    });
    getPayrollDates(payload)
      .then((res) => {
        dispatch({
          type: PayrollReportActions.FETCH_PAYROLL_DATES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PayrollReportActions.FETCH_PAYROLL_DATES_ERRORED,
          payload: e,
        });
      });
  };

export const fetchPayrollReportSingleEmployee =
  (queryParams: PayrollReportRequestDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: PayrollReportActions.FETCH_SINGLE_PAYROLL_REPORT_START,
    });

    getPayrollReportSingleEmployee(queryParams)
      .then((res) => {
        dispatch({
          type: PayrollReportActions.FETCH_SINGLE_PAYROLL_REPORT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: PayrollReportActions.FETCH_SINGLE_PAYROLL_REPORT_ERRORED,
          payload: e,
        });
      });
  };

export const clearFetchPayrollReportSingleEmployee =
  () => (dispatch: Dispatch) => {
    dispatch({
      type: PayrollReportActions.CLEAR_ALL_PAYROLL_REPORT,
    });
  };
