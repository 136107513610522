import React from 'react';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { fetchProjectOptions } from 'components/NewForms/SelectWithLoad/utils';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { getUserTrackingHoursTeamSkim } from 'api/trackedHoursService';
import { EmployeesSkim } from 'types/Employee';
import { OptionType } from 'types/OptionTypes';
import TrackingFilterSelectItem from './TrackingFilterSelectItem';
import DescriptionFilterPanelItem from './DescriptionFilterPanelItem';
import FilterTogglePanelItem from './FilterTogglePanelItem';
import { TrackingFilterPanelLabel } from '../utils';

interface TrackingFilterPanelItemProps {
  label: TrackingFilterPanelLabel;
  borderRight?: boolean;
  labels?: TrackingFilterPanelLabel[];
  onFilterVisibilityChange?: (newLabels: TrackingFilterPanelLabel[]) => void;
}

const TrackingFilterPanelItem: React.FC<TrackingFilterPanelItemProps> = ({
  label,
  borderRight,
  labels,
  onFilterVisibilityChange,
}) => {
  const {
    urlState: { projectIds, employeeIds },
  } = useTrackedHoursData();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const fetchSkim = async (filterText: string) => {
    const skim = await getUserTrackingHoursTeamSkim();
    const data = skim?.data
      ?.map((el: EmployeesSkim) => ({
        id: el.id,
        label: `${el.firstName} ${el.lastName}`,
        value: el.id,
      }))
      ?.filter((o: OptionType) =>
        `${o.label}`
          .toLocaleLowerCase()
          .startsWith(filterText.toLocaleLowerCase())
      );
    return { data, page: 1 };
  };

  const teamFetchOptions = (filterText: string) => {
    if (
      [AUTH_ROLES.AMDIN, AUTH_ROLES.HR].includes(authUserRole as AUTH_ROLES)
    ) {
      return getEmployeeSkimSelectWithLoadOptions(filterText);
    }
    return fetchSkim(filterText);
  };

  switch (label) {
    case TrackingFilterPanelLabel.FILTERS:
      return (
        <FilterTogglePanelItem
          borderRight={borderRight}
          onFilterVisibilityChange={onFilterVisibilityChange}
          labels={labels}
        />
      );
    case TrackingFilterPanelLabel.PROJECT:
      return (
        <TrackingFilterSelectItem
          fieldName="projectIds"
          label={t(TrackingFilterPanelLabel.PROJECT)}
          urlIds={projectIds}
          noDataMsg={t('noprojectsselectedYet')}
          fetchOptions={fetchProjectOptions}
          borderRight={borderRight}
        />
      );
    case TrackingFilterPanelLabel.TEAM:
      return (
        <TrackingFilterSelectItem
          fieldName="employeeIds"
          label={t(TrackingFilterPanelLabel.TEAM)}
          noDataMsg={t('noemployeesselectedYet')}
          urlIds={employeeIds}
          fetchOptions={teamFetchOptions}
          borderRight={borderRight}
        />
      );
    case TrackingFilterPanelLabel.DESCRIPTION:
      return <DescriptionFilterPanelItem borderRight={borderRight} />;
    default:
      return null;
  }
};

export default TrackingFilterPanelItem;
