import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { fetchInterviews } from 'redux/interviewsPage/actions';
import { fetchCandidateOptions } from 'components/NewForms/SelectWithLoad/utils';
import { InterviewsQueryParamsType } from 'types/QueryParams';
import { useTranslation } from 'react-i18next';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function InterviewsFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function onFormChange() {
    const candidate: string = form.getFieldValue('candidateId')?.value ?? '';

    const filterInterviewsTable: InterviewsQueryParamsType = {
      candidate,
    };
    dispatch(fetchInterviews(filterInterviewsTable));
  }

  const InterviewsFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'candidateId',
          label: t('candidates'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchCandidateOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={InterviewsFilterConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchInterviews());
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
