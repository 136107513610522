import { Col, Form, Row } from 'antd';
import {
  createEmployeeAssets,
  updateEmployeeAssets,
} from 'api/employeeService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { fetchAssetOptions } from 'components/NewForms/SelectWithLoad/utils';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { EmployeeAssetDTO, EmployeeAssetType } from 'types/Asset';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { convertUTCtoLocalTime } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  assetSelected?: EmployeeAssetType;
  employeeId: string;
}

export default function AddAssetsModal({
  open,
  closeModal,
  assetSelected,
  employeeId,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'assetId',
          label: t('assetsList'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchAssetOptions,
          defaultValue: assetSelected?.asset?.id,
          rules: [
            {
              required: true,
              message: t('assetisRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'notes',
          label: t('name'),
          type: 'input',
          defaultValue: assetSelected?.notes,
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: t('dateLoaned'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(assetSelected?.startDate),
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('dateReturned'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(assetSelected?.endDate),
        },
      ],
    ],
    []
  );

  const onSubmit = async () => {
    if (loading || !employeeId) return;

    setLoading(true);
    const formValues: EmployeeAssetDTO = form.getFieldsValue(true);

    const valuesToSend = {
      employeeId,
      assetId: form.getFieldValue('assetId').value,
      notes: formValues.notes,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
    };

    if (assetSelected) {
      await updateEmployeeAssets(assetSelected?.id, valuesToSend)
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(fetchEmployeesDetail(employeeId));
            toast.success(t('successfullyUpdatedAssets'));
            closeModal();
          }
        })
        .catch(() => {
          toast.error(t('failedtoUpdateAssets'));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await createEmployeeAssets(valuesToSend)
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(fetchEmployeesDetail(employeeId));
            toast.success(t('successfullyAssignedAssets'));
            closeModal();
          }
        })
        .catch(() => {
          toast.error(t('failedtoAssignAssets'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <GenericModal
      title={t('assignAsset')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
