import { Reducer } from 'react';
import { Action } from 'redux';
import {
  PayrollReportActions,
  PayrollReportSingleEmployeeState,
  PayrollReportState,
} from './types';

const initialState: PayrollReportState = {
  data: [],
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const PayrollDatesReducer: Reducer<PayrollReportState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case PayrollReportActions.FETCH_PAYROLL_DATES_START:
      state = { ...state, loading: true };
      break;
    case PayrollReportActions.FETCH_PAYROLL_DATES_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case PayrollReportActions.FETCH_PAYROLL_DATES_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case PayrollReportActions.CLEAR_SINGLE_PAYROLL_DATES:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const initialSingleEmployeeState: PayrollReportSingleEmployeeState = {
  data: null,
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const SingleEmployeePayrollReportReducer: Reducer<
  PayrollReportSingleEmployeeState,
  Action
> = (state = initialSingleEmployeeState, action: any) => {
  switch (action.type) {
    case PayrollReportActions.FETCH_SINGLE_PAYROLL_REPORT_START:
      state = { ...state, loading: true };
      break;
    case PayrollReportActions.FETCH_SINGLE_PAYROLL_REPORT_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case PayrollReportActions.FETCH_SINGLE_PAYROLL_REPORT_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case PayrollReportActions.CLEAR_ALL_PAYROLL_REPORT:
      state = initialSingleEmployeeState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { SingleEmployeePayrollReportReducer, PayrollDatesReducer };
